import { graphql, useStaticQuery } from 'gatsby';
import { CurrencyDocumentData } from 'src/app/types';

interface CurrenciesQuery {
  allCurrency: {
    nodes: CurrencyDocumentData[];
  };
}

const allCurrencyQuery = graphql`
  {
    allCurrency {
      nodes {
        # currencyData
        id
        localizations {
          localeId
          locales {
            description
            name
          }
        }
      }
    }
  }
`;

export function useSourcedCurrencies(): CurrencyDocumentData[] {
  const { allCurrency } = useStaticQuery<CurrenciesQuery>(allCurrencyQuery);
  return allCurrency?.nodes || [];
}
