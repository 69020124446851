import * as React from 'react';
import { navigate } from 'gatsby';

import DocumentDeleteIcon from '@material-ui/icons/DeleteForever';
import DocumentEditIcon from '@material-ui/icons/Edit';
import DocumentCreateIcon from '@material-ui/icons/NoteAdd';
import DocumentPreviewIcon from '@material-ui/icons/FindInPage';
import DocumentPageIcon from '@material-ui/icons/MenuBook';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  Button,
  ButtonGroup,
  Grid,
  Localized,
  TableToolbar,
  Toolbar,
} from 'src/app/components';

import { useAppContext } from 'src/app/hooks';
import {
  BaseGeographicalDocumentData,
  DocumentDeleteProps,
  BaseDocumentData,
  TableRowClickEvent,
} from 'src/app/types';
import { SystemAdminDialogType } from 'src/app.system/constants';

import { SourceCollectionName } from 'src/app/constants';
import { getSystemSourceCollectionPath } from 'src/lib/db';
import {
  filterOptions,
  createHeaderTableData,
  transformDataForTable,
} from './table.helper';

import { DocumentTableContainer } from './DocumentTableContainer';
import { DocumentPreview } from './DocumentPreview';

export interface DocumentListContainerProps<T extends BaseDocumentData> {
  children?: React.ReactNode;
  className?: string;
  columnPropertyNames: string[];
  collectionName?: SourceCollectionName; // for delete action
  features: {
    hasCreate: boolean;
    hasDelete: boolean;
    hasDocumentLink: boolean;
    hasEdit: boolean;
    hasPreview: boolean;
    hasEditImages: boolean;
    hasEditTranslations: boolean;
  };
  navigatePaths?: DocumentNavigationPaths;
  screenI18nBasePath: string;
  tableData: T[];
  tableI18nBasePath: string;
  onUpdateTableData: () => void;
  renderControls?: (document: T | null) => React.ReactNode;
}

export interface DocumentNavigationPaths {
  createDocumentPath: string;
  editDocumentPath: string;
  editDocumentImagesPath: string;
  editDocumentTranslationsPath: string;
  previewDocumentPath: string;

  // TODO:
}

// const tableI18nBasePath = 'system:document.countryDocument'; // FIXME
// TODO: create common preview config for DocumentPreviewScreenRouter & DocumentListContainer

const getDocumentPagePath = (
  docType: SourceCollectionName,
  doc: BaseGeographicalDocumentData,
): string => {
  switch (docType) {
    case SourceCollectionName.CATEGORY:
      return `/${'en'}/${doc.categoryTypeId}/${doc.categoryId}`;
    case SourceCollectionName.CATEGORY_TYPE:
      return `/${'en'}/${doc.id}`;
    case SourceCollectionName.CONTINENT:
      return `/${'en'}/${doc.id}`;
    case SourceCollectionName.COUNTRY:
      return `/${'en'}/${doc.continentId}/${doc.id}`;
    case SourceCollectionName.COUNTRY_AREA:
      return `/${'en'}/${doc.continentId}/${doc.countryId}/area/${doc.id}`;
    case SourceCollectionName.CITY:
      return `/${'en'}/${doc.continentId}/${doc.countryId}/city/${doc.id}`;
    case SourceCollectionName.CITY_AREA:
      return `/${'en'}/${doc.continentId}/${doc.countryId}/city/${doc.cityId}/${doc.id}`;
    case SourceCollectionName.LOCATION:
      return `/${'en'}/location/${doc.id}`;
    case SourceCollectionName.TAG:
      return `/${'en'}/tag/${doc.id}`;
    case SourceCollectionName.PRODUCT:
      return `/${'en'}/experience/${doc.id}`;
    default:
      return '/';
  }
};

// const DocumentListContainerToolbar = () => {
//   return (

//   )
// }

export const DocumentListContainer = <T extends BaseDocumentData>({
  children,
  className,
  columnPropertyNames,
  collectionName,
  features,
  navigatePaths,
  screenI18nBasePath,
  tableI18nBasePath,
  tableData,
  onUpdateTableData,
  renderControls,
}: DocumentListContainerProps<T>) => {
  const { openDialog } = useAppContext();
  let {
    hasCreate,
    hasDelete,
    hasDocumentLink,
    hasEdit,
    hasEditImages,
    hasEditTranslations,
    hasPreview,
  } = features;
  // const { createDocumentPath, editDocumentPath, previewDocumentPath } = navigatePaths;

  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | undefined>(
    undefined,
  );
  const [selectedDocument, setSelectedDocument] = React.useState<T | null>(null);
  const [autoSuggestedItem, setAutoSuggestedItem] = React.useState<T | null>(null);

  const header = createHeaderTableData(columnPropertyNames);
  const transformedData = transformDataForTable<T>((tableData as any[] as T[]) || []); // type mismatch?

  const filteredData = autoSuggestedItem
    ? transformedData.filter((countryDoc: T) => countryDoc.id === autoSuggestedItem.id)
    : transformedData;

  if (typeof window === 'undefined') {
    return null;
  }

  const onCreateDocument = () => {
    navigate(`${navigatePaths?.createDocumentPath || 'invalidPath'}`);
  };

  const onDeleteDocument = () => {
    if (!selectedDocument) {
      return;
    }

    const dialogParams: DocumentDeleteProps = {
      documentCollectionPath: getSystemSourceCollectionPath(collectionName),
      documentId: selectedDocument.id,
      shouldDeleteAttachedFiles: true,
      onSuccess: () => onUpdateTableData(),
    };

    // FIXME: on this level we use only the app wide DialogType
    openDialog(SystemAdminDialogType.DOCUMENT_DELETE, dialogParams);
  };

  let openDocumentOption = false;
  let previewDocumentOption = false;
  if(collectionName=='order_user'){
    hasPreview=true;
  }
  console.log('oodoidp',collectionName);

  /** PREVIEW SWITCH  // TODO move into cfg */
  if (
    collectionName === SourceCollectionName.CATEGORY ||
    collectionName === SourceCollectionName.CATEGORY_TYPE ||
    collectionName === SourceCollectionName.CONTINENT ||
    collectionName === SourceCollectionName.COUNTRY ||
    collectionName === SourceCollectionName.COUNTRY_AREA ||
    collectionName === SourceCollectionName.CITY ||
    collectionName === SourceCollectionName.CITY_AREA ||
    collectionName === SourceCollectionName.LOCATION ||
    collectionName === SourceCollectionName.TAG ||
    collectionName === SourceCollectionName.PRODUCT || 
    collectionName === SourceCollectionName.ORDERUSER 
  ) {
    openDocumentOption = true;
    previewDocumentOption = true;
  }

  const onEditDocument = () => {
    if (!selectedDocument) {
      return;
    }
    navigate(
      `${navigatePaths?.editDocumentPath || 'invalidPath'}/${selectedDocument.id}`,
    );
    console.log(navigatePaths?.editDocumentPath,'hgfkhfg')
  };

  const onPreviewDocumentPage = () => {
    if (!selectedDocument || typeof window === 'undefined') {
      return;
    }
    window.open(
      `${navigatePaths?.previewDocumentPath || 'invalidPath'}/${selectedDocument.id}`,
      '_blank',
      'toolbar=yes, location=yes, directories=no, status=yes, menubar=yes, scrollbars=yes, resizable=yes',
    );
  };

  const onOpenDocumentPage = () => {
    if (!selectedDocument) {
      return;
    }
    const doc = selectedDocument as any as BaseGeographicalDocumentData;
    window.open(
      getDocumentPagePath(collectionName, doc),
      '_blank',
      'toolbar=yes, location=yes, directories=no, status=yes, menubar=yes, scrollbars=yes, resizable=yes',
    );

    // if (collectionName === SourceCollectionName.CONTINENT) {
    //   window.open(
    //     `/${'en'}/${selectedDocument.id}`,
    //     '_blank',
    //     'toolbar=yes, location=yes, directories=no, status=yes, menubar=yes, scrollbars=yes, resizable=yes',
    //   );
    // }
    // if (collectionName === SourceCollectionName.COUNTRY) {
    //   window.open(
    //     `/${'en'}/${doc.continentId}/${selectedDocument.id}`,
    //     '_blank',
    //     'toolbar=yes, location=yes, directories=no, status=yes, menubar=yes, scrollbars=yes, resizable=yes',
    //   );
    // }
    // if (collectionName === SourceCollectionName.CITY) {
    //   window.open(
    //     `/${'en'}/${doc.continentId}/${doc.countryId}/city/${selectedDocument.id}`,
    //     '_blank',
    //     'toolbar=yes, location=yes, directories=no, status=yes, menubar=yes, scrollbars=yes, resizable=yes',
    //   );
    // }
  };

  const onEditDocumentTranslations = () => {
    if (!selectedDocument) {
      return;
    }
    navigate(
      `${navigatePaths?.editDocumentTranslationsPath || 'invalidPath'}/${
        selectedDocument.id
      }`,
    );
  };
  const onEditDocumentImages = () => {
    if (!selectedDocument) {
      return;
    }
    navigate(
      `${navigatePaths?.editDocumentImagesPath || 'invalidPath'}/${selectedDocument.id}`,
    );
  };
 

  return (
    <Grid container className={className || ''}>
      <Grid item xs={12}>
      <DocumentTableContainer<T>
                      data={filteredData}
                      header={header}
                      i18nBasePath={tableI18nBasePath}
                      onRowClick={(e: TableRowClickEvent) => {
                        console.log('sjakhdashdjhja',e);
                        setSelectedRowIndex(e.index);
                        setSelectedDocument(e.rowData);
                      }}
                      selectedRowIndex={selectedRowIndex}
                      toolbar={
                        <TableToolbar<T>
                          autocompleteOptions={tableData as any[] as T[]} // maybe transform for search?
                          filterOptions={filterOptions}
                          title={
                            <Localized
                              dictKey={`${screenI18nBasePath}.list.count`}
                              translationOptions={{ count: tableData ? tableData.length : 0 }}
                            />
                          }
                          onChange={(selectedRowData) => setAutoSuggestedItem(selectedRowData)}
                          centerToolbar={
                            <>
                            
                              <Toolbar>
                                <ButtonGroup
                                  fullWidth
                                  size={'large'}
                                  variant={'outlined'}
                                  color={'primary'}
                                  className='w-full'
                                >
                                  {hasCreate ? (
                                    <Button
                                      disabled={false}
                                      startIcon={<DocumentCreateIcon />}
                                      onClick={onCreateDocument}
                                    >
                                      Create
                                    </Button>
                                  ) : null}
                                  {hasEdit ? (
                                    <Button
                                      disabled={!selectedDocument}
                                      startIcon={<DocumentEditIcon />}
                                      onClick={() => onEditDocument()}
                                    >
                                      Edit
                                    </Button>
                                  ) : null}
            
                                  {hasDelete ? (
                                    <Button
                                      disabled={!selectedDocument}
                                      onClick={onDeleteDocument}
                                      startIcon={<DocumentDeleteIcon />}
                                    >
                                      Delete
                                    </Button>
                                  ) : null}
                                </ButtonGroup>
                              </Toolbar>
                              {hasEditTranslations || hasEditImages ? (
                                <Toolbar>
                                  <ButtonGroup
                                    fullWidth
                                    size={'large'}
                                    variant={'outlined'}
                                    color={'primary'}
                                    className='w-full'
                                  >
                                    {hasEditTranslations ? (
                                      <Button
                                        disabled={!selectedDocument}
                                        startIcon={<DocumentEditIcon />}
                                        onClick={onEditDocumentTranslations}
                                      >
                                        Edit translation
                                      </Button>
                                    ) : null}
                                    {hasEditImages ? (
                                      <Button
                                        disabled={!selectedDocument}
                                        startIcon={<DocumentEditIcon />}
                                        onClick={onEditDocumentImages}
                                      >
                                        Edit images
                                      </Button>
                                    ) : null}
                                  </ButtonGroup>
                                </Toolbar>
                              ) : null}
                              <Toolbar>
                                <ButtonGroup
                                  fullWidth
                                  size={'large'}
                                  variant={'outlined'}
                                  color={'primary'}
                                  className='w-full'
                                >
                                  {hasPreview ? (
                                    <Button
                                      disabled={!selectedDocument || !previewDocumentOption}
                                      onClick={() => onPreviewDocumentPage()}
                                      startIcon={<DocumentPreviewIcon />}
                                    >
                                      Open preview
                                    </Button>
                                  ) : null}
                                  {hasDocumentLink ? (
                                    <Button
                                      disabled={!selectedDocument || !openDocumentOption}
                                      onClick={() => onOpenDocumentPage()}
                                      startIcon={<DocumentPageIcon />}
                                    >
                                      Open shoppage
                                    </Button>
                                  ) : null}
                                </ButtonGroup>
                              </Toolbar>
                            </>
                          }
                        >
                          <>
                          
                            {renderControls ? renderControls(selectedDocument) : null}
                            {children || null}
                          </>
                        </TableToolbar>
                      }
        />
        
      </Grid>
    </Grid>
  );
};
