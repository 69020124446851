import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Details from '@material-ui/icons/Details';
// import dayjs from 'dayjs';

import CalendarToday from '@material-ui/icons/CalendarTodayOutlined';

import {
  Button,
  ButtonTw,
  DatePicker,
  DropDown,
  Grid,
  Localized,
  ProductConfiguration,
  Typography,
} from 'src/app/components';
import {
  BookingSystemType,
  DatePickerType,
  ParticipantEditMode,
} from 'src/app/constants';
import { ThemeMode } from 'src/app.consumer/constants';
import {
  AppDate,
  DayTime,
  DaytimeEventDocument,
  Price,
  ShoppingCartItemConfiguration,
  // SourceProduct,
  SourcedGQLProduct,
  AddToCartDialogInitialConfig,
} from 'src/app/types';
import {
  calculatePriceFromPriceString,
  calculatePriceGroupTotalPriceWithExtraFees,
  calculateTotalPrice,
  euroPriceAsCurrency,
  getBasePriceByFeatureEnabled,
  getLowestProductPriceSystemBasePrice,
  getProductPriceSystemBasePrice,
  padPrice,
} from 'src/lib/price';
import { getDayTimeFromTimeString, isProductEventDateDisabled } from 'src/lib/date';
import { useAppContext, useStyles } from 'src/app/context';

export interface ProductAddToCartControlsProps {
  bookingSystemType: BookingSystemType;
  children: React.ReactNode;
  date: AppDate;
  dayTime: DayTime | null;
  eventDocument: DaytimeEventDocument | null;
  isCheckoutDisabled: () => boolean; // loading or no results
  isExpanded: boolean;
  isLoading: boolean;
  isProductAvailable: boolean;
  priceGroupTickets: ShoppingCartItemConfiguration[];
  priceSystemId?: string;
  product: SourcedGQLProduct;
  onAddItemToCart: () => void;
  onCheckAvailability: () => void;
  onDayTimeSelect: (dayTime: string) => void;
  setDate: (date: AppDate) => void;
  setIsExpanded: (expanded: boolean) => void;
  setPriceGroupTickets: (ticketGroupItems: ShoppingCartItemConfiguration[]) => void;
  themeMode?: ThemeMode;
  totalPrice: Price;
  hasInitialRequest: boolean;
  pickupOptionId?: string;
  ticketOrderLimit: number;
}

export const ProductAddToCartControls = (props: ProductAddToCartControlsProps) => {
  const {
    date,
    dayTime,
    eventDocument,
    isCheckoutDisabled,
    isExpanded,
    isLoading,
    isProductAvailable,
    onAddItemToCart,
    onCheckAvailability,
    onDayTimeSelect,
    product,
    priceGroupTickets,
    priceSystemId,
    setDate,
    setIsExpanded,
    setPriceGroupTickets,
    themeMode,
    totalPrice,
    hasInitialRequest,
    pickupOptionId,
    ticketOrderLimit,
  } = props;

  // const styles = useStyles();
  const { currencyId } = useAppContext();
  let priceIndex = product?.priceSystems?.findIndex(p=>p.id === priceSystemId)
  priceIndex = priceIndex === -1 ? 0 : priceIndex;
  
  const productCurrentDetails = getBasePriceByFeatureEnabled(product?.priceSystems?.[priceIndex])
  let bookingSystemType = productCurrentDetails?.priceType || 'publicBooking'
  const allGroupPositionsPrices: Price[] =
  priceGroupTickets?.map((pgt) => {
    return calculatePriceGroupTotalPriceWithExtraFees(pgt, product?.priceSystems, product, bookingSystemType);
  }) || [];
  let checkPrice=totalPrice
  if (allGroupPositionsPrices?.length) {
      checkPrice = calculateTotalPrice(allGroupPositionsPrices);
  }
  const totalConvertPrice=euroPriceAsCurrency(checkPrice, currencyId);
  // FIXME: concept of primary priceSystem => depends on dayTimeStart
  // const basePriceSystem =
  //   product?.productPriceSystems?.length > 0 ? product.productPriceSystems[0] : null;
  // TODO: maybe disable the quickbuy btn if there are multiple variants?
  // TODO: should also check if the are product variants enabled
  // TODO: basePriceSystem productPriceSystem depends on selected day activity time

  // const hasPriceSystemVariants = !!(productPriceSystems && productPriceSystems.length > 1);
  let lowestPrice: Price | null = null;
  let max_num=0;
  if( bookingSystemType==BookingSystemType.PRIVATE){
    max_num=productCurrentDetails?.maxParticipants || 0;
  }
  // if (!product?.priceSystems?.length) {
  //   // => priceSystems
  //   return null;
  // }

  // // the lowest price among alle pricegroups -> not ideal (depends on day and startTime...)
  // for (let priceSysIdx = 0; priceSysIdx < product.priceSystems.length; priceSysIdx++) {
  //   if (product.priceSystems[priceSysIdx]?.publicBooking?.featureEnabled) {
  //     const priceString = product.priceSystems[priceSysIdx].basePrice;

  //     let curLowestPrice: Price = {
  //       integer: 999999,
  //       decimal: 99,
  //     };
  //     if (priceString) {
  //       const pSystemPrice = calculatePriceFromPriceString(priceString.toString());
  //       if (
  //         (pSystemPrice.integer <= curLowestPrice.integer &&
  //           pSystemPrice.decimal < curLowestPrice.decimal) ||
  //         pSystemPrice.integer < curLowestPrice.integer
  //       )
  //         curLowestPrice = pSystemPrice;
  //       }
  //     }
  //     lowestPrice = curLowestPrice;
  //   }
  // }
  // NOTE: calculate the cheapest public base price from all price systems

  const lowestPublicPriceFloat = getLowestProductPriceSystemBasePrice(
    product,
    bookingSystemType,
  );
  lowestPrice = calculatePriceFromPriceString(lowestPublicPriceFloat?.toString() || 0);

  const priceSystemPrice = priceSystemId
    ? calculatePriceFromPriceString(
        getProductPriceSystemBasePrice(
          product,
          priceSystemId,
          bookingSystemType,
        )?.toString() || 0,
      )
    : null;
    console.log('sahdjkhaSJKDHJSA',priceSystemPrice);

    // console.log('priceGroupTicket: ', priceGroupTicket);

  const i18nPath = 'shop:screen-product';
  let price= priceSystemPrice
  ? 
      euroPriceAsCurrency(priceSystemPrice, currencyId)
  : euroPriceAsCurrency(lowestPrice, currencyId)

  // console.log('ProductAddToCartControls priceGroupTickets:', priceGroupTickets);

  const optionalEventReuseParams: AddToCartDialogInitialConfig = {
    bookingSystemType,
    priceGroupTickets,
    date,
    dayTime,
    fetchedEventDocument: eventDocument,
  };

  return (
    <>
      <div className='flex flex-col gap-x-2 gap-y-6 md:flex-row'>
        <div className='md:w-1/2'>
          <DatePicker
            selectedDate={date}
            setDate={setDate}
            type={DatePickerType.DATE}
            shouldDisableDate={(d) => isProductEventDateDisabled(d, product)}
            disablePast
            InputProps={{
              classes: {
                input: '!h-14 !box-border !font-normal',
              },
            }}
            keyboardIcon={<CalendarToday className={'text-accent-500'} />}
          />
        </div>
        <div className='md:w-1/2'>
          <DropDown
            variant={'outlined'}
            size={'large'}
            fullWidth
            style={{ height: '100%' }}
            themeMode={themeMode || ThemeMode.LIGHT}
            label={
              <>
                {dayTime ? (
                  <Localized
                    dictKey={`${i18nPath}.add-cart.time.label`}
                    translationOptions={{ time: dayTime }}
                  />
                ) : (
                  <Localized
                    dictKey={`${i18nPath}.add-cart.action.select-start-time.label`}
                  />
                )}
              </>
            }
            showArrowIcon={false}
            options={
              product?.availability?.daytimeEvents?.map((dailyStart) => {
                const time: DayTime = getDayTimeFromTimeString(dailyStart.startTime);
                return (
                  <ButtonTw
                    variant='text'
                    key={`dailyStart_${dailyStart.startTime}`}
                    onClick={() => onDayTimeSelect(dailyStart.startTime)}
                  >
                    <Localized
                      dictKey={`${i18nPath}.add-cart.time.format-time`}
                      translationOptions={{ time }}
                    />
                  </ButtonTw>
                );
              }) || []
            }
          />
        </div>
      </div>
      <div className='py-4'>
        <p className='text-p1 font-semibold'>
        {bookingSystemType==BookingSystemType.PRIVATE?
          <Localized
            dictKey={price.decimal===0?`${i18nPath}.add-cart.price-per-group.labelInteger`:`${i18nPath}.add-cart.price-per-group.label`}
            translationOptions={{
              currencyId,
              max_num:max_num,
              price: priceSystemPrice
                ? 
                    euroPriceAsCurrency(priceSystemPrice, currencyId)
                : euroPriceAsCurrency(lowestPrice, currencyId),
            }}
          />:          
          <Localized
          dictKey={price.decimal===0?`${i18nPath}.add-cart.price-per-person-from.labelInteger`:`${i18nPath}.add-cart.price-per-person-from.label`}
          translationOptions={{
            currencyId,
            max_num:max_num,
            price: priceSystemPrice
              ? 
                  euroPriceAsCurrency(priceSystemPrice, currencyId)
              : euroPriceAsCurrency(lowestPrice, currencyId),
          }}
        />}


        </p>
      </div>
      <div className='text-center py-6'>
        <ButtonTw
          variant={'accent'}
          // color={isNotAvailable() ? 'error' : 'primary'} // TODO
          onClick={onCheckAvailability}
          size='lg'
          rounded
          className='w-full md:w-auto md:min-w-[270px]'
        >
          <Localized
            dictKey={`${i18nPath}.add-cart.action.availability-check.label`}
            translationOptions={{ count: ticketOrderLimit || 0 }}
          />
        </ButtonTw>
      </div>

      {!isProductAvailable && hasInitialRequest ? (
        <div>
          <p className='py-4 font-bold'>
            <Localized
              dictKey={`${i18nPath}.add-cart.action.availability-check.not-available.label`}
            />
          </p>
        </div>
      ) : null}

      {isLoading ? (
        <div>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <>
          {!isCheckoutDisabled() && eventDocument ? (
            <div>
              {isExpanded && priceSystemId ? (
                <ProductConfiguration
                  product={product}
                  priceSystemId={priceSystemId}
                  priceGroupTickets={priceGroupTickets}
                  setPriceGroupTickets={setPriceGroupTickets}
                  cartType={bookingSystemType}
                  showParticipantsInfo={false}
                  pickupOptionId={pickupOptionId}
                  ticketOrderLimit={ticketOrderLimit}
                  hiddenChapters={{ productInformation: true }}
                  openChapters={{ priceGroups: true }}
                  participantEditMode={ParticipantEditMode.COLLAPSED}
                  optionalEventReuseParams={
                    dayTime && eventDocument && optionalEventReuseParams
                      ? optionalEventReuseParams
                      : undefined
                  }
                />
              ) : null}
            </div>
          ) : null}
          <div className='text-center py-6'>
            <ButtonTw
              disabled={
                isCheckoutDisabled()
                // || priceGroupTickets.filter((pgt) => pgt.count === 0).length ===
                //   priceGroupTickets.length
              }
              onClick={onAddItemToCart}
              size={'lg'}
              variant='accent'
              rounded
              className='w-full md:w-auto md:min-w-[270px]'
            >
              <Localized
        
                dictKey={totalConvertPrice.decimal===0?`${i18nPath}.add-cart.action.add-product-to-cart.labelInteger`:`${i18nPath}.add-cart.action.add-product-to-cart.label`}
                translationOptions={{
                  currencyId,
                  price: {
                    decimal: totalConvertPrice.decimal,
                    integer: totalConvertPrice.integer,
                  },
                }}
              />
            </ButtonTw>

            {/* <ButtonTw
              disabled={isCheckoutDisabled()}
              onClick={() => setIsExpanded(!isExpanded)}
              variant='text'
            >
              <Details />
            </ButtonTw> */}

          </div>
        </>
      )}
      {/* <Grid item xs={12}>
      <Typography variant={'subtitle1'}>
        <GiftContainer />
      </Typography>
    </Grid> */}

    </>
  );
};
