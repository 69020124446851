import * as React from 'react';

import { Divider, Grid, Typography } from 'src/app/components';

export interface DialogLayoutProps {
  children?: React.ReactNode;
  dialogControls?: React.ReactNode;
  dialogDescription?: string | React.ReactNode;
}

const DialogLayout = ({
  children,
  dialogControls,
  dialogDescription,
}: DialogLayoutProps) => {
  return (
    <Grid container spacing={2}>
      {dialogDescription ? (
        <>
          <Grid item xs={12}>
            {typeof dialogDescription === 'string' ? (
              <Typography variant={'body1'}>{dialogDescription}</Typography>
            ) : (
              dialogDescription
            )}
          </Grid>
        </>
      ) : null}
      {children ? (
        <Grid item xs={12}>
          {children}
        </Grid>
      ) : null}
      {dialogControls ? (
        <>
          <Grid item xs={12}>
            {dialogControls}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export { DialogLayout };
