import * as React from 'react';

import { Localized } from 'src/app/components';

import { Price } from 'src/app/types';

import { createPriceStringWithCurrency } from 'src/lib/price';

const i18nPrefix = 'shop:screen-product';

interface TotalRowProps {
  totalPrice: Price;
  currencyId: string;
}

export const TotalRow = ({ totalPrice, currencyId }: TotalRowProps) => {
  return (
    <div className='flex gap-4 font-bold justify-between'>
      <div>
        <p>
          <Localized dictKey={`${i18nPrefix}.price-summary.total-row.total`} />
        </p>
      </div>
      <div>
        <p className='text-right'>
          {createPriceStringWithCurrency(totalPrice, currencyId)}
        </p>
      </div>
    </div>
  );
};
