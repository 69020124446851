import categoryDocument from './category.json';
import categoryTypeDocument from './categoryType.json';
import cityDocument from './city.json';
import cityAreaDocument from './cityArea.json';
import countryDocument from './country.json';
import countryAreaDocument from './countryArea.json';
import continentDocument from './continent.json';
import currencyDocument from './currency.json';
import localeDocument from './locale.json';
import locationDocument from './location.json';
import orderEventDocument from './orderEvent.json';
import orderDocument from './order.json';
import tagDocument from './tag.json';

export default {
  ...categoryDocument,
  ...categoryTypeDocument,
  ...cityDocument,
  ...cityAreaDocument,
  ...countryDocument,
  ...countryAreaDocument,
  ...continentDocument,
  ...currencyDocument,
  ...localeDocument,
  ...locationDocument,
  ...orderEventDocument,
  ...orderDocument,
  ...tagDocument,
};
