import { createTheme, Theme } from '@material-ui/core/styles';
import {
  blueGrey,
  lightBlue,
  amber,
  red,
  orange,
  pink,
  yellow,
  indigo,
  green,
  lightGreen,
} from '@material-ui/core/colors';
// eslint-disable-next-line no-restricted-imports
import { alpha } from '@material-ui/core/styles/colorManipulator';
// import 'react-image-crop/dist/ReactCrop.css';

import { ThemeMode } from 'src/app.consumer/constants';

const themeMode = ThemeMode.DARK;
const isDark = Boolean(themeMode === ThemeMode.LIGHT);
// main: '#f50057',
// const primaryColor = isDark ? '#ffa500dd' : '#ffa500dd';
// const primaryColorLight = isDark ? '#ffa50088' : '#ffa50088';
// const primaryColorDark = isDark ? '#ffa500ff' : '#ffa500ff';
// #1CCFB9
const primaryColorUltraLight = '#ffe0b2'; // '#20CF1C'; // #ffe0b2
const primaryColorLight = '#1CCFB9'; // #ffa726
const primaryColor = '#1CCFB9'; // #ff9800
const secondaryColor = '#273B6D'; // #ff9800
const primaryColorDark = '#27EAD2'; // #fb8c00

// NOTE: global consts

export const global_borderRadius = 12;

/** we set a custom variable for .fullheight class in css which we update here  */
const cssGlobalBaseLine = {
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'content-box',
    },

    '[data-color-scheme="dark"]': {
      colorScheme: 'dark',
    },

    '[data-color-scheme="light"]': {
      colorScheme: 'light',
    },

    // OLD SCROLL BAR CODE

    /* Hide scrollbar for Chrome, Safari and Opera */
    // 'html::-webkit-scrollbar': {
    //   display: 'none',
    // },

    /* Hide scrollbar for IE, Edge and Firefox */
    // html: {
    //   '-ms-overflow-style': 'none' /* IE and Edge */,
    //   'scrollbar-width': 'none' /* Firefox */,
    //   height: '100%',
    // },

    html: {
      height: '100%',
    },

    body: {
      height: '100%',
      // backgroundColor: '#fff !important',
      overFlow: 'crop',
      // NOTE: FONTS
      // 'font-family':
      //   '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    },

    a: {
      // color: '#f00 !important',
      textDecoration: 'none !important',
    },

    '.fullheight': {
      height: 'calc(var(--vh, 1vh) * 100)',
    },

    // '.glass-item': {
    //   backgroundColor: '#f0f !important',
    // },

    '@supports ( backdrop-filter: blur(1px) )': {
      '.glass-item': {
        // backgroundColor: '#0f0 !important',
      },
    },
    /* #GLASS */
    '@supports (not (backdrop-filter: blur()))': {
      '.glass-item': {
        // backgroundColor: '#eafaffe8 !important', // FIXME remove
      },
      // '.glass-bar': {
      //   // backgroundColor: '#eafaffe8 !important', // FIXME remove
      //   // backgroundColor: '##9efaffaa!important',
      // },
      // h1::before {
      //     content: '';
      //     position: absolute;
      //     z-index: -1;
      //     top: 0; left: 0; bottom: 0; right: 0;
      //     background: element(#back) fixed;
      //     filter: grayscale(1) contrast(3) blur(1px);
      // }
    },

    '.page-container': { padding: '66px 0' },
    '.page-container-less-v-padding': { padding: '64px 0' },
    '.page-container-no-v-padding': { padding: '0' },

    '.page-inner-container': { padding: '32px', minHeight: '81vh' },

    '.item-container': {
      padding: '32px',
      minHeight: '96px',
      // width: '100%',
      // margin: '32px 0',
      // padding: '64px', width: '100%', margin: '64px 0'
    },

    '.org-item-container': {
      padding: '16px',
      minHeight: '48px',
      margin: '32px 0',
    },

    '.row__selected': {
      backgroundColor: '#ff980022',
      borderColor: '#ff9800',
      borderStyle: 'solid',
      borderRadius: global_borderRadius,
      borderWidth: '1px',
    },

    // 'input:-webkit-autofill': {
    //   '-webkit-box-shadow': '0 0 0 30px #9efaff22 inset !important',
    // },
    // '&:-webkit-autofill': {
    //   '-webkit-box-shadow': '0 0 0 30px #9efaff22 inset !important',
    // },

    // 'input:-webkit-autofill': {
    //   '-webkit-box-shadow': '0 0 0 30px #ff000000 inset !important',
    // },
    // '&:-webkit-autofill': {
    //   '-webkit-box-shadow': '0 0 0 30px #ff000000 inset !important',
    // },

    'input:-webkit-autofill': {
      // border: '1px solid green',
      '-webkit-text-fill-color': '#222',
      '-webkit-box-shadow': '0 0 0px 1000px #FF8E5322 inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },

    //     `input:-webkit-autofill,
    //     input:-webkit-autofill:hover,
    //     input:-webkit-autofill:focus,
    //     textarea:-webkit-autofill,
    //     textarea:-webkit-autofill:hover,
    //     textarea:-webkit-autofill:focus,
    //     select:-webkit-autofill,
    //     select:-webkit-autofill:hover,
    //     select:-webkit-autofill:focus`: {
    //       border: '1px solid green',
    //       '-webkit-text-fill-color': 'green',
    //       '-webkit-box-shadow': '0 0 0px 1000px #000 inset',
    //       transition: 'background-color 5000s ease-in-out 0s',
    //     },
    //   },
    // },

    '.video-responsive': {
      overflow: 'hidden',
      'padding-bottom': '56.25%',
      position: 'relative',
      height: 0,
      'border-radius': '16px',
    },
    '.video-responsive iframe': {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
    },

    // TODO: get rid of those

    '.MuiAccordionSummary-root': {
      minHeight: '0px !important',
      margin: '2px 0px !important',
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '0px !important',
      margin: '2px 0px !important',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      minHeight: '0px !important',
      margin: '2px 0px !important',
    },

    '.MuiDialog-paperFullWidth': {
      // width: 'calc(100% - 256px) !important',
    },
    '.MuiDialog-paperWidthSm': {
      // maxWidth: '800px',
    },
  },
};

const overrides = {
  /* MuiCssBaseline: {
    '@global': {
      ...cssGlobalBaseLine['@global'],
    },
  }, */
  MuiAccordionSummary: {
    content: {
      margin: 0,
    },
    root: {
      '&:.Mui-expanded': {
        minHeight: 0,
      },
      'Mui-expanded': {
        minHeight: 0,
      },
      '.Mui-expanded': {
        minHeight: 0,
      },
    },
  },
  MuiTypography: {
    button: {
      textTransform: 'none',
    },
  },
  MuiAlert: {
    root: {
      backgroundColor: '#fff',
      borderRadius: global_borderRadius,
      border: 0,
    },
    action: {
      alignItems: 'flex-start',
      marginRight: '0',
      marginTop: '4px',
    },
    standardInfo: {
      backgroundColor: '#fff',
      color: '#19233F',
    }
  },
  MuiButton: {
    outlinedSizeLarge: {
      padding: '14px 21px',
    },
    outlinedSizeSmall: {
      minHeight: '32px',
      borderRadius: '8px',
    },
    outlined: {
      backgroundColor: '#20CF1C14',
    },
    root: {
      minWidth: '32px',
      maxHeight: '56px',
      minHeight: '42px',
      //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      //   borderRadius: global_borderRadius,
      //   border: 0,
      //   color: 'white',
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
    },
  },
  MuiButtonBase: {
    root: {
      // minWidth: '32px',
      //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      //   borderRadius: global_borderRadius,
      //   border: 0,
      //   color: 'white',
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
    },
  },
  MuiToggleButtonGroup: {
    groupedHorizontal: {
      color: '#19233f',
      '&.Mui-selected': {
        backgroundColor: primaryColor,
        color: '#19233f',
        '&:hover': {
          backgroundColor: primaryColor,
        },
      },
    },
  },
  MuiToggleButton: {
    root: {

    },
  },
  MuiPaginationItem: {
    page: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        fontWeight: 600,
      },
    },
    root: {
      '&.Mui-disabled': {
        display: 'none',
      },
    },
  },
  MuiFilledInput: {
    root: {},
    // fieldset: {
      // backgroundColor: primaryColor,
      // color: primaryColor,
    // },
    input: {
      // borderColor: primaryColor,
      // color: primaryColor,
    },
  },
  MuiFormControl: {
    root: {},
  },
  MuiFormControlLabel: {
    label: {
      width: '100%',
      minWidth: '240px',
    },
  },
  MuiInput: {
    root: {},
    input: {
      // color: primaryColor,
    },
  },
  // All in one
  MuiInputBase: {
    root: {
      // height: '42px',
      // minHeight: '42px',
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      // background: 'none',
      // background: 'linear-gradient(0deg, #aaaaaa22 60%, #eeeeee44 90%)',
      // backgroundColor: '#aaaaaa22',
      // borderColor: '#f00',
      borderRadius: global_borderRadius,
      backgroundColor: '#f2f2f2',
      border: 0,
      '&:-internal-autofill-selected': {
        '-webkit-box-shadow': '0 0 0 30px #00000000 inset !important',
      },
    },
    input: {
      '&::placeholder': {
        // color: primaryColor,
      },
      // color: primaryColor, // if you also want to change the color of the input, this is the prop you'd use
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: '#f2f2f2',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#aaaaaa',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: primaryColor,
      },
    },
    input: {
      //backgroundColor: 'red',

    },
    notchedOutline: {
      borderColor: '#dddddd',
      // backgroundColor: primaryColor,
      // color: primaryColor,
    },
    inputMarginDense: {
      // paddingTop: '4px',
      // paddingBottom: '4px',
    },
    // fieldset: {
    //   backgroundColor: primaryColor,
    //   color: primaryColor,
    // },
  },
  MuiInputAdornmentRoot: {
    backgroundColor: primaryColor,
  },
  MuiInputAdornment: {
    root: {},
  },
  MuiInputLabel: {
    root: {
      //color: 'red', // worx
    },
    outlined: {
      transform: 'translate(14px, 20px) scale(1)', // translate(14px, 20px) scale(1) in original with larger input size (large vs medium)
      '&.MuiInputLabel-marginDense': {
        transform: 'translate(14px, 19px) scale(1)',
      },
    },
    shrink: {
      transform: 'translate(14px, -6px) scale(0.75) !important',
    },
    formControl: {
      // color: primaryColor,
    },
  },
  MuiList: {
    root: {
      backgroundColor: '#fff', // see further down @ issue of mui paper
    },
  },
  MuiPaper: {
    root: {
      // backgroundColor: '#fff', // this is default but gets in conflict with glassPaper => TODO: find best fallback solution for all devices
      // backgroundColor: '#ffffff77', // if this is used => fallback for non glass supporting browsers are needed
      backgroundColor: '#fbfbfb',
      color: '#19233F',
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      backgroundColor: '#fff',
    },
  },
  MuiPickersDay: {
    // day: {
    //   color: 'light-gray',
    //   fontFamily: '"Do Hyeon", sans-serif',
    //   backgroundColor: 'white',
    //   borderRadius: global_borderRadius,
    // },
    // container: {
    //   backgroundColor: 'black',
    // },
    // daySelected: {
    //   backgroundColor: '',
    //   color: 'light-gray',
    // },
    // dayDisabled: {
    //   color: 'black',
    // },
    // current: {
    //   color: '',
    // },
  },
  '.Mui-expanded': {
    minHeight: 0,
  },
  MuiSwitch: {
    switchBase: {
      // Controls default (unchecked) color for the thumb
      color: '#ccc', // default
      // color: primaryColor,
      '&$checked': {
        // Controls checked color for the thumb
        color: '#1CCFB9',
      },
    },
    colorPrimary: {
      // color: primaryColorUltraLight,
      color: alpha(primaryColor, 0.4),
      '&$checked': {
        // Controls checked color for the thumb
        color: primaryColor,
      },
    },
    colorSecondary: {
      color: alpha(secondaryColor, 0.4),
      '&$checked': {
        // Controls checked color for the thumb
        color: secondaryColor,
      },
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.7,
      // backgroundColor: '#fff', // default
      // default unchecked state
      '&:not($colorPrimary):not($colorSecondary) + &': {
        backgroundColor: '#fff', // default
      },
      '$colorPrimary + &': {
        backgroundColor: primaryColorUltraLight, // default
      },
      '$colorSecondary + &': {
        backgroundColor: '#ddd' // default
      },
      // checked
      '$checked:not($colorPrimary):not($colorSecondary) + &': {
        opacity: 0.7,
        backgroundColor: '#ddd', // default
      },
      '$checked$colorPrimary + &': {
        opacity: 0.7,
        backgroundColor: primaryColor,
      },
      '$checked$colorSecondary + &': {
        opacity: 0.7,
        backgroundColor: secondaryColor, // default
      },
    },
  },
  MuiTextField: {
    root: {
      // height: '46px',
    },
  },
  MuiStepIcon: {
    root: {
      border: '1px solid #010D1C',
      borderRadius: '50%',
      color: 'transparent',
      width: '2rem',
      height: '2rem',
    },
    active: {
      border: 'none',
    },
    text: {
      fontWeight: 'bold',
    },
  },
  MuiStepConnector: {
    line: {
      borderColor: '#1CCFB9',
    },
  },
  MuiBackdrop: {
    root: {
      backdropFilter: 'blur(2px)',
      backgroundColor: '#19233F40',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '12px 24px 24px',
    },
  },
  MuiDialogTitle: {
    root: {
      padding: '24px 24px 12px',
    },
  },
  MuiAccordion: {
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    rounded: {
      borderRadius: '8px',
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '&:last-child': {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      borderRadius: '8px',
      border: '1px solid #e9f7e9',
      backgroundColor: '#e9f7e9',
      color: '#19233f',
      fontWeight: 500,
      '&.Mui-expanded': {
        minHeight: '48px',
        borderRadius: '8px',
      },
    },
    content: {
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
  },
  MuiCollapse: {
    root: {
      backgroundColor: '#fafafa',
    },
  },
};

const commonTheme: Theme = {
  overrides,
  palette: {
    type: 'light', // use for dark theme switch
    primary: {
      main: primaryColor, // blueGrey[700],
      dark: primaryColorDark,
      // light: primaryColorLight,
    },
    // secondary: {
    //   // This is green.A700 as hex.
    //   // main: '#1a237edd', // red[500], '#cc4a4a'
    //   // dark: '#1a237eff',
    //   // main: amber[500],
    // },
  },
  shape: {
    borderRadius: global_borderRadius,
  },
  props: {
    MuiButtonBase: {
      // disableRipple: false, // No more ripple, on the whole application 💣
    },
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'p',
        body2: 'p',
      },
    },
  },
  // NOTE: FONTS
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    h1: {
      // fontSize: '1rem !important',
      // fontWeight: 500,
      // fontStyle: 'italic',
    },
    // h2: 'h2',
    // h3: 'h3',
    // h4: 'h4',
    // h5: 'h5',
    // h6: 'h6',
    // subtitle1: 'h6',
    // subtitle2: 'h6',
    // body1: 'p',
    // body2: 'p',

    // -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important
    // fontFamily: [
    //   '"Raleway", sans-serif',
    //   '"Montserrat", sans-serif',
    //   'Segoe UI',
    //   'Roboto',
    //   'Fira Sans',
    //   'Open Sans',
    // ].join(','),
    /* fontFamily: [
      '"Noto Sans JP", sans-serif',
      // '"Raleway", sans-serif',
      // '"Montserrat", sans-serif',
      // 'Segoe UI',
      'Roboto',
      'Fira Sans',
      'Open Sans',
    ].join(','), */
    // fontFamily: [ // gatsby defaults https://www.gatsbyjs.com/plugins/gatsby-theme-material-ui/
    //   'Montserrat',
    //   'sans-serif'
    // ].join(','),
  },
};

const coloredButtons = {
  error: {
    main: red[500],
    light: red[400],
    dark: red[600],
    contrastText: '#000',
  },
  info: {
    main: lightBlue[500],
    light: lightBlue[400],
    dark: lightBlue[600],
    contrastText: '#000',
  },
  process: {
    main: yellow[500],
    light: yellow[400],
    dark: yellow[600],
    contrastText: '#000',
  },
  success: {
    main: lightGreen[500],
    light: lightGreen[400],
    dark: lightGreen[600],
    contrastText: '#000',
  },
};

const lightTheme: Theme = {
  palette: {
    type: 'light', // use for dark theme switch
    primary: {
      // main: '#ffa500dd',
      // dark: '#ffa500ff',
      main: primaryColor,
      dark: primaryColorDark,
    },
    // common: {
    //   white: 'white',
    // },
    secondary: {
      // This is green.A700 as hex.
      main: green[100], // pink[500], // red[500], '#cc4a4a'
      dark: green[200], // pink[600],
    },
    // ...coloredButtons,
  },
};

const darkTheme: Theme = {
  palette: {
    type: 'dark', // use for dark theme switch
    primary: {
      // Purple and green play nicely together.
      // main: primaryColor, // blueGrey[700],
      // main: '#f50057dd',
      // dark: '#f50057ff',
      // main: pink[500],
      // dark: pink[600],
      main: primaryColor,
      dark: primaryColorDark,
    },
    secondary: {
      // This is green.A700 as hex.
      main: primaryColor, // '#ffa500dd', // red[500], '#cc4a4a'
      dark: primaryColorDark, // '#ffa500ff',
      // main: pink[500], // red[500], '#cc4a4a'
      // dark: pink[600],
    },
    // ...coloredButtons,
  },
  overrides: {
    /* MuiCssBaseline: {
      '@global': {
        ...cssGlobalBaseLine['@global'],
        body: {
          backgroundColor: '#000 !important',
          // overFlow: 'crop',import default from 'src\pages\test\component';
        },
      },
    }, */
    MuiTypography: {
      root: {
        color: '#fbfbfb',
      },
      button: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      root: {
        backgroundColor: '#010D1C',
        border: '1px solid #203467',
      },
      action: {
        alignItems: 'flex-start',
        marginRight: '0',
        marginTop: '4px',
      },
      standardInfo: {
        backgroundColor: '#010D1C',
        border: '1px solid #203467',
        color: '#fbfbfb',
      }
    },
    MuiPaper: {
      root: {
        // backgroundColor: '#fff', // this is default but gets in conflict with glassPaper => TODO: find best fallback solution for all devices
        // backgroundColor: '#ffffff77', // if this is used => fallback for non glass supporting browsers are needed
        backgroundColor: '#19233F',
        color: '#fbfbfb',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: '#19233F',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#19233F',
      },
    },
    MuiButton: {
      root: {
        minWidth: '32px',
        maxHeight: '56px',
        minHeight: '42px',
      },
      outlined: {
        backgroundColor: '#19233F',
      },
      outlinedSizeLarge: {
        padding: '14px 21px',
      },
      outlinedSizeSmall: {
        minHeight: '32px',
        borderRadius: '8px',
      },
    },
    MuiList: {
      root: {
        backgroundColor: '#0F1A36', // see further down @ issue of mui paper
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#f2f2f2',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#19233F',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#414C6B',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: primaryColor,
        },
      },
      notchedOutline: {
        borderColor: '#28324D',
        // backgroundColor: primaryColor,
        // color: primaryColor,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#bbb',
      },
      outlined: {
        transform: 'translate(14px, 20px) scale(1)', // translate(14px, 20px) scale(1) in original with larger input size (large vs medium)
        '&.MuiInputLabel-marginDense': {
          transform: 'translate(14px, 19px) scale(1)',
        },
      },
      shrink: {
        transform: 'translate(14px, -6px) scale(0.75) !important',
      },
    },
    MuiPaginationItem: {
      page: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        '&.Mui-disabled': {
          display: 'none',
        },
      },
    },
    MuiStepIcon: {
      root: {
        border: '1px solid #FBFBFB',
        borderRadius: '50%',
        color: 'transparent',
        width: '2rem',
        height: '2rem',
      },
      active: {
        border: 'none',
      },
      text: {
        fontWeight: 'bold',
        fill: '#FBFBFB',
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: '#1CCFB9',
      },
    },
    MuiDialog: {
      paper: {
        border: '2px solid #203467',
        backgroundColor: '#010D1C',
      }
    },
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(2px)',
        backgroundColor: '#19233F40',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '12px 24px 24px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 24px 12px',
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: '8px',
        '&:first-child': {
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        },
        '&:last-child': {
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        borderRadius: '8px',
        border: '1px solid #19233F',
        backgroundColor: '#19233F',
        color: '#fbfbfb',
        fontWeight: 500,
        '&.Mui-expanded': {
          minHeight: '48px',
          borderRadius: '8px',
        },
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiCollapse: {
      root: {
        backgroundColor: '#010D1C',
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        '&.Mui-selected': {
          backgroundColor: primaryColor,
          color: '#19233f',
          '&:hover': {
            backgroundColor: primaryColor,
          },
        },
      },
    },
  },
};

export const lightMuiTheme = createTheme({
  ...commonTheme,
  ...lightTheme,
});

export const darkMuiTheme = createTheme({
  ...commonTheme,
  ...darkTheme,
});
