import * as React from 'react';

import { Localized } from 'src/app/components';
import { BaseComponentProps, ShoppingCartItemConfiguration } from 'src/app/types';

const i18nPath = 'shop:screen-product';

export interface ShoppingCartConfigurationItemViewProps extends BaseComponentProps {
  itemCartData: ShoppingCartItemConfiguration;
}
// used in notifications (maybe reuse for other things)
export const ShoppingCartConfigurationItemView = (
  props: ShoppingCartConfigurationItemViewProps,
) => {
  const { itemCartData } = props;
  return (
    <div className='flex flex-col gap-y-2'>
      <div className='flex gap-4'>
          <div className='w-1/2'>
            <p className='text-p2'>
              <Localized
                dictKey={`${i18nPath}.add-cart-confirmation.price-group.label`}
              />
            </p>
          </div>
          <div className='w-1/2'>
            <p className='text-p2 text-right'>
              <Localized
                dictKey={`${i18nPath}.price-group.${
                  itemCartData?.priceGroupId || ''
                }.label`}
              />
              </p>
          </div>
      </div>
      <div className='flex gap-4'>
        <div className='w-1/2'>
          <p className='text-p2'>
            <Localized
              dictKey={`${i18nPath}.add-cart-confirmation.price-group.amount`}
            />
          </p>
        </div>
        <div className='w-1/2'>
          <p className='text-p2 text-right'>
            {itemCartData.count}
          </p>
        </div>
      </div>
    </div>
  );
};
