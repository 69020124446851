import { SourceCollectionName } from 'src/app/constants';

const systemName = 'holiday'; // TODO - source ?

export const getCurrentSystemName = () => systemName; // TODO
// FIXME: continentId + in FB.functions
export const getSystemSourceDocumentImageStoragePath = (
  documentCollectionName: SourceCollectionName,
  documentId: string,
): string => {
  if (!systemName || !documentCollectionName || !documentId) {
    return 'default__invalided__param____getSystemSourceDocumentImageStoragePath';
  }

  return `system/${systemName}/image/${documentCollectionName}/${documentId}`;
};

export const getSystemSourceCollectionPath = (
  documentCollectionName: SourceCollectionName,
): string => {
  if (!systemName || !documentCollectionName) {
    return 'default__invalided__param____getSystemSourceCollectionPath';
  }
  if (documentCollectionName === SourceCollectionName.PRODUCT) {
    return `${documentCollectionName}`;
  }
  // TODO: hacked in
  if (documentCollectionName === 'event') {
    return `${documentCollectionName}`;
  }
  // TODO: hacked in
  if (documentCollectionName === 'order') {
    return `${documentCollectionName}`;
  }
  if (documentCollectionName === SourceCollectionName.ORDERUSER) {
    return `${documentCollectionName}`;
  }
  return `system/${systemName}/${documentCollectionName}`;
};
