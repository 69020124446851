import * as React from 'react';
import * as yup from 'yup';
import CancelIcon from '@material-ui/icons/Block';

import {
  ButtonTw,
  DialogLayout,
  DropDown,
  TextInputField
} from 'src/app/components';
import { NotificationType, ProductQuestionType } from 'src/app/constants';
import { useStyles, useAppContext } from 'src/app/context';
// import { call } from 'src/api';
import { QuestionAboutProductDialogParams } from 'src/app/types';
import {
  sendProductInformationRequestMessage,
  SendProductInformationRequestMessageCallParams,
} from 'src/api/callables/common/sendProductInformationRequestMessage';
import { useAppStaticQueryConfig } from 'src/app/hooks';

const availableTopics = [
  ProductQuestionType.PICKUP_AND_MEETINGPOINT,
  ProductQuestionType.FOOD_AND_BEVERAGES,
  ProductQuestionType.OTHER,
];

const emailSchema = yup.string().email();

const isEmailValid = (val: string) => emailSchema.isValidSync(val);

export interface QuestionAboutProductDialogProps
  extends QuestionAboutProductDialogParams {
  children?: React.ReactNode;
  onCloseDialog: () => void;
}

// TODO: useForm and secure form input through app check

const QuestionAboutProductDialog = (props: QuestionAboutProductDialogProps) => {
  const { children, onCloseDialog, product, questionType } = props;
  const { createNotification, firebase } = useAppContext();
  const styles = useStyles();

  const [nameInput, setNameInput] = React.useState<string>('');
  const [emailInput, setEmailInput] = React.useState<string>('');
  const [phoneInput, setPhoneInput] = React.useState<string>('');
  const [messageInput, setMessageInput] = React.useState<string>('');
  const [selectedQuestionTypeIdx, setSelectedQuestionTypeIdx] = React.useState<number>(
    questionType ? availableTopics.findIndex((v) => v === questionType) : -1,
  );
  const [isUpdatingRequest, setUpdatingRequest] = React.useState<boolean>(false);
  const { env } = useAppStaticQueryConfig();

  const onSubmit = async () => {
    if (!firebase) {
      return;
    }
    try {
      setUpdatingRequest(true);
      const params: SendProductInformationRequestMessageCallParams = {
        systemId: 'holiday',
        organisationId: product.meta.organisationId || '',
        productId: product.id,
        topicType: availableTopics[selectedQuestionTypeIdx] || ProductQuestionType.OTHER,
        phoneNumber: phoneInput,
        name: nameInput,
        email: emailInput,
        body: messageInput,
      };

      await sendProductInformationRequestMessage(firebase, env, params);

      createNotification(
        NotificationType.SUCCESS,
        'Your request is sent to activity supplier',
      );
      onCloseDialog();
    } catch (e) {
      console.warn('Error: ', e);
      createNotification(NotificationType.ERROR, `Error: ${e?.message || ''}`);
    } finally {
      setUpdatingRequest(false);
    }
  };

  const isSubmitDisabled =
    isUpdatingRequest || !isEmailValid(emailInput) || !messageInput || !nameInput || !emailInput;

  return (
    <DialogLayout
      dialogDescription={'In which topic are you interested in'}
      dialogControls={
        <p className='text-center'>
          <ButtonTw
            disabled={isUpdatingRequest}
            onClick={onCloseDialog}
            variant={'text'}
            className='underline !font-semibold !text-accent-500 hover:!text-neutral-900 dark:hover:!text-neutral-50'
          >
            Cancel
          </ButtonTw>
        </p>
      }
    >
      <div className='flex flex-col gap-y-4'>
        <div>
          <DropDown
            disabled={!!questionType || isUpdatingRequest}
            // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
            //className={styles.dropDown__Button}
            variant={'outlined'}
            // color={isFilterOn() ? 'secondary' : 'primary'}
            size={'large'}
            label={
              <p>
                {!Number.isNaN(selectedQuestionTypeIdx) && selectedQuestionTypeIdx >= 0
                  ? availableTopics[selectedQuestionTypeIdx]
                  : 'Select a Topic'}
              </p>
            }
            placement={'top'}
            options={[
              ...availableTopics.map((topic) => (
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {topic}
                </span>
              )),
            ]}
            selectedIndex={selectedQuestionTypeIdx}
            onChange={(idx: number) => setSelectedQuestionTypeIdx(idx)}
            showArrowIcon={false}
            openOnHover
            fullWidth
          />
        </div>
        <div>
          <TextInputField
            disabled={isUpdatingRequest}
            fullWidth
            variant={'outlined'}
            placeholder={'John Smith'}
            label={'Name*'}
            onChange={(e) => setNameInput(e.target.value)}
          />
        </div>
        <div>
          <TextInputField
            disabled={isUpdatingRequest}
            fullWidth
            variant={'outlined'}
            placeholder={'example@example.com'}
            label={'Email*'}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </div>
        <div>
          <TextInputField
            disabled={isUpdatingRequest}
            fullWidth
            variant={'outlined'}
            placeholder={'+49 12345678'}
            label={'Phone'}
            onChange={(e) => setPhoneInput(e.target.value)}
          />
        </div>
        <div>
          <TextInputField
            disabled={isUpdatingRequest}
            fullWidth
            variant={'outlined'}
            multiline
            rows={6}
            placeholder={'Your question to the activity supplier'}
            label={'Your message*'}
            onChange={(e) => setMessageInput(e.target.value)}
          />
        </div>
        <div>
          <p className='text-p3'>
            {'Fields marked with * are mandatory'}
          </p>
        </div>
        <div className='text-center py-4'>
          <ButtonTw
            disabled={isSubmitDisabled}
            variant={'accent'}
            rounded
            size='lg'
            onClick={onSubmit}
          >
            Submit question
          </ButtonTw>
        </div>
      </div>
      {/* <ProductAddToCartContainer product={product} onSuccess={onCloseDialog} /> */}
      {children}
    </DialogLayout>
  );
};

export { QuestionAboutProductDialog };
