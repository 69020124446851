import * as React from 'react';
import {
  ShoppingCartContext,
  ShoppingCartItemDocumentData,
  ShoppingCartItemConfiguration,
  SourcedGQLProduct,
} from 'src/app/types';

import { Localized } from 'src/app/components';
// import { RemoveFromCartConfirmation } from 'src/app.consumer/components';
import {
  useAppContext,
  useShopFirebaseUpdateDocument,
  useLocalStorageCart,
} from 'src/app/hooks';
import { CartSystemType, NotificationType } from 'src/app/constants';
import { getFirestore, deleteDoc, doc, setDoc } from 'firebase/firestore';

import { areShoppingCartItemsParticipantsIncomplete } from 'src/lib/participant'; // TODO: provide as helper?

const i18nPath = 'shop:/shopping-cart';

const Context = React.createContext<ShoppingCartContext | null>(null);

export const useShoppingCartContext: () => ShoppingCartContext =
  (): ShoppingCartContext =>
    React.useContext<ShoppingCartContext | null>(Context) as ShoppingCartContext;

export const ShoppingCartProvider = ({
  cartItems = [],
  children,
  isCheckoutEnabled,
  operatingMode,
  sourceProducts,
}: {
  cartItems?: ShoppingCartItemDocumentData[];
  children: React.ReactNode;
  isCheckoutEnabled: boolean;
  operatingMode: CartSystemType;
  sourceProducts: SourcedGQLProduct[];
}): React.ReactElement | null => {
  // TODO: fetch the product document and verify seat count availability
  const { createStackedNotification, firebase, user, currencyId } = useAppContext();
  const { localCart, onLocalCartItemRemove, onLocalCartItemUpdate } =
    useLocalStorageCart(); // TODO: make API public
  const [isImporting, setIsImporting] = React.useState<boolean>(false);

  const uid = user?.uid || '';
  const collectionPath = `user/${uid}/cart`;

  const updateDocument = useShopFirebaseUpdateDocument<any>(
    collectionPath,
    'itemConfigurations',
  );

  const onCartItemRemove = async (documentId: string) => {
    const docRef = doc(getFirestore(firebase), `user/${uid}/cart/${documentId}`);
    return deleteDoc(docRef);
  };

  const onCartItemUpdate = async (
    documentId: string,
    data: ShoppingCartItemConfiguration[],
  ) => {
    return updateDocument(documentId, [...data]);
  };

  // TODO: maybe import to wishlist is  also an valid option
  const onImportLocalStorageCart = async () => {
    if (!firebase || !user?.uid) {
      return;
    }

    try {
      setIsImporting(true);
      localCart.cart.forEach(async (item) => {
        const docRef = doc(getFirestore(firebase), `${collectionPath}/${item.id}`);
        await setDoc(docRef, item);
        onLocalCartItemRemove(item.id);
        createStackedNotification(
          NotificationType.SUCCESS,
          <Localized
            dictKey={`${i18nPath}.action.transfer-local-cart-item.notification.success`}
            translationOptions={{ name: item.id || '' }}
          />,
        );
      });
    } catch (err) {
      createStackedNotification(NotificationType.ERROR, `Error ${err.message || ''}`);
    } finally {
      setIsImporting(false);
    }
  };

  // TODO: do this only once in effect and save res into state?
  let sourceProductsFiltered: SourcedGQLProduct[] = [];

  if (operatingMode === CartSystemType.LOCAL_STORAGE) {
    sourceProductsFiltered = localCart?.cart?.length
      ? ([
          ...localCart.cart.map((d) => sourceProducts.find((p) => p.id === d.productId)),
        ].filter((v) => Boolean(v)) as SourcedGQLProduct[])
      : [];
  }

  if (operatingMode === CartSystemType.CLOUD) {
    sourceProductsFiltered =
      cartItems && cartItems?.length
        ? ([
            ...cartItems.map((d) => sourceProducts.find((p) => p.id === d.productId)),
          ].filter((v) => Boolean(v)) as SourcedGQLProduct[])
        : [];
  }

  let areParticipantsValid = false;

  if (operatingMode === CartSystemType.LOCAL_STORAGE) {
    areParticipantsValid = false;
  }

  if (operatingMode === CartSystemType.CLOUD) {
    areParticipantsValid =
      !!(cartItems && cartItems.length > 0) &&
      !areShoppingCartItemsParticipantsIncomplete(
        cartItems,
        sourceProducts as SourcedGQLProduct[],
      );
  }

  const hasLocalCart = !!localCart?.cart?.length;

  return (
    <Context.Provider
      value={{
        areParticipantsValid,
        cartItems: operatingMode === CartSystemType.CLOUD ? cartItems : localCart.cart,
        currency: currencyId,
        hasLocalCart,
        isCheckoutEnabled: !!(isCheckoutEnabled && areParticipantsValid), // TODO: maybe needs improvement here
        isImporting,
        onImportLocalStorageCart,
        onCartItemRemove:
          operatingMode === CartSystemType.CLOUD
            ? onCartItemRemove
            : onLocalCartItemRemove,
        onCartItemUpdate:
          operatingMode === CartSystemType.CLOUD
            ? onCartItemUpdate
            : onLocalCartItemUpdate,
        operatingMode,
        sourceProducts: sourceProductsFiltered,
      }}
    >
      {children}
    </Context.Provider>
  );
};
