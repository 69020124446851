import * as React from 'react';

import { Grid } from 'src/app/components';

export interface FormFragmentContainerProps {
  children: React.ReactNode;
}

export const FormFragmentContainer = ({ children }: FormFragmentContainerProps) => {
  return (
    <>
      {children}
    </>
  );
};
