import * as React from 'react';

import { DialogType, BookingSystemType } from 'src/app/constants';
import {
  ConfirmationDialog,
  Dialog,
  DialogProps,
  GDPRDialog,
  Localized,
  LoginDialog,
  SignupDialog,
} from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

import { SystemAdminDialogType } from 'src/app.system/constants';
import { OrganisationAdminDialogType } from 'src/app.organisation/constants';

import { ChangeDocumentStateDialog } from 'src/admin/components';

import {
  AddToCartDialogParams,
  DocumentDeleteProps,
  ParticipantDialogParams,
  ParticipantsDialogParams,
  QuestionAboutProductDialogParams,
} from 'src/app/types';

import { getSystemDialogTitle, SystemDialogRouter } from 'src/app.system/dialogs';
import { SystemDialogParams } from 'src/app.system/types';

import { AddToCartDialog } from './addToCartDialog';
import { DebugDialog } from './debugDialog';
import { DocumentDeleteDialog } from './DocumentDeleteDialog';
import { LoadingDialog } from './loadingDialog';
import { ParticipantsDialog } from './participantsDialog';
import { QuestionAboutProductDialog } from './questionAboutProductDialog';
import { ParticipantDialog } from './participantDialog';
import { TransactionDialog } from './transactionDialog';

enum DialogContext {
  APP = 'app',
  ORG = 'org',
  SYS = 'sys',
}

export const AppDialogRouter = () => {
  const { closeDialog, dialogType, isDialogOpen, dialogData } = useAppContext();

  // console.info('!AppDialogRouter - isDialogOpen: ', isDialogOpen);
  // console.info('!AppDialogRouter - dialogType: ', dialogType);
  // console.info('!AppDialogRouter - dialogData: ', dialogData);
  if (!isDialogOpen) {
    return null;
  }

  let dialogContext: DialogContext = DialogContext.APP; // default => app context

  if (window && window.location.pathname.includes('/admin/system')) {
    dialogContext = DialogContext.SYS;
  }

  if (window && window.location.pathname.includes('/admin/partner')) {
    dialogContext = DialogContext.ORG;
  }

  // TODO: i18n + config mapping
  const dialogTitle = (context: DialogContext) => {
    if (context === DialogContext.APP) {
      switch (dialogType) {
        case DialogType.ADD_TO_CART: {
          const { product:product ,bookingSystemType:bookingSystemType } = dialogData as AddToCartDialogParams;
          let cartType=bookingSystemType;
          return cartType === BookingSystemType.PUBLIC ? (
            <Localized className='text-h4 font-semibold block' dictKey={'shop:screen-product.add-cart.title-public'} />
            ) : (
            <Localized className='text-h4 font-semibold block' dictKey={'shop:screen-product.add-cart.title-private'} />
          );
        }
        case DialogType.CONFIRMATION:
          return 'Confirmation';
        case DialogType.DEBUG:
          return 'Debug';
        case DialogType.GDPR:
          return 'Set your privacy settings';
        case DialogType.LOADING:
          return 'Loading...';
        case DialogType.LOGIN:
          return (<span className='text-h4 font-semibold text-center block'>Log in on Hey Holiday</span>);
        case DialogType.PARTICIPANT_EDIT:
          return 'Edit participant';
        case DialogType.PARTICIPANT_LIST:
          return 'Modify participant list';
        case DialogType.SIGNUP:
          return (<span className='text-h4 font-semibold text-center block'>Sign up on Hey Holiday</span>);
        case DialogType.QUESTION_ABOUT_PRODUCT:
          return 'Ask a question about a product';
        default:
          return '';
      }
    }
    if (context === DialogContext.ORG) {
      switch (dialogType) {
        case DialogType.DEBUG:
          return 'Debug';
        case DialogType.LOADING:
          return 'Loading...';
        case OrganisationAdminDialogType.create: // TODO: remove
          return 'Create Document';
        case OrganisationAdminDialogType.publish:
          return (
            <Localized dictKey={'organisation:dialogs.product-publish-dialog.title'} />
          );
        case OrganisationAdminDialogType.delete: // TODO: remove
          return 'Delete Product';
        case OrganisationAdminDialogType.edit: // TODO: remove
          return 'Edit Product';
        default:
          return '';
      }
    }
    if (context === DialogContext.SYS) {
      switch (dialogType) {
        case DialogType.CONFIRMATION:
          return 'Confirmation';
        case DialogType.DEBUG:
          return 'Debug';
        case DialogType.LOADING:
          return 'Loading...';
        case DialogType.SYSTEM:
          return getSystemDialogTitle(
            (dialogData as SystemDialogParams).systemDialogType,
          ); // TODO: must be composed from inner params systemDialogType eg: SystemAdminDialogType.UPDATE_ORGANISATION_PRODUCT_CATEGORY_TYPES
        // FIXME: on this level we use only the app wide DialogType
        case SystemAdminDialogType.DOCUMENT_DELETE:
          return 'Delete system document';
        // FIXME: on this level we use only the app wide DialogType
        case SystemAdminDialogType.create:
          return 'Create System Document';
        default:
          return '';
      }
    }
    return '';
  };

  // has click out (on backdrop) functionality, but no "cancel" button
  const dialogConfigClickAway: DialogProps = {
    title: dialogTitle(dialogContext),
    onClose: () => closeDialog(),
    isOpen: true,
    // showBackdrop: true, // no back drop
    showCloseButtonBottom: false,
    showCloseButtonTop: true,
  };

  // the "cancel" button needs to be provided explicitly inside of the modal
  const dialogConfigStrictConfirm: DialogProps = {
    ...dialogConfigClickAway,
    showCloseButtonBottom: false,
    showCloseButtonTop: false,
    onClose: () => null, // NOOP
    showBackdrop: true,
  };

  const dialogConfigFlexWidth: Omit<
    DialogProps,
    'isOpen' | 'onClose' | 'showBackdrop' | 'title'
  > = {
    maxWidth: false,
  };
  const dialogConfigFlexDetailWidth: Omit<
    DialogProps,
    'isOpen' | 'onClose' | 'showBackdrop' | 'title'
  > = {
    maxWidth: 'md',
  };

  const getDialogConfig = (context: DialogContext) => {
    if (context === DialogContext.APP) {
      switch (dialogType) {
        case DialogType.ADD_TO_CART:
          return dialogConfigStrictConfirm;
        case DialogType.CONFIRMATION:
          return dialogConfigStrictConfirm;
        case DialogType.DEBUG:
          return dialogConfigStrictConfirm;
        case DialogType.GDPR:
          return dialogConfigStrictConfirm;
        case DialogType.LOADING:
          return dialogConfigStrictConfirm;
        case DialogType.LOGIN:
          return dialogConfigClickAway;
        case DialogType.PARTICIPANT_EDIT:
          return dialogConfigStrictConfirm;
        case DialogType.PARTICIPANT_LIST:
          return {
            ...dialogConfigStrictConfirm,
            ...dialogConfigFlexWidth,
          } as DialogProps;
        case DialogType.SIGNUP:
          return dialogConfigClickAway;
        case DialogType.QUESTION_ABOUT_PRODUCT:
          return dialogConfigStrictConfirm;
          case DialogType.TRANSACTION_DETAIL:
            return {
              ...dialogConfigStrictConfirm,
              ...dialogConfigFlexDetailWidth,
            } as DialogProps;
        default:
          return dialogConfigClickAway;
      }
    }
    if (context === DialogContext.ORG) {
      switch (dialogType) {
        case DialogType.DEBUG:
        case DialogType.LOADING:
        case OrganisationAdminDialogType.publish:
          return dialogConfigStrictConfirm;
        default:
          return dialogConfigStrictConfirm;
      }
    }
    if (context === DialogContext.SYS) {
      switch (dialogType) {
        case DialogType.DEBUG:
        case DialogType.CONFIRMATION:
        case SystemAdminDialogType.DOCUMENT_DELETE: // FIXME: on this level we use only the app wide DialogType
        case DialogType.LOADING:
        case DialogType.SYSTEM:
          return dialogConfigStrictConfirm;
        default:
          return dialogConfigClickAway;
      }
    }
    return dialogConfigStrictConfirm;
  };

  const renderDialogContent = (context: DialogContext) => {
    // console.info('! rendering context ', context);
    // console.info('! rendering app dialog type: ', dialogType);
    if (context === DialogContext.APP) {
      switch (dialogType) {
        case DialogType.ADD_TO_CART:
          return (
            <AddToCartDialog
              {...(dialogData as AddToCartDialogParams)}
              onCloseDialog={closeDialog}
            />
          );
        case DialogType.CONFIRMATION:
          return <ConfirmationDialog />;
        case DialogType.DEBUG:
          return <DebugDialog onCloseDialog={closeDialog} />;
        case DialogType.GDPR:
          return <GDPRDialog />;
        case DialogType.LOADING:
          return <LoadingDialog onCloseDialog={closeDialog} />;
        case DialogType.LOGIN:
          return <LoginDialog />;
        case DialogType.PARTICIPANT_EDIT:
          return (
            <ParticipantDialog
              {...(dialogData as ParticipantDialogParams)}
              // onCloseDialog={closeDialog}
            />
          );
        case DialogType.PARTICIPANT_LIST: {
          const { file, onImportData, schema, pickupLocations, priceSystem } =
            dialogData as ParticipantsDialogParams;
          return (
            <ParticipantsDialog
              onCloseDialog={closeDialog}
              file={file}
              onImportData={onImportData}
              pickupLocations={pickupLocations}
              priceSystem={priceSystem}
              schema={schema}
            />
          );
        }
        case DialogType.SIGNUP:
          return <SignupDialog />;
        case DialogType.QUESTION_ABOUT_PRODUCT: {
          const { product, questionType } =
            dialogData as QuestionAboutProductDialogParams;
          return (
            <QuestionAboutProductDialog
              onCloseDialog={closeDialog}
              product={product}
              questionType={questionType}
            />
          );
        }
        case DialogType.TRANSACTION_DETAIL: {
          const { item ,orderItem} =dialogData ;
          console.log(item);
          return (
            <TransactionDialog
              onCloseDialog={closeDialog}
              item={item}
              order={orderItem}
            />
          );
        }
        default:
          return null;
      }
    }
    if (context === DialogContext.ORG) {
      switch (dialogType) {
        case DialogType.DEBUG:
          return <DebugDialog onCloseDialog={closeDialog} />;
        case DialogType.LOADING:
          return <LoadingDialog onCloseDialog={closeDialog} />;
        case OrganisationAdminDialogType.publish: {
          // TODO: too specific? - other differentiation needed
          const publishDialogData = dialogData as {
            documentId: string;
            documentPath: string;
            onCloseDialog: () => void;
          };
          return (
            <ChangeDocumentStateDialog
              onCloseDialog={closeDialog}
              documentId={publishDialogData.documentId}
              documentCollectionPath={publishDialogData.documentPath}
              onCloseDialog={publishDialogData.onCloseDialog}
            />
          );
        }
        // case OrganisationAdminDialogType.delete:
        //   return <DeleteProductDialog onCloseDialog={closeDialog} />;
        // case OrganisationAdminDialogType.edit:
        //   return <EditProductDialog onCloseDialog={closeDialog} />;
        default:
          return null;
      }
    }

    if (context === DialogContext.SYS) {
      switch (dialogType) {
        case DialogType.CONFIRMATION:
          return <ConfirmationDialog />;
        case DialogType.DEBUG:
          return <DebugDialog onCloseDialog={closeDialog} />;
        case DialogType.LOADING:
          return <LoadingDialog onCloseDialog={closeDialog} />;
        case DialogType.SYSTEM:
          return <SystemDialogRouter {...(dialogData as SystemDialogParams)} />;
        // FIXME: on this level we use only the app wide DialogType
        case SystemAdminDialogType.DOCUMENT_DELETE: {
          const deleteDialogData = dialogData as DocumentDeleteProps;
          return (
            <DocumentDeleteDialog
              onCloseDialog={closeDialog}
              // documentId={dialogData.documentId}
              // documentPath={dialogData.documentCollectionPath}
              {...deleteDialogData}
            />
          );
        }
        // case SystemAdminDialogType.create:
        //   return <CreateSystemDocumentDialog />; // removed due to shitty maintenance
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <Dialog {...getDialogConfig(dialogContext)}>
      {renderDialogContent(dialogContext)}
    </Dialog>
  );
};
