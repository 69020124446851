import * as React from 'react';
import { navigate } from 'gatsby';

import { useAppContext } from 'src/app/hooks';

import { Button, Grid, LoadingIndicator, Typography } from 'src/app/components';

import { NotificationType } from 'src/app/constants';
import { applyActionCode, getAuth } from 'firebase/auth';

interface EmailConfirmationFormProps {
  apiKey: string; // not used ATM - how to verify?
  code: string; // the FB code
  lang: string; // is supported but currently not used to change language based on this provided value from FB
}

// TODO: i18n
export const EmailConfirmationForm = ({ code }: EmailConfirmationFormProps) => {
  const { createNotification, firebase } = useAppContext();

  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

  const onSubmitButtonClick = async () => {
    if (!firebase) {
      return;
    }

    const auth = getAuth(firebase);

    if (auth.currentUser?.emailVerified) {
      navigate('/');
    }

    if (!code) {
      createNotification(NotificationType.ERROR, 'Invalid params', 5000);
      return;
    }

    setIsRequesting(true);

    try {
      await applyActionCode(auth, code);

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Email successfully confirmed', 5000);
      navigate('/');
    } catch (err) {
      console.warn('Email confirm error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsRequesting(false);
    }
  };

  if (isRequesting) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{'Confirm your email'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color={'secondary'} onClick={onSubmitButtonClick}>
              <Typography>Confirm email</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
