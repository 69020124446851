import * as React from 'react';

import {
  ButtonTw,
  Localized,
  LocalizedLink,
  // LoginFormContainer,
  // SocialButtons,
} from 'src/app/components';
import { DialogType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

import { LoginFormContainer } from './LoginFormContainer';

// import { LoginForm } from './LoginForm';

export interface LoginDialogProps extends BaseComponentProps {
  children?: React.ReactNode;
}

/**
 * LoginDialog / WAPLoginDialog
 * @param { LoginDialogProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoginDialog = (props: LoginDialogProps) => {
  const { children } = props;
  const { closeDialog, openDialog } = useAppContext();
  // const cls = useStyles();

  const onDisplaySignupDialog = () => {
    openDialog(DialogType.SIGNUP);
  };

  return (
    <div className='md:px-6'>
      <div className='text-center'>
        <h3>
          <Localized
            dictKey={`account:screen.screen-account-${'login'}.screen subtitle`}
          />
        </h3>
        <p>
          <Localized
            dictKey={`account:screen.screen-account-${'login'}.screen description`}
          />
        </p>
      </div>

      {/* <SocialButtons compType={'login'} /> */}

      <LoginFormContainer>
        <div className='flex flex-wrap items-center justify-center gap-2 py-8'>
            <Localized
              dictKey={`account:screen.screen-account-${'login'}.rememberMe.label`}
            />
          <LocalizedLink onClick={closeDialog} href={'/lost-password'} className='!text-p2 underline !text-accent-500'>
            <Localized
              dictKey={`account:screen.screen-account-${'login'}.forgotPassword.label`}
            />
          </LocalizedLink>
        </div>
      </LoginFormContainer>
      <p className='flex flex-wrap items-center justify-center gap-2 pt-6 pb-12'>
        <Localized
          dictKey={`account:screen.screen-account-${'signup'}.nav description`}
        />
        <ButtonTw
          variant='text'
          onClick={() => onDisplaySignupDialog()}
          className='underline !text-accent-500'
        >
          <Localized
            dictKey={`account:screen.screen-account-${'signup'}.nav label`}
          />
        </ButtonTw>
      </p>
      <div>
        {children}
      </div>
    </div>
  );
};

const WAPLoginDialog = (props: CombinedAppProps<LoginDialogProps>) =>
  withoutAppProps<CombinedAppProps<LoginDialogProps>, LoginDialogProps>(
    LoginDialog,
    props,
  );

export { LoginDialog, WAPLoginDialog };
