import * as React from 'react';

import { ButtonTw, Localized } from 'src/app/components';
import { AppLocale, ProductCategoryType } from 'src/app/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BaseProduct, Price, ShoppingCartItemDocumentData } from 'src/app/types';
import { useLocalizationContext, useLocalizedNavigation } from 'src/app/hooks';
import { calculateTotalPrice, getCartItemsTotalPrices } from 'src/lib/price';
import { WishlistDocumentData } from './wishlist.types';

// const { navigateLocalized } = useLocalizedNavigation();

interface WishlistItemContentProps<T extends ProductCategoryType> {
  wishlistDocument: WishlistDocumentData; // <T> ??
  onAddItemToCart: () => void;
  onCartItemRemove: (itemDocId: string) => void;
  onOpenAdditionalInformation: () => void;
  onShareButtonClick: () => void;
  product: BaseProduct<T>;
}

// TODO: Reuse common product data destructuring funcs from product view
const WishlistItemContent = <T extends ProductCategoryType>(
  props: WishlistItemContentProps<T>,
) => {
  const {
    wishlistDocument,
    onAddItemToCart,
    onCartItemRemove,
    onOpenAdditionalInformation,
    onShareButtonClick,
    product,
  } = props;
  const { exactDuration, id, localizations, productPriceSystems } = product;
  const { navigateLocalized } = useLocalizedNavigation();

  const i18nPath = 'shop:/shopping-cart';
  const currencyId = 'EUR';
  const { language } = useLocalizationContext();

  // const totalPrices: Price[] = getCartItemsTotalPrices([wishlistDocument], [product]);
  // const totalPrice = calculateTotalPrice(totalPrices);

  const totalPrice = {
    integer: 0,
    decimal: 0,
  };

  // const isPriceValid = !(
  //   Number.isNaN(totalPrice.integer) ||
  //   Number.isNaN(totalPrice.decimal) ||
  //   totalPrice.integer < 0 ||
  //   totalPrice.decimal < 0
  // ); // FIXME: better validation needed

  const price = 0; // productPriceSystems?[0]?.publicSettings?.productBasePrice || ''; // TODO:
  const hasDiscount = true; // FIXME show discounts explicitly
  const priceBeforeDiscount = price * 2 || ''; // TODO: dummy

  const plocales =
    localizations?.find((locale) => locale.localeId === language) ||
    localizations?.find((locale) => locale.localeId === 'en'); // Fallback to en locales

  const onNavigateToProduct = () => {
    navigateLocalized(`/experience/${id}`);
  };

  return (
    <>
      <div className='flex flex-col h-full lg:flex-row md:gap-8'>
        <div className='flex flex-1 flex-col text-left lg:w-7/12 xl:w-8/12'>
          <h2 class="text-p1 font-semibold md:text-h3 md:font-bold">{plocales?.locales?.name ?? ''}</h2>
          <h3 className='text-p2 md:text-p1 md:font-medium'>
            {plocales?.locales?.teaser ?? ''}
          </h3>
        </div>
        <div className='flex flex-col gap-4 items-start py-2 lg:justify-end lg:items-end lg:w-5/12 xl:w-4/12'>
            {/* <Grid item xs={12}>
              <Typography variant={'body2'} align={'right'}>
                From
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {hasDiscount ? (
                <Typography variant={'body2'} align={'right'}>
                  <strike>€ {priceBeforeDiscount}</strike>
                </Typography>
              ) : (
                ''
              )}
            </Grid> */}
            <div className='flex items-center md:flex-col gap-1 md:gap-0'>
              <span className='text-p3'>
                total:
              </span>
              <span className='text-p3 font-bold md:text-h2'>
                <Localized
                  dictKey={totalPrice.decimal===0?`${i18nPath}.cart.total-price-integer`:`${i18nPath}.cart.total-price`}
                  translationOptions={{ currencyId, price: totalPrice }}
                />
              </span>
            </div>
            <div className='flex gap-6 items-center justify-center'>
            <ButtonTw
              variant={'text-underline'}
              onClick={onAddItemToCart}
            >
              Share
            </ButtonTw>
            <ButtonTw
              variant={'accent'}
              rounded
              size='lg'
              onClick={onAddItemToCart}
            >
              Add to cart
            </ButtonTw>
          </div>
        </div>
      </div>
      <div>
        <div className='flex gap-2'>
          <ButtonTw
            rounded
            size='sm'
            variant={'primary'}
            onClick={onNavigateToProduct}
          >
            Productpage
          </ButtonTw>
          <ButtonTw
            rounded
            size='sm'
            variant={'primary'}
            onClick={onOpenAdditionalInformation}
          >
            Info
            <ExpandMoreIcon />
          </ButtonTw>
          <ButtonTw
            rounded
            size='sm'
            variant={'primary'}
            onClick={() => onCartItemRemove(wishlistDocument.id)}
          >
            Delete
          </ButtonTw>
        </div>
      </div>
    </>
  );
};

export { WishlistItemContent };
