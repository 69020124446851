import * as React from 'react';
import { ButtonTw } from 'src/app/components';
import { navigate } from 'gatsby';

import { MessageCollection, MessageType } from 'src/app/constants';

interface MessagesRootScreenProps {
  messageCollectionsWriteOptions: MessageCollection[];
  messageTypeOptions: MessageType[];
  routeBasePath: string;
}

export const MessagesRootScreen = ({
  messageCollectionsWriteOptions,
  messageTypeOptions,
  routeBasePath,
}: MessagesRootScreenProps) => {
  return (
    <>
      <div className='flex flex-wrap gap-2'>
        {messageTypeOptions.map((type) => {
          return (
            <div key={type}>
              <ButtonTw
                // variant={'outlined'}
                variant={'accent'}
                rounded
                size='lg'
                onClick={() => navigate(`${routeBasePath}/${type}`)}
              >
                {/** //FIXME: i18n */}
                Open {type} messages
              </ButtonTw>
            </div>
          );
        })}
        {messageCollectionsWriteOptions.map((collection) => {
          return (
            <div key={collection}>
              <ButtonTw
                // variant={'outlined'}
                variant={'accent'}
                rounded
                size='lg'
                onClick={() => navigate(`${routeBasePath}/${collection}`)}
              >
                {/** //FIXME: i18n */}
                Write new {collection} message
              </ButtonTw>
            </div>
          );
        })}
        {/** TODO: create new messages?? */}
      </div>
    </>
  );
};
