import * as React from 'react';
import { BrowserPluginArgs, WrapRootElementBrowserArgs } from 'gatsby';
import { StyleContextProvider } from 'src/app/context';

import { AppContext } from 'src/app.consumer/components';

// TODO: extract firebase & localization provider out of "AppContext" => use only AppContextProvider directly
// TODO: rename Provider
const WrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return (
    <AppContext>
      <StyleContextProvider>{element}</StyleContextProvider>
    </AppContext>
  );
};

export default WrapRootElement;
