import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import { Localized } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';
import { useI18nArray } from 'src/app/hooks';

import { FoodOptionsInformation } from './FoodOptionsInformation';

import Check from '@material-ui/icons/Check';

export interface ProductStaticFeaturesProps {
  product: SourcedGQLProduct;
}

export const ProductStaticFeatures = ({ product }: ProductStaticFeaturesProps) => {
  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;
  const i18nPath = 'product-information.static-features';

  const { i18nItems } = useI18nArray<{ title: string; description: string }>(
    `${i18nPrefix}.${i18nPath}`,
    ['description', 'title'],
  );

  return (
    <div className='mb-6 pt-3 md:mb-0 md:pt-0'>
      <ul className='list-none flex flex-col gap-2 md:gap-5'>
        {i18nItems.map(({ description, title }, idx) => (
          <li key={`product-infobox-${idx}`} className='text-left'>
            <p className='text-p2 font-normal relative pl-8'>
              <span className='text-accent-500 absolute left-0 top-0'>
                <Check />
              </span>
              {title}
            </p>
            <p className='text-p3 font-light pl-8'>
              {description || ''}
            </p>
          </li>
        ))}
        {product.priceSystems[0].privateBooking.featureEnabled ? (
          <li className=''>
            <p className='text-p2 relative pl-8'>
              <span className='text-accent-500 absolute left-0 top-0'>
                <Check />
              </span>
              <Localized
                dictKey={`${i18nPrefix}.product-information.private-booking.title`}
              />
            </p>
            <p className='text-p3 font-light pl-8'>
              <Localized
                dictKey={`${i18nPrefix}.product-information.private-booking.description`}
              />
            </p>
          </li>
        ) : null}
        {/* {product?.foodOptions?.featureEnabled ? (
          <li>
            <FoodOptionsInformation
              i18nPrefix={i18nPrefix}
              priceSystemFoodOptions={product.foodOptions} // TODO: handle multiple pricesystems
            />
          </li>
        ) : null} */}
      </ul>
    </div>
  );
};
