import { RegisterOptions } from 'react-hook-form';

export const isRequired = (warnText?: string) =>
  ({ required: warnText || 'This field is required' } as RegisterOptions);

// TODO: localize
export const hasMinLength = (min: number, warnText?: string) =>
  ({
    minLength: { value: min || 0, message: `Min length (${min}) not met...` },
  } as RegisterOptions);
export const hasMaxLength = (max: number, warnText?: string) =>
  ({
    maxLength: { value: max || 0, message: `Max length (${max}) exceeded...` },
  } as RegisterOptions);
