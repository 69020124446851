import * as React from 'react';

import FindInPageIcon from '@material-ui/icons/FindInPage';

import { ButtonTw } from 'src/app/components';

import { useAppContext } from 'src/app/context';
import { DialogType } from 'src/app/constants';

interface ButtonDebugMenuProps {}

export const ButtonDebugMenu = (props: ButtonDebugMenuProps) => {
  const { openDialog } = useAppContext();

  return (   
    <ButtonTw
      size={'lg'}
      onClick={async () => {
        openDialog(DialogType.DEBUG);
      }}
      variant={'primary'}      
    >
      Open Debug Menu <FindInPageIcon  />
    </ButtonTw>      
  );
};
