import * as React from 'react';
import { SystemAdminDialogType } from 'src/app.system/constants';
import {
  SystemDialogParams,
  SystemDialog_UpdateOrganisationCategoryTypesParams,
} from 'src/app.system/types';

import { UpdateOrganisationProductCategoryTypesDialog } from './updateOrganisationProductCategoryTypes';

export const SystemDialogRouter = ({
  systemDialogType,
  ...restProps
}: SystemDialogParams) => {
  switch (systemDialogType) {
    case SystemAdminDialogType.UPDATE_ORGANISATION_PRODUCT_CATEGORY_TYPES:
      return (
        <UpdateOrganisationProductCategoryTypesDialog
          {...(restProps as SystemDialog_UpdateOrganisationCategoryTypesParams)}
        />
      );
    default:
      return null;
  }
};
