import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
// import IconButton from '@material-ui/core/IconButton';

import {
  CenteredContainer,
  Checkbox,
  Divider,
  Grid,
  Localized,
  TextInputField,
  Typography,
} from 'src/app/components';

import { Switch } from '@material-ui/core';
import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FormFieldLayout } from '../Layouts';

import { FormFieldProps, NestedFieldProps } from '../form.types';
import { FieldStyle } from '../form.constants';

export interface FormBooleanFieldProps {
  // isPassword?: boolean;
  // defaultValue: boolean;
}

const FormBooleanField = ({
  children,
  fieldStyle,
  i18nKey,
  name,
  rules,
  defaultValue,
  ...passProps
}: FormFieldProps<boolean> &
  FormBooleanFieldProps & { checkbox: boolean | undefined }) => {
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
  } = formMethods;

  // let controllerArgs = {
  //   name,
  //   control,
  //   rules,
  //   defaultValue,
  // };

  // if (name === 'localizations.0.locales.name') {
  //   console.warn('localizations.0.locales.name määäh');
  //   controllerArgs = {
  //     name: 'RHF__arrayItemKeyName_localizations.0.locales.name',
  //     control,
  //     rules,
  //     defaultValue,
  //   };
  // }

  const controllerArgs = {
    name,
    control,
    rules,
    defaultValue,
  };

  // console.warn('controllerArgs: ', controllerArgs);

  const controller = useController(controllerArgs);
  const hasError = !!errors?.[name]; // TODO:

  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = controller;

  // console.warn('controller: ', controller);

  // return null;
  const { checkbox, ...cleanPassProps } = passProps;

  console.log(`bool ${name} inputProps: `, inputProps);
  return checkbox ? (
    <FormFieldLayout
      additionalBottomArea={
        !fieldStyle || (fieldStyle && fieldStyle === FieldStyle.FORM) ? (
          <FieldInformation
            hasError={hasError}
            description={<Localized dictKey={`${i18nKey}.field.description`} />}
            example={<Localized dictKey={`${i18nKey}.field.example`} />}
          >
            {children}
          </FieldInformation>
        ) : null
      }
      debugId={name}
      debugI18nKey={i18nKey}
      fieldStyle={fieldStyle}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <TextInputField
          label={<Localized dictKey={`${i18nKey}.field.name`} />}
          type={'checkbox'}
          variant={'outlined'}
          {...inputProps}
          {...cleanPassProps}
          value={inputProps.value}
          inputRef={ref}
          error={hasError}
          fullWidth
          // helperText={<div style={{ backgroundColor: 'red' }}>Heeeelp</div>}
          // helperText={'Heeeelp'}
          // render={(bfcProps) => (
          //   <div>
          //     <Checkbox
          //       onChange={(e) => bfcProps.onChange(e.target.checked)}
          //       checked={bfcProps.value}
          //     />
          //   </div>
          // )}
          InputProps={{
            // for custom inputs => TODO: pass as props
            inputComponent: Checkbox,
            inputProps: { ...inputProps, checked: inputProps?.value || false },
            endAdornment: <FieldAdornment hasError={hasError} />,
          }}
        />
      </FieldInputContainer>
    </FormFieldLayout>
  ) : (
    <FormFieldLayout
      debugId={name}
      debugI18nKey={i18nKey}
      fieldStyle={FieldStyle.CLEAN}
      hasError={hasError}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography align={'left'} centerV style={{ fontWeight: 'bold' }}>
            <Localized dictKey={`${i18nKey}.field.name`} />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
              >
                <CenteredContainer>
                  <Typography
                    align={'right'}
                    style={inputProps.value ? { color: 'gray' } : {}}
                  >
                    NO
                  </Typography>
                </CenteredContainer>
                <CenteredContainer>
                  <Switch checked={inputProps.value} onChange={inputProps.onChange} />
                </CenteredContainer>
                <CenteredContainer>
                  <Typography
                    align={'right'}
                    style={!inputProps.value ? { color: 'gray' } : {}}
                  >
                    YES
                  </Typography>
                </CenteredContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0px' }}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {!fieldStyle || (fieldStyle && fieldStyle === FieldStyle.FORM) ? (
            <FieldInformation
              hasError={hasError}
              description={<Localized dictKey={`${i18nKey}.field.description`} />}
              example={<Localized dictKey={`${i18nKey}.field.example`} />}
            ></FieldInformation>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </FormFieldLayout>
  );
};

// updatemode ?
// if is "update" mode render different version?
// all versions have to be passed then (create(form)/update(form/singleFields))
// update involves whole documents or /(and) single fields on documents, maybe even nested ones...
export const BooleanField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps<boolean> &
  FormBooleanFieldProps & { checkbox: boolean | undefined }) => (
  <FormBooleanField
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormBooleanField>
);
