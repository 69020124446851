import * as React from 'react';
import WishlistIcon from '@material-ui/icons/FavoriteBorder'; // TODO find one
import { ButtonTw } from 'src/app/components';
import { ContentNavigationType, SourcedGQLProduct } from 'src/app/types';
import { GatsbyNodeType } from 'src/app/constants';

export interface ItemCardTopAreaProps {
  onWishlistButtonClick?: (id: string) => void;
  item: ContentNavigationType;
  itemType: GatsbyNodeType;
  reviewAnchorId: string;
}

export const ItemCardTopArea = (props: ItemCardTopAreaProps) => {
  const { onWishlistButtonClick, item, itemType, reviewAnchorId } = props;
  const navigationData = {
    continentId: item.continentId || '',
    countryId: item.countryId || '',
    countryAreaId: item.countryAreaId || '',
    cityId: item.cityId || '',
    cityAreaId: item.cityAreaId || '',
  };
  if (itemType === GatsbyNodeType.CONTINENT) {
    navigationData.continentId = item.id;
  }
  if (itemType === GatsbyNodeType.COUNTRY) {
    navigationData.countryId = item.id;
  }
  if (itemType === GatsbyNodeType.COUNTRY_AREA) {
    navigationData.countryAreaId = item.id;
  }
  if (itemType === GatsbyNodeType.CITY) {
    navigationData.cityId = item.id;
  }
  if (itemType === GatsbyNodeType.CITY_AREA) {
    navigationData.cityAreaId = item.id;
  }

  const productItem = item as any as SourcedGQLProduct;
  return (
    <>
      {/* <Glass
        borderRadius={`0 ${global_borderRadius}px ${global_borderRadius} px 0`}
        blurAmount={2}
        opacity={0.2}
        className={clsx(
          styles.productCardTopArea__bcGlass,
          !isWiderMD ? styles.w_min_64px : '',
          styles.h__min__58px,
        )}
      >
        {itemType === GatsbyNodeType.PRODUCT ? (
          <>
            <Breadcrumbs
              continentId={productItem.location.continentId}
              countryId={productItem.location.countryId}
              countryAreaId={productItem.location.countryAreaId}
              cityId={productItem.location.cityId}
              cityAreaId={productItem.location.cityAreaId}
            />
            {isWiderMD && item.productData?.rating !== undefined ? (
              <ProductRating
                reviewAnchorId={reviewAnchorId}
                product={item}
                direction={'row'}
                // color={'#fff'}
                className={styles.px__2}
                style={{ minWidth: '200px', color: '#fff' }}
              />
            ) : null}
          </>
        ) : (
          <Breadcrumbs
            continentId={navigationData.continentId}
            countryId={navigationData.countryId}
            countryAreaId={navigationData.countryAreaId}
            cityId={navigationData.cityId}
            cityAreaId={navigationData.cityAreaId}
          />
        )}
      </Glass> */}
      {onWishlistButtonClick ? (        
        <ButtonTw
          variant={'text'}
          onClick={() => onWishlistButtonClick(item.id)}
        >
          <span className='hidden md:inline mr-2'>Add to Wishlist</span>
          <WishlistIcon className='text-neutral-50 md:text-neutral-900 md:dark:text-neutral-50' />
        </ButtonTw>        
      ) : null}
    </>
  );
};
