import * as React from 'react';
import { getApp } from 'firebase/app';
import merge from 'deepmerge';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';
import { useLocation } from '@reach/router';

import i18n, { Resource } from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { supportedLanguages, localeResources, nameSpaces, defaultNS } from 'src/locales';
import { AppLocale } from 'src/app/constants';
import { LocaleNamespaces } from 'src/app/types';
import CallableAPI from 'src/api/callables';
import { useAppStaticQueryConfig } from 'src/app/hooks';
import LocalizationContext from './localizationContext';
import { LocalizationState } from './localizationContext.types';

const isI18nDebugMode = false; //  TODO: via .env sourcing
const defaultLanguage: AppLocale = AppLocale.EN;

const languageCookieName = 'language';

const i18nBaseConfig = {
  resources: null,
  fallbackLng: defaultLanguage,
  lng: defaultLanguage,
  ns: [...nameSpaces],
  defaultNS,
  debug: isI18nDebugMode,
  keySeparator: '.', // we do not use keys in form messages.welcome
  supportedLngs: supportedLanguages,
  interpolation: {
    escapeValue: false, // not needed for react - already protects from xss
  },
  react: {
    // wait: true,
    useSuspense: true,
  },
};

const overwriteMerge = (destinationArray: any[], sourceArray: any[], options: any) =>
  sourceArray;

// FIXME: create fetch current locales effect + trigger mechanism

// TODO. get lang from url /en/ ?
function GetLocalizationState(isI18nReady: boolean): LocalizationState {
  const { pathname } = useLocation();
  const { env } = useAppStaticQueryConfig();
  const [cookies, setCookie] = useCookies([languageCookieName]);
  const [language, setLanguage] = React.useState<AppLocale | null>(
    cookies[languageCookieName] || null,
  );
  // const language = cookies?.[languageCookieName] ?? null;

  let pathWithoutLocales = '';
  let isIndexPage = false;
  let isAdminPage = false;
  if (typeof window !== 'undefined') {
    const elements = pathname.split('/').filter((e, idx) => Boolean(e) && idx > 1);
    // const elements = window.location.pathname
    //   .split('/')
    //   .filter((e, idx) => Boolean(e) && idx > 1);

    isIndexPage = !elements.length;
    pathWithoutLocales = elements.join('/');

    // NOTE: admin pages have currently no localeId prefixed, therefore extra foo
    const elementsInAdmin = window.location.pathname.split('/').filter((e) => Boolean(e));
    const adminPathWithoutLocales = elementsInAdmin.join('/');
    if (adminPathWithoutLocales.startsWith('admin/')) {
      isAdminPage = true;
    }
  }

  console.log('!!! current language: ', language);
  console.log('!!! current pathWithoutLocales: ', pathWithoutLocales);
  console.log('!!! isIndexPage: ', isIndexPage);

  /** If there is a "language" cookie or its value has been mutated:
   * => update the language inside of I18n instance,
   * Else set the "language" cookie to the current language of the I18n instance */
  React.useEffect(() => {
    if (isI18nReady) {
      if (language) {
        // NOTE: maybe perform some validation here with fallback?
        i18n.changeLanguage(language);
        // FIXME: check if url update is necessary here
        // navigate(isIndexPage ? `/${language}` : `/${language}/${pathWithoutLocales}`);
      } else {
        // get default lang from i18n.language or other source (url path/browser lang)?
        // const defaultLanguage = i18n.language;
        setCookie(languageCookieName, defaultLanguage, { path: '/' });
        i18n.changeLanguage(defaultLanguage); // TODO: test this
        setLanguage(defaultLanguage);
        navigate(
          isIndexPage
            ? `/${i18n?.language || defaultLanguage}`
            : `/${i18n?.language || defaultLanguage}/${pathWithoutLocales}`,
        );
      }
    }
  }, [isIndexPage, isI18nReady, language, setCookie, pathWithoutLocales]);

  // let pathLocale = 'en'; // fallback

  // if (typeof window !== 'undefined') {
  //   const locales: string[] = ['en', 'de', 'hr'];
  //   for (let index = 0; index < locales.length; index++) {
  //     if (window.location.pathname.startsWith(`/${locales[index]}/`)) {
  //       pathLocale = locales[index];
  //     }
  //   }
  // }

  // React.useEffect(() => {
  //   if (isI18nReady && pathLocale !== language.language) {
  //     // console.log(' ______ LocalizationContextProvider current trigger: ');
  //     i18n.changeLanguage(pathLocale);
  //   }
  // }, [isI18nReady, language, pathLocale]);

  // console.log(' ______ LocalizationContextProvider current pathLocale: ', pathLocale);
  // console.log(
  //   ' ______ LocalizationContextProvider current language.language: ',
  //   language?.language,
  // );

  const onSetLanguage = (newLanguage: AppLocale) => {
    setCookie('language', newLanguage, { path: '/' });
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    if (!isAdminPage) {
      let redirectLocaleId = newLanguage;
      if (newLanguage === 'cimode') {
        redirectLocaleId = 'en';
      }

      const updateNavigationPath = isIndexPage
        ? `/${redirectLocaleId}`
        : `/${redirectLocaleId}/${pathWithoutLocales}`;
      // console.log('updateNavigationPath: ', updateNavigationPath);
      navigate(updateNavigationPath);
    }
  };

  const onUpdateLocalizations = async (lang?: string, namespace?: string) => {
    const loadedResources: { [key: string]: LocaleNamespaces } = {};
    const firebase = getApp();
    if (!firebase) {
      return;
    }
    const localizations = await CallableAPI.EXPORT.getLocalizations(firebase, env, {
      systemId: 'holiday',
    });

    if (localizations) {
      // filter by params
      Object.getOwnPropertyNames(localizations).forEach((locId) => {
        if (lang) {
          if (lang === locId) {
            if (namespace) {
              Object.getOwnPropertyNames(localizations[locId]).forEach((ns) => {
                if (ns === namespace) {
                  loadedResources[locId][ns] = localizations[locId][ns];
                }
              });
            } else {
              loadedResources[locId] = localizations[locId];
            }
          }
        } else {
          loadedResources[locId] = localizations[locId];
        }
      });

      // console.log('loadedResources: ', loadedResources);

      if (loadedResources.code && loadedResources.code === 'permission-denied') {
        alert('Your user have no permissions to load translations (are you logged in?)');
      }

      // load new resources per namespace (only pre registered NS should work - not tested)
      Object.getOwnPropertyNames(loadedResources).forEach((locId) => {
        const loadedNamespaces = loadedResources[locId];
        if (loadedNamespaces) {
          Object.getOwnPropertyNames(loadedNamespaces).forEach((ns) => {
            i18n.addResourceBundle(locId, ns, loadedNamespaces[ns], true, true);
          });
        }
      });
      // TODO: handle using additional namespaces
      navigate(`${pathname}?t=${new Date().getTime()}`, {});
    }
  };

  return {
    language,
    setLanguage: onSetLanguage,
    onUpdateLocalizations,
    locales: supportedLanguages,
  };
}

function LocalizationContextProvider(props: {
  children: React.ReactNode;
  importLocaleLocalizations?: boolean;
}): React.ReactElement | null {
  const { children, importLocaleLocalizations } = props;
  const [isI18nReady, setIsI18nReady] = React.useState<boolean>(false);
  const localizationState = GetLocalizationState(isI18nReady);

  /** the i18n setup */
  React.useEffect(() => {
    if (importLocaleLocalizations) {
      // NOTE: this could be passed to gatsby and be retrived via sourced data?
      import('../../../import/locales/imported_localizations.json').then((res) => {
        if (res.default) {
          const importedResources = res.default;
          // console.log('importedLocales: ', importedResources);

          const mergedResources = merge(localeResources, importedResources, {
            arrayMerge: overwriteMerge,
          }) as {
            [key: string]: Resource;
          };

          // IMPORTANT: lack of this is causing blank page on new localizations
          if (!mergedResources.fr?.webapp) {
            mergedResources.fr['webapp'] = {
              footer: {},
              appbar: {},
            };
          }

          // console.log('mergedResources: ', mergedResources);

          i18n
            .use(Backend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
              ...i18nBaseConfig,
              resources: mergedResources,
            });
        }
      });
    } else {
      i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          ...i18nBaseConfig,
          resources: localeResources,
        });
    }
    i18n.init(() => {
      setIsI18nReady(true);
    });

    console.log('i18n: ', i18n);
  }, [importLocaleLocalizations]);

  return (
    <LocalizationContext.Provider
      value={{
        ...localizationState,
      }}
    >
      {isI18nReady ? <I18nextProvider i18n={i18n}>{children}</I18nextProvider> : null}
    </LocalizationContext.Provider>
  );
}

export default LocalizationContextProvider;
