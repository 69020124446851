import { BaseOrganisationDocument } from 'src/admin/types';

export const getOrganisationUserDisplayName = (
  organisationDocument: BaseOrganisationDocument,
  userId: string,
): string => {
  if (
    organisationDocument?.organisationData?.user?.users &&
    Array.isArray(organisationDocument.organisationData.user.users)
  ) {
    return (
      organisationDocument.organisationData.user.users.find(
        (user: { id: string; displayName: string }) => user.id === userId,
      )?.displayName || ''
    );
  }
  return '';
};