import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useStyles, useLocalizationContext, useAppContext } from 'src/app/context';
import { getTranslationForLanguage } from 'src/lib/document';

import {
  SourcedGQLCity,
  SourcedGQLLocation,
  MapViewArgs,
  MapQueryArgs,
  MapMarkerObject,
} from 'src/app/types';
// import { useSourcedLocations } from 'src/app/hooks';
import { AdditionalCityMapInformation, MapWidget } from 'src/app/components';

export interface CityMapWidgetProps {
  city: SourcedGQLCity;
  // mapQueryArgs: MapQueryArgs;
  mapLocations: SourcedGQLLocation[];
}

export const CityMapWidget = ({
  // mapVisualArgs: { height, scale, width, zoom },
  // mapQueryArgs: { area, city, country },
  mapLocations,
  city,
}: CityMapWidgetProps) => {
  const { id, countryId, mapData, locations: cityLocations } = city;
  const theme = useTheme();
  const isWiderXL = useMediaQuery(theme.breakpoints.up('xl') /* '(min-width:600px)' */);
  const isWiderLG = useMediaQuery(theme.breakpoints.up('lg') /* '(min-width:600px)' */);
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const isWiderSM = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);
  const isWiderXS = useMediaQuery(theme.breakpoints.up('xs') /* '(min-width:600px)' */);
  const { language } = useLocalizationContext();
  const { isAppDebug } = useAppContext();
  // const sourcedLocations = useSourcedLocations();
  const styles = useStyles();
  const area = '';

  // const area = ''; // productLocationAddress; // productLocationCityArea
  // const city = ''; // productLocationZipCode; // productLocationCity
  // const country = 'Croatia';

  const mapArgs: MapViewArgs = {
    // height: isWiderMD ? 586 : 293,
    // width: isWiderMD ? 586 : 293, // 1172
    height: 0,
    width: 0,
    scale: 1,
    zoom: mapData?.zoomFactor || 11,
    mapType: 'hybrid',
  };
  if (isWiderXS) {
    // map.height = 146;
    // map.width = 146;
    // mapArgs.height = 326;
    // mapArgs.width = 326;
    mapArgs.height = 252;
    mapArgs.width = 252;
  }
  if (isWiderSM) {
    // map.height = 293;
    // map.width = 293;
    mapArgs.height = 652;
    mapArgs.width = 652;
  }
  if (isWiderMD) {
    mapArgs.height = 466;
    mapArgs.width = 466;
  }
  if (isWiderLG) {
    mapArgs.height = 586;
    mapArgs.width = 586;
  }
  if (isWiderXL) {
    mapArgs.height = 586;
    mapArgs.width = 586;
  }

  const mapQueryArgs: MapQueryArgs = {
    area,
    city: id,
    country: countryId,
    language,
  };

  const markers: MapMarkerObject[] = [];

  if (cityLocations?.length) {
    for (let index = 0; index < cityLocations.length; index++) {
      const res = mapLocations?.find((mapLoc) => mapLoc.id === cityLocations[index].id);
      if (res) {
        const label = getTranslationForLanguage(
          res.localizations,
          language,
          'label',
          isAppDebug,
        );
        markers.push({
          id: res.id, // for keying and i18n
          color: res.locationData.color, // '#ff0000' gets converted later to '0xff0000'
          label,
          lat: res.locationData.lat, // latitude
          long: res.locationData.long, // longitude
          localizations: res.localizations,
        });
      }
    }
  }

  // console.log('markers: ', markers);

  return (
    <MapWidget
      isStatic // TODO
      mapVisualArgs={mapArgs}
      mapQueryArgs={mapQueryArgs}
      markers={markers}
    >
      <AdditionalCityMapInformation city={city} markers={markers} />
    </MapWidget>
  );
};
