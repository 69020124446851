import * as React from 'react';
import clsx from 'clsx';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CommentOutlined from '@material-ui/icons/CommentOutlined';

import { ButtonTw, Localized } from 'src/app/components';
import { BookingSystemType, DialogType, PickupOptionType } from 'src/app/constants';
import {
  SourcedGQLProduct,
  AddToCartDialogParams,
  QuestionAboutProductDialogParams,
} from 'src/app/types';
import { useAppContext } from 'src/app/hooks';
import { createProductLocationOptions } from 'src/lib/participant';

import {
  calculatePriceFromPriceString,
  euroPriceAsCurrency,
  getBasePriceByFeatureEnabled,
  getLowestProductPriceSystemBasePrice,
  padPrice,
} from 'src/lib/price';

const i18nPath = 'shop:screen-product';

export interface ProductPriceContainerProps {
  className?: string;
  product: SourcedGQLProduct;
}

export const onOpenAddToCartDialog = (
  bookingSystemType: BookingSystemType,
  openDialog: any,
  product: SourcedGQLProduct,
) => {
  const pickupLocations: { id: string; type: PickupOptionType; cityId: string }[] =
    createProductLocationOptions(product);

  const addToCartDialogParams: AddToCartDialogParams = {
    product,
    bookingSystemType,
    pickupOptionId:
      pickupLocations.find((l) => l.type.startsWith(PickupOptionType.DEFAULT))?.id ||
      undefined,
  };

  openDialog(DialogType.ADD_TO_CART, addToCartDialogParams);
};

export const ProductPriceContainer = ({
  className,
  product,
}: ProductPriceContainerProps) => {
  const { openDialog, currencyId } = useAppContext();

  const onOpenQuestionAboutProductDialog = () => {
    openDialog<QuestionAboutProductDialogParams>(DialogType.QUESTION_ABOUT_PRODUCT, {
      product,
    });
  };

  // NOTE: calculate the cheapest public base price from all price systems
  const priceDetails:any = getBasePriceByFeatureEnabled(product?.priceSystems[0])
  const lowestPublicPriceFloat = getLowestProductPriceSystemBasePrice(
    product,
    priceDetails?.priceType || 'publicBooking',
  );

  const hasPublicBooking = !!product?.priceSystems.find(
    (ps) => ps.publicBooking?.featureEnabled,
  ); // FIXME: O(n) = 1
  const hasPrivateBooking = !!product?.priceSystems.find(
    (ps) => ps.privateBooking?.featureEnabled,
  ); // FIXME: O(n) = 1
  let bookingType=BookingSystemType.PUBLIC;
  let max_num=0;
  let basePrice=0;
  if(priceDetails?.priceType === BookingSystemType.PRIVATE){
    bookingType=BookingSystemType.PRIVATE
    max_num=priceDetails?.maxParticipants;
    basePrice=priceDetails?.basePrice;
  }
let rawPrice=                euroPriceAsCurrency(
                  calculatePriceFromPriceString(basePrice),
                  currencyId,
                );
                console.log('basePrice,rawPrice',basePrice,lowestPublicPriceFloat);
// let lowestPrice= euroPriceAsCurrency(
//   calculatePriceFromPriceString(lowestPublicPriceFloat.toString()), currencyId,
// )                
  // if(product?.priceSystems?.[0]?.[priceType]?.basePrice> 0){
  //   bookingType=BookingSystemType.PUBLIC
  // }else{
  //   bookingType=BookingSystemType.PRIVATE
  // }
  return (
    <div
      className={clsx(
        className ||
          'text-right pb-6 md:p-8 md:border-2 md:border-accent-500 rounded-2xl md:bg-accent-400 md:bg-opacity-[0.08] flex flex-col md:text-center gap-8 dark:text-neutral-50 md:dark:bg-neutral-800',
        // isWiderMD ? styles.itemCardTitleContainer__additional : '',
      )}
    >
      <div className='hidden md:block'>
        <h3 className='text-h2 font-bold'>
          <Localized dictKey={`${i18nPath}.book-now.title`} />
        </h3>
      </div>
      {bookingType== BookingSystemType.PRIVATE? (
        <div>
           <p className='font-bold md:text-p1'>
          <Localized
            dictKey={rawPrice.decimal===0?`${i18nPath}.book-now.price-per-group-from.labelInteger`:`${i18nPath}.book-now.price-per-group-from.label`}
            translationOptions={{
              currencyId,
              max_num:max_num,
              price:
                euroPriceAsCurrency(
                  calculatePriceFromPriceString(basePrice),
                  currencyId,
                ),
              
            }}
          />
        
        </p>
        <p className='font-bold md:text-p1'>
          <Localized
            dictKey={`${i18nPath}.book-now.price-per-group-max_num.label`}
            translationOptions={{
              currencyId,
              max_num:max_num,
            }}
          />
            </p> 
        </div>
       
        
        
      ) : lowestPublicPriceFloat ? (
        <p className='font-bold md:text-p1'>
          <Localized
            dictKey={euroPriceAsCurrency(
              calculatePriceFromPriceString(lowestPublicPriceFloat.toString()), currencyId,
            ).decimal===0?`${i18nPath}.book-now.price-per-person-from.labelInteger`:`${i18nPath}.book-now.price-per-person-from.label`}
            translationOptions={{
              currencyId,
              price:
                euroPriceAsCurrency(
                  calculatePriceFromPriceString(lowestPublicPriceFloat.toString()), currencyId,
              ),
            }}
          />
        </p>
      ) : null
      }

      <div className='hidden md:flex flex-col gap-3'>
        {/* {hasPublicBooking ? ( */}
          <ButtonTw
            variant={'accent'}
            size={'lg'}
            rounded={'rounded'}
            onClick={() =>
              onOpenAddToCartDialog(bookingType, openDialog, product)
            }
            className={'w-full'}
          >
            <Localized dictKey={`${i18nPath}.book-now.action.availability-check.label`} />
          </ButtonTw>
        {/* ) : null} */}
        {/* {bookingType==BookingSystemType.PRIVATE ? (
          <ButtonTw
            variant={'accent'}
            size={'lg'}
            rounded={'rounded'}
            onClick={() =>
              onOpenAddToCartDialog(BookingSystemType.PRIVATE, openDialog, product)
            }
            className={'w-full'}
          >
            <ShoppingCartIcon className='mr-2' />
            <Localized dictKey={`${i18nPath}.book-now.action.private-group-booking.label`} />
          </ButtonTw>
        ) : null} */}

        <ButtonTw
          variant={'outline'}
          size={'lg'}
          rounded={'rounded'}
          onClick={onOpenQuestionAboutProductDialog}
          className={'w-full'}
        >
          <CommentOutlined className='mr-2' />
          <Localized dictKey={`${i18nPath}.book-now.action.submit-question.label`} />
        </ButtonTw>
      </div>
    </div>
  );
};
