import * as React from 'react';

import { Localized } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';
import { useLocalizationContext, useSourcedCities } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

interface MeetingPointInformationProps {
  product: SourcedGQLProduct;
}

export const MeetingPointInformation = ({ product }: MeetingPointInformationProps) => {
  const { availability, location } = product;  
  const { language } = useLocalizationContext();
  const cities = useSourcedCities();

  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;
  const basePath = 'general-information.meeting-point';
  const fieldPath = `${basePath}.field`;

  const productCity = cities.find((city) => city.id === product.location.cityId) || null;

  return (
    <>
      <h3 className='text-p2 font-semibold mb-2 underline md:no-underline md:text-h4 md:font-bold md:mb-12'>  
        <Localized dictKey={`${i18nPrefix}.${basePath}.title`} />
      </h3>   
      <div className='flex flex-col gap-2 mb-2 md:gap-7 md:mb-7'>
        {location.cityId ? (          
          <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>    
            <span>
              <Localized dictKey={`${i18nPrefix}.${fieldPath}.city`} />
            </span>
            <span className='font-semibold'>                   
              {`${
                productCity
                  ? getBaseDocumentLocales(productCity, language).name
                  : location.cityId
              }`}
            </span>
          </p>                  
        ) : null}
        {location.streetName && location.streetNumber ? (
          <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
            <span>
              <Localized dictKey={`${i18nPrefix}.${fieldPath}.address`} />
            </span>
            <span className='font-semibold'>                      
              {`${location.streetName} ${location.streetNumber}`}
            </span>
          </p>                    
        ) : null}
        {availability.duration ? (
          <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
            <span>
              <Localized dictKey={`${i18nPrefix}.${fieldPath}.duration`} />
            </span>
            <span className='font-semibold'>      
              {`${availability.duration} Minutes`}
              {/** // TODO: format minutes > 60 */}
            </span>
          </p>     
        ) : null}
        {location.timeDelta ? (
          <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
            <span>
              <Localized dictKey={`${i18nPrefix}.${fieldPath}.timeDelta`} />
            </span>
            <span className='font-semibold'>  
              {`${location.timeDelta} Minutes`}
              {/** // TODO: format minutes > 60 */}
            </span>
          </p>
        ) : null}       
        {location.w3w ? (          
          <a
            href={`https://what3words.com/${location.w3w}`}
            rel={'noopener noreferrer'}
            target={'_blank'}
            className={'text-p3 md:text-center my-3'}
          >     
            <RoomOutlinedIcon fontSize='small' />
            <Localized dictKey={`${i18nPrefix}.${basePath}.w3w-link`} />
          </a>              
        ) : null}
      </div>      
    </>
  );
};
