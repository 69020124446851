import { AppLocale } from 'src/app/constants';
import { SourcedGQLProduct } from 'src/app/types';

export const getFoodItemNameById = (
  pickupOptionId: string,
  product: SourcedGQLProduct,
  language: AppLocale,
  // showSource?: boolean,
) => {
  return (
    product?.localizations
      ?.find((l) => l.localeId === language)
      ?.food?.find((f) => f.id === pickupOptionId)?.title || pickupOptionId
  );
};

export const createProductFoodOptionsFromPriceSystem = (
  product: SourcedGQLProduct,
  priceSystemId: string,
): { id: string }[] => {
  const priceSystem = product?.priceSystems.find((ps) => ps.id === priceSystemId);

  return priceSystem?.priceSystemFoodOptions?.featureEnabled &&
    priceSystem.priceSystemFoodOptions.foodOptions?.length
    ? [
        ...priceSystem.priceSystemFoodOptions.foodOptions
          .filter((foodOption) => !!foodOption.foodId)
          .map((foodOption) => ({
            id: foodOption.foodId || '', // fallback piping only for TS since already filtered out
          })),
      ]
    : [];
};
