import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';

import {
  useLocalizedNavigation,
  useSourcedCategories,
  useSourcedCategoryTypes,
  useSourcedCities,
} from 'src/app/hooks';

import { CollectionDataProducts } from './CollectionDataProducts';
import { CollectionDataProductCategories } from './CollectionDataProductCategories';

export interface CountryProductsContainerProps extends BaseComponentProps {
  // categoryId?: string; // eg 'activity-000' // TODO: maybe use one "objectId" or sim for all types (city/category/etc)
  // cityId?: string; // TODO: maybe use one "objectId" or sim for all types (city/category/etc)
  // countryId: string;
  description: string | JSX.Element;
  title: string | JSX.Element;
  type: 'city' | 'category' | 'categoryType';
}

// TODO: rename in all references
const CountryProductsContainer = (props: CountryProductsContainerProps) => {
  const {
    // children,
    // categoryId,
    // cityId,
    // countryId,
    className,
    description,
    title,
    type,
  } = props;
  const cities = useSourcedCities();
  const categories = useSourcedCategories();
  const categoryTypes = useSourcedCategoryTypes();

  const { navigateLocalized } = useLocalizedNavigation();

  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const countryId = 'hr';

  const onCategoryClick = (
    e: React.MouseEvent,
    t: string,
    catId: string,
    catTypeId?: string,
  ) => {
    const path = t === 'category' ? `/${catTypeId}/${catId}` : `/${catId}`;
    navigateLocalized(path); // TODO:
    // http://localhost:8000/explore/s?q=split-croatia&p=activity&date_from=2020-12-01&date_from=2020-12-01
    // navigateLocalized(`/explore/s?country=${countryId}&p=${categoryId}`);
  };

  const onCityClick = (e: React.MouseEvent, t: string, id: string) => {
    // http://localhost:8000/explore/s?q=split-croatia&p=activity&date_from=2020-12-01&date_from=2020-12-01
    // navigate(`/explore/s?country=${countryId}&city=${cityId}`);
    navigateLocalized(`/europe/${countryId}/city/${id}`);
    // navigateLocalized(`/europe/${countryId}/area/${countryAreaId}/city/${cityId}`); - alternative
  };

  return (
    <>

      <div className='pb-6 text-neutral-900 dark:text-neutral-50'>
        <h2 className='text-h4 font-semibold md:text-h2 md:font-bold'>
          {title || ''} {/** TODO: based on params or context? */}
        </h2>

        {/* <h3 className='text-p1'>
          {description}
        </h3> */}

      </div>

      <div>
        {type === 'category' || type === 'categoryType' ? (
          <>
            {type === 'category' ? (
              <CollectionDataProductCategories
                collection={categories}
                onClick={onCategoryClick}
                type={type}
              />
            ) : null}

            {type === 'categoryType' ? (
              <CollectionDataProducts
                collection={categoryTypes}
                onClick={onCategoryClick}
                type={type}
              />
            ) : null}
          </>
        ) : (
          <CollectionDataProducts
            collection={cities}
            onClick={onCityClick}
            type={'city'}
          />
        )}
      </div>

    </>

  );
};

const WAPCountryProductsContainer = (
  props: CombinedAppProps<CountryProductsContainerProps>,
) =>
  withoutAppProps<
    CombinedAppProps<CountryProductsContainerProps>,
    CountryProductsContainerProps
  >(CountryProductsContainer, props);

export { CountryProductsContainer, WAPCountryProductsContainer };
