import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

export interface DisableProductCallableRequestParams {
  systemId: string;
  organisationId: string;
  productId: string;
}

/**
 * systemFunction-disableProduct
 * @version 0.0.0 functions version
 */
export const disableProduct = async (
  firebase: FirebaseApp,
  params: DisableProductCallableRequestParams,
  env: string,
) => {
  return httpsCallable(
    getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
    'systemFunction-disableProduct',
  )(params);
};
