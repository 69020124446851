import React from 'react';
import Button from '@material-ui/core/Button';
import MDialog, { DialogProps as MDialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import { ButtonTw, Localized } from 'src/app/components';
// import { useStyles } from 'src/app/context';

import { global_borderRadius } from 'src/app/context/Theme/muiTheme'; // TODO: create a common styles/theme dir (separate structure by app?)

export interface DialogProps {
  children: React.ReactNode;
  isOpen: boolean;
  maxWidth?: MDialogProps['maxWidth'];
  onClose: () => void;
  showBackdrop?: boolean;
  showCloseButtonBottom?: boolean;
  showCloseButtonTop?: boolean;
  title: string;
}

const noShade = null;
const lowShade = null;
const themeMode = null;
const opacity = 0.5;
const blurAmount = 20;
/* #GLASS */
export const Dialog = (props: DialogProps) => {
  const {
    children,
    isOpen,
    maxWidth,
    onClose,
    showBackdrop,
    showCloseButtonBottom,
    showCloseButtonTop,
    title,
  } = props;
  // const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  let shadeOpacity = noShade ? 0 : 0.37;
  if (lowShade) {
    // shadeOpacity = 0.185;
    shadeOpacity = 0.26;
  }
  const boxShadowP1 = noShade ? 8 : 16;
  const boxShadowP2 = noShade ? 16 : 32;
  const boxShadow = `0 ${boxShadowP1}px ${boxShadowP2}px 0 rgba( 31, 38, 135, ${shadeOpacity} )`;
  const background = `rgba( ${themeMode === 'dark' ? '35, 50, 50,' : '237, 255, 255,'} ${
    opacity ?? '0.1'
  })`;
  const blurFilter = blurAmount ? `blur( ${blurAmount}px )` : 'blur( 0.1px )';

  const glassStyle = {
    // backgroundColor: themeMode === 'dark' ? '#00000044' : '#ffffff44', // as fallback I guess?
    background,
    boxShadow,
    backdropFilter: blurFilter,
    WebkitBackdropFilter: blurFilter,
    borderRadius: global_borderRadius,
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
  };

  const defaultStyle = {
    backgroundColor: 'transparent',
    overflow: 'hidden',
  };
  // TODO backdrop handling

  return (
    <MDialog
      fullWidth
      maxWidth={maxWidth}
      // maxWidth={'100%'}
      // maxWidth={maxWidth} // MDialogProps['maxWidth']
      scroll='body'
      open={isOpen || false}
      onClose={onClose}
      aria-labelledby='max-width-dialog-title'
      //BackdropProps={{ invisible: !showBackdrop }}
      PaperProps={{
        style: isWiderMD
          ? {
              //...defaultStyle,
              // boxShadow: 'none',
              // maxWidth: '600px',
              // width: '100%',
              // margin: '0',
              //...glassStyle,
            }
          : {
              //...defaultStyle,
              // NOTE: defaults:
              // maxWidth: '600px',
              // width: 'calc(100% - 128px)',
              // margin: '32px',
              // NOTE: for full width dialogs on SubMD devices
              width: '100%',
              maxWidth: 'calc(100% - 16px)',
              margin: '24px auto',
              //...glassStyle,
            },
        className: 'font-body',
      }}
    >

      {showCloseButtonTop ? (
        <p className="text-right pt-4 px-4">
          <ButtonTw onClick={onClose} variant='text' className='hover:text-accent-500 dark:hover:text-accent-500'>
            <CloseIcon />
          </ButtonTw>
        </p>
      ) : null}

      <DialogTitle id='max-width-dialog-title'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {showCloseButtonBottom ? (
          <ButtonTw onClick={onClose} variant='accent' rounded size='lg'>
            <Localized dictKey={'common:dialog.action.dialog dismiss.label'} />
          </ButtonTw>
        ) : null}
      </DialogActions>

    </MDialog>
  );
};
