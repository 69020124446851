import { graphql, useStaticQuery } from 'gatsby';
import { SourcedGQLLocation } from 'src/app/types';

interface LocationsQuery {
  allLocation: {
    nodes: SourcedGQLLocation[];
  };
}

export const allLocationQuery = graphql`
  {
    allLocation {
      nodes {
        # locationData
        countryAreaId
        countryId
        id
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 1) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 1) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        localizations {
          localeId
          locales {
            description
            name
            label
            shortDescription
            teaser
          }
          seo {
            description
            title
          }
        }
        locationData {
          color
          lat
          long
        }
        # mapData {
        #   markers {
        #     localizations {
        #       localeId
        #       locales {
        #         description
        #         name
        #         label
        #         shortDescription
        #         teaser
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export function useSourcedLocations(): SourcedGQLLocation[] {
  const { allLocation } = useStaticQuery<LocationsQuery>(allLocationQuery);

  return allLocation?.nodes || [];
}
