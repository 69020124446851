import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
// import IconButton from '@material-ui/core/IconButton';
import { FormControl, InputLabel } from '@material-ui/core';
import { Localized, TextInputField, SelectInputField } from 'src/app/components';

import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FormFieldLayout } from '../Layouts';

import { FormFieldProps, NestedFieldProps } from '../form.types';

const FormSelectField = (props: FormFieldProps<{}> & SelectFieldProps) => {
  const { children, i18nKey, name, options, rules, defaultValue, ...passProps } = props;
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
    setValue
  } = formMethods;

  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  let myRef = null;

  if (ref?.current) {
    // catch missing focus on undefined
    myRef = ref;
  }

 const dataFromAutoComplete=(data:any,fieldName:any)=>{
  setValue(fieldName,data)
 }
  const hasError = !!errors?.[name]; // TODO:
  return (
    <FormFieldLayout
      additionalBottomArea={
        <FieldInformation
          hasError={hasError}
          description={<Localized dictKey={`${i18nKey}.field.description`} />}
          example={<Localized dictKey={`${i18nKey}.field.example`} />}
        >
          {children}
        </FieldInformation>
      }
      debugId={name}
      debugI18nKey={i18nKey}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <FormControl variant='outlined' fullWidth>  
          {props.multiple ?
           null:         
            <InputLabel id={`${i18nKey}.field.name`}>
           <Localized dictKey={`${i18nKey}.field.name`} />
           </InputLabel>
          }
          <SelectInputField
            sendDataToAutoComplete={(metaName:any)=>dataFromAutoComplete(metaName,name)}
            {...inputProps}
            {...passProps}
            value={inputProps.value || ''}
            inputRef={myRef}
            error={hasError}
            fullWidth
            autoFocus={false}
            labelId={`${i18nKey}.field.name`}
            label={<Localized dictKey={`${i18nKey}.field.name`} />}
          >
            {options}
          </SelectInputField>
        </FormControl>
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

interface SelectFieldProps {
  options: React.ReactNode[];
}

export const SelectField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps & SelectFieldProps) => (
  <FormSelectField
    {...rest}
    i18nKey={i18nPath ? `${i18nPath}.${i18nKey}` : i18nKey}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={i18nPath ? `${i18nPath}.${i18nKey}` : i18nKey}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormSelectField>
);
