import * as React from 'react';

import { Localized } from 'src/app/components';

const i18nPrefix = 'shop:screen-product';

// TODO: do not always show price groups?
export const PriceGroupControlHeader = () => {
  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
      <div className='hidden sm:block'>
        <p className='underline'>
          <Localized dictKey={`${i18nPrefix}.add-cart.column.price-group`} />
        </p>
      </div>
      <div>
        <p className='underline'>
          <Localized dictKey={`${i18nPrefix}.add-cart.column.price`} />
        </p>
      </div>
      <div>
        <p className='underline text-right'>
          <Localized dictKey={`${i18nPrefix}.add-cart.column.amount`} />
        </p>
      </div>
    </div>
  );
};
