import * as React from 'react';
import { navigate } from 'gatsby';

import { useAppContext } from 'src/app/hooks';

import { Button, Grid, LoadingIndicator, Typography } from 'src/app/components';

import { LocalStorageKey, NotificationType } from 'src/app/constants';
import {
  applyActionCode,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';

interface PasswordlessSigninFormProps {
  apiKey: string; // not used ATM - how to verify?
  code: string; // the FB code
  lang: string; // is supported but currently not used to change language based on this provided value from FB
  link: string;
}

// TODO: i18n
export const PasswordlessSigninForm = ({ code, link }: PasswordlessSigninFormProps) => {
  const { createNotification, firebase } = useAppContext();

  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

  if (typeof window === 'undefined') {
    return null;
  }

  const loginEmail = window.localStorage.getItem(LocalStorageKey.EMAIL_LOGIN);

  const onSubmitButtonClick = async () => {
    if (!firebase) {
      return;
    }

    const auth = getAuth(firebase);

    // if (auth.currentUser?.emailVerified) {
    //   navigate('/');
    // }

    if (!code) {
      createNotification(NotificationType.ERROR, 'Invalid params', 5000);
      return;
    }

    setIsRequesting(true);

    try {
      console.log('loginEmail: ', loginEmail);

      if (!loginEmail) {
        console.warn('No saved email');
        // setHasNoSavedEmail(true);
        createNotification(
          NotificationType.ERROR,
          'No email to login, maybe you are trying to login in on a different device than the one from which the login request was issued',
          5000,
        );
        // TODO: PASSWORDLESS LOGIN => provide mechanism of entering the email for local storage
        return;
      }

      if (link && isSignInWithEmailLink(auth, link) && loginEmail) {
        // console.log('isSigninLink')
        await signInWithEmailLink(auth, loginEmail, link);
        window.localStorage.removeItem(LocalStorageKey.EMAIL_LOGIN);
        navigate('/');
        createNotification(NotificationType.SUCCESS, 'Login successful', 5000);
      } else if (!isSignInWithEmailLink(auth, link)) {
        // console.log('is not sign in link')
        createNotification(NotificationType.ERROR, 'This is not a sign in link', 5000);
      }
      // await applyActionCode(auth, code);

      // TODO: i18n
    } catch (err) {
      console.warn('Login error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsRequesting(false);
    }
  };

  if (isRequesting) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{'Login through email'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!loginEmail}
              fullWidth
              color={'secondary'}
              onClick={onSubmitButtonClick}
            >
              <Typography>Login</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
