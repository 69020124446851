// TODO: consolidate with functions API
/** @deprecated => remove */
enum Collection {
  category = 'category', // is collectionGroup?
  city = 'city',
  // cityArea = 'cityArea',
  country = 'country',
  locale = 'locale',
  order = 'order',
  organisation = 'organisation',
  organisationRegistration = 'organisation_registration',
  product = 'product', // collectionGroup
  productSource = 'product_source', // collectionGroup
  productGroup = 'product_group', // TODO: create + is per organisation
  productTag = 'product_tag',
  productType = 'product_Type',
  report = 'report',
  system = 'system',
  user = 'user',
  userPermission = 'userPermission',
}

export { Collection };
