import * as React from 'react';

import {
  BooleanSwitch,
  Button,
  Grid,
  QuantityWidget,
  StringPropertyRow,
} from 'src/app/components';

import { OrganisationProductCategoryTypeConfig } from 'src/admin/types';

import { ProductCategoryType } from 'src/app/constants';
import { useStyles } from 'src/app/context';

const setInitialCategoryTypeConfigs = (
  currentProductCategoryType: OrganisationProductCategoryTypeConfig[],
  availableProductCategoryTypes: ProductCategoryType[],
): OrganisationProductCategoryTypeConfig[] => {
  const dict = Object.assign(
    {},
    ...currentProductCategoryType.map((x) => ({ [x.id]: x })),
  );
  return [
    ...availableProductCategoryTypes.map((catType) => ({
      id: catType,
      isEnabled: dict[catType] ? dict[catType].isEnabled : false,
      productLimit: dict[catType] ? dict[catType].productLimit : 16, // TODO: create default constant
    })),
  ];
};

export const UpdateOrganisationProductCategoryTypes = ({
  availableProductCategoryTypes,
  onChange,
  productCategoryTypeConfig,
}: {
  availableProductCategoryTypes: string[];
  onChange: (val: OrganisationProductCategoryTypeConfig[]) => Promise<void>;
  productCategoryTypeConfig?: OrganisationProductCategoryTypeConfig[];
}) => {
  const styles = useStyles();
  const [categoryTypeConfigs, setCategoryTypeConfigs] = React.useState<
    OrganisationProductCategoryTypeConfig[]
  >([
    ...setInitialCategoryTypeConfigs(
      productCategoryTypeConfig as OrganisationProductCategoryTypeConfig[],
      availableProductCategoryTypes as ProductCategoryType[],
    ),
  ]);

  const onChangeCategoryTypeConfig = (
    id: string,
    isEnabled: boolean,
    productLimit: number,
  ) => {
    const update = [...categoryTypeConfigs];
    const config = update.find((cfg) => cfg.id === id);
    if (!config) {
      return;
    }
    config.isEnabled = isEnabled;
    config.productLimit = productLimit;
    setCategoryTypeConfigs(update);
  };

  return (
    <Grid container spacing={1}>
      {categoryTypeConfigs.map((cfg) => (
        <Grid key={cfg.id} item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <StringPropertyRow
                title={`${cfg.id}`}
                value={
                  <BooleanSwitch
                    onSwitch={() =>
                      onChangeCategoryTypeConfig(cfg.id, !cfg.isEnabled, cfg.productLimit)
                    }
                    value={cfg.isEnabled}
                  />
                }
              />
            </Grid>
            <Grid item xs={5}>
              <QuantityWidget
                disabled={!cfg.isEnabled}
                amount={cfg.productLimit}
                labelDictId={''}
                min={0}
                max={64}
                onAmountChange={(val) =>
                  onChangeCategoryTypeConfig(cfg.id, cfg.isEnabled, val)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Grid container className={styles.py__2}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Button
              fullWidth
              color={'primary'}
              onClick={() => onChange(categoryTypeConfigs)}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>
    </Grid>
  );
};
