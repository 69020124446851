import * as React from 'react';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useStyles } from 'src/app/context';
import { ScrollToTop } from './ScrollToTop';

const ScrollToTopContainer = () => {
  const styles = useStyles();
  return (
    <ScrollToTop window={typeof window !== 'undefined' ? window : undefined}>
      <Fab
        color={'primary'}
        className={styles.fab}
        size='small'
        aria-label='scroll back to top'
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollToTop>
  );
};

// rename exports
export { ScrollToTopContainer as ScrollToTop };
