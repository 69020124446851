import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { navigate } from 'gatsby';

import { Grid } from 'src/app/components';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks'; // TODO: select systemId based on system user data
import { DeleteMessageData, RespondToMessageData } from 'src/app/types';

import { AdminMenuContainer } from 'src/admin/components';
import { AdminScreenProps } from 'src/admin/types';

import { MessagesRouter } from 'src/app/container';
import { NotificationType, MessageType, MessageCollection } from 'src/app/constants';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

const messageTypeCollectionsOptions: MessageType[] = [
  // MessageType.LOG,
  MessageType.INBOUND,
  MessageType.OUTBOUND,
];

const messageCollectionWriteOptions: MessageCollection[] = [
  // MessageCollection.ORGANISATION,
  // MessageCollection.USER,
  // MessageCollection.SYSTEM, // FIXME: DEBUG only
];

const collectionPath = 'message_user'; // TODO: create constants for all use cases

const systemId = 'holiday'; // TODO: select systemId based on system user data
const routerBasePath = '/account/messages';

/**
 * System messages screen - Show In/Out/Log system messages
 * @version 1.0.0
 * @description
 * @todo
 * @example
 */
const UserMessages = (props: AdminScreenProps) => {
  const { /* currentSystem, */ createNotification, firebase, user } = useAppContext(); // TODO: select systemId based on system user data
  const { env } = useAppStaticQueryConfig();

  const onRespondToMessage = async (messageId: string, messageText: string) => {
    if (!firebase || !user?.uid) {
      return;
    }

    const respondToMessage = httpsCallable(
      getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
      'commonFunctions-respondToMessage',
    );

    try {
      const params: RespondToMessageData = {
        collection: collectionPath,
        messageId,
        systemId: user.uid,
        responseText: messageText,
      };

      await respondToMessage(params); // make function non async?

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Message successfully sent', 5000);
      navigate(`${routerBasePath}`);
    } catch (err) {
      console.warn('Message sent error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    }
  };

  const onDeleteMessage = async (messageId: string) => {
    if (!firebase) {
      return;
    }

    const deleteMessage = httpsCallable(
      getFunctions(firebase),
      'commonFunctions-deleteMessage',
    );

    try {
      const params: DeleteMessageData = {
        collection: collectionPath,
        messageId,
        systemId,
      };

      await deleteMessage(params); // make function non async?

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Message successfully deleted', 5000);
      navigate(`${routerBasePath}`);
    } catch (err) {
      console.warn('Message delete error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    }
  };

  if (!user?.uid) {
    return null;
  }

  return (
    <AdminMenuContainer {...props}>
      <Grid item xs={12}>
        <MessagesRouter
          messageCollectionPath={collectionPath}
          messageCollectionsWriteOptions={messageCollectionWriteOptions}
          messageTypeOptions={messageTypeCollectionsOptions}
          onDeleteMessage={(id) => onDeleteMessage(id)}
          onRespondToMessage={(id, message) => onRespondToMessage(id, message)}
          routerBasePath={routerBasePath}
          uid={user.uid}
        />
      </Grid>
    </AdminMenuContainer>
  );
};

export { UserMessages };
