import { TopicNode } from 'src/app/types';
import { TopicNodeType } from 'src/app/constants';

import { CallCustomerSupport } from './callCustomerSupport';
import { SendEmail } from './sendEmail';
import { SendWhatsappMessage } from './sendWhatsappMessage';

export const contactTopics: TopicNode[] = [
  {
    id: 'send-email',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [SendEmail],
      },
    ],
  },
  {
    id: 'call-customer-support',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [CallCustomerSupport],
      },
    ],
  },
  {
    id: 'send-whatsapp-message',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [SendWhatsappMessage],
      },
    ],
  },
];
