import * as React from 'react';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';

export interface ImageProps extends BaseComponentProps {
  children?: React.ReactNode;
  className?: string;
  src: string;
}

const rootContainer: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
};

const childContainer: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  position: 'absolute',
  zIndex: 1000,
};

const innerChildContainer: React.CSSProperties = {
  width: '100%',
  minHeight: '200px', // TODO: depends on item width
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  wordBreak: 'break-word',
};

/**
 * Image / WAPImage
 * @param { ImageProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Image = ({ children, className, src, style }: ImageProps) => (
  <div style={rootContainer}>
    <img
      alt={'img-'}
      src={src}
      style={{
        // backgroundImage: `url("${src}")`,
        // backgroundSize: 'contain',
        // backgroundPosition: 'center',

        width: '100%',
        height: 'auto',
        ...style,
      }}
      className={className || ''}
    />
    <div style={childContainer}>
      {children ? <div style={innerChildContainer}>{children}</div> : null}
    </div>
  </div>
);

const WAPImage = (props: CombinedAppProps<ImageProps>) =>
  withoutAppProps<CombinedAppProps<ImageProps>, ImageProps>(Image, props);

export { Image, WAPImage };
