import * as React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { AdminScreenTitleLocalized } from 'src/admin/components';
import { AdminBreadcrumbs } from './AdminBreadcrumbs';

export interface AdminMenuNavigationProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  i18nNS: string;
  screenId: string;
}

const AdminMenuNavigation = ({
  children,
  icon,
  i18nNS,
  screenId,
}: AdminMenuNavigationProps) => {
  const theme = useTheme();
  // const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));
  const isWiderLG = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <h1 className='pb-4 text-h3 md:text-h1 font-bold flex items-center gap-y-4'>
        {/* {icon ? <span>{icon || null}</span> : null} */}
        {/* {currentOrganisation.displayName ?? ''} */}
        <AdminScreenTitleLocalized nameSpace={i18nNS} screenName={screenId} />
        {/* // TODO: implement nested menu bc navigation */}
      </h1>
      <AdminBreadcrumbs />
      {children ? (
        <>
          <div>
            {children}
          </div>
        </>
      ) : null}
    </>
  );
};

export interface AdminMenuContainerProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  i18nNS: string;
  screenId: string;
  subNavigationChildren?: React.ReactNode;
}

export const AdminMenuContainer = ({
  children,
  icon,
  i18nNS,
  screenId,
  subNavigationChildren,
}: AdminMenuContainerProps) => {
  return (
    <>
      <div>
        <AdminMenuNavigation icon={icon} i18nNS={i18nNS} screenId={screenId}>
          {subNavigationChildren ? (
            <div className='pl-4'>
              {subNavigationChildren}
            </div>
          ) : null}
        </AdminMenuNavigation>
      </div>
      {children ? (
        <div className='py-2'>
          {children}
        </div>
      ) : null}
    </>
  );
};
