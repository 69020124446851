import * as React from 'react';

import { RenderI18nUrlItem } from 'src/app/components';
import { useTranslation } from 'react-i18next';
import { customerHelpTopicDictKey } from '../customerHelpTopic.dictKey';

const topicKey = 'i-cant-find-the-meeting-point';
export const ICantFindTheMeetingPoint = () => {
  const { t } = useTranslation();

  const contentKey = `${customerHelpTopicDictKey}.${topicKey}.content`;
  const contentItems: string[] = t(contentKey, { returnObjects: true }) || [];

  const imgUrlsKey = `${customerHelpTopicDictKey}.${topicKey}.imageUrls`;
  const imgUrlItems: string[] = t(imgUrlsKey, { returnObjects: true }) || [];

  return (
    <div className='py-2 md:p-4'>
      {contentItems?.length && Array.isArray(contentItems)
        ? contentItems.map((item) => {
            return (
              <p className='mb-2' key={item}>
                {item}
              </p>
            );
          })
        : null}

      {imgUrlItems?.length && Array.isArray(imgUrlItems)
        ? imgUrlItems.map((item, idx) => (
            <RenderI18nUrlItem key={idx} prefixedStringFromImgUrls={item} />
          ))
        : null}
    </div>
  );
};
