import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  Button,
  Grid,
  Localized,
  TextInputField,
  Typography,
  CenteredContainer,
} from 'src/app/components';
import { useStyles } from 'src/app/context';
import clsx from 'clsx';

interface QuantityWidgetProps {
  amount: number;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  labelDictId: string;
  min: number;
  max: number;
  onAmountChange: (amount: number) => void;
  stepAmount?: number;
}

// TODO: refactor to use generic QuantityWidget (too specific logic here)
export const QuantityWidget = (props: QuantityWidgetProps) => {
  const {
    amount,
    children,
    className,
    disabled,
    labelDictId,
    min,
    max,
    onAmountChange,
    stepAmount,
  } = props;
  const stepSize = stepAmount || 1;
  const styles = useStyles();
  const isInStepMode = !!stepAmount;
  return (
    <div className={className}>
      <div className={`grid grid-cols-2 gap-2 border-b border-neutral-100 dark:border-neutral-800 py-3 sm:border-none ${labelDictId ? 'sm:grid-cols-3' : 'sm:grid-cols-2'} `}>
        {labelDictId ? (
          <div>
            <p>
              <Localized dictKey={labelDictId} />
            </p>
          </div>
        ) : null}
        <div className={labelDictId ? 'order-2 sm:order-none' : ''}>
          {children}
        </div>
        <div className={labelDictId ? 'order-1 sm:order-none' : ''}>
          <div className='flex gap-x-1 justify-end'>
            <Button
              // classes={{
              //   root: clsx(styles.w_min_32px, styles.h__min__100),
              // }}
              className='!h-8 !box-border !bg-neutral-100 !border-none dark:!bg-neutral-800'
              disabled={disabled}
              size={'small'}
              variant={'outlined'}
              // color={'primary'}
              onClick={() => {
                let val = amount;
                if (isInStepMode) {
                  val = Math.ceil(val / stepSize) * stepSize;
                }
                val =
                  val - stepSize > min || (val - stepSize === 0 && min === 0)
                    ? val - stepSize
                    : val;
                onAmountChange(val);
              }}
            >
              <RemoveIcon fontSize={'small'} />
            </Button>
            <TextInputField
              disabled={disabled}
              // type={'number'} // no arrows
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              // style={{
              //   direction: 'rtl',
              //   fontSize: '24px',
              //   borderRadius: '0px',
              //   margin: '0 4px',
              //   minWidth: '48px',
              //   height: '100%',
              //   // textAlign: 'right',
              // }} // TODO borderadius
              InputProps={{
                classes: {
                  input: '!h-8 !box-border text-center !w-14 !bg-neutral-50 dark:!bg-neutral-900 !rounded-lg',
                  // focused: classes.textField__focused,
                  // notchedOutline: classes.textField__notchedOutline,
                },
              }}
              value={amount}
              // inputProps={{ max: 99 }}
              // inputProps={{ min: 0, style: { textAlign: 'right' } }}
              onChange={(e) => {
                let parsedValue = parseInt(e.target.value, 10);
                if (Number.isNaN(parsedValue)) {
                  parsedValue = stepSize;
                }
                const isOfValidStepSize = !!(parsedValue % stepSize === 0);
                if (
                  !isOfValidStepSize &&
                  !Number.isNaN(parsedValue) &&
                  parsedValue !== 0
                ) {
                  parsedValue =
                    Math.ceil(parsedValue / stepSize) * stepSize - stepSize;
                }
                onAmountChange(parsedValue <= max ? parsedValue : max);
              }}
            />
            <Button
              // classes={{
              //   root: clsx(styles.w_min_32px, styles.h__min__100),
              // }}
              className='!h-8 !box-border !bg-neutral-100 !border-none dark:!bg-neutral-800'
              disabled={disabled}
              size={'small'}
              variant={'outlined'}
              // color={'primary'}
              onClick={() =>
                onAmountChange(amount + stepSize <= max ? amount + stepSize : amount)
              }
            >
              <AddIcon fontSize={'small'} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
