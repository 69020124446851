import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

export interface DuplicateProductCallableRequestParams {
  systemId: string;
  organisationId: string;
  productId: string;
}

/**
 * systemFunction-duplicateProduct
 * @version 0.0.0 functions version
 */
export const duplicateProduct = async (
  firebase: FirebaseApp,
  params: DuplicateProductCallableRequestParams,
  env: string,
) => {
  return httpsCallable(
    getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
    'systemFunction-duplicateProduct',
  )(params);
};
