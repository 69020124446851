import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FormErrorData {
  nestedErrors: FormErrorData[];
  fieldKey: string;
  messages: string[];
  path: string;
}

const getNestedErrorMessages = (
  obj: Record<string, any>,
  formObjPath: string,
  i18nBasePath: string,
  t: (dictId: string) => string,
): FormErrorData[] => {
  const res: FormErrorData[] = [];

  const objKeys = Object.keys(obj);

  // NOTE: maybe buggy on deeply nested objects...
  // TODO: use this code to determine if we have an array
  // TODO: if yes then show information based on idx
  for (let objKeyIdx = 0; objKeyIdx < objKeys.length; objKeyIdx++) {
    const key = objKeys[objKeyIdx];
    const path = formObjPath ? `${formObjPath}.${key}` : key;
    const numberPathElements =
      path.split('.').filter((item) => !Number.isNaN(parseInt(item, 10))) || [];
    let errMsgPath: string | null = null;
    errMsgPath = path;
    // if we find numbers (array indices) inside of path replace with localization keys
    if (numberPathElements?.length) {
      // console.log('found number numberPathElements: ', numberPathElements);
      for (let index = 0; index < numberPathElements.length; index++) {
        const element = numberPathElements[index];
        errMsgPath = errMsgPath.replace(`${element}`, 'fields');
      }
    }

    // NOTE: inspect only children if root has no message - inspect
    if (obj?.[key]?.message) {
      const errCode = obj?.[key]?.message;
      const translatedErrMsg = t(`${i18nBasePath}.${errMsgPath}.error.${errCode}`);

      res.push({
        messages: [translatedErrMsg],
        nestedErrors: [],
        path,
        fieldKey: key,
      });
    } else {
      const nestedErrorNodes = getNestedErrorMessages(obj?.[key], path, i18nBasePath, t);
      if (nestedErrorNodes?.length) {
        res.push({
          messages: [],
          nestedErrors: [...nestedErrorNodes],
          path,
          fieldKey: key,
        });
      }
    }
  }
  return res;
};

const RenderError = ({
  nestedErrors,
  fieldKey,
  i18nBasePath,
  messages,
  path,
}: FormErrorData & { i18nBasePath: string }) => {
  const showPath = false;
  return (
    <div key={path} className='flex flex-wrap gap-2 text-p3'>
      {showPath ? (
        <div className='w-full md:w-1/3'>
          <p>{path}:</p>
        </div>
      ) : null}
      <div className={showPath ? 'w-full md:w-2/3' : 'w-full'}>
        {messages?.map((msg, idx) => (
          <p key={`${fieldKey}_${msg}_${idx}`}>{msg}</p>
        ))}
      </div>
      <div className='w-full'>
        {nestedErrors?.map((errChild, idx) => (
          <RenderError
            key={`${errChild.fieldKey}_${idx}`}
            {...errChild}
            i18nBasePath={i18nBasePath}
          />
        ))}
      </div>
    </div>
  );
};

export interface FormErrorsProps {
  children?: React.ReactNode;
  i18nBasePath: string;
}

export const FormErrors = ({ children, i18nBasePath }: FormErrorsProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();
  const hasErrors = Object.keys(errors).length;
  const errorStructure = getNestedErrorMessages(errors, '', i18nBasePath, t);
  return hasErrors ? (
    <div className='flex flex-col gap-y-2 p-3 my-4 md:p-4 text-danger-500 border rounded-xl border-danger-500 break-words'>
      <h3 className='text-p1 font-semibold'>
        There are problems with the input to resolve:
      </h3>
      {errorStructure?.length
        ? errorStructure.map((errNode: FormErrorData) => {
            return (
              <React.Fragment key={errNode.path}>
                <RenderError {...errNode} i18nBasePath={i18nBasePath} />
              </React.Fragment>
            );
          })
        : null}
      <div>
        {children}
      </div>
    </div>
  ) : null;
};
