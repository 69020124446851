import * as React from 'react';

import { Grid, Image, Typography } from 'src/app/components';

export const RenderI18nUrlItem = ({
  prefixedStringFromImgUrls: value,
}: {
  prefixedStringFromImgUrls: string;
}) => {
  if (value.startsWith('TEXT:')) {
    const slicedText = value.replace('TEXT:', '');
    return (
      <Grid key={slicedText} item xs={12}>
        <Typography>{slicedText}</Typography>
      </Grid>
    );
  }
  if (value.startsWith('IMG_URL:')) {
    const slicedText = value.replace('IMG_URL:', '');
    return (
      <Grid key={slicedText} item xs={12}>
        <img src={slicedText || ''} alt={'img_alt_text'} />
      </Grid>
    );
  }
  return null; // NOTE: No other types supported
  // return (
  //   <Grid key={value} item xs={12}>
  //     <Typography>{value}</Typography>
  //   </Grid>
  // );
};
