import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';

import { useAppContext, useFirebaseStorage } from 'src/app/hooks';
import {
  createBaseDocumentCreatedBy,
  getFileExtension,
  updateExistingDocument,
} from 'src/app/lib';
import {
  ImageUploadErrorNotification,
  ImageUploadSuccessNotification,
  DocumentCreateErrorNotification,
  DocumentCreateSuccessNotification,
  DocumentUpdateErrorNotification,
  DocumentUpdateSuccessNotification,
} from 'src/app/components';

import { StoredImageData } from 'src/app/types';
import { DialogType, NotificationType } from 'src/app/constants';

export type AcceptedDataTypesTodo = Record<string, any>; // BaseDocumentData | CountryDocumentCreateFormData ;

export interface UseSetDocumentWithImageUpload {
  setDocumentWithImageUpload: <T extends AcceptedDataTypesTodo>(
    data: T,
    collectionPath: string,
    storageImagePath: string,
    imageArrayPropertyName: string,
    imageFilePropertyName: string,
    isUpdateOperation: boolean, // indicates an update operation for notifications
    onSuccess?: (() => void) | (() => Promise<void>),
  ) => Promise<void>;
}

const imgRandomUidLength = 8; // TODO: move elsewhere

// NOTE: Currently only supporting image files... TODO genericize
export const useSetDocumentWithImageUpload = (): UseSetDocumentWithImageUpload => {
  const {
    createStackedNotification,
    firebase,
    isLoggedIn,
    user,
    openDialog,
    closeDialog,
  } = useAppContext();
  const { useUploadFiles } = useFirebaseStorage();
  const {
    // hasUploadError,
    // hasUploadSuccess,
    // isUploading,
    uploadFiles,
    // uploadProgress,
    // uploadFileUrls,
  } = useUploadFiles(ImageUploadSuccessNotification, ImageUploadErrorNotification);

  const setDocumentWithImageUpload = async <T extends AcceptedDataTypesTodo>(
    data: T,
    collectionPath: string,
    storageImagePath: string, // TODO: generic
    imageArrayPropertyName: string,
    imageFilePropertyName: string, // TODO: generic
    isUpdateOperation: boolean,
    onSuccess?: (() => void) | (() => Promise<void>),
  ) => {
    if (!firebase || !isLoggedIn || !user || !user.uid || !data.id) {
      // TODO: check if document alread exists
      // 2 operation modes necessary? (drop operation & aks for confirmation)
      return;
    }

    console.log('useSetDocumentWithImageUpload data: ', data);
    try {
      const { imageUrls, ...imgFileOmittedData } = data; // remove imageUrls & replace later with strings
      const docRef = doc(getFirestore(firebase), `${collectionPath}/${data.id}`);

      // upload each image file and pass url to document
      const filesToUpload: File[] = [];

      // create the file meta data for firestore
      const imageData: StoredImageData[] = [];
      if (!data?.[imageArrayPropertyName]) {
        return;
      }
      // FIXME: this should be dependant on if isUpdateOperation flag is set?
      const imageDataToKeep: StoredImageData[] = [];

      for (let index = 0; index < data[imageArrayPropertyName].length; index++) {
        // TODO: does the file have to be awaited?
        const file: File | null =
          data[imageArrayPropertyName][index][imageFilePropertyName];
        const url: string | null = data[imageArrayPropertyName][index].imageUrl;
        // console.log('______hhmmmm2 file: ', file);
        // console.log('______hhmmmm2 url: ', url);
        // console.log(
        //   '______hhmmmm2 data.[imageArrayPropertyName]: ',
        //   data[imageArrayPropertyName],
        // );
        // IMAGE FILE LOGIC
        // if a file is present then overwrite the existing imageUrl & img meta data
        if (file?.type?.startsWith('image/')) {
          filesToUpload.push(file);
          // console.log('______hhmmmm3');
          // create meta data for the image
          const fileExtension = getFileExtension(file);
          const originalNameUrlEncoded = encodeURIComponent(file.name);
          const fileName = `${data.id}_${nanoid(imgRandomUidLength)}`;
          const fullFilePath = `${storageImagePath}/${fileName}.${fileExtension}`;
          const uploadImage = {
            // create file names (extension is copied from original file.name)
            imageUrl: '',
            fileExtension,
            displayName: originalNameUrlEncoded,
            fileName,
            fullFilePath,
            storagePath: storageImagePath,
            localizations: imageUrls[index].localizations
              ? [...imageUrls[index].localizations]
              : [],
          };
          imageData.push(uploadImage);
          console.log('IMAGE CASE 1 - uploadImage: ', uploadImage);
        } else if (url && !file) {
          // copy over all values that are not the imageFile
          const keepImage: StoredImageData = {} as StoredImageData;
          const keys = Object.keys(data[imageArrayPropertyName][index]).filter(
            (key) => key !== imageFilePropertyName,
          );
          for (let k = 0; k < keys.length; k++) {
            keepImage[keys[k]] = data[imageArrayPropertyName][index][keys[k]];
          }
          imageDataToKeep.push(keepImage);
          // keep url if no file was present
          console.log('IMAGE CASE 2 - keepImage: ', keepImage);
        } else {
          console.log('IMAGE CASE 3 - url: ', !!url);
          console.log('IMAGE CASE 3 - file: ', !!file);
          console.warn(
            'Invalid file data type - upload rejected filename: ',
            data.imageUrls[index][imageFilePropertyName]?.name || '',
          );
        }
      }

      // return;
      openDialog(DialogType.LOADING);
      // FIXME: upload files if doc data is validated...
      // NOTE: maybe do a "double commit" => 1. only doc and if success then upload files and update the doc again?
      const uploadedFilesResults = imageData.length
        ? await uploadFiles(
            filesToUpload,
            storageImagePath,
            imageData.map((imgData) => imgData.fileName),
          )
        : [];
      // console.log('______uploadedFilesResults: ', uploadedFilesResults);
      // console.log('______imageData: ', imageData);

      // VALIDATE BY FINDING MATCHING PAIRS AND ONLY RETURN MATCHES

      let imageDataWithOnlySuccessfulUploadedImages: StoredImageData[] = imageData.length
        ? imageData.filter(
            (imgData) =>
              !!uploadedFilesResults.find(
                (upFileRes) => upFileRes.uploadStoragePath === imgData.fullFilePath,
              ),
          )
        : [];

      // console.log(
      //   'imageDataWithOnlySuccessfulUploadedImages1: ',
      //   imageDataWithOnlySuccessfulUploadedImages,
      // );

      imageDataWithOnlySuccessfulUploadedImages =
        imageDataWithOnlySuccessfulUploadedImages.map((img) => ({
          ...img,
          imageUrl:
            uploadedFilesResults.find((res) => res.uploadStoragePath === img.fullFilePath)
              ?.downloadUrl || '',
        }));

      // console.log(
      //   'imageDataWithOnlySuccessfulUploadedImages2: ',
      //   imageDataWithOnlySuccessfulUploadedImages,
      // );

      // TODO: OPT
      // show failed results
      // propose retry or leave it
      // then create the document

      console.log('imageDataToKeep: ', imageDataToKeep);

      const dataWithStorageImgUrls = {
        ...imgFileOmittedData,
        imageUrls: [...imageDataWithOnlySuccessfulUploadedImages, ...imageDataToKeep],
      } as any as T;
      // const docData = createBaseDocument<T>(data.id, dataWithStorageImgUrls);
      // NOTE: while this is only used in system admin context, always insert createdBy
      const transformDocData = isUpdateOperation
        ? updateExistingDocument<T>(user.uid, data.id, dataWithStorageImgUrls)
        : createBaseDocumentCreatedBy<T>(user.uid, data.id, dataWithStorageImgUrls);
      console.log('set doc: ', transformDocData);
      await setDoc(docRef, transformDocData);
      await new Promise((resolve) => setTimeout(resolve, 10000));
      if (onSuccess) {
        if (onSuccess instanceof Promise) {
          await onSuccess;
        } else {
          onSuccess();
        }
      }
      createStackedNotification(
        NotificationType.SUCCESS,
        isUpdateOperation
          ? DocumentUpdateSuccessNotification
          : DocumentCreateSuccessNotification,
      );
    } catch (err) {
      createStackedNotification(
        NotificationType.ERROR,
        isUpdateOperation
          ? DocumentUpdateErrorNotification
          : DocumentCreateErrorNotification,
      );
      console.warn('Error on submit - finalizing uploads error: ', err);
    } finally {
      closeDialog();
    }
  };

  return { setDocumentWithImageUpload };
};
