import * as React from 'react';

import { isLocalhost } from 'src/app/lib';

import { DebugState } from 'src/app/types';

export const useDebugState = (): DebugState => {
  const [isAppDebug, setIsAppDebug] = React.useState<boolean>(isLocalhost());
  // TODO: add localstorage state to survive page reloads...

  return { isAppDebug, setIsAppDebug };
};
