import styled from 'styled-components';
import MTextField from '@material-ui/core/TextField';

const StyledTextField = styled(MTextField)`
  label {
    //color: #000;
  }
  label.focused {
    // color: #ffa500;
  }
  .MuiOutlinedInput-root {
    /* height: 30px; // NOTE: applied globally */
    label {
      // color: #ffa500;
    }
    fieldset {
      // border-color: #ffa500;
    }
    &:hover fieldset {
      // border-color: #ffa500;
    }
    &.Mui-focused fieldset {
      // border-color: #ffa500;
    }
  }
  .MuiFilledInput-root {
    color: #000;
    background-color: #eee;
    border-color: #fff;
    fieldset {
      color: #000;
      background-color: #f00;
      border-color: #0f0;
    }
    &:hover fieldset {
      // border-color: #f44336;
    }
    &.Mui-focused fieldset {
      // border-color: #f44336;
    }
  }
`;

export { StyledTextField as TextField };
