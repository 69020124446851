import * as React from 'react';
import { doc, Firestore, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import CancelIcon from '@material-ui/icons/Block';

import {
  Button,
  ButtonTw,
  ButtonGroup,
  ConfirmationWidget,
  DialogLayout,
  Localized,
  LoadingIndicator,
  Typography,
} from 'src/app/components';

// import { BaseDocumentData } from 'src/app/types';
import {
  useDocumentActions,
  useAppContext,
  useAppStaticQueryConfig,
} from 'src/app/hooks';
import { useStyles } from 'src/app/context';
import { NotificationType, ProductDocumentState } from 'src/app/constants';
import { FirestoreDocData } from 'src/app/container';
import { BaseOrganisationDocument } from 'src/admin/types';

import { isOrganisationProductCategoryTypeLimitReached } from 'src/app.organisation/lib';
import { deleteProduct } from 'src/api/callables';

export interface ChangeDocumentStateDialogProps {
  children?: React.ReactNode;
  onCloseDialog: () => void;
  documentId: string;
  documentCollectionPath: string;
  onSuccess?: () => void;
  organisationDocument: BaseOrganisationDocument;
}

enum DocumentUserAction {
  PUBLISH = 'publish',
  REVOKE = 'revoke',
  DELETE = 'delete',
}

const getLocalizationOK = async (
  organisationId: string,
  productId: string,
  localeId: string,
  firestore: Firestore,
): Promise<boolean> => {
  const docPath = `organisation/${organisationId}/product_source/${productId}/localization/${localeId}`;
  const docSnap = await getDoc(doc(firestore, docPath));
  return !!docSnap.exists;
};

export const ChangeDocumentStateDialog = (props: ChangeDocumentStateDialogProps) => {
  const {
    children,
    documentId,
    documentCollectionPath,
    onCloseDialog,
    onSuccess,
    organisationDocument,
  } = props;
  const styles = useStyles();
  const {
    createStackedNotification,
    currentOrganisation,
    firebase,
    isAppDebug,
    systemId,
  } = useAppContext();
  const firestore = useFirestore();

  const {
    setDeleteRequestDocumentStatus,
    setRevokeDocumentStatus,
    setPublishRequestDocumentStatus,
  } = useDocumentActions();
  const [openMenuId, setOpenMenuId] = React.useState<string | null>(null);
  const { env } = useAppStaticQueryConfig();

  if (!currentOrganisation?.id) {
    return null;
  }

  const onChangeConfirmed = () => {
    setOpenMenuId('modify');
  };

  const onChangeState = async (action: DocumentUserAction) => {
    try {
      let task: Promise<void> | null = null;
      switch (action) {
        case DocumentUserAction.DELETE:
          task = setDeleteRequestDocumentStatus(documentId, documentCollectionPath);
          break;
        case DocumentUserAction.PUBLISH:
          {
            const enLocalizationResult = await getLocalizationOK(
              currentOrganisation.id,
              documentId,
              'en',
              firestore,
            );
            if (!enLocalizationResult) {
              createStackedNotification(
                NotificationType.ERROR,
                'To publish a product you have to create an english translation first',
              );
              return;
            }
            task = setPublishRequestDocumentStatus(documentId, documentCollectionPath);
          }
          break;
        case DocumentUserAction.REVOKE:
          task = setRevokeDocumentStatus(documentId, documentCollectionPath);
          break;
        default:
          throw new Error('action not implemented');
      }
      if (!task) {
        throw new Error('no task to execute');
      }
      await task;
      onCloseDialog();
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // NOTE: Anatomy of firebase error: => create generic FB Error handler

  interface FirebaseError<E = {}> {
    code: string; // "functions/unknown"
    name: string; // "FirebaseError"
    details: {
      errorDetails: {
        details: {
          // NOTE: this is custom error payload ( => throw new functions.https.HttpsError('permission-denied', 'permission-denied', {reason: 'not in organisation'}); )
          reason: string; // E => "not in organisation"
        };
        message: string; // "permission-denied"
        status: string; // "PERMISSION_DENIED"
      };
    };
  }

  const onDebugDelete = async () => {
    try {
      if (!firebase) {
        return;
      }
      await deleteProduct(
        firebase,
        {
          systemId,
          organisationId: currentOrganisation.id,
          productId: documentId,
        },
        env,
      );
      createStackedNotification(NotificationType.SUCCESS, 'Delete success');
    } catch (err) {
      console.log('Error: ', JSON.stringify(err));
      createStackedNotification(NotificationType.ERROR, `Error: ${JSON.stringify(err)}`);
    }
  };

  // TODO: SET BY ORG PERMISSIONS
  const featureIds: DocumentUserAction[] = [
    DocumentUserAction.DELETE,
    DocumentUserAction.PUBLISH,
    DocumentUserAction.REVOKE,
  ];

  const hasFeature = (featureId: DocumentUserAction) => featureIds.includes(featureId);

  const currentProductTypeCount = 16;

  return (
    <DialogLayout
      dialogDescription={
        !openMenuId ? (
          <Localized
            dictKey={'organisation:dialogs.product-publish-dialog.confirm-subtitle'}
          />
        ) : (
          <Localized
            dictKey={'organisation:dialogs.product-publish-dialog.change-state-subtitle'}
          />
        )
      }
      dialogControls={
        <div className='flex justify-center py-2'>
          <ButtonTw
            onClick={onCloseDialog}
            variant={'text'}
            className='underline !text-accent-500 !font-semibold hover:!text-neutral-900 dark:hover:!text-neutral-50'
          >
            {'Cancel'}
          </ButtonTw>
        </div>
      }
    >
      <FirestoreDocData
        documentPath={`${documentCollectionPath}/${documentId}`}
        renderElements={({ data: document, status }) => {
          if (status === 'loading') {
            return <LoadingIndicator />;
          }
          if (status === 'error') {
            return 'Error';
            // <LocalizedErrorIndicator
            //   i18nContext={'organisation'}
            //   i18nErrorCode={'LOADING_DOCUMENT'}
            //   i18nErrorAreaCode={'organisation'}
            // />
          }
          return status !== 'success' || !document?.id || !document.meta.status ? null : (
            <>
              <div className='py-2'>
                <p className='text-p1 font-semibold'>
                {document.meta.status=="__publish_rejected" ?  "status : REJECTED" :
                  <Localized
                    dictKey={'organisation:org-products.product.status.button.label'}
                    translationOptions={{ status: document.meta.status }}
                  />
                }
                </p>
              </div>

              {!openMenuId ? (
                <div>
                  <ConfirmationWidget
                    confirmId={documentId || ''}
                    onSubmitSuccess={onChangeConfirmed}
                  />
                </div>
              ) : null}
              {openMenuId === 'modify' ? (
                <div>
                  <ButtonGroup fullWidth orientation={'vertical'} size={'large'}>
                    <Button
                      fullWidth
                      disabled={
                        !hasFeature(DocumentUserAction.PUBLISH) &&
                        document.meta.status !== '__published' &&
                        !isOrganisationProductCategoryTypeLimitReached(
                          organisationDocument,
                          document.meta.categoryTypeIds[0],
                          currentProductTypeCount,
                        )
                      }
                      onClick={() => onChangeState(DocumentUserAction.PUBLISH)}
                    >
                      Publish
                    </Button>
                    <Button
                      fullWidth
                      disabled={
                        !hasFeature(DocumentUserAction.REVOKE) ||
                        !(document?.meta?.status === ProductDocumentState.PUBLISHED)
                      }
                      onClick={() => onChangeState(DocumentUserAction.REVOKE)}
                    >
                      Unpublish
                    </Button>
                    <Button
                      fullWidth
                      disabled={!hasFeature(DocumentUserAction.DELETE)}
                      onClick={() => onChangeState(DocumentUserAction.DELETE)}
                    >
                      Delete
                    </Button>
                    {isAppDebug ? (
                      <Button
                        fullWidth
                        // disabled={!hasFeature(DocumentUserAction.DELETE)}
                        onClick={() => onDebugDelete()}
                      >
                        <Typography color={'secondary'}>Delete dirty</Typography>
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </div>
              ) : null}
              {children ? (
                <div>
                  {children}
                </div>
              ) : null}
            </>
          );
        }}
      />
    </DialogLayout>
  );
};
