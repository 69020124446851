import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Localized,
  FlaggedPhoneNumber,
  RenderI18nUrlItem,
} from 'src/app/components';

import { callSupportFlagNumbers } from '../contactPhoneNumbers';
import { customerHelpTopicDictKey } from '../contactTopic.dictKey';

const topicKey = 'call-customer-support';

export const CallCustomerSupport = () => {
  const { t } = useTranslation();

  const contentKey = `${customerHelpTopicDictKey}.${topicKey}.content`;
  const contentItems: string[] = t(contentKey, { returnObjects: true }) || [];

  const imgUrlsKey = `${customerHelpTopicDictKey}.${topicKey}.imageUrls`;
  const imgUrlItems: string[] = t(imgUrlsKey, { returnObjects: true }) || [];

  return (
    <div className='py-2 md:p-4'>
      {contentItems?.length && Array.isArray(contentItems)
        ? contentItems.map((item) => {
            return (
              <p className='mb-2' key={item}>
                {item}
              </p>
            );
          })
        : null}
      <div className='my-4 mx-2'>
        {callSupportFlagNumbers?.map(({ id, phoneNumber }) => (
          <FlaggedPhoneNumber
            key={id}
            countryTitle={<Localized dictKey={`shop:countries.${id}.title`} />}
            flagCountryId={id}
            phoneNumber={phoneNumber}
          />
        )) || null}
      </div>
      {imgUrlItems?.length && Array.isArray(imgUrlItems)
        ? imgUrlItems.map((item, idx) => (
            <RenderI18nUrlItem key={idx} prefixedStringFromImgUrls={item} />
          ))
        : null}
    </div>
  );
};
