import * as React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';

import duration from 'dayjs/plugin/duration';
import { Slider } from './Slider';

dayjs.extend(duration);

interface DurationFilterProps {
  currentDurationFilterValues: { min: number; max: number };
  setDurationFilter: (min: number, max: number) => void;
}

export const DurationFilter = ({
  currentDurationFilterValues,
  setDurationFilter,
}: DurationFilterProps) => {
  const styles = useStyles();

  const onChangeFilter = (
    e: React.ChangeEvent<{}>,
    value: number | number[],
  ) /*: void & React.FormEventHandler<any> */ => {
    if (Array.isArray(value)) {
      setDurationFilter(value[0], value[1]);
    }
  };

  const durationMin = dayjs.duration({
    minutes: currentDurationFilterValues.min,
  });

  const durationMax = dayjs.duration({
    minutes: currentDurationFilterValues.max,
  });

  return (
    <Grid container className={clsx(styles.pt__2, styles.px__1)}>
      <Grid item xs={12} className={clsx(styles.px__1)}>
        <Slider
          disabled={
            typeof currentDurationFilterValues?.min === 'undefined' ||
            typeof currentDurationFilterValues?.max === 'undefined'
          }
          valueLabelDisplay='auto'
          // aria-label='slider'
          getAriaLabel={(idx) => 'slider'}
          // defaultValue={[10, 100]}
          min={0}
          max={1 * 24 * 60}
          step={15}
          // valueLabelDisplay='on'
          valueLabelFormat={(val, idx) => <span>{val} Min</span>}
          value={[currentDurationFilterValues.min, currentDurationFilterValues.max]}
          onChange={onChangeFilter}
          label={'Foo'}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography align={'left'} variant={'h6'} renderAs={'p'}>
              {'Duration'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align={'right'} variant={'h6'} renderAs={'p'}>
              {`${durationMin.asHours()} h - ${durationMax.asHours()} h`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
