import {
  BaseLocalizedDocumentData,
  BaseLocalization,
  SeoData,
  SeoLocalization,
  TimelineLocalization,
  TimelineLocalizationNodeData,
  ProductLocalization,
  MediaLocalization,
} from 'src/app/types';

import { AppLocale } from 'src/app/constants';

const getDefaultLocaleForCiMode = (localeId: AppLocale) =>
  (localeId as string) === 'cimode' ? AppLocale.EN : localeId;

const isCimode = (localeId: AppLocale) => !!((localeId as string) === 'cimode');

const sourceInfo = '<DB>';

const emptyDocLocNodeData = {
  additionalInformation: sourceInfo,
  title: sourceInfo,
  type: sourceInfo,
};

const emptyProductLocalization = {
  covidSafetyMeasures: [emptyDocLocNodeData],
  covidTravelerRequirements: [emptyDocLocNodeData],
  importantInformationBeforeYouGo: [emptyDocLocNodeData],
  importantInformationNotAllowed: [emptyDocLocNodeData],
  importantInformationWhatToBring: [emptyDocLocNodeData],
  notSuitableFor: [emptyDocLocNodeData],
  productExcludes: [emptyDocLocNodeData],
  productFeatures: [emptyDocLocNodeData],
  productIncludes: [emptyDocLocNodeData],
  productInformation: [emptyDocLocNodeData],
};

// better pattern use this instead of the former
export const getTranslationForLanguage = (
  localizations: BaseLocalization[],
  locale: AppLocale,
  localesPropName: string,
  showSource: boolean = true,
): string => {
  const localization = localizations.find(
    (loc) => loc.localeId === getDefaultLocaleForCiMode(locale),
  );
  return showSource && isCimode(locale)
    ? // ? `${sourceInfo} ${localization?.locales?.[localesPropName]}` // TODO: this way with all replacements
      sourceInfo
    : localization?.locales?.[localesPropName] || '';
};
export function  getCurrencySign (currencyId: string) {
  switch (currencyId) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'BTC':
      return '₿';
    default:
      throw Error('unknown currency id');
  }
};

export const getTimelineForLanguage = (
  localizations: TimelineLocalization[],
  locale: AppLocale,
  showSource: boolean = true,
): TimelineLocalizationNodeData[] => {
  const localization = localizations.find(
    (loc) => loc.localeId === getDefaultLocaleForCiMode(locale),
  );
  return showSource && isCimode(locale)
    ? localization?.timeline.map(() => ({ time: sourceInfo }))
    : localization?.timeline || [];
};

export const getProductLocalizations = (
  localizations: TimelineLocalization[],
  locale: AppLocale,
  showSource: boolean = true,
): ProductLocalization => {
  const localization = localizations.find(
    (loc) => loc.localeId === getDefaultLocaleForCiMode(locale),
  );
  return showSource && isCimode(locale)
    ? emptyProductLocalization // TODO: map tru
    : localization?.product || [];
};

export const getMediaLocalizations = (
  localizations: TimelineLocalization[],
  locale: AppLocale,
  showSource: boolean = true,
): MediaLocalization => {
  const localization = localizations.find(
    (loc) => loc.localeId === getDefaultLocaleForCiMode(locale),
  );
  return showSource && isCimode(locale)
    ? [] // TODO: map tru
    : localization?.media || [];
};

const fallbackSeo: SeoData = { description: '', title: '' };

export const getSeoForLanguage = (
  localizations: SeoLocalization[],
  locale: AppLocale,
  localesPropName: string,
  showSource: boolean = true,
): SeoData => {
  const localization = localizations.find(
    (loc) => loc.localeId === getDefaultLocaleForCiMode(locale),
  );
  return showSource && isCimode(locale)
    ? { description: sourceInfo, title: sourceInfo }
    : localization?.seo?.[localesPropName] || fallbackSeo?.[localesPropName];
};

// TODO:
const fallbackLang = AppLocale.EN;

/** @deprecated */
export const getDocumentLocalizedPropValue = (
  data: BaseLocalizedDocumentData,
  localizationsPropName: string,
  locale: AppLocale,
  localesPropName: string,
  showSource: boolean = true,
): string => {
  const localization = data?.[localizationsPropName]?.find(
    (loc) =>
      loc.localeId === getDefaultLocaleForCiMode(locale)) || data?.[localizationsPropName]?.find(
        (loc) => loc.localeId === fallbackLang,
  );
  return showSource && isCimode(locale)
    ? sourceInfo
    : localization?.locales?.[localesPropName] || '';
};

// TODO: make a version which accepts array of desired values which gets transformed to obj with typing? or remove
// FIXME: products has title, system data uses name...
export const getBaseDocumentLocales = (
  document: BaseLocalizedDocumentData,
  locale: AppLocale,
  showSource: boolean = true,
) => {
  return {
    description:
      showSource && isCimode(locale)
        ? sourceInfo
        : getDocumentLocalizedPropValue(
            document,
            'localizations',
            getDefaultLocaleForCiMode(locale),
            'description',
          ),
    shortDescription:
      showSource && isCimode(locale)
        ? sourceInfo
        : getDocumentLocalizedPropValue(
            document,
            'localizations',
            getDefaultLocaleForCiMode(locale),
            'shortDescription',
          ),
    name:
      showSource && isCimode(locale)
        ? sourceInfo
        : getDocumentLocalizedPropValue(
            document,
            'localizations',
            getDefaultLocaleForCiMode(locale),
            'name',
          ),
    teaser:
      showSource && isCimode(locale)
        ? sourceInfo
        : getDocumentLocalizedPropValue(
            document,
            'localizations',
            getDefaultLocaleForCiMode(locale),
            'teaser',
          ),
    title:
      showSource && isCimode(locale)
        ? sourceInfo
        : getDocumentLocalizedPropValue(
            document,
            'localizations',
            getDefaultLocaleForCiMode(locale),
            'title',
          ),
  };
};
