import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAppContext, useFirebaseCollection } from 'src/app/hooks';

import { Accordion, Glass, Grid, Typography, VirtualizedTable } from 'src/app/components';
import {
  BaseComponentProps,
  CollectionModel,
  CollectionQuery,
  DatabaseCollectionOptions,
} from 'src/app/types';

import { OrganisationAdminDialogType } from 'src/app.organisation/constants';
import { AdminCollectionToolbar } from './AdminCollectionToolbar';
import { AdminCollectionFilter } from './AdminCollectionFilter';

type CollectionTableProps = DatabaseCollectionOptions<CollectionModel> &
  BaseComponentProps;

interface AdminCollectionTableProps extends CollectionTableProps {
  query?: CollectionQuery;
}

// TODO: remove and use common component
const AdminCollectionTable = <T extends CollectionModel>(
  props: AdminCollectionTableProps,
) => {
  const { collectionName, dataProperties, isCollectionGroup, query: queryProp } = props;
  const { isLoggedIn, openDialog /* , setDialogItems */ } = useAppContext();
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(true);
  const [query, setQuery] = React.useState<CollectionQuery | null>(queryProp || null);
  const [results, setResults] = React.useState<T[] | null>(null);
  const [selectedTableRowItem, setSelectedTableRowItem] = React.useState<T | null>(null);

  // TODO: reimplement with new FB api

  // const { results: fetchResults } = useFirebaseCollection<T>(
  //   collectionName,
  //   !!isCollectionGroup,
  //   isLoggedIn,
  //   [],
  //   // dataProperties,
  //   true,
  //   shouldUpdate,
  //   0,
  //   query,
  // );

  const fetchResults = [];

  // stop fetching after a new update
  // React.useEffect(() => {
  //   if (
  //     fetchResults &&
  //     JSON.stringify(fetchResults) !== JSON.stringify(results) &&
  //     shouldUpdate
  //   ) {
  //     setResults(fetchResults);
  //     setShouldUpdate(false);
  //   }
  // }, [fetchResults, results, shouldUpdate]);

  const header = dataProperties
    ? [
        { dataKey: 'index', label: '#', width: 48 },
        // ...dataProperties.forEach((property) => {
        //   if (property.type === 'string') {
        //     return {
        //       dataKey: property.name,
        //       label: property.name,
        //       width: 160,
        //     };
        //   }
        // }),
        // ...dataProperties.map((property) => {
        //   if (property.type === 'string') {
        //     return {
        //       dataKey: property.name,
        //       label: property.name,
        //       width: 160,
        //     };
        //   }
        // }),
        // { dataKey: 'actions', label: 'Actions', width: 160 },
      ]
    : [];

  if (dataProperties && dataProperties.length) {
    dataProperties.forEach((property) => {
      if (property.type === 'string') {
        header.push({
          dataKey: property.name,
          label: property.name,
          width: 160,
        });
      }
    });
  }

  // TODO: create/use complex data component mapping
  const data = results
    ? [
        ...results.map((colData, idx) => {
          // render only known data types
          return { index: idx + 1, ...colData };
        }),
      ]
    : [];

  const actionOptions = {
    hasCreateOption: true,
    hasEditOption: true,
    hasDeleteOption: true,
  };

  const onRowClick = (e: { e: any; index: number; rowData: T }) => {
    setSelectedTableRowItem(
      selectedTableRowItem && selectedTableRowItem.id === e.rowData.id ? null : e.rowData,
    );
  };

  const onCreateDocument = () => {
    // setDialogItems(selectedTableRowItem ? [selectedTableRowItem] : []);  // TODO
    // TODO: maybe create a separate openAdminDialog/openSystemDialog etc
    openDialog(OrganisationAdminDialogType.create, { collectionName });
  };

  const onDeleteDocument = () => {
    // setDialogItems(selectedTableRowItem ? [selectedTableRowItem] : []);  // TODO
    openDialog(OrganisationAdminDialogType.delete, { collectionName });
  };

  const onEditDocument = () => {
    // setDialogItems(selectedTableRowItem ? [selectedTableRowItem] : []);  // TODO
    openDialog(OrganisationAdminDialogType.edit, { collectionName });
  };

  return (
    <Glass style={{ margin: '32px 0' }}>
      <Grid container>
        <Grid item xs={12} style={{ padding: '16px' }}>
          <Accordion
            // expanded
            // hideSummary
            style={{ borderWidth: '0', width: '100%' }}
            expandIcon={<ExpandMoreIcon />}
            summary={<h2>{`${collectionName} collection filter`}</h2>}
          >
            <AdminCollectionFilter query={query} onUpdateQuery={setQuery} />
          </Accordion>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ padding: '8px', display: 'flex', flexDirection: 'column' }}
        >
          <Typography align={'center'} variant={'overline'}>
            {selectedTableRowItem
              ? `Selected document: ${selectedTableRowItem.id || ''}`
              : 'No document selected'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AdminCollectionToolbar<T>
            onCreateDocument={onCreateDocument}
            onDeleteDocument={onDeleteDocument}
            onEditDocument={onEditDocument}
            hasSelectedDocument={!!selectedTableRowItem}
            documentActionOptions={actionOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <VirtualizedTable<T> header={header} data={data} onRowClick={onRowClick} />
        </Grid>
      </Grid>
    </Glass>
  );
};

export { AdminCollectionTable };
