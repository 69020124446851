import * as React from 'react';
import { updateDoc, doc, getFirestore } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth';
import { Router } from '@reach/router';

import { useStyles } from 'src/app/context';
import { NotificationType } from 'src/app/constants';

import {
  Grid,
  LoadingIndicator,
  Localized,
  PrivateRoute,
  Typography,
} from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { FormRootContainer } from 'src/app/components/RHF';
import { UserProfileInformationData } from 'src/app/types';

// LOCAL IMPORTS
import { FirestoreDocData } from 'src/app/container';
import { UserAccountForm, UserAccountFormProps } from './UserAccountProfileForm';

import {
  defaultValues,
  // defaultValues__DEV,
  createDocumentResolver,
} from './userAccountProfile.forms.config';

const documentI18nBasePath = 'shop:/account/profile.form';
const routeBasepath = 'account/profile';
type DocumentType = UserProfileInformationData;

const UserProfileScreen = () => {
  const { createStackedNotification, firebase, isAppDebug, user } = useAppContext();
  // const { language } = useLocalizationContext();
  const styles = useStyles();
  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);

  if (typeof window === 'undefined') {
    return null;
  }

  const onSubmit = async (data: DocumentType) => {
    try {
      if (!firebase || !user?.uid) {
        return null;
      }
      setRequestingUpdate(true);

      const auth = getAuth(firebase);

      if (!auth.currentUser) {
        return null;
      }

      await updateProfile(auth.currentUser, {
        displayName: data.firstName || '',
        // phoneNumber: data.phoneNumber || '',
      });

      const { email, ...restData } = data;

      await updateDoc(doc(getFirestore(firebase), `user/${user.uid}`), restData);

      createStackedNotification(
        NotificationType.SUCCESS,
        <Localized dictKey={'account:action.update-user-profile.notification.success'} />,
      );
    } catch (err) {
      console.warn(err);
      createStackedNotification(
        NotificationType.ERROR,
        <Localized
          dictKey={'account:action.update-user-profile.notification.error'}
          translationOptions={{ errorMsg: err.message || '' }}
        />,
      );
    } finally {
      setRequestingUpdate(false);
    }
    return null;
  };

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    console.log('dataRaw: ', dataRaw);
    const data: DocumentType = {
      ...dataRaw,
      // id: docId,
      // password umpopeln
    };

    // TODO: handle password => create account logic
    // TODO: maybe create account first then send organisation confirmation

    await onSubmit(data);
  };

  const isSubmitDataValid = true;

  return !user?.uid ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          <Localized dictKey={'account:user-not-logged-in'} />
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <FirestoreDocData<DocumentType>
      useSubscription
      documentPath={`user/${user.uid}`}
      renderElements={({ data: result, status }) => {
        if (status === 'loading' || isRequestingUpdate) {
          return <LoadingIndicator />;
        }
        if (status === 'error') {
          return 'Error';
          // <LocalizedErrorIndicator
          //   i18nContext={'organisation'}
          //   i18nErrorCode={'LOADING_DOCUMENT'}
          //   i18nErrorAreaCode={'organisation'}
          // />
        }
        return status !== 'success' || !result?.id ? null : (
          <FormRootContainer<DocumentType>
            className={styles.px__4}
            defaultValues={
              {
                firstName: result?.firstName || defaultValues.firstName,
                lastName: result?.lastName || defaultValues.lastName,
                email: result?.email || defaultValues.email,
                phoneNumber: result?.phoneNumber || defaultValues.phoneNumber,
                dob: result?.dob?.toDate ? result.dob.toDate() : defaultValues.dob,
              } as DocumentType
            }
            // isDebug={isDebug}
            resolver={createDocumentResolver}
            onSubmit={onSubmitWrapper}
            useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
            isDebug={isAppDebug}
            renderForm={({ formProps }) => {
              return (
                <Router basepath={routeBasepath} style={{ width: '100%' }}>
                  <PrivateRoute<UserAccountFormProps<DocumentType>>
                    path={'/*'}
                    component={UserAccountForm}
                    passProps={{
                      formProps,
                      i18nBasePath: documentI18nBasePath,
                      // routeBasepath,
                      isSubmitDataValid,
                    }}
                  />
                </Router>
              );
            }}
          />
        );
      }}
    />
  );
};

export const UserAccountProfileFormRouter = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Router style={{ width: '100%' }}>
          <PrivateRoute<{}> path={'/*'} component={UserProfileScreen} />
        </Router>
      </Grid>
    </Grid>
  );
};
