import * as React from 'react';

import { Button, DropDown, Grid, TextInputField, Toolbar, Typography } from 'src/app/components';

import { WhereFilterOperator } from 'src/admin/constants';
import { CollectionQuery } from 'src/app/types';

export interface AdminCollectionFilterProps {
  query?: CollectionQuery | null;
  onUpdateQuery: (query: CollectionQuery) => void;
}
// TODO: debounce queries
const AdminCollectionFilter = (props: AdminCollectionFilterProps) => {
  const { query, onUpdateQuery } = props;

  const onQueryChange = (q: CollectionQuery) => {
    onUpdateQuery(q);
  };

  return (
    <Toolbar style={{ margin: '16px 0' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>QueryBuilder: </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputField
            fullWidth
            label='propertyName'
            // variant={'outlined'}
            onChangeCapture={(e) =>
              onQueryChange({
                collectionItemProperty: e.target.value,
                whereFilterOperator: query?.whereFilterOperator!,
                compareValue: query?.compareValue!,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DropDown
            fullWidth
            // variant='text'
            // variant={'outlined'}
            label={query?.whereFilterOperator ?? 'whereFilterOperator'}
            options={WhereFilterOperator}
            onChange={(idx) => {
              onQueryChange({
                collectionItemProperty: query?.collectionItemProperty!,
                whereFilterOperator: WhereFilterOperator[(idx as any) as number],
                compareValue: query?.compareValue!,
              });
            }}
            style={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputField
            fullWidth
            label='compareValue'
            // variant={'outlined'}
            onChangeCapture={(e) =>
              onQueryChange({
                collectionItemProperty: query?.collectionItemProperty!,
                whereFilterOperator: query?.whereFilterOperator!,
                compareValue: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <code>Query preview: {query ? JSON.stringify(query) : 'null'}</code>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => {
              onQueryChange({
                collectionItemProperty: null,
                whereFilterOperator: null,
                compareValue: null,
              });
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export { AdminCollectionFilter };
