import * as React from 'react';
import clsx from 'clsx';
// FIXME: create fallback style for browsers without backdropFilter support

import { global_borderRadius } from 'src/app/context/Theme/muiTheme';

// -webkit-backdrop-filter: saturate(200%) blur(20px);
// backdrop-filter: saturate(200%) blur(20px);

export interface GlassProps {
  children: React.ReactNode;
  className?: string;
  borderRadius?: string;
  container?: boolean;
  blurAmount?: number;
  opacity?: number;
  lowShade?: boolean;
  noShade?: boolean;
  // wrong semantic

  style?: Record<string, unknown>;
  themeMode?: 'dark' | 'light' | 'default';
}
/* #GLASS */
// TODO: remove glassContainerStyle since it is possibly not used anywhere => what was it for??
/**
 * Glass
 * @param { GlassProps } props
 * @version 0.0.0
 * @description
 * @todo fallback for non-backdropfilter blurfilter browsers
 * @example
 * @deprecated Do not use anymore => switch to GlassPaper
 */
export const Glass = (props: GlassProps) => {
  const {
    borderRadius,
    className,
    blurAmount,
    children,
    container,
    lowShade,
    noShade,
    opacity,
    style,
    themeMode,
    ...passProps
  } = props;
  let shadeOpacity = noShade ? 0 : 0.37;
  if (lowShade) {
    // shadeOpacity = 0.185;
    shadeOpacity = 0.26;
  }
  const boxShadowP1 = noShade ? 8 : 16;
  const boxShadowP2 = noShade ? 16 : 32;
  // const boxShadow = `0 ${boxShadowP1}px ${boxShadowP2}px 0 rgba( 31, 38, 135, ${shadeOpacity} )`;
  // const background = `rgba( ${themeMode === 'dark' ? '35, 50, 50,' : '237, 255, 255,'} ${
  //   opacity ?? '0.1'
  // })`;
  // FIXME: make shades respects the theme colors
  const boxShadow = `0 ${boxShadowP1}px ${boxShadowP2}px 0 rgba( 38, 38, 38, ${shadeOpacity} )`;
  const background = `rgba( ${themeMode === 'dark' ? '50, 50, 50,' : '230, 230, 230,'} ${
    opacity ?? '0.1'
  })`;
  const blurFilter = blurAmount ? `blur( ${blurAmount}px )` : 'blur( 0.1px )';

  const glassStyle = {
    // backgroundColor: themeMode === 'dark' ? '#00000044' : '#ffffff44', // as fallback I guess?
    background,
    boxShadow,
    backdropFilter: blurFilter,
    WebkitBackdropFilter: blurFilter,
    borderRadius: borderRadius || global_borderRadius,
    border: '1px solid rgba( 240, 235, 255, 0.05 )',
    // backgroundColor: '#f00',
    // color: '#f0f',
  };

  // was used in appbar but not used anymore right now
  const glassContainerStyle = {
    background,
    boxShadow,
    backdropFilter: blurFilter,
    WebkitBackdropFilter: blurFilter,
    // borderRadius: borderRadius ?? '4px',
    // border: '1px solid rgba( 255, 255, 255, 0.18 )',
  };
  return container ? (
    <div
      // className={className ?? ''}
      className={clsx('glass-item', className ?? '')}
      style={style ? { ...glassContainerStyle, ...style } : glassContainerStyle}
      {...passProps}
    >
      {children}
    </div>
  ) : (
    <div
      // className={className ?? ''}
      // className={cls.container}
      className={clsx('glass-item', className ?? '')}
      style={style ? { ...glassStyle, ...style } : glassStyle}
      {...passProps}
    >
      {children}
    </div>
  );
};
