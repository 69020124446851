import * as React from 'react';
import { useCookies } from 'react-cookie';

import { CurrencyState } from 'src/app/types'; // TODO:
import { Currency } from 'src/app/constants';
import { useSourcedCurrencies } from 'src/app/hooks';
// import { FrontendCurrencyData } from 'src/app/types';

const currencyCookieName = 'cur';

export function useCurrencyState(): CurrencyState {
  const [cookies, setCookie] = useCookies([currencyCookieName]);
  const availableCurrencies = useSourcedCurrencies();
  // const [currency, setCurrency] = React.useState<Currency>(cookies.cur || Currency.EUR);
  const currentCurrency = availableCurrencies.find(
    (cur) => cur.id === cookies[currencyCookieName],
  );
  const currencyId = currentCurrency ? currentCurrency.id : 'EUR'; // fallback

  const setCurrency = (newCurId: string) => {
    setCookie(currencyCookieName, newCurId, { path: '/' });
  };

  // set initial currency
  React.useEffect(() => {
    if (!cookies[currencyCookieName]) {
      setCurrency(Currency.EUR);
    }
  }, [cookies]);
  return { currencyId, setCurrency };
}
