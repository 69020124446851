import * as React from 'react';
import Chip from '@material-ui/core/Chip';

import { Divider, Grid, Localized, Typography } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';

import { useStyles } from 'src/app/context';
import clsx from 'clsx';

interface AvailabilityAndLanguagesProps {
  product: SourcedGQLProduct;
}

export const AvailabilityAndLanguages = ({ product }: AvailabilityAndLanguagesProps) => {
  const { availability } = product;
  const languages = availability.daytimeEvents[0].languageIds;
  const styles = useStyles();

  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;
  const basePath = 'general-information.availability';
  const fieldPath = `${basePath}.field`;

  return (
    <>
      <h3 className='text-p2 font-semibold mb-2 underline md:no-underline md:text-h4 md:font-bold md:mb-12'>  
        <Localized dictKey={`${i18nPrefix}.${basePath}.title`} />
      </h3>        
      
      <div className='flex flex-col gap-6 md:gap-12'>
        {availability?.weekdays ? (        
          <div>
            <p className='text-p3 mb-1 font-normal md:text-p2 md:mb-2'>
              <Localized dictKey={`${i18nPrefix}.${fieldPath}.weekdays`} />
            </p>
            <div className="flex flex-wrap gap-1 md:gap-y-2">
              {availability.weekdays.map((wd) => {
                // TODO: sort by week order
                return (
                  <span
                    className={'text-p2 bg-accent-500 capitalize text-neutral-900 inline-flex items-center rounded-xl px-4 font-medium h-7'}
                    key={wd}
                  >           
                    {/** // TODO: i18n */}
                    {` ${String(wd)} `}
                    {/** // TODO: sort + i18n days */}                            
                  </span>
                );
              })}
            </div>
          </div>            
          
        ) : null}

        {/* {availability?.daytimeEvents?.length && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  align={'left'}
                  style={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                >
                  <Localized dictKey={`${i18nPrefix}.${fieldPath}.startTime`} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align={'left'}
                  style={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                >
                  <Localized dictKey={`${i18nPrefix}.${fieldPath}.languages`} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            {availability?.daytimeEvents?.map((daytimeEvent, idx) => {
              return (
                <Grid key={`daytimeEvent_${idx}`} container spacing={1}>
                  <Grid item xs={6}>
                    <Typography align={'left'}>
                      {daytimeEvent.startTime.split(':')[0]} :{' '}
                      {daytimeEvent.startTime.split(':')[1]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align={'left'}>
                      {daytimeEvent.languageIds.map((langId) => {
                        // TODO: use flags
                        return (
                          <Chip
                            className={clsx(styles.px__2, styles.ml__1)}
                            key={langId}
                            variant='outlined'
                            color='primary'
                            size='small'
                            style={{ margin: '2px' }}
                            label={
                              <Typography align={'center'} renderAs={'span'}>
                                {` ${String(langId).toUpperCase()} `}
                              </Typography>
                            }
                          />
                        );
                      }) || null}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )} */}

        {availability?.daytimeEvents?.map((daytimeEvent, idx) => {
          return (
            <div key={`daytimeEvent_${idx}`}>          
              <p className='text-p2 font-semibold md:text-p1 md:font-semibold'>
                <Localized
                  dictKey={`${i18nPrefix}.${fieldPath}.startTime`}
                  translationOptions={{
                    time: {
                      hours: daytimeEvent.startTime.split(':')[0] || '',
                      minutes: daytimeEvent.startTime.split(':')[1] || '',
                    },
                  }}
                />
              </p>
              <p className='text-p3 font-normal mb-1 md:mb-2'>
                <Localized dictKey={`${i18nPrefix}.${fieldPath}.languages`} />
              </p>
              <div className="flex flex-wrap gap-1 md:gap-y-2">
                {daytimeEvent.languageIds.map((langId) => {
                  // TODO: use flags
                  return (
                    <span
                      className={'text-p2 bg-accent-500 capitalize text-neutral-900 inline-flex items-center rounded-xl px-4 font-medium h-7'}
                      key={langId}>
                        {/** // TODO: i18n */}
                        {` ${String(langId)} `}
                    </span>                        
                    
                  );
                }) || null}
              </div>
            </div>
          );
        })}    
      </div>  
     
    </>
  );
};
