import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
// import PaletteIcon from '@material-ui/icons/Palette';
// import IconButton from '@material-ui/core/IconButton';

import { Localized, TextInputField } from 'src/app/components';

import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FormFieldLayout } from '../Layouts';

import { FormFieldProps, NestedFieldProps } from '../form.types';
import { FieldStyle } from '../form.constants';

const FormColorField = ({
  children,
  fieldStyle,
  i18nKey,
  name,
  rules,
  defaultValue,
  ...passProps
}: FormFieldProps<{}>) => {
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
  } = formMethods;

  const controllerArgs = {
    name,
    control,
    rules,
    defaultValue,
  };

  const controller = useController(controllerArgs);
  const hasError = !!errors?.[name]; // TODO:

  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = controller;

  // console.warn('controller: ', controller);

  // return null;
  return (
    <FormFieldLayout
      additionalBottomArea={
        !fieldStyle || (fieldStyle && fieldStyle === FieldStyle.FORM) ? (
          <FieldInformation
            hasError={hasError}
            description={<Localized dictKey={`${i18nKey}.field.description`} />}
            example={<Localized dictKey={`${i18nKey}.field.example`} />}
          >
            {children}
          </FieldInformation>
        ) : null
      }
      debugId={name}
      debugI18nKey={i18nKey}
      fieldStyle={fieldStyle}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <TextInputField
          type={'color'}
          variant={'outlined'}
          {...inputProps}
          {...passProps}
          value={inputProps.value || ''}
          inputRef={ref}
          error={hasError}
          fullWidth
          // helperText={<div style={{ backgroundColor: 'red' }}>Heeeelp</div>}
          // helperText={'Heeeelp'}
          InputProps={{
            endAdornment: (
              <>
                {/* <PaletteIcon
                  style={inputProps.value ? { color: inputProps.value } : {}}
                /> */}
                <FieldAdornment hasError={hasError} />
              </>
            ),
          }}
          style={inputProps.value ? { color: inputProps.value } : {}}
        />
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

// updatemode ?
// if is "update" mode render different version?
// all versions have to be passed then (create(form)/update(form/singleFields))
// update involves whole documents or /(and) single fields on documents, maybe even nested ones...
export const ColorField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps<{}>) => (
  <FormColorField
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormColorField>
);
