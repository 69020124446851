import * as React from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { nanoid } from 'nanoid';

import { NotificationType, imgRandomUidLength } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import { FileMetaData, FileUploadSuccessResult } from 'src/app/types';
import { getFileExtension } from 'src/app/lib';

export interface UseUploadFiles {
  isUploading: boolean;
  hasUploadError: boolean;
  hasUploadSuccess: boolean;
  uploadFiles: (
    files: File[],
    storagePath: string,
    fileNames: string[],
  ) => Promise<FileUploadSuccessResult[]>;
  uploadProgress: number;
  uploadFileResults: FileUploadSuccessResult[];
}

// enum FirebaseStorageTaskEvent {
//   STATE_CHANGED = 'state_changed',
//   ERROR = 'error',
//   PAUSED = 'paused',
//   RUNNING = 'running',
//   SUCCESS = 'success',
// }

// enum FirebaseStorageTaskErrorType {
//   UNAUTHORIZED = 'storage/unauthorized',
//   CANCELED = 'storage/canceled',
//   UNKNOWN = 'storage/unknown',
// }

export const useUploadFiles = (
  successNotification: string | React.ReactNode,
  errorNotification: string | React.ReactNode,
): UseUploadFiles => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [hasUploadError, setHasUploadError] = React.useState<boolean>(false);
  const [hasUploadSuccess, setHasUploadSuccess] = React.useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [uploadFileResults, setUploadFileResults] = React.useState<
    FileUploadSuccessResult[]
  >([]);

  const fileStorageUpload = async (
    filePath: string,
    file: File,
    fileMetaData?: FileMetaData,
  ): Promise<FileUploadSuccessResult> => {
    if (!firebase) {
      return Promise.reject(new Error('Invalid precondition - fb'));
    }
    try {
      const fileData = await file;
      const fileRef = ref(getStorage(firebase), filePath);
      const task = fileMetaData
        ? uploadBytes(fileRef, fileData, fileMetaData)
        : uploadBytes(fileRef, fileData);
      await task;
      createNotification(NotificationType.SUCCESS, successNotification, 4000);
      setIsUploading(false);
      const downloadUrl = await getDownloadURL(fileRef);
      // console.log('___downloadUrl: ', downloadUrl);
      const result: FileUploadSuccessResult = {
        downloadUrl,
        uploadStoragePath: filePath,
      };
      return result;
      // return getDownloadURL(fileRef).then((downloadUrl: string) => {
      //   const result: FileUploadSuccessResult = {
      //     downloadUrl,
      //     uploadStoragePath: filePath,
      //   };
      //   return result;
      // });
    } catch (err) {
      console.warn('Upload file error: ', err);
      createNotification(NotificationType.ERROR, errorNotification, 4000);
      setHasUploadError(true);
      setIsUploading(false);
    }
    // NOTE: old fb v8 code
    // task.on(
    //   // https://firebase.google.com/docs/reference/js/firebase.storage#taskevent
    //   FirebaseStorageTaskEvent.STATE_CHANGED,
    //   (snapshot) => {
    //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     console.log(`Upload is ${progress}% done`);
    //     // setUploadProgress(percent); // needs to be overall Progress (racing condition)
    //     switch (snapshot.state) {
    //       case FirebaseStorageTaskEvent.PAUSED:
    //         console.log('Upload is paused');
    //         break;
    //       case FirebaseStorageTaskEvent.RUNNING:
    //         console.log('Upload is running');
    //         break;
    //       default:
    //         break;
    //     }
    //   },
    //   (error) => {
    //     // A full list of error codes is available at
    //     // https://firebase.google.com/docs/storage/web/handle-errors
    //     switch (error.code) {
    //       case FirebaseStorageTaskErrorType.UNAUTHORIZED:
    //         // User doesn't have permission to access the object
    //         break;
    //       case FirebaseStorageTaskErrorType.CANCELED:
    //         // User canceled the upload
    //         break;

    //       // ...

    //       case FirebaseStorageTaskErrorType.UNKNOWN:
    //         // Unknown error occurred, inspect error.serverResponse
    //         break;

    //       default:
    //         break;
    //     }
    //     createNotification(NotificationType.ERROR, errorNotification, 4000);
    //     setIsUploading(false);
    //     setHasUploadError(true);
    //   },
    //   () => {
    //     // Upload completed successfully, now we can get the download URL
    //     createNotification(NotificationType.SUCCESS, successNotification, 4000);
    //   },
    // );

    // OPTIONAL:
    //   // Pause the upload
    //   // task.pause();

    //   // Resume the upload
    //   // task.resume();

    //   // Cancel the upload
    //   // task.cancel();
    // const uploadTask = await task;
    // return getDownloadURL(uploadTask.ref).then((downloadUrl: string) => {
    //   const result: FileUploadSuccessResult = {
    //     downloadUrl,
    //     uploadStoragePath: filePath,
    //   };
    //   return result;
    // });
  };

  const uploadFiles = async (
    files: File[],
    storagePath: string,
    fileNames: string[],
    fileMetas?: FileMetaData[],
  ): Promise<FileUploadSuccessResult[]> => {
    setIsUploading(true);
    if (
      typeof window === 'undefined' ||
      !firebase ||
      !isLoggedIn ||
      // !files?.length ||
      // !fileNames.length ||
      // files.length !== fileNames.length
      (files && files.length && (!fileNames.length || files.length !== fileNames.length))
    ) {
      return Promise.reject(new Error('Invalid precondition'));
    }

    if (!files?.length) {
      return Promise.resolve([]);
    }

    const tasks: Promise<FileUploadSuccessResult>[] = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const fileName = fileNames[index];
      const fileExtension = getFileExtension(file);
      const filePath = `${storagePath}/${
        fileName
          ? `${fileName}.${fileExtension}`
          : `new_file_${nanoid(imgRandomUidLength)}.${fileExtension}` // TODO use const
      }`;
      const fileMetaData = fileMetas?.[index];
      tasks.push(fileStorageUpload(filePath, file, fileMetaData));
    }

    const uploadResults = await Promise.all([...tasks]);

    createNotification(NotificationType.SUCCESS, successNotification, 4000);
    setUploadFileResults(uploadResults);
    setIsUploading(false);
    setHasUploadSuccess(true);
    return uploadResults;
  };

  return {
    hasUploadError,
    hasUploadSuccess,
    isUploading,
    uploadFiles,
    uploadProgress,
    uploadFileResults,
  };
};
