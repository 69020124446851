import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { BaseComponentProps } from 'src/app/types';

import { Divider, GlassPaper, Grid, Typography } from 'src/app/components';

import { useLocalizedNavigation, useSourcedProducts } from 'src/app/hooks';

import { ProductLoader } from './ProductLoader';

export interface ProductLoaderContainerProps extends BaseComponentProps {
  description: string | JSX.Element;
  itemsAddPerStepCount?: number;
  title: string | JSX.Element;
}

// compare CountryProductsContainer for outer layout => same
export const ProductLoaderContainer = (props: ProductLoaderContainerProps) => {
  const { className, description, itemsAddPerStepCount, title } = props;
  const products = useSourcedProducts();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const { navigateLocalized } = useLocalizedNavigation();

  const onClick = (productId: string) => {
    navigateLocalized(`/experience/${productId}`);
  };

  return (
    <>
      <div className='pb-2 px-4 md:px-0 text-neutral-900 dark:text-neutral-50'>
        <h2 className='text-h4 font-semibold md:text-h2 md:font-bold'>{title || ''}</h2>
        {/* <h3 className='text-p1'>{description}</h3> */}
      </div>

      <ProductLoader
        collection={products}
        itemsAddPerStepCount={itemsAddPerStepCount || 8}
        onClick={onClick}
      />
    </>
  );
};
