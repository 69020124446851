import React from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import { useStyles } from 'src/app/context';

import Check from '@material-ui/icons/Check';

import { Divider, Grid, Typography } from 'src/app/components';

export const InfoBox = ({
  className,
  title,
  additionalInformation,
}: {
  className?: string;
  title: string | React.ReactElement;
  additionalInformation?: string | React.ReactElement;
}) => {
  const styles = useStyles();
  return (
    <div className={className}>
      <p className='text-p2 relative pl-8'>
        <span className='text-accent-500 absolute left-0 top-1'>
          <Check />
        </span>
        {title}       
      </p>
      {additionalInformation ? (
        <p className='text-p3 font-light pl-8'>
          {additionalInformation}
        </p>         
      ) : null}
    </div>
  );
};
