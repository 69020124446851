import { FieldValues } from 'react-hook-form';

type ArrayFormValues =
  | boolean
  | number
  | string
  | Record<string, unknown>
  | Record<string, unknown>[];

export const getArrayDefaultValue = <T extends ArrayFormValues>(
  arrayItems: FieldValues,
  arrayItemIndex: number,
  propertyName: string,
): T | undefined => {
  return arrayItems?.[arrayItemIndex]?.[propertyName] || undefined;
};

export const composeFieldName = (fieldDefaultName: string, fieldNamePrefix?: string) =>
  fieldNamePrefix ? `${fieldNamePrefix}.${fieldDefaultName}` : fieldDefaultName;
