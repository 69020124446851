import * as React from 'react';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import DirectionsIcon from '@material-ui/icons/Directions';
// import ForwardIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import {
  Accordion,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from 'src/app/components';
import { useStyles, useLocalizationContext, useAppContext } from 'src/app/context';

import { MapMarkerObject, SourcedGQLCity } from 'src/app/types';
import { getTranslationForLanguage } from 'src/lib/document';
import { useLocalizedNavigation } from 'src/app/hooks';

export interface AdditionalCityMapInformationProps {
  city: SourcedGQLCity;
  markers: MapMarkerObject[];
}

export const AdditionalCityMapInformation = ({
  city,
  markers,
}: AdditionalCityMapInformationProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const { isAppDebug } = useAppContext();
  const { language } = useLocalizationContext();
  const { navigateLocalized } = useLocalizedNavigation();
  return (
    <Grid container spacing={2} className={isWiderMD ? styles.pl__4 : ''}>
      <Grid item xs={12}>
        <div className={clsx(styles.py__2, styles.mb__2)}>
          <Typography color={'primary'} align={'center'} variant={'h5'} renderAs={'h2'}>
            Popular places to visit in the area of{' '}
            {getTranslationForLanguage(city.localizations, language, 'name', isAppDebug)}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography align={'center'}>Places to visit in the area of {city.id}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid> */}
      {markers?.map((marker, idx) => {
        return (
          <React.Fragment key={marker.id}>
            <Grid item xs={10}>
              <Accordion
                elevation={0}
                // expanded
                // hideSummary
                style={{ borderWidth: '0', width: '100%' }}
                expandIcon={<ExpandMoreIcon />}
                summary={
                  <Grid container>
                    <Grid item xs={3}>
                      <Toolbar className={styles.ml__0}>
                        <LocationOnIcon style={{ color: marker.color }} />
                        <Typography
                          align={'left'}
                          className={!isWiderMD ? styles.ml__0 : ''}
                        >
                          {
                            getTranslationForLanguage(
                              marker.localizations,
                              language,
                              'label',
                              isAppDebug,
                            )[0] // Display first letter only
                          }
                        </Typography>
                      </Toolbar>
                    </Grid>
                    <Grid item xs={9}>
                      <Toolbar>
                        <Typography align={'left'} variant={'subtitle1'}>
                          {getTranslationForLanguage(
                            marker.localizations,
                            language,
                            'name',
                            isAppDebug,
                          )}
                        </Typography>
                      </Toolbar>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={9}>
                      <Typography
                        align={'left'}
                        variant={'caption'}
                        className={styles.pl__1}
                      >
                        {getTranslationForLanguage(
                          marker.localizations,
                          language,
                          'teaser',
                          isAppDebug,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              >
                <Grid container>
                  <Grid item xs={3} />
                  <Grid item xs={9}>
                    <Typography
                      align={'left'}
                      variant={'caption'}
                      className={styles.pl__1}
                    >
                      {getTranslationForLanguage(
                        marker.localizations,
                        language,
                        'shortDescription',
                        isAppDebug,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
            <Grid item xs={2}>
              <Toolbar>
                <Button
                  variant={'text'}
                  onClick={() => navigateLocalized(`/location/${marker.id}`)}
                >
                  <DirectionsIcon color={'primary'} />
                </Button>
              </Toolbar>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        );
      }) || null}
    </Grid>
  );
};
