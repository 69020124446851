import * as React from 'react';
// import { Resolution } from 'src/app/types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import styled from 'styled-components';

import {
  Button,
  ButtonGroup,
  Grid,
  // CenteredContainer,
  // ImageFileInformation,
  // LoadingIndicator,
  Typography,
} from 'src/app/components';
import { useStyles } from 'src/app/context';
import { ImagePreviewEditContainer } from '../imagePreviewEditContainer/ImagePreviewEditContainer';

export interface ImageFilePickerProps {
  canDeleteFile?: boolean;
  children?: React.ReactNode;
  id: string; // needed uid as renderKey
  initialFileUrl?: string;
  noUpload: boolean; // show hide upload button
  onCancel?: () => void;
  onRemove?: () => void;
  onSelectImageFile?: (file: File) => void;
  onUploadImage: (file: File) => void;
  showPreview?: boolean; // show image url preview
  fileName?: string; // for display
}

// TODO: handle downloads
// Base of/SIMILAR TO ImageUpload.tsx & ImageFilePickerWidget.tsx
// New clean Version of ImageFilePickerWidget for a broader use case scenario => use this in future
/**
 * ImageFilePicker
 * @param { ImageFilePickerProps } props
 * @version 0.0.0
 * @description
 * @todo Handle Min Max + image type etc params
 * @example
 */
export const ImageFilePicker = ({
  canDeleteFile,
  children,
  id,
  initialFileUrl,
  noUpload,
  onCancel,
  onRemove,
  onSelectImageFile,
  fileName, // for display
  onUploadImage,
  showPreview,
}: ImageFilePickerProps) => {
  const styles = useStyles();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [fileUrl, setFileUrl] = React.useState<string | null>(initialFileUrl || null);

  React.useEffect(() => {
    if (initialFileUrl && initialFileUrl !== fileUrl) {
      setFileUrl(initialFileUrl);
    }
  }, [fileUrl, initialFileUrl]);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file = e.target.files[0] || null; // TODO: no multi file support atm
      if (!file) {
        return;
      }

      const fileData = await file;

      setSelectedFile(fileData);

      const url = URL.createObjectURL(file);
      setFileUrl(url);

      if (!onSelectImageFile) {
        return;
      }

      onSelectImageFile(file);
    }
  };

  const onCancelButtonClick = () => {
    setSelectedFile(null);
    setFileUrl(initialFileUrl || null);
    if (!onCancel) {
      return;
    }
    onCancel();
  };

  const onRemoveButtonClick = () => {
    setSelectedFile(null);
    setFileUrl(initialFileUrl || null);
    if (!onRemove) {
      return;
    }
    onRemove();
  };

  const onUploadButtonClick = () => {
    if (!noUpload && onUploadImage && selectedFile) {
      onUploadImage(selectedFile);
      setSelectedFile(null);
      setFileUrl(initialFileUrl || null);
    }
  };

  const onUpdateStorageFileName = () => {};

  // const isWidthValid = !!(naturalResolution && naturalResolution.width >= minWidth);
  // const isHeightValid = !!(naturalResolution && naturalResolution.height >= minHeight);
  // const isSelectedFileImageValid = isWidthValid && isHeightValid;

  interface TypographyProps {
    fontSize?: string;
    lineHeight?: string;
    maxWidth?: string;
  }
  
  interface TruncatedTypographyProps {
    maxCharacters?: number;
  }

  const StyledTypography = styled(Typography)<TypographyProps>`
    && {
      font-size: ${({ fontSize }) => fontSize || '16px'};
      line-height: ${({ lineHeight }) => lineHeight || '1'}; 
      max-width: ${({maxWidth})=> maxWidth || '100%'}
    }
  `;
  
  const TruncatedTypography = styled(Typography)<TruncatedTypographyProps>`
    && {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: ${({ maxCharacters }) => maxCharacters ? `${maxCharacters}ch` : 'none'};
    }
  `;
  return (
    <Grid container className={styles.p__0}>
      {(selectedFile || fileUrl) && showPreview ? (
        <Grid item md={12} xs={12} >
          <ImagePreviewEditContainer
            file={selectedFile}
            fileUrl={fileUrl}
            updateStorageFileName={onUpdateStorageFileName}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <ButtonGroup fullWidth>
          {children}
          <Button fullWidth color={'primary'} variant='contained' component='span'>
            <label htmlFor={`upload-image ${id}`} style={{ width: '100%' }}>
              <input
                style={{ display: 'none', width: '100%' }}
                id={`upload-image ${id}`}
                name={`upload-image ${id}`}
                type={'file'}
                className='fileUpload'
                onChange={onSelectFile}
                accept={'image/jpeg, image/png' /* , image/webp' */}
              />
              <Grid container>
                <Grid item xs={12}>
                  <span className={styles.flex__col}>
                    <InsertPhotoIcon className={styles.mr__1} />
                    <StyledTypography>
                      {'Select a file (10mb)'}
                      {/* {selectedFile ? `${selectedFile.name}` : 'Select image'} */}
                      {/* {hasUploadSuccess ? 'Select another image' : 'Select image'} */}
                    </StyledTypography>
                  </span>
                </Grid>
              </Grid>
            </label>
          </Button>
          {noUpload ? null : (
            <Button
              disabled={!selectedFile}
              onClick={onUploadButtonClick}
              color={'primary'}
              variant='contained'
              size={'small'}
            >
              <TruncatedTypography>
                {fileName && !selectedFile ? (
                  fileName
                ) : (
                  <span className={styles.flex__row}>
                    <CloudUploadIcon className={styles.mr__1} /> Upload{' '}
                    {selectedFile?.name || ''}
                  </span>
                )}
              </TruncatedTypography>
            </Button>
          )}
          <Button
            disabled={!selectedFile && !canDeleteFile}
            onClick={
              !selectedFile && typeof onRemove !== 'undefined'
                ? onRemoveButtonClick
                : onCancelButtonClick
            }
            color={'primary'}
            variant='contained'
            size={'small'}
          >
            <Typography>
              <span className={styles.flex__row}>
                <DeleteForeverIcon className={styles.mr__1} />{' '}
                {selectedFile ? ' Cancel' : 'Remove'}
              </span>
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>
      {/* {selectedFile ? (
                <Grid item xs={12}>
                  <ImageFileInformation resolution={naturalResolution} file={selectedFile} />
                </Grid>
              ) : null} */}
      {/* {selectedFile && fileUrl ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {!isWidthValid ? (
                    <Grid item xs={12}>
                      <Typography align={'center'} color={'error'}>
                        {`The image must be at least ${minWidth}px wide`}
                      </Typography>
                    </Grid>
                  ) : null}
                  {!isHeightValid ? (
                    <Grid item xs={12}>
                      <Typography align={'center'} color={'error'}>
                        {`The image must be at least ${minHeight}px tall`}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      fullWidth
                      disabled={!isSelectedFileImageValid}
                      onClick={onUploadFile}
                    >
                      <Typography >Upload image</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}
    </Grid>
  );
};
