import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPage } from 'src/app.consumer/components';

const seo: SeoObject = {
  title: 'Help page',
};

export interface AppPageLayoutProps extends AppProps {
  sidebar?: JSX.Element;
  subTitle: string | JSX.Element;
  title: string | JSX.Element;
}

// TODO: switch sidebar position to top if view too narrow and sidebar is available
export const AppPageLayout = (props: AppPageLayoutProps) => {
  const { children, sidebar, subTitle, title, ...restAppProps } = props;
  return (
    <AppPage seo={seo} {...restAppProps}>
      <section className='py-8 px-4 md:py-16'>
        <div className='xl:container font-body dark:text-neutral-50'>
          <div className='flex flex-col gap-4 md:flex-row'>
            {sidebar ? (
              <>
                <div className='w-full md:w-1/3 md:pt-20'>
                  {sidebar}
                </div>
                <div className='w-full md:w-2/3'>
                  <div className='pb-6 md:pb-8'>
                    <h1 className='text-h2 md:text-h1 font-bold'>{title}</h1>
                    <h2 className='hidden md:block md:mt-2 text-h4 font-semibold'>{subTitle}</h2>
                  </div>
                  {children}
                </div>
              </>
            ) : (
              <div className='w-full'>
                <div className='pb-6 md:pb-8'>
                  <h1 className='text-h2 md:text-h1 font-bold'>{title}</h1>
                  <h2 className='hidden md:block md:mt-2 text-h4 font-semibold'>{subTitle}</h2>
                </div>
                {children}
              </div>
            )}
          </div>

        </div>
      </section>
    </AppPage>
  );
};
