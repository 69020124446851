import * as React from 'react';
import CancelIcon from '@material-ui/icons/Block';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

import { Button, DialogLayout, Grid, Typography } from 'src/app/components';

import { SystemDialog_UpdateOrganisationCategoryTypesParams } from 'src/app.system/types';
import {
  OrganisationProductCategoryTypeConfig,
  BaseOrganisationDocument,
} from 'src/admin/types';

import { useAppContext, useStyles } from 'src/app/context';
import { NotificationType, ProductCategoryType } from 'src/app/constants';
import { FirestoreDocData } from 'src/app/container';

import { UpdateOrganisationProductCategoryTypes } from './UpdateOrganisationProductCategoryTypes';

interface UpdateOrganisationProductCategoryTypesDialogProps
  extends SystemDialog_UpdateOrganisationCategoryTypesParams {}

export const UpdateOrganisationProductCategoryTypesDialog = ({
  organisationId,
  availableProductCategoryTypes,
}: UpdateOrganisationProductCategoryTypesDialogProps) => {
  const styles = useStyles();
  const { closeDialog, createStackedNotification, firebase } = useAppContext();

  const onSaveConfiguration = async (
    configuration: OrganisationProductCategoryTypeConfig[],
  ) => {
    if (!firebase) {
      return;
    }
    try {
      const firestore = getFirestore(firebase);
      const docRef = doc(firestore, `organisation/${organisationId}`);
      await updateDoc(docRef, { productCategoryTypeConfig: configuration });
      closeDialog();
      createStackedNotification(
        NotificationType.SUCCESS,
        'Successfully changed organisation product types',
      );
    } catch (err) {
      createStackedNotification(NotificationType.ERROR, `Error: ${err.message || ''}`);
    }
  };

  return (
    <DialogLayout
      dialogDescription={
        'Change the available product category options for a partner organisation'
      }
      dialogControls={
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={closeDialog}
              fullWidth
              variant={'contained'}
              color={'primary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              <CancelIcon className={styles.mr__1} />
              <Typography>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FirestoreDocData<BaseOrganisationDocument>
        documentPath={`organisation/${organisationId}`}
        useSubscription
        renderElements={({ data, status }) => {
          return status !== 'success' || !data?.id ? null : (
            <UpdateOrganisationProductCategoryTypes
              availableProductCategoryTypes={
                availableProductCategoryTypes.length
                  ? availableProductCategoryTypes
                  : [ProductCategoryType.ACTIVITY]
              }
              productCategoryTypeConfig={data.productCategoryTypeConfig}
              onChange={(val) => onSaveConfiguration(val)}
            />
          );
        }}
      />
    </DialogLayout>
  );
};
