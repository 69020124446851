import * as React from 'react';
import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';

import { ButtonTw } from 'src/app/components';
import { ContentNavigationType } from 'src/app/types';
// import Carousell from './Carousell';

// import { ProductReviews } from '../ProductReviews'; // TODO

export interface ItemImageSelectWidgetProps {
  item: ContentNavigationType;
  onNextImageClick: () => void;
  onPrevImageClick: () => void;
  onSelectImageIndexClick: (index: number) => void;
}

export const ItemImageSelectWidget = (props: ItemImageSelectWidgetProps) => {
  const {
    onNextImageClick,
    onPrevImageClick,
    onSelectImageIndexClick,
    item: product,
  } = props;

  return (
    <>
      <div className='flex w-full justify-between absolute z-10 top-1/2 -mt-6 p-4'>
        <ButtonTw
          onClick={onPrevImageClick}
          className={
            'rounded-full w-12 h-12 bg-neutral-900 bg-opacity-50 md:dark:shadow-box-dark !text-neutral-50'
          }
          variant={'text'}
        >
          <PrevIcon fontSize='large' />
        </ButtonTw>
        <ButtonTw
          onClick={onNextImageClick}
          className={
            'rounded-full w-12 h-12 bg-neutral-900 bg-opacity-50 md:dark:shadow-box-dark !text-neutral-50'
          }
          variant={'text'}
        >
          <NextIcon fontSize='large' />
        </ButtonTw>
      </div>

      {product?.imageFiles?.length > 0 ? (
        <div className='flex w-full justify-center gap-1 absolute z-10 bottom-0 p-4'>
          {product.imageFiles.map((item, idx) => (
            <ButtonTw key={idx}
              onClick={() => onSelectImageIndexClick(idx)}
              className={
                'rounded-full w-[10px] h-[10px] bg-neutral-900 dark:bg-neutral-50 bg-opacity-50 focus:bg-neutral-50 dark:bg-opacity-80 dark:focus:bg-neutral-900'
              }
              variant={'text'}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};
