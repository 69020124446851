import * as React from 'react';

import { Grid, Localized } from 'src/app/components';
import { PaginationControls } from './PaginationControls';

export interface PaginationProps<T extends Record<string, any>> {
  className?: string;
  data: T[]; // TODO: generic doc type can be used
  hideControlsBottom?: boolean;
  hideControlsTop?: boolean;
  pageSize?: number;
  renderElements: (renderProps: { data: T[] }) => React.ReactNode; // TODO: generic doc type can be used
}

export const Pagination = <T extends Record<string, any>>({
  className,
  data,
  hideControlsBottom,
  hideControlsTop,
  pageSize,
  renderElements,
}: PaginationProps<T>) => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const limit = pageSize || 10; // default value
  const pageCount = Math.ceil(data.length / limit);

  const currentStartIndex = pageNumber * limit - limit;
  const currentEndIndex = currentStartIndex + limit;
  return (
    <div className={className || ''}>
      {hideControlsTop ? null : (
        <div className='py-1 md:py-4'>
          <PaginationControls
            countText={
              <Localized
                dictKey={'common:filter.format-result-count'}
                translationOptions={{ count: data?.length || 0 }}
              />
            }
            currentPage={pageNumber}
            handleChange={handleChange}
            pageCount={pageCount}
          />
        </div>
      )}
      <>
        {renderElements({
          data: data.slice(currentStartIndex, currentEndIndex),
        })}
      </>
      {hideControlsBottom ? null : (
        <div className='py-1 md:py-4'>
          <PaginationControls
            countText={
              <Localized
                dictKey={'common:filter.format-result-count'}
                translationOptions={{ count: data?.length || 0 }}
              />
            }
            currentPage={pageNumber}
            handleChange={handleChange}
            pageCount={pageCount}
          />
        </div>
      )}
    </div>
  );
};
