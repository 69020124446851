import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import { nanoid } from 'nanoid';

import { BaseComponentProps, SourcedGQLProduct } from 'src/app/types';
import {
  Button,
  Divider,
  Grid,
  GlassPaper,
  ProductRating, // TODO:
  ProductTags,
  Image
} from 'src/app/components';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useFirebase, useAppContext } from 'src/app/hooks';
import { useStyles } from 'src/app/context';

interface ProductListItemProps extends BaseComponentProps {
  children?: React.ReactNode;
  extraChildren?: React.ReactNode;
  hasWishlistIcon: boolean;
  hideTagsAndRatings?: boolean;
  product: SourcedGQLProduct;
  upperRowChildren?: React.ReactNode;
  isSourcedImage?: boolean;
}

// TODO: switch based on <T>
export const ProductListItem = (props: ProductListItemProps) => {
  const {
    children,
    extraChildren,
    hideTagsAndRatings,
    hasWishlistIcon,
    upperRowChildren,
    product,
    isSourcedImage = true,
  } = props;
  const { user } = useAppContext();
  const styles = useStyles();
  const { useStoreActions } = useFirebase();
  const { useSetDocument } = useStoreActions();
  const { setDocument } = useSetDocument();
  const [imageIndex,setImageIndex]=React.useState<any>()
  // TODO: create productTeaserImage (200px X 200px) => old?
  // const itemHeight = '200px';

  // TODO: handle user without login via local storage
  const onWishlistClick = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    console.log('click');

    if (!user?.uid) {
      return;
    }
    const wishlistDoc = {
      id: nanoid(),
      productId: product.id,
    };
    setDocument(wishlistDoc, `user/${user?.uid}/wishlist`, false);
  };
  React.useEffect(()=>{
    if(isSourcedImage){
      const index = product.imageUrls.reduce((lowestIndex, currentItem, currentIndex) => {
        // If currentItem.order is null, prioritize it
        if (currentItem.order === null) {
            return currentIndex;
        }
        // If lowestIndex is null or currentItem.order is lower than the currentItem at lowestIndex, update lowestIndex
        if (lowestIndex === null || currentItem.order < product.imageUrls[lowestIndex].order) {
            return currentIndex;
        } else {
            return lowestIndex;
        }
    }, null);
      setImageIndex(index)
    }
  },[product.imageUrls]);
  return (
    <>

      {isSourcedImage ? (
        <GatsbyImage
          // className={clsx(cn.media, !isWiderMD ? cn.mediaSM : {})}
          fluid={product.imageFiles?.[imageIndex]?.childImageSharp.fluid} // TODO: fallback
          alt={'Activity Title Image'}
          className={'rounded-tl-2xl rounded-tr-2xl md:rounded-bl-2xl md:rounded-tr-none h-full'}
        />
      ) : product.imagePreview?.length ? (
        <Image
          src={product.imagePreview[0]}
          className={'rounded-tl-2xl rounded-tr-2xl md:rounded-bl-2xl md:rounded-tr-none h-full object-cover'}
        />
      ) : // TODO: show HH fallback image if no (only for preview? since gql layer has fallback logic?) image are available
      null}


      {/** // FIXME: button cannot be descendant from button... => warp each the img area and the left side with a separate button each */}
      {hasWishlistIcon ? (
        <Button
          variant={'text'}
          onClick={onWishlistClick}
          className={'absolute top-4 right-4 text-accent-500 md:text-neutral-900 md:top-9 md:right-9 md:dark:text-neutral-50'}
        >
          <FavoriteBorderIcon />
        </Button>
      ) : null}

      {!hideTagsAndRatings ? ( // TODO
        <>

          {/* //TODO: move elsewhere (Rating)
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                {product.productData?.rating !== undefined ? (
                  <ProductRating
                    direction={'row'}
                    product={product}
                    reviewAnchorId={''}
                    color={'#000'}
                    textSizeForProductListItem={'body1'}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid> */}
        </>
      ) : null}

      {upperRowChildren}

      {children}

      {extraChildren}


    </>
  );
};
