import * as React from 'react';

import { Grid, Typography, CenteredContainer } from 'src/app/components';
import { Switch } from '@material-ui/core';

interface BooleanSwitchProps {
  color?: 'primary' | 'secondary' | 'default';
  description?: string | React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string | React.ReactNode;
  onSwitch: () => void;
  value: boolean;
  showState?: boolean;
  title?: string | React.ReactNode;
}

export const BooleanSwitch = ({
  color,
  description,
  disabled,
  icon,
  label,
  onSwitch,
  value,
  showState = true,
  title,
}: BooleanSwitchProps) => {
  return (
    <Grid container spacing={1}>
      {title ? (
        <Grid item xs={12}>
          <h3 className='text-p1 font-bold'>
            {title}
          </h3>
        </Grid>
      ) : null}
      {description ? (
        <Grid item xs={12}>
          <p>{description}</p>
        </Grid>
      ) : null}
      {label ? (
        <Grid item xs={6}>
          <h3 className='text-p1 font-bold'>
            {label}{' '}
            {/** this should be wrapped inside of a FormControlLabel (label for checkbox ARIA) */}
          </h3>
        </Grid>
      ) : null}
      <Grid item xs={label ? 6 : 12}>
        <Grid container>
          <Grid item xs={12}>
            {showState ? (
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
              >
                {icon ? <CenteredContainer>{icon}</CenteredContainer> : null}
                <CenteredContainer>
                  <Typography align={'right'}>{value ? 'On' : 'Off'}</Typography>
                </CenteredContainer>
                <CenteredContainer>
                  <Switch
                    checked={value}
                    disabled={!!disabled || false}
                    onChange={onSwitch}
                  />
                </CenteredContainer>
              </Grid>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {icon}
                <CenteredContainer>
                  <Switch
                    checked={value}
                    disabled={!!disabled || false}
                    onChange={onSwitch}
                    color={color || 'default'}
                  />
                </CenteredContainer>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
