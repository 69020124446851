import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

export interface TypesenseApiCallableRequestParams {
  searchParam: any,
  pageNumber: number,
  orderbyField: string,
  orderbyVal: string,
  collectionName: string,
  }
  export interface SearchParams {
    q: string,
    query_by: string,
    filter_by: string,
    page?: number,
    per_page?: number,
    exhaustive_search?: boolean,
    max_candidates?: number,
    enable_lazy_filter?: boolean,
    sort_by?: string
  }
  

/**
 * systemFunction-typesenseApi
 * @version 0.0.0 functions version
 */
export const typesenseApi = async (
  firebase: FirebaseApp,
  params: TypesenseApiCallableRequestParams,
  env: string,
) => {
  return httpsCallable(
    getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
    'systemFunction-typesenseApi',
  )(params);
};
