import * as React from 'react';

import MToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup';

/**
 * ToggleButtonGroup
 * @param { ToggleButtonGroupProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/toggle-button-group
 * @todo
 * @example
 */
export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  ...passProps
}: ToggleButtonGroupProps) => {
  return <MToggleButtonGroup {...passProps} />;
};
