import * as React from 'react';

import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import CancelIcon from '@material-ui/icons/Block';

import {
  Button,
  DialogLayout,
  Grid,
  Typography,
  ConfirmationWidget,
} from 'src/app/components';

import { DocumentDeleteProps } from 'src/app/types';
import { useFirebase } from 'src/app/hooks';
import { useStyles } from 'src/app/context';

export interface DocumentDeleteDialogProps extends DocumentDeleteProps {
  children?: React.ReactNode;
  onCloseDialog: () => void;
  // DocumentDeleteProps:
  documentId: string;
  documentCollectionPath: string;
  shouldDeleteAttachedFiles?: boolean;
  onSuccess: () => void;
}

export const DocumentDeleteDialog = (props: DocumentDeleteDialogProps) => {
  const {
    children,
    documentId,
    documentCollectionPath,
    onCloseDialog,
    shouldDeleteAttachedFiles,
    onSuccess,
  } = props;
  const styles = useStyles();
  const { useStoreActions } = useFirebase();
  const { useDeleteDocumentWithStorageFiles, useDeleteDocument } = useStoreActions();
  const { deleteDocument } = useDeleteDocument();
  const { deleteDocumentWithStorageFiles } = useDeleteDocumentWithStorageFiles();
  const [openMenuId, setOpenMenuId] = React.useState<string | null>(null);

  const onDeleteConfirmed = () => {
    if (shouldDeleteAttachedFiles) {
      deleteDocumentWithStorageFiles(documentId, documentCollectionPath);
    } else {
      deleteDocument(documentId, documentCollectionPath);
    }
    onCloseDialog();
    onSuccess();
  };

  return (
    <DialogLayout
      dialogDescription={!openMenuId ? 'Delete document' : ''}
      dialogControls={
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={!openMenuId ? onCloseDialog : () => setOpenMenuId(null)}
              fullWidth
              variant={'contained'}
              color={'primary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              {!openMenuId ? (
                <CancelIcon className={styles.mr__1} />
              ) : (
                <NavigatePrevIcon className={styles.mr__1} />
              )}
              <Typography >
                {!openMenuId ? 'Cancel' : 'Back'}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      }
    >
      <>
        <Grid item xs={12}>
          <ConfirmationWidget
            confirmId={documentId}
            onSubmitSuccess={onDeleteConfirmed}
          />
        </Grid>
        {children ? (
          <Grid item xs={12}>
            {children}
          </Grid>
        ) : null}
      </>
    </DialogLayout>
  );
};
