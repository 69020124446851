import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ButtonTw, Localized, Typography, classNames } from 'src/app/components';

export interface FormControlProps {
  children?: React.ReactNode;
  hasReset?: boolean;
  isValid?: boolean;
  // optional: always render submit button ? => hasSubmitButton?: boolean;
  // OPTIONAL: renderResetButton: ({ onClick: () => void }) => React.ReactNode;
}

// TODO: pass i18n
export const FormControl = ({
  children,
  className,
  hasReset,
  isValid,
}: // hasSubmitButton,
FormControlProps) => {
  const { formState, reset } = useFormContext();
  // const { isValid, isSubmitSuccessful } = formState;
  console.log('!!!formState isValid: ', formState);
  // console.log('!!!formState isSubmitSuccessful: ', isSubmitSuccessful);
  return (
    <div className={className}>
      <ButtonTw
        disabled={!isValid}
        variant='accent'
        size='lg'
        rounded
        className='w-full sm:w-auto sm:min-w-[270px]'
        type={'submit'}
      >
        <Localized dictKey={'common:action.form submit.label'} />
      </ButtonTw>

      {hasReset ? (
        <ButtonTw
          type='button'
          variant='outline'
          size='lg'
          rounded
          className='w-full md:w-auto'
          onClick={() =>
            reset(undefined, {
              /* ...defaultValues */ keepValues: true,
              keepDefaultValues: true,
            })
          }
        >
          <Localized dictKey={'common:action.form reset.label'} />
        </ButtonTw>
      ) : null}
      <div>{children}</div>
    </div>
  );
};
