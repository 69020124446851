import { FirebaseApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';

export const defaultFunctionsRegionOrCustomDomain = (env: string) =>
  env === 'DEVELOPMENT' || env === 'DEVELOPMENT_NETLIFY'
    ? 'europe-west3'
    : 'europe-west3';

export const getRegionalFunctions = (firebase: FirebaseApp, env: string) =>
  getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env));
