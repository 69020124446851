import * as React from 'react';

import { useAppContext } from 'src/app/hooks';
import { FieldStyle } from '../form.constants';

interface FormFieldLayoutProps {
  additionalBottomArea?: React.ReactNode;
  additionalTopArea?: React.ReactNode;
  children?: React.ReactNode;
  fieldStyle?: FieldStyle;
  hasError?: boolean;

  // debug
  debugId?: string;
  debugI18nKey?: string;
}

export const FormFieldLayout = ({
  additionalBottomArea,
  additionalTopArea,
  children,
  hasError,
  fieldStyle,
  // debug
  debugId,
  debugI18nKey,
}: // ...props
FormFieldLayoutProps) => {
  const { isAppDebug } = useAppContext();

  const useFormStyle = !fieldStyle || (fieldStyle && fieldStyle === FieldStyle.FORM);
  const useCleanStyle = !fieldStyle || (fieldStyle && fieldStyle === FieldStyle.CLEAN);

  // NOTE: do not render card for clean variant
  return (
    <div className='dark:text-neutral-50'>
      {isAppDebug && debugId ? (
        <div>
          {debugId}
        </div>
      ) : null}
      {isAppDebug && debugI18nKey ? (
        <div>
          {debugI18nKey}
        </div>
      ) : null}

      <div className='flex flex-col gap-2 my-6'>
        {additionalTopArea ? (
          <div>
            {additionalTopArea}
          </div>
        ) : null}
        <div>
          {children}
        </div>
        {additionalBottomArea ? (
          <div>
            {additionalBottomArea}
          </div>
        ) : null}
      </div>

    </div>
  );
};
