import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MapMarkerObject, MapViewArgs, MapQueryArgs } from 'src/app/types';
import { Grid } from 'src/app/components';
import { useStyles } from 'src/app/context';

export interface MapWidgetProps {
  children: React.ReactNode;
  isStatic: boolean; // TODO
  mapVisualArgs: MapViewArgs;
  mapQueryArgs: MapQueryArgs;
  markers?: MapMarkerObject[];
  title?: React.ReactNode;
}

const apiKey = 'AIzaSyD5FpBZYFnWoqj7r4xagUwfS4qyqexVFqs'; // TODO: source

// removes # in color + uses only first letter of the label
// https://developers.google.com/maps/documentation/maps-static/styling
const createMarkerString = (color: String, label: String, lat: Number, long: Number) =>
  `&markers=color:0x${color.replace('#', '')}%7Clabel:${label[0]}%7C${lat},${long}`;

export const MapWidget = ({
  children,
  isStatic,
  mapVisualArgs: { height, mapType, scale, width, zoom },
  mapQueryArgs: { area, city, country, language },
  markers,
  title,
}: MapWidgetProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);

  // const area = ''; // productLocationAddress; // productLocationCityArea
  // const city = ''; // productLocationZipCode; // productLocationCity
  // const country = 'Croatia';

  const testMapId = '72b0e8e0a48cb030'; // TODO:

  const reqParams = `center=${area},${city},${country}&language=${language}&zoom=${zoom}&scale=${scale}&size=${
    width * zoom
  }x${
    height * zoom
  }&maptype=${mapType}=&key=${apiKey}&format=png&map_id=${testMapId}&style=feature:road.highway%7Celement:geometry%7Cvisibility:simplified%7Ccolor:0xc280e9&style=feature:transit.line%7Cvisibility:simplified%7Ccolor:0xbababa&style=feature:road.highway%7Celement:labels.text.stroke%7Cvisibility:on%7Ccolor:0xb06eba&style=feature:road.highway%7Celement:labels.text.fill%7Cvisibility:on%7Ccolor:0xffffff`;

  const markersString: string = markers?.length
    ? markers
        .map((marker) =>
          createMarkerString(marker.color, marker.label, marker.lat, marker.long),
        )
        .join('')
    : '';

  const imgMapRequest = `https://maps.googleapis.com/maps/api/staticmap?${reqParams}${markersString}`; // &amp;KEY_OR_CLIENT=YOUR_KEY_OR_ID
  // console.log('map reqParams: ', reqParams);
  // console.log('map reqParams: ', markersString);

  return (
    <>
      {title ? (
        <>
          {title}
        </>
      ) : null}
      <div className='pt-9 pb-3 flex flex-col gap-y-8 md:pb-0 md:pt-12 md:flex-row md:gap-x-8'>
        {children ? (
          <div className='w-full md:w-1/2 md:flex md:justify-start'>
            {children}
          </div>
        ) : null}
        <div className='w-full flex justify-center items-start md:w-1/2 md:justify-end'>
          <div>
            <img
              src={imgMapRequest}
              alt={'map'}
              className='rounded-lg shadow-box-inset md:aspect-square object-cover'
              style={{
                // minWidth: `${map.width}px`,
                // maxWidth: `${map.width}px`,
                width: `${width}px`,
                // aspectRatio: 'auto',
                // objectPosition: '50% 50%',
                // objectFit: 'fill',
                // height: `${map.height}px`,
                height: `${height}px`,
              }}
            />
            <p className='text-p4 my-2 md:mt-4 md:mb-0 md:text-p2 text-center'>
              See the activities on the map
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
