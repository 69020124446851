import * as React from 'react';
import { useCookies } from 'react-cookie';

import { PasswordState } from 'src/app/types';
// import { useAppStaticQueryConfig } from '../staticQueryHooks';

const pwCookieName = 'pw';

// NOTE: is currently not used / disabled
export function usePasswordState(): PasswordState {
  const [cookies, setCookie] = useCookies([pwCookieName]);
  // const { passwordMode: isInPasswordMode, passwordHash } = useAppStaticQueryConfig(); // are commented out now
  const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);
  const passwordHash = '123';
  const isInPasswordMode = false;
  const password = cookies[pwCookieName];
  React.useEffect(() => {
    const isCorrect = () => !!(passwordHash === password);
    if (password && isCorrect() && !isPasswordCorrect) {
      setIsPasswordCorrect(true);
    }
  }, [isPasswordCorrect, password]);

  const enterPassword = (pw: string) => {
    setCookie(pwCookieName, pw, { path: '/' });
  };

  return { isInPasswordMode, enterPassword, isPasswordCorrect };
}
