import * as React from 'react';

import { BaseComponentProps, SourcedGQLCategory } from 'src/app/types';
import { useSourcedProducts } from 'src/app/hooks';

import { GatsbyNodeType } from 'src/app/constants';

import { useLocalizationContext } from 'src/app/context';
import { ContentItemScreen } from '../ContentItemScreen';

export interface CategoryScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLCategory;
}

export const CategoryScreen = (props: CategoryScreenProps) => {
  const { item, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  const products = useSourcedProducts();

  return (
    <ContentItemScreen
      collectionMappings={[
        // TODO: get PRODUCTS
        // {
        //   id: GatsbyNodeType.COUNTRY,
        //   collectionItems: categories,
        //   collectionItemIdPropertyName: 'continentId', // TODO: nested (meta.categoryIds)
        // },
        // TODO display products per cat
        // TODO display cat per cat (nested feature)
        {
          id: GatsbyNodeType.PRODUCT,
          collectionItems: products,
          // collectionItemIdPropertyName: '',
          collectionItemIdMetaArrayIdPropertyName: 'categoryIds',
          renderWithProductListItem: true, // TODO:
        },
      ]}
      itemLocations={[]}
      itemType={GatsbyNodeType.CATEGORY}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
