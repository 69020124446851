import { httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { getRegionalFunctions } from 'src/lib/functions';

interface CallableFunctionMeta {
  functionNamespace: string;
  functionName: string;
}

/**
 * call firebase functions with region params and unwrap response data
 */
export const callableFunction = async <
  FBRequest extends Record<string, unknown>,
  FBResponse extends Record<string, unknown>,
>(
  firebase: FirebaseApp,
  env: string,
  params: FBRequest,
  meta: CallableFunctionMeta,
) =>
  (
    await httpsCallable<FBRequest, FBResponse>(
      getRegionalFunctions(firebase, env),
      `${meta.functionNamespace}-${meta.functionName}`,
    )(params)
  ).data;

export const createCallableFunctionMeta = (
  functionNamespace: string,
  functionName: string,
): CallableFunctionMeta => ({
  functionNamespace,
  functionName,
});
