import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { navigate } from 'gatsby';

import { useAppContext } from 'src/app/hooks';

import { Button, Grid, LoadingIndicator, Typography } from 'src/app/components';

import { NotificationType } from 'src/app/constants';

interface NewsletterUnsubscribeFormProps {
  id: string;
  lang: string; // TODO: is supported but currently not used to change language based on this provided value from FB
}

// TODO: i18n
// TODO: wrap inside from and use 'TextField' from RHF for that + form validation

export const NewsletterUnsubscribeForm = ({
  id,
  lang,
}: NewsletterUnsubscribeFormProps) => {
  const { createNotification, firebase } = useAppContext();

  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  const onSubmitButtonClick = async () => {
    if (!firebase) {
      return;
    }

    if (!id) {
      createNotification(NotificationType.ERROR, 'Invalid params', 5000);
      return;
    }

    try {
      setIsRequestingUpdate(true);

      const unsubscribeFromNewsletter = httpsCallable(
        getFunctions(firebase),
        'shopFunction-newsletterUnsubscribe',
      );

      await unsubscribeFromNewsletter({ id });

      // TODO: i18n
      createNotification(
        NotificationType.SUCCESS,
        'You have been removed from the newsletter list',
        5000,
      );
      navigate('/');
    } catch (err) {
      console.warn('Remove from newsletter error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  if (isRequestingUpdate) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button fullWidth color={'secondary'} onClick={onSubmitButtonClick}>
              <Typography>Unsubscribe</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
