import { graphql, useStaticQuery } from 'gatsby';
import { CategoryTypeDocumentData } from 'src/app/types';

interface CategoryTypesQuery {
  allCategoryType: {
    nodes: CategoryTypeDocumentData[];
  };
}

export const allCategoryTypeQuery = graphql`
  {
    allCategoryType {
      nodes {
        fields {
          activityCount
        }
        id
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        localizations {
          localeId
          locales {
            description
            name
            teaser
          }
          seo {
            description
            title
          }
        }
        meta {
          createdAt {
            _nanoseconds
            _seconds
          }
          # createdById
          categoryIds
          categoryTypeIds
          tagIds
          v
        }
      }
    }
  }
`;

export function useSourcedCategoryTypes(): CategoryTypeDocumentData[] {
  const { allCategoryType } = useStaticQuery<CategoryTypesQuery>(allCategoryTypeQuery);

  return allCategoryType?.nodes || [];
}
