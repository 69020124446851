import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { UserProfileInformationData } from 'src/app/types';
import { FormError } from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FormError.REQUIRED)
    .max(32, FormError.NUMBER_MAX_VALUE),
  lastName: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
  // email: yup.string().email().required(), // not changeable
  phoneNumber: yup
    .string()
    .required(FormError.REQUIRED)
    .max(32, FormError.NUMBER_MAX_VALUE),
  dob: yup.date().required(FormError.REQUIRED),
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: UserProfileInformationData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  dob: '',
};

export const defaultValues__DEV: UserProfileInformationData = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email@willkuerlich.com',
  phoneNumber: 'phoneNumber',
  dob: '2019-10-25T00:34:12.134Z',
  testFail: 'plz fail', // Test, should not be accepted with that property
};
