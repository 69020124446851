import * as React from 'react';

import { Link, LocalizedLink, Localized } from 'src/app/components';

import { useStyles, useLocalizationContext } from 'src/app/context';

// TODO: implement background color mode
export const ButtonLink = ({
  dictKey,
  href,
  useLocalizedLink = true,
}: {
  dictKey: string;
  href: string;
  useLocalizedLink?: boolean;
}) => {
  const styles = useStyles();
  const { language } = useLocalizationContext();
  // const theme = useTheme();
  // const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);

  // TODO:if is mobile res => label should be centered
  return (
    <>
      {useLocalizedLink ? (
        <LocalizedLink
          href={href}
          // align={isWiderMD ? 'left' : 'center'}
          //align={'center'}
          //classes={language === 'cimode' ? { root: styles.textTransform__none } : {}}
          className={'hover:no-underline'}
          style={{ textDecoration: 'none' }}
        >
          <Localized className={'text-neutral-50 font-normal font-body capitalize duration-300 hover:text-accent-500'} dictKey={dictKey} />
        </LocalizedLink>
      ) : (
        <Link
          href={href}
          // align={isWiderMD ? 'left' : 'center'}
          //align={'center'}
          className={'hover:no-underline'}
        >
          <Localized className={'text-neutral-50 font-normal font-body capitalize duration-300 hover:text-accent-500'} dictKey={dictKey} />
        </Link>
      )}
    </>
  );
};
