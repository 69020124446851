import * as React from 'react';

import {
  ButtonTw,
  Checkbox,
  DialogLayout,
  TextInputField,
} from 'src/app/components';

// #i18n

export interface ConfirmationWidgetProps {
  confirmId?: string;
  onSubmitSuccess: () => void;
}

/**
 * ConfirmationWidget
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const ConfirmationWidget = ({
  confirmId,
  onSubmitSuccess,
}: ConfirmationWidgetProps) => {
  const [confirmedId, setConfirmedId] = React.useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);

  // TODO: accept confirmationIds

  const onConfirmWithId = () => {
    if (confirmedId && confirmedId === confirmId) {
      onSubmitSuccess();
    } else {
      // TODO notify
      console.warn('Error id mismatch');
    }
  };

  return (
    <DialogLayout
      dialogDescription={<span className='font-semibold'>{'Are you really sure?'}</span>}
    >
      <div className='flex flex-col gap-y-3 text-center'>
        {confirmId ? (
          <>
            <p>
              {'Confirm document id'}
            </p>
            <p>{`${confirmId}`}</p>
            <TextInputField
              variant={'outlined'}
              // color={'primary'}
              fullWidth
              label={`Please confirm the document id [${confirmId}]`}
              onChange={(e) => setConfirmedId(e.target.value)}
            />
          </>
        ) : (
          <div>
            <Checkbox
              onChange={console.log}
              onChange={() => setIsConfirmed(!isConfirmed)}
              checked={isConfirmed}
              // color={'primary'}
              // name={'checkedA'}
            >
              {/* <span>{'Yes ( Your account will be deleted )'}</span> */}
              <span>{'Yes ( This is irreversible )'}</span>
            </Checkbox>
          </div>
        )}
        <div>
          <ButtonTw
            rounded
            disabled={
              !!((confirmId && confirmedId !== confirmId) || (!confirmId && !isConfirmed))
            }
            variant={'accent'}
            size={'lg'}
            className='w-full md:w-auto md:min-w-[270px]'
            onClick={confirmId ? onConfirmWithId : onSubmitSuccess}
          >
            OK
          </ButtonTw>
        </div>
      </div>
    </DialogLayout>
  );
};
