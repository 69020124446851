import * as React from 'react';
import { Router } from '@reach/router';

import { Grid, PrivateRoute } from 'src/app/components';

import { useAppContext } from 'src/app/hooks';
// TODO: reuse AppPageLoayout here?
// import { AppPageLayout, HistoryContainer } from 'src/app.consumer/components'; // TODO: remove Messages
import {
  UserBilling,
  UserEmail,
  UserHistory,
  UserOrders,
  UserMessages,
  UserPassword,
  UserProfile,
  UserSettings,
  UserOrder
} from './pages';
import { UserAccountMenu } from './components';
import { basePath } from './navigationBasePath';

// const i18nNS = 'organisation';

export const UserAccount = () => {
  const { isLoggedIn } = useAppContext();
  if (
    !isLoggedIn ||
    typeof window === 'undefined' ||
    !window.location.pathname.startsWith(basePath)
  ) {
    return null;
  }

  return (
    <Grid container>
      <Grid item md={2} xs={12}>
        <UserAccountMenu />
      </Grid>
      <Grid item md={10} xs={12}>
        <Router basepath={basePath}>
          {/* <PrivateRoute path={'/'} component={Dashboard as React.FC} /> */}
          <PrivateRoute path={`${'profile'}/*`} component={UserProfile as React.FC} />
          <PrivateRoute path={`${'history'}/*`} component={UserHistory as React.FC} />
          <PrivateRoute path={`${'messages'}/*`} component={UserMessages as React.FC} />
          <PrivateRoute path={`${'settings'}/*`} component={UserSettings as React.FC} />
          <PrivateRoute path={`${'email'}/*`} component={UserEmail as React.FC} />
          <PrivateRoute path={`${'password'}/*`} component={UserPassword as React.FC} />
          <PrivateRoute path={`${'billing'}/*`} component={UserBilling as React.FC} />
          <PrivateRoute path={`${'orders'}/*`} component={UserOrders as React.FC} />
          <PrivateRoute path={'orders/:id/*'} component={UserOrder as React.FC} />

        </Router>
      </Grid>
    </Grid>
  );
};
