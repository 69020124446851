import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  Button,
  Grid,
  LoadingIndicator,
  SubmitSuccessMessage,
  TextInputField,
  Typography,
} from 'src/app/components';

import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';

import { NotificationType } from 'src/app/constants';

import { FormRootContainer } from 'src/app/components/RHF';

import { ContactFormSubmitData } from 'src/app/types';
import { ContactForm } from './ContactForm';

import { defaultValues, createDocumentResolver } from './contact.form.config';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

const documentI18nBasePath = 'common:forms.contact.form';
type DocumentType = ContactFormSubmitData;

// TODO: i18n
export const ContactFormContainer = () => {
  const { /* currentSystem, */ createNotification, firebase, isAppDebug, user } =
    useAppContext(); // TODO: select systemId based on system user data
  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);
  const [hasSubmitSuccess, setHasSubmitSuccess] = React.useState<boolean>(false);
  const { env } = useAppStaticQueryConfig();

  const currentSystemId = 'holiday';

  const onSubmit = async (data: DocumentType) => {
    if (!firebase) {
      return;
    }

    setRequestingUpdate(true);

    // interface ReferenceMessageData {
    //   referenceId: string;
    //   systemId: string;
    //   name: string;
    //   email: string;
    //   phoneNumber?: string;
    //   attachment?: string; // FIXME: BASE64 string (PDF/IMAGE)
    //   body: string;
    // }

    interface PublicRequestMessageData {
      systemId: string;
      name: string;
      email: string;
      phoneNumber?: string;
      attachment?: string; // FIXME: BASE64 string (PDF/IMAGE) // TODO?
      body: string;
    }

    try {
      // const createReferenceMessage = firebase
      //   .functions()
      //   .httpsCallable('commonFunctions-createReferenceMessage');

      const sendPublicRequestMessage = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'commonFunctions-sendPublicRequestMessage',
      );

      // TODO: attachments
      // const attachmentText = attachment ? await attachment?.text() : '';
      // console.log('attachmentText: ', attachmentText);
      const params: PublicRequestMessageData = {
        // referenceId: data.bookingReferenceId,
        systemId: currentSystemId, // TODO: currentSystem => get via ENV
        name: data.name,
        email: data.email,
        phoneNumber: data.phone,
        attachment: '', // FIXME: BASE64 string (PDF/IMAGE)
        body: data.body,
      };

      await sendPublicRequestMessage(params); // make function non async?

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Message successfully sent', 5000);
      setHasSubmitSuccess(true);
    } catch (err) {
      console.warn('Message send error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setRequestingUpdate(false);
    }
  };

  const isSubmitDataValid = true;

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    const data: DocumentType = {
      ...dataRaw,
    };

    await onSubmit(data);
  };

  // FIXME:
  // const onChooseFileClick = () => {
  //   console.log('not implemented');
  // };

  return hasSubmitSuccess ? (
    <SubmitSuccessMessage text={'Message is sent'} />
  ) : (
    <Grid container spacing={2}>
      <Grid>
        <FormRootContainer<DocumentType>
          defaultValues={defaultValues}
          resolver={createDocumentResolver}
          onSubmit={onSubmitWrapper}
          useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
          isDebug={isAppDebug}
          renderForm={({ formProps }) => {
            return isRequestingUpdate ? (
              <LoadingIndicator />
            ) : (
              <ContactForm
                formProps={formProps}
                i18nBasePath={documentI18nBasePath}
                isSubmitDataValid={isSubmitDataValid}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
