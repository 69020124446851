import * as React from 'react';

import { DropDown, Typography } from 'src/app/components';

import { PriceSystem } from 'src/app/types';

import { useStyles } from 'src/app/context';

interface SelectPriceGroupProps {
  disabled?: boolean;
  onChangePriceGroup: (priceGroupId: string) => void;
  priceSystem: PriceSystem;
  selectedId?: string;
}

export const SelectPriceGroup = ({
  disabled,
  onChangePriceGroup,
  priceSystem,
  selectedId,
}: SelectPriceGroupProps) => {
  const styles = useStyles();
  // TODO: private booking or pass as booking system
  const bookingSystem = priceSystem.publicBooking;
  // bookingSystem.featureEnabled => handle?
  // bookingSystem.hasGroupDiscount

  const priceGroups: { id: string }[] = [
    { id: 'default' }, // NOTE: add default price group (could be simplified by creating it by default when creating a org source product)
    ...bookingSystem.groupDiscounts,
  ];

  return (
    <DropDown
      containerClassName={styles.p__1}
      disabled={disabled}
      variant={'contained'}
      size={'small'}
      // color={isFilterOn() ? 'secondary' : 'primary'}
      color={'primary'}
      label={
        <div
          style={{
            overflow: 'hidden',
            maxWidth: '32ch',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography align={'center'} variant={'caption'}>
            {selectedId
              ? priceGroups.find((pg) => pg.id === selectedId)?.id || 'Error'
              : 'Select price group'}
          </Typography>
        </div>
      }
      placement={'top'}
      options={[
        ...priceGroups.map((option) => (
          <Typography
            
            // color={!isFilterOnId(categoryId) ? 'primary' : 'secondary'}
            align={'center'}
            renderAs={'span'}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {option.id}
          </Typography>
        )),
      ]}
      selectedIndex={priceGroups.findIndex((pg) => pg.id === selectedId)}
      onChange={(i: number) => onChangePriceGroup(priceGroups[i].id)}
      showArrowIcon={false}
      openOnHover
      fullWidth
    />
  );
};
