import * as React from 'react';
import { DialogType } from 'src/app/constants';
import { ButtonTw, Localized } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

/**
 * SignupButton - Opens signup dialog
 * - hidden if logged in
 * @param { SignupButtonProps } props
 * @version 0.0.0
 */
export const SignupButton = () => {
  const { openDialog, isLoggedIn } = useAppContext();
  const onClick = () => {
    openDialog(DialogType.SIGNUP);
  };

  return isLoggedIn ? null : (
    <div className='hidden md:block'>
      <ButtonTw variant='text' onClick={onClick} className={'dark:text-neutral-50 text-p3 !font-medium hover:text-accent-500 dark:hover:text-accent-500 transition duration-300'}>
        <Localized dictKey={'staticLocales:appbar.btn signup'} className='capitalize' />
      </ButtonTw>
    </div>
  );
};