import * as React from 'react';

import { ButtonUpload, Grid, Typography } from 'src/app/components';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface ButtonFileSelectProps {
  accept?: string;
  buttonTitle: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  onSelectFile: (file: File | null) => void;
  title?: string | React.ReactNode;
}

export const ButtonFileSelect = ({
  accept,
  buttonTitle,
  disabled,
  icon,
  onSelectFile,
  title,
}: ButtonFileSelectProps) => {
  return (
    <Grid container>
      {title ? (
        <Grid item xs={6}>
          {typeof title === 'string' ? (
            <Typography center variant={'caption'}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Grid>
      ) : null}
      <Grid item xs={title ? 6 : 12}>
        <ButtonUpload
          disabled={disabled}
          accept={accept || '*'}
          color={'secondary'}
          fullWidth
          onChange={(e) => onSelectFile(e?.target?.files ? e.target.files[0] : null)}
          variant={'contained'}
          startIcon={icon || <CloudUploadIcon />}
          title={buttonTitle}
        />
      </Grid>
    </Grid>
  );
};
