import React, { useEffect, useMemo, useState } from 'react';


import { PaginationButtons, TableList } from '../table-search/components';
import { NotificationType } from 'src/app/constants';

import {
  getSearchResult,
  makeSearchQueryInTypSenseFormat,
  mapResultValues,
} from '../table-search/helpers';
import {
  DataSearchTableProps,
  ResultDataFromTypeSense,
  SearchListObject,
  SearchParams,
} from '../table-search/types';
import { typesenseApi } from 'src/api/callables';
import { useAppContext } from 'src/app/context';
import { useAppStaticQueryConfig } from 'src/app/hooks';

const  DataSearchTable: React.FC<DataSearchTableProps> = ({
  tableHeadings,
  collectionName,
  filterParamter,
  tableName,
  onEditProfileClick,
  onOpenMessage,
  onUpdateProductCategoryTypes,
  onEnableOrganisationOperations
}) => {
  const [serviceList, setServiceList] = useState<ResultDataFromTypeSense>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const [orderbyField, setOrderbyField] = useState<string>('');
  const [orderbyVal, setOrderbyVal] = useState<string>('');
  let filterValue:any='';
  if(filterParamter){
    if(filterParamter['supplier']){
      filterValue = 'supplierId:'+ filterParamter['supplier'];
    }else if( filterParamter['user']){
      filterValue = 'userId:'+ filterParamter['user'];
    }
  }
  const [searchQueryParams, setSearchQueryParams] = useState<SearchParams>({
    q: '*',
    query_by: '',
    filter_by:filterValue?filterValue:'',
  });
 
  const [searchList, setSearchList] = useState<SearchListObject>({});
  const { firebase,createStackedNotification, openDialog, } = useAppContext();
   const { env } = useAppStaticQueryConfig();

  const dateFields = useMemo(() => {
    const fields = tableHeadings
      .filter((head: any) => head.fieldType === 'date')
      .map((i) => i.dataName);
    return fields || [];
  }, [tableHeadings]);

  const amountFields = useMemo(() => {
    const fields = tableHeadings
      .filter((head: any) => head.fieldType === 'number')
      .map((i) => i.dataName);
    return fields || [];
  }, [tableHeadings]);

  const participantCountField = useMemo(() => {
    const fields = tableHeadings
      .filter((head: any) => head.fieldType === 'int')
      .map((i) => i.dataName);
    return fields || [];
  }, [tableHeadings]);

  useEffect(() => {
    setLoading(true);
    
    const getServiceList = async () => {
      if(filterParamter){
      if(filterParamter['supplier']){
        const field = { ['supplierId']: filterParamter['supplier'] };
        setSearchList({ ...searchList, ...field });
      }else if( filterParamter['user']){
        const field = { ['userId']: filterParamter['user'] };
        setSearchList({ ...searchList, ...field });
      }
    }
    const searchParam: SearchParams = makeSearchQueryInTypSenseFormat(
      searchList,
      dateFields,
      amountFields,
      participantCountField
    );
    console.log('ss',searchQueryParams);
    setSearchQueryParams(searchParam);
      const response:any= await typesenseApi(
        firebase,
        {
          searchParam:searchQueryParams,
          pageNumber:1,
          orderbyField,
          orderbyVal,
          collectionName,
        },
        env
      );
      console.log('djkshfkjsdhkjfh',response.data.payload.successArray);
      // if (error && errorMessage) {
      //   setLoading(false);
      //   return alert(errorMessage);
      // }
      const formatedData: ResultDataFromTypeSense = mapResultValues(
        response.data.payload.successArray.result,
        tableHeadings,
      );
      setServiceList(formatedData || []);
      setLoading(false);
      setCount( response.data.payload.successArray.totalSize);
    };

    getServiceList();
  }, [orderbyVal]);

  const showNext = async () => {
    if (serviceList.length === 0) {
      alert('Thats all we have for now !');
    } else {
      if(filterParamter){
        if(filterParamter['supplier']){
          const field = { ['supplierId']: filterParamter['supplier'] };
          setSearchList({ ...searchList, ...field });
        }else if( filterParamter['user']){
          const field = { ['userId']: filterParamter['user'] };
          setSearchList({ ...searchList, ...field });
        }
      }
      const searchParam: SearchParams = makeSearchQueryInTypSenseFormat
        (
          searchList,
          dateFields,
          amountFields,
          participantCountField
        );
      setSearchQueryParams(searchParam);
      console.log('ss',searchQueryParams);
      const response:any= await typesenseApi(
        firebase,
        {
          searchParam,
          pageNumber:page + 1,
          orderbyField,
          orderbyVal,
          collectionName,
        },
        env
      );
      console.log('djkshfkjsdhkjfh',response);
      const formatedData: ResultDataFromTypeSense = mapResultValues(
        response.data.payload.successArray.result,
        tableHeadings,
      );
      setServiceList(formatedData || []);
      setLoading(false);
      setPage(page + 1);
    }
  };

  const showPrevious = async () => {
    if(filterParamter){
      if(filterParamter['supplier']){
        const field = { ['supplierId']: filterParamter['supplier'] };
        setSearchList({ ...searchList, ...field });
      }else if( filterParamter['user']){
        const field = { ['userId']: filterParamter['user'] };
        setSearchList({ ...searchList, ...field });
      }
    }
    const searchParam: SearchParams = makeSearchQueryInTypSenseFormat(
      searchList,
      dateFields,
      amountFields,
      participantCountField
    );
    setSearchQueryParams(searchParam);
    console.log('ss',searchQueryParams);
     const response:any= await typesenseApi(
        firebase,
        {
          searchParam,
          pageNumber:page - 1,
          orderbyField,
          orderbyVal,
          collectionName,
        },
        env
      );
    console.log('djkshfkjsdhkjfh',response);
    const formatedData: ResultDataFromTypeSense = mapResultValues(
      response.data.payload.successArray.result,
      tableHeadings,
    );
    setServiceList(formatedData || []);
    setLoading(false);
    setPage(page - 1);
  };

  const handleSearch = async (fieldName = '', value: any) => {
    console.log('sdsdsad',fieldName);

    setOrderbyField('');
    setOrderbyVal('');

    let finalSearchList: SearchListObject = {};
    // if(filterParamter){
    //   if(filterParamter['supplier']){
    //     const field = { ['supplierId']: filterParamter['supplier'] };
    //     finalSearchList={ ...searchList, ...field }
    //     setSearchList({ ...searchList, ...field });
    //   }else if( filterParamter['user']){
    //     const field = { ['userId']: filterParamter['user'] };
    //     console.log(searchList,"searchhhhhhhhhhhhhhhhh")
    //     finalSearchList={ ...searchList,...field }
    //     console.log(finalSearchList,"finalserartjhti")
    //     setSearchList({ ...searchList, ...field });

    //   }
    // }

    console.log('ss',searchQueryParams);

    if (fieldName !== '') {
      // call from date change
      if (value !== '') {
        //finalSearchList = { ...searchList, [fieldName]: value };
        finalSearchList = {
          ...searchList,[fieldName]: value
        };
      } else {
        Object.prototype.hasOwnProperty.call(searchList, fieldName)
          ? delete searchList[fieldName]
          : '';
        finalSearchList = { ...searchList };
      }
    } else {
      finalSearchList = { ...searchList };
    }
    

    const searchParam: SearchParams = makeSearchQueryInTypSenseFormat(
      finalSearchList,
      dateFields,
      amountFields,
      participantCountField
    );
    setSearchQueryParams(searchParam);
    const response:any= await typesenseApi(
      firebase,
      {
        searchParam:searchParam,
        pageNumber:1,
        orderbyField,
        orderbyVal,
        collectionName,
      },
      env
    );
  console.log('djkshfkjsdhkjfh',response);
  
    const formatedData: ResultDataFromTypeSense = mapResultValues(
      response.data.payload.successArray.result,
      tableHeadings,
    );
    console.log(
      'Result data from Typesense',
       response.data.payload.successArray.result,
      'formatedData',
      formatedData,
    );
    setServiceList(formatedData || []);
    setLoading(false);
    setCount(response.data.payload.successArray.totalSize);
    setPage(1);
  };

  const handleSetOrder = (value: string) => {
    setOrderbyField(value);
    if (orderbyVal !== '') {
      orderbyVal == 'desc' ? setOrderbyVal('asc') : setOrderbyVal('desc');
    } else {
      setOrderbyVal('asc');
    }
  };

  return (
    <div className='mx-auto'>
      <div className='bg-white dark:bg-gray-800 relative  sm:rounded-lg overflow-hidden'>
        <div className='w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3 flex-shrink-0 py-5 px-1'>
          <h1 className=' text-gray-500 text-3xl font-normal leading-none tracking-tight   dark:text-white'>
            {tableName}
          </h1>
        </div>
        {loading ? (
          <div
            style={{
              width: '100px',
              margin: 'auto',
              minHeight: '500px',
              marginTop: '200px',
            }}
          >
            {/* <Loader /> */}
          </div>
        ) : (
          <>
            <div className='overflow-x-auto'>
              <TableList
                tableData={serviceList}
                tableHead={tableHeadings}
                handleSetOrder={handleSetOrder}
                detailUrl='/admin/organizations'
                orderbyField={orderbyField}
                orderbyVal={orderbyVal}
                handleSearch={handleSearch}
                searchList={searchList}
                setSearchList={setSearchList}
                onEditProfileClick={onEditProfileClick}
                onOpenMessage={onOpenMessage}
                onUpdateProductCategoryTypes={onUpdateProductCategoryTypes} 
                onEnableOrganisationOperations={onEnableOrganisationOperations}

              />
            </div>
            <nav
              className='flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 py-4'
              aria-label='Table navigation'
            >
              {count > 0 && (
                <span className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  Showing{' '}
                  <span className='font-semibold text-gray-900 dark:text-white'>
                    {(page - 1) * 10 + 1}-{Math.min(page * 10, count)}{' '}
                  </span>
                  of{' '}
                  <span className='font-semibold text-gray-900 dark:text-white'>
                    {count}
                  </span>
                </span>
              )}

              <div className='flex'>
                {/* <!-- Previous Button --> */}

                {
                  //show previous button only when we have items
                  page === 1 ? (
                    ''
                  ) : (
                    <PaginationButtons
                      handleClick={showPrevious}
                      buttonName='Previous'
                    />
                  )
                }

                {
                  //show next button only when we have items
                  serviceList.length < 10 || page * 10 == count ? (
                    ''
                  ) : (
                    <PaginationButtons
                      handleClick={showNext}
                      buttonName='Next'
                    />
                  )
                }
              </div>
            </nav>
          </>
        )}
      </div>
    </div>
  );
};
export default DataSearchTable;
