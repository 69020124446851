import { BaseOrganisationDocument } from 'src/admin/types';
import { ProductCategoryType } from 'src/app/constants';

export const hasOrganisationProductType = (
  organisationDocument: BaseOrganisationDocument,
  productType: ProductCategoryType,
) =>
  !!organisationDocument?.productCategoryTypeConfig.find(
    (cfg) => cfg.id === productType && cfg.isEnabled,
  );
