import * as React from 'react';
import { deleteDoc, getFirestore, doc } from 'firebase/firestore';

import {
  Grid,
  ProductListItem,
  LoadingIndicator,
  DocumentDeleteErrorNotification,
  DocumentDeleteSuccessNotification,
} from 'src/app/components';
import {
  useFirebaseCollectionEZ,
  useAppContext,
  useSourcedProducts,
} from 'src/app/hooks';
import { NotificationType } from 'src/app/constants';
import { WishlistItemAdditionalInformation } from './WishlistItemAdditionalInformation';
import { WishlistItemContent } from './WishlistItemContent';
import { WishlistDocumentData } from './wishlist.types';

/**
 * Wishlist
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const Wishlist = () => {
  const { firebase, user, createNotification } = useAppContext();
  const products = useSourcedProducts();
  const uid = user?.uid;
  const wishlistCollectionPath = `user/${uid}/wishlist`;
  // TODO: implement local storage version for non registered user?

  const [expandedId, setExpandedId] = React.useState<string | null>(null);

  // Wishlist documents from firestore

  const [shouldFetch, setShouldFetch] = React.useState<boolean>(true);
  const [isFetchingWishlistDocs, setIsFetchingWishlistDocs] = React.useState<boolean>(
    true,
  );
  const [wishlistDocuments, setWishlistDocuments] = React.useState<
    WishlistDocumentData[] | null
  >(null);

  const { results: wishlistDocsFetchResults } = useFirebaseCollectionEZ<
    WishlistDocumentData
  >(
    wishlistCollectionPath,
    shouldFetch,
    true, // isPrivileged
  );

  React.useEffect(() => {
    if (shouldFetch) {
      setIsFetchingWishlistDocs(true);
      setWishlistDocuments([]); // reset array
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  React.useEffect(() => {
    if (wishlistDocsFetchResults) {
      setWishlistDocuments([...wishlistDocsFetchResults]);
      setIsFetchingWishlistDocs(false);
    }
  }, [wishlistDocsFetchResults]);

  const onAddItemToCart = () => {};

  const onWishlistUpdate = () => {};

  const onRemove = async (docId: string) => {
    if (!firebase || !docId || !uid) {
      return;
    }
    try {
      await deleteDoc(doc(getFirestore(firebase), `user/${uid}/wishlist/${docId}`));
      createNotification(NotificationType.SUCCESS, DocumentDeleteSuccessNotification);
      setShouldFetch(true);
    } catch (err) {
      createNotification(NotificationType.ERROR, DocumentDeleteErrorNotification);
    }
  };

  const onWishlistItemRemove = (docId: string) => {
    onRemove(docId);
  };

  // console.log('wishlistDocuments: ', wishlistDocuments);

  return (
    <div container spacing={4}>
      {wishlistDocuments?.length ? (
        wishlistDocuments.map((wishlistItem) => {
          const isExtended = expandedId === wishlistItem.id;
          const productDocument = products?.find(
            (product) => product.id === wishlistItem.productId,
          );
          if (!productDocument) {
            return null;
          }

          return (
            <>
              <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative my-6 md:my-4' key={wishlistItem.id}>
                <div className='w-full md:w-1/3'>
                  <ProductListItem
                    // key={wishlistItem.id}
                    hasWishlistIcon={false}
                    product={productDocument}
                  />
                </div>
                <div className='w-full h-full py-4 px-4 gap-2 md:px-6 md:h-auto md:w-2/3 md:min-h-[240px] flex flex-col'>
                {/* <Grid item xs={12}> INCEPTION
                      <iframe
                        src={`/experience/${wishlistItem.productId}`}
                        style={{ width: '100%', height: '600px' }}
                      />
                    </Grid> */}
                  <WishlistItemContent
                    wishlistDocument={wishlistItem}
                    onAddItemToCart={onAddItemToCart}
                    onCartItemRemove={onWishlistItemRemove}
                    onOpenAdditionalInformation={() =>
                      setExpandedId(isExtended ? null : wishlistItem.id)
                    }
                    onShareButtonClick={() => {}}
                    product={productDocument}
                  />
                </div>
              </div>
              {isExtended ? (
                <div className='py-4'>
                  <WishlistItemAdditionalInformation
                    wishlistDocument={wishlistItem}
                    onCartItemUpdate={onWishlistUpdate}
                    product={productDocument}
                  />
                </div>
              ) : null}
            </>
          );
        })
      ) : (
        <>
          {wishlistDocuments ? (
            <p className='text-p1 font-semibold'>You have no items on your wishlist</p>
          ) : null}
        </>
      )}
      {isFetchingWishlistDocs || shouldFetch ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : null}
    </div>
  );
};
