import * as React from 'react';
import clsx from 'clsx';

import { Grid } from 'src/app/components';

import { useStyles } from 'src/app/context';

export interface FieldAdditionalContainer {
  children?: React.ReactNode;
}
export const FieldAdditionalContainer = ({ children }: FieldAdditionalContainer) => {
  const styles = useStyles();
  return children ? (
    <Grid container className={clsx({ [styles.pt__2]: true })}>
      <Grid item xs={12} className='hidden-if-empty'>
        {children}
      </Grid>
    </Grid>
  ) : null;
};
