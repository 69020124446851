import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { useAppContext, useSourcedProducts } from 'src/app/hooks';
import { formatFirebaseTimestamp } from 'src/lib/date';
import { FirestoreCollectionData, Pagination } from 'src/app/container';

import { Timestamp, where } from 'firebase/firestore';


import { Accordion, Button, ButtonTw, DialogLayout, Grid, LoadingIndicator, Typography } from 'src/app/components';
import { parseSkuString } from 'src/lib/event';

import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  setDoc,
  updateDoc,
  getDoc
} from 'firebase/firestore';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DocumentDeleteProps } from 'src/app/types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';


export const TransactionDialog = (props: any) => {
  const { item, onCloseDialog, order } = props;
  return (
    <DialogLayout
      dialogDescription={'Transaction Details'}
      dialogControls={
        <Grid container >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={onCloseDialog}
              fullWidth
              variant={'contained'}
              color={'primary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              <Typography>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    >
      {!order ? <Typography variant={'subtitle1'}>
        No Orders Found
      </Typography> : (


        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell >Name</TableCell>
                <TableCell >Price&nbsp;Group</TableCell>
                <TableCell >Price</TableCell>
                <TableCell >If&nbsp;pickup&nbsp;show&nbsp;address</TableCell>
                <TableCell >Price&nbsp;of&nbsp;Pickup</TableCell>
                <TableCell >If&nbsp;food&nbsp;show&nbsp;name</TableCell>
                <TableCell >Total&nbsp;Amount</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {order.productItems.map((ord: any, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell>
                    {ord.payer.name.given_name}  {ord.payer.name.surname}
                  </TableCell>
                  {/* <TableCell> {row.item.name}</TableCell> */}


                  <TableCell component="th" scope="row">
                    <p> {ord.description.split(/[[\]]{1,2}/)[1]} </p> {/* TODO: show real data */}

                  </TableCell>

                  <TableCell>
                    <p>
                      {ord.unit_amount.value} {ord.unit_amount.currency_code}
                    </p>
                  </TableCell>
                  <TableCell> Janka Bobetka 10, 21312 Podstrana</TableCell>
                  <TableCell>0 EUR</TableCell>
                  <TableCell> Chicken</TableCell>
                  <TableCell >
                    {ord?.quantity || 0} x {ord?.unit_amount?.value || 0} {ord?.unit_amount?.currency_code || 'EUR'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      )
      }

    </DialogLayout>
  );
};




