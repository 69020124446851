import { confirmAction } from 'src/app/lib';
import { useAppContext } from 'src/app/context';

// TODO: accept a password or id to verify

export const useConfirmAction = () => {
  const { closeDialog, openDialog } = useAppContext();
  const confirmActionOverride = (
    action: () => Promise<void> | void,
    additionalMessage?: string,
  ) => confirmAction(action, openDialog, closeDialog, additionalMessage);
  return {
    confirmAction: confirmActionOverride,
  };
};
