import * as React from 'react';
import clsx from 'clsx';
import { Localized, LocalizedNodesContainer } from 'src/app/components';

import { DocumentLocalizationNodeData } from 'src/app/types';

interface NodeContainerProps {
  children?: React.ReactElement;
  elevation?: number;
  i18nPrefix: string;
  i18nPath: string;
  localizedNodes: DocumentLocalizationNodeData[];
  isGlassWrapped?: boolean;
  mdSize?: GridSize;
  fullHeight?: boolean;
}

export const NodeContainer = ({
  children,
  iconList,
  iconTitle,
  titleClass,
  className,
  hasDropdown,
  elevation,
  i18nPrefix,
  i18nPath,
  isGlassWrapped = true,
  localizedNodes,
  mdSize = 6,
  fullHeight = true,
}: NodeContainerProps) => {

  return isGlassWrapped ? (
    <div
      className={clsx(className ?? '')}
    >
      <LocalizedNodesContainer
        iconList={iconList}
        hasDropdown={hasDropdown}
        iconTitle={iconTitle}
        localizedNodes={localizedNodes}
        title={<Localized dictKey={`${i18nPrefix}.${i18nPath}.title`} />}
        titleClass={titleClass}
        description={<Localized dictKey={`${i18nPrefix}.${i18nPath}.description`} />}
      />
      {children}
    </div>
  ) : (
    <div
      className={clsx(className ?? '')}
      >
      <LocalizedNodesContainer
        iconList={iconList}
        hasDropdown={hasDropdown}
        iconTitle={iconTitle}
        localizedNodes={localizedNodes}
        title={<Localized dictKey={`${i18nPrefix}.${i18nPath}.title`} />}
        titleClass={titleClass}
        description={<Localized dictKey={`${i18nPrefix}.${i18nPath}.description`} />}
      />
      {children}
    </div>
  );
};
