import * as React from 'react';

import { ButtonTw, ButtonProps, Localized } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps } from 'src/app/types';

import LogoutIcon from '@material-ui/icons/MeetingRoom';

export interface LogoutButtonProps extends ButtonProps {
  closeMenu: any;
}

/**
 * LogoutButton / WAPLogoutButton
 * @param { LogoutButtonProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LogoutButton = (props: LogoutButtonProps) => {
  const { color, closeMenu, ...passProps } = props;
  const { logout } = useAppContext();
  const onClick = () => {
    logout();
    if (closeMenu) closeMenu();
  };
  return (
    <ButtonTw variant='text' onClick={onClick} className={'inline-flex items-center gap-1 text-p3 dark:text-neutral-50 !font-medium hover:text-accent-500 dark:hover:text-accent-500 transition duration-300 w-full'}>
      <LogoutIcon />
      <span className='capitalize leading-none'>
        <Localized dictKey={'account:screen.screen-account-logout.nav label'} />
      </span>
    </ButtonTw>
  );
};

const WAPLogoutButton = (props: CombinedAppProps<LogoutButtonProps>) =>
  withoutAppProps<CombinedAppProps<LogoutButtonProps>>(LogoutButton, props);

export { LogoutButton, WAPLogoutButton };
