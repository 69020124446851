import * as React from 'react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
// import { navigate } from 'gatsby';
import { Router } from '@reach/router';

import {
  BooleanSwitch,
  Grid,
  Link,
  LoadingIndicator,
  Localized,
  LocalizedLinkButton,
  PrivateRoute,
  Typography,
} from 'src/app/components';
import { NotificationType } from 'src/app/constants';
import { useStyles } from 'src/app/context';
import { useAppContext } from 'src/app/hooks';
import { UserAccountSettingsData } from 'src/app/types';
import { FirestoreDocData } from 'src/app/container';
import { useTranslation } from 'react-i18next';

const i18nPath = 'shop:/account/settings';

const UserAccountSettingsScreen = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { createNotification, firebase, user } = useAppContext();

  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);

  if (typeof window === 'undefined') {
    return null;
  }

  const onSubmit = async (
    e: React.ChangeEvent<HTMLInputElement>,
    propName:
      | 'isSendEmailMessagingOn'
      | 'isSendEmailRecommendationsOn'
      | 'isSendEmailTipsAndOffersOn',
  ) => {
    try {
      if (!firebase || !user?.uid) {
        return null;
      }

      setRequestingUpdate(true);

      const docRef = doc(getFirestore(firebase), `user/${user.uid}`);
      await updateDoc(docRef, { [propName]: e.target.checked });

      createNotification(
        NotificationType.SUCCESS,
        <div>Settings successfully changed</div>,
        5000,
      );
      // navigate('/account');
    } catch (err) {
      console.warn(err);
      createNotification(
        NotificationType.ERROR,
        <div>
          Settings change error: {err.message || ''}
          {/* <Localized dictKey={`${documentI18nBasePath}-errors.${err.message}`} /> */}
        </div>,
        5000,
      );
    } finally {
      setRequestingUpdate(false);
    }
    return null;
  };

  return !user?.uid ? null : (
    <FirestoreDocData<UserAccountSettingsData>
      useSubscription
      documentPath={`user/${user.uid}`}
      renderElements={({ data: result, status }) => {
        if (status === 'loading' || isRequestingUpdate) {
          return <LoadingIndicator />;
        }

        if (status === 'error') {
          return 'Error';
        }

        // console.log('result: ', result);

        // const resHasProp_IsSendEmailMessagingOn = Object.keys(result).find(
        //   (item) => item === 'isSendEmailMessagingOn',
        // );

        // const resHasProp_IsSendEmailRecommendationsOn = Object.keys(result).find(
        //   (item) => item === 'isSendEmailRecommendationsOn',
        // );

        // const resHasProp_IsSendEmailTipsAndOffersOn = Object.keys(result).find(
        //   (item) => item === 'isSendEmailTipsAndOffersOn',
        // );

        return status !== 'success' ? null : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {/* {resHasProp_IsSendEmailMessagingOn ? ( */}
                <Grid item xs={12}>
                  <BooleanSwitch
                    description={
                      <Localized
                        dictKey={`${i18nPath}.feature.email-enabled.description`}
                      />
                    }
                    disabled
                    label={
                      <Localized dictKey={`${i18nPath}.feature.email-enabled.label`} />
                    }
                    onSwitch={(e) => onSubmit(e, 'isSendEmailMessagingOn')}
                    // value={result.isSendEmailMessagingOn}
                    value
                    title={
                      <Localized dictKey={`${i18nPath}.feature.email-enabled.title`} />
                    }
                  />
                </Grid>
                {/* ) : null} */}
                <Grid item xs={12}>
                  <Typography>
                    <Localized
                      dictKey={`${i18nPath}.feature.email-enabled.email-destination`}
                      className={styles.pr__1}
                    />
                    <strong>{user?.email || ''}</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Link
                    to={`${t(`${i18nPath}.action.change-email.link.url`)}`}
                    // variant={'text'}
                    // startIcon={<HistoryIcon />}
                    color={'primary'}
                    size={'small'}
                    className={styles.buttonBase}
                    // fullWidth
                  >
                    <Localized dictKey={`${i18nPath}.action.change-email.link.label`} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            {/* {resHasProp_IsSendEmailRecommendationsOn ? ( */}
            <Grid item xs={12}>
              <BooleanSwitch
                description={
                  <Localized
                    dictKey={`${i18nPath}.feature.recommendations.description`}
                  />
                }
                disabled
                label={
                  <Localized dictKey={`${i18nPath}.feature.recommendations.label`} />
                }
                onSwitch={(e) => onSubmit(e, 'isSendEmailRecommendationsOn')}
                // value={result.isSendEmailRecommendationsOn}
                value
                title={
                  <Localized dictKey={`${i18nPath}.feature.recommendations.title`} />
                }
              />
            </Grid>
            {/* ) : null} */}
            {/* {resHasProp_IsSendEmailTipsAndOffersOn ? ( */}
            <Grid item xs={12}>
              <BooleanSwitch
                description={
                  <Localized dictKey={`${i18nPath}.feature.tips-offers.description`} />
                }
                disabled
                label={<Localized dictKey={`${i18nPath}.feature.tips-offers.label`} />}
                onSwitch={(e) => onSubmit(e, 'isSendEmailTipsAndOffersOn')}
                // value={result.isSendEmailTipsAndOffersOn}
                value
                title={<Localized dictKey={`${i18nPath}.feature.tips-offers.title`} />}
              />
            </Grid>
            {/* ) : null} */}
            <Grid item xs={12}>
              <LocalizedLinkButton
                to='/privacy-policy'
                variant={'text'}
                // startIcon={<HistoryIcon />}
                color={'primary'}
                size={'small'}
                className={styles.buttonBase}
                fullWidth
              >
                <Localized dictKey={'staticLocales:pages./privacy-policy.link label'} />
              </LocalizedLinkButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export const UserAccountSettingsRouter = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Router style={{ width: '100%' }}>
          <PrivateRoute<{}> path={'/*'} component={UserAccountSettingsScreen} />
        </Router>
      </Grid>
    </Grid>
  );
};
