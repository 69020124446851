import * as React from 'react';
import { Paper, PaperProps } from 'src/app/components';

import { CombinedBaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';
import clsx from 'clsx';

export interface GlassPaperProps extends CombinedBaseComponentProps<PaperProps> {
  noOutline?: boolean;
}

/**
 * GlassPaper
 * @param { GlassPaperProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const GlassPaper = (props: GlassPaperProps) => {
  const { children, elevation, className, noOutline, ...passProps } = props;
  const styles = useStyles();
  return (
    <Paper
      // color={'transparent'}
      isTransparent
      {...passProps}
      elevation={
        typeof elevation !== 'undefined' && !Number.isNaN(elevation) ? elevation : 4
      }
      className={clsx({
        [styles.glassPaper]: Boolean(typeof elevation !== 'undefined' && elevation > 0),
        [styles.glassPaper__outlined]: !noOutline,
        [styles.bg__transparent]: Boolean(
          (typeof elevation !== 'undefined' && elevation === 0) || !elevation,
        ),
        [className || '']: true,
      })}
    >
      {children}
    </Paper>
  );
};
