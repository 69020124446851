import * as React from 'react';
import { Grid, Localized } from 'src/app/components';
import { UserAccountPasswordRouter } from 'src/app/container/userAccountPassword/UserAccountPasswordRouter';
import { useAppContext } from 'src/app/hooks';

interface AdminAccountProps {
  children?: React.ReactNode;
}

export const AdminAccount = ({ children }: AdminAccountProps) => {
  const { isLoggedIn, user } = useAppContext();

  if (!isLoggedIn) {
    return null;
  }

  console.log(JSON.stringify(children));
  return (
    <Grid container>
      <Grid item md={10} xs={12}>
        <h1 className='pb-4 text-h3 md:text-h1 font-bold flex items-center gap-y-4'>
          <Localized dictKey={'staticLocales:pages./account.link label'} />
        </h1>
      </Grid>
      <Grid item md={10} xs={12}>
        <span className='pb-4 text-h4 font-bold flex items-center gap-y-4'>
          {user?.email}
        </span>
      </Grid>
      <Grid item md={10} xs={12}>
        <UserAccountPasswordRouter />
      </Grid>
    </Grid>
  );
};
