import * as React from 'react';

import CancelIcon from '@material-ui/icons/Block';

import {
  ButtonTw,
  DialogLayout,
  Grid,
  Localized,
  ProductAddToCartContainer,
  Typography,
} from 'src/app/components';
import { useStyles } from 'src/app/context';
import { AddToCartDialogParams } from 'src/app/types';

const i18nPath = 'shop:screen-product';

export interface AddToCartDialogProps extends AddToCartDialogParams {
  children?: React.ReactNode;
  onCloseDialog: () => void;
}

export const AddToCartDialog = (props: AddToCartDialogProps) => {
  const {
    children,
    initialParams,
    onCloseDialog,
    product,
    bookingSystemType,
    pickupOptionId,
  } = props;
  const styles = useStyles();

  return (
    <DialogLayout
      dialogDescription={<Localized dictKey={`${i18nPath}.add-cart.subTitle`} />}
      dialogControls={
        <div className='text-center'>
            <ButtonTw
              onClick={onCloseDialog}
              variant={'text'}
              className='underline !text-accent-500 !font-semibold hover:!text-neutral-900 dark:hover:!text-neutral-50'
            >
              <Localized dictKey={`${i18nPath}.add-cart.action.cancel.label`} />
            </ButtonTw>
        </div>
      }
    >
      <ProductAddToCartContainer
        product={product}
        onSuccess={onCloseDialog}
        bookingSystemType={bookingSystemType}
        pickupOptionId={pickupOptionId}
        initialParams={initialParams}
      />
      {children}
    </DialogLayout>
  );
};
