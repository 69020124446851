import * as React from 'react';
import clsx from 'clsx';
import { BaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';
import { Grid, Typography, Localized } from 'src/app/components';

export interface LocalizedErrorIndicatorProps extends BaseComponentProps {
  i18nContext: 'app' | 'system' | 'organisation'; // => is the namespace name used?
  i18nErrorCode: string;
  i18nErrorAreaCode: string;
}

/**
 * LocalizedErrorIndicator
 * @param { LocalizedErrorIndicatorProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const LocalizedErrorIndicator = (props: LocalizedErrorIndicatorProps) => {
  const {
    children,
    className,
    i18nContext,
    i18nErrorCode,
    i18nErrorAreaCode,
    ...passProps
  } = props;
  const styles = useStyles();
  return (
    <Grid
      container
      className={clsx(styles.formErrors__container, styles.p__2, className || '')}
    >
      <Grid item xs={12}>
        <Typography>
          Error:
          <Localized dictKey={`${i18nContext}:${i18nErrorCode}.${i18nErrorAreaCode}`} />
        </Typography>
      </Grid>
      {children ? (
        <Grid item xs={12}>
          {children}
        </Grid>
      ) : null}
    </Grid>
  );
};
