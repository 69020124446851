import * as React from 'react';
import { useAppContext } from 'src/app/hooks';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { Grid } from 'src/app/components';
import {
    collection,
    CollectionReference,

  } from 'firebase/firestore';
  import {
    Divider,
    StringPropertyRow,
    LocalizedLink,
  } from 'src/app/components';

// TODO: reuse AppPageLoayout here?

import { basePath } from '../navigationBasePath';
import { parseSkuString } from 'src/lib/event';
import { useLocalizationContext } from 'src/app/hooks';
export const UserOrder = (props:any) => {
  const {id}=props
  const { isLoggedIn ,user} = useAppContext();
  console.log(user,'yugfyugfuygugy')
  const firestore = useFirestore();
  const collectionPath = `user/${user?.uid}/order`;
  console.log(user,'users')
const collectionRef = collection(firestore, collectionPath) as CollectionReference;
const fetchOptions = {
  initialData: null,
  // suspense
};
let orderDetail
const { status, data } = useFirestoreCollectionData(collectionRef, fetchOptions);
if (data) {
     orderDetail = data.find(item => item.id === id);
  } else {
    console.log("Data is not available yet.");
  }

// console.log(data,'jydjyytdyj')

  if (
    !isLoggedIn ||
    typeof window === 'undefined' ||
    !window.location.pathname.startsWith(basePath)
  ) {
    return null;
  }

  const PurchaseUnitItemView = ({
    item,
  }: {
    item: {
      description: string;
      name: string;
      quantity: number;
      unit_amount: {
        value: string;
        currency_code: string;
      };
      sku: string;
      category: string; // could be left away in BE while saving userOrderDoc
      tax: {
        value: string;
        currency_code: string;
      };
      imageFiles:Array<any>
    };
  }) => {
    const { language } = useLocalizationContext();
    const parsedSku = parseSkuString(item.sku);
    if (!parsedSku) {
      return null;
    }
    const {
      productId,
      priceSystemId,
      priceGroupId,
      eventDateIsoStringId,
      userId,
      organisationId,
      orderId,
      cartItemId,
    } = parsedSku;
  
    return (
      <Grid container spacing={1} style={{ padding: '2px 0' }}>
        {/* <Grid item xs={12}>
          <StringPropertyRow title={'Quantity'} value={item?.quantity || 0} />
        </Grid> */}
        {/* <Grid item xs={12}>
          <DebugObject isDebug object={item} collapsed={0} />
        </Grid> */}
        <Grid item xs={12}>
          <StringPropertyRow
            title={`
              ${
                new Date(eventDateIsoStringId).toLocaleString(language, {
                  hour: '2-digit',
                  minute: '2-digit',
                }) || ''
              }
               - 
              ${
                new Date(eventDateIsoStringId).toLocaleString(language, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }) || ''
              }
            `}
            value={' '}
          />
        </Grid>
        <Grid   xs={12} spacing={2} alignItems="center"> {/* Align items vertically centered */}

<StringPropertyRow
    title={
      <>
        <LocalizedLink href={`/experience/${productId}`}>
          {item?.name || ''}
        </LocalizedLink>
        {` - ${item?.description || ''}`}
      </>
    }
    value={`${item?.quantity || 0} x ${item?.unit_amount?.value || 0} ${
      item?.unit_amount?.currency_code || 'EUR'
    }`}
    image={item.imageFiles && (
      <img
      
      // className={clsx(cn.media, !isWiderMD ? cn.mediaSM : {})}
      src={item.imageFiles[0]?.childImageSharp.fluid.src} // TODO: fallback
      alt={'Activity Title Image'}
      width={100} // Set the width of the image
      height={100} // Set the height of the image
      className={'rounded '}
    />
  
    )}
  />

</Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* //TODO: map booked options here */}
        {/* <Grid item xs={12}>
          <StringPropertyRow title={'Quantity'} value={item?.quantity || 0} />
        </Grid> */}
      </Grid>
    );
  };
  
  const TotalsView = ({
    purchaseUnits,
  }: {
    purchaseUnits: any[]; // TODO
  }) => {
    // TODO: calc total of all purchase units
    const total = purchaseUnits[0]?.amount?.breakdown?.item_total?.value || 0;
    const currencyCode =
      purchaseUnits[0]?.amount?.breakdown?.item_total?.currency_code || 'EUR';
    return (
      <Grid container>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
          <StringPropertyRow title={'Total'} value={`${total} ${currencyCode}`} />
        </Grid>
        {/* <Grid item xs={12}>
          <DebugObject isDebug object={purchaseUnits} collapsed={0} />
        </Grid> */}
      </Grid>
    );
  };
  

  // TODO: change profile info
  // cancel order

  return (
    <Grid container>
      <Grid item >
        {/* <OrdersContainer /> */}
        <div>
                  <Grid item xs={12}>
             </Grid>
             <Grid item xs={12}>
               {orderDetail?.orderData?.purchase_units?.[0]?.items?.map((item) => (
                 <PurchaseUnitItemView
                   key={`${item.name}_${item.description}_${item.quantity}`}
                   item={item}
                 />
               )) || null}
             </Grid>
             {orderDetail?.orderData?.purchase_units?.length ? (
               <Grid item xs={12}>
                 <TotalsView purchaseUnits={orderDetail.orderData.purchase_units} />
               </Grid>
             ) : null}
              </div>

      </Grid>
    </Grid>
  );
};