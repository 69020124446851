import React from 'react';

import { TableColumn, TableRowClickEvent } from 'src/app/types';
import { StyledVirtualizedTable } from './StyledVirtualizedTable';

interface VirtualizedTableProps<T extends Record<string, unknown>> {
  className?: string;
  data: T[];
  header: TableColumn[];
  onRowClick: (e: TableRowClickEvent) => void;
  toolbar?: React.ReactNode;
  isLocalized: boolean;
  i18nBasePath?: string;
  selectedRowIndex?: number;
}

export function VirtualizedTable<T extends Record<string, unknown>>(
  props: VirtualizedTableProps<T>,
) {
  const {
    className,
    selectedRowIndex,
    header,
    data,
    onRowClick,
    toolbar,
    isLocalized,
    i18nBasePath,
  } = props;
  return (
    // <Glass />
    <div className={className || ''}>
      <div>
        {toolbar ? (
          <div>
            {toolbar}
          </div>
        ) : null}
        <div className='min-h-[400px]'>
          <StyledVirtualizedTable
            rowCount={data.length}
            headerHeight={48}
            rowHeight={48}
            rowGetter={({ index }) => data[index]}
            columns={header}
            onRowClick={onRowClick}
            isLocalized={isLocalized} // drilling
            i18nBasePath={i18nBasePath} // drilling
            currentSelectedIndex={selectedRowIndex}
          />
        </div>
      </div>
    </div>
  );
}
