import * as React from 'react';

import { DocumentLocalizationNodeData, BaseComponentProps } from 'src/app/types';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

export interface LocalizedNodesContainerProps extends BaseComponentProps {
  localizedNodes: DocumentLocalizationNodeData[]; // atm support only for BaseBullets
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
}

export const LocalizedNodesContainer = (props: LocalizedNodesContainerProps) => {
  const { localizedNodes, title, description, iconList, iconTitle, titleClass, hasDropdown } = props; // TODO: render different bulletTypes...

  const [isDropdown, setDropdown] = React.useState(false);
  const toggleDropdownClass = () => {
    setDropdown(!isDropdown);
  };

  return (
    <>
      <h2 className={titleClass} onClick={toggleDropdownClass}>
        {iconTitle}
        {title}
        {hasDropdown && (
          <span className={`absolute right-0 top-[2px] md:hidden ${isDropdown ? 'rotate-180' : '' } `}>
            <ArrowDropDown />
          </span>
        )}
      </h2>
      <p className='text-p2'>{description}</p>

      {localizedNodes?.length
        ? (
          <div className={`${hasDropdown ? `mb-6 md:mb-0 ${isDropdown ? '' : 'hidden md:block' }` : '' }`}>
            <ul className='list-none flex flex-col gap-2 md:gap-4'>
              {localizedNodes.map((locNode) => {
                return (
                  <li
                    key={`${locNode.title}`}
                    className=''
                  >
                    <p className='text-p2 font-normal relative pl-8'>
                      {iconList}
                      {locNode.title || ''}
                    </p>
                    <p className='text-p3 font-light pl-8'>
                      {locNode.additionalInformation || ''}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )
        : null}
    </>
  );
};



