import * as React from 'react';
import { navigate } from 'gatsby';
import { useFormContext } from 'react-hook-form';

import { ButtonTw } from 'src/app/components';

export const FormLinkButton = ({
  disabled,
  label,
  to,
  triggerProperties,
  shouldFocus = true,
  ...passProps
}: {
  className?: string;
  disabled?: boolean;
  label: any;
  to: string;
  triggerProperties: string[];
  shouldFocus?: boolean;
}) => {
  const { trigger } = useFormContext();
  const onClick = () => {
    if (triggerProperties?.length) {
      trigger(triggerProperties || undefined, { shouldFocus: typeof shouldFocus !== 'undefined' ? shouldFocus : true }).then(
        (res) => {
          if (res) {
            if (navigate) {
              navigate(to);
            }
          }
        },
      );
    } else if (navigate) {
      navigate(to);
    }
  };
  return (
    <ButtonTw
      disabled={disabled || false}
      variant={'outline'}
      rounded
      size='lg'
      onClick={onClick}
      {...passProps}
    >
      {label || ''}
    </ButtonTw>
  );
};
