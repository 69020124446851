import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import RowingIcon from '@material-ui/icons/Rowing';
import DirectionsBoatOutlinedIcon from '@material-ui/icons/DirectionsBoatOutlined';
import LocationOnIcon from '@material-ui/icons/RoomOutlined';


import {
  ButtonTw,
  CityMapWidget,
  ItemCard,
  ItemTextParagraph,
  // Localized,
  ProductListItem,
  ProductListItemContent,
} from 'src/app/components';
import {
  ContentNavigationType,
  SourcedGQLCity,
  SourcedGQLLocation,
  SourcedGQLProduct,
} from 'src/app/types';
import {
  useAppContext,
  useFirebase,
  useLocalizedNavigation,
  useLocalizationContext,
} from 'src/app/hooks';

import { AppLocale, GatsbyNodeType } from 'src/app/constants';
import { useStyles } from 'src/app/context';
import { getBaseDocumentLocales } from 'src/lib/document';
import { Pagination } from 'src/app/container';

// PRODUCT CONTENT
import { ProductPriceContainer } from './Product/ProductPriceContainer';
import { ProductContent } from './Product/ProductContent';
// import { ProductReviews } from './Product/ProductReviews';
import {
  ProductListFilterContainer,
  ProductListFilterView,
} from './Product/ProductListFilter';

// BASE CONTENT
import { ContentItemWeatherInformation } from './ContentItemWeatherInformation';
import { ContentNavigationItem } from './ContentNavigationItem';
import { ContentNavigationAdditionalContent } from './ContentNavigationAdditionalContent';

export interface ContentItemScreenProps {
  collectionMappings: CollectionMapping[];
  itemType: GatsbyNodeType;
  itemLocations: SourcedGQLLocation[];
  nextItemId?: string;
  prevItemId?: string;
  screenItemData: ContentNavigationType;

  previewLanguage?: string; // FIXME: create a speacial preview ItemScreenComponent?
}

export interface CollectionMapping {
  id: GatsbyNodeType;
  collectionItems: ContentNavigationType[];

  // USE 1 of those:
  collectionItemLocationIdPropertyName?: string; // (first for location fields)
  collectionItemIdMetaArrayIdPropertyName?: string; // (second for meta array fields, category(Type)Ids)
}

export const ContentItemScreen = ({
  collectionMappings,
  itemType,
  itemLocations,
  nextItemId,
  prevItemId,
  screenItemData,
  previewLanguage, // only for preview of document
}: ContentItemScreenProps) => {
  const { user } = useAppContext();
  const { useStoreActions } = useFirebase();
  const { useSetDocument } = useStoreActions();
  const { setDocument } = useSetDocument();

  const { setPayBoxVisible } = useAppContext();
  const observableRef = React.useRef(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setPayBoxVisible(entry.isIntersecting);
      },
      { rootMargin: '5%' },
    );
    const observable = observableRef.current;
    if (observable) {
      observer.observe(observable);
    }
    return () => {
      observer.disconnect();
    };
  }, [setPayBoxVisible]);

  const { language: lang } = useLocalizationContext();

  const language = previewLanguage || lang;
  const { navigateLocalized } = useLocalizedNavigation();
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const reviewAnchorId = '';
  const fontSize = isWiderMD ? '1.2em' : '1em';

  const [currentView, setCurrentView] = React.useState<
    'all' | 'cityInformationView' | 'activitiesView' | 'boatsView'
  >('all');

  const toggleBtnStyles = 'flex flex-shrink-0 h-10 first:ml-4 last:mr-4 gap-x-2 justify-center items-center md:h-auto md:w-full md:first:ml-0 md:last:mr-0 md:flex-shrink disabled:cursor-auto disabled:!opacity-100';

  const getNavigationPath = (type: GatsbyNodeType, navItemId: string): string => {
    switch (type) {
      case GatsbyNodeType.CONTINENT:
        return `/${navItemId}`;
      case GatsbyNodeType.COUNTRY:
        return `/${screenItemData.id}/${navItemId}`;
      case GatsbyNodeType.COUNTRY_AREA:
        return `/${screenItemData.continentId}/${screenItemData.id}/area/${navItemId}`;
      case GatsbyNodeType.CITY:
        return `/${screenItemData.continentId}/${screenItemData.id}/city/${navItemId}`;
      case GatsbyNodeType.CITY_AREA:
        return `/${screenItemData.continentId}/${screenItemData.countryId}/city/${screenItemData.id}/area/${navItemId}`;
      case GatsbyNodeType.PRODUCT:
        return `/experience/${navItemId}`;
      default:
        return '';
    }
  };

  const onWishlistClick = (itemId: string) => {
    if (!user?.uid) {
      return;
    }
    const wishlistDoc = {
      id: nanoid(),
      productId: itemId,
    };
    setDocument(wishlistDoc, `user/${user?.uid}/wishlist`, false);
  };

  return (
    <ItemCard
      onAddWishlistProduct={
        itemType === GatsbyNodeType.PRODUCT ? onWishlistClick : undefined
      }
      item={screenItemData} // TODO: SourcedGQLProduct<T>
      itemType={itemType}
      reviewAnchorId={reviewAnchorId}
      extraChildren={
        <ContentNavigationAdditionalContent item={screenItemData} itemType={itemType} />
      }
      itemLocales={getBaseDocumentLocales(screenItemData, language)}
      itemInformation={
        <>
          {isWiderMD &&
          itemType === GatsbyNodeType.CITY &&
          screenItemData.countryId &&
          screenItemData.id ? (
            <ContentItemWeatherInformation
              cityName={getBaseDocumentLocales(screenItemData, language).name}
              country={screenItemData.countryId}
              city={getBaseDocumentLocales(screenItemData, AppLocale.EN).name}
            />
          ) : null}
          {itemType === GatsbyNodeType.PRODUCT && screenItemData.id ? (
            <div ref={observableRef}>
              <ProductPriceContainer
                product={screenItemData as any as SourcedGQLProduct}
              />
            </div>
          ) : null}
        </>
      }
      nextItemId={nextItemId}
      prevItemId={prevItemId}
    >
      <>
        {/** WEATHER INFORMATION */}
        {!isWiderMD &&
        itemType === GatsbyNodeType.CITY &&
        screenItemData.countryId &&
        screenItemData.id ? (
          <ContentItemWeatherInformation
            cityName={getBaseDocumentLocales(screenItemData, language).name}
            country={screenItemData.countryId}
            city={getBaseDocumentLocales(screenItemData, AppLocale.EN).name}
          />
        ) : null}

        {/** VIEW SWITCH */}
        {itemType !== GatsbyNodeType.PRODUCT ? (
          <div className='pt-10 md:pt-20 dark:text-neutral-50'>
            <h2 className='font-semibold pb-3 text-h4 md:pb-5 md:font-bold md:text-h3'>Things to do in {getBaseDocumentLocales(screenItemData, language).name}</h2>

            <div className='-mx-4 md:mx-0'>
              <div className='flex gap-x-2 overflow-x-scroll no-scrollbar snap-mandatory snap-x  md:overflow-x-auto md:justify-between md:gap-x-3 lg:gap-x-4 xl:gap-x-12'>

                <ButtonTw
                  onClick={() => setCurrentView('all')}
                  disabled={currentView === 'all'}
                  variant = {currentView === 'all' ? 'accent' : 'outline'}
                  size='md'
                  rounded
                  className={`${toggleBtnStyles}`}
                >
                  All products
                </ButtonTw>

                <ButtonTw
                  onClick={() => setCurrentView('activitiesView')}
                  disabled={currentView === 'activitiesView'}
                  variant = {currentView === 'activitiesView' ? 'accent' : 'outline'}
                  size='md'
                  rounded
                  className={` ${toggleBtnStyles}`}
                >
                  <RowingIcon />
                  Activities
                </ButtonTw>

                <ButtonTw
                  onClick={() => setCurrentView('boatsView')}
                  disabled={currentView === 'boatsView'}
                  variant = {currentView === 'boatsView' ? 'accent' : 'outline'}
                  size='md'
                  rounded
                  className={` ${toggleBtnStyles}`}
                >
                  <DirectionsBoatOutlinedIcon />
                  Boats & Cruises
                </ButtonTw>

                <ButtonTw
                  onClick={() => setCurrentView('cityInformationView')}
                  disabled={currentView === 'cityInformationView'}
                  variant = {currentView === 'cityInformationView' ? 'accent' : 'outline'}
                  size='md'
                  rounded
                  className={` ${toggleBtnStyles}`}
                >
                  <LocationOnIcon />
                  Location information
                </ButtonTw>

                {/* <ToggleButtonGroup
                  value={currentView}
                  exclusive
                  className={'w-full justify-between'}
                  onChange={(e, view) => setCurrentView(view)}
                >
                  <ToggleButton
                    variant='text'
                    value={'all'}
                    className={'!text-red-500 bg-primary-500'}
                    //color={'primary'}
                    disabled={currentView === 'all'}
                  >

                      All products

                  </ToggleButton>
                  <ToggleButton
                    value={'activitiesView'}
                    className={styles.w__100}
                    color={'primary'}
                    disabled={currentView === 'activitiesView'}
                  >
                    <Typography
                      color={currentView === 'activitiesView' ? 'secondary' : 'primary'}
                      style={{ fontSize }}
                    >
                      Activities
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value={'boatsView'}
                    className={styles.w__100}
                    color={'primary'}
                    disabled={currentView === 'boatsView'}
                  >
                    <Typography
                      color={currentView === 'boatsView' ? 'secondary' : 'primary'}
                      style={{ fontSize }}
                    >
                      Boats & Cruises
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value={'cityInformationView'}
                    className={styles.w__100}
                    color={'primary'}
                    disabled={currentView === 'cityInformationView'}
                  >
                    <Typography
                      color={
                        currentView === 'cityInformationView' ? 'secondary' : 'primary'
                      }
                      style={{ fontSize }}
                    >
                      Location information
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup> */}

              </div>
            </div>
          </div>
        ) : null}

        {/** MAP */}
        {currentView === 'cityInformationView' &&
        itemType === GatsbyNodeType.CITY &&
        screenItemData.countryId ? (
          <div>
            <CityMapWidget
              city={screenItemData as SourcedGQLCity}
              mapLocations={itemLocations}
            />
          </div>
        ) : null}

        {/** PRODUCT SPECIFIC CONTENT */}
        {itemType === GatsbyNodeType.PRODUCT && screenItemData?.location?.countryId ? (
          <ProductContent product={screenItemData as any as SourcedGQLProduct} />
        ) : null}

        {/** DISPLAY CONTENT NAV LINKS */}
        {collectionMappings.map((collectionMap) => {
          // NOTE: TEMP CASE FOR PRODUCT LOCATION.cityId interface
          // TODO: adjust other types
          if (collectionMap.id === GatsbyNodeType.PRODUCT) {
            const products: SourcedGQLProduct[] = collectionMap.collectionItems.filter(
              (colItem) => {
                const item = colItem as SourcedGQLProduct;
                // NOTE: BÄH => first case for get 'meta.category(Type)Ids' from an array
                if (collectionMap.collectionItemIdMetaArrayIdPropertyName) {
                  return item.meta?.[
                    collectionMap.collectionItemIdMetaArrayIdPropertyName
                  ]?.includes(screenItemData.id);
                }
                // NOTE: BÄH => second case for get 'location.xxx' geo data from an array
                if (collectionMap.collectionItemLocationIdPropertyName) {
                  return (
                    item.location?.[
                      collectionMap.collectionItemLocationIdPropertyName
                    ] === screenItemData.id
                  );
                }
                return false;
              },
            ) as SourcedGQLProduct[];
            return (
              <div
                key={collectionMap.id}
                className=''
              >
                {currentView === 'activitiesView' ? (
                  <ProductListFilterContainer
                    products={products}
                    renderElements={({ data: filteredProducts, filter, setFilter }) => {
                      return (
                        <>
                          <div className='py-4'>
                            <ProductListFilterView
                              allProducts={products}
                              filteredProducts={filteredProducts}
                              filter={filter}
                              setFilter={setFilter}
                            />
                          </div>
                          <ItemTextParagraph title={collectionMap.id} />
                          <Pagination<SourcedGQLProduct>
                            className='py-2'
                            data={filteredProducts}
                            renderElements={({ data: paginatedData }) => (
                              <div className='flex gap-2 overflow-x-scroll no-scrollbar -ml-4 md:ml-0 md:overflow-x-auto md:flex-col md:gap-4 snap-mandatory snap-x py-4 -mx-4 md:mx-0'>
                                {paginatedData?.map((item) => {
                                  return (
                                    <div
                                      className='w-10/12 first:ml-4 last:mr-4 sm:w-2/3 md:first:ml-0 md:last:mr-0 md:ml-0 md:w-full flex-shrink-0 snap-center'
                                      key={item.id}>
                                      <div
                                        onClick={() =>
                                          navigateLocalized(`/experience/${item.id}`)
                                        }
                                        className='w-full bg-transparent border-none h-full cursor-pointer'
                                      >
                                        <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 h-full dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative'>
                                          <div className='w-full md:w-1/3'>
                                            <ProductListItem
                                              key={item.id}
                                              product={item as SourcedGQLProduct}
                                              hasWishlistIcon={false}
                                            />
                                          </div>
                                          <div className='w-full h-full md:h-auto md:w-2/3'>
                                            <ProductListItemContent
                                              {...(item as SourcedGQLProduct)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          />
                        </>
                      );
                    }}
                  />
                ) : (
                  <div className='py-8'>
                    <Pagination<SourcedGQLProduct>
                      className='py-2'
                      data={products}
                      renderElements={({ data: paginatedProducts }) => (
                        <div className='flex gap-2 overflow-x-scroll no-scrollbar md:ml-0 md:overflow-x-auto md:flex-col md:gap-4 snap-mandatory snap-x py-4 -mx-4 md:mx-0'>
                          {paginatedProducts.map((item) => {
                            // TODO: "add more" => functionality?
                            return ( 
                              <div
                                className='w-10/12 first:ml-4 last:mr-4 sm:w-2/3 md:first:ml-0 md:last:mr-0 md:ml-0 md:w-full flex-shrink-0 snap-center'
                                key={item.id}
                              >
                                {/* <ContentNavigationItem
                                  onClick={(navItemId: string) =>
                                    navigateLocalized(
                                      getNavigationPath(collectionMap.id, navItemId),
                                    )
                                  }
                                  item={item}
                                /> */}
                                <div
                                  onClick={() =>
                                    navigateLocalized(`/experience/${item.id}`)
                                  }
                                  className='w-full bg-transparent border-none h-full cursor-pointer'
                                >
                                  <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 h-full dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative'>
                                    <div className='w-full md:w-1/3'>
                                      <ProductListItem
                                        key={item.id}
                                        product={item as SourcedGQLProduct}
                                        hasWishlistIcon={false}
                                      />
                                    </div>
                                    <div className='w-full h-full md:h-auto md:w-2/3'>
                                      <ProductListItemContent
                                        {...(item as SourcedGQLProduct)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            );
          }
          return currentView === 'cityInformationView' ? (
            <div
              key={collectionMap.id}
              className='md:col-span-12'
            >
              {/* <ItemTextParagraph className='pt-4' title={collectionMap.id} /> */}

              <Pagination<NotSourcedGQLProduct>
                className='py-2 px-4 md:px-0'
                data={
                  collectionMap.collectionItems.filter(
                    (item) =>
                      item[collectionMap.collectionItemLocationIdPropertyName] ===
                      screenItemData.id,
                  ) as NotSourcedGQLProduct[]
                }
                renderElements={({ data: paginatedData }) => (
                  <div className='flex flex-col gap-4'>
                    {paginatedData.map((pItem) => (
                      <div key={pItem.id}>
                        <ContentNavigationItem
                          onClick={(navItemId: string) =>
                            navigateLocalized(
                              getNavigationPath(collectionMap.id, navItemId),
                            )
                          }
                          item={pItem}
                        />
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          ) : null;
        })}
      </>
    </ItemCard>
  );
};
