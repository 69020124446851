export enum MessageType {
  INBOUND = 'in',
  LOG = 'log', // special db route for system (only for system?)
  OUTBOUND = 'out',
}

export enum MessageCollection {
  ORGANISATION = 'message_organisation',
  SYSTEM = 'message_system',
  USER = 'message_user',
}
