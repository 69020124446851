import * as React from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { Localized, Stepper } from 'src/app/components';
import { useStyles } from 'src/app/context';

export interface FormStepsProps {
  activeStep: number;
  baseNavPath?: string;
  i18nBasePath: string;
  steps: string[];
}

export const FormSteps = ({
  activeStep,
  baseNavPath,
  i18nBasePath,
  steps,
}: FormStepsProps) => {
  const styles = useStyles();

  const onClickNavStep = (id: string) => {
    if (baseNavPath && id) {
      navigate(`${baseNavPath}/${id}`);
    }
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps?.length
        ? steps.map((id, idx) => (
            <Step
              key={id}
              className={clsx({
                [styles.hover__cursor]: Boolean(baseNavPath && idx !== activeStep),
              })}
              onClick={baseNavPath ? () => onClickNavStep(id) : () => null}
              disabled={false}
              completed={false}
            >
              <StepLabel>
                <Localized dictKey={`${i18nBasePath}./${id}.shortTitle`} />
              </StepLabel>
            </Step>
          ))
        : null}
    </Stepper>
  );
};
