import { BookingSystemType } from 'src/app/constants';
import { DaytimeEventDocument } from 'src/app/types';

export const createSku = (
  // systemId
  // countryId
  productId: string,
  priceSystem: string,
  priceGroupId: string,
  productDateId: string,
  userId: string,
  organisationId: string,
  // TODO: reorganise
  orderId: string,
  cartItemId: string,
) => {
  // return `${productId}#${priceSystem}#${priceGroupId}#${productDateId}#${userId}#${organisationId}#${orderId}#${cartItemId}`;
  return `${productId}#-#-#${productDateId}#${userId}#${organisationId}#-#-`;
};

export const parseSkuString = (
  str: string,
): {
  productId: string;
  priceSystemId: string;
  priceGroupId: string;
  eventDateIsoStringId: string;
  userId: string;
  organisationId: string;
  orderId: string;
  cartItemId: string;
} | null => {
  // NOTE: verify sku string parse requirements are met else return null
  const split = str?.split('#') || [];
  // if (split.length !== 8) {
  //   console.error('parseSkuString() - length error');
  //   return null;
  // }

  // for (let index = 0; index < split.length; index++) {
  //   if (!split[index]) {
  //     console.error('parseSkuString() - content error');
  //     return null;
  //   }
  // }

  return {
    productId: split[0] || '',
    priceSystemId: split[1] || '',
    priceGroupId: split[2] || '', // does this suffice for quantity related discounts ?
    eventDateIsoStringId: split[3] || '',
    userId: split[4] || '',
    organisationId: split[5] || '',
    orderId: split[6] || '',
    cartItemId: split[7] || '',
  };
};

export const getEventTicketOrderLimit = (
  eventDoc: DaytimeEventDocument,
  bookingType: BookingSystemType,
) => {
  let eventTicketsMax = 0;
  // TODO: use switch if more booking systems are necessary
  if (bookingType === BookingSystemType.PUBLIC) {
    eventTicketsMax = eventDoc?.priceSystem?.publicBooking?.maxParticipants || 0;
  } else if (bookingType === BookingSystemType.PRIVATE) {
    eventTicketsMax = eventDoc?.priceSystem?.privateBooking?.maxParticipants || 0;
  }
  // const eventTicketsTaken = 8 + eventDoc.offSystemOrderCount; // Test availability
  const eventTicketsTaken = eventDoc.orders.length + eventDoc.offSystemOrderCount;
  const eventTicketsAvailable = eventTicketsMax - eventTicketsTaken;
  return !eventTicketsAvailable || eventTicketsAvailable <= 0 ? 0 : eventTicketsAvailable;
};
