import * as React from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { DropDown, Typography } from 'src/app/components';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';
import { useAppContext, useSourcedCurrencies } from 'src/app/hooks';

import { ThemeMode } from 'src/app.consumer/constants';
import { useStyles } from 'src/app/context';

export interface CurrencySelectionProps extends BaseComponentProps {
  themeMode?: ThemeMode;
}

/**
 * CurrencySelection / WAPCurrencySelection
 * @param { CurrencySelectionProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
function CurrencySelection(props: CurrencySelectionProps) {
  const { children, className, themeMode, ...passProps } = props;
  const styles = useStyles();
  const { currencyId, setCurrency } = useAppContext();
  const availableCurrencies = useSourcedCurrencies();
  const selectedCurrencyIndex = currencyId
    ? availableCurrencies.findIndex((cur) => cur.id === currencyId)
    : 0;

  const onCurrencyChange = (index: number) => {
    const newCurrency = availableCurrencies[index];
    setCurrency(newCurrency.id);
  };

  return currencyId && availableCurrencies?.length ? (
    <DropDown
      themeMode={themeMode || ThemeMode.LIGHT}
      variant='text'
      label={<p className={`font-normal text-sm ${className}`}>{currencyId}</p>}
      options={availableCurrencies.map((cur) => `${cur.id}`)}
      selectedIndex={selectedCurrencyIndex || 0}
      onChange={(idx: number) => onCurrencyChange(idx)}
      showArrowIcon={false}
      openOnHover
      fullWidth
      {...{ passProps, className }}
    >
      {children}
    </DropDown>
  ) : null;
}

const WAPCurrencySelection = (props: CombinedAppProps<CurrencySelectionProps>) =>
  withoutAppProps<CombinedAppProps<CurrencySelectionProps>, CurrencySelectionProps>(
    CurrencySelection,
    props,
  );

export { CurrencySelection, WAPCurrencySelection };
