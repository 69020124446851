import * as React from 'react';

export interface FieldInformationProps {
  children?: React.ReactNode;
  description: React.ReactNode;
  example: React.ReactNode;
  hasError: boolean;
}

export const FieldInformation = ({
  children,
  description,
  example,
  hasError,
  links,
}: FieldInformationProps) => {
  return (
    <>
      <p className={`text-p4 md:text-p3 px-4 ${hasError ? 'text-red-600' : ''}`}>
        {description}
      </p>
      <p className={`text-p4 md:text-p3 px-4 ${hasError ? 'text-red-600' : ''}`}>
        {example}
      </p>
      {children}
    </>
  );
};
