import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

export interface TranslateApiCallableRequestParams {
    transilateData: any;
    sourceLanguage: string;
    targetLanguage:string;
    translationApi:string
  }

/**
 * systemFunction-translateApi
 * @version 0.0.0 functions version
 */
export const translateApi = async (
  firebase: FirebaseApp,
  params: TranslateApiCallableRequestParams,
  env: string,
) => {
  return httpsCallable(
    getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
    'systemFunction-translateApi',
  )(params);
};