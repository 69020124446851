import * as React from 'react';
import { ShoppingCartItemDocumentData, SourcedGQLProduct } from 'src/app/types';
import { FirestoreCollectionData } from 'src/app/container';
import { LoadingIndicator } from 'src/app/components';
import { useAppContext, useSourcedProducts } from 'src/app/hooks';
import { CartSystemType } from 'src/app/constants';

import { ShoppingCartProvider } from 'src/app/context';
import API from 'src/api/firestore';
import { ShoppingCartLayout } from './ShoppingCartLayout';

// const i18nPath = 'shop:/shopping-cart';

export const ShoppingCartContainer = () => {
  // TODO: fetch the product document and verify seat count availability
  const { user } = useAppContext();
  const sourcedProducts = useSourcedProducts();

  const uid = user?.uid || '';

  if (!user?.uid) {
    return (
      <ShoppingCartProvider
        operatingMode={CartSystemType.LOCAL_STORAGE}
        sourceProducts={sourcedProducts as SourcedGQLProduct[]}
        isCheckoutEnabled={false}
      >
        <ShoppingCartLayout />
      </ShoppingCartProvider>
    );
  }

  const collectionPath = API.SHOP.COLLECTION.USER.CART(uid);

  return (
    <FirestoreCollectionData<ShoppingCartItemDocumentData>
      collectionPath={collectionPath}
      renderElements={({ data: cartItems, status }) => {
        if (status === 'loading') {
          return <LoadingIndicator />;
        }
        if (status === 'error') {
          return (
            <>{'Error'}</>
            // <LocalizedErrorIndicator
            //   i18nContext={'organisation'}
            //   i18nErrorCode={'LOADING_COLLECTION'}
            //   i18nErrorAreaCode={'order'}
            // />
          );
        }
        // TODO: add pagination?
        /* status !== 'success' || !cartItems?.length ? null : */
        return (
          <ShoppingCartProvider
            key={`${status}_${cartItems.length}`}
            cartItems={cartItems}
            operatingMode={CartSystemType.CLOUD}
            sourceProducts={sourcedProducts as SourcedGQLProduct[]}
            isCheckoutEnabled
          >
            <ShoppingCartLayout />
          </ShoppingCartProvider>
        );
      }}
    />
  );
};
