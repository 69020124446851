import * as React from 'react';
import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoginForm } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

import { useStyles } from 'src/app/context';

import { FormRootContainer } from 'src/app/components/RHF';
import { NotificationType } from 'src/app/constants';
import { delay } from 'src/app/lib';

interface LoginFormValues extends FieldValues {
  email: string;
  password: string;
}

const defaultValues: LoginFormValues = {
  email: '',
  password: '',
};

const loginFormSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

const resolver = yupResolver(loginFormSchema);

export interface LoginFormProps extends BaseComponentProps {
  children?: React.ReactNode;
}

/**
 * LoginForm / WAPLoginForm - MUI
 * @param { LoginFormProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoginFormContainer = (props: LoginFormProps) => {
  const { children } = props;
  const styles = useStyles();
  const { closeDialog, createNotification, isAppDebug, login } = useAppContext();

  const localizationPath = 'shop:loginForm';

  const onSubmit = async (formData: { email: string; password: string }) => {
    try {
      const res = await login(formData);
      if (res) {
        const { user } = res;
        if (user.emailVerified) {
          closeDialog();
        } else {
          createNotification(
            NotificationType.INFO,
            <>{'Please verify your email address first'}</>,
            5000,
          );
        }
      }
    } catch (err: unknown) {
      createNotification(NotificationType.ERROR, <>{err?.code || 'Error'}</>, 5000);
    }
  };

  return (
    <>
      <FormRootContainer<LoginFormValues>
        className={styles.px__4}
        defaultValues={defaultValues}
        isDebug={isAppDebug}
        resolver={resolver}
        onSubmit={onSubmit}
        useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
        renderForm={({ formProps }) => (
          <LoginForm<LoginFormValues>
            i18nBasePath={`${localizationPath}`}
            // formProps={formProps}
          />
        )}
      />
      {children}
    </>
  );
};

const WAPLoginFormContainer = (props: CombinedAppProps<LoginFormProps>) =>
  withoutAppProps<CombinedAppProps<LoginFormProps>, LoginFormProps>(
    LoginFormContainer,
    props,
  );

export { LoginFormContainer, WAPLoginFormContainer };
