// components/SearchComponent.js
import { SearchBoxProps } from '../types/DataTableComponentProps';
import React from 'react';

export const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder,
  name,
  handleChange,
  handleSearch,
  searchList,
}) => {
  const submitSearch = (e: any) => {
    e.preventDefault();
    handleSearch();
  };
  return (
    <form className='flex items-center'>
      <div className='relative flex items-center w-full'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <svg
            aria-hidden='true'
            className='w-4 h-4 text-gray-500 opacity-65 dark:text-gray-400'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <input
          type='text'
          id='simple-search'
          name={name}
          className='bg-gray-50 border text-[11px]  font-normal border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-focus-border block w-full pl-10 p-[10px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  dark:focus:border-[#1FCCB9]'
          placeholder={placeholder}
          onChange={handleChange}
          value={
            Object.prototype.hasOwnProperty.call(searchList, name)
              ? searchList[name]
              : ''
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') submitSearch(e);
          }}
        />
      </div>
    </form>
  );
};