import * as React from 'react';

import { useAppContext } from 'src/app/hooks';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
  Button,
  Grid,
  LoadingIndicator,
  TextInputField,
  Typography,
} from 'src/app/components';

interface UserReAuthenticationProps {
  onSuccess: () => void;
  onFailure: (error: any) => void;
}

export const UserReAuthentication = ({
  onSuccess,
  onFailure,
}: UserReAuthenticationProps) => {
  const { firebase, user } = useAppContext();
  const [passwordInput, setPasswordInput] = React.useState('');
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const onSubmit = async () => {
    try {
      if (!firebase || !user?.email || !passwordInput) {
        return null;
      }
      setIsFetching(true);

      // NOTE: Better way wanted => ?
      // const credential: Firebase.auth.AuthCredential = {};
      // await firebase.auth().currentUser?.reauthenticateWithCredential(credential);

      const auth = getAuth(firebase);
      await signInWithEmailAndPassword(auth, user?.email, passwordInput);
      onSuccess();
    } catch (err) {
      console.warn(err);
      onFailure(err);
    } finally {
      setIsFetching(false);
    }
    return null;
  };
  return !isFetching ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{'Please authenticate with your current password'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextInputField
          fullWidth
          variant={'outlined'}
          label={'Password'}
          type={'password'}
          value={passwordInput}
          onChange={(e) => {
            setPasswordInput(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onSubmit}>OK</Button>
      </Grid>
    </Grid>
  ) : (
    <LoadingIndicator />
  );
};
