import * as React from 'react';
import clsx from 'clsx';

import { DropDown, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useLocalizationContext, useSourcedCategories } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';

interface CategoryFilterProps {
  availableCategoryIds: string[];
  currentCategoryFilterIds: string[];
  setCategoryFilter: (id: string, isOn: boolean) => void;
}

export const CategoryFilter = ({
  availableCategoryIds,
  currentCategoryFilterIds,
  setCategoryFilter,
}: CategoryFilterProps) => {
  const styles = useStyles();
  const categories = useSourcedCategories();
  const { language } = useLocalizationContext();

  const isFilterOn = () => !!currentCategoryFilterIds.length;

  const isFilterOnId = (id: string) =>
    !!currentCategoryFilterIds.find((filterId) => filterId === id);

  const onChangeFilter = (id: string) => {
    setCategoryFilter(id, !isFilterOnId(id));
  };

  const selectedCategory = currentCategoryFilterIds?.length
    ? categories.find((ca) => ca.id === currentCategoryFilterIds[0])
    : null;

  const selectedIndex = selectedCategory
    ? categories.findIndex((ca) => ca.id === currentCategoryFilterIds[0])
    : null;
  return (
    <Grid container spacing={2} className={clsx(styles.py__1)}>
      <Grid item xs={12}>
        <DropDown
          disabled={!availableCategoryIds?.length}
          // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
          className={styles.dropDown__Button}
          variant={'contained'}
          color={isFilterOn() ? 'secondary' : 'primary'}
          label={
            <Typography align={'center'} >
              {currentCategoryFilterIds?.length
                ? `${
                    getBaseDocumentLocales(selectedCategory, language).name ||
                    currentCategoryFilterIds[0]
                  }`
                : 'Select a category'}
            </Typography>
          }
          placement={'top'}
          options={[
            ...availableCategoryIds
              .filter((categoryId) => categories.find((cat) => cat.id === categoryId))
              .map((categoryId) => {
                const category = categories.find((cat) => cat.id === categoryId);
                return !category ? null : (
                  <Grid container key={categoryId}>
                    <Grid item xs={12}>
                      <Typography
                        
                        color={!isFilterOnId(categoryId) ? 'primary' : 'secondary'}
                        align={'center'}
                        renderAs={'span'}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {getBaseDocumentLocales(category, language).name || categoryId}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }),
          ]}
          selectedIndex={selectedIndex !== null ? selectedIndex : -1}
          onChange={(id: string) => onChangeFilter(availableCategoryIds[id])}
          showArrowIcon={false}
          openOnHover
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
