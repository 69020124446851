import * as React from 'react';

import { NotificationType } from 'src/app/constants';
import { NotificationState } from 'src/app/types';

export function useNotificationState(): NotificationState {
  const [isNotificationOpen, setIsNotificationOpen] = React.useState<boolean>(false);
  const [useStack, setUseStack] = React.useState<boolean>(false);
  const [notificationType, setNotificationType] = React.useState<NotificationType | null>(
    null,
  );
  const [notificationContent, setNotificationContent] =
    React.useState<React.ReactNode | null>(null);
  const [notificationDisplayTime, setNotificationDisplayTime] =
    React.useState<number>(6000);
  const closeNotification = () => {
    setNotificationType(null);
    setIsNotificationOpen(false);
  };
  const createNotification = (
    type: NotificationType,
    content: React.ReactNode,
    displayTime?: number,
  ) => {
    setNotificationType(type);
    setNotificationContent(content);
    setNotificationDisplayTime(displayTime || 6000);
    setIsNotificationOpen(true);
  };
  const createStackedNotification = (
    type: NotificationType,
    content: React.ReactNode,
    displayTime?: number,
  ) => {
    setNotificationType(type);
    setNotificationContent(content);
    setNotificationDisplayTime(displayTime || 6000);
    setIsNotificationOpen(true);
    setUseStack(true);
  };
  return {
    closeNotification,
    isNotificationOpen,
    notificationContent,
    notificationType,
    notificationDisplayTime,
    createNotification,
    createStackedNotification,
    useStack,
    setUseStack,
  };
}
