import * as React from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { ButtonTw } from 'src/app/components';

import { useStyles } from 'src/app/context';

// TODO: map config values and replace the bc labels

export const AdminBreadcrumbs = () => {
  const styles = useStyles();

  if (typeof window === 'undefined') {
    return null;
  }

  const path = window.location.pathname;
  const segments = path.split('/').filter(Boolean) || [];
  const productTypes = [
    'activity-000',
    'boat-000',
    'rental-000',
    'reservation-000',
    'transfer-000',
  ];
  const specialSegments = ['event', 'participants'];
  // const isCreateRoute = !!path.includes('/create');
  // const lockedRoutes = isCreateRoute
  //   ? ['create', ...specialSegments]
  //   : ['edit', ...productTypes, ...specialSegments]; // parametrized routes
  const lockedRoutes = [
    'create',
    'edit',
    'configuration',
    'activity',
    'images',
    'translations',
    ...productTypes,
    ...specialSegments,
  ]; // lock all stuff
  const breadcrumbs = segments.map((seg, bcIdx) => {
    if (bcIdx === 0) {
      // dont show the "/admin" route (login?)
      return null;
    }

    const isEditSubPath = segments[bcIdx - 1] && segments[bcIdx - 1] === 'edit';

    // const isProductEditSubpath = true;

    if (bcIdx >= segments.length - 1 || lockedRoutes.includes(seg) || isEditSubPath) {
      return (
        <span key={seg} className={''}>
          {seg || ''}
        </span>
      );
    }
    const reducedPath = segments
      .filter((item, segIdx) => segIdx <= bcIdx) // take only as much as the index high is ;)
      .reduce((prev, cur) => {
        return prev ? `${prev}/${cur}` : `/${cur}`;
      });

    return (
      <a key={seg} href={`/${reducedPath}`} className={' hover:opacity-60'}>
        <span>{seg || ''}</span>
      </a>
    );
  });

  return (
    <div className='flex flex-wrap gap-4'>
      <ButtonTw
          variant={'text'}
          onClick={() => navigate('../')}
        >
          <KeyboardBackspaceIcon />
      </ButtonTw>
      <Breadcrumbs
        separator={'-'}
        aria-label='breadcrumb'
        className='py-2 flex flex-wrap gap-1 !text-neutral-900 dark:!text-neutral-50 !text-p4 max-lg:flex-col'
      >
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};
