import * as React from 'react';
import { DebugObject, GlassPaper, Grid, Typography, Localized } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';

import { useAppContext } from 'src/app/hooks';
import { useShoppingCartContext } from 'src/app/context';
import { ShoppingCartListItem } from './ShoppingCartListItem';

export const ShoppingCartList = () => {
  const { cartItems, onCartItemRemove, onCartItemUpdate, sourceProducts } =
    useShoppingCartContext();

  const { isAppDebug } = useAppContext();
  const [openItemId, setOpenItemId] = React.useState<string | null>(null);

  const i18nPath = 'shop:/shopping-cart';

  return (
    <>
      {isAppDebug ? (
        <div className='py-4'>
          <DebugObject object={cartItems} isDebug={isAppDebug} collapsed={0} />
        </div>
      ) : null}
      {cartItems?.length ? (
        cartItems.map((cartItem) => {
          const srcProduct: SourcedGQLProduct | null =
            sourceProducts.find((srcProd) => srcProd.id === cartItem.productId) || null;

          // const itemConfigurations = productCartItem.itemConfigurations || null;
          // const priceGroupId = itemConfigurations[0].priceGroupId;
          // const priceGroupCount = itemConfigurations[0].count;
          return srcProduct ? (
            <ShoppingCartListItem
              key={cartItem.id}
              cartItem={cartItem}
              isExtended={openItemId === cartItem.id}
              onExtendDetails={() => {
                if (!openItemId || openItemId !== cartItem.id) {
                  setOpenItemId(cartItem.id);
                } else {
                  setOpenItemId(null);
                }
              }}
              onCartItemRemove={() => onCartItemRemove(cartItem.id)}
              onCartItemUpdate={(data) => onCartItemUpdate(cartItem.id, data)}
              srcProduct={srcProduct}
            />
          ) : null;
        })
      ) : (
        <div>
          <p className='text-p1 font-semibold'>
            <Localized dictKey={`${i18nPath}.no-items`} />
          </p>
        </div>
      )}
      {/** // FIXME TODO */}
      {/* <Grid item xs={12}>
          <Divider variant='middle' style={{ margin: '16px 0 32px 0' }} />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextInputField
                fullWidth
                variant={'outlined'}
                label={'Enter gift code or promo code'}
              />
            </Grid>
            <Grid item xs={3}>
              <Button size={'large'} fullWidth>
                <Typography  align={'right'}>
                  {'Redeem'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
    </>
  );
};
