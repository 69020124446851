import * as React from 'react';
import { Grid, ProductConfiguration } from 'src/app/components';
import { ProductImportantInformation } from 'src/app.consumer/components';
import {
  SourcedGQLProduct,
  ShoppingCartItemDocumentData,
  ShoppingCartItemConfiguration,
} from 'src/app/types';

import { ParticipantEditMode } from 'src/app/constants';

import { AssemblyInformation } from './AssemblyInformation';
import { ProductPickupLocationInformation } from './ProductPickupLocationInformation';
import { AdditionalProductCheckoutInformation } from './AdditionalProductCheckoutInformation';

export interface AdditionalProductInformationProps {
  cartItem: ShoppingCartItemDocumentData;
  onCartItemUpdate: (data: ShoppingCartItemConfiguration[]) => void;
  product: SourcedGQLProduct;
}

// NOTE: AdditionalProductInformation + AdditionalProductCheckoutInformation can be replaced by ProductConfiguration entirely => look tru before deletion
export const AdditionalProductInformation = (
  props: AdditionalProductInformationProps,
) => {
  const { cartItem, onCartItemUpdate, product } = props;
  const priceSystemId = cartItem.priceSystemId || '';
  return (
    <>
      <div>
        <ProductConfiguration
          product={product}
          priceGroupTickets={cartItem.itemConfigurations}
          priceSystemId={priceSystemId}
          cartType={cartItem.bookingSystemType}
          setPriceGroupTickets={(data) => onCartItemUpdate(data)}
          showParticipantsInfo
          ticketOrderLimit={
            product.priceSystems.find((ps) => ps.id === priceSystemId)?.publicBooking
              ?.maxParticipants || 0 // FIXME:
          }
          hiddenChapters={{}}
          openChapters={{ productInformation: true, priceSummary: true }}
          participantEditMode={ParticipantEditMode.DIALOG}
          // TODO:
          // optionalEventReuseParams={undefined} // FIXME: needed here?
        />
      </div>
      {/** //NOTE: OLD STUFF => LOOK TRU & REMOVE  */}
      {true ? null : ( // TODO: keep or remove
        <div>
          <div className='mb-4 mt-1 px-1'>
            <div>
              <ProductImportantInformation
                bookingType={cartItem.bookingSystemType}
                // title={'Important information'}
                product={product}
              />
            </div>
            <div>
              <ProductPickupLocationInformation
                title={'Available food options'}
                product={product}
              />
            </div>
            {product.pickup?.hasPickupService || true ? (
              <div>
                <ProductPickupLocationInformation
                  title={'Pickup options'}
                  product={product}
                />
              </div>
            ) : null}
            <div>
              <AssemblyInformation title={'Assembly location'} product={product} />
            </div>
          </div>
        </div>
      )}
      {true ? null : ( // TODO: keep or remove
        <div>
          <AdditionalProductCheckoutInformation product={product} />
        </div>
      )}
    </>
  );
};
