import * as React from 'react';
import readXlsxFile from 'read-excel-file';
import { ReadExcelFileSchema } from 'src/app/types';

export const useReadXlsxFile = <T extends Record<string, unknown>>(
  file: File,
  schema: ReadExcelFileSchema,
  isDebug?: boolean,
) => {
  const [rows, setRows] = React.useState<T[]>([]);
  const [errors, setErrors] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (file && schema) {
      if (isDebug) {
        readXlsxFile(file).then((fileRows, importErrors) => {
          // `errors` list items have shape: `{ row, column, error, value }`.

          // no errors if schema is present
          if (importErrors?.length === 0) {
            return;
          }
          console.log('useReadXlsxFile - fileRows raw: ', fileRows);
          // `rows` is an array of rows
          // each row being an array of cells.
        });
      }
      // NOTE: this only works in this constellation, importErrors are available if no schema is passed...
      readXlsxFile(file, { schema }).then((fileRows /* , importErrors */) => {
        // `errors` list items have shape: `{ row, column, error, value }`.

        // no errors if schema is present
        // if (importErrors?.length === 0) {
        //   return;
        // }
        console.log('fileRows: ', fileRows);

        if (fileRows.errors.length) {
          setErrors(fileRows.errors);
        }
        setRows(fileRows.rows as T[]);
        // `rows` is an array of rows
        // each row being an array of cells.
      });
    } else {
      console.warn('no file or schema provided to read-excel-file');
    }
  }, [file, schema, isDebug]);

  return { rows, errors };
};
