import * as React from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { AppEnvironment } from 'src/app/constants';
import { isEnvironment } from 'src/app/lib';

export interface LocalizedProps {
  defaultValue?: string;
  dictKey: string;
  /** interpolation options */
  translationOptions?: Record<string, unknown>;
}

/**
 * Localized
 * @param { LocalizedProps } props
 * @version 0.0.0
 * @description Takes a dictKey and translates it via I18n t func, with optional default value and optional translationOptions keyMap
 * @todo
 * @example
 */
export const Localized = (props: LocalizedProps) => {
  const { defaultValue, dictKey, translationOptions, ...passProps } = props;
  const { t, i18n } = useTranslation();
  const { href } = useLocation();
  const isProduction = isEnvironment(
    href,
    AppEnvironment.STAGE || AppEnvironment.PRODUCTION,
  );

  // NOTE: initialized with empty obj, since it is needed in case a default value is used => bad API
  const translationOptionsSafe = translationOptions || {};

  const res = defaultValue
    ? t(dictKey, defaultValue, translationOptionsSafe)
    : t(dictKey, translationOptionsSafe);

  if (isProduction) {
    if (!dictKey) {
      return null;
    }
    return <>{res ? <span {...passProps}>{res}</span> : null}</>;
  }

  /* //TODO: i18n should complain in non production in case of missing keys */
  return i18n.language !== 'cimode' ? (
    <>{res ? <span {...passProps}>{res}</span> : null}</>
  ) : (
    <span
      {...passProps}
      style={{ color: '#f0f', wordBreak: 'break-word', fontSize: '0.75rem' }}
    >
      {i18n.language === 'cimode' ? dictKey : ''}
    </span>
  );
};
