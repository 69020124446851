import * as React from 'react';

import { Localized } from 'src/app/components';

export interface FormChapterHeroProps {
  formChapterName: string;
  i18nBasePath: string;
}

export const FormChapterHero = (props: FormChapterHeroProps) => {
  const { formChapterName, i18nBasePath } = props;
  const dictPath = `${i18nBasePath}.${formChapterName}`;
  return (
    <div className='py-6 md:py-14 md:text-center dark:text-neutral-50'>
      <h2 className='pb-6 md:pb-3 text-h2 md:text-h1 font-bold'>
        <Localized dictKey={`${dictPath}.title`} />
      </h2>
      <p className='text-p1 md:text-h4 font-bold'>
        <Localized dictKey={`${dictPath}.label`} />
      </p>
      <p className='text-p2 pt-2 md:pt-9'>
        <Localized dictKey={`${dictPath}.description`} />
      </p>
    </div>
  );
};
