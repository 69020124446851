import * as React from 'react';

import { BaseComponentProps } from 'src/app/types';

import { AdminScreenTitleLocalized } from 'src/admin/components';

export interface AdminMenuScreenInformationProps extends BaseComponentProps {
  children: React.ReactNode;
  screenId: string; // OrganisationAdminRootScreen | SystemAdminScreen;
  icon?: React.ReactNode;
  i18nNS: string;
}

const AdminMenuScreenInformation = ({
  children,
  icon,
  i18nNS,
  screenId,
}: AdminMenuScreenInformationProps) => {
  return (
    <div className='md:hidden'>
      {icon ? <span style={{ paddingRight: '16px' }}>{icon || null}</span> : null}
      {/* {currentOrganisation.displayName ?? ''} */}
      <AdminScreenTitleLocalized nameSpace={i18nNS} screenName={screenId} />
      {children}
    </div>
  );
};

export { AdminMenuScreenInformation };