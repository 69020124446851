import * as React from 'react';
import { FieldValues } from 'react-hook-form';

import {
  FormControl,
  FormErrors,
  FormRootContainerProps,
  FormLayout,
  FieldStyle,
  TextField,
} from 'src/app/components/RHF';

interface BaseFormProps<T extends FieldValues> {
  formProps: FormRootContainerProps<T>;
  i18nBasePath: string;
}

export interface BookingCancellationFormProps<T extends FieldValues>
  extends BaseFormProps<T> {
  isSubmitDataValid: boolean;
}

export const BookingCancellationForm = <T extends FieldValues>({
  i18nBasePath,
  isSubmitDataValid,
}: BookingCancellationFormProps<T>) => {
  return (
    <FormLayout
      formControl={<FormControl isValid={isSubmitDataValid} />}
      // renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
    >
      <TextField
        name={'bookingReferenceId'}
        i18nKey={'bookingReferenceId'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        name={'pin'}
        i18nKey={'pin'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
    </FormLayout>
  );
};
