import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  Button,
  LoadingIndicator,
  SubmitSuccessMessage,
  TextInputField,
  Localized,
} from 'src/app/components';

import { useAppContext } from 'src/app/hooks';

import { NotificationType } from 'src/app/constants';

import { FormRootContainer } from 'src/app/components/RHF';

import { BookingReferenceFormSubmitData } from 'src/app/types';
import { BookingReferenceForm } from './BookingReferenceForm';

import { defaultValues, createDocumentResolver } from './bookingReference.form.config';

const documentI18nBasePath = 'common:forms.bookingReference.form';
type DocumentType = BookingReferenceFormSubmitData;

// TODO: i18n
export const BookingReferenceFormContainer = () => {
  const { /* currentSystem, */ createNotification, firebase, isAppDebug, user } =
    useAppContext(); // TODO: select systemId based on system user data
  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);
  const [hasSubmitSuccess, setHasSubmitSuccess] = React.useState<boolean>(false);

  const currentSystemId = 'holiday';

  const onSubmit = async (data: DocumentType) => {
    if (!firebase) {
      return;
    }

    setRequestingUpdate(true);

    interface ReferenceMessageData {
      referenceId: string;
      systemId: string;
      name: string;
      email: string;
      phoneNumber?: string;
      attachment?: string; // FIXME: BASE64 string (PDF/IMAGE)
      body: string;
    }

    try {
      const createReferenceMessage = httpsCallable(
        getFunctions(firebase),
        'commonFunctions-createReferenceMessage',
      );

      // TODO: attachments
      // const attachmentText = attachment ? await attachment?.text() : '';
      // console.log('attachmentText: ', attachmentText);
      const params: ReferenceMessageData = {
        referenceId: data.bookingReferenceId,
        systemId: currentSystemId, // TODO: currentSystem => get via ENV
        name: data.name,
        email: data.email,
        phoneNumber: data.phone,
        attachment: '', // FIXME: BASE64 string (PDF/IMAGE)
        body: data.body,
      };

      await createReferenceMessage(params); // make function non async?

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Message successfully sent', 5000);
      setHasSubmitSuccess(true);
    } catch (err) {
      console.warn('Message send error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setRequestingUpdate(false);
    }
  };

  const isSubmitDataValid = true;

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    const data: DocumentType = {
      ...dataRaw,
    };

    await onSubmit(data);
  };

  // FIXME:
  // const onChooseFileClick = () => {
  //   console.log('not implemented');
  // };

  return hasSubmitSuccess ? (
    <SubmitSuccessMessage text={<Localized dictKey={'shop:/help/customer.topic.arrange-pickup.submitSuccessMessage'} />} />
  ) : (
    <>
      <div className='my-2'>
        <p className='text-accent-500 font-bold'>
          <Localized dictKey={'shop:/help/customer.topic.arrange-pickup.infoText'} />
        </p>
      </div>
      <FormRootContainer<DocumentType>
        defaultValues={defaultValues}
        resolver={createDocumentResolver}
        onSubmit={onSubmitWrapper}
        useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
        isDebug={isAppDebug}
        renderForm={({ formProps }) => {
          return isRequestingUpdate ? (
            <LoadingIndicator />
          ) : (
            <BookingReferenceForm
              formProps={formProps}
              i18nBasePath={documentI18nBasePath}
              isSubmitDataValid={isSubmitDataValid}
            />
          );
        }}
      />
    </>
  );
};
