import * as React from 'react';
import { Router } from '@reach/router';

import { MessageType } from 'src/app/constants';
import { Route } from 'src/app/components';

import { MessagesList } from './MessagesList';
import { Message } from './Message';

export interface MessageTypeOverviewProps {
  messageCollectionPath: string;
  messageType: MessageType;
  onDeleteMessage: (messageId: string) => void;
  onRespondToMessage: (messageId: string, messageText: string) => void;
  routerBasePath: string;
  uid: string;
}

export const MessageTypeOverview = ({
  messageCollectionPath,
  messageType,
  onDeleteMessage,
  onRespondToMessage,
  routerBasePath,
  uid,
}: MessageTypeOverviewProps) => {
  return (
    <Router basepath={routerBasePath}>
      <Route
        component={Message}
        path={'/:id'}
        passProps={{
          messageCollectionPath,
          messageType,
          uid,
          onDeleteMessage,
          onRespondToMessage,
        }}
      />
      <Route
        component={MessagesList}
        path={'/'}
        passProps={{
          messageCollectionPath,
          messageType,
          uid,
          onDeleteMessage,
        }}
      />
    </Router>
  );
};
