import * as React from 'react';
import {
  ButtonTw,
  Localized,
  LocalizedLink,
  // SocialButtons,
} from 'src/app/components';
import { DialogType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';
import { SignupFormContainer } from './SignupFormContainer';

const FormAgreementExtension = () => {
  return (
    <p className='text-center py-8'>
      <Localized
        dictKey={`account:screen.screen-account-${'signup'}.create account agreement`}
      />
       {' '}
      <LocalizedLink href={'/terms-of-business'} className='!text-p2 underline !text-accent-500'>
        <Localized dictKey={'staticLocales:pages./terms-of-business.link label'} />
      </LocalizedLink>
      {' '}
      <LocalizedLink href={'/privacy-policy'} className='!text-p2 underline !text-accent-500'>
        <Localized dictKey={'staticLocales:pages./privacy-policy.link label'} />
      </LocalizedLink>

    </p>
  );
};

export interface SignupDialogProps extends BaseComponentProps {
  children?: React.ReactNode;
}

// TODO: consider asking for DOB & phoneNumber here...

/**
 * SignupDialog
 * @param { SignupDialogProps } props
 * @version 0.0.0
 */
const SignupDialog = (props: SignupDialogProps) => {
  const { children } = props;
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const { openDialog } = useAppContext();

  const onDisplayLoginDialog = () => {
    openDialog(DialogType.LOGIN);
  };

  return (
    <div className='md:px-6 font-body'>
      <p className='text-p2 my-4 md:text-center'>
        <Localized
          dictKey={`account:screen.screen-account-${'signup'}.screen subtitle`}
        />
      </p>
      <p className='text-p2 my-4 md:text-center'>
        <Localized
          dictKey={`account:screen.screen-account-${'signup'}.screen description`}
        />
      </p>      {/* <SocialButtons compType={'signup'} /> */}

      <div>
        {!showForm ? (
          <div className='text-center py-4'>
            <ButtonTw variant='accent' size='lg' className='w-full md:w-auto' rounded onClick={() => setShowForm(true)}>
              <Localized
                dictKey={`account:screen.screen-account-${'signup'}.btn-signup-email.label`}
              />
            </ButtonTw>
          </div>
        ) : (
          <SignupFormContainer signupType={'shop'}>
            <FormAgreementExtension />
          </SignupFormContainer>
        )}
      </div>
      <div className='flex flex-wrap items-center justify-center gap-2 py-6'>
        <Localized
          dictKey={`account:screen.screen-account-${'login'}.nav description`}
        />
        <ButtonTw
          variant='text'
          className='underline !text-accent-500'
          onClick={() => onDisplayLoginDialog()}
        >
          <Localized dictKey={`account:screen.screen-account-${'login'}.nav label`} />
        </ButtonTw>
      </div>
      {children}
    </div>
  );
};

const WAPSignupDialog = (props: CombinedAppProps<SignupDialogProps>) =>
  withoutAppProps<CombinedAppProps<SignupDialogProps>, SignupDialogProps>(
    SignupDialog,
    props,
  );

export { SignupDialog, WAPSignupDialog };
