import * as React from 'react';

import { Router } from '@reach/router';

import { Route } from 'src/app/components';
import { MessageCollection, MessageType } from 'src/app/constants';
import { MessageTypeOverview } from './MessageTypeOverview';
import { MessagesRootScreen } from './MessagesRootScreen';
import { MessageCreate } from './Message';

interface MessagesRouterProps {
  messageCollectionsWriteOptions: MessageCollection[];
  messageTypeOptions: MessageType[];
  messageCollectionPath: string;
  onDeleteMessage: (messageId: string) => void;
  onRespondToMessage: (messageId: string, messageText: string) => void;
  routerBasePath: string;
  uid: string;
}

/**
 * MessagesRouter - Messages main router container
 * @version 1.0.0
 * @description
 * @todo
 * @example
 */
const MessagesRouter = ({
  messageCollectionPath,
  messageCollectionsWriteOptions,
  messageTypeOptions,
  onDeleteMessage,
  onRespondToMessage,
  routerBasePath,
  uid,
}: MessagesRouterProps) => {
  return (
    <Router basepath={routerBasePath}>
      <Route
        component={MessagesRootScreen}
        path={'/'}
        passProps={{
          messageCollectionsWriteOptions,
          messageTypeOptions,
          routeBasePath: routerBasePath,
        }}
      />
      {messageTypeOptions?.map((messageType) => {
        return (
          <Route
            key={messageType}
            component={MessageTypeOverview}
            path={`/${messageType}/*`}
            passProps={{
              messageType,
              messageCollectionPath,
              onDeleteMessage,
              onRespondToMessage:
                messageType === MessageType.INBOUND ? onRespondToMessage : () => null,
              routerBasePath: `${routerBasePath}/${messageType}`,
              uid,
            }}
          />
        );
      }) || null}
      {messageCollectionsWriteOptions?.map((collection) => {
        return (
          <Route
            key={collection}
            component={MessageCreate}
            path={`/${collection}/*`}
            passProps={{
              // messageType: collection,
              // messageCollectionPath,
              // onDeleteMessage,
              // onRespondToMessage:
              //   collection === MessageType.INBOUND ? onRespondToMessage : () => null,
              // routerBasePath: `${routerBasePath}/${collection}`,
              // uid,
              targetId: collection === MessageCollection.SYSTEM ? 'holiday' : null, // fixed target id for organisations
              messageCollectionPath: collection,
              routerBasePath: `${routerBasePath}/${collection}`,
            }}
          />
        );
      }) || null}
    </Router>
  );
};

export { MessagesRouter };
