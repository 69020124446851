import * as React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { ButtonTw } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { SourcedGQLProduct } from 'src/app/types';
import { useAppContext, useSourcedTags, useLocalizationContext } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';
// import { ProductFilter } from '../productFilter.types';

// const isFoo: ProductFilter = (product: SourcedGQLProduct) =>
//   product.meta.tagIds.includes('day-trip-000');
// const isFoo1: ProductFilter = (product: SourcedGQLProduct) =>
//   product.meta.tagIds.includes('family-friendly-000');
// const isFoo2: ProductFilter = (product: SourcedGQLProduct) =>
//   product.availability.weekdays.includes('wed');

export interface TagFilterProps {
  availableTagIds: string[];
  currentTagFilterIds: string[];
  setTagFilter: (id: string, isOn: boolean) => void;
}

export const TagFilter = ({
  availableTagIds: availableTagIdsRaw,
  currentTagFilterIds,
  setTagFilter,
}: TagFilterProps) => {
  // const availableTagIds = ['1', '2', '3', '4'];
  const styles = useStyles();
  const tags = useSourcedTags();
  // TODO: do this not on every render...
  const availableTagIds = availableTagIdsRaw.filter((tagId) =>
    tags.find((t) => t.id === tagId),
  );
  const { language } = useLocalizationContext();
  const [displayOffset, setDisplayOffset] = React.useState<number>(0);
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const isWiderSM = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);
  let tagNum = isWiderMD ? 4 : 2;
  if (!isWiderSM) tagNum = 1;

  const [displayIds, setDisplayIds] = React.useState<string[]>(
    availableTagIds?.length < tagNum + 1
      ? [...availableTagIds]
      : [...availableTagIds.slice(0, tagNum)],
  );

  React.useEffect(() => {
    setDisplayIds(
      availableTagIds?.length < tagNum + 1
        ? [...availableTagIds]
        : [...availableTagIds.slice(0, tagNum)],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagNum]);

  const isTagOn = (id: string) => !!currentTagFilterIds.find((tagId) => tagId === id);
  const onChangeFilter = (id: string) => {
    setTagFilter(id, !isTagOn(id));
  };

  const onIncreaseIndex = () => {
    if (displayOffset + 1 < availableTagIds.length) {
      setDisplayOffset(displayOffset + 1);
      setDisplayIds([
        ...availableTagIds.slice(displayOffset + 1, displayOffset + 1 + tagNum),
      ]);
    }
  };

  const onDecreaseIndex = () => {
    if (displayOffset - 1 >= 0) {
      setDisplayOffset(displayOffset - 1);
      setDisplayIds([
        ...availableTagIds.slice(displayOffset - 1, displayOffset - 1 + tagNum),
      ]);
    }
  };

  // const currentDisplayTags = tags?.filter((t) => displayIds.includes(t.id)) || [];

  const hasNavigation = displayIds.length <= 4 && availableTagIds.length > 4;

  // console.log('displayOffset: ', displayOffset);
  // console.log('availableTagIds: ', availableTagIds);
  // console.log('displayIds: ', displayIds);
  return (
    <div className='flex items-center justify-center py-4 gap-x-4'>
      {/* <Grid item xs={1}>
        <Typography align={'center'} variant={'h6'} renderAs={'p'}>
          By tag
        </Typography>
      </Grid> */}
      {hasNavigation ? (
        <ButtonTw
          disabled={displayOffset === 0}
          onClick={onDecreaseIndex}
          variant='text'
          size={'sm'}
        >
          <NavigatePrevIcon />
        </ButtonTw>
      ) : null}
      <div className='flex gap-x-2 justify-center'>
        {displayIds
          // ?.filter((tagId, i) => i >= minIdx && i < maxIdx)
          .filter((tagId) => tags.find((t) => t.id === tagId))
          .map((tagId) => {
            const tag = tags.find((t) => t.id === tagId);
            return (
              <ButtonTw
                key={tagId}
                size={'sm'}
                rounded
                variant={isTagOn(tagId) ? 'accent' : 'outline'}
                onClick={() => onChangeFilter(tagId)}
              >
                <span>
                  {/* {tagId} */}
                  {tag ? getBaseDocumentLocales(tag, language).name : tagId}
                </span>
              </ButtonTw>
            );
          })}
      </div>
      {hasNavigation ? (
        <ButtonTw
          disabled={displayOffset + tagNum >= availableTagIds.length}
          onClick={onIncreaseIndex}
          variant='text'
          size={'sm'}
        >
          <NavigateNextIcon />
        </ButtonTw>
      ) : null}
    </div>
  );
};

// const tagFilter: ProductFilter = (product: SourcedGQLProduct, tagId: string) => (
//   product: SourcedGQLProduct,
// ) => product.meta.tagIds.includes(tagId);

// function tagFilter(product: SourcedGQLProduct) {
//   return (tagId: string) => {
//     return product.meta.tagIds.includes(tagId);
//   };
// }
