import * as React from 'react';

import {
  Localized,
  LocalizedProps,
  Typography,
  TypographyProps,
} from 'src/app/components';

export interface LocalizedTypographyProps extends LocalizedProps {
  typographyProps?: TypographyProps;
}

export const LocalizedTypography = ({
  typographyProps,
  ...localizedProps // NOTE: restprops go all inside of Localized component
}: LocalizedTypographyProps) => (
  <Typography {...typographyProps}>
    <Localized {...localizedProps} />
  </Typography>
);
