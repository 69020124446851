import * as React from 'react';
import {
  ButtonTw,
  LoadingIndicator,
  Localized
} from 'src/app/components';

const i18nPath = 'shop:/shopping-cart';

export const ShoppingCartLocalStorageImportSuggestion = ({
  isImporting,
  onImportLocalStorageCart,
}: {
  isImporting?: boolean;
  onImportLocalStorageCart: () => void;
}) => {
  const [isHidden, setIsHidden] = React.useState<boolean>(false);
  if (isImporting) {
    return (
      <>
        <LoadingIndicator />
      </>
    );
  }
  return isHidden ? null : (
    <>
      <p className='py-2'>
        <Localized dictKey={`${i18nPath}.action.transfer-local-cart-item.suggestion`} />
      </p>
      <div className='flex gap-2 py-2'>
        <ButtonTw variant={'outline'} rounded size='sm' onClick={onImportLocalStorageCart}>
          <Localized dictKey={`${i18nPath}.action.transfer-local-cart-item.label`} />
        </ButtonTw>
        <ButtonTw variant={'outline'} rounded size='sm' onClick={() => setIsHidden(true)}>
          <Localized dictKey={`${i18nPath}.action.transfer-local-cart-item.dismiss`} />
        </ButtonTw>
      </div>
    </>
  );
};
