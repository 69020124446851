import * as React from 'react';

import { Grid, Localized, SearchFilter } from 'src/app/components';

import { useSourcedCategories } from 'src/app/hooks';
// import { ThemeMode } from 'src/app.consumer/constants';

import { StyledComponent } from 'src/app/types';

export interface CategoryFilterProps extends StyledComponent {
  currentCategoryId: string;
  setCategoryId: (cityId: string) => void;
}

/**
 * CategoryFilter
 * @param { CategoryFilterProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const CategoryFilter: React.FC<CategoryFilterProps> = (
  props: CategoryFilterProps,
) => {
  const { currentCategoryId, setCategoryId, themeMode } = props;
  const categories = useSourcedCategories();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SearchFilter
          filterTypeId={'category'}
          filterOptions={[...categories]}
          selectedFilterId={currentCategoryId || ''}
          setFilter={setCategoryId}
          // themeMode={themeMode || ThemeMode.LIGHT}
          placeholderLabel={
            <Localized dictKey={'shop:/.search-box.filter.category.placeholder'} />
          }
        />
      </Grid>
    </Grid>
  );
};
