import clsx from 'clsx';
import * as React from 'react';

import {
  Divider,
  Grid,
  GlassPaper,
  ImageFilePicker,
  Typography,
} from 'src/app/components';
// import { nanoid } from 'nanoid';

import { useStoredImage } from 'src/app/hooks';
import { useStyles } from 'src/app/context';
import { ImageDocument, ImageLocalization } from 'src/app/types';

interface ImageDocumentProps {
  documentPath: string;
  fileName: string;
  id: string;
  imageDocument: ImageDocument;
  storagePath: string;
  mode?: 'single' | 'array';
  canDeleteFile?: boolean;
}

// interface ImageData {
//   localizations: ImageLocalization[];
// }

// TODO: create a ImageDocumentsContainer version with array funtionality ? or use here
export const ImageDocumentContainer = (props: ImageDocumentProps) => {
  const {
    documentPath,
    fileName,
    id,
    imageDocument,
    storagePath,
    mode = 'array',
    canDeleteFile,
  } = props;
  const styles = useStyles();
  const [imageData, setImageData] = React.useState<ImageLocalization[]>([]);

  const isArrayMode = !!mode === 'array';

  React.useEffect(() => {
    if (isArrayMode && imageDocument?.images?.length) {
      let data: ImageLocalization[] = [];
      imageDocument.images.forEach((img) => {
        if (img.localizations?.length) {
          data = [...data, ...img.localizations.map((loc) => loc)];
        }
      });
      setImageData(data);
    }
  }, [imageDocument, isArrayMode]);

  const { setStoredImage, deleteStoredImage } = useStoredImage(
    documentPath,
    storagePath,
    id,
  );

  return (
    <GlassPaper isTransparent elevation={1}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <ImageFilePicker
            canDeleteFile={canDeleteFile}
            noUpload={false}
            id={id} // nanoid if used with more than one img - for render id issues => TODO: create once in useEffect and save in state
            onUploadImage={async (file) => {
              await setStoredImage(
                file,
                [],
                isArrayMode ? '__multiple' : '__single',
                file.name || fileName, // take the new input file name as displayName if there is - else use old doc based name
              );
              // TODO: after upload state needs to be reset...
            }}
            onRemove={async () => {
              await deleteStoredImage(fileName);
            }}
            showPreview
            fileName={isArrayMode ? id : decodeURIComponent(imageDocument.displayName)}
            initialFileUrl={
              isArrayMode
                ? imageDocument.images?.[0]?.imageUrl
                : imageDocument.imageUrl || ''
            }
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container spacing={2}>
            {imageData?.length // TODO localizations for images
              ? imageData.map((data) => (
                  <Grid key={data.localeId} item xs={12}>
                    {`Localization ${data.localeId}`}
                  </Grid>
                ))
              : null}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </GlassPaper>
  );
};
