import system from './system.json';

import systemConfiguration from './system-configuration.json';
import systemConfigurationSourceCategory from './system-configuration-source-category.json';
import systemConfigurationSourceCategoryType from './system-configuration-source-category-type.json';
import systemConfigurationSourceCity from './system-configuration-source-city.json';
import systemConfigurationSourceCityArea from './system-configuration-source-city-area.json';
import systemConfigurationSourceCountry from './system-configuration-source-country.json';
import systemConfigurationSourceCountryArea from './system-configuration-source-countryArea.json';
import systemConfigurationSourceContinent from './system-configuration-source-continent.json';
import systemConfigurationSourceCurrency from './system-configuration-source-currency.json';
import systemConfigurationSourceLocale from './system-configuration-source-locale.json';
import systemConfigurationSourceLocation from './system-configuration-source-location.json';
import systemConfigurationSourceTag from './system-configuration-source-tag.json';

import systemDashboard from './system-dashboard.json';
import systemData from './system-data.json';
import systemMedia from './system-media.json';
import systemMessages from './system-messages.json';

import systemOrder from './system-order.json';
import systemOrderEvent from './system-order-event.json';
import systemOrderOrder from './system-order-order.json';

import systemOrganisation from './system-organisation.json';
import systemLocalization from './system-localization.json';
import systemProduct from './system-product.json';
import systemReport from './system-report.json';
import systemUser from './system-user.json';

export default {
  'sys-system': system,
  'sys-configuration': systemConfiguration,
  'sys-configuration-source-category': systemConfigurationSourceCategory,
  'sys-configuration-source-category-type': systemConfigurationSourceCategoryType,
  'sys-configuration-source-city': systemConfigurationSourceCity,
  'sys-configuration-source-city-area': systemConfigurationSourceCityArea,
  'sys-configuration-source-country': systemConfigurationSourceCountry,
  'sys-configuration-source-country-area': systemConfigurationSourceCountryArea,
  'sys-configuration-source-continent': systemConfigurationSourceContinent,
  'sys-configuration-source-currency': systemConfigurationSourceCurrency,
  'sys-configuration-source-locale': systemConfigurationSourceLocale,
  'sys-configuration-source-location': systemConfigurationSourceLocation,
  'sys-configuration-source-tag': systemConfigurationSourceTag,
  'sys-dashboard': systemDashboard,
  'sys-data': systemData,
  'sys-media': systemMedia,
  'sys-messages': systemMessages,
  'sys-order': systemOrder,
  'sys-order-event': systemOrderEvent,
  'sys-order-order': systemOrderOrder,
  'sys-organisation': systemOrganisation,
  'sys-localization': systemLocalization,
  'sys-product': systemProduct,
  'sys-report': systemReport,
  'sys-user': systemUser,
};
