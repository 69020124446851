import * as React from 'react';
import MToggleButton, { ToggleButtonProps } from '@material-ui/lab/ToggleButton';

/**
 * ToggleButton
 * @param { ToggleButtonProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/toggle-button
 * @todo
 * @example
 */
export const ToggleButton: React.FC<ToggleButtonProps> = ({
  ...passProps
}: ToggleButtonProps) => {
  return <MToggleButton {...passProps} />;
};
