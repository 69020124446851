export enum ProductCategoryType {
  ACTIVITY = 'activity-000',
  BOAT = 'boat-000', // IS needed separately or should we use "rental" for this?
  RENTAL = 'rental-000',
  RESERVATION = 'reservation-000',
  TRANSFER = 'transfer-000',
}

/** @deprecated => use DocumentState const */
export enum ProductStatus {
  UNPUBLISHED = 'unpublished', // initial state set by system (TODO: enforce by security rules!)
  PUBLISH_REQUEST = 'publish_request', // submitted to system by org user
  PUBLISH_REJECTED = 'publish_rejected', // submitted to system but rejected due to problems (TODO: => notify!)
  PUBLISHED = 'published', // publish by system user (everything is ok)
  REVOKED = 'revoked', // item is set inactive by org user (TODO: handle already sold items!)
  DELETED = 'deleted', // item is set to delete (apply flag and hide or move/copy to archive)
}

export enum ProductVariantOptions {
  singleVariant = 'singleVariant',
  multiVariant = 'multiVariant',
  // familyVariant/nestedVariant =>
  // eval nested products / grouped products (related products) ?
}

export enum ProductLocale {
  hr = 'hr',
  de = 'de',
  en = 'en',
  fr = 'fr',
}
export enum DiscountType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum DefaultGroupDiscountType {
  ADULT = '__ADULT',
  CHILDREN_UNDER_3 = '__CHILDREN_UNDER_3',
  CHILDREN_UNDER_3_5 = '__CHILDREN_UNDER_3_5',
  CHILDREN_UNDER_6_16 = '__CHILDREN_UNDER_6_16',
  DISABLED = '__DISABLED',
  STUDENT = '__STUDENT',
}

export enum DocumentLocalizationNodeType {
  DEFAULT = 'default',
}

export enum AvailabilityType {
  UNIQUE = 'unique',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum ActivityProductDocumentFormChapter {
  AVAILABILITY = 'availability',
  BASE_DATA = 'baseData',
  LOCALIZATION = 'localization',
  LOCATION = 'location',
  MEDIA = 'media',
  OVERVIEW = 'overview',
  PICKUP = 'pickup',
  PRICING = 'pricing',
  FOOD_AND_BEVERAGE = 'food_and_beverage',
}

export enum BoatProductDocumentFormChapter {
  AVAILABILITY = 'availability',
  BASE_DATA = 'baseData',
  BOAT_DATA = 'boatData',
  LOCATION = 'location',
  PRICING = 'pricing',
}

export enum PickupOptionType {
  DEFAULT = 'default__meetingLocation',
  SHUTTLE = 'pickup__meetingLocation',
  CUSTOM_PICKUP = 'custom__pickup__meetingLocation',
}

export enum ActivityProductViewMode {
  FEATURES = '__features',
  MEDIA = '__media',
  MAP = '__map',
  IMPORTANT = '__important',
  GENERAL = '__general',
  COVID = '__covid',
}

export enum BoatProductViewMode {
  FEATURES = '__features',
  MEDIA = '__media',
  MAP = '__map',
  IMPORTANT = '__important',
  GENERAL = '__general',
  COVID = '__covid',
}
