import { SystemAdminDialogType } from 'src/app.system/constants';

export const getSystemDialogTitle = (type: SystemAdminDialogType) => {
  switch (type) {
    case SystemAdminDialogType.UPDATE_ORGANISATION_PRODUCT_CATEGORY_TYPES:
      return 'Update product categories';
    default:
      return 'System Dialog';
  }
};
