import login from './login.json';
import product from './product.json';
import shop from './shop.json';
import newsletter from './newsletter.json';
import signup from './signup.json';

export default {
  shop,
  ...login,
  ...signup,
  'screen-product': product,
  'screen-newsletter': newsletter,
};
