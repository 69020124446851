import * as React from 'react';
import {
  doc,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  collectionGroup,
  collection,
  query as queryFB,
  limit as limitFB,
  where,
} from 'firebase/firestore';

import { CollectionQuery } from 'src/app/types';
import { AppNotificationsProps } from 'src/app/components';
import { delay } from 'src/app/lib';

import { NotificationType, ProductStatus } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import { AdminNotificationsProps } from 'src/admin/components';
// import { AdminNotificationsProps } from 'src\admin\components\AdminNotifications\AdminNotifications';

export function useFirebaseCollection<T extends Record<string, unknown>>(
  collectionName: string,
  useCollectionGroupQuery: boolean,
  fetchPreCondition: boolean,
  dataProperties: T, // TODO: implement validation?
  isPrivilegedCollection: boolean, // = protected by login (todo: permissionize)
  shouldTriggerUpdate: boolean,
  limit: number,
  query?: CollectionQuery | null, // what about multiple where clauses?
): { results: T[] | null; refetch: () => void } {
  const { firebase, isLoggedIn, user } = useAppContext();
  const [results, setResults] = React.useState<T[] | null>(null);

  if (!firebase) {
    return { results: null, refetch: () => {} };
  }

  // NOTE: original query logic code - too difficult and unnecessary to rebuild with new FB api...

  // const db = firebase.firestore();

  //
  // // decide which collection type query method to use
  // // console.log('collectionName: ', collectionName);
  // const collectionRef = useCollectionGroupQuery
  //   ? db.collectionGroup(collectionName)
  //   : db.collection(collectionName);
  // // TODO: refactor
  // let collectionQuery: Firebase.firestore.Query<any> | null = null;

  // if (
  //   query?.collectionItemProperty &&
  //   query?.whereFilterOperator &&
  //   query?.compareValue
  // ) {
  //   if (Array.isArray(query)) {
  //     const queryItems: any[] = query;
  //     for (let index = 0; index < queryItems.length; index++) {
  //       if (!collectionQuery) {
  //         collectionQuery = collectionRef.where(
  //           queryItems[index].collectionItemProperty,
  //           queryItems[index].whereFilterOperator,
  //           queryItems[index].compareValue,
  //         );
  //       } else {
  //         collectionQuery = collectionQuery.where(
  //           queryItems[index].collectionItemProperty,
  //           queryItems[index].whereFilterOperator,
  //           queryItems[index].compareValue,
  //         );
  //       }
  //     }
  //   } else {
  //     collectionQuery = collectionRef.where(
  //       query.collectionItemProperty,
  //       query.whereFilterOperator,
  //       query.compareValue,
  //     );
  //   }
  // }

  // console.log('collectionQuery: ', collectionQuery);

  // const request = async function getCollection() {
  //   try {
  //     console.log('start fetch request for collectionName: ', collectionName);
  //     // console.log('useCollectionGroupQuery: ', useCollectionGroupQuery);
  //     let documentSnapshot = null;
  //     if (collectionQuery) {
  //       if (limit) {
  //         documentSnapshot = await collectionQuery.limit(limit).get();
  //       } else {
  //         documentSnapshot = await collectionQuery.get();
  //       }
  //     } else if (limit) {
  //       documentSnapshot = await collectionRef.limit(limit).get();
  //     } else {
  //       documentSnapshot = await collectionRef.get();
  //     }
  //     //  collectionQuery
  //     //   ? await collectionQuery.get()
  //     //   : await collectionRef.get();
  //     // console.log('documentSnapshot: ', documentSnapshot);
  //     if (documentSnapshot?.empty) {
  //       // console.log('No matching documents inside snapshot.');
  //       setResults([]); // NOTE: set results from null to empty array
  //       return;
  //     }

  //     const res: T[] = [];

  //     documentSnapshot.forEach((doc) => {
  //       const data = (doc.data() as unknown) as T;
  //       res.push(data);
  //     });
  //     setResults(res);
  //   } catch (err) {
  //     // console.warn('useFirebaseCollection error: ', err);
  //     setResults(null);
  //   }
  // };

  // NOTE: new dummy replacement fetch function - fetches everything
  const request = async function getCollection() {
    try {
      console.log('start fetch request for collectionName: ', collectionName);
      // console.log('useCollectionGroupQuery: ', useCollectionGroupQuery);
      let documentSnapshot = null;
      const q = query
        ? queryFB(
            collection(getFirestore(firebase), collectionName),
            limitFB(limit || 10),
            where(
              query.collectionItemProperty,
              query.whereFilterOperator,
              query.compareValue,
            ),
          )
        : queryFB(
            collection(getFirestore(firebase), collectionName),
            limitFB(limit || 10),
          );
      documentSnapshot = await getDocs(q);

      if (documentSnapshot?.empty) {
        // console.log('No matching documents inside snapshot.');
        setResults([]); // NOTE: set results from null to empty array
        return;
      }

      const res: T[] = [];

      documentSnapshot.forEach((docSnap) => {
        const data = docSnap.data() as unknown as T;
        res.push(data);
      });
      setResults(res);
    } catch (err) {
      // console.warn('useFirebaseCollection error: ', err);
      setResults(null);
    }
  };
  React.useEffect(() => {
    if (
      fetchPreCondition &&
      (!isPrivilegedCollection || (isPrivilegedCollection && isLoggedIn)) &&
      shouldTriggerUpdate
    ) {
      request();
    }
  }, [
    collectionName,
    useCollectionGroupQuery,
    fetchPreCondition,
    isLoggedIn,
    query,
    limit,
    shouldTriggerUpdate,
    isPrivilegedCollection,
  ]);

  const refetchDelayed = async (delayMS?: number) => {
    await delay(delayMS || 1000);
    await request();
  };

  return { results, refetch: refetchDelayed };
}

export function useFirebaseCollectionEZ<T extends Record<string, unknown>>(
  collectionName: string,
  shouldTriggerUpdate: boolean,
  isPrivilegedCollection: boolean = true,
  limit?: number,
  query?: CollectionQuery | null,
) {
  // console.log('useFirebaseCollectionEZ - collectionName: ', collectionName);
  return useFirebaseCollection<T>(
    collectionName,
    false,
    true,
    {},
    isPrivilegedCollection,
    shouldTriggerUpdate,
    limit || 50, // maybe too much, but at least ca 46 items are cities (implement pre filtering?)
    query,
  );
}

export interface UseFirebaseDocument<T extends Record<string, unknown>> {
  error: string | null;
  isFetching: boolean;
  result: T | null;
}

export function useFirebaseDocument<T extends Record<string, unknown>>(
  fetchPreCondition: boolean,
  collectionName: string,
  documentId: string,
  dataProperties: T, // TODO: implement validation?
  isPrivilegedCollection: boolean,
  shouldTriggerUpdate: boolean,
  query?: CollectionQuery | null,
): UseFirebaseDocument<T> {
  const { firebase, isLoggedIn, user } = useAppContext();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [result, setResult] = React.useState<T | null>(null);

  console.log('useFirebaseDocument for collectionName: ', collectionName);
  console.log('useFirebaseDocument docId: ', documentId);
  React.useEffect(() => {
    if (
      fetchPreCondition &&
      (!isPrivilegedCollection || (isPrivilegedCollection && isLoggedIn)) &&
      firebase &&
      shouldTriggerUpdate
    ) {
      const request = async function getCollection() {
        try {
          setIsFetching(true);
          setError(null);

          const docRef = doc(getFirestore(firebase), `${collectionName}/${documentId}`);
          const documentSnap = await getDoc(docRef);
          console.log('getting doc - without cache via useFirebaseDocument');
          if (documentSnap.exists()) {
            const docData = documentSnap.data() as T;
            console.log('got doc - without cache: ', docData);
            if (docData) {
              setResult(docData || null);
            } else {
              setError('No documents found'); // NOTE: empty querys are handled as error..
            }
          }
        } catch (err) {
          console.log(err);
          setError(err.message || 'Unknown Error');
          setResult(null);
        } finally {
          setIsFetching(false);
        }
      };

      request();
    }
  }, [
    fetchPreCondition,
    collectionName,
    documentId,
    isLoggedIn,
    query,
    shouldTriggerUpdate,
    isPrivilegedCollection,
  ]);

  return { error, isFetching, result };
}

// TODO: eval if array supported version is necessary?

export type SupportedDBBaseType = string | boolean;
export type SupportedDBType =
  | SupportedDBBaseType
  | Record<string, SupportedDBBaseType>
  | Record<string, Record<string, SupportedDBBaseType>>; // NOTE: this could get annoying if the nesting gets deeper...

// TODO: copy from user permission management logic into shopping cart container
/** is using internally setDoc... */
export function useShopFirebaseUpdateDocument<
  T extends SupportedDBType,
  // U extends firebase.firestore.DocumentData = firebase.firestore.DocumentData
  // T extends firebase.firestore.DocumentData = firebase.firestore.FieldValue
>(documentPath: string, propertyName: string) {
  const { firebase, isLoggedIn, createNotification } = useAppContext(); // problem with different contexts

  // TODO: implement validation?
  const updateDocument = async (documentId: string, propertyValue: T): Promise<void> => {
    if (isLoggedIn && firebase) {
      try {
        // const db = firebase.firestore();
        // const docRef = db.collection(documentPath).doc(documentId); // onSnapshot...
        const docRef = doc(getFirestore(firebase), `${documentPath}/${documentId}`);
        // const doc = ((await docRef.get()) as any) as U; // TODO: use fb converter
        // console.log('updating doc: ', doc);
        // if (!doc.exists) {
        //   createNotification(NotificationType.ERROR, 'Document to modify does not exists', 3000);
        //   return Promise.reject(new Error('Document to modify does not exists'));
        // }
        // return await docRef.set({ [propertyName]: propertyValue }, { merge: true }); // TODO: create store rule to not allow other values
        return await setDoc(docRef, { [propertyName]: propertyValue }, { merge: true });
        // TODO: clean up beginning with useAccount vs useAppState => merge into core
      } catch (err) {
        console.warn(err);
        createNotification(NotificationType.ERROR, JSON.stringify(err), 3000);

        return Promise.reject(err);
      }
    } else {
      console.warn('PublishDocument operation failed - insufficient permission');
      createNotification(
        NotificationType.ERROR,
        'PublishDocument operation failed - insufficient permission',
        3000,
      );
      return Promise.reject(
        new Error('PublishDocument operation failed  - insufficient permission'),
      );
    }
  };

  return updateDocument;
}
