import * as React from 'react';
import GImage from 'gatsby-image';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps, GatsbyImageFile } from 'src/app/types';

export interface GatsbyImageProps extends BaseComponentProps {
  children?: React.ReactNode;
  imageFile: GatsbyImageFile;
}

const rootContainer: React.CSSProperties = {
  width: '100%',
  display: 'flex',
};

const childContainer: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  position: 'absolute',
  zIndex: 1000,
};

const innerChildContainer: React.CSSProperties = {
  width: '100%',
  minHeight: '200px', // TODO: depends on item width
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  wordBreak: 'break-word',
};

/**
 * Image / WAPImage
 * @param { GatsbyImageProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const GatsbyImage = ({ children, imageFile, style, className }: GatsbyImageProps) => {
  return (
    <>
      {/* <img
      alt={'img-'}
      src={src}
      style={{
        // backgroundImage: `url("${src}")`,
        // backgroundSize: 'contain',
        // backgroundPosition: 'center',

        width: '100%',
        height: 'auto',
        ...style,
      }}
    /> */}
      <GImage
        // key={selectedImageIdx} // for blur reload effect
        // className={clsx(
        //   styles.itemCard__media,
        //   !isWiderMD ? styles.itemCard__mediaSM : {},
        // )}
        style={{
          ...style,
        }}
        fluid={imageFile.childImageSharp.fluid}
        alt={'Country Image'}        
        className={className || ''}
      />      
      {children}      
    </>
  );
};

const WAPGatsbyImage = (props: CombinedAppProps<GatsbyImageProps>) =>
  withoutAppProps<CombinedAppProps<GatsbyImageProps>, GatsbyImageProps>(GatsbyImage, props);

export { GatsbyImage, WAPGatsbyImage };
