import * as React from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import { useAppContext } from 'src/app/hooks';

import { DocumentGetErrorNotification } from 'src/app/components';

import { NotificationType } from 'src/app/constants';

export type AcceptedDataTypesTodo = Record<string, any>; // BaseDocumentData | CountryDocumentCreateFormData ;

export interface UseGetDocument {
  document: AcceptedDataTypesTodo | null;
  update: () => void;
  hasError: boolean;
  isFetching: boolean;
}

// FIXME: remove component + component user references
export const useGetDocument = <T extends AcceptedDataTypesTodo>(
  collectionName: string,
  documentId: string,
  isPrivilegedCollection: boolean,
): UseGetDocument => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<T | null>(null);

  const [shouldUpdateUpdate, setShouldUpdateUpdate] = React.useState<boolean>(true); // initial update is on

  const update = () => {
    setShouldUpdateUpdate(true); // TODO:
  };

  React.useEffect(() => {
    if (
      firebase &&
      shouldUpdateUpdate &&
      (!isPrivilegedCollection || (isPrivilegedCollection && isLoggedIn))
    ) {
      const store = getFirestore();
      const docRef = doc(store, `${collectionName}/${documentId}`);

      const request = async function getCollection() {
        try {
          setIsFetching(true);
          setHasError(false);

          const docSnap = await getDoc(docRef);

          console.log('getting doc - without cache');
          if (docSnap.exists()) {
            const docData = docSnap.data() as T;
            console.log('got doc data  - without cache: ', docData);
            if (!docData) {
              throw new Error('Document contains no data');
            }
            if (docData) {
              setDocument(docData);
            }
          }
        } catch (err) {
          console.warn(err);
          createNotification(NotificationType.ERROR, DocumentGetErrorNotification);
          setHasError(true);
          setDocument(null);
        } finally {
          setIsFetching(false);
        }
      };

      request();
    }
  }, [
    collectionName,
    documentId,
    isLoggedIn,
    shouldUpdateUpdate,
    isPrivilegedCollection,
  ]);

  return { hasError, isFetching, document, update };
};
