// export * as call from './callables';

// NOTE: ORG

const ORG_PRODUCT = {
  PRODUCT: (organisationId: string, productId: string) =>
    `organisation/${organisationId}/product_source/${productId}`,
  PRODUCT_LOCALIZATION: (organisationId: string, productId: string, localeId: string) =>
    `organisation/${organisationId}/product_source/${productId}/localization/${localeId}`,
  PRODUCT_IMAGE: (organisationId: string, productId: string, imageDocId: string) =>
    `organisation/${organisationId}/product_source/${productId}/image/${imageDocId}`,
  PRODUCT_MEDIA: (organisationId: string, productId: string, mediaTypeId: string) =>
    `organisation/${organisationId}/product_source/${productId}/media/${mediaTypeId}`, // e.g 'youtube'
};

const ORG_COLLECTION = {
  // USER,
  // ...ORG_PRODUCT,
};

const ORG_DOCUMENT = {
  // USER,
  ...ORG_PRODUCT,
};

// NOTE: SHOP

const USER = {
  CART: (uid: string) => `user/${uid}/cart`,
  ORDER: (uid: string) => `user/${uid}/order`,
  WISHLIST: (uid: string) => `user/${uid}/wishlist`,
};

const PRODUCT = {
  EVENT: (productId: string) => `product/${productId}/daytimeEvent`,
  PRODUCT: 'product',
};

const SHOP_COLLECTION = {
  USER,
  PRODUCT,
};

// NOTE: SYS

const SYS_COLLECTION = {
  // USER,
  // PRODUCT,
};

// NOTE: COMPOSING

const ORG = {
  COLLECTION: ORG_COLLECTION,
  DOCUMENT: ORG_DOCUMENT,
};

const SHOP = {
  COLLECTION: SHOP_COLLECTION,
  // DOCUMENT: USER_DOCUMENT,
};

const SYS = {
  COLLECTION: SYS_COLLECTION,
};

const API = {
  ORG,
  SHOP,
  SYS,
};

export default API;
