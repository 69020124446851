import * as React from 'react';

// NOTE: LEGACY in NODE: https://nodejs.org/api/querystring.html
// consider removing and replace with 'query-string' or 'qs' NPM package
import querystring from 'querystring';

import { useAppContext } from 'src/app/hooks';
import { Grid, CategoryFilter, CityFilter, SignupBox } from 'src/app/components';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps, ProductSearchQuery } from 'src/app/types';

import { ProductCategoryType, Collection } from 'src/app/constants';
import { SearchResultsList } from './SearchResultsList';

import { HintBox } from './components/HintBox';
import { DateFilter } from './components/DateFilter';
// import { SearchFilter } from './components/SearchFilter';
import { SearchSort } from './components/SearchSort';

// type CollectionName = 'user' | 'country' | 'city';

export interface SearchResultsProps extends BaseComponentProps {
  // id: string;
  // collectionName: CollectionName;
  // query?: CollectionQuery;
  // dataProperties: {};
  location: {
    search: string;
  };
}

// export { ActivityContainerProps as ActivityProps };

// TODO: rename in SearchResults container + make query both the GQL store and then the FB API(with date params)?
/**
 * SearchResults
 * @param { SearchResultsProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const SearchResults = (props: SearchResultsProps) => {
  // const { collectionName, dataProperties } = props;
  // const { location } = props;
  const { isLoggedIn, user } = useAppContext();
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(true);
  // const [query, setQuery] = React.useState<CollectionQuery | null>();
  const [results, setResults] = React.useState<any[] | null>(null);

  const [productType, setProductType] = React.useState<ProductCategoryType | null>(null);

  return null; // NOT USED ANYMORE ? => IF YES REIMPLEMENT FB V9

  const collectionName = 'product';
  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location?.search || '';
  }

  // remove first char '?' from query string
  const locationQuery: string = search
    .split('')
    .filter((v, idx) => idx !== 0)
    .join('');
  console.log('locationQuery: ', locationQuery);
  const queryObj2 = querystring.decode(locationQuery) as any as ProductSearchQuery;

  // const onQueryChange = (q: CollectionQuery) => {
  //   setQuery(q);
  // };

  console.log('product fetch results: ', results);

  React.useEffect(() => {
    const testo = async () => {
      const query2 = firebase
        .firestore()
        .collection('product')
        .where('status', '==', 'published');
      // .where('productType', '==', 'rental');
      // .orderBy('created', 'desc');
      const snapshot = await query2.get();
      // return snapshot.docs.map((doc) => doc.data());
      const res = snapshot.docs.map((doc) => doc.data());
      console.log(
        'test:',
        snapshot.docs.map((doc) => doc.data()),
      );
      if (res) {
        setResults(res);
        console.log('EFF1-1');
      }
    };
    if (!locationQuery && !results) {
      console.log('got no search');
      testo();
      console.log('EFF1-2');
    }
  }, [locationQuery, results]);

  React.useEffect(() => {
    if (
      isLoggedIn &&
      firebase &&
      firebase?.apps?.length &&
      shouldUpdate &&
      locationQuery
    ) {
      const app = firebase.app();
      const db = app.firestore();
      const queryObj = querystring.decode(locationQuery) as any as ProductSearchQuery; // q=split-croatia&p=activity&date_from=2020-12-01&date_to=2020-12-01

      const collectionRef = db
        .collection(collectionName)
        .where('status', '==', 'published');
      let collectionQuery: firebase.firestore.Query<firebase.firestore.DocumentData> =
        null as any as firebase.firestore.Query<firebase.firestore.DocumentData>;
      if (queryObj.country) {
        if (!collectionQuery) {
          collectionQuery = collectionRef.where(
            'productLocationCountry',
            '==',
            queryObj.country,
          );
        } else {
          collectionQuery = collectionQuery.where(
            'productLocationCountry',
            '==',
            queryObj.country,
          );
        }
      }
      if (queryObj.city) {
        if (!collectionQuery) {
          collectionQuery = collectionRef.where(
            'productLocationCity',
            '==',
            queryObj.city,
          );
        } else {
          collectionQuery = collectionQuery.where(
            'productLocationCity',
            '==',
            queryObj.city,
          );
        }
      }
      if (queryObj.p) {
        if (!collectionQuery) {
          collectionQuery = collectionRef.where(
            'productCategoryIds',
            'array-contains',
            queryObj.p,
          );
        } else {
          collectionQuery = collectionQuery.where(
            'productCategoryIds',
            'array-contains',
            queryObj.p,
          );
        }
      }

      if (queryObj.p && queryObj.p !== productType) {
        setProductType(queryObj.p as ProductCategoryType);
      }

      // const collectionQuery = null;

      // console.log('collectionQuery: ', collectionQuery);

      const request = async function getCollection() {
        try {
          const documentSnapshot = collectionQuery
            ? await collectionQuery.get()
            : await collectionRef.get();
          if (documentSnapshot.empty) {
            console.log('No matching documents inside snapshot.');
            return;
          }

          const res: any[] = [];

          documentSnapshot.forEach((doc) => {
            console.log(doc.id, '=>', doc.data());
            const data = doc.data() as unknown as T;
            res.push(data);
          });
          setResults(res || null);
          setShouldUpdate(false);
        } catch (err) {
          console.log(err);
          setResults(null);
          setShouldUpdate(false);
        }
      };
      console.log('EFF2');
      request();
    }
  }, [collectionName, isLoggedIn, shouldUpdate, locationQuery, productType]);

  // console.log('SearchResults props: ', props);
  /** safety filter */
  // const searchResults =
  //   results  && productType
  //     ? results.filter((product: BaseProduct<any>) => product.type === productType)
  //     : [];

  const queryObj = querystring.decode(locationQuery) as any as ProductSearchQuery;

  const updateQuery = (newQuery: ProductSearchQuery) => {
    const newQueryString = querystring.stringify(newQuery);
    console.log('newQueryString: ', newQueryString);
    window.location.search = newQueryString;
  };

  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <SearchSort query={queryObj} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <SearchResultFilter query={queryObj} updateQuery={updateQuery} />
          </Grid>
          <Grid item xs={12} md={9}>
            <SearchResultsList results={results} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* <SignupBox
          item={item}
          // cityId={'hr-split-000'}
          // contextId={'product-view'}
          // countryId={'hr'}
          // imageUrl={'/backgroundViva.webp'}
        /> */}
      </Grid>
    </Grid>
  );
};

export interface SearchResultFilterProps {
  updateQuery: (query: ProductSearchQuery) => void;
  query: ProductSearchQuery;
}

const SearchResultFilter = (props: SearchResultFilterProps) => {
  const { updateQuery, query } = props;
  const cityId = query.city || '';
  const categoryId = query.p || '';
  const setCityId = (cityId: string) => {
    const newQuery = { ...query };
    newQuery.city = cityId;
    updateQuery(newQuery);
  };
  const setCategoryId = (categoryId: string) => {
    const newQuery = { ...query };
    newQuery.p = categoryId;
    updateQuery(newQuery);
  };
  // console.log('SearchResultFilter query: ', query);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DateFilter query={query} />
      </Grid>
      <Grid item xs={12}>
        <HintBox type={query.p || 'none'} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CityFilter currentCityId={cityId || ''} setCityId={setCityId} />
          </Grid>
          <Grid item xs={12}>
            <CategoryFilter
              currentCategoryId={categoryId || ''}
              setCategoryId={setCategoryId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WAPSearchResults = (props: CombinedAppProps<SearchResultsProps>) =>
  withoutAppProps<CombinedAppProps<SearchResultsProps>, SearchResultsProps>(
    SearchResults,
    props,
  );

export { SearchResults, WAPSearchResults };
