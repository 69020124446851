import * as React from 'react';

import { Button, Divider, Grid, Typography } from 'src/app/components';
import { BaseComponentProps, ContentNavigationType } from 'src/app/types';
import { useLocalizationContext } from 'src/app/hooks';

import { useStyles } from 'src/app/context';
import { getBaseDocumentLocales } from 'src/lib/document';
import { NavigationListItem } from './NavigationListItem';

export interface ContentNavigationItemProps extends BaseComponentProps {
  children?: React.ReactNode;
  item: ContentNavigationType;
  onClick: (itemId: string) => void;
}

export const ContentNavigationItem = ({
  children,
  item,
  onClick,
}: ContentNavigationItemProps) => {
  const { language } = useLocalizationContext();
  const styles = useStyles();

  const { teaser, shortDescription, name } = getBaseDocumentLocales(item, language);

  return (
    <div
      className='w-10/12 first:ml-4 last:mr-4 sm:w-2/3 md:first:ml-0 md:last:mr-0 md:ml-0 md:w-full flex-shrink-0 snap-center'>
      <div // TODO; place 2 buttons beside each other (img + left area)
        // onClick={() => navigateLocalized(`/experience/${p.id}`)}
        onClick={() => onClick(item.id)}
        className='w-full bg-transparent border-none h-full cursor-pointer'
      >
        <NavigationListItem<ContentNavigationType>
          title={
            name ? (
              <>
                <h3 className='text-p1 font-semibold md:text-h3 md:font-bold'>
                  {name}
                </h3>
                {teaser ? (
                  <h4 className='text-p2 font-medium md:text-h4 md:font-bold'>{teaser}</h4>
                ) : null}
              </>
            ) : null
          }
          item={item}
        >
          {/* <ProductListItemContent<{}> {...p} /> */}
          <div>
            {children}
            {/* {shortDescription ? (
              <>
                <Grid
                  item
                  xs={12}
                  className={styles.mt__2}
                  style={{ padding: '0 64px' }}
                >
                  <Typography variant={'body1'}>{shortDescription}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ) : null} */}
            <Grid item xs={12} className={styles.mb__2}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography>
                    Activities: {item?.fields?.activityCount || 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Boats: {item?.fields?.navalCount || 0}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Rentals: {item?.fields?.rentalCount || 0}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </NavigationListItem>
      </div>
    </div>
  );
};
