import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageFile } from 'src/app/types';

interface AppImagesQuery {
  file: GatsbyImageFile;
}

export interface UseAppImages {
  fallbackImage: GatsbyImageFile;
  signupImage: GatsbyImageFile;
}
export function useAppImages(): UseAppImages {
  const { file } = useStaticQuery<AppImagesQuery>(graphql`
    query {
      # file(relativePath: { eq: "../assets/ok.png" }) {
      #   childImageSharp {
      #     # Specify the image processing specifications right in the query.
      #     # Makes it trivial to update as your page's design changes.
      #     fixed(width: 125, height: 125) {
      #       ...GatsbyImageSharpFixed
      #     }
      #   }
      # }
      file(relativePath: { eq: "notFound.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          # fixed(width: 125, height: 125) {
          #   ...GatsbyImageSharpFixed
          # }
          fluid(maxWidth: 1230, maxHeight: 800, quality: 1) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    # {
    #   allFile(filter: { extension: { eq: "pdf" } }) {
    #     edges {
    #       node {
    #         publicURL
    #         name
    #       }
    #     }
    #   }
    # }
  `);

  // return allTag?.nodes || [];
  return { fallbackImage: file, signupImage: file };
}
