import * as React from 'react';

import { Seo, SeoProps, GDPRConsentTrigger, PasswordProtected } from 'src/app/components';
import { CombinedAppProps, SeoObject } from 'src/app/types';

// import { useAppStaticQueryConfig, useFirebaseStaticQueryConfig } from 'src/app/hooks';
// import { ScriptLoader } from './ScriptLoader'; // NOTE: not used for pp but could be handy for other dom scripts
// import { BProtectedRoute as ProtectedRoute } from 'components';

export interface AppPageProps extends CombinedAppProps<SeoProps> {
  children: React.ReactNode;
  data: any;
  seo: SeoObject;
  hasPaypal?: boolean;
  hideAppbar?: boolean;
}
// TODO: create here a path based seo import system and inject it

/**
 * Site
 * @param { AppPageProps } props
 * @version 0.0.0
 * @description
 * - wraps with <Seo />
 * - show env debug information in non-prod envs
 * @todo
 * @example
 */
const AppPage = (props: AppPageProps) => {
  const { children, location, pageContext, seo } = props;
  // const { env, emulationMode, version } = useAppStaticQueryConfig();
  // const { projectId } = useFirebaseStaticQueryConfig();
  // initDebug();
  return (
    <>
      {/* <ScriptLoader loadPaypal={!!loadPaypal} /> */}
      {/* <ProtectedRoute>{children}</ProtectedRoute> */}
      <Seo location={location} pageContext={pageContext} seo={seo} />
      {children}
      {/* {env !== 'PRODUCTION' ? (
          <div style={{ padding: '0 16px' }}>
            <span>{`id: ${projectId} | env: ${env} | version: v${version} | emulateServices: ${emulationMode}`}</span>
          </div>
        ) : null} */}
    </>
  );
};

export { AppPage };
