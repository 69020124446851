import { graphql, useStaticQuery } from 'gatsby';

import { GQLSiteData } from 'src/app/types';
import { PaypalEnvConfig } from 'src/lib/paypal';

export function usePaypalStaticQueryConfig(): PaypalEnvConfig {
  const query = useStaticQuery<GQLSiteData>(graphql`
    {
      site {
        siteMetadata {
          appData {
            paypal {
              clientId
            }
          }
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: {
        appData: {
          paypal: { clientId },
        },
      },
    },
  } = query;

  return { clientId };
}
