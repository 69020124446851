import * as React from 'react';
import { Grid } from 'src/app/components';

export interface BaseNotificationProps {
  children: React.ReactNode;
  title: React.ReactNode;
}
export const BaseNotification = ({ children, title }: BaseNotificationProps) => (
  <Grid container>
    <Grid item xs={12}>
      {title}
    </Grid>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);
