import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
// import IconButton from '@material-ui/core/IconButton';

import { Localized, TextInputField } from 'src/app/components';

import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FormFieldLayout } from '../Layouts';

import { FormFieldProps, NestedFieldProps } from '../form.types';
import { FieldStyle } from '../form.constants';

export interface TextFieldProps {
  isPassword?: boolean;
}

const FormTextField = ({
  children,
  fieldStyle,
  i18nKey,
  isPassword,
  name,
  rules,
  defaultValue,
  ...passProps
}: FormFieldProps<string> & TextFieldProps) => {
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
  } = formMethods;

  // let controllerArgs = {
  //   name,
  //   control,
  //   rules,
  //   defaultValue,
  // };

  // if (name === 'localizations.0.locales.name') {
  //   console.warn('localizations.0.locales.name määäh');
  //   controllerArgs = {
  //     name: 'RHF__arrayItemKeyName_localizations.0.locales.name',
  //     control,
  //     rules,
  //     defaultValue,
  //   };
  // }

  const controllerArgs = {
    name,
    control,
    rules,
    defaultValue,
  };

  // console.warn('controllerArgs: ', controllerArgs);

  const controller = useController(controllerArgs);
  const hasError = !!errors?.[name]; // TODO:

  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = controller;
  console.log(inputProps,'ygulygyugukyfguyfuyf')

  // console.warn('controller: ', controller);

  // return null;
  return (
    <FormFieldLayout
      additionalBottomArea={
        !fieldStyle || (fieldStyle && fieldStyle === FieldStyle.FORM) || hasError ? (
          <FieldInformation
            hasError={hasError}
            description={<Localized dictKey={`${i18nKey}.field.description`} />}
            example={<Localized dictKey={`${i18nKey}.field.example`} />}
          >
            {children}
          </FieldInformation>
        ) : null
      }
      debugId={name}
      debugI18nKey={i18nKey}
      fieldStyle={hasError ? FieldStyle.FORM : fieldStyle}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <TextInputField
          type={isPassword ? 'password' : 'text'}
          variant={'outlined'}
          {...inputProps}
          {...passProps}
          value={inputProps.value || ''}
          inputRef={ref}
          error={hasError}
          fullWidth
          InputLabelProps={passProps.type === 'date' ? { shrink: true } : {}}
          label={<Localized dictKey={`${i18nKey}.field.name`} />}
          // helperText={<div style={{ backgroundColor: 'red' }}>Heeeelp</div>}
          // helperText={'Heeeelp'}
          InputProps={{
            endAdornment: <FieldAdornment hasError={hasError} />,
          }}
        />
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

// updatemode ?
// if is "update" mode render different version?
// all versions have to be passed then (create(form)/update(form/singleFields))
// update involves whole documents or /(and) single fields on documents, maybe even nested ones...
export const TextField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps<string> & TextFieldProps) => (
  <FormTextField
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormTextField>
);
