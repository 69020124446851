import { getFirestore, doc, deleteDoc, getDoc } from 'firebase/firestore';

import { NotificationType } from 'src/app/constants';
import { useAppContext, useFirebaseStorage } from 'src/app/hooks';

// import { createBaseDocument } from 'src/app/lib'; // generics go here...
import {
  DocumentDeleteErrorNotification,
  DocumentDeleteSuccessNotification,
  ImageDeleteErrorNotification,
  ImageDeleteSuccessNotification,
} from 'src/app/components';

export type AcceptedDataTypesTodo = Record<string, any>; // BaseDocumentData | CountryDocumentCreateFormData ;

export interface UseDeleteDocumentWithStorageFiles {
  // NOTE: we make a lookup to the doc itself to look what can be deleted...
  deleteDocumentWithStorageFiles: <T extends AcceptedDataTypesTodo>(
    id: string,
    collectionPath: string,
  ) => Promise<void>;
}

// NOTE: Currently only supporting image files...
const imageFilePropertyName = 'imageUrls';
export const useDeleteDocumentWithStorageFiles = (): UseDeleteDocumentWithStorageFiles => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();
  const { useDeleteFile } = useFirebaseStorage();
  const { deleteFile } = useDeleteFile(
    ImageDeleteSuccessNotification,
    ImageDeleteErrorNotification,
  );

  const deleteDocumentWithStorageFiles = async <T extends AcceptedDataTypesTodo>(
    id: string,
    collectionPath: string,
  ) => {
    // 2 operation modes necessary? (drop operation & aks for confirmation)
    if (!firebase || !isLoggedIn || !id || !collectionPath) {
      console.warn('Invalid delete operation params');
      return;
    }
    try {
      const store = getFirestore(firebase);
      const docRef = doc(store, `${collectionPath}/${id}`);

      const docRes = await getDoc(docRef);
      if (docRes.exists()) {
        const docData = docRes.data() as T;
        if (docData[imageFilePropertyName]?.length) {
          for (let index = 0; index < docData[imageFilePropertyName].length; index++) {
            const imgData = docData[imageFilePropertyName][index];
            if (imgData.fileName && imgData.fileExtension && imgData.storagePath) {
              deleteFile(
                `${imgData.fileName}.${imgData.fileExtension}`,
                imgData.storagePath,
              );
            } else {
              console.warn('Invalid image storage data - cannot be deleted');
              // trigger some webhook/trackerAction/email notification here to report/find invalid items
            }
          }
        }
      }

      await deleteDoc(docRef);
      createNotification(NotificationType.SUCCESS, DocumentDeleteSuccessNotification);
    } catch (err) {
      createNotification(NotificationType.ERROR, DocumentDeleteErrorNotification);

      console.warn('Error on delete - error: ', err);
    }
  };

  return { deleteDocumentWithStorageFiles };
};
