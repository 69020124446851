import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { /* Button, Localized, */ Grid, LocalizedLink, Glass } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';
import { LoginFormContainer } from './LoginFormContainer';

export interface LoginContainerProps extends BaseComponentProps {}

/**
 * LoginContainer / WAPLoginContainer
 * @param { LoginContainerProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoginContainer = (props: LoginContainerProps) => {
  const { children, ...restProps } = props;
  const { isLoggedIn } = useAppContext();
  const [shouldPersistSession, setShouldPersistSession] = React.useState(false);

  const onRememberMeClick = (value: boolean) => {
    console.warn('not implemented');
    // TODO: propagate to useAppContext and create functionality (if needed? or set cookie ttl to certain val)
    setShouldPersistSession(value);
  };

  return isLoggedIn ? null : (
    <Glass
      style={{ margin: '32px 0', padding: '64px 25%', minHeight: '600px' }}
      {...restProps}
    >
      <LoginFormContainer>
        <Grid container>
          <Grid item xs={12} md={6}>
            <span>
              <Checkbox
                onChange={(e) => onRememberMeClick(e.target.checked)}
                checked={shouldPersistSession}
              />
              <span>Remember me</span>
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <LocalizedLink href={'/lost-password'}>Forgot your password?</LocalizedLink>
          </Grid>
          {children}
        </Grid>
      </LoginFormContainer>
    </Glass>
  );
};

const WAPLoginContainer = (props: CombinedAppProps<LoginContainerProps>) =>
  withoutAppProps<CombinedAppProps<LoginContainerProps>, LoginContainerProps>(
    LoginContainer,
    props,
  );

export { LoginContainer, WAPLoginContainer };
