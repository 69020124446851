import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { navigate } from 'gatsby';

import {
  Button,
  ButtonTw,
  Glass,
  Localized,
  Breadcrumbs,
  ProductRating,
} from 'src/app/components';
import { ContentNavigationType, SourcedGQLProduct } from 'src/app/types';
import {
  useLocalizationContext,
  useLocalizedNavigation,
  useAppImages,
} from 'src/app/hooks';
import { ProductCategoryType, GatsbyNodeType } from 'src/app/constants';
import { useStyles, useAppContext } from 'src/app/context';

import { getTranslationForLanguage } from 'src/lib/document';
import { ItemCardTopArea } from './ItemCardTopArea';
import { ItemImageSelectWidget } from './ItemImageSelectWidget';
// import { ProductReviews } from '../ProductReviews'; // TODO
import { ItemCardTitleContainer } from './ItemCardTitleContainer';

import { ProductPriceContainer } from './Product/ProductPriceContainer';

export interface ItemCardProps {
  children: React.ReactNode;
  extraChildren?: React.ReactNode;
  onAddWishlistProduct?: (id: string) => void;
  item: ContentNavigationType; // TODO:  | BaseProduct<T> - BaseProduct as fallback technique for direct api query
  itemType: GatsbyNodeType;
  reviewAnchorId: string;
  itemInformation?: React.ReactNode; // Block on the right side (weather)
  // new
  itemLocales: {
    teaser?: string;
    title?: string;
    description?: string;
  };
  nextItemId?: string;
  prevItemId?: string;
}

export interface SiblingNavigationProps {
  nextItemId?: string;
  prevItemId?: string;
}

const SiblingNavigation = ({ nextItemId, prevItemId }: SiblingNavigationProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);

  const { navigateLocalized } = useLocalizedNavigation();
  const onNavigate = (navId: string) => {
    if (typeof window === 'undefined') {
      return;
    }
    const { pathname } = window.location;
    const segments = pathname.split('/');
    // NOTE: idx === 0 is supposed to be the localeId other wise => BAD
    const filteredPath = segments
      .filter((val, idx) => Boolean(val) && idx !== 1 && idx < segments.length - 1)
      .join('/');
    const newpath = `/${filteredPath}/${navId}`;
    navigateLocalized(newpath);
  };
  return (
    <div
      className={clsx(
        styles.itemCardTitleContainer__sibling_nav,
        !isWiderMD ? styles.itemCardTitleContainer__sibling_nav__SM : '',
      )}
    >
      {prevItemId ? (
        <Glass opacity={0.2} blurAmount={2} className={styles.containerLeftCenter}>
          <Button
            variant={'text'}
            className={clsx(
              styles.itemCardTitleContainer__sibling_button,
              styles.containerLeftCenter,
              styles.w_min_64px,
            )}
            onClick={() => onNavigate(prevItemId)}
          >
            <NavigateBeforeIcon color={'inherit'} />
          </Button>
        </Glass>
      ) : (
        <div />
      )}
      {nextItemId ? (
        <Glass opacity={0.2} blurAmount={2} className={styles.containerRightCenter}>
          <Button
            variant={'text'}
            className={clsx(
              styles.itemCardTitleContainer__sibling_button,
              styles.containerRightCenter,
              styles.w_min_64px,
            )}
            onClick={() => onNavigate(nextItemId)}
          >
            <NavigateNextIcon color={'inherit'} />
          </Button>
        </Glass>
      ) : (
        <div />
      )}
    </div>
  );
};

/**
 * ItemCard
 * @param { ItemCardProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const ItemCard = <T extends ProductCategoryType>(props: ItemCardProps) => {
  const {
    children,
    extraChildren,
    onAddWishlistProduct,
    item,
    itemInformation,
    itemType,
    nextItemId,
    prevItemId,
    reviewAnchorId,
    itemLocales,
  } = props;
  const { productLocales, localization, imageUrls } = item;
  const { teaser, title, name, description, shortDescription } = itemLocales;
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const { fallbackImage } = useAppImages();
  const { isAppDebug } = useAppContext();
  const { language } = useLocalizationContext();
  const [selectedImageIdx, setSelectedImageIdx] = React.useState<number>(0);

  const [productImages, setProductImages] = React.useState<Array<any>>(item.imageFiles);
  const imageCount = item.imageFiles?.length || 0;

  const imageName =
    !Number.isNaN(selectedImageIdx) && imageUrls?.[selectedImageIdx]
      ? getTranslationForLanguage(
          imageUrls[selectedImageIdx]?.localizations || [],
          language,
          'name',
          isAppDebug,
        )
      : '';
  const imageDescription =
    !Number.isNaN(selectedImageIdx) && imageUrls?.[selectedImageIdx]
      ? getTranslationForLanguage(
          imageUrls[selectedImageIdx]?.localizations || [],
          language,
          'description',
          isAppDebug,
        )
      : '';

  //console.log('imageName: ', imageName);

  // TODO: blend in addition appBar with product nav points
  // console.log('carousellItems: ', carousellItems);

  // const onWishlistButtonClick = () => {
  //   console.log('wishlist');
  // };

  //console.log('Item card props: ', props);
  // console.log('Item card imageCount: ', imageCount);

  const onNextImageClick = () => {
    if (!selectedImageIdx) {
      setSelectedImageIdx(imageCount > 0 ? 1 : 0);
    } else {
      setSelectedImageIdx(selectedImageIdx + 1 < imageCount ? selectedImageIdx + 1 : 0);
    }
  };
  const onPrevImageClick = () => {
    if (!selectedImageIdx) {
      setSelectedImageIdx(imageCount - 1);
    } else {
      setSelectedImageIdx(
        selectedImageIdx - 1 < 0 ? imageCount - 1 : selectedImageIdx - 1,
      );
    }
  };
  const onSelectImageIndexClick = (index: number) => {
    setSelectedImageIdx(index || 0);
  };

  const fallbackFluid = fallbackImage.childImageSharp.fluid; // TODO: create fallback mechanism
  const currentFluid =
    item.imageFiles?.[selectedImageIdx]?.childImageSharp?.fluid || fallbackFluid;
  const isPreview = item.imageFiles?.[selectedImageIdx]?.imageUrl;
  const navigationData = {
    continentId: item.continentId || '',
    countryId: item.countryId || '',
    countryAreaId: item.countryAreaId || '',
    cityId: item.cityId || '',
    cityAreaId: item.cityAreaId || '',
  };
  if (itemType === GatsbyNodeType.CONTINENT) {
    navigationData.continentId = item.id;
  }
  if (itemType === GatsbyNodeType.COUNTRY) {
    navigationData.countryId = item.id;
  }
  if (itemType === GatsbyNodeType.COUNTRY_AREA) {
    navigationData.countryAreaId = item.id;
  }
  if (itemType === GatsbyNodeType.CITY) {
    navigationData.cityId = item.id;
  }
  if (itemType === GatsbyNodeType.CITY_AREA) {
    navigationData.cityAreaId = item.id;
  }

  const productItem = item as any as SourcedGQLProduct;

  const [isActive, setActive] = React.useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isExpanded, setExpanded] = React.useState(true);
  const toggleExpandReadMore = () => {
    setExpanded(!isExpanded);
  };

  const [isDropdown, setDropdown] = React.useState(false);
  const toggleDropdownClass = () => {
    setDropdown(!isDropdown);
  };

  React.useEffect(()=>{ 
    if(!isPreview){
      productImages.forEach((file, index) => {
        console.log(item.imageUrls[index],'fdgdgfdfgdfgdfg')
        file.order = item.imageUrls[index]?.order;
    }); 
    }
  },[item])
  return (
    <>
      <section className='md:px-4'>
        <div className='xl:container'>
          <div className='flex flex-col relative'>
            <div className={`h-auto md:min-h-[440px] md:h-[60vh] ${itemType === GatsbyNodeType.PRODUCT ? 'md:order-2 md:pb-7' : ''}`}>
              <div className='h-full md:grid md:gap-1 md:grid-cols-6 md:grid-rows-3 relative'>
                {imageCount > 1 ? (
                  <>
                    { productImages && productImages.sort((a:any,b:any)=>a?.order-b?.order).map((image, index) => (
                      <img
                        key={`i${image?.id}${index}`}
                        alt={image.displayName}
                        src={ `${!isPreview ? image.childImageSharp.fluid.src : image.imageUrl}` }

                        // alt={image.displayName}
                        className={`
                          object-cover object-center md:h-full md:w-full md:shadow-box-light dark:md:shadow-box-dark
                          ${
                            index === 0 && imageCount > 3
                              ? 'hidden md:block md:rounded-l-2xl md:col-span-4 md:row-span-3'
                              : ''
                          }
                          ${
                            index === 1 && imageCount > 3
                              ? 'hidden md:block md:rounded-tr-2xl md:col-span-2 md:row-span-2'
                              : ''
                          }
                          ${index === 2 && imageCount > 3 ? 'hidden md:block' : ''}
                          ${
                            index === 3 && imageCount > 3
                              ? 'hidden md:block md:rounded-br-2xl'
                              : ''
                          }
                          ${index > 3 && imageCount > 3 ? 'hidden' : ''}
                          ${
                            index === 0 && imageCount === 2
                              ? 'hidden md:block md:rounded-l-2xl md:col-span-4 md:row-span-3'
                              : ''
                          }
                          ${
                            index === 1 && imageCount === 2
                              ? 'hidden md:block md:rounded-r-2xl md:col-span-2 md:row-span-3'
                              : ''
                          }
                          ${
                            index === 0 && imageCount === 3
                              ? 'hidden md:block md:rounded-l-2xl md:col-span-4 md:row-span-3'
                              : ''
                          }
                          ${
                            index === 1 && imageCount === 3
                              ? 'hidden md:block md:rounded-tr-2xl md:col-span-2 md:row-span-2'
                              : ''
                          }
                          ${
                            index === 1 && imageCount === 3
                              ? 'hidden md:block md:rounded-br-2xl md:col-span-2'
                              : ''
                          }
                        `}
                      />
                    ))}

                    <ButtonTw
                      size={'sm'}
                      rounded={'rounded'}
                      variant={'neutral'}
                      className={'hidden md:block absolute z-1 bottom-4 right-4'}
                      onClick={toggleClass}
                    >
                      View all images
                    </ButtonTw>

                    <div
                      className={`${
                        isActive
                          ? 'fixed flex items-center justify-center w-full h-full inset-0 z-30'
                          : 'md:hidden'
                      }`}
                    >
                      <div
                        className='hidden absolute inset-0 z-0 w-full h-full bg-neutral-900 bg-opacity-90 md:block'
                        onClick={toggleClass}
                      />
                      <div className='xl:container relative md:z-10'>
                        {!isPreview ? (
                          <GatsbyImage
                            key={selectedImageIdx} // for blur reload effect
                            className={
                              'h-[260px] sm:min-h-[440px] sm:h-[75vh] md:w-[85vw] xl:max-w-[1200px] md:rounded-2xl md:mx-auto relative'
                            }
                            // fluid={
                            //   selectedImageIdx === null
                            //     ? product.imageFiles[0].childImageSharp.fluid
                            //     : product.imageFiles[selectedImageIdx].childImageSharp.fluid
                            // }
                            fluid={currentFluid}
                            alt={'Activity Title Image'}
                          />
                        ) : (
                          <div className={
                            'h-[260px] sm:min-h-[440px] sm:h-[75vh] md:w-[85vw] xl:max-w-[1200px] md:rounded-2xl md:mx-auto relative'
                          }>
                            <img
                              src={item.imageFiles[selectedImageIdx]?.imageUrl}
                              alt={item.imageFiles[selectedImageIdx]?.displayName}
                              className={'h-full w-full object-cover object-center z-10 relative'}
                            />
                          </div>
                        )}

                        <ItemImageSelectWidget
                          onNextImageClick={onNextImageClick}
                          onPrevImageClick={onPrevImageClick}
                          onSelectImageIndexClick={onSelectImageIndexClick}
                          item={item}
                        />
                      </div>
                    </div>

                  </>
                ) : (
                  <div className='md:col-span-6 md:row-span-3'>
                    {!isPreview ? (
                      <GatsbyImage
                        key={selectedImageIdx} // for blur reload effect
                        className={
                          'h-[260px] sm:min-h-[440px] md:h-full md:rounded-2xl relative'
                        }
                        // fluid={
                        //   selectedImageIdx === null
                        //     ? product.imageFiles[0].childImageSharp.fluid
                        //     : product.imageFiles[selectedImageIdx].childImageSharp.fluid
                        // }
                        fluid={currentFluid}
                        alt={'Activity Title Image'}
                      />
                    ) : (
                      <div className={
                        'h-[260px] sm:min-h-[440px] md:h-full md:rounded-2xl relative'
                      }>
                        <img
                          src={item.imageFiles[selectedImageIdx]?.imageUrl}
                          alt={item.imageFiles[selectedImageIdx]?.displayName}
                          className={'h-full w-full object-cover object-center z-10 relative'}
                        />
                      </div>
                    )}

                  </div>
                )}
              </div>

              {/* <SiblingNavigation nextItemId={nextItemId} prevItemId={prevItemId} /> */}

              {/* {itemInformation || null} */}

              {/* <ItemCardTitleContainer
                title={title || name || ''}
                description={teaser ?? ''}
              /> */}

              {true ? (
                <div>
                  {imageName}
                  {imageDescription}
                </div>
              ) : null}
            </div>
            <div className={`${itemType === GatsbyNodeType.PRODUCT ? 'md:order-1 ' : 'md:pt-12'} p-4 md:p-0`}>
              <div className='flex gap-4 absolute top-4 left-4 md:relative md:top-auto md:left-auto'>
                <ButtonTw
                  variant={'text'}
                  className={
                    'rounded-full w-7 h-7 bg-neutral-50 shadow-box-light dark:bg-neutral-900 dark:text-neutral-50 md:shadow-none md:w-6 md:h-6'
                  }
                  onClick={() => navigate(-1)}
                >
                  <KeyboardBackspaceIcon />
                </ButtonTw>
                <div className='hidden md:block md:pb-4'>
                  {itemType === GatsbyNodeType.PRODUCT ? (
                    <Breadcrumbs
                      continentId={productItem.location.continentId}
                      countryId={productItem.location.countryId}
                      countryAreaId={productItem.location.countryAreaId}
                      cityId={productItem.location.cityId}
                      cityAreaId={productItem.location.cityAreaId}
                    />
                  ) : (
                    <Breadcrumbs
                      continentId={navigationData.continentId}
                      countryId={navigationData.countryId}
                      countryAreaId={navigationData.countryAreaId}
                      cityId={navigationData.cityId}
                      cityAreaId={navigationData.cityAreaId}
                    />
                  )}
                </div>
              </div>

              <div className='dark:text-neutral-50 mb-4'>
                <h1 className='text-h3 font-bold md:text-h1'>{(title || name) ?? ''}</h1>
                <h3 className={`${itemType === GatsbyNodeType.PRODUCT ? 'hidden' : ''} text-p2 pt-2`}>{teaser ?? {}}</h3>
              </div>

              {/* <ProductPriceContainer product={item} /> */}

              <div className='flex justify-between dark:text-neutral-50'>
                <div>
                  {item.productData?.rating !== undefined ? (
                    <div className='md:pb-4'>
                      <ProductRating
                        reviewAnchorId={reviewAnchorId}
                        product={item}
                        direction={'row'}
                      />
                    </div>
                  ) : null}
                </div>
                <div className='absolute top-4 right-4 md:relative md:top-auto md:right-auto md:pb-4'>
                  <ItemCardTopArea
                    onWishlistButtonClick={onAddWishlistProduct}
                    item={item}
                    itemType={itemType}
                    reviewAnchorId={reviewAnchorId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='px-4' id='overview' style={{ scrollMarginTop: '130px' }}>
        <div className={`${itemType === GatsbyNodeType.PRODUCT ? 'grid md:grid-cols-12 md:grid-flow-row-dense md:gap-6 md:pt-8' : ''} xl:container`}>

          {/** the short description */}
          {shortDescription ? (
            <div className='order-2 md:order-none md:col-span-6 lg:col-span-7 xl:col-span-8'>
              <div className='pb-4 md:pb-8 text-neutral-900 dark:text-neutral-50'>
                <h2 className='font-semibold pb-3 hidden md:block md:pb-5 md:font-bold text-h4 md:text-h2'>
                  <Localized
                    dictKey={'shop:screen-content-item.shortDescription.title'}
                  />
                </h2>
                <p className='text-p2'>{shortDescription}</p>
              </div>
            </div>
          ) : null}

          {itemInformation ? (
            <div className='order-1 md:order-none md:row-span-2 md:col-span-6 lg:col-span-5 xl:col-span-4'>
              {itemInformation || null}
            </div>
          ) : null}

          {/** the description */}
          {description ? (
            <div className={`${itemType === GatsbyNodeType.PRODUCT ? 'border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 md:border-none' : ''} order-6 md:pt-0 md:order-none md:col-span-6 lg:col-span-7 xl:col-span-8`}>
              <div className='text-neutral-900 dark:text-neutral-50'>

                {itemType === GatsbyNodeType.PRODUCT ? (
                  <h2 className='font-medium text-p2 pb-3 relative md:pb-5 md:font-bold md:text-h2' onClick={toggleDropdownClass}>
                    <span className='text-accent-500 mr-2 align-text-bottom md:hidden'>
                      <InfoOutlinedIcon />
                    </span>
                      <Localized
                        dictKey={'shop:screen-content-item.description.title'}
                      />
                    <span className={`absolute right-0 top-[2px] md:hidden ${isDropdown ? 'rotate-180' : '' }`}>
                      <ArrowDropDown />
                    </span>
                  </h2>
                ) : null}

                <div className={`${itemType === GatsbyNodeType.PRODUCT ? 'mb-6 md:block md:mb-0' : ''} ${isDropdown ? 'block' : 'hidden' }`}>
                  <div className='flex flex-col gap-4 text-p2'>
                    <p className={`${isExpanded ? 'line-clamp-3' : ''}`}>
                      {description}
                    </p>
                    <p>
                      <ButtonTw
                        variant={'text-underline'}
                        onClick={toggleExpandReadMore}
                      >
                        {isExpanded ? 'Read more' : 'Read less'}
                      </ButtonTw>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/** the content */}
          {children ? <>{children}</> : null}
        </div>
      </section>

      {/** the stuff underneath the glass container */}
      {extraChildren ? <>{extraChildren}</> : null}
    </>
  );
};
