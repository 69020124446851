import { graphql, useStaticQuery } from 'gatsby';
import { SourcedGQLCity } from 'src/app/types';

interface CitiesQuery {
  allCity: {
    nodes: SourcedGQLCity[];
  };
}

export const allCityQuery = graphql`
  {
    allCity {
      nodes {
        cityData {
          inhabitantCount
        }
        continentId
        countryAreaId
        countryId
        fields {
          activityCount
        }
        id
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        localizations {
          localeId
          locales {
            description
            name
            shortDescription
            teaser
          }
          seo {
            description
            title
          }
        }
        locations {
          id
        }
        mapData {
          zoomFactor
        }
        # mapData {
        #   markers {
        #     localizations {
        #       localeId
        #       locales {
        #         description
        #         name
        #         label
        #         shortDescription
        #         teaser
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export function useSourcedCities(): SourcedGQLCity[] {
  const { allCity } = useStaticQuery<CitiesQuery>(allCityQuery);

  return allCity?.nodes || [];
}
