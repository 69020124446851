import * as React from 'react';
// import ReactCrop, { Crop } from 'react-image-crop';
import { Resolution } from 'src/app/types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

import {
  Button,
  ButtonGroup,
  Glass,
  Grid,
  ImageFileInformation,
  LoadingIndicator,
  Typography,
  TextInputField,
} from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
// import { OrganisationAdminDialogType } from 'src/app.organisation/constants';
import { PrevIcon } from '@material-ui/icons/NavigateBefore';
import { useStyles } from 'src/app/context';
import { ImagePreviewEditContainer } from '../imagePreviewEditContainer/ImagePreviewEditContainer';

interface Crop {}

export interface ImageFilePickerWidgetProps {
  children: React.ReactNode;
  index: number;
  initialFileUrl?: string;
  noUpload: boolean; // TODO: find out what for
  onCancel?: () => void;
  onRemove?: () => void;
  onSelectImageFile?: (file: File) => void;
  onUploadImage: (file: File) => void;
  storagePath: string; // TODO: is this used / where?
  showPreview?: string; // TODO: can be imgUrl?? => check where is used..
  // TODO: pass fb storage url
}

// TODO: handle downloads
// Base of/SIMILAR TO ImageUpload.tsx

/**
 * ImageFilePickerWidget
 * @param { ImageFilePickerWidgetProps } props
 * @version 0.0.0
 * @description Base of/SIMILAR TO ImageUpload.tsx - OLD use ImageFilePicker
 * @todo refactor + delete/replace ImageUpload.tsx
 * @example
 * @deprecated OLD use ImageFilePicker.tsx instead
 */
const ImageFilePickerWidget = ({
  children,
  index,
  initialFileUrl,
  noUpload,
  onCancel,
  onRemove,
  onSelectImageFile,
  onUploadImage,
  showPreview,
  storagePath,
  title,
}: ImageFilePickerWidgetProps) => {
  const styles = useStyles();
  // const { currentOrganisation } = useAppContext();
  // const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [hasUploadSuccess, setHasUploadSuccess] = React.useState(false);
  const [naturalResolution, setNaturalResolution] = React.useState<Resolution | null>(
    null,
  );
  const [imageContainerResolution, setImageContainerResolution] =
    React.useState<Resolution | null>(null);
  const [cropDimensions, setCropDimensions] = React.useState<Resolution | null>(null);

  const minWidth = 1230;
  const minHeight = 600;

  // 1230x600 Product title
  // 1280x720 - 16:9
  const [crop, setCrop] = React.useState<Crop>({
    width: 0,
    height: 0,
    unit: 'px', // | '%'
  });
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [fileUrl, setFileUrl] = React.useState<string | null>(initialFileUrl || null);

  React.useEffect(() => {
    // console.log('triggered imageContainerResolution: ', imageContainerResolution);
    // console.log('triggered naturalResolution: ', naturalResolution);
    // console.log('triggered crop: ', crop);
    if (
      !cropDimensions &&
      imageContainerResolution &&
      naturalResolution &&
      crop.unit === 'px' &&
      crop.width === 0 &&
      crop.height === 0
    ) {
      console.log('eff imageContainerResolution: ', imageContainerResolution);
      console.log('eff naturalResolution: ', naturalResolution);
      console.log('eff crop: ', crop);
      const w = parseInt((1230 / (naturalResolution.width / 100)).toFixed(0), 10);
      const h = parseInt((600 / (naturalResolution.height / 100)).toFixed(0), 10);
      console.log('w: ', w);
      console.log('h: ', h);
      const cropData: Crop = {
        width: w,
        height: h,
        unit: '%', // 'px' | '%'
      };
      console.log('cropData: ', cropData);
      setCropDimensions({
        height: h,
        width: w,
      });
      setCrop(cropData);
    }
  }, [imageContainerResolution, naturalResolution, crop, cropDimensions]);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file = e.target.files[0] || null; // TODO: no multi file support atm
      if (!file) {
        return;
      }

      const fileData = await file; // FIXME: not sure

      setSelectedFile(fileData);
      setHasUploadSuccess(false);

      console.log('selected file: ', file);

      const url = URL.createObjectURL(file);
      setFileUrl(url);

      // const image = new Image();
      // const fileReader = new FileReader();

      // fileReader.onload = () => {
      //   if (fileReader !== null && typeof fileReader.result === 'string') {
      //     image.src = fileReader.result;
      //   }
      // };
      // fileReader.readAsDataURL(file);

      // image.onload = async () => {
      //   const { naturalHeight, naturalWidth } = image;
      //   setNaturalResolution({ height: naturalHeight, width: naturalWidth });
      //   console.log('image on load image naturalHeight: ', naturalHeight);
      //   console.log('image on load image naturalWidth: ', naturalWidth);
      // };

      if (!onSelectImageFile) {
        return;
      }

      onSelectImageFile(file);
    }
  };

  // const onUploadFile = async () => {
  //   console.log('start uploading - selectedFile: ', selectedFile);
  //   setIsUploading(true);
  //   if (!selectedFile) {
  //     return;
  //   }

  //   console.log('selected file: ', selectedFile);

  //   const fileName = selectedFile.name || 'new_image';
  //   // const fileType = selectedFile.type;
  //   // const data = await file.arrayBuffer();
  //   const selectedfileData = await selectedFile;

  //   const filePath = `${storagePath}/${fileName}`;

  //   console.log('start upload, filepath: ', filePath);
  //   // Create a child reference
  //   const storageFileRef = firebase.storage().ref().child(filePath);

  //   await storageFileRef.put(selectedfileData).then(async (snapshot) => {
  //     console.log('Uploaded a blob or file - snapshot.metadata: ', snapshot.metadata);
  //     setIsUploading(false);
  //     setHasUploadSuccess(true);
  //     setSelectedFile(null);
  //     setFileUrl(null);

  //     // const metaData = {
  //     //   // bucket	string	NO
  //     //   // generation	string	NO
  //     //   // metageneration	string	NO
  //     //   // fullPath	string	NO
  //     //   // name	string	NO
  //     //   // size	number	NO
  //     //   // timeCreated	string	NO
  //     //   // updated	string	NO
  //     //   // md5Hash	string	YES on upload, NO on updateMetadata
  //     //   // cacheControl	string	YES
  //     //   // contentDisposition	string	YES
  //     //   // contentEncoding	string	YES
  //     //   // contentLanguage	string	YES
  //     //   // contentType	string	YES
  //     //   // customMetadata	Object containing string->string mappings	YES
  //     // };
  //     // await storageFileRef
  //     //   .updateMetadata(metaData)
  //     //   .then((newMeta) => {
  //     //     console.log('uploaded - file metadata:', newMeta);
  //     //   })
  //     //   .catch((error) => {
  //     //     console.warn('Upload error: ', error);
  //     //   })
  //     //   .finally(() => {
  //     //     setIsUploading(false);
  //     //   });
  //   });

  //   // const uploadImage = firebase
  //   // .functions()
  //   // .httpsCallable('organisationFunction-uploadImage');
  //   // const data = await file.text()
  //   // console.log('uploadData: ', data);
  //   // const res = await uploadImage({base64ImgStr: data});
  //   // console.log('res: ', res);

  //   // //read data from the blob objects(file)
  //   // let reader = new FileReader();

  //   // //reads the binary data and encodes it as base64 data url
  //   // reader.readAsDataURL(file);

  //   // //reads it finish with either success or failure
  //   // reader.onloadend = (reader: FileReader, event: ProgressEvent<FileReader>): any => {
  //   //   //reader.result is the result of the reading in base64 string
  //   //   setFileName(file.name);
  //   //   setSelectedFile(reader.result);
  //   //   // });
  //   //   //   this.sendImageData()
  //   // };
  // };

  const onCancelButtonClick = () => {
    setSelectedFile(null);
    setFileUrl(initialFileUrl || null);
    setHasUploadSuccess(false);
    if (!onCancel) {
      return;
    }
    onCancel();
  };

  const onRemoveButtonClick = () => {
    setSelectedFile(null);
    setFileUrl(initialFileUrl || null);
    setHasUploadSuccess(false);
    if (!onRemove) {
      return;
    }
    onRemove();
  };

  const onUploadButtonClick = () => {
    if (!noUpload && onUploadImage && selectedFile) {
      onUploadImage(selectedFile);
    }
  };

  const onUpdateStorageFileName = () => {};

  const isWidthValid = !!(naturalResolution && naturalResolution.width >= minWidth);
  const isHeightValid = !!(naturalResolution && naturalResolution.height >= minHeight);
  const isSelectedFileImageValid = isWidthValid && isHeightValid;

  // console.log('ImageFilePicker fileUrl:', fileUrl);
  // console.log('ImageFilePicker showPreview:', showPreview);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup fullWidth>
          {children}
          <Button fullWidth color={'primary'} variant='contained' component='span'>
            <label htmlFor={`upload-image ${index}`} style={{ width: '100%' }}>
              <input
                style={{ display: 'none', width: '100%' }}
                id={`upload-image ${index}`}
                name={`upload-image ${index}`}
                type={'file'}
                className='fileUpload'
                onChange={onSelectFile}
                accept={'image/jpeg, image/png, image/webp'}
              />
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.flex__row}>
                    <InsertPhotoIcon />
                    <Typography >
                      {'Select a file (10mb)'}
                      {/* {selectedFile ? `${selectedFile.name}` : 'Select image'} */}
                      {/* {hasUploadSuccess ? 'Select another image' : 'Select image'} */}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </label>
          </Button>
          {noUpload ? null : (
            <Button
              disabled={!selectedFile}
              onClick={onUploadButtonClick}
              color={'primary'}
              variant='contained'
              size={'small'}
            >
              <Typography >
                <CloudUploadIcon />
              </Typography>
            </Button>
          )}
          <Button
            // disabled={!selectedFile}
            onClick={
              selectedFile && typeof onRemove !== 'undefined'
                ? onRemoveButtonClick
                : onCancelButtonClick
            }
            color={'primary'}
            variant='contained'
            size={'small'}
            startIcon={<DeleteForeverIcon />}
          >
            <Typography >
              {selectedFile ? ' Cancel' : 'Remove'}
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>
      {/* {hasUploadSuccess ? (
        <Grid item xs={12}>
          <Typography paragraph>Image successfully uploaded</Typography>
        </Grid>
      ) : null} */}
      {/* {isUploading ? (
        <Grid item xs={12}>
          <Typography paragraph>Image is uploading..</Typography>
          <LoadingIndicator />
        </Grid>
      ) : null} */}
      {/** TODO preview accordion when file is selecten underneath */}
      <>
        {!hasUploadSuccess ? (
          <>
            {/* {selectedFile ? (
                <Grid item xs={12}>
                  <ImageFileInformation resolution={naturalResolution} file={selectedFile} />
                </Grid>
              ) : null} */}
            {(selectedFile && fileUrl) || showPreview ? ( // MESSY
              <Grid item xs={12}>
                <ImagePreviewEditContainer
                  file={selectedFile}
                  fileUrl={fileUrl || showPreview} // FIXME: messy
                  updateStorageFileName={onUpdateStorageFileName}
                />
              </Grid>
            ) : null}
            {/* {selectedFile && fileUrl ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {!isWidthValid ? (
                    <Grid item xs={12}>
                      <Typography align={'center'} color={'error'}>
                        {`The image must be at least ${minWidth}px wide`}
                      </Typography>
                    </Grid>
                  ) : null}
                  {!isHeightValid ? (
                    <Grid item xs={12}>
                      <Typography align={'center'} color={'error'}>
                        {`The image must be at least ${minHeight}px tall`}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      fullWidth
                      disabled={!isSelectedFileImageValid}
                      onClick={onUploadFile}
                    >
                      <Typography >Upload image</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}
          </>
        ) : null}
      </>
    </Grid>
  );
};

export { ImageFilePickerWidget };
