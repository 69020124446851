import * as React from 'react';
import { useFormContext, FieldValues } from 'react-hook-form';

import {
  ButtonTw,
  DebugObject,
  TextInputField,
} from 'src/app/components';

export const FormDebug = (props: { defaultValues: FieldValues }) => {
  const { defaultValues } = props;
  const [jsonString, setJsonString] = React.useState<string | null>(null);
  const [importJsonString, setImportJsonString] = React.useState<string | null>();
  const formMethods = useFormContext();
  const {
    setValue,
    watch,
    formState: { errors },
    reset,
  } = formMethods;

  const getErrorMessagesAsArray = (
    obj: Record<string, any>,
  ): Record<string, string[]>[] => {
    const res = [];

    const objKeys = Object.keys(obj);

    for (let objKeyIdx = 0; objKeyIdx < objKeys.length; objKeyIdx++) {
      const key = objKeys[objKeyIdx];
      if (obj?.[key]?.message) {
        res.push({
          [key]: [obj?.[key]?.message],
        });
      } else {
        const deeperErrorMessages = getErrorMessagesAsArray(obj?.[key]);
        console.log('xxx deeperErrors: ', deeperErrorMessages);
        if (deeperErrorMessages?.length) {
          res.push({
            [key]: [...deeperErrorMessages],
          });
        }
      }
    }
    return res;
  };

  console.log('xxx errors: ', errors);
  console.log('xxx getErrorMessagesAsArray: ', getErrorMessagesAsArray(errors));
  // const watchShowAge = watch('showAge', false); // you can supply default value as second argument
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // const watchFields = watch(['showAge', 'number']); // you can also target specific fields by their names
  console.log('!!!watchAllFields:', watchAllFields);

  const onGenerateJSON = () => {
    setJsonString(JSON.stringify(watchAllFields));
  };

  const onImportJSON = () => {
    if (!importJsonString) {
      return;
    }

    console.log('importing JSON string: ', JSON.parse(importJsonString));

    // setValue('', JSON.parse(importJsonString), {
    //   shouldDirty: true,
    // });

    reset(JSON.parse(importJsonString)); // keepStateOptions are available
  };
  return (
    <div className='flex flex-col gap-y-4 dark:text-neutral-50'>
      <div>
        <h3 className='text-h4 font-semibold text-center'>
          Default values debug
        </h3>
      </div>
      <div className='p-4'>
        <DebugObject collapsed={0} isDebug object={defaultValues || {}} />
      </div>
      <div>
        <h3 className='text-h4 font-semibold text-center'>
          Form debug
        </h3>
      </div>
      <div style={{ padding: '16px' }}>
        <DebugObject collapsed={0} isDebug object={watchAllFields || {}} />
      </div>
      <div>
        <h3 className='text-h4 font-semibold text-center'>
          Errors debug
        </h3>
      </div>
      <div className='p-4'>
        <DebugObject
          collapsed={0}
          isDebug
          object={{ errors: getErrorMessagesAsArray(errors) } || {}}
        />
      </div>
      <div>
        <div className='flex flex-wrap gap-4 items-center justify-center'>
          <TextInputField
            variant={'outlined'}
            color={'primary'}
            label={'JSON input'}
            size={'large'}
            fullWidth
            onChange={(e) => setImportJsonString(e.target.value)}
            multiline
          />
          <ButtonTw size={'lg'} rounded variant='accent' onClick={onImportJSON}>
            Import JSON
          </ButtonTw>
          <ButtonTw size={'lg'} rounded variant='accent' onClick={onGenerateJSON}>
            Get JSON
          </ButtonTw>
        </div>
      </div>
      {jsonString ? (
        <div className='p-4'>
          {jsonString}
        </div>
      ) : null}
    </div>
  );
};
