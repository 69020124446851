import * as React from 'react';
import MToolbar, { ToolbarProps as MToolbarProps } from '@material-ui/core/Toolbar';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';

export interface ToolbarProps extends CombinedBaseComponentProps<MToolbarProps> {}

/**
 * Toolbar / WAPToolbar - MUI
 * @param { ToolbarProps } props
 * @version 0.0.0
 * @description Applies spacing on child items
 * @todo
 * @example
 */
const Toolbar = (props: ToolbarProps) => {
  const { children, className, ...passProps } = props;
  const cls = useStyles();
  return (
    <MToolbar className={`${cls.toolbar} ${className}`} {...passProps}>
      {children}
    </MToolbar>
  );
};

const WAPToolbar = (props: CombinedAppProps<ToolbarProps>) =>
  withoutAppProps<CombinedAppProps<ToolbarProps>, ToolbarProps>(Toolbar, props);

export { Toolbar, WAPToolbar };
