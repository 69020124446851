import * as React from 'react';
import {
  useAuthState,
  useDebugState,
  useDialogState,
  useNotificationState,
  useCurrencyState,
  usePasswordState,
  useGDPRState,
  useAppStaticQueryConfig,
} from 'src/app/hooks';

import { AppContext } from 'src/app/types';
import { FirebaseApp } from 'firebase/app';

const Context = React.createContext<AppContext | null>(null);

export const useAppContext: () => AppContext = (): AppContext =>
  React.useContext<AppContext | null>(Context) as AppContext;

export function AppContextProvider({
  children,
  firebaseApp,
}: {
  children: React.ReactNode;
  firebaseApp: FirebaseApp;
}): React.ReactElement | null {
  const appConfig = useAppStaticQueryConfig();
  const [payBoxVisible, setPayBoxVisible] = React.useState(false);
  // TODO: optimize with useMemo/CB ?? => yes but how?? => https://www.robinwieruch.de/react-usecallback-hook
  return (
    <Context.Provider
      value={{
        firebase: firebaseApp, // TODO - keep?
        ...useAuthState(firebaseApp),
        ...useDebugState(),
        ...useDialogState(),
        ...useNotificationState(),
        ...useCurrencyState(),
        ...usePasswordState(),
        ...useGDPRState(), // General Data Protection Regulation
        ...{ payBoxVisible, setPayBoxVisible },
        systemId: appConfig.systemId || 'holiday', // FIXME: the value should come from BE / fb appConfig?
      }}
    >
      {children}
    </Context.Provider>
  );
}
