import * as React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { Grid, Localized, Typography } from 'src/app/components';

import { useStyles } from 'src/app/context';

export interface FieldErrorsProps {
  name: string;
  i18nKey: string;
}

export const FieldErrors = ({ name, i18nKey }: FieldErrorsProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const styles = useStyles();
  const hasError = !!errors?.[name];
  return hasError ? (
    <Grid item xs={12}>
      <Grid container className={clsx({ [styles.px__4]: true })}>
        {/* <Grid item xs={12}>
          <Typography align={'center'} color={'error'}>
            This field has an error...
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography align={'center'} color={'error'}>
            <Localized dictKey={`${i18nKey}.error.${errors?.[name].message || ''}`} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};
