import * as React from 'react';

import { ButtonTw, Localized } from 'src/app/components';
import { ShoppingCartConfigurationItemView } from 'src/app.consumer/components';
import { ShoppingCartItemConfiguration } from 'src/app/types';
import { useLocalizedNavigation, useAppContext } from 'src/app/hooks';

const i18nPath = 'shop:screen-product';

export interface AddToCartConfirmationProps {
  itemConfigurations: ShoppingCartItemConfiguration[];
}

export const AddToCartConfirmation = (props: AddToCartConfirmationProps) => {
  const { itemConfigurations } = props;
  const { closeNotification } = useAppContext();
  const { navigateLocalized } = useLocalizedNavigation();
  const onShoppingCartNavigationClick = () => {
    navigateLocalized('/shopping-cart');
    closeNotification();
  };
  return (
    <div className='flex flex-col gap-y-8 py-8 md:p-8'>
      <h3 className='text-h4 text-center text-accent-500 font-semibold'>
        <Localized dictKey={`${i18nPath}.add-cart-confirmation.title`} />
      </h3>
      <div>
        {itemConfigurations.map((itemConfig) =>
          itemConfig.count ? (
            <ShoppingCartConfigurationItemView
              key={itemConfig.priceGroupId}
              itemCartData={itemConfig}
            />
          ) : null,
        )}
      </div>
      <div className='text-center'>
        <ButtonTw variant={'accent'} rounded size='lg' className='w-full md:w-auto md:min-w-[270px]' onClick={onShoppingCartNavigationClick}>
          <Localized dictKey={`${i18nPath}.add-cart-confirmation.cart-link-label`} />
        </ButtonTw>
      </div>
    </div>
  );
};
