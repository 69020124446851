import accountBilling from './accountBilling.json';
import accountProfile from './accountProfile.json';
import accountSettings from './accountSettings.json';
import contact from './contact.json';
import shoppingCart from './shopping-cart.json';
import experience from './experience.json';
import helpCustomer from './help.customer.json';
import helpOrganisation from './help.organisation.json';
import shop from './shop.json';

export default {
  '/': shop,
  '/account/billing': accountBilling,
  '/account/profile': accountProfile,
  '/account/settings': accountSettings,
  '/contact': contact,
  '/experience': experience,
  '/help/customer': helpCustomer,
  '/help/organisation': helpOrganisation,
  '/shopping-cart': shoppingCart,
};
