import * as React from 'react';
import DocumentEditIcon from '@material-ui/icons/BorderColorOutlined';

// import DocumentDeleteIcon from '@material-ui/icons/DeleteForever';
// import DocumentCreateIcon from '@material-ui/icons/NoteAdd';
// import DocumentPreviewIcon from '@material-ui/icons/FindInPage';
// import DocumentPageIcon from '@material-ui/icons/MenuBook';

import {
  ButtonTw,
  Localized,
} from 'src/app/components';

import { ProductEventParticipant } from 'src/app/types';
import { useStyles } from 'src/app/context';

const i18nPath = 'shop:screen-product';
const participantsI18nPath = 'shop:participants';

interface ParticipantListItemViewProps {
  index: number; // for display
  isRequestingUpdate: boolean;
  participant: ProductEventParticipant;
  priceGroupId: string;
  onEditParticipantClick: () => void;
}

export const ParticipantListItemView = ({
  index,
  isRequestingUpdate,
  participant,
  priceGroupId,
  onEditParticipantClick,
}: ParticipantListItemViewProps) => {
  const styles = useStyles();

  return (
    <div className='flex gap-y-4 text-p3 justify-between sm:text-p2'>
      <div className='w-1/4'>
        <p>
          {`${index + 1}. `}
          <em>
            <Localized dictKey={`${i18nPath}.price-group.${priceGroupId}.label`} />
          </em>
        </p>
      </div>
      <div className='w-1/2'>
        {participant && (participant.firstName || participant.lastName) ? (
          <div className='flex gap-2'>
            {participant.firstName ? (
              <div className='w-1/2'>
                <p>{participant.firstName}</p>
              </div>
            ) : null}
            {participant.lastName ? (
              <div className='w-1/2'>
                <p>{participant.lastName}</p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className='w-1/4 text-right'>
        <ButtonTw
          disabled={isRequestingUpdate}
          onClick={onEditParticipantClick}
          variant={'text'}
        >
          <DocumentEditIcon className='!text-p2 mr-2' />
          <Localized
            dictKey={`${participantsI18nPath}.participant.action.edit.label`}
          />
        </ButtonTw>
      </div>
    </div>
  );
};
