/**
 * Localizations of the app it self
 */
export enum AppLocale {
  DE = 'de',
  EN = 'en',
  HR = 'hr',
  FR = 'fr',
}

/**
 * The default fallback locale
 */
export const defaultLocale = AppLocale.EN;

/**
 * Locale resource namespaces
 */
export enum LocaleNamespaces {
  country = 'country',
  currency = 'currency',
  gdpr = 'gdpr',
  organisation = 'organisation',
  product = 'product',
  shop = 'shop',
  system = 'system',
  account = 'account',
  common = 'common', // rename to shared?
  dynamicLocales = 'dynamicLocales', // REMOVE?
  meta = 'meta',
  staticLocales = 'staticLocales',
  webapp = 'webapp', // REMOVE
}

// export interface LocaleNamespaces {
//   // composed files
//   organisation?: {};
//   shop?: {};
//   system?: {};
//   // json files
//   account?: {};
//   common?: {};
//   currencies?: {};
//   dynamicLocales?: {};
//   meta?: {};
//   staticLocales?: {};
//   webapp?: {};
// }

/**
 * Localizations available for selection (Org/Sys-Admin)
 */
export enum Locale {
  DE = 'de',
  EN = 'en',
  HR = 'hr',
  FR = 'fr',
}

export enum FlagCountryId {
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  HR = 'HR',
  IT = 'IT',
  RU = 'RU',
  US = 'US',
}
