import * as React from 'react';

// import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import { useStyleContext } from 'src/app/context';
import { ThemeMode } from 'src/app.consumer/constants';

import { BaseCSS } from './BaseCSS';

import { lightMuiTheme, darkMuiTheme } from './muiTheme';

// NOTE extra css imports
// import 'react-image-crop/dist/ReactCrop.css'; // FIXME: only necessary for org + sys admin => not really used - try to avoid - maybe source of bugs/CSP

// NOTE: we set a custom variable for .fullheight class in mui theme css - which we update here
function updateDocHeight() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
}

function subscribeToWindowEvents(): void {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', updateDocHeight);
    window.addEventListener('orientationchange', updateDocHeight);
  }
}

function unSubscribeFromWindowEvents(): void {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', updateDocHeight);
    window.removeEventListener('orientationchange', updateDocHeight);
  }
}

const ThemeProvider: React.FC = ({ children }) => {
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
  const { themeMode } = useStyleContext();
  const theme = themeMode === ThemeMode.LIGHT ? lightMuiTheme : darkMuiTheme;
  React.useEffect(() => {
    if (!isInitialized) {
      updateDocHeight();
      subscribeToWindowEvents();
      setIsInitialized(true);
    }
    return function cleanup() {
      unSubscribeFromWindowEvents();
    };
  }, [isInitialized, setIsInitialized]);
  return (
    <MuiThemeProvider theme={theme}>
      {/* <StyledComponentsThemeProvider theme={StyleComponentProviderTheme}> */}
     {/*  <StylesProvider injectFirst>
        <CssBaseline />
        <BaseCSS /> */}
        {children}
       {/* </StylesProvider> */}
      {/* </StyledComponentsThemeProvider> */}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
