import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  ButtonTw,
  DialogLayout,
  DebugObject,
  TextInputField,
} from 'src/app/components';
import { KeyboardDatePicker } from '@material-ui/pickers'; // TODO: create proxy component?

import CancelIcon from '@material-ui/icons/Block';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ImportDataMode } from 'src/app/constants';
import { useReadXlsxFile, useAppContext } from 'src/app/hooks';
import {
  // BasePickupLocation,
  ParticipantImportData,
  ParticipantDialogParams,
  ProductEventParticipant,
  ParticipantPickupAddressInformation,
} from 'src/app/types';
import { useLocalizationContext, useStyles } from 'src/app/context';
import { ProductParticipantOptions } from 'src/app/components/Product/productConfiguration/components/priceGroupControl/ProductParticipantOptions';
import { ParticipantPickupAddress } from 'src/app/components/Product/productConfiguration/components/priceGroupControl/ParticipantPickupAddress';

// import { TableHeader } from './TableHeader';
// import { TableActions } from './TableActions';
// import { ParticipantsRow } from './ParticipantsRow';

const i18nPath = 'shop:participants';

const hasFieldProperty = (id: string, fieldOptions: { fieldPropertyId: string }[]) =>
  !!fieldOptions?.find((opt) => opt.fieldPropertyId === id);

export interface ParticipantDialogProps extends ParticipantDialogParams {
  onCloseDialog: () => void;
}

export const ParticipantDialog = ({
  children,
  fieldOptions,
  participant,
  //
  onSaveParticipant,
  onSetParticipantPickupAddress,
  onCloseDialog,
  //
  locationOptions,
  foodOptions,
  languageOptions,
  product,
  //
  showPickup,
  showFood,
  showLanguage,
}: ParticipantDialogProps) => {
  const { isAppDebug } = useAppContext();
  const [participantState, setParticipantState] =
    React.useState<ProductEventParticipant>(participant);
  const [isEditAddress, setIsEditAddress] = React.useState<boolean>(false);

  const styles = useStyles();
  const { language } = useLocalizationContext();

  const onUpdateParticipant = (
    participantId: string,
    // priceGroupId: string,
    propName: string, // how to handle options props? - dot separated?
    value: unknown,
  ) => {
    if (!propName) {
      return;
    }
    setParticipantState({ ...participantState, [propName]: value });
  };

  const onSelectPickupOption = (participantId: string, pickupOptionIdx: number) => {
    if (!participantId || Number.isNaN(pickupOptionIdx)) {
      return;
    }

    const pickupOptionId = locationOptions[pickupOptionIdx].id;

    if (!pickupOptionId) {
      return;
    }

    setParticipantState({
      ...participantState,
      options: {
        ...participantState.options,
        pickup: pickupOptionId,
      },
    });
  };

  const onSelectFoodOption = (participantId: string, foodOptionIdx: number) => {
    if (!participantId || Number.isNaN(foodOptionIdx)) {
      return;
    }

    const foodOptionId = product.foodOptions.options[foodOptionIdx].localizations.reduce(
      (opt: string, loc: any) => {
        let name = '';
        if (!opt) name = loc?.locales?.name;
        else name = opt;
        if (loc?.localeId === language) name = loc?.locales?.name;
        return name;
      },
      null,
    );

    if (!foodOptionId) {
      return;
    }

    setParticipantState({
      ...participantState,
      options: {
        ...participantState.options,
        food: foodOptionId,
      },
    });
  };

  const onSelectLanguageOption = (participantId: string, languageOptionIdx: number) => {
    if (!participantId || Number.isNaN(languageOptionIdx)) {
      return;
    }

    const languageOptionId = languageOptions[languageOptionIdx].id;

    if (!languageOptionId) {
      return;
    }

    setParticipantState({
      ...participantState,
      options: {
        ...participantState.options,
        language: languageOptionId,
      },
    });
  };

  const onAddressAddOption = (participantId: string, addressData: any) => {
    if (!participantId || !addressData) {
      return;
    }

   participantState.pickupAddress=addressData
    setParticipantState({
      ...participantState,
      options: {
        ...participantState.options,
    
      },
    });
  };
  
  const inputVariant = 'outlined'; // TODO

  const hasPickupAddressInput = true; // TODO: only necessary if is not "base pickup location";

  // TODO: handle save/cancel display of pickupAddress

  return (
    <DialogLayout
      dialogDescription={'Description'}
      dialogControls={
        <div className='flex flex-col gap-y-4 items-center py-4'>
          <ButtonTw
            disabled={isEditAddress}
            onClick={() => {
              onSaveParticipant(participant.id, participantState);
              onCloseDialog();
            }}
            variant={'accent'}
            rounded
            size={'lg'}
            className='w-full md:w-auto md:min-w-[270px]'
          >
            Save
          </ButtonTw>

          <ButtonTw
            disabled={isEditAddress}
            onClick={onCloseDialog}
            variant={'text'}
            className='underline !font-semibold !text-accent-500 hover:!text-neutral-900 dark:hover:!text-neutral-50'
          >
            Cancel
          </ButtonTw>
        </div>
      }
    >
      <div className='py-4'>
        {isAppDebug ? (
          <div>
            <DebugObject isDebug={isAppDebug} object={participant} collapsed={0} />
          </div>
        ) : null}
        {isAppDebug ? (
          <div>
            <DebugObject isDebug={isAppDebug} object={fieldOptions} collapsed={0} />
          </div>
        ) : null}

        {isEditAddress ? null : (
          <>
            {hasFieldProperty('firstName', fieldOptions) ? (
              <div className='my-4'>
                <TextInputField
                  // disabled={isRequestingUpdate}
                  fullWidth
                  value={participantState?.firstName || ''}
                  label={'First name'}
                  variant={inputVariant}
                  placeholder={'Enter first name'}
                  onChange={(e) =>
                    onUpdateParticipant(participant.id, 'firstName', e.target.value)
                  }
                />
              </div>
            ) : null}

            {hasFieldProperty('lastName', fieldOptions) ? (
              <div className='my-4'>
                <TextInputField
                  // disabled={isRequestingUpdate}
                  fullWidth
                  value={participantState?.lastName || ''}
                  label={'Last name'}
                  variant={inputVariant}
                  placeholder={'Enter last name'}
                  onChange={(e) =>
                    onUpdateParticipant(participant.id, 'lastName', e.target.value)
                  }
                />
              </div>
            ) : null}

            {hasFieldProperty('dob', fieldOptions) ? (
              <div className='my-4'>
                <KeyboardDatePicker
                  // disabled={isRequestingUpdate}
                  value={participantState?.dob || new Date()}
                  onChange={(date, value) =>
                    onUpdateParticipant(participant.id, 'dob', value)
                  }
                  disableFuture
                  autoComplete={'dob'}
                  // name={'dob'}
                  // i18nKey={'dob'}
                  // i18nPath={'i18nBasePath'}
                  // datePickerMode={'DATE'}
                  // disableFuture
                  //
                  // hideTabs={!!hasToolbar} // TODO: pass down
                  variant={'inline'}
                  inputVariant='outlined'
                  // type={'date'}
                  //
                  // value={inputProps.value || ''} // like in other fields
                  // inputRef={ref}
                  fullWidth
                  // onChange={field.onChange}
                  // inputRef={field.ref}
                  // disableToolbar={!hasToolbar} // TODO: pass down
                  openTo={'year'}
                  // openTo={hasToolbar ? 'year' : 'day'} // TODO: pass down
                  autoOk
                  views={['year', 'month', 'date']}
                  // variant='dialog' // inline
                  // format='DD-MM-YYYY'
                  format='MM-DD-YYYY'
                  // value={field.value as Date}
                  InputAdornmentProps={{ position: 'start', color: 'primary' }}
                  // InputProps={{
                  //   endAdornment: <FieldAdornment hasError={hasError} />,
                  // }}
                  // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
                  label='Day of birth'
                  // openTo='day'
                  // helperText={error}
                  // InputProps={}
                  // ampm={!de} // => so.com
                  // style={{ paddingLeft: '16px' }}
                  // size={'small'}
                  // label={label || ''}
                  // anchorEl={}
                  // ampm={false}
                  // ampm={!de} // => so.com
                  // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
                  // minutesStep={5}
                  // {...inputProps}
                  // {...passProps}
                />
              </div>
            ) : null}
            <ProductParticipantOptions
              isRequestingUpdate={false}
              fieldOptions={fieldOptions}
              locationOptions={locationOptions}
              foodOptions={foodOptions}
              languageOptions={languageOptions}
              participant={participantState}
              product={product}
              // showPickup={showPickup}
              // showFood={showFood}
              // showLanguage={showLanguage}
              onSelectPickupOption={(idx) => onSelectPickupOption(participant.id, idx)}
              onSelectFoodOption={(idx) => onSelectFoodOption(participant.id, idx)}
              onSelectLanguageOption={(idx) =>
                onSelectLanguageOption(participant.id, idx)
              }
            />
          </>
        )}
        {hasPickupAddressInput ? (
          <ParticipantPickupAddress
            address={participant.pickupAddress} // NOTE: don't need to use participantState, since save provides update
            setAddress={(addressData) =>
              onAddressAddOption(participant.id, addressData)
            }
            isEditMode={isEditAddress}
            setIsEditMode={(isOn) => setIsEditAddress(isOn)}
          />
        ) : null}
      </div>
      {children ? (
        <div>
          {children}
        </div>
      ) : null}
    </DialogLayout>
  );
};
