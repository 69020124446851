import * as React from 'react';
import { CSSProperties } from '@material-ui/styles';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';

export interface HeroProps extends BaseComponentProps {
  // background-attachment: fixed; // TODO
  // backgroundSource?: string[] | string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  text?: string | string[];
}

/**
 * Hero / WAPHero
 * @param { HeroProps } props
 * @version 0.0.0
 * @description
 * @todo refactor
 * @example
 */
const Hero: React.FC<HeroProps> = (props: HeroProps) => {
  const { children } = props;

  return (
    <div
      className='font-body flex flex-col justify-end py-14 w-full relative bg-cover bg-center h-auto min-h-[calc(100vh-80px)] md:min-h-[620px] lg:h-[calc(100vh-300px)]
       before:inset-0 before:absolute before:z-0 before:bg-gradient-to-b before:from-[#0F1A3666] before:to-[#0F1A3610]'
      style={{ backgroundImage: "url('./backgroundViva.webp')" }}
    >
      <div className='xl:container relative'>{children}</div>
    </div>
  );
};

const WAPHero = (props: CombinedAppProps<HeroProps>) =>
  withoutAppProps<CombinedAppProps<HeroProps>, HeroProps>(Hero, props);

export { Hero, WAPHero };
