import * as React from 'react';

import { Grid } from 'src/app/components';
import { useStyles } from 'src/app/context';

export const TableRowLayout = ({
  firstChildren,
  secondChildren,
}: {
  firstChildren: React.ReactNode;
  secondChildren: React.ReactNode;
}) => {
  const styles = useStyles();
  return (
    <Grid container>
      <Grid item md={7} xs={12} className={styles.h__min__100}>
        {firstChildren}
      </Grid>
      <Grid item md={1} xs={12} className={styles.h__min__100} />
      <Grid item md={4} xs={12} className={styles.h__min__100}>
        {secondChildren}
      </Grid>
    </Grid>
  );
};
