import * as React from 'react';

import { Button, ButtonProps, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';

interface ButtonUploadProps extends Omit<ButtonProps, 'onChange' | 'title'> {
  accept?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  startIcon?: React.ReactNode;
  title: string | React.ReactNode;
}

// TODO: use ButtonFileSelect
export const ButtonUpload = ({
  accept,
  onChange,
  startIcon,
  title,
  ...restProps
}: ButtonUploadProps) => {
  const styles = useStyles();
  return (
    <Button
      fullWidth
      color={'primary'}
      variant='contained'
      component='span'
      // startIcon={startIcon}
      {...restProps}
    >
      <label htmlFor={`upload-image ${'index'}`} style={{ width: '100%' }}>
        <input
          style={{ display: 'none', width: '100%' }}
          id={`upload-image ${'index'}`}
          name={`upload-image ${'index'}`}
          type={'file'}
          className='fileUpload'
          onChange={onChange}
          accept={accept || '*/*'}
        />
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.flex__row}>
              {startIcon}
              {title && typeof title === 'string' ? (
                <Typography >{title}</Typography>
              ) : (
                title
              )}
            </div>
          </Grid>
        </Grid>
      </label>
    </Button>
  );
};
