import * as React from 'react';
import { PropTypes } from '@material-ui/core';

import LoginIcon from '@material-ui/icons/ExitToApp';

import { ButtonTw, ButtonProps, Localized } from 'src/app/components';
import { DialogType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import { CombinedAppProps } from 'src/app/types';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { useLocalizationContext } from 'src/app/context';

export interface LoginButtonProps extends ButtonProps {
  color?: PropTypes.Color;
  closeMenu: any;
}

/**
 * LoginButton / WAPLoginButton - Opens app login dialog
 * @param { LoginButtonProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoginButton = (props: LoginButtonProps) => {
  const { closeMenu } = props;
  const { openDialog: openModal } = useAppContext();
  const { language } = useLocalizationContext();
  const onClick = () => {
    openModal(DialogType.LOGIN);
    if (closeMenu) closeMenu();
  };
  return (
    <ButtonTw variant='text' onClick={onClick} className={'inline-flex items-center gap-1 text-p3 dark:text-neutral-50 !font-medium hover:text-accent-500 dark:hover:text-accent-500 transition duration-300'}>
      {/** Typography interface accepts no 'default' value... */}
      <LoginIcon />
      <span className='capitalize leading-none md:block hidden'>
        <Localized dictKey={'account:screen.screen-account-login.nav label'} />
      </span>
    </ButtonTw>
  );
};

const WAPLoginButton = (props: CombinedAppProps<LoginButtonProps>) =>
  withoutAppProps<CombinedAppProps<LoginButtonProps>, LoginButtonProps>(
    LoginButton,
    props,
  );

export { LoginButton, WAPLoginButton };
