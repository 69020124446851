import * as React from 'react';

import { Button } from 'src/app/components';

interface ButtonFormSubmitPRops {
  children?: React.ReactNode;
}

export const ButtonFormSubmit = ({ children }: ButtonFormSubmitPRops) => {
  return (
    <Button type={'submit'} variant={'outlined'} fullWidth>
      {children}
    </Button>
  );
};
