import * as React from 'react';

import { Grid, Localized, SearchFilter } from 'src/app/components';

import { useSourcedCities } from 'src/app/hooks';
// import { ThemeMode } from 'src/app.consumer/constants';

import { StyledComponent } from 'src/app/types';

export interface CityFilterProps extends StyledComponent {
  currentCityId: string;
  setCityId: (cityId: string) => void;
}

/**
 * SearchBar
 * @param { CityFilterProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const CityFilter: React.FC<CityFilterProps> = (props: CityFilterProps) => {
  const { currentCityId, setCityId, themeMode } = props;
  const cities = useSourcedCities();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SearchFilter
          filterTypeId={'city'}
          filterOptions={[...cities]}
          selectedFilterId={currentCityId || ''}
          setFilter={setCityId}
          // themeMode={themeMode || ThemeMode.LIGHT}
          placeholderLabel={
            <Localized dictKey={'shop:/.search-box.filter.city.placeholder'} />
          }
        />
      </Grid>
    </Grid>
  );
};
