import { graphql, useStaticQuery } from 'gatsby';
import { SourcedGQLCountry } from 'src/app/types';

interface CountriesQuery {
  allCountry: {
    nodes: SourcedGQLCountry[];
  };
}

const allCountryQuery = graphql`
  {
    allCountry {
      nodes {
        countryData {
          inhabitantCount
        }
        continentId
        fields {
          activityCount
        }
        id
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        localizations {
          localeId
          locales {
            description
            name
            teaser
          }
          seo {
            description
            title
          }
        }
      }
    }
  }
`;

export function useSourcedCountries(): SourcedGQLCountry[] {
  // const allCountryArea = {
  //   nodes: [],
  // };
  const { allCountry } = useStaticQuery<CountriesQuery>(allCountryQuery);
  return allCountry?.nodes || [];
}
