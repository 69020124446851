import * as React from 'react';

import { Localized } from 'src/app/components';

import { Price } from 'src/app/types';

import { createPriceStringWithCurrency, euroPriceAsCurrency } from 'src/lib/price';

const i18nPrefix = 'shop:screen-product';

interface GroupDiscountProps {
  totalDiscount: Price;
  currencyId: string;
}

export const GroupDiscount = ({ totalDiscount, currencyId }: GroupDiscountProps) => {
  return (
    <div className='flex gap-4 font-bold justify-between'>
      <div>
        <p>
          <Localized dictKey={`${i18nPrefix}.price-summary.groupDiscount-row.discount`} />
        </p>
      </div>
      <div>
        <p className='text-right'> 
          {createPriceStringWithCurrency(euroPriceAsCurrency(totalDiscount,currencyId), currencyId)}
        </p>
      </div>
    </div>
  );
};
