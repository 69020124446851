import * as React from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import { useAppContext } from 'src/app/hooks';

import {
  ButtonTw,
  Grid,
  LoadingIndicator,
  TextInputField,
  Localized,
  Typography,
} from 'src/app/components';

import { NotificationType } from 'src/app/constants';

// TODO: i18n
// TODO: wrap with form + validation
// dev email url https://travlr-dev.netlify.app/action?mode=resetPassword&oobCode=HSLq74vU4F4-LiZgL9jROsKpIbLpbXreBb8cOXZt6SMAAAF8n4-H1A&apiKey=AIzaSyAnDgWq0KuQkXAgawD7q1UVi_X1WVpzV5E&lang=en
export const SendPasswordEmailForm = () => {
  const { /* currentSystem, */ createNotification, firebase, user } = useAppContext(); // TODO: select systemId based on system user data

  const [emailInput, setEmailInput] = React.useState<string>('');

  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [isRequestSent, setIsRequestSent] = React.useState<boolean>(false);

  const onSubmitButtonClick = async () => {
    if (!firebase) {
      return;
    }

    setIsFetching(true);

    interface PublicRequestMessageData {
      systemId: string;
      name: string;
      email: string;
      phoneNumber?: string;
      // attachment?: string; // FIXME: BASE64 string (PDF/IMAGE) // TODO?
      body: string;
    }

    try {
      // const params: PublicRequestMessageData = {
      //   // collection: collectionPath,
      //   // messageId,
      //   // systemId,
      //   // responseText: messageText,
      //   // referenceId: bookingRefInput,
      //   systemId: 'holiday', // TODO: currentSystem => get via ENV
      //   name: nameInput,
      //   email: emailInput,
      //   phoneNumber: phoneInput,
      //   // attachment: attachmentText, // FIXME: BASE64 string (PDF/IMAGE) // TODO?
      //   body: messageInput,
      // };

      const actionCodeSettings: firebase.default.auth.ActionCodeSettings = {
        // url: 'https://travlr-dev.netlify.app/action', // set continue URL => important for App Version - right now through firebase console
      };

      await sendPasswordResetEmail(
        getAuth(firebase),
        emailInput /* , actionCodeSettings */,
      ); // TODO: leave away in localDev but inject into deploys?

      // TODO: i18n
      createNotification(
        NotificationType.SUCCESS,
        `A reset mail has been sent to ${emailInput}`,
        5000,
      );
      // setEmailInput(''); // do not reset input in order to display it
      setIsRequestSent(true);
      // navigate(`${routerBasePath}`);
    } catch (err) {
      console.warn('Message send error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsFetching(false);
    }
  };

  if (user?.uid) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align={'left'}>
            <Localized dictKey={'staticLocales:pages./lost-password.form.loggedIn'} />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (isFetching) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  if (isRequestSent) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align={'left'}>
            <Localized
              dictKey={`staticLocales:pages./lost-password.form.successMessage`}
              translationOptions={{ email: emailInput }}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className='flex flex-col gap-y-4'>
      <p>
        {<Localized dictKey={'staticLocales:pages./lost-password.form.info.label'} />}
      </p>
      <TextInputField
        fullWidth
        label={<Localized dictKey={'staticLocales:pages./lost-password.form.field.email'} />}
        variant={'outlined'}
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
      />
      <p className='text-center md:text-right'>
        <ButtonTw variant={'accent'} rounded size='sm' onClick={onSubmitButtonClick}>
          <Localized dictKey={'staticLocales:pages./lost-password.form.button.label'} />
        </ButtonTw>
      </p>
    </div>
  );
};
