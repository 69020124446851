import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Grid, Link, Localized, Toolbar, Typography } from 'src/app/components';

import { useAdminContext } from 'src/admin/context';

import { useStyles } from 'src/app/context';
import clsx from 'clsx';

export const OrganisationDocumentNotifications = () => {
  const styles = useStyles();
  const { document } = useAdminContext();
  const [isHidden, setIsHidden] = React.useState<boolean>(false);

  if (!document?.organisationUploadDocuments || isHidden) {
    return null;
  }

  if (
    typeof window !== 'undefined' &&
    window.location.pathname.startsWith('/admin/partner/documents')
  ) {
    return null;
  }

  return !document?.organisationUploadDocuments?.organisationInsuranceDocument ||
    !document?.organisationUploadDocuments?.organisationRegistrationDocument ||
    !document?.organisationUploadDocuments?.organisationLicenceDocument ? (
    <Grid container className={clsx(styles.formErrors__container, styles.p__2)}>
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              paragraph
              variant={'h6'}
              renderAs={'p'}
              align={'center'}
              color={'error'}
            >
              <Localized dictKey={'organisation:upload-documents-alert.title'} />:
            </Typography>
          </Grid>
          {!document.organisationUploadDocuments.organisationInsuranceDocument ? (
            <Grid item xs={12}>
              <Typography align={'center'} color={'error'}>
                <Localized dictKey={'organisation:upload-documents-alert.insuranceDocument'} />
              </Typography>
            </Grid>
          ) : null}
          {!document.organisationUploadDocuments.organisationRegistrationDocument ? (
            <Grid item xs={12}>
              <Typography align={'center'} color={'error'}>
                <Localized dictKey={'organisation:upload-documents-alert.registrationDocument'} />
              </Typography>
            </Grid>
          ) : null}
          {!document.organisationUploadDocuments.organisationLicenceDocument ? (
            <Grid item xs={12}>
              <Typography align={'center'} color={'error'}>
                <Localized dictKey={'organisation:upload-documents-alert.licenceDocument'} />
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} className={styles.my__2}>
            <Link href={'/admin/partner/documents'}>
              <Typography align={'center'} color={'error'}>
                <Localized dictKey={'organisation:upload-documents-alert.uploads'} />
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Grid item xs={12}>
          <Toolbar style={{ justifyContent: 'center' }}>
            <Button
              fullWidth
              variant={'text'}
              color={'secondary'}
              onClick={() => setIsHidden(true)}
              className={styles.h__min__100}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};
