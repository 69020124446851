import * as React from 'react';
import { navigate } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useAppContext } from 'src/app/hooks';
import { BaseComponentProps } from 'src/app/types';
import {
  AdminMenuScreenInformation,
  AdminToolbar,
  AdminNavigation,
} from 'src/admin/components';

import { SystemAdminScreen } from 'src/app.system/types';
import { OrganisationAdminScreen } from 'src/app.organisation/types';
import { ScreenNavigationConfigData } from 'src/admin/types';

export interface AdminLayoutContainerProps<T extends AdminScreenType>
  extends BaseComponentProps {
  basePath: string;
  currentScreen: T;
  i18nNS: string;
  screenNavigationConfig: ScreenNavigationConfigData<T>[];
  setCurrentScreen: (screenId: T) => void;
}

type AdminScreenType = OrganisationAdminScreen | SystemAdminScreen;

export const AdminLayoutContainer = <T extends AdminScreenType>(
  props: AdminLayoutContainerProps<T>,
) => {
  const {
    basePath,
    children,
    currentScreen,
    i18nNS,
    screenNavigationConfig,
    setCurrentScreen,
  } = props;
  const { openDialog, logout } = useAppContext();
  const theme = useTheme();

  // FIXME: do it based on path
  const curScreen = screenNavigationConfig.find((screen) => screen.id === currentScreen);

  const onAccountSettingsButtonClick = () => {
    navigate('/admin/partner/account'); // TODO: navigate also into admin/system/account
    // openDialog(DialogType.ACCOUNT);
  };

  const onLogoutButtonClick = () => {
    logout();
  };

  return (
    <>
      <AdminToolbar
        onAccountSettingsButtonClick={onAccountSettingsButtonClick}
        onLogoutButtonClick={onLogoutButtonClick}
      />
      <section className='py-9 md:py-16 px-4 dark:text-neutral-50'>
        <div className='xl:container'>
          <div className='flex flex-col gap-8 md:flex-row md:justify-between'>
            <div className='w-full md:w-1/4'>
              <AdminMenuScreenInformation
                icon={curScreen?.icon}
                i18nNS={i18nNS}
                screenId={currentScreen}
              >
              </AdminMenuScreenInformation>
              <AdminNavigation<T>
                basePath={basePath}
                currentScreen={currentScreen}
                i18nNS={i18nNS}
                screenNavigationConfig={screenNavigationConfig}
                setCurrentScreen={setCurrentScreen}
              />
            </div>
            <div className='w-full md:w-3/4'>
              {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
