import { getFirestore, doc, updateDoc } from 'firebase/firestore';

import { useAppContext } from 'src/app/hooks';
import { delay } from 'src/app/lib';

import {
  DocumentStateChangeSuccessNotification,
  DocumentStateChangeErrorNotification,
} from 'src/app/components';

import { ProductDocumentState, NotificationType } from 'src/app/constants';

type UseDocumentActionFunction = (
  id: string,
  collectionPath: string,
  onSuccess?: (() => void) | Promise<void>,
) => Promise<void>;

type UseDocumentActionRejectFunction = (
  id: string,
  collectionPath: string,
  rejectionMessage: string,
  onSuccess?: (() => void) | Promise<void>,
) => Promise<void>;

export interface UseDocumentActions {
  // USER ACTIONS
  setDeleteRequestDocumentStatus: UseDocumentActionFunction;
  setPublishRequestDocumentStatus: UseDocumentActionFunction;
  setRevokeDocumentStatus: UseDocumentActionFunction;

  // SYS ADMIN ACTIONS
  setPublishApprovedDocumentStatus: UseDocumentActionFunction;
  setPublishRejectedDocumentStatus: UseDocumentActionRejectFunction;
}

export const useDocumentActions = (): UseDocumentActions => {
  const { createNotification, firebase, user } = useAppContext();

  const updateDocumentMetaStatus = async (
    docId: string,
    collectionPath: string,
    status: ProductDocumentState, // TODO:
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    if (!firebase || !user?.uid || !docId) {
      return;
    }
    try {
      const store = getFirestore(firebase);
      const docRef = doc(store, `${collectionPath}/${docId}`);

      await updateDoc(docRef, { 'meta.status': status });

      if (typeof onSuccess !== 'undefined') {
        await delay(100);
        if (onSuccess instanceof Promise) {
          await onSuccess;
        } else {
          onSuccess();
        }
      }
      createNotification(
        NotificationType.SUCCESS,
        DocumentStateChangeSuccessNotification,
      );
    } catch (err) {
      createNotification(NotificationType.ERROR, DocumentStateChangeErrorNotification);
      console.warn('Error usePublishDocument - setDocumentStatus error: ', err);
    }
  };

  const updateDocumentMetaStatusOnReject = async (
    docId: string,
    collectionPath: string,
    status: ProductDocumentState, // TODO:
    rejectionMessage: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    if (!firebase || !user?.uid || !docId) {
      return;
    }
    try {
      const store = getFirestore(firebase);
      const docRef = doc(store, `${collectionPath}/${docId}`);

      await updateDoc(docRef, {
        'meta.status': status,
        rejectionMessage: rejectionMessage || '',
      });

      if (typeof onSuccess !== 'undefined') {
        await delay(100);
        if (onSuccess instanceof Promise) {
          await onSuccess;
        } else {
          onSuccess();
        }
      }
      createNotification(
        NotificationType.SUCCESS,
        DocumentStateChangeSuccessNotification,
      );
    } catch (err) {
      createNotification(NotificationType.ERROR, DocumentStateChangeErrorNotification);
      console.warn('Error usePublishDocument - setDocumentStatus error: ', err);
    }
  };

  const setDeleteRequestDocumentStatus = async (
    id: string,
    collectionPath: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    return updateDocumentMetaStatus(
      id,
      collectionPath,
      ProductDocumentState.DELETE__REQUEST,
      onSuccess,
    );
  };

  const setPublishRequestDocumentStatus = async (
    id: string,
    collectionPath: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    return updateDocumentMetaStatus(
      id,
      collectionPath,
      ProductDocumentState.PUBLISH__REQUEST,
      onSuccess,
    );
  };

  const setRevokeDocumentStatus = async (
    id: string,
    collectionPath: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    return updateDocumentMetaStatus(
      id,
      collectionPath,
      ProductDocumentState.REVOKE__REQUEST,
      onSuccess,
    );
  };

  // SYS ADMIN
  const setPublishApprovedDocumentStatus = async (
    id: string,
    collectionPath: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    return updateDocumentMetaStatus(
      id,
      collectionPath,
      ProductDocumentState.PUBLISH__APPROVED,
      onSuccess,
    );
  };

  const setPublishRejectedDocumentStatus = async (
    id: string,
    collectionPath: string,
    rejectionMessage: string,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    return updateDocumentMetaStatusOnReject(
      id,
      collectionPath,
      ProductDocumentState.PUBLISH__REJECTED,
      rejectionMessage,
      onSuccess,
    );
  };

  return {
    setDeleteRequestDocumentStatus,
    setPublishRequestDocumentStatus,
    setRevokeDocumentStatus,
    // SYS ADMIN
    setPublishApprovedDocumentStatus,
    setPublishRejectedDocumentStatus,
  };
};
