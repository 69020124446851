import { graphql, useStaticQuery } from 'gatsby';
import { TagDocumentData, AllTagNodeQuery } from 'src/app/types';

// NOTE: limit queries to only necessary data (inside of org app) like here:
const allTagQuery = graphql`
  {
    allTag {
      nodes {
        fields {
          activityCount
        }
        id
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        localizations {
          localeId
          locales {
            description
            name
            teaser
          }
          seo {
            description
            title
          }
        }
        # meta {...}
      }
    }
  }
`;

export function useSourcedTags(): TagDocumentData[] {
  const { allTag } = useStaticQuery<AllTagNodeQuery>(allTagQuery);

  return allTag?.nodes || [];
}
