import * as React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { TopicNode } from 'src/app/types';
import { Localized, TextInputField } from 'src/app/components';

interface HelpTopicSearchProps {
  topics: TopicNode[];
}

/** // TODO: implement */
export const HelpTopicSearch = ({ topics }: HelpTopicSearchProps) => {
  const [searchInput, setSearchInput] = React.useState<string>('');
  return (
    <div>
      <p className='pt-4 pb-2'>
        <Localized
          dictKey={'staticLocales:pages./help.pages./customer.search-topic.title'}
        />
      </p>
      <TextInputField
        disabled
        fullWidth
        label={
          <Localized
            dictKey={'staticLocales:pages./help.pages./customer.search-topic.label'}
          />
        }
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        variant={'outlined'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
