import React from "react"
import { changetommddyyyy ,changeUnixStampToddmmyy} from "../helpers/helpers"
import { TableDatePickerProps } from "../types/DataTableComponentProps"

export const TableDatePicker: React.FC<TableDatePickerProps> = ({
  searchList,
  fieldDetails,
  handleDateChange
}) => {
  return (
    <>
      <input
        type='date'
        value={
          Object.prototype.hasOwnProperty.call(
            searchList,
            fieldDetails.dataName,
          )
            ?fieldDetails.fieldType=="date"?changeUnixStampToddmmyy(searchList[fieldDetails.dataName]): changetommddyyyy(searchList[fieldDetails.dataName])
            :""
        }
        onChange={(e) => handleDateChange(e, fieldDetails)}
        className='bg-gray-50 border  font-normal border-gray-300 text-gray-500 text-sm rounded-lg  focus:border-focus-border block w-full pl-10 p-[10px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  dark:focus:border-[#1FCCB9]'
      />
    </>
  )
}