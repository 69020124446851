import * as React from 'react';
import { Router } from '@reach/router';

import { Grid, PrivateRoute } from 'src/app/components';
import { FormChapterHero, FormSteps, FormStepsProps } from 'src/app/components/RHF';

export const FormChapterInformation = ({
  formChapterIds,
  i18nBasePath,
  routeBasepath,
}: {
  formChapterIds: string[];
  i18nBasePath: string;
  routeBasepath?: string;
}) => {
  const chapterI18nBasePath = `${i18nBasePath}-formChapter`;
  return (
    <>
      <Router>
        {formChapterIds?.map((chapterId, idx) => (
          <PrivateRoute<FormStepsProps>
            key={chapterId}
            path={chapterId}
            component={FormSteps}
            passProps={{
              activeStep: idx,
              i18nBasePath: chapterI18nBasePath,
              steps: formChapterIds,
              baseNavPath: routeBasepath,
            }}
          />
        )) || null}
      </Router>
      <Router>
        <PrivateRoute
          path={'/'}
          component={FormChapterHero}
          passProps={{
            formChapterName: '/',
            i18nBasePath: chapterI18nBasePath,
          }}
        />
        {formChapterIds?.map((chapterId) => {
          const chapterPath = `/${chapterId}`;
          return (
            <PrivateRoute
              key={chapterId}
              path={chapterPath}
              component={FormChapterHero}
              passProps={{
                formChapterName: chapterPath,
                i18nBasePath: chapterI18nBasePath,
              }}
            />
          );
        }) || null}
      </Router>
    </>
  );
};
