import * as React from 'react';

import {
  ButtonTw,
  Localized,  
} from 'src/app/components';
import { SourcedGQLProduct, AddToCartDialogParams } from 'src/app/types';
import { useLocalizationContext, useSourcedCities } from 'src/app/hooks';

import { useAppContext } from 'src/app/context';

import { getTranslationForLanguage } from 'src/lib/document';
import { BookingSystemType, DialogType, PickupOptionType } from 'src/app/constants';
import { createProductLocationOptions } from 'src/lib/participant';
import {
  calculatePriceFromPriceString,
  euroPriceAsCurrency
} from 'src/lib/price';
interface PickupInformationProps {
  product: SourcedGQLProduct;
}

export const PickupInformation = ({ product }: PickupInformationProps) => {
  const { pickup } = product;
  const { locations, customLocations } = pickup;
  const { isAppDebug, openDialog } = useAppContext();
  const { language } = useLocalizationContext();  
  const { currencyId } = useAppContext();

  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;
  const basePath = 'general-information.pickup-transfer';
  // const fieldPath = `${basePath}.field`;

  const cities = useSourcedCities();


  const getCity = (cityId:string) => {
    const city = cities.find((city) => city.id === cityId) || null; 
    return  city?.localizations.find((locale) => locale.localeId === language)?.locales.name;      
  }

  const pickupLocations: { id: string; type: PickupOptionType; cityId: string }[] =
    createProductLocationOptions(product);

  const onBookWithTransportReservationClick = (
    type: 'fixedPickupLocation' | 'customPickupLocation',
  ) => {
    const addToCartDialogParams: AddToCartDialogParams = {
      product,
      bookingSystemType: BookingSystemType.PUBLIC,
      pickupOptionId:
        pickupLocations.find((l) =>
          l.type.startsWith(
            type === 'fixedPickupLocation'
              ? PickupOptionType.SHUTTLE
              : PickupOptionType.CUSTOM_PICKUP,
          ),
        )?.id || undefined,
    };

    openDialog<AddToCartDialogParams>(DialogType.ADD_TO_CART, addToCartDialogParams);
  };

  if (!pickup?.hasPickupService && !pickup?.hasCustomLocationPickupService) {
    return null;
  }

  return (
    <>
      <h3 className='text-p2 font-semibold mb-2 underline md:no-underline md:text-h4 md:font-bold md:mb-12'>  
        <Localized dictKey={`${i18nPrefix}.${basePath}.title`} />  
      </h3>     
        
      <div className='flex flex-col gap-6 md:gap-12 h-full'>
        {pickup?.hasPickupService && locations?.length ? (
          
          <div className='flex flex-col gap-2 md:gap-7 h-full'>
            
            <h3 className='text-p2 font-semibold md:text-h4 md:font-bold'>                 
              <Localized dictKey={`${i18nPrefix}.${basePath}.subtitle.shuttleService`} />
            </h3>
            
            {locations.map((pickupLocation, idx) => {
              return (
                <div className='flex flex-col gap-2 justify-between md:gap-7 h-full' key={`pickup_location_information_${idx}`} >                  
                    
                  <div className='flex flex-col gap-2 md:gap-7'>
                    {pickupLocation.localizations?.length &&
                    pickupLocation.localizations.find(
                      (loca) => loca.localeId === language,
                    ) ? (
                      
                      <p>
                        <strong>
                          {getTranslationForLanguage(
                            pickupLocation.localizations,
                            language,
                            'name',
                            isAppDebug,
                          )}                                 
                        </strong>                              
                      </p>
                      
                    ) : null}

                    
                    {/* <Grid item xs={12}>
                      <StringPropertyRow
                        title={'City:'}
                        value={
                          city
                            ? getBaseDocumentLocales(city, language).name
                            : location.id
                        }
                      />
                    </Grid> */}

                    <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
                      <span>
                        <Localized dictKey={`${i18nPrefix}.${basePath}.field.address`} />
                      </span>
                      <span className='font-semibold'> 
                        {`${pickupLocation.streetName} ${pickupLocation.streetNumber}`}
                      </span>
                    </p>

                    <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
                      <span>
                        <Localized dictKey={`${i18nPrefix}.${basePath}.field.beThere`} />
                      </span>
                      <span className='font-semibold'> 
                        <Localized 
                          dictKey={`${i18nPrefix}.${basePath}.field.minutesBefore`} 
                          translationOptions={{ time: `${pickupLocation.timeDelta}` }}
                        />
                      </span>
                    </p>               
                    
                    {/* // TODO: remove and rename localizations to localization  */}

                    {pickupLocation.localizations?.length &&
                    pickupLocation.localizations.find(
                      (loca) => loca.localeId === language,
                    ) ? (
                      
                        <>
                          {getTranslationForLanguage(
                            pickupLocation.localizations,
                            language,
                            'additionalInformation',
                            isAppDebug,
                          )}
                        </>
                      
                    ) : null}
                    {/** // TODO: some properties are missing after sourcing */}
                  </div>
                  <ButtonTw
                    className={'w-full my-3 md:my-0'}                  
                    variant={'accent'}
                    size={'lg'}
                    rounded={'rounded'}
                    onClick={() =>
                      onBookWithTransportReservationClick(
                        'fixedPickupLocation',
                        // pickupLocation,
                      )
                    }
                  >                                                          
                    {pickupLocation.basePrice ? 
                      <Localized 
                        dictKey={ euroPriceAsCurrency(calculatePriceFromPriceString(pickupLocation.basePrice),currencyId).decimal==0?
                          `${i18nPrefix}.${basePath}.button.label.checkShuttleInteger`:
                          `${i18nPrefix}.${basePath}.button.label.checkShuttle`}
                        translationOptions={{
                          currencyId,
                          price: euroPriceAsCurrency(calculatePriceFromPriceString(pickupLocation.basePrice),currencyId)}}
                      />                                 
                      : 
                      <>
                        <Localized 
                          dictKey={`${i18nPrefix}.${basePath}.button.label.checkShuttle`} 
                          translationOptions={{ price: `` }}
                        />
                        <Localized dictKey={`${i18nPrefix}.${basePath}.no-fees`}       
                        /> 
                      </>                                  
                    }                       
                  </ButtonTw>                    
                  
                </div>
              );
            })}
          </div>
          
        ) : null}          

        {pickup?.hasPickupService && pickup.hasCustomLocationPickupService ? (
          <div className='flex flex-col gap-2 md:gap-7 h-full'>           
              
            <h3 className='text-p2 font-semibold md:text-h4 md:font-bold'>               
              <Localized dictKey={`${i18nPrefix}.${basePath}.subtitle.pickupService`} />   
            </h3>                  

            {customLocations.map((customPickupLocation, idx) => {
              // const city = cities.find((c) => c.id === customPickupLocation.cityId);
              return (
                <div className='flex flex-col gap-2 justify-between mb-2 md:gap-7 h-full' key={`custom_pickup_location_information${idx}`} >
                  {console.log('customLocation: ', customPickupLocation)}                    
                    
                  <div className='flex flex-col gap-2 md:gap-7'>  
                    {customPickupLocation.localizations?.length &&
                    customPickupLocation.localizations.find(
                      (loca) => loca.localeId === language,
                    ) ? (
                      
                      <p>
                        <strong>
                          {getTranslationForLanguage(
                            customPickupLocation.localizations,
                            language,
                            'name',
                            isAppDebug,
                          )}
                        </strong>
                      </p>
                      
                    ) : null}                

                    {/* <Grid item xs={12}>
                      <StringPropertyRow
                        title={'City:'}
                        value={
                          city
                            ? getBaseDocumentLocales(city, language).name
                            : customPickupLocation.id
                        }
                      />
                    </Grid> */}
                    <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
                      <span>
                        <Localized 
                          dictKey={`${i18nPrefix}.${basePath}.field.pickupRadius`} 
                          translationOptions={{ city: `${getCity(customPickupLocation.cityId)}` }}
                        />
                      </span>
                      <span className='font-semibold'> 
                        {`${customPickupLocation.radiusKM} km`}
                      </span>
                    </p>

                    <p className='flex flex-row gap-1 text-p3 md:flex-col md:text-p2 md:flex-row'>  
                      <span>
                        <Localized dictKey={`${i18nPrefix}.${basePath}.field.beReady`} />
                      </span>
                      <span className='font-semibold'> 
                        <Localized 
                          dictKey={`${i18nPrefix}.${basePath}.field.minutesBefore`} 
                          translationOptions={{ time: `${customPickupLocation.timeDelta}` }}
                        />
                      </span>
                    </p>             

                    {customPickupLocation.localizations?.length &&
                    customPickupLocation.localizations.find(
                      (loca) => loca.localeId === language,
                    ) ? (                      
                      <p>
                        {getTranslationForLanguage(
                          customPickupLocation.localizations,
                          language,
                          'additionalInformation',
                          isAppDebug,
                        )}
                      </p>                      
                    ) : null}

                    {/** // TODO: some properties are missing after sourcing */}
                  </div>
                  <ButtonTw
                    className={'w-full my-3 md:my-0'}                    
                    size={'lg'}
                    rounded={'rounded'}
                    variant={'accent'}
                    onClick={() =>
                      onBookWithTransportReservationClick(
                        'customPickupLocation',
                        // customPickupLocation,
                      )
                    }
                  >
                    {customPickupLocation.basePrice ? 
                      <Localized 
                        dictKey={`${i18nPrefix}.${basePath}.button.label.checkService`}
                        translationOptions={{ price: `(${customPickupLocation.basePrice} €)` }}
                      />                                 
                      : 
                      <>
                        <Localized 
                          dictKey={`${i18nPrefix}.${basePath}.button.label.checkService`} 
                          translationOptions={{ price: `` }}
                        />
                        <Localized dictKey={`${i18nPrefix}.${basePath}.no-fees`}       
                        /> 
                      </>                                  
                    }                               
                  </ButtonTw>                     
                  
                </div>
              );
            })}
            
          </div>
        ) : null}

      </div>
        
    </>
  );
};
