// // const StyledTextField = styled(MTextField)`
// //   label {
// //     color: #ff9800;
// //   }
// //   label.focused {
// //     color: #ff9800;
// //   }
// //   .MuiOutlinedInput-root {
// //     fieldset {
// //       border-color: #ff9800;
// //     }
// //     &:hover fieldset {
// //       border-color: #ff9800;
// //     }
// //     &.Mui-focused fieldset {
// //       border-color: #ff9800;
// //     }
// //   }
// //   .MuiFilledInput-root {
// //     color: #fff;
// //     background-color: #eee;
// //     background-color: '#222';
// //     border-color: #fff;
// //     fieldset {
// //       color: #ff9800;
// //       background-color: #ff9800;
// //       border-color: #ff9800;
// //     }
// //     &:hover fieldset {
// //       border-color: #ff9800;
// //     }
// //     &.Mui-focused fieldset {
// //       border-color: #ff9800;
// //     }
// //   }
// //   .MuiInputBase-input {
// //     color: #ff9800;
// //     font-weight: bold;
// //   }

// //   .MuiSvgIcon-root {
// //     color: #ff9800;
// //     font-weight: bold;
// //   }
// // `;

import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  // KeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import { DatePickerType } from 'src/app/constants';

import dayjs from 'dayjs';

import { useFormContext, useController } from 'react-hook-form';

import { Localized } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { FormFieldProps, NestedFieldProps } from '../form.types';
import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FormFieldLayout } from '../Layouts';

export interface FormDateFieldProps extends FormFieldProps<Date> {
  datePickerMode: 'DATE' | 'DATE_AND_TIME';
}

const FormDateField = (props: FormDateFieldProps) => {
  const {
    children,
    defaultValue,
    datePickerMode,
    i18nKey,
    label,
    name,
    rules,
    ...passProps
  } = props;
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
  } = formMethods;

  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const { ref, ...inputProps } = field;
  const styles = useStyles();
  const hasError = !!errors?.[name]; // TODO:

  return (
    <FormFieldLayout
      additionalBottomArea={
        <FieldInformation
          hasError={hasError}
          description={<Localized dictKey={`${i18nKey}.field.description`} />}
          example={<Localized dictKey={`${i18nKey}.field.example`} />}
        >
          {children}
        </FieldInformation>
      }
      debugId={name}
      debugI18nKey={i18nKey}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        {datePickerMode === 'DATE_AND_TIME' ? (
          <KeyboardDateTimePicker
            label={<Localized dictKey={`${i18nKey}.field.name`} />}
            // hideTabs={!!hasToolbar} // TODO: pass down
            // disableFuture={!!hasToolbar} // TODO: pass down
            variant={'inline'}
            inputVariant='outlined'
            // type={'date'}
            // value={inputProps.value || ''} // like in other fields
            inputRef={ref}
            fullWidth
            // onChange={field.onChange}
            // inputRef={field.ref}
            // disableToolbar={!hasToolbar} // TODO: pass down
            openTo={'date'}
            // openTo={hasToolbar ? 'year' : 'day'} // TODO: pass down
            autoOk
            views={['year', 'month', 'date']}
            // variant='dialog' // inline
            format='DD-MM-YYYY'
            // value={field.value as Date}
            InputAdornmentProps={{ position: 'start', color: 'primary' }}
            InputProps={{
              endAdornment: <FieldAdornment hasError={hasError} />,
            }}
            // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
            // label='Year of registration'
            // openTo='day'
            // helperText={error}
            // InputProps={}
            // ampm={!de} // => so.com
            // style={{ paddingLeft: '16px' }}
            // size={'small'}
            // label={label || ''}
            // anchorEl={}
            ampm={false}
            // ampm={!de} // => so.com
            // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
            minutesStep={5}
            {...inputProps}
            {...passProps}
          />
        ) : (
          <KeyboardDatePicker
            label={<Localized dictKey={`${i18nKey}.field.name`} />}
            // hideTabs={!!hasToolbar} // TODO: pass down
            // disableFuture={!!hasToolbar} // TODO: pass down
            variant={'inline'}
            inputVariant='outlined'
            // type={'date'}

            // value={inputProps.value || ''} // like in other fields
            inputRef={ref}
            fullWidth
            // onChange={field.onChange}
            // inputRef={field.ref}
            // disableToolbar={!hasToolbar} // TODO: pass down
            openTo={'year'}
            // openTo={hasToolbar ? 'year' : 'day'} // TODO: pass down
            autoOk
            views={['year', 'month', 'date']}
            // variant='dialog' // inline
            format='DD-MM-YYYY'
            // value={field.value as Date}
            InputAdornmentProps={{ position: 'start', color: 'primary' }}
            InputProps={{
              endAdornment: <FieldAdornment hasError={hasError} />,
            }}
            // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
            // label='Year of registration'
            // openTo='day'
            // helperText={error}
            // InputProps={}
            // ampm={!de} // => so.com
            // style={{ paddingLeft: '16px' }}
            // size={'small'}
            // label={label || ''}
            // anchorEl={}
            // ampm={false}
            // ampm={!de} // => so.com
            // TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
            // minutesStep={5}
            {...inputProps}
            {...passProps}
          />
        )}
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

export const DateField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps<Date> & FormDateFieldProps) => (
  <FormDateField
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormDateField>
);
