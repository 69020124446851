import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { Localized, TextInputField, ImageFilePickerWidget } from 'src/app/components';

import { useStyles } from 'src/app/context';
import { nanoid } from 'nanoid';
import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';

import { FormFieldLayout } from '../Layouts';

import { FormFieldProps, NestedFieldProps } from '../form.types';

interface FormImageFieldProps extends FormFieldProps<{ imageUrl: string }> {
  // index?: number; // usage in arrays - find better way than passing it down
  noUpload: boolean;
  onRemoveImage?: () => void;
  onUploadImage?: (imgFile: File) => void;
  imageIndex: number;
}
const FormImageField = (props: FormImageFieldProps) => {
  const {
    children,
    i18nKey,
    imageIndex,
    name,
    noUpload,
    onRemoveImage,
    onUploadImage,
    rules,
    defaultValue,
    ...passProps
  } = props;
  const formMethods = useFormContext();
  const styles = useStyles();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = formMethods;

  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const onRemoveItem = () => {
    if (onRemoveImage) {
      onRemoveImage();
    }
    // setFiles([...files.filter((file, idx) => idx !== index)]);
    // remove(index);
    // setValue(name, '', { shouldValidate: true });
  };

  const onUploadButtonClick = (imgFile: File) => {
    if (onUploadImage) {
      onUploadImage(imgFile);
    }
  };

  const onSetValue = (file: File) => {
    // console.log('_______on set value: ', file);
    setValue(`imageUrls.${imageIndex}.imageFile`, file, { shouldValidate: true });
    setValue(`imageUrls.${imageIndex}.displayName`, file.name, { shouldValidate: true });
  };

  const watchedImageUrl: string = watch(`imageUrls.${imageIndex}.imageUrl`, '');
  const watchedImageFile: File = watch(`imageUrls.${imageIndex}.imageFile`, '');
  const watchedDisplayName: string = watch(`imageUrls.${imageIndex}.displayName`, '');

  // TODO: create effect to clean up (trigger if imageFile is present)?

  // console.log('watchedImageUrl: ', watchedImageUrl);
  // console.log('watchedDisplayName: ', watchedImageUrl); // return always empty
  let value = 'No file selected...';
  if (watchedDisplayName) {
    value = watchedDisplayName;
  }
  const showPreview: string | undefined = watchedImageUrl || undefined;

  // TODO: handle presence of "imageFile"

  // let fileNameFromUrl = '';
  // let hasValue = false;
  // if (inputProps?.value?.name) {
  //   // is file
  //   value = inputProps.value.name;
  //   hasValue = true;
  // }
  // // for imgURl display
  // // if (defaultValue?.value) {
  // //   value = defaultValue.value;
  // // }
  // // for imgURl display
  // if (defaultValue) {
  //   value = defaultValue;
  //   hasValue = true;
  // }
  // if (value === 'USE_INITIAL__VALUE__FROM__URL') {
  //   const urlSegments = watchedImageUrl.split('%2F');
  //   const lastSegment = urlSegments.filter(
  //     (seg, idx) => idx === urlSegments.length - 1,
  //   )[0];

  //   console.log('lastSegment: ', lastSegment);
  //   // "hr-split-000-00.jpg?alt=media&token=61a93093-65ee-4ca1-97c8-4a01d0a821b9"
  //   fileNameFromUrl = lastSegment.split('?')[0] || 'Invalid url';
  //   console.log('fileNameFromUrl: ', fileNameFromUrl);
  //   value = watchedImageUrl; // fileNameFromUrl;
  //   hasValue = true;
  // } else {
  //   value = 'No file selected...';
  // }

  // console.log('ZZZ FormImageField props: ', props);
  // console.log('ZZZ FormImageField inputProps: ', inputProps);

  console.log('watchedImageUrl: ', watchedImageUrl);

  const hasError = !!errors?.[name]; // TODO:
  return (
    <FormFieldLayout
      additionalBottomArea={
        <>
          <ImageFilePickerWidget
            storagePath={''} // TODO: remove?
            noUpload={noUpload} // hhm
            index={nanoid()} // for render id issues
            onSelectImageFile={(file) => onSetValue(file)}
            onUploadImage={onUploadButtonClick}
            onCancel={() => onRemoveItem()}
            showPreview={showPreview}
            initialFileUrl={watchedImageUrl}
          />
          <FieldInformation
            hasError={hasError}
            description={
              !watchedImageFile ? (
                <Localized dictKey={`${i18nKey}.field.description`} />
              ) : null
            }
            example={<Localized dictKey={`${i18nKey}.field.example`} />}
          >
            {children}
          </FieldInformation>
        </>
      }
      debugId={name}
      debugI18nKey={i18nKey}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <TextInputField
          variant={'outlined'}
          onFocus={(e) => {
            e.preventDefault();
            e.currentTarget.blur();
          }}
          {...inputProps}
          {...passProps}
          // disabled
          inputRef={ref}
          error={hasError}
          fullWidth
          value={value}
          // value={hasValue ? fileNameFromUrl : value}
          // helperText={<div style={{ backgroundColor: 'red' }}>Heeeelp</div>}
          // helperText={'Heeeelp'}
          InputProps={{
            endAdornment: <FieldAdornment hasError={hasError} />,
          }}
        />
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

export type ImageFieldProps = FormImageFieldProps & NestedFieldProps;

export const ImageField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: ImageFieldProps) => (
  <FormImageField
    {...rest}
    i18nKey={i18nPath ? `${i18nPath}.${i18nKey}` : i18nKey}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={i18nPath ? `${i18nPath}.${i18nKey}` : i18nKey}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormImageField>
);
