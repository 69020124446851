import * as React from 'react';
import clsx from 'clsx';

import {
  ButtonTw,
  Localized,
  TextInputField,
} from 'src/app/components';

import { useStyles } from 'src/app/context';
import { ParticipantPickupAddressInformation } from 'src/app/types';

const i18nPath = 'shop:participants.pickup-address';

interface ParticipantPickupAddressProps {
  address?: ParticipantPickupAddressInformation;
  isEditMode: boolean;
  setIsEditMode: (state: boolean) => void;
  setAddress: (address: ParticipantPickupAddressInformation) => void;
}

const initialAddress: ParticipantPickupAddressInformation = {
  additionalInformation: '',
  city: '',
  streetName1: '',
  streetName2: '',
  streetNumber: '',
  w3w: '',
  zip: '',
};

const addressFieldsConfig = [
  'city',
  'zip',
  'streetName1',
  'streetName2',
  'streetNumber',
  'w3w',
  'additionalInformation',
];

// TODO: handle save/cancel display in upper container

export const ParticipantPickupAddress = ({
  address,
  isEditMode,
  setAddress,
  setIsEditMode,
}: ParticipantPickupAddressProps) => {
  const styles = useStyles();
  const [addressState, setAddressState] =
    React.useState<ParticipantPickupAddressInformation>(address || initialAddress);

  const onChange = (key: string, val: string) => {
    const update: ParticipantPickupAddressInformation = { ...addressState };
    update[key] = val;
    setAddressState(update);
  };
  return (
    <>
      {isEditMode ? (
        <div>
          {addressFieldsConfig?.map((cfgItem) => (
            <div className='my-4' key={`pickup_address_${cfgItem}`}>
              <TextInputField
                value={addressState?.[cfgItem]}
                label={<Localized dictKey={`${i18nPath}.field.${cfgItem}.label`} />}
                variant={'outlined'}
                size={'large'}
                onChange={(e) => onChange(cfgItem, e.target.value)}
                fullWidth
                multiline={cfgItem === 'additionalInformation'}
              />
            </div>
          )) || null}
        </div>
      ) : (
        <div>
          <p className='text-p2 mb-4'>
            {'Address: '}
            {addressState?.city} {addressState?.zip ? `( ${addressState.zip} )` : ''}{' '}
            {addressState?.streetName1} {addressState?.streetName2}{' '}
            {addressState?.streetNumber}
          </p>
        </div>
      )}
      {isEditMode ? (
        <>
          <div className='flex flex-wrap items-center justify-center gap-2 py-4'>
            <ButtonTw
              onClick={() => {
                setAddressState(address || initialAddress);
                setIsEditMode(!isEditMode);
              }}
              variant={'outline'}
              rounded
              size={'sm'}
              className={styles.btn__error__out}
            >
              <Localized dictKey={`${i18nPath}.action.cancel.label`} />
            </ButtonTw>

            <ButtonTw
              onClick={() => {
                setAddress(addressState);
                setIsEditMode(!isEditMode);
              }}
              variant={'outline'}
              rounded
              size={'sm'}
              className={clsx(styles.btn__success__out, styles.mt__1)}
            >
              <Localized dictKey={`${i18nPath}.action.save.label`} />
            </ButtonTw>
          </div>
        </>
      ) : (
        <div className='text-center'>
          <ButtonTw
            onClick={() => {
              setIsEditMode(!isEditMode);
            }}
            rounded
            variant={'outline'}
            size={'lg'}
          >
            <Localized dictKey={`${i18nPath}.action.edit.label`} />
          </ButtonTw>
        </div>
      )}
    </>
  );
};
