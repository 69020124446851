import * as React from 'react';

import { DropDown } from 'src/app/components';

import { ProductEventParticipant, SourcedGQLProduct } from 'src/app/types';
import { useLocalizationContext, useStyles } from 'src/app/context';

import {
  getFoodItemNameById,
  getPickupLocationNameById,
  getLanguageOptionNameById,
} from 'src/lib/participant';
import { getBaseDocumentLocales } from 'src/lib/document';

// const i18nPath = 'shop:participants.pickup-address';

const hasFieldProperty = (id: string, fieldOptions: { fieldPropertyId: string }[]) =>
  !!fieldOptions?.find((opt) => opt.fieldPropertyId === id);

// TODO: get gender information?

interface ProductParticipantOptionsProps {
  isRequestingUpdate: boolean;
  fieldOptions: { fieldPropertyId: string }[];
  locationOptions: { id: string }[];
  foodOptions: { id: string }[];
  languageOptions: { id: string }[];
  participant: ProductEventParticipant;
  product: SourcedGQLProduct;
  // onSaveParticipant
  onSelectPickupOption: (pickupOptionIndex: number) => void; // TODO: use/extends onUpdateParticipant
  onSelectFoodOption: (foodOptionIndex: number) => void; // TODO: use/extends onUpdateParticipant
  onSelectLanguageOption: (languageOptionIndex: number) => void; // TODO: use/extends onUpdateParticipant
}

export const ProductParticipantOptions = ({
  isRequestingUpdate,
  fieldOptions,
  locationOptions,
  foodOptions,
  languageOptions,
  participant,
  product,
  onSelectPickupOption,
  onSelectFoodOption,
  onSelectLanguageOption,
}: ProductParticipantOptionsProps) => {
  const styles = useStyles();
  const { language } = useLocalizationContext();

  const variant = 'outlined';
  return (
    <>
      {hasFieldProperty('pickup', fieldOptions /** or options.pickup ?? */) ? (
        <div className='my-4'>
          <DropDown
            disabled={isRequestingUpdate}
            variant={variant}
            size={'large'}
            // color={isFilterOn() ? 'secondary' : 'primary'}
            // color={'primary'}
            showArrowIcon={false}
            label={
              <div
                style={{
                  overflow: 'hidden',
                  maxWidth: '32ch',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <p>
                  {participant.options?.pickup
                    ? getPickupLocationNameById(
                        participant.options?.pickup || '',
                        product,
                        language,
                      )
                    : 'No pickup / shuttle'}
                </p>
              </div>
            }
            placement={'top'}
            options={[
              ...locationOptions.map((option) => (
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {option.id}
                </span>
              )),
            ]}
            // selectedIndex={selectedQuestionTypeIdx}
            onChange={(i: number) => onSelectPickupOption(i)}
            openOnHover
            fullWidth
          />
        </div>
      ) : null}
      {product.foodOptions?.featureEnabled ? (
        <div className='my-4'>
          <DropDown
            disabled={isRequestingUpdate}
            variant={variant}
            size={'large'}
            showArrowIcon={false}
            // color={isFilterOn() ? 'secondary' : 'primary'}
            // color={'primary'}
            label={
              <div
                style={{
                  overflow: 'hidden',
                  maxWidth: '32ch',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <p>
                  {participant.options?.food
                    ? participant.options.food
                    : 'Food not selected'}
                </p>
              </div>
            }
            placement={'top'}
            options={[
              ...product.foodOptions.options.map((option) => (
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {console.log(option)}
                  {option.localizations.reduce((opt: string, loc: any) => {
                    let name = '';
                    if (!opt) name = loc?.locales?.name;
                    else name = opt;
                    if (loc?.localeId === language) name = loc?.locales?.name;
                    return name;
                  }, null)}
                  {/* {option.id}
                  {getBaseDocumentLocales(category, language).name || categoryId} */}
                </span>
              )),
            ]}
            // selectedIndex={selectedQuestionTypeIdx}
            onChange={(i: number) => onSelectFoodOption(i)}
            openOnHover
            fullWidth
          />
        </div>
      ) : null}
      {hasFieldProperty('language', fieldOptions) ? (
        <div className='my-4'>
          <DropDown
            disabled={isRequestingUpdate}
            variant={variant}
            size={'large'}
            showArrowIcon={false}
            // color={isFilterOn() ? 'secondary' : 'primary'}
            // color={'primary'}
            label={
              <div
                style={{
                  overflow: 'hidden',
                  maxWidth: '32ch',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <p>
                  {participant.options?.language
                    ? getLanguageOptionNameById(
                        participant.options?.language || '',
                        product,
                        language,
                      )
                    : 'Language not selected'}
                </p>
              </div>
            }
            placement={'top'}
            options={[
              ...languageOptions.map((option) => (
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {option.id}
                </span>
              )),
            ]}
            // selectedIndex={selectedQuestionTypeIdx}
            onChange={(i: number) => onSelectLanguageOption(i)}
            openOnHover
            fullWidth
          />
        </div>
      ) : null}
    </>
  );
};
