import * as React from 'react';
import LightThemeIcon from '@material-ui/icons/WbSunny';
import DarkThemeIcon from '@material-ui/icons/Brightness3';

import { useStyleContext } from 'src/app/context';
import { ThemeMode } from 'src/app.consumer/constants';

export const ThemeSwitch = () => {
  const { setThemeMode, themeMode } = useStyleContext();
  const hasThemeModeFeature = true; // FIXME: .env // or per account
  const isDark = themeMode === ThemeMode.DARK;
  return !hasThemeModeFeature ? null : (
    <span
      role='button'
      onClick={() => setThemeMode(isDark ? ThemeMode.LIGHT : ThemeMode.DARK)}
    >
      {isDark ? (
        <span className='dark:text-neutral-50 text-neutral-900 dark:hover:text-accent-500 duration-300 transition-all'>
          <DarkThemeIcon />
        </span>
      ) : (
        <span className='dark:text-neutral-50 text-neutral-900 hover:text-accent-500 duration-300 transition-all'>
          <LightThemeIcon  />
        </span>
      )}
    </span>
  );
};
