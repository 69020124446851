import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion, Localized } from 'src/app/components';
import { ProductImportantInformation } from 'src/app.consumer/components'; // TODO: either bring this in the core app or move product configuration view etc into app.consumer

import {
  Price,
  ShoppingCartItemConfiguration,
  SourcedGQLProduct,
  ParticipantImportData,
  ProductEventParticipant,
  ShoppingCartItemConfigurationChaptersConfig,
  AddToCartDialogInitialConfig,
} from 'src/app/types';

import {
  getPercentageGroupDiscountPrice,
  getFixedGroupDiscountPrice,
  euroPriceAsCurrency,
} from 'src/lib/price';

import {
  BookingSystemType,
  ImportDataMode,
  ParticipantEditMode,
} from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import {
  createProductLocationOptions,
  // getCartItemsTotalParticipantCount,
} from 'src/lib/participant';
import { PriceSummary } from './components/priceSummary/PriceSummary';

import { PriceGroupControl } from './components/priceGroupControl/PriceGroupControl';
import { PriceGroupControlHeader } from './components/priceGroupControl/PriceGroupControlHeader';
import { UploadParticipantList } from './components/uploadParticipantList/UploadParticipantList';
import { PriceGroupAllParticipantSettings } from './components/priceGroupAllParticipantSettings/PriceGroupAllParticipantSettings';
import { ProductTicketGroupParticipants } from './components/priceGroupControl/ProductTicketGroupParticipants';

import { TotalRow } from './components/totalRow';
import { GroupDiscount } from './components/groupDiscount';
import {
  convertPriceToFloat,
  calculatePriceFromPriceString
} from 'src/lib/price';
const i18nPrefix = 'shop:screen-product';
const i18nPriceSummaryPrefix = `${i18nPrefix}.price-summary`;
// const i18nParticipantsPrefix = `${i18nPrefix}.participants`; // ?? the right one?

export interface ProductConfigurationViewProps {
  basePrice: Price;
  cartType: BookingSystemType;
  hiddenChapters?: ShoppingCartItemConfigurationChaptersConfig;
  openChapters?: ShoppingCartItemConfigurationChaptersConfig;
  participantEditMode: ParticipantEditMode;
  priceGroupTickets: ShoppingCartItemConfiguration[];
  priceSystemId: string;
  product: SourcedGQLProduct;
  showParticipantsInfo: boolean;
  totalPrice: Price;
  groupQuantityDiscount: Price;
  onImportData: (data: ParticipantImportData[], mode: ImportDataMode) => void;
  onSaveParticipant: (
    priceGroupId: string,
    participantId: string,
    participantData: ProductEventParticipant,
  ) => void;
  setTicketCount: (priceGroupId: string, amount: number) => void;
  ticketOrderLimit: number;
  optionalEventReuseParams?: AddToCartDialogInitialConfig; // NOTE: propBag for addToCart reuse state mechanism
}

const hasChapter = (
  chapterKey: 'productInformation' | 'priceGroups' | 'participants' | 'priceSummary',
  chapterConfig: Record<string, boolean | undefined> | undefined,
) => {
  if (!chapterConfig) {
    return true;
  }
  if (
    Object.getOwnPropertyNames(chapterConfig).find((propName) => propName === chapterKey)
  ) {
    return !chapterConfig[chapterKey]; // NOTE: if a chapter is deactivated the value is true - thus flipped here
  }
  return true;
};

export const ProductConfigurationView = (props: ProductConfigurationViewProps) => {
  const {
    basePrice,
    cartType,
    hiddenChapters,
    openChapters,
    participantEditMode,
    priceGroupTickets,
    priceSystemId,
    product,
    showParticipantsInfo,
    totalPrice,
    groupQuantityDiscount,
    onImportData,
    onSaveParticipant,
    setTicketCount,
    ticketOrderLimit,
    optionalEventReuseParams,
  } = props;
  const { priceSystems } = product;
  const { currencyId } = useAppContext();

  const [isProductInformationOpen, setIsProductInformationOpen] = React.useState<boolean>(
    openChapters ? openChapters.productInformation || false : false,
  );
  const [isPriceSummaryOpen, setIsPriceSummaryOpen] = React.useState<boolean>(
    openChapters ? openChapters.priceSummary || false : false,
  );
  const [isParticipantsOpen, setIsParticipantsOpen] = React.useState<boolean>(
    openChapters ? openChapters.participants || false : false,
  );
  const [isPriceGroupsOpen, setIsPriceGroupsOpen] = React.useState<boolean>(
    openChapters ? openChapters.priceGroups || false : false,
  );
console.log('groupQuantityDiscount.',groupQuantityDiscount)
  // const totalParticipants = getCartItemsTotalParticipantCount(priceGroupTickets);
  // const hasMaxTicketAmountReached = !!(ticketOrderLimit - totalParticipants <= 0);

  const productPriceSystem = priceSystems.find((ps) => ps.id === priceSystemId);
  if (!productPriceSystem) {
    return null;
  }

  // PUBLIC
  const publicPriceSystemSettings = productPriceSystem.publicBooking?.featureEnabled
    ? productPriceSystem.publicBooking
    : null;
  const hasPublicGroupDiscount = publicPriceSystemSettings?.hasGroupDiscount;
  const publicGroupsDiscounts = hasPublicGroupDiscount
    ? publicPriceSystemSettings.groupDiscounts
    : [];
    const isAnyOfDiscountSelected = publicGroupsDiscounts.filter(i=>i.isEnabled)
	const privatePriceSystemSettings =productPriceSystem.privateBooking;
  	const pickupLocations: { id: string; type: string; cityId: string }[] =
    createProductLocationOptions(product);

  // TODO:
  // - pass onImportData via props => ask if should overwrite/add new cart items for excel user

  // // TODO:
  // const hasPublicQuantityDiscount = publicSettings?.hasQuantityDiscount;
  // const publicQuantityDiscounts = publicSettings?.quantityDiscounts || [];

  // TODO: make configurable

  const fieldOptions = [
    // FIXME: this have to be sourced and created first by organisation
    // {
    //   fieldPropertyId: 'firstName',
    // },
    // {
    //   fieldPropertyId: 'lastName',
    // },
    // {
    //   fieldPropertyId: 'dob',
    // },
    // those both can be auto determined in product
    {
      fieldPropertyId: 'pickup',
    },
    {
      fieldPropertyId: 'food',
      isSelectable: true, // TODO: implement functionality
    },
    {
      fieldPropertyId: 'language',
    },
  ];

  return (
    <>
      {true ? (
        <>
          {/** cartType // TODO */}
          {/* <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <ButtonGroup fullWidth>
              <Button
                disabled={cartType !== AddToCartType.DEFAULT_BOOKING}
                fullWidth
                color={'primary'}
              >
                <Typography>
                  <Localized
                    dictKey={`${i18nPrefix}.add-cart.booking-system.public.label`}
                  />
                </Typography>
              </Button>

              <Button
                disabled={cartType !== AddToCartType.PRIVATE_BOOKING}
                fullWidth
                color={'primary'}
              >
                <Typography>
                  <Localized
                    dictKey={`${i18nPrefix}.add-cart.booking-system.private.label`}
                  />
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid> */}
        </>
      ) : null}

      {/** // TODO: PRIVATE/PUBLIC  */}

      {/* {hasPublicGroupDiscount && publicGroupsDiscounts?.length > 0
        ? publicGroupsDiscounts?.map((pgd) => {
            // also avail: calculatePriceGroupPrice(priceGroup, product.priceSystems)

            if (!pgd.isEnabled) {
              return null;
            }

            let perPersonPrice: Price | null = null;
            if (pgd.discountType === 'percentage') {
              perPersonPrice = getPercentageGroupDiscountPrice(basePrice, pgd);
            }
            // TODO: map through qty discounts and apply if valid
            if (pgd.discountType === 'fixed') {
              perPersonPrice = getFixedGroupDiscountPrice(basePrice, pgd);
            }

            return perPersonPrice === null ? null : (
              <Grid key={pgd.id} item xs={12}>
                <PriceGroupControl
                  groupPrice={perPersonPrice}
                  fieldOptions={fieldOptions}
                  priceGroupId={pgd.id}
                  priceGroupTickets={priceGroupTickets}
                  priceSystemId={priceSystemId}
                  priceSystemSettings={publicPriceSystemSettings}
                  product={product}
                  showParticipantsInfo={showParticipantsInfo}
                  onSaveParticipant={onSaveParticipant}
                  setTicketCount={setTicketCount}
                  maxTicketsOrderable={ticketOrderLimit}
                  // TODO: pub/priv reservation flag needed?
                />
              </Grid>
            );
          })
        : null} */}

      {hasChapter('productInformation', hiddenChapters) && hasPublicGroupDiscount ? (

          <>
            <Accordion
              expanded={isProductInformationOpen}
              nonVisuallyDisabled
              // hideSummary
              onClick={() => setIsProductInformationOpen(!isProductInformationOpen)}
              style={{ borderWidth: '0', width: '100%' }}
              expandIcon={<ExpandMoreIcon className='mr-1' />}
              summary={
                <p className='font-semibold'>
                  {/* <Localized dictKey={`${i18nPriceSummaryPrefix}.title`} /> */}
                  {'Important'}
                </p>
              }
            >
              <div className='py-3 px-2 md:px-4 w-full'>
                <ProductImportantInformation bookingType={cartType} product={product} />
              </div>
            </Accordion>
          </>

      ) : null}

      {hasChapter('priceGroups', hiddenChapters) ? (
        <div className='my-2'>
          <Accordion
            expanded={isPriceGroupsOpen}
            nonVisuallyDisabled
            // hideSummary
            onClick={() => setIsPriceGroupsOpen(!isPriceGroupsOpen)}
            style={{ borderWidth: '0', width: '100%' }}
            expandIcon={<ExpandMoreIcon className='mr-1' />}
            summary={
              <p className='font-semibold'>
                {/* <Localized dictKey={`${i18nPriceSummaryPrefix}.title`} /> */}
                {'Participants'}
              </p>
            }
          >
            <div className='py-3 px-2 md:px-4 w-full'>
              <PriceGroupControlHeader />
              {cartType==BookingSystemType.PUBLIC ? ((publicGroupsDiscounts?.length > 0 && isAnyOfDiscountSelected?.length >0)
                ? publicGroupsDiscounts?.map((pgd) => {
                    // also avail: calculatePriceGroupPrice(priceGroup, product.priceSystems)

                    if (!pgd.isEnabled) {
                      return null;
                    }

                    let perPersonPrice: Price | null = null;
                    if (pgd.discountType === 'percentage') {
                      perPersonPrice =
                        getPercentageGroupDiscountPrice(basePrice, pgd) || 0;
                    }
                    // TODO: map through qty discounts and apply if valid
                    if (pgd.discountType === 'fixed') {
                      perPersonPrice = getFixedGroupDiscountPrice(basePrice, pgd) || 0;
                    }
                    

                    return perPersonPrice === null ? null : (
                      <React.Fragment key={pgd.id}>

                        <div className=''>
                          <PriceGroupControl
                            groupPrice={perPersonPrice}
                            fieldOptions={fieldOptions}
                            priceGroupId={pgd.id}
                            priceGroupTickets={priceGroupTickets}
                            priceSystemId={priceSystemId}
                            priceSystemSettings={publicPriceSystemSettings}
                            product={product}
                            showParticipantsInfo={showParticipantsInfo}
                            onSaveParticipant={onSaveParticipant}
                            setTicketCount={setTicketCount}
                            maxTicketsOrderable={ticketOrderLimit}
                            bookingSystem={cartType}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })
                : (() => {
                    return (
                      <>
                        <PriceGroupControl
                          groupPrice={basePrice}
                          fieldOptions={fieldOptions}
                          priceGroupId={'__ADULT'}
                          priceGroupTickets={priceGroupTickets}
                          priceSystemId={priceSystemId}
                          priceSystemSettings={publicPriceSystemSettings}
                          product={product}
                          showParticipantsInfo={showParticipantsInfo}
                          onSaveParticipant={onSaveParticipant}
                          setTicketCount={setTicketCount}
                          maxTicketsOrderable={ticketOrderLimit}
                          bookingSystem={cartType}
                        />
                      </>
                    );
                  })()
                ):
                <PriceGroupControl
                          groupPrice={basePrice}
                          fieldOptions={fieldOptions}
                          priceGroupId={'__PRIVATE_GROUP'}
                          priceGroupTickets={priceGroupTickets}
                          priceSystemId={priceSystemId}
                          priceSystemSettings={privatePriceSystemSettings}
                          product={product}
                          showParticipantsInfo={showParticipantsInfo}
                          onSaveParticipant={onSaveParticipant}
                          setTicketCount={setTicketCount}
                          maxTicketsOrderable={ticketOrderLimit}
                          bookingSystem={cartType}
                        />
                }
            </div>
          </Accordion>
        </div>
      ) : null}

      {hasChapter('participants', hiddenChapters) ? (
        <div className='my-2'>
          <Accordion
            expanded={isParticipantsOpen}
            nonVisuallyDisabled
            // hideSummary
            onClick={() => setIsParticipantsOpen(!isParticipantsOpen)}
            style={{ borderWidth: '0', width: '100%' }}
            expandIcon={<ExpandMoreIcon className='mr-1' />}
            summary={
              <p className='font-semibold'>
                {/* <Localized dictKey={`${i18nPriceSummaryPrefix}.title`} /> */}
                {'Participant details'}
              </p>
            }
          >
            <div className='py-3 px-2 md:px-4 w-full'>
              {/* {hasPublicGroupDiscount && publicGroupsDiscounts?.length > 0 ? (
                <Grid item xs={12} className={styles.py__2}>
                  <PriceGroupAllParticipantSettings
                    fieldOptions={fieldOptions}
                    priceSystemId={priceSystemId}
                    product={product}
                  />
                </Grid>
              ) : null} */}

              { cartType==BookingSystemType.PUBLIC ? (publicGroupsDiscounts?.length > 0
                ? publicGroupsDiscounts?.map((pgd) => {
                    if (!pgd.isEnabled) {
                      return null;
                    }

                    const priceGroupId = pgd.id;

                    const priceGroup = priceGroupTickets.find(
                      (pgt) => pgt.priceGroupId === priceGroupId,
                    );

                    if (!priceGroup) {
                      return null;
                    }

                    const participants = [...(priceGroup.participants || [])];

                    return (
                      <div key={`priceGroupParticipants_${priceGroupId}`} >
                        <ProductTicketGroupParticipants
                          bookingSystem={cartType}
                          saveParticipant={(id, data) =>
                            onSaveParticipant(priceGroupId, id, data)
                          }
                          participants={participants}
                          product={product}
                          priceSystemId={priceSystemId}
                          priceGroupId={priceGroupId}
                          // FIXME: pass as props
                          fieldOptions={fieldOptions}
                          participantEditMode={participantEditMode}
                          optionalEventReuseParams={optionalEventReuseParams}
                          // TODO: must be provided
                          // // bookingSystemType

                          // priceGroupTickets={priceGroupTickets}
                          // date={date}
                          // dayTime={dayTime}
                          // eventDocument={eventDocument}
                        />
                      </div>
                    );
                  })
                : (() => {
                    const participants = [...(priceGroupTickets[0]?.participants || [])];
                    const priceGroupId = '__ADULT';
                    return (
                      <div key={`priceGroupParticipants_${priceGroupId}`}>
                        <ProductTicketGroupParticipants
                          bookingSystem={cartType}
                          saveParticipant={(id, data) =>
                            onSaveParticipant(priceGroupId, id, data)
                          }
                          participants={participants}
                          product={product}
                          priceSystemId={priceSystemId}
                          priceGroupId={priceGroupId}
                          // FIXME: pass as props
                          fieldOptions={fieldOptions}
                          participantEditMode={participantEditMode}
                          optionalEventReuseParams={optionalEventReuseParams}
                          // TODO: must be provided
                          // // bookingSystemType

                          // priceGroupTickets={priceGroupTickets}
                          // date={date}
                          // dayTime={dayTime}
                          // eventDocument={eventDocument}
                        />
                      </div>
                    );
                  })()):  
                <div key={`priceGroupParticipants___PRIVATE_GROUP`}>
                  <ProductTicketGroupParticipants
                    bookingSystem={cartType}
                    saveParticipant={(id, data) =>
                      onSaveParticipant('__PRIVATE_GROUP', id, data)
                    }
                    participants={[...(priceGroupTickets[0]?.participants || [])]}
                    product={product}
                    priceSystemId={priceSystemId}
                    priceGroupId={'__PRIVATE_GROUP'}

                    fieldOptions={fieldOptions}
                    participantEditMode={participantEditMode}
                    optionalEventReuseParams={optionalEventReuseParams}
                  />
                </div> 
                  }

              {showParticipantsInfo ? (
                <>
                  <UploadParticipantList
                    onImportData={onImportData}
                    pickupLocations={pickupLocations}
                    priceSystem={productPriceSystem}
                    // TODO: pub/priv reservation flag
                    // onImportData={(data: ParticipantImportData[], mode: ImportDataMode) =>
                    //   console.log('UploadParticipantList - imported excel data: ', data)
                    // }
                  />
                </>
              ) : null}
            </div>
          </Accordion>
        </div>
      ) : null}

      {hasChapter('priceSummary', hiddenChapters) ? (
        <div className='my-2'>
          <Accordion
            expanded={isPriceSummaryOpen}
            nonVisuallyDisabled
            // hideSummary
            onClick={() => setIsPriceSummaryOpen(!isPriceSummaryOpen)}
            style={{ borderWidth: '0', width: '100%' }}
            expandIcon={<ExpandMoreIcon className='mr-1' />}
            summary={
              <p className='font-semibold'>
                <Localized dictKey={`${i18nPriceSummaryPrefix}.title`} />
              </p>
            }
          >
            <div className='flex flex-col gap-y-4 w-full py-3 px-2 md:px-4'>
              <div>
                <PriceSummary
                  priceGroupTickets={priceGroupTickets}
                  priceSystems={priceSystems}
                  showParticipantsInfo={showParticipantsInfo}
                  product={product}
                  bookingSystem={cartType}
                />
              </div>
              <div>
                <GroupDiscount totalDiscount={groupQuantityDiscount} currencyId={currencyId} />
              </div>
              <div>
              
                <TotalRow totalPrice={groupQuantityDiscount.integer==0?totalPrice:euroPriceAsCurrency(totalPrice,currencyId)} currencyId={currencyId} />
              </div>
            </div>
          </Accordion>
        </div>
      ) : null}
    </>
  );
};
