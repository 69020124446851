import * as React from 'react';

import FreeCancellationIcon from '@material-ui/icons/ThumbUpOutlined';
import FasterAccessIcon from '@material-ui/icons/Timelapse';
import FriendlyHelpIcon from '@material-ui/icons/FavoriteBorder';

import { FeatureItem, Grid } from 'src/app/components';
import { useI18nArray } from 'src/app/hooks';

const getIcon = (idx: number) => {
  switch (idx) {
    case 0:
      return <FreeCancellationIcon fontSize='large' />;
    case 1:
      return <FasterAccessIcon fontSize='large' />;
    case 2:
      return <FriendlyHelpIcon fontSize='large' />;
    default:
      return null;
  }
};

export const ShopFeaturesContainer = () => {
  const { i18nItems: features } = useI18nArray<{ description: string; title: string }>(
    'staticLocales:pages./root.features',
    ['description', 'title'],
  );

  return (    
    <div className="flex flex-col md:flex-row gap-4">
      {(features?.length &&
        features.map(({ description, title }, idx) => (
          <div className="flex-1" key={`${title}_${idx}`}>
            <FeatureItem description={description} icon={getIcon(idx)} title={title} />
          </div>
        ))) ||
        null}
    </div>    
  );
};
