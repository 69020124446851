// check if "organisationAdmin" prefix is needed here or just apply while composing the i18n key
export enum OrganisationAdminRootScreen {
  account = 'org-account',
  communication = 'org-communication',
  customers = 'org-customers',
  documents = 'org-documents',
  media = 'org-media',
  orders = 'org-orders',
  products = 'org-products',
  productGeneration = 'org-products-create-product',
  productManagement = 'org-products-management',
  productSettings = 'org-products-settings',
  organisation = 'org-organisation',
  reports = 'org-reports',
  settings = 'org-settings',
  user = 'org-users',
  preview = 'org-preview',
}

// TODO this should be added to a common type with systemAdmin
export enum OrganisationAdminDialogType {
  create = 'create',
  publish = 'publish',
  delete = 'delete',
  edit = 'edit',
}
