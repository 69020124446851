import * as React from 'react';
import clsx from 'clsx';

import {
  Localized,
} from 'src/app/components';
import { useStyles } from 'src/app/context';
import { SourcedGQLProduct } from 'src/app/types';

import debounce from 'lodash.debounce';
import { ProductFilter } from './productFilter.types';

import {
  CategoryFilter,
  CityAreaFilter,
  CountryAreaFilter,
  DurationFilter,
  LanguageFilter,
  PriceFilter,
  ServicesFilter,
  TagFilter,
} from './Filter';

// const isFoo: ProductFilter = (product: SourcedGQLProduct) =>
//   product.meta.tagIds.includes('day-trip-000');
// const isFoo1: ProductFilter = (product: SourcedGQLProduct) =>
//   product.meta.tagIds.includes('family-friendly-000');
// const isFoo2: ProductFilter = (product: SourcedGQLProduct) =>
//   product.availability.weekdays.includes('wed');

export interface ProductFilterListViewProps {
  allProducts: SourcedGQLProduct[];
  filter: ProductFilter[];
  filteredProducts: SourcedGQLProduct[];
  setFilter: (filter: ProductFilter[]) => void;
}

// const tagFilter: ProductFilter = (product: SourcedGQLProduct, tagId: string) => (
//   product: SourcedGQLProduct,
// ) => product.meta.tagIds.includes(tagId);

function tagFilter(product: SourcedGQLProduct) {
  return (tagId: string) => {
    return product.meta.tagIds.includes(tagId);
  };
}

function cityAreaFilter(product: SourcedGQLProduct) {
  return (cityAreaId: string) => {
    return product.location.cityAreaId === cityAreaId;
  };
}

function countryAreaFilter(product: SourcedGQLProduct) {
  return (countryAreaId: string) => {
    return product.location.countryAreaId === countryAreaId;
  };
}

function categoryFilter(product: SourcedGQLProduct) {
  return (categoryId: string) => {
    return product.meta.categoryIds.includes(categoryId);
  };
}

function languageFilter(product: SourcedGQLProduct) {
  return (localeId: string) => {
    let res = false;

    for (
      let index = 0;
      index < product.availability.daytimeEvents.length && !res;
      index++
    ) {
      if (product.availability?.daytimeEvents?.[index].languageIds?.includes(localeId)) {
        res = true;
      }
    }
    return res;
  };
}

function priceFilter(product: SourcedGQLProduct) {
  return (min: number, max: number) => {
    let res = false;

    for (let index = 0; index < product.priceSystems.length && !res; index++) {
      if (
        product.priceSystems?.[index].publicBooking.basePrice >= min &&
        product.priceSystems?.[index].publicBooking.basePrice <= max
      ) {
        res = true;
      }
    }
    return res;
  };
}

function durationFilter(product: SourcedGQLProduct) {
  return (min: number, max: number) => {
    return product.availability.duration >= min && product.availability.duration <= max;
  };
}
const initialPriceFilter = {
  min: 1,
  max: 500,
};

const initialDurationFilter = {
  min: 0,
  max: 1 * 24 * 60,
};

const i18nPath = 'shop:screen-content-item';

export const ProductListFilterView = ({
  allProducts,
  filter,
  filteredProducts,
  setFilter,
}: ProductFilterListViewProps) => {
  const styles = useStyles();
  // const theme = useTheme();
  // const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  // tags
  const [currentTagFilterIds, setCurrentTagFilterIds] = React.useState<string[]>([]);
  const [availableProductTagIds, setAvailableProductTagIds] = React.useState<string[]>(
    [],
  );
  // cityAreas
  const [currentCityAreaFilterIds, setCurrentCityAreaFilterIds] = React.useState<
    string[]
  >([]);
  const [availableProductCityAreaIds, setAvailableProductCityAreaIds] = React.useState<
    string[]
  >([]);
  // countryAreas
  const [currentCountryAreaFilterIds, setCurrentCountryAreaFilterIds] = React.useState<
    string[]
  >([]);
  const [availableProductCountryAreaIds, setAvailableProductCountryAreaIds] =
    React.useState<string[]>([]);
  // categories
  const [currentCategoryFilterIds, setCurrentCategoryFilterIds] = React.useState<
    string[]
  >([]);
  const [availableProductCategoryIds, setAvailableProductCategoryIds] = React.useState<
    string[]
  >([]);
  // languages
  const [currentLanguageFilterIds, setCurrentLanguageFilterIds] = React.useState<
    string[]
  >([]);
  const [availableProductLanguageIds, setAvailableProductLanguageIds] = React.useState<
    string[]
  >([]);

  const [priceFilterRange, setPriceFilterRange] = React.useState<{
    min: number;
    max: number;
  }>(initialPriceFilter);

  const [durationFilterRange, setDurationFilterRange] = React.useState<{
    min: number;
    max: number;
  }>(initialPriceFilter);

  // NOTE: get available ids once (and flatten arrays if necessary)- executed once to create a set of unique ids for
  React.useEffect(() => {
    // TODO: filter out not more valid tags here ? => should be done on product export => scan product for invalid categoryIds/categoryTypeIds/orgIds/geostuff etc
    const allTagIdsInAllProducts = allProducts
      .filter((item) => Boolean(item.meta.tagIds))
      .map((item) => item.meta.tagIds.map((id) => id))
      .flat();
    setAvailableProductTagIds([...new Set(allTagIdsInAllProducts)]); // aggregate UniqueTagIds

    const allCityAreaIdsInAllProducts = allProducts
      .filter((item) => Boolean(item.location.cityAreaId))
      .map((item) => item.location.cityAreaId);
    setAvailableProductCityAreaIds([...new Set(allCityAreaIdsInAllProducts)]); // aggregate UniqueTagIds

    const allCountryAreaIdsInAllProducts = allProducts
      .filter((item) => Boolean(item.location.countryAreaId))
      .map((item) => item.location.countryAreaId);
    setAvailableProductCountryAreaIds([...new Set(allCountryAreaIdsInAllProducts)]); // aggregate UniqueTagIds

    const allCategoryIdsInAllProducts = allProducts
      .filter((item) => Boolean(item.meta.categoryIds))
      .map((item) => item.meta.categoryIds.map((id) => id))
      .flat();
    setAvailableProductCategoryIds([...new Set(allCategoryIdsInAllProducts)]); // aggregate UniqueTagIds

    const allLocaleIdsInAllProducts = allProducts
      .filter((item) => Boolean(item.availability.daytimeEvents.length))
      .map(
        (item) =>
          item.availability.daytimeEvents?.map((event) =>
            event.languageIds?.map((locId) => locId || []),
          ) || [],
      )
      .flat()
      .flat()
      .filter((v) => Boolean(v));
    setAvailableProductLanguageIds([...new Set(allLocaleIdsInAllProducts)]); // aggregate UniqueTagIds

    // set once initially
    setPriceFilterRange(initialPriceFilter);
    setDurationFilterRange(initialDurationFilter);
  }, [allProducts]);

  // NOTE: FILTER SET FUNCTIONS => dismisses selection before

  const setTagFilter = (filterId: string, isOn: boolean) => {
    if (isOn) {
      // const newIds = [...currentTagFilterIds, filterId];
      const newIds = [filterId];
      setCurrentTagFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
      ]);
    } else {
      const newIds = [...currentTagFilterIds.filter((id) => id !== filterId)];
      setCurrentTagFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
      ]);
    }
  };

  const setCityAreaFilter = (filterId: string, isOn: boolean) => {
    if (isOn) {
      // const newIds = [...currentTagFilterIds, filterId];
      const newIds = [filterId];
      setCurrentCityAreaFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id)),
      ]);
    } else {
      const newIds = [...currentCityAreaFilterIds.filter((id) => id !== filterId)];
      setCurrentCityAreaFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id)),
      ]);
    }
  };

  const setCountryAreaFilter = (filterId: string, isOn: boolean) => {
    if (isOn) {
      // const newIds = [...currentTagFilterIds, filterId];
      const newIds = [filterId];
      setCurrentCountryAreaFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id)),
      ]);
    } else {
      const newIds = [...currentCountryAreaFilterIds.filter((id) => id !== filterId)];
      setCurrentCountryAreaFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id)),
      ]);
    }
  };

  const setCategoryFilter = (filterId: string, isOn: boolean) => {
    if (isOn) {
      // const newIds = [...currentTagFilterIds, filterId];
      const newIds = [filterId];
      setCurrentCategoryFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => categoryFilter(p)(id)),
      ]);
    } else {
      const newIds = [...currentCityAreaFilterIds.filter((id) => id !== filterId)];
      setCurrentCategoryFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentLanguageFilterIds.map(
          (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => categoryFilter(p)(id)),
      ]);
    }
  };

  const setLanguageFilter = (filterId: string, isOn: boolean) => {
    if (isOn) {
      // const newIds = [...currentTagFilterIds, filterId];
      const newIds = [filterId];
      setCurrentLanguageFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => languageFilter(p)(id)),
      ]);
    } else {
      const newIds = [...currentCityAreaFilterIds.filter((id) => id !== filterId)];
      setCurrentLanguageFilterIds(newIds);
      setFilter([
        // recreate other filter states
        ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
        ...currentCountryAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
        ),
        ...currentCityAreaFilterIds.map(
          (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
        ),
        ...currentCategoryFilterIds.map(
          (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
        ),
        (p: SourcedGQLProduct) =>
          priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
        (p: SourcedGQLProduct) =>
          durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
        ...newIds.map((id) => (p: SourcedGQLProduct) => languageFilter(p)(id)),
      ]);
    }
  };

  const setPriceFilter = (min: number, max: number) => {
    setFilter([
      // recreate other filter states
      ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
      ...currentCountryAreaFilterIds.map(
        (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
      ),
      ...currentCityAreaFilterIds.map(
        (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
      ),
      ...currentCategoryFilterIds.map(
        (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
      ),
      ...currentLanguageFilterIds.map(
        (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
      ),
      (p: SourcedGQLProduct) =>
        durationFilter(p)(durationFilterRange.min, durationFilterRange.max),
      (p: SourcedGQLProduct) => priceFilter(p)(min, max),
    ]);
  };

  const debouncedSetPriceFilter = React.useMemo(
    () => debounce(setPriceFilter, 250),
    [
      currentTagFilterIds,
      currentCategoryFilterIds,
      currentLanguageFilterIds,
      durationFilterRange.min,
      durationFilterRange.max,
    ],
  );

  const setDurationFilter = (min: number, max: number) => {
    setFilter([
      // recreate other filter states
      ...currentTagFilterIds.map((id) => (p: SourcedGQLProduct) => tagFilter(p)(id)),
      ...currentCountryAreaFilterIds.map(
        (id) => (p: SourcedGQLProduct) => countryAreaFilter(p)(id),
      ),
      ...currentCityAreaFilterIds.map(
        (id) => (p: SourcedGQLProduct) => cityAreaFilter(p)(id),
      ),
      ...currentCategoryFilterIds.map(
        (id) => (p: SourcedGQLProduct) => categoryFilter(p)(id),
      ),
      ...currentLanguageFilterIds.map(
        (id) => (p: SourcedGQLProduct) => languageFilter(p)(id),
      ),
      (p: SourcedGQLProduct) =>
        priceFilter(p)(priceFilterRange.min, priceFilterRange.max),
      (p: SourcedGQLProduct) => durationFilter(p)(min, max),
    ]);
  };

  // const debouncedSetDurationFilter = React.useCallback(
  //   debounce(setDurationFilter, 250),
  //   [],
  // );

  const debouncedSetDurationFilter = React.useMemo(
    () => debounce(setDurationFilter, 250),
    [
      currentTagFilterIds,
      currentCategoryFilterIds,
      currentLanguageFilterIds,
      priceFilterRange.min,
      priceFilterRange.max,
    ],
  );

  return (
    <div className='w-full text-center text-p3 dark:text-neutral-50 pb-6 md:p-8 md:border-2 md:border-accent-500 rounded-2xl md:bg-accent-400 md:bg-opacity-[0.08] flex flex-col gap-4 dark:text-neutral-50 md:dark:bg-neutral-800'>
      <div className='py-4'>
        <h3 className='text-p1 font-bold mb-3'><Localized dictKey={`${i18nPath}.filter.title`} /></h3>
        <p>
          <Localized dictKey={`${i18nPath}.filter.subtitle`} />
        </p>
      </div>

      {availableProductTagIds.length ? (
        <TagFilter
          availableTagIds={availableProductTagIds}
          currentTagFilterIds={currentTagFilterIds}
          setTagFilter={setTagFilter}
        />
      ) : null}

      <div className='md:grid md:grid-cols-3 gap-3'>
        <div className=''>
          <CountryAreaFilter
            availableCountryAreaIds={availableProductCountryAreaIds}
            currentCountryAreaFilterIds={currentCountryAreaFilterIds}
            setCountryAreaFilter={setCountryAreaFilter}
          />
        </div>
        <div>
          <CityAreaFilter
            availableCityAreaIds={availableProductCityAreaIds}
            currentCityAreaFilterIds={currentCityAreaFilterIds}
            setCityAreaFilter={setCityAreaFilter}
          />
        </div>
        <div className=''>
          <CategoryFilter
            availableCategoryIds={availableProductCategoryIds}
            currentCategoryFilterIds={currentCategoryFilterIds}
            setCategoryFilter={setCategoryFilter}
          />
        </div>
        <div className=''>
          <LanguageFilter
            availableLanguageIds={availableProductLanguageIds}
            currentLanguageFilterIds={currentLanguageFilterIds}
            setLanguageFilter={setLanguageFilter}
          />
        </div>
        <div className='px-4'>
          <PriceFilter
            currentPriceFilterValues={priceFilterRange}
            setPriceFilter={(min, max) => {
              setPriceFilterRange({ min, max });
              debouncedSetPriceFilter(min, max);
            }}
          />
        </div>
        <div className='px-4'>
          <DurationFilter
            currentDurationFilterValues={durationFilterRange}
            setDurationFilter={(min, max) => {
              setDurationFilterRange({ min, max });
              debouncedSetDurationFilter(min, max);
            }}
          />
        </div>
        <div className=''>
          <ServicesFilter
            availableLanguageIds={availableProductLanguageIds}
            currentLanguageFilterIds={currentLanguageFilterIds}
            setLanguageFilter={setLanguageFilter}
          />
        </div>
      </div>
    </div>
  );
};
