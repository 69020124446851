import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  DatePicker,
  DropDown,
  GlassPaper,
  Grid,
  Typography,
  TextInputField,
} from 'src/app/components';
import { DatePickerType, Weekday } from 'src/app/constants';

export interface CollectionDateFilterProps {
  date: MaterialUiPickersDate;
  time: MaterialUiPickersDate;
  setDate: (date: MaterialUiPickersDate) => void;
  setTime: (date: MaterialUiPickersDate) => void;
}

export enum Month {
  JAN = 'jan',
  FEB = 'feb',
  MAR = 'mar',
  APR = 'apr',
  MAI = 'mai',
  JUN = 'jun',
  JUL = 'jul',
  AUG = 'aug',
  SEP = 'sep',
  OCT = 'oct',
  NOV = 'nov',
  DEC = 'dec',
}

const months: Month[] = [
  Month.JAN,
  Month.FEB,
  Month.MAR,
  Month.APR,
  Month.MAI,
  Month.JUN,
  Month.JUL,
  Month.AUG,
  Month.SEP,
  Month.OCT,
  Month.NOV,
  Month.DEC,
];

const years: int[] = [2020, 2021];

const weekDays: Weekday[] = [
  Weekday.SUN,
  Weekday.MON,
  Weekday.TUE,
  Weekday.WED,
  Weekday.THU,
  Weekday.FRI,
  Weekday.SAT,
];

const CollectionDateFilter = (props: CollectionDateFilterProps) => {
  const { date, setDate, time, setTime } = props;
  const [selectedWeekday, setSelectedWeekday] = React.useState<Weekday | null>(null);
  const [selectedWeek, setSelectedWeek] = React.useState<int | null>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<Month | null>(null);
  const [selectedYear, setSelectedYear] = React.useState<int | null>(null);
  // console.info('selectedMonth: ', selectedMonth);
  // console.info('selectedYear ', selectedYear);
  return (
    <GlassPaper style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>{'Select by day'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                selectedDate={date!}
                setDate={setDate}
                type={DatePickerType.DAY}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>{'Select by time'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                selectedDate={time}
                setDate={(t) => setTime(t)}
                type={DatePickerType.TIME}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>{'Select by weekday'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <DropDown
                // themeMode={themeMode || ThemeMode.LIGHT}
                fullWidth
                variant={'outlined'}
                color={'primary'}
                label={
                  selectedWeekday || selectedWeekday === 0
                    ? weekDays[selectedWeekday]
                    : 'Select weekday'
                }
                options={weekDays.map((wd) => wd)}
                selectedIndex={selectedWeekday}
                onChange={(idx: number) => setSelectedWeekday(idx)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>{'Select by week'}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputField
                fullWidth
                variant={'outlined'}
                color={'primary'}
                label={'Week No.:'}
                onChange={(e) => setSelectedWeek(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>{'Select by month / year'}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <DropDown
                // themeMode={themeMode || ThemeMode.LIGHT}
                fullWidth
                variant={'outlined'}
                color={'primary'}
                label={
                  selectedMonth || selectedMonth === 0
                    ? months[selectedMonth]
                    : 'Select month'
                }
                options={months.map((m) => m)}
                selectedIndex={selectedMonth}
                onChange={(idx: number) => setSelectedMonth(idx)}
                // {...passProps}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DropDown
                // themeMode={themeMode || ThemeMode.LIGHT}
                fullWidth
                variant={'outlined'}
                color={'primary'}
                label={
                  selectedYear || selectedYear === 0 ? years[selectedYear] : 'Select year'
                }
                options={years.map((y) => y)}
                selectedIndex={selectedYear}
                onChange={(idx: number) => setSelectedYear(idx)}
                // {...passProps}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GlassPaper>
  );
};

export { CollectionDateFilter };
