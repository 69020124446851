import * as React from 'react';

import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';

// TODO: refactor components for general usage?
import { AppFeature } from 'src/app/constants';
import { isLocalhost } from 'src/app/lib';

export const useAppFeatures = (featureId: AppFeature) => {
  const { isEmailVerified, isLoggedIn, isOrganisationUser, isSystemUser } =
    useAppContext();
  const appConfig = useAppStaticQueryConfig();
  const [hasFeature, setHasFeature] = React.useState<boolean>(false);

  const isProduction = Boolean(appConfig.env === 'PRODUCTION'); // FIXME: remove later or use FLAG fetched from BE (use fb appConfig)
  React.useEffect(() => {
    if (featureId === AppFeature.ORGANISATION_AREA) {
      if (isLoggedIn && isOrganisationUser && isEmailVerified) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
    if (featureId === AppFeature.SYSTEM_AREA) {
      if (isLoggedIn && isSystemUser && isEmailVerified) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
    if (featureId === AppFeature.USER_ACCOUNT) {
      if (isLoggedIn && !isOrganisationUser && isEmailVerified) {
        setHasFeature(true);
        // NOTE: ENABLE FEATURE ON LOCALHOST
      } else if (isLoggedIn && isLocalhost()) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
    if (featureId === AppFeature.USER_CHECKOUT) {
      if (isLoggedIn && !isOrganisationUser && isEmailVerified && !isProduction) {
        setHasFeature(true);
        // NOTE: ENABLE FEATURE ON LOCALHOST
      } else if (isLoggedIn && isLocalhost()) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
    if (featureId === AppFeature.USER_WISHLIST) {
      if (isLoggedIn && !isOrganisationUser) {
        setHasFeature(true);
        // NOTE: ENABLE FEATURE ON LOCALHOST
      } else if (isLoggedIn && isLocalhost()) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
    if (featureId === AppFeature.WARNING_ORGANISATION_ACCOUNT) {
      // NOTE: DISABLE FEATURE ON LOCALHOST
      if (isLoggedIn && isOrganisationUser && !isLocalhost()) {
        setHasFeature(true);
      } else {
        setHasFeature(false);
      }
    }
  }, [featureId, isLoggedIn, isOrganisationUser, isSystemUser]);
  return { hasFeature };
};
