import * as React from 'react';

import { Divider, Grid } from 'src/app/components';
import { useStyles } from 'src/app/context';

export interface FormLayoutProps {
  children?: React.ReactNode;
  className?: string;
  formControl?: React.ReactNode;
  header?: React.ReactNode;
  renderErrors?: React.ReactNode;
}

export const FormLayout = ({
  children,
  className,
  formControl,
  header,
  renderErrors,
}: FormLayoutProps) => {
  const styles = useStyles();
  return (
    <div className={className || ''}>
      {header ? (
        <>
          {header}
        </>
      ) : null}
      {renderErrors && React.isValidElement(renderErrors) && (
        <>
          {renderErrors}
        </>
      )}
      <>
        {/** NOTE: form__minHeight effect is commented out in styles */}
        {/* <Grid container spacing={0} className={styles.form__minHeight}> */}
        {children}
        {/* </Grid> */}
      </>
      {formControl ? (
        <>
          {formControl}
        </>
      ) : null}
    </div>
  );
};
