import * as React from 'react';
import clsx from 'clsx';

import { Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';

import { Slider } from './Slider';

interface PriceFilterProps {
  currentPriceFilterValues: { min: number; max: number };
  setPriceFilter: (min: number, max: number) => void;
}

export const PriceFilter = ({
  currentPriceFilterValues,
  setPriceFilter,
}: PriceFilterProps) => {
  const styles = useStyles();

  const onChangeFilter = (
    e: React.ChangeEvent<{}>,
    value: number | number[],
  ) /*: void & React.FormEventHandler<any> */ => {
    if (Array.isArray(value)) {
      setPriceFilter(value[0], value[1]);
    }
  };

  return (
    <Grid container className={clsx(styles.pt__2, styles.px__1)}>
      <Grid item xs={12} className={clsx(styles.px__1)}>
        <Slider
          disabled={!currentPriceFilterValues?.min || !currentPriceFilterValues?.max}
          valueLabelDisplay='auto'
          // aria-label='slider'
          getAriaLabel={(idx) => 'slider'}
          // defaultValue={[10, 100]}
          min={1}
          max={500}
          // valueLabelDisplay='on'
          valueLabelFormat={(val, idx) => <span>{val} €</span>}
          value={[currentPriceFilterValues.min, currentPriceFilterValues.max]}
          onChange={onChangeFilter}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography align={'left'} variant={'h6'} renderAs={'p'}>
              {'Price range'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align={'right'} variant={'h6'} renderAs={'p'}>
              {`${currentPriceFilterValues.min} € - ${currentPriceFilterValues.max} €`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
