import * as React from 'react';
import Button from '@material-ui/core/Button';

import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import ButtonGroup, { ButtonGroupProps } from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import {
  // Button,
  // ButtonGroup, // NOTE: don't use => ref issues
  // ButtonGroupProps,
  Paper,
  Grid,
  Typography,
} from 'src/app/components';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { ThemeMode } from 'src/app.consumer/constants';
import clsx from 'clsx';
import { useLocalizationContext, useStyles } from 'src/app/context';
import { classNames } from '../ButtonTw';

export interface DropDownProps extends CombinedBaseComponentProps<ButtonGroupProps> {
  containerClassName?: string;
  label: string | React.ReactNode;
  options: string[] | React.ReactNode[];
  onChange?: (index: number) => void;
  openOnHover?: boolean;
  selectedIndex?: number; // FIXME: is used both I think (<T>?)
  themeMode?: ThemeMode;
  placement?: PopperPlacementType; // TODO:
  showArrowIcon: boolean;
}

// TODO: remove onClick handler or make special version with selected id functionality
/**
 * DropDown / WAPDropDown
 * @param { DropDownProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const DropDown = (props: DropDownProps) => {
  const {
    containerClassName,
    label,
    options,
    onChange,
    openOnHover,
    selectedIndex,
    showArrowIcon,
    themeMode,
    placement,
    size,
    ...passProps
  } = props;
  const [isOpen, setOpen] = React.useState(false);
  const { language } = useLocalizationContext();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles();
  // const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    idx: number,
  ) => {
    // setSelectedIndex(index);
    setOpen(false);
    if (onChange) {
      onChange(idx);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // let backgroundColor = passProps.color ? '#ffa500dd' : undefined;
  // if (props.disabled) {
  //   backgroundColor = '#00000020';
  // }

  const useDarkTheme = themeMode && themeMode === ThemeMode.DARK;

  return (
    <Grid
      container
      item
      xs={12}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      className={containerClassName}
    >
      <ButtonGroup
        // className={clsx(
        //   themeMode && themeMode === ThemeMode.DARK
        //     ? styles.buttonGroupDark
        //     : styles.buttonGroup,
        // )}
        // classes={language !== 'cimode' ? {} : { root: styles.textTransform__none }}
        // variant={'contained'}
        ref={anchorRef}
        aria-label='split button'
        fullWidth
        size={size || 'large'}
        {...passProps}
      >
        <Button
          fullWidth
          onClick={handleToggle}
          size={size || 'large'}
          // classes={language !== 'cimode' ? {} : { root: styles.textTransform__none }}
          // onPointerEnter={() => (!isOpen ? setOpen(true) : null)} // NOTE: on hover open feature (needs context or better redux)
        >
          {typeof label !== 'string' ? label : { label }}
          <ArrowDropDownIcon className={props?.className} />
        </Button>
        {showArrowIcon || typeof showArrowIcon === 'undefined' ? (
          <Button
            size='small'
            aria-controls={isOpen ? 'split-button-menu' : undefined}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-label='select merge strategy - todo'
            aria-haspopup='menu'
            onClick={handleToggle}
            style={{ maxWidth: '32px' }}
          >
            <ArrowDropDownIcon />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper
        placement={placement || 'bottom'}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10000 }} // NOTE: maybe a higher value will be necessary
        // PaperProps={{
        //   style: {
        //     backgroundColor: '#f0f',
        //     // overflow: 'hidden',
        //     // boxShadow: 'none',
        //     ...glassStyle,
        //   },
        //   // className: 'glass-item',
        // }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            {/* className={'pop-out'} */}
            <Paper isTransparent themeMode={themeMode || ThemeMode.LIGHT} elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='split-button-menu'
                  classes={{
                    root: clsx(
                      styles.muiList__root,
                      useDarkTheme
                        ? styles.muiList__root__dark
                        : styles.muiList__root__light,
                    ),
                  }}
                  // className={styles.glassPaper}
                >
                  {options?.map((option, index) => {
                    const isSelected =
                      !Number.isNaN(selectedIndex) && index === selectedIndex;
                    // return <React.Fragment key={option?.key || index}>{option}</React.Fragment>; // FIXME either pass strings or JSX
                    return (
                      <MenuItem
                        key={`dropdown_menu_item_${index}`}
                        selected={isSelected}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            {typeof option === 'string' ? (
                              <Typography
                                align={'center'}
                                color={isSelected ? 'primary' : 'inherit'}
                              >
                                {option || ''}
                              </Typography>
                            ) : (
                              option
                            )}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                  }) || null}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

const WAPDropDown = (props: CombinedAppProps<DropDownProps>) =>
  withoutAppProps<CombinedAppProps<DropDownProps>, DropDownProps>(DropDown, props);

export { DropDown, WAPDropDown };
