import * as React from 'react';
import { ButtonTw } from 'src/app/components';
import MenuIcon from '@material-ui/icons/Menu';

export interface BurgerButtonProps {
  onClick: () => void;
}

const BurgerButton = ({ onClick }: BurgerButtonProps) => {
  return (
    <ButtonTw
      variant={'text'}
      onClick={onClick}
    >
      <MenuIcon />
    </ButtonTw>
  );
};

export { BurgerButton };