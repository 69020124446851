import * as React from 'react';
import {
  Divider,
  Grid,
  LoadingIndicator,
  Localized,
  Typography,
} from 'src/app/components';
import { CountryProductsContainer } from 'src/app.consumer/components';

import { useShoppingCartContext } from 'src/app/context';
import { CartSystemType } from 'src/app/constants';
import { CheckoutBox, FrequentlyAskedCheckoutQuestions } from './components';
import { ShoppingCartList } from './shoppingCartList';

import { ShoppingCartLocalStorageImportSuggestion } from './ShoppingCartLocalStorageImportSuggestion';

const i18nPath = 'shop:/shopping-cart';

export const ShoppingCartLayout = () => {
  const { hasLocalCart, isImporting, onImportLocalStorageCart, operatingMode } =
    useShoppingCartContext();

  return (
    <>
      {operatingMode === CartSystemType.CLOUD && hasLocalCart ? (
        <div className='py-4'>
          <ShoppingCartLocalStorageImportSuggestion
            onImportLocalStorageCart={onImportLocalStorageCart}
            isImporting={!!isImporting}
          />
        </div>
      ) : null}

      {/* <Grid item xs={12}>
        <Typography variant={'h4'} renderAs={'h1'}>
          <Localized dictKey={`${i18nPath}.title`} />
        </Typography>
      </Grid> */}

      <div className='flex flex-col lg:flex-row gap-8 pb-4 md:pb-12'>
        <div className='lg:w-8/12'>
          {isImporting ? (
            <LoadingIndicator />
          ) : (
            <>
              {true ? ( // TODO:
                <ShoppingCartList />
              ) : (
                <p className='text-p1 font-semibold'>
                  <Localized dictKey={`${i18nPath}.cart.cart-empty`} />
                </p>
              )}
            </>
          )}
        </div>
        <div className='lg:w-4/12'>
          <CheckoutBox i18nPath={i18nPath} />
          <div className='pt-8'>
            <FrequentlyAskedCheckoutQuestions i18nPath={i18nPath} />
          </div>
        </div>
      </div>
      <section className='py-4 md:py-10'>
        <CountryProductsContainer
          // cityId={'hr-split-000'}
          // countryId={'hr'}
          description={''}
          type={'city'}
          className='my-4'
          title={<Localized dictKey={`${i18nPath}.recommendation-products.title`} />}
        />
      </section>
    </>
  );
};
