import * as React from 'react';
import Flags from 'country-flag-icons/react/3x2';

import { FlagCountryId } from 'src/app/constants';
// import { useTranslation } from 'react-i18next'; // TODO: tooltips i18n

interface InternationalFlagProps {
  flagCountryId: FlagCountryId;
}

export const InternationalFlag = ({ flagCountryId }: InternationalFlagProps) => {
  const className = 'max-w-[32px] max-h-[16px]';
  return flagCountryId ? (
    <>
      {flagCountryId === FlagCountryId.DE ? (
        <Flags.DE title='Germany' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.FR ? (
        <Flags.FR title='France' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.GB ? (
        <Flags.GB title='United Kingdom' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.HR ? (
        <Flags.HR title='Croatia' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.IT ? (
        <Flags.IT title='Italia' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.RU ? (
        <Flags.RU title='Russia' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.ES ? (
        <Flags.ES title='Spain' className={className} />
      ) : null}
      {flagCountryId === FlagCountryId.US ? (
        <Flags.US title='United States' className={className} />
      ) : null}
    </>
  ) : null;
};
