import { SourcedGQLProduct, FirebaseTimestamp, DayTime } from 'src/app/types';
import dayjs from 'dayjs';
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export function getDayTimeFromTimeString(timeString: string): DayTime {
  const dayTime: DayTime = {
    // TODO: error handling
    hours: '--',
    minutes: '--',
  };
  if (timeString) {
    return {
      hours: timeString?.split(':')[0].padStart(2, '0'), // FIXME: add padding also while creating a new product
      minutes: timeString?.split(':')[1].padStart(2, '0'),
    };
  }
  return dayTime;
}

// https://stackoverflow.com/questions/52247445/how-do-i-convert-a-firestore-date-timestamp-to-a-js-date
export function formatFirebaseTimestamp(
  fbTimeStamp: FirebaseTimestamp,
  formatString?: string,
) {
  // FIXME no error handling or messaging besides setting to epoch 0 as fallback
  return dayjs
    .unix(parseInt(fbTimeStamp?.seconds?.toString() || '0', 10) || 0)
    .format(formatString || 'DD/MM/YYYY HH:mm');
}

export const parseFirebaseTimestampToDayJs = (fbTimeStamp: FirebaseTimestamp) =>
  dayjs.unix(fbTimeStamp.seconds);

export const parseFirebaseTimestampToDate = (fbTimeStamp: FirebaseTimestamp) =>
  parseFirebaseTimestampToDayJs(fbTimeStamp).toDate();

// Datepicker product helper

const WeekdayNumberMapping: Record<string, number> = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

const isDateOnValidWeekday = (
  date: MaterialUiPickersDate,
  product: SourcedGQLProduct,
) => {
  for (let index = 0; index < product.availability.weekdays.length; index++) {
    const mappingDayNumber = WeekdayNumberMapping[product.availability.weekdays[index]];
    if (date?.day() === mappingDayNumber) {
      return true;
    }
  }
  return false;
};

const isDateInbetweenUnavailablePeriods = (
  date: MaterialUiPickersDate,
  product: SourcedGQLProduct,
) => {
  // console.log('product uvp: ', product);
  for (
    let index = 0;
    index < product.availability.unavailabilityPeriods.length;
    index++
  ) {
    // NOTE: format "2021-12-26" comes from FE
    let toDates:any = new Date(product.availability.unavailabilityPeriods[index].toDate);
    toDates.setDate(toDates.getDate());
    const toDate = Intl.DateTimeFormat('sv-SE').format(new Date(toDates));
    let fromDates:any = new Date(product.availability.unavailabilityPeriods[index].fromDate);
    fromDates.setDate(fromDates.getDate() - 1);
    const fromDate = Intl.DateTimeFormat('sv-SE').format(new Date(fromDates));
    const parsedFromDate = dayjs(
      new Date(
        `${fromDate}T00:00:00.000+01:00`,
      ),
    ); // FIXME use timezone conversion
    // NOTE: format "2021-12-26" comes from FE

    const parsedToDate = dayjs(
      new Date(
        `${toDate}T00:00:00.000+01:00`,
      ),
    ); // FIXME use timezone conversion
    if (date?.isAfter(parsedFromDate) && date?.isBefore(parsedToDate)) {
      return true;
    }
  }
  return false;
};

export const isProductEventDateDisabled = (
  date: MaterialUiPickersDate,
  product: SourcedGQLProduct,
) => {
  let isDayDisabled = false;
  if (!isDateOnValidWeekday(date, product)) {
    isDayDisabled = true;
  }
  if (isDateInbetweenUnavailablePeriods(date, product)) {
    isDayDisabled = true;
  }
  return isDayDisabled;
};

export const createBasicTimestamp = (cutOffTail?: number) => {
  const timestamp = new Date().getTime().toString();
  return cutOffTail ? timestamp.slice(0, timestamp.length - cutOffTail) : timestamp;
};
