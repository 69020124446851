import * as React from 'react';
import { useLocation } from '@reach/router';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';

import { DropDown, Typography } from 'src/app/components';
import { useLocalizationContext } from 'src/app/hooks';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';

import { ThemeMode } from 'src/app.consumer/constants';
import clsx from 'clsx';
import { useAppContext, useStyles } from 'src/app/context';
import { isEnvironment } from 'src/app/lib';
import { AppEnvironment, AppLocale } from 'src/app/constants';
import { getAuth } from 'firebase/auth';
import { classNames } from '../Button';

export interface LanguageSelectionProps extends BaseComponentProps {
  size?: 'large' | 'small';
  themeMode?: ThemeMode;
}

/**
 * LanguageSelection / WAPLanguageSelection
 * @param { LanguageSelectionProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
function LanguageSelection(
  props: LanguageSelectionProps,
): React.ReactElement<LanguageSelectionProps> {
  const { children, className, size = 'large', themeMode, ...passProps } = props;
  const { i18n } = useTranslation();
  const { language, setLanguage } = useLocalizationContext();
  const styles = useStyles();

  const { href } = useLocation();

  const isDevEnv =
    isEnvironment(href || '', AppEnvironment.DEVELOPMENT) ||
    isEnvironment(href || '', AppEnvironment.DEVELOPMENT_NETLIFY);

  const [selectedIndex, setSelectedIndex] = React.useState<number>(
    Object.getOwnPropertyNames(i18n.store.data).findIndex((p) => p === language) || 0,
  );

  const { isSystemUser, firebase } = useAppContext();
  const auth = getAuth(firebase);
  const isStipe = auth.currentUser?.email === 'stjepan.raic@directbox.com';

  const availableLanguages =
    isDevEnv || isSystemUser || isStipe
      ? [...Object.getOwnPropertyNames(i18n.store.data), 'cimode']
      : [...Object.getOwnPropertyNames(i18n.store.data)];

  // TODO: use to source locale languages from db config

  const onLanguageChange = (index: number) => {
    const newLang = availableLanguages[index];
    setSelectedIndex(index);
    setLanguage(newLang as AppLocale);
  };

  return (
    <DropDown
      themeMode={themeMode || ThemeMode.LIGHT}
      variant='text'
      label={<p className={`font-normal text-sm capitalize ${className}`}>{language}</p>}
      options={availableLanguages.map((lang) => `${lang.toUpperCase()}`)}
      selectedIndex={selectedIndex}
      onChange={(idx: number) => onLanguageChange(idx)}
      showArrowIcon={false}
      openOnHover
      fullWidth
      {...{ passProps, className }}
    >
      {children}
    </DropDown>
  );
}

const WAPLanguageSelection = (props: CombinedAppProps<LanguageSelectionProps>) =>
  withoutAppProps<CombinedAppProps<LanguageSelectionProps>, LanguageSelectionProps>(
    LanguageSelection,
    props,
  );

export { LanguageSelection, WAPLanguageSelection };
