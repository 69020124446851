export enum OrganisationTurnoverAmount {
  'NOT_AVAILABLE_YET' = 'not_available_yet',
  'LESS_THAN_FIFTY' = '0-50000',
  'BETWEEN_FIFTY_AND_ONE_HUNDRED' = '50000-100000',
  'BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED' = '100000-300000',
  'BETWEEN_THREE_HUNDRED_AND_FIVE_HUNDRED' = '300000-500000',
  'BETWEEN_FIVE_HUNDRED_AND_MILLION' = '500000-1000000',
  'ABOVE_MILLION' = '1000000+',
}
export enum SocialMediaType {
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
}
