import { graphql, useStaticQuery } from 'gatsby';

import { GQLSiteData, FirebaseEnvConfig } from 'src/app/types';

export function useFirebaseStaticQueryConfig(): FirebaseEnvConfig {
  const {
    site: {
      siteMetadata: { appData },
    },
  } = useStaticQuery<GQLSiteData>(graphql`
    {
      site {
        siteMetadata {
          appData {
            firebase {
              apiKey
              authDomain
              databaseURL
              projectId
              storageBucket
              messagingSenderId
              appId
              measurementId
            }
          }
        }
      }
    }
  `);
  return { ...appData.firebase };
}
