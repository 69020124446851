import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';

import { TableColumn } from 'src/app/types';
// const filterOptionsParams = (options, { inputValue }) => matchSorter(options, inputValue);
// const filterOptions = createFilterOptions(filterOptionsParams);
// todo parametrize and pass as props
export const filterOptions = createFilterOptions({
  // matchFrom: 'start',
  // stringify: (option) => option.id,
  // alternative multi search
  stringify: (option) => option.continentId + option.id + option.testprop, // DUMMY FOR SYSTEM DATA TABLES
  // stringify: (option) => option.id + option.continentId || option.continentId + option.id,
});

// browser warning: Failed prop type: Invalid prop `width` of type `string` supplied to `Column`, expected `number`.
// const getColWidthByColCount = (count: number): string | number => {
//   switch (count) {
//     case 1:
//       return '100%';
//     case 2:
//       return '50%';
//     case 3:
//       return '33%';
//     case 4:
//       return '25%';
//     case 5:
//       return '20%';
//     default:
//       return '120px';
//   }
// };

// FIXME: calculated in px
const getColWidthByColCount = (count: number): number => {
  switch (count) {
    case 1:
      return 600;
    case 2:
      return 300;
    case 3:
      return 200;
    case 4:
      return 150;
    case 5:
      return 150;
    default:
      return 120;
  }
};

export const createHeaderTableData = (dataProperties: string[]): TableColumn[] => {
  return dataProperties?.length
    ? [
        { dataKey: 'index', label: '#', minWidth: 48, width: 48 },
        ...dataProperties.map((dataPropName) => {
          // if (dataPropName === 'id') {
          //   return {
          //     dataKey: dataPropName,
          //     label: dataPropName,
          //     minWidth: 80,
          //     width: 80,
          //   };
          // }
          return {
            dataKey: dataPropName,
            label: dataPropName,
            // minWidth: 120,
            // minWidth: dataProperties.length < 5 ? 240 : 120,
            minWidth: getColWidthByColCount(dataProperties.length),
            width: getColWidthByColCount(dataProperties.length),
            // width: 120,
            // width: dataProperties.length < 4 ? 180 : 120,
          };
        }),
        { dataKey: 'widthX', label: 'widthX', width: 1920 },
        // { dataKey: 'actions', label: 'Actions', width: 160 },
      ]
    : [];
};

export const transformDataForTable = <T extends Record<string, any>>(results: T[]) => {
  return results?.length
    ? [
        ...results.map((r: T, idx) => ({
          index: (idx + 1).toString(),
          imageCount: r.imageUrls?.length?.toString() || '',
          widthX: 'widthX',
          ...r,
        })),
      ]
    : [];
};

// move to common
// TODO: fix types so that added and exclued propNames are typesafe
// export const filterOutTableUtilityProps = <T extends Record<string, any>>(
//   object: T,
//   filterPropNames: string[],
// ): T => {
//   const newObject: T = {} as T;
//   const includedPropertyNames: string[] =
//     Object.getOwnPropertyNames(object).filter(
//       (propName) => !filterPropNames.includes(propName),
//     ) || [];
//   for (let index = 0; index < includedPropertyNames.length; index++) {
//     newObject[includedPropertyNames[index]] = object[includedPropertyNames[index]];
//   }
//   return newObject as T;
// };
