import {WhereFilterOp} from 'firebase/firestore';

export enum AdminViewLayout {
  default = 'default',
  fullview = 'fullview',
}

export const WhereFilterOperator: WhereFilterOp[] = [
  '<',
  '>',
  '<=',
  '>=',
  '==',
  '!=',
  'array-contains',
  'array-contains-any',
  'in',
  'not-in',
];

export enum AdminScreenNavigationElement {
  button = 'button',
  expandableContainer = 'expandableContainer',
}

export enum AdminType {
  ORGANISATION = 'organisation',
  SYSTEM = 'system',
}
