import * as React from 'react';
import { Grid, SignupBox } from 'src/app/components';
import { ActivityProduct, BaseComponentProps } from 'src/app/types';
import { CountryProductsContainer } from 'src/app.consumer/components';
// import { ProductType } from 'src/app/constants';
// import Carousell from './Carousell';
// import { ProductRating } from '../components/ProductRating';

// import { ProductReviews } from '../ProductReviews'; // TODO
import { useStyles } from 'src/app/context';

// const carousellItems = [
//   {
//     imgAltLabel: '',
//     imgLabel: 'San Francisco – Oakland Bay Bridge, United States',
//     imgUrl:
//       'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
//   {
//     imgAltLabel: '',
//     imgLabel: 'Bali, Indonesia',
//     imgUrl:
//       'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
//   {
//     imgAltLabel: '',
//     imgLabel: 'Goč, Serbia',
//     imgUrl:
//       'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
// ];

export interface ActivityProductAdditionalContentProps extends BaseComponentProps {
  activity: ActivityProduct;
}

/**
 * ActivityProductView
 * @param { ActivityProductViewProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const ActivityProductAdditionalContent = (props: ActivityProductViewProps) => {
  const { activity } = props;
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <CountryProductsContainer
          className={styles.my__4}
          // cityId={'hr-split-000'}
          // countryId={'hr'}
          description={''}
          type={'city'}
          title={`Other products in ${'hr-split-000'}`}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <ProductReviews reviewAnchorId={reviewAnchorId} />
      </Grid> */}
      <Grid item xs={12}>
        <SignupBox
          item={activity}
          // cityId={'hr-split-000'}
          // contextId={'product-view'}
          // countryId={'hr'}
          // imageUrl={'/backgroundViva.webp'}
          // foo={'bar'}
        />
      </Grid>
      {/* <Grid item xs={12}>
          <Carousell items={tutorialSteps} />
        </Grid> */}
    </Grid>
  );
};
