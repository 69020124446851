export enum DialogType {
  ADD_TO_CART = 'ADD_TO_CART',
  ACCOUNT = 'ACCOUNT',
  CONFIRMATION = 'CONFIRMATION',
  DEBUG = 'DEBUG',
  GDPR = 'GDPR',
  LOADING = 'LOADING',
  LOGIN = 'LOGIN',
  PARTICIPANT_EDIT = 'PARTICIPANT_EDIT',
  PARTICIPANT_LIST = 'PARTICIPANT_LIST',
  SIGNUP = 'SIGNUP',
  SYSTEM = 'SYSTEM',
  QUESTION_ABOUT_PRODUCT = 'QUESTION_ABOUT_PRODUCT',
  TRANSACTION_DETAIL = 'TRANSACTION_DETAIL',
}

// 1to1 mapping of MUI alert states ( lower case ) => (do abstraction + wrapper-transformer if necessary)
export enum NotificationType { // TODO: rename AppNotificationType?
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum SystemAdminClaimType {
  SUPER_ADMIN = 'sua',
}

// export type AppDialogType = DialogType | NotificationType | SystemAdminClaimType; => compose this way?
