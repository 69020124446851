import * as React from 'react';
import {
  ParticipantImportData,
  Price,
  ShoppingCartItemConfiguration,
  ShoppingCartItemConfigurationChaptersConfig,
  ProductEventParticipant,
  SourcedGQLProduct,
  AddToCartDialogInitialConfig,
} from 'src/app/types';

import {
  // calculatePriceGroupTotalPrice,
  calculatePriceGroupTotalPriceWithExtraFees,
  calculateTotalPrice,
  calculatePriceFromPriceString,
  convertPriceToFloat,
  calculateGroupQuantityDiscountPrice,
  getGroupQuantityDiscounts,
  euroPriceAsCurrency
} from 'src/lib/price';

import {
  BookingSystemType,
  ImportDataMode,
  NotificationType,
  ParticipantEditMode,
} from 'src/app/constants';
import { useAppContext, useLocalizedNavigation } from 'src/app/hooks';

import { createBasicTimestamp } from 'src/lib/date';
import {
  createInitialParticipant,
  createParticipantFromImportData,
  getCartItemsTotalParticipantCount,
} from 'src/lib/participant';
import { ProductConfigurationView } from './ProductConfigurationView';

export interface ProductConfigurationProps {
  hiddenChapters?: ShoppingCartItemConfigurationChaptersConfig;
  openChapters?: ShoppingCartItemConfigurationChaptersConfig;
  participantEditMode: ParticipantEditMode;
  product: SourcedGQLProduct;
  priceGroupTickets: ShoppingCartItemConfiguration[];
  priceSystemId: string;
  setPriceGroupTickets: (groupData: ShoppingCartItemConfiguration[]) => void;
  cartType: BookingSystemType;
  showParticipantsInfo: boolean;
  pickupOptionId?: string;
  ticketOrderLimit: number;
  optionalEventReuseParams?: AddToCartDialogInitialConfig; // NOTE: propBag for addToCart reuse state mechanism
}

// TODO: cleanup
export const ProductConfiguration = (props: ProductConfigurationProps) => {
  const {
    cartType,
    hiddenChapters,
    openChapters,
    participantEditMode,
    priceGroupTickets,
    priceSystemId,
    product,
    setPriceGroupTickets,
    showParticipantsInfo,
    pickupOptionId,
    ticketOrderLimit,
    optionalEventReuseParams,
  } = props;
  const { priceSystems } = product;
  const { createStackedNotification, firebase, closeDialog } = useAppContext();
  const { navigateLocalized } = useLocalizedNavigation();

  const productPriceSystem = priceSystems.find((ps) => ps.id === priceSystemId);

  const totalParticipants = getCartItemsTotalParticipantCount(priceGroupTickets);
  const hasMaxTicketAmountReached = !!(ticketOrderLimit - totalParticipants <= 0);

  const orderMinQuantity =
    (cartType === BookingSystemType.PUBLIC
      ? productPriceSystem?.publicBooking.orderMinQty
      : productPriceSystem?.privateBooking.orderMinQty) || 0;
  const hasMinTicketAmountReached = !!(totalParticipants - orderMinQuantity <= 0);

  const setTicketCount = (priceGroupId: string, count: number) => {
    console.log('setTicketCount priceGroupId: ', priceGroupId);
    console.log('setTicketCount count: ', count);
    console.log('setTicketCount priceGroupTickets: ', priceGroupTickets);

    /** make copy of current groupTickets */
    const priceGroupTicketsUpdate = [...priceGroupTickets];

    /** find groupTickets to modify */
    const priceGroup = priceGroupTicketsUpdate.find(
      (pgt) => pgt.priceGroupId === priceGroupId,
    );

    console.log('setTicketCount priceGroup: ', priceGroup);

    if (priceGroup) {
      const isRemoveOperation = priceGroup.count > count;
      const isAddOperation = priceGroup.count < count;

      if (isAddOperation) {
        if (hasMaxTicketAmountReached) {
          createStackedNotification(
            NotificationType.INFO,
            'Max available tickets reached',
          );
          return;
        }
        const diffCount = count - priceGroup.count;
        priceGroup.count = count; // TODO: remove this prop altogether in DB

        const newParticipants: ProductEventParticipant[] = [];
        for (let index = 0; index < diffCount; index++) {
          newParticipants[index] = {
            ...createInitialParticipant(firebase),
          };
          // if pickupOption is preselected
          if (pickupOptionId) {
            newParticipants[index].options.pickup = pickupOptionId;
          }
        }
        const currentParticipants = priceGroup.participants || [];
        priceGroup.participants = [...currentParticipants, ...newParticipants];
      }

      if (isRemoveOperation) {
        if (hasMinTicketAmountReached) {
          createStackedNotification(NotificationType.INFO, 'Minimum required ticket reached');
          return;
        }

        priceGroup.count = count; // TODO: remove this prop altogether in DB

        const newParticipants: ProductEventParticipant[] = priceGroup.participants
          ? [...priceGroup.participants?.filter((p, idx) => idx < count)]
          : [];
        priceGroup.participants = [...newParticipants];
      }

      /** make update of all ticket groups */
    } else {
      // no price group found => create one
      console.log('no price group found => create one');

      const newParticipants: ProductEventParticipant[] = [];

      for (let index = 0; index < count; index++) {
        newParticipants[index] = {
          ...createInitialParticipant(firebase),
        };
        // if pickupOption is preselected
        if (pickupOptionId) {
          newParticipants[index].options.pickup = pickupOptionId;
        }
      }

      // TODO: for private bookings + check if publicBooking is enabled
      const initialPriceGroupTickets = productPriceSystem
        ? [
            ...productPriceSystem.publicBooking.groupDiscounts
              .filter((gDiscount) => gDiscount.id !== priceGroupId)
              .map((gDiscount) => ({
                priceGroupId: gDiscount.id,
                count: 0,
                priceSystemId,
                participants: [],
              })),
          ]
        : [];

      initialPriceGroupTickets.forEach((item) => {
        priceGroupTicketsUpdate.push(item);
      });

      const newPriceGroup: ShoppingCartItemConfiguration = {
        priceGroupId,
        count,
        priceSystemId,
        participants: newParticipants,
      };
      priceGroupTicketsUpdate.push(newPriceGroup);
    }

    console.log('priceGroupTicketsUpdate : ', priceGroupTicketsUpdate);
    setPriceGroupTickets([...priceGroupTicketsUpdate]);
  };

  const onSaveParticipant = (
    priceGroupId: string,
    participantId: string,
    participantData: ProductEventParticipant,
  ) => {
    /** make copy of current groupTickets */
    const priceGroupTicketsUpdate = [...priceGroupTickets];

    /** find groupTickets to modify */
    const priceGroup = priceGroupTicketsUpdate.find(
      (pgt) => pgt.priceGroupId === priceGroupId,
    );

    if (priceGroup) {
      const index = priceGroup.participants.findIndex((p) => p.id === participantId);
      if (index >= -1) {
        priceGroup.participants[index] = participantData;
      }
    }

    setPriceGroupTickets([...priceGroupTicketsUpdate]);
  };

  // TODO: maybe show some visual feedback on trigger
  // TODO: create firestore rules to prevent invalid shopping carts (eg missing participant array etc)
  const onImportParticipantData = (
    importedParticipantsData: ParticipantImportData[],
    mode: ImportDataMode,
  ) => {
    interface ParticipantPriceGroupMapping {
      priceGroupId: string;
      participants: ProductEventParticipant[];
    }

    const participantGroupMappings: ParticipantPriceGroupMapping[] = [];

    importedParticipantsData.forEach((participant) => {
      if (participant.priceGroupId && participant.enabled) {
        const mapping = participantGroupMappings.find(
          (pgm) => pgm.priceGroupId === participant.priceGroupId,
        );
        if (mapping) {
          mapping.participants.push(createParticipantFromImportData(participant));
        } else {
          // TODO: check if priceGroup exists first
          participantGroupMappings.push({
            priceGroupId: participant.priceGroupId,
            participants: [createParticipantFromImportData(participant)],
          });
        }
      } else {
        // TODO: notify
      }
    });

    console.log('importedParticipantsData: ', importedParticipantsData);
    console.log('participantGroupMappings: ', participantGroupMappings);

    let priceGroupTicketsUpdate: ShoppingCartItemConfiguration[] = [...priceGroupTickets];
    if (mode === ImportDataMode.REPLACE) {
      priceGroupTicketsUpdate = priceGroupTicketsUpdate.map((pgt) => ({
        ...pgt,
        count: 0,
        participants: [],
      }));
    }

    participantGroupMappings.forEach(({ priceGroupId, participants }) => {
      /** find groupTickets to modify */
      const priceGroup = priceGroupTicketsUpdate.find(
        (pgt) => pgt.priceGroupId === priceGroupId,
      );

      if (priceGroup) {
        // TODO: check for collisions with existing items (id)
        if (mode === ImportDataMode.REPLACE) {
          // set participant count => imported group items count
          priceGroup.count = participants.length;
        } else if (mode === ImportDataMode.ADD) {
          // set participant count => imported group items count + current participant count
          priceGroup.count += participants.length;
        }
        participants.forEach((p) => {
          priceGroup.participants.push(p);
        });
      }
    });

    console.log('--- updating priceGroupTicketsUpdate: ', priceGroupTicketsUpdate);
    console.log('update mode: ', mode);

    // FIXME: add hard limits (50/100 participants max per priceGroup?)

    setPriceGroupTickets([...priceGroupTicketsUpdate]);
    closeDialog();
    navigateLocalized(`/shopping-cart?t=${createBasicTimestamp(3)}`);
  };

  // PUBLIC
  const publicSettings = productPriceSystem?.publicBooking?.featureEnabled
    ? productPriceSystem.publicBooking
    : null;
  const hasPublicGroupDiscount = publicSettings?.hasGroupDiscount;
  const publicGroupsDiscounts = publicSettings?.groupDiscounts || [];

  const availableGroupQuantityDiscounts =   getGroupQuantityDiscounts( priceSystems, priceSystemId);
   
  // TODO:
  // const hasPublicQuantityDiscount = publicSettings?.hasQuantityDiscount;
  // const publicQuantityDiscounts = publicSettings?.quantityDiscounts || [];

  // TODO: validation + invalidation of whole process
  // TODO: ORDER-FIX
  // FIXME: is bookingSystemId switch necessary here?
  const split =
    typeof productPriceSystem?.publicBooking?.basePrice === 'number'
      ? productPriceSystem?.publicBooking?.basePrice?.toString()?.split('.') || [] // TODO: bäh
      : [];
  const int = split?.length && split[0] ? split[0] : '0'; // TODO: invalidate
  const dec = split?.length && split[1] ? split[1] : '0'; // TODO: invalidate
  const basePrice: Price = {
    integer: parseInt(int, 10),
    decimal: parseInt(dec, 10),
  };

  // const isInCheckout = false;

  // React.useEffect(() => {
  //   if (
  //     productPriceSystem &&
  //     hasPublicGroupDiscount &&
  //     publicGroupsDiscounts?.length &&
  //     priceGroupTickets?.length !== publicGroupsDiscounts.length + 1
  //     /* && !isInCheckout */
  //   ) {
  //     let additionalPriceGroups: ShoppingCartItemConfiguration[] = [];
  //     additionalPriceGroups = [
  //       {
  //         priceGroupId: 'default', // TODO: system needed
  //         count: 1,
  //         // price: basePrice,
  //         priceSystemId: productPriceSystem.id,
  //       },
  //     ];
  //     additionalPriceGroups = [
  //       ...additionalPriceGroups,
  //       ...publicGroupsDiscounts.map((pgd) => {
  //         // const price = getGroupDiscountPrice(basePrice, pgd);
  //         console.log('pgd: ', pgd);
  //         const update = {
  //           // id: pgd.priceGroupId, // TODO: should be id for i18n
  //           count: 0,
  //           // price,
  //           priceGroupId: pgd.id,
  //           priceSystemId: productPriceSystem.id,
  //         };
  //         return update;
  //       }),
  //     ];
  //     let temp: ShoppingCartItemConfiguration[] = [];
  //     if (priceGroupTickets?.length) {
  //       temp = [...priceGroupTickets];
  //     }
  //     setPriceGroupTickets([...temp, ...additionalPriceGroups]);
  //   }
  // }, [
  //   productPriceSystem,
  //   hasPublicGroupDiscount,
  //   priceGroupTickets,
  //   publicGroupsDiscounts,
  // ]);

  // must be declared after effect definition...
  if (!productPriceSystem) {
    return null;
  }

  let totalPrice: Price = {
    integer: 0,
    decimal: 0,
  };

  // const allGroupPositionsPrices: Price[] =
  //   priceGroupTickets?.map((pgt) => {
  //     return calculatePriceGroupTotalPrice(pgt, priceSystems);
  //   }) || [];

  const allGroupPositionsPrices: Price[] =
    priceGroupTickets?.map((pgt) => {
      return calculatePriceGroupTotalPriceWithExtraFees(pgt, priceSystems, product, cartType);
    }) || [];
  if (allGroupPositionsPrices?.length) {
    totalPrice = calculateTotalPrice(allGroupPositionsPrices);
  }
  const { currencyId } = useAppContext();

  let finalPrice =euroPriceAsCurrency(totalPrice,currencyId);
  let discountAmount =0;
  const totalParticipantsCount = getCartItemsTotalParticipantCount(priceGroupTickets);
  const groupQuantityDiscount = calculateGroupQuantityDiscountPrice(availableGroupQuantityDiscounts,totalParticipantsCount ,cartType)
  
  if(groupQuantityDiscount.discountType!=null){
    let discountType = groupQuantityDiscount.discountType;
    if(discountType=="fixed"){
       discountAmount = groupQuantityDiscount.discountAmount;
      }else{
      let discountPercentage = groupQuantityDiscount.discountPercentage;
       discountAmount = convertPriceToFloat(totalPrice)*discountPercentage/100;
    }
    console.log("discountAmount Comfig",discountAmount)

   
    finalPrice = calculatePriceFromPriceString(discountAmount >0 ? convertPriceToFloat(totalPrice)-discountAmount : convertPriceToFloat(totalPrice));
   

  }
  let groupQuantityDiscountPrice =calculatePriceFromPriceString(discountAmount)
  //let finalPrice = calculatePriceFromPriceString(convertPriceToFloat(groupQuantityDiscount) >0 ? convertPriceToFloat(totalPrice)-convertPriceToFloat(groupQuantityDiscount) : convertPriceToFloat(totalPrice));
  console.log("ttttttttttttt...",discountAmount);

  const hasProductCheckoutFeatureParticipants = true; // FIXME: make configurable via sourceProduct
  // TODO: make fallback system with auto generated minimal data for a valid participant

  // TODO: reuse again
  const showParticipants =
    !!priceGroupTickets.find((t) => !!t.participants) &&
    showParticipantsInfo &&
    hasProductCheckoutFeatureParticipants;

  // TODO: save cartType saved shopping cart items (bookingSystem)

  console.log('xxx priceSystemId: ', priceSystemId);

  console.log('ProductConfiguration priceGroupTickets:', priceGroupTickets);

  // FIXME: Fallback for price system without priceGroupDiscounts

  return (
    <ProductConfigurationView
      basePrice={basePrice}
      cartType={cartType} // bookingSystem
      priceGroupTickets={priceGroupTickets}
      priceSystemId={priceSystemId}
      product={product}
      // showParticipantsInfo={showParticipants} // TODO
      showParticipantsInfo={false}
      totalPrice={finalPrice}
      onImportData={onImportParticipantData}
      onSaveParticipant={onSaveParticipant}
      setTicketCount={setTicketCount}
      ticketOrderLimit={ticketOrderLimit}
      hiddenChapters={hiddenChapters}
      openChapters={openChapters}
      participantEditMode={participantEditMode}
      optionalEventReuseParams={optionalEventReuseParams}
      groupQuantityDiscount = {groupQuantityDiscountPrice}
    />
  );
};
