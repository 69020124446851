import { navigate } from 'gatsby';
import { defaultLocale } from 'src/app/constants';
import { useLocalizationContext } from 'src/app/context';

export function useLocalizedNavigation() {
  const { language } = useLocalizationContext();
  const navigateLocalized = (path: string) => {
    const localizedPath = `/${
      language && language !== 'cimode' ? language : defaultLocale
    }${path}`;
    navigate(localizedPath);
  };
  return { navigateLocalized };
}
