import * as React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
// import HistoryIcon from '@material-ui/icons/History';


import {
  // AccountButton,
  Localized,
  DropDown,
  LoginButton,
  LogoutButton,
  // LocalizedLinkButton,
  Link,
  Toolbar,
} from 'src/app/components';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

import { useAppContext, useAppFeatures } from 'src/app/hooks';
import { AppFeature } from 'src/app/constants';

export interface LoginDropDownProps extends BaseComponentProps {
  closeMenu: any;
}

// TODO: rename to account dropdown
/**
 * LoginDropDown / WAPLoginDropDown
 * @param { LoginDropDownProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoginDropDown = (props: LoginDropDownProps) => {
  const { closeMenu } = props;
  const { isLoggedIn, user } = useAppContext();
  const { hasFeature: hasUserAccountFeature } = useAppFeatures(AppFeature.USER_ACCOUNT);

  // const AppLink = () => (
  //   <LocalizedLink color='textPrimary' href='/apps'>
  //     <Typography>
  //       <Localized dictKey={'staticLocales:pages./apps.link label'} />
  //     </Typography>
  //   </LocalizedLink>
  // );

  const options = [];
  if (isLoggedIn) {
    // options.push(
    //   <Link
    //     to='/history'
    //     // variant={'text'}
    //     startIcon={<HistoryIcon />}
    //     color={'primary'}
    //     size={'small'}
    //     className={styles.buttonBase}
    //     fullWidth
    //   >
    //     <HistoryIcon />
    //     <Localized dictKey={'staticLocales:pages./history.link label'} />
    //   </Link>,
    //   // <LocalizedLinkButton
    //   //   to='/history'
    //   //   variant={'text'}
    //   //   startIcon={<HistoryIcon />}
    //   //   color={'primary'}
    //   //   size={'small'}
    //   //   className={styles.buttonBase}
    //   //   fullWidth
    //   // >
    //   //   <Localized dictKey={'staticLocales:pages./history.link label'} />
    //   // </LocalizedLinkButton>,
    // );
    if (hasUserAccountFeature) {
      options.push(
        <Link
          href='/account'
          // variant={'text'}
          // startIcon={<AccountCircleIcon />}
          // color={'primary'}
          // size={'small'}
          className='text-neutral-900 capitalize hover:text-accent-500 dark:hover:text-accent-500 transition duration-300'
          // fullWidth
          onClick={closeMenu}
        >
          <Toolbar className='!min-h-0 py-2'>
            {/* <AccountCircleIcon fontSize={'small'} className='text-neutral-900' /> */}
            <Localized dictKey={'account:screen.screen-account-account.nav label'} className='capitalize'/>
          </Toolbar>
        </Link>,
      );
    }
    options.push(
      <Toolbar className='!min-h-0 py-2'>
        <LogoutButton closeMenu={closeMenu} />
      </Toolbar>,
    );
  } else {
    // TODO: remove due to unreachability (if not logged in there is no account button)
    options.push(
      <Toolbar className='!min-h-0 py-2'>
        <LoginButton fullWidth closeMenu={closeMenu} />
      </Toolbar>,
    );
  }

  // <Localized dictKey={'account:screen.screen-account-login.nav label'} />

  // TODO: if user displayname is longer than certain length then truncate and/or show icon instead
  // TODO: either pass object with keys or JSX to DropDowns...
  return isLoggedIn && user ? (
    <DropDown
      // fullwidth
      variant='text'
      // color={'primary'}
      className='group'
      label={
        <>
          <span className='dark:text-neutral-50 text-neutral-900 group-hover:text-accent-500 dark:group-hover:text-accent-500 transition duration-300'>
            <AccountCircleIcon />
          </span>
          {/* <span className='ml-1 capitalize dark:text-neutral-50 text-neutral-900 hidden md:inline-block'>
            <Localized
              dictKey={'account:user logged in'}
              translationOptions={{
                userName: user.displayName || user.email || 'Account',
              }}
            />
          </span> */}
        </>
      }
      options={options}
      showArrowIcon={false}
      openOnHover
    />
  ) : (
    <LoginButton fullWidth closeMenu={closeMenu} />
  );
};

const WAPLoginDropDown = (props: CombinedAppProps<LoginDropDownProps>) =>
  withoutAppProps<CombinedAppProps<LoginDropDownProps>>(LoginDropDown, props);

export { LoginDropDown, WAPLoginDropDown };
