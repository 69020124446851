export enum AppSystem {
  travlr = 'travlr',
  public = 'public',
  demo = 'demo',
}

export enum Currency {
  EUR = 'EUR',
  BTC = 'BTC',
  USD = 'USD',
}

// TODO: remove leftovers from spark env
export enum AppEnvironment {
  DEVELOPMENT = 'DEVELOPMENT',
  DEVELOPMENT_NETLIFY = 'DEVELOPMENT_NETLIFY',
  STAGE = 'STAGE',
  PRODUCTION = 'PRODUCTION',
}

export enum AppFeature {
  ORGANISATION_AREA = 'ORGANISATION_AREA',
  SYSTEM_AREA = 'SYSTEM_AREA',
  USER_ACCOUNT = 'USER_ACCOUNT',
  USER_CHECKOUT = 'USER_CHECKOUT',
  USER_WISHLIST = 'USER_WISHLIST',
  WARNING_ORGANISATION_ACCOUNT = 'WARNING_ORGANISATION_ACCOUNT',
}
