import * as React from 'react';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore';

import { NotificationType, DialogType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import { ImageLocalization } from 'src/app/types';

import { createStoredImageData, createImageFileName } from 'src/app/lib';

export interface UseStoredImage {
  isUploading: boolean;
  isDeleting: boolean;
  hasUploadError: boolean;
  hasUploadSuccess: boolean;
  deleteStoredImage: (fileName: string) => Promise<void>;
  setStoredImage: (
    file: File /* , storagePath: string, replacefileName?: string */,
    localizations: ImageLocalization[],
    mode: '__single' | '__multiple', // document format ("images" array vs none)
    fileName?: string,
  ) => Promise<void>;
  // TODO: ?
  // setStoredImages: (files: FileSystem, storagePath: string, replacefileNames?: string[]) => void;
  // uploadProgress: number;
  uploadFileUrl: string | null;
}

// TODO: multiImage upload needed?

export const useStoredImage = (
  storedImageDocumentPath: string,
  storedImageStoragePath: string,
  imageId: string,
): UseStoredImage => {
  const { createStackedNotification, openDialog, closeDialog, firebase, isLoggedIn } =
    useAppContext();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [hasUploadError, setHasUploadError] = React.useState<boolean>(false);
  const [hasUploadSuccess, setHasUploadSuccess] = React.useState<boolean>(false);
  const [uploadFileUrl, setUploadFileUrl] = React.useState<string | null>(null);

  const setStoredImageDocument = async (docData: any) => {
    if (!firebase) {
      throw Error('No firebase instance');
    }
    const docRef = doc(getFirestore(firebase), storedImageDocumentPath);
    return setDoc(docRef, docData);
  };

  const deleteStoredImage = async (fileName: string) => {
    setIsDeleting(true);
    if (typeof window === 'undefined' || !firebase || !isLoggedIn) {
      return;
    }

    try {
      const fileRef = ref(getStorage(firebase), `${storedImageStoragePath}/${fileName}`);
      const docRef = doc(getFirestore(firebase), storedImageDocumentPath);
      await deleteObject(fileRef);
      await deleteDoc(docRef);
      createStackedNotification(NotificationType.SUCCESS, 'Delete successful');
    } catch (err) {
      createStackedNotification(
        NotificationType.ERROR,
        `Delete error: ${err.message || ''}`,
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const uploadFile = async (
    file: File,
    storagePath: string,
    fileName?: string,
  ): Promise<string | undefined> => {
    // console.log('start uploading - selectedFile: ', selectedFile);
    setIsUploading(true);
    if (typeof window === 'undefined' || !firebase || !isLoggedIn || !file) {
      return undefined;
    }

    // console.log('uploadFile file: ', file);

    const fileNameWithFileExtension = createImageFileName(file, false, fileName);
    const selectedfileData = await file;

    const storageFilePath = `${storagePath}/${fileNameWithFileExtension}`;

    // console.log('start upload, file: ', file);
    // console.log('start upload, fileName: ', fileName);
    // console.log('start upload, fileNameWithFileExtension: ', fileNameWithFileExtension);
    // console.log('start upload, storageFilePath: ', storageFilePath);
    // console.log('start upload, storedImageDocumentPath: ', storedImageDocumentPath);
    // console.log('start upload, storedImageStoragePath: ', storedImageStoragePath);

    try {
      const fileRef = ref(getStorage(firebase), storageFilePath);
      createStackedNotification(NotificationType.INFO, 'File upload started');
      await uploadBytes(fileRef, selectedfileData);
      const downLoadUrl = await getDownloadURL(fileRef);

      setUploadFileUrl(downLoadUrl);
      setIsUploading(false);
      setHasUploadSuccess(true);
      createStackedNotification(NotificationType.INFO, 'File uploaded');
      return Promise.resolve(downLoadUrl);
    } catch (err) {
      createStackedNotification(NotificationType.ERROR, `File upload error ${err}`);
      setIsUploading(false);
      setHasUploadError(true);
    }
    return undefined;
  };

  const setStoredImage = async (
    file: File,
    localizations: ImageLocalization[],
    mode: '__single' | '__multiple', // document format ("images" array vs none)
    fileName?: string,
  ) => {
    // console.log('setStoredImage file: ', file);
    // console.log('setStoredImage fileName: ', fileName);
    // console.log('setStoredImage storedImageStoragePath: ', storedImageStoragePath);
    // console.log('setStoredImage mode: ', mode);
    openDialog(DialogType.LOADING);
    const downloadUrl = await uploadFile(file, storedImageStoragePath, fileName);
    // FIXME: was before => const downloadUrl = await uploadFile(file, storedImageDocumentPath, fileName);

    if (!downloadUrl) {
      // TODO: could cleanup created file here, if one was created
      closeDialog();
      return;
    }

    let storedImageDocData = null;

    if (mode === '__single') {
      storedImageDocData = {
        id: imageId, // FIXME: constant
        ...createStoredImageData(
          file,
          downloadUrl,
          localizations,
          imageId,
          storedImageStoragePath,
          true,
          fileName,
        ),
      };
    }

    if (mode === '__multiple') {
      storedImageDocData = {
        id: imageId, // FIXME: constant
        images: [
          createStoredImageData(
            file,
            downloadUrl,
            localizations,
            imageId,
            storedImageStoragePath,
            true,
            fileName,
          ),
        ], // TODO: handle insertion/replacement with multiple images
      };
    }

    if (!storedImageDocData) {
      return;
    }

    // console.log('storedImageDocData: ', storedImageDocData);
    await setStoredImageDocument(storedImageDocData); // when to create when to update??
    closeDialog();
    createStackedNotification(NotificationType.SUCCESS, 'File successful updated');
  };

  return {
    // TODO: do we need this ?=> check + eval if same is needed for deletion process
    hasUploadError,
    hasUploadSuccess,
    isUploading,
    isDeleting,
    setStoredImage,
    deleteStoredImage,
    uploadFileUrl,
  };
};
