import * as React from 'react';
import { Localized, LocalizedLink } from 'src/app/components';

const linkClasses = '!text-p2 flex gap-1 items-center relative after:w-0 after:h-[2px] after:absolute after:bg-accent-500 after:-bottom-0 after:right-0  hover:after:w-full transition-all duration-300 after:transition-all after:duration-300 hover:after:right-auto hover:after:left-0';

export const AppPageLinks = () => {
  return (
    <div className='flex my-4 gap-x-4 gap-y-2 flex-wrap md:my-0 md:flex-col md:gap-y-4'>
      {/* <div>
        <LocalizedLink href={'/help'}>
          <Localized dictKey={'staticLocales:pages./help.link label'} />
        </LocalizedLink>
      </div> */}
      <div>
        <LocalizedLink href={'/help/customer'}>
          <Localized dictKey={'staticLocales:pages./help.pages./customer.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/help/supplier'}>
          <Localized dictKey={'staticLocales:pages./help.pages./supplier.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/contact'}>
          <Localized dictKey={'staticLocales:pages./contact.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/about'}>
          <Localized dictKey={'staticLocales:pages./about.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/imprint'}>
          <Localized dictKey={'staticLocales:pages./imprint.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/privacy-policy'}>
          <Localized dictKey={'staticLocales:pages./privacy-policy.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/terms-of-business'}>
          <Localized dictKey={'staticLocales:pages./terms-of-business.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
      <div>
        <LocalizedLink href={'/lost-password'}>
          <Localized dictKey={'staticLocales:pages./lost-password.link label'} className={linkClasses} />
        </LocalizedLink>
      </div>
    </div>
  );
};
