// export enum SourceCollection {
//   COUNTRY = 'country',
//   CURRENCY = 'currency',
//   LOCALE = 'locale',
//   PRODUCT_TYPE = 'category', // TODO RENAME
//   IMAGE = 'image',
//   TAG = 'tag',
// }

// TODO: consider merging with GatsbyNodeType

export enum SourceCollectionName {
  CATEGORY = 'category',
  CATEGORY_TYPE = 'category-type',
  CITY = 'city',
  CITY_AREA = 'city-area',
  COUNTRY = 'country',
  COUNTRY_AREA = 'country-area',
  CONTINENT = 'continent',
  CURRENCY = 'currency',
  // SHOP_IMAGE = 'shop_image', // remove?
  // ORGANISATION_IMAGE = 'organisation_image', // remove?
  // SYSTEM_IMAGE = 'system_image', // remove?
  LOCALE = 'locale',
  ORDER='order',
  ORDERUSER='order_user',
  LOCATION = 'location',
  PRODUCT = 'product',
  TAG = 'tag',
  //   // PRODUCT_PAGE
  //   // ORGANISATION_PAGE
  //   // META/DAILY SHIT?
}

export enum GatsbyNodeType {
  CATEGORY = 'Category',
  CATEGORY_TYPE = 'CategoryType',
  CITY = 'City',
  CITY_AREA = 'CityArea',
  COUNTRY = 'Country',
  COUNTRY_AREA = 'CountryArea',
  CONTINENT = 'Continent',
  CURRENCY = 'Currency',
  LOCALE = 'Locale',
  LOCATION = 'Location',
  TAG = 'Tag',
  ORDER='Order',
  // new
  PRODUCT = 'Product',
}

// export enum SourcedCollectionName {
//   CATEGORY = 'category',
//   CITY = 'city',
//   CITY_AREA = 'city-area',
//   COUNTRY = 'country',
//   COUNTRY_AREA = 'country-area',
//   CONTINENT = 'continent',
//   CURRENCY = 'currency',
//   LOCALE = 'locale',
//   TAG = 'tag',
// }
