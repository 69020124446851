import * as React from 'react';
import clsx from 'clsx';

import { DropDown, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useSourcedCountryAreas, useLocalizationContext } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';

export interface CountryAreaFilterProps {
  availableCountryAreaIds: string[];
  currentCountryAreaFilterIds: string[];
  setCountryAreaFilter: (id: string, isOn: boolean) => void;
}

export const CountryAreaFilter = ({
  availableCountryAreaIds,
  currentCountryAreaFilterIds,
  setCountryAreaFilter,
}: CountryAreaFilterProps) => {
  const styles = useStyles();
  const countryAreas = useSourcedCountryAreas();
  const { language } = useLocalizationContext();

  const isFilterOn = () => !!currentCountryAreaFilterIds.length;

  const isFilterOnId = (id: string) =>
    !!currentCountryAreaFilterIds.find((filterId) => filterId === id);

  const onChangeFilter = (id: string) => {
    setCountryAreaFilter(id, !isFilterOnId(id));
  };

  const selectedCountryArea = currentCountryAreaFilterIds?.length
    ? countryAreas.find((ca) => ca.id === currentCountryAreaFilterIds[0])
    : null;

  const selectedIndex = selectedCountryArea
    ? countryAreas.findIndex((ca) => ca.id === currentCountryAreaFilterIds[0])
    : null;
  return (
    <Grid container spacing={2} className={clsx(styles.py__1)}>
      <Grid item xs={12}>
        <DropDown
          disabled={!availableCountryAreaIds?.length}
          // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
          className={styles.dropDown__Button}
          variant={'contained'}
          // color={'primary'}
          color={isFilterOn() ? 'secondary' : 'primary'}
          label={
            <Typography align={'center'}>
              {currentCountryAreaFilterIds?.length
                ? `${
                    getBaseDocumentLocales(selectedCountryArea, language).name ||
                    currentCountryAreaFilterIds[0]
                  }`
                : 'Select a country area'}
            </Typography>
          }
          placement={'top'}
          options={[
            ...availableCountryAreaIds
              .filter((countryAreaId) =>
                countryAreas.find((area) => area.id === countryAreaId),
              )
              .map((countryAreaId) => {
                const countryArea = countryAreas.find(
                  (area) => area.id === countryAreaId,
                );
                return !countryArea ? null : (
                  <Grid container key={countryAreaId}>
                    <Grid item xs={12}>
                      <Typography
                        color={!isFilterOnId(countryAreaId) ? 'primary' : 'secondary'}
                        align={'center'}
                        renderAs={'span'}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {getBaseDocumentLocales(countryArea, language).name ||
                          countryAreaId}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }),
          ]}
          selectedIndex={selectedIndex !== null ? selectedIndex : -1}
          onChange={(id: string) => onChangeFilter(availableCountryAreaIds[id])}
          showArrowIcon={false}
          openOnHover
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
