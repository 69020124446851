import * as React from 'react';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { NotificationType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';

export interface UseDeleteFile {
  isDeleting: boolean;
  hasDeleteError: boolean;
  hasDeleteSuccess: boolean;
  deleteFile: (fileName: string, storagePath?: string) => void;
}

export const useDeleteFile = (
  successNotification: string | React.ReactNode,
  errorNotification: string | React.ReactNode,
): UseDeleteFile => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [hasDeleteError, setHasDeleteError] = React.useState<boolean>(false);
  const [hasDeleteSuccess, setHasDeleteSuccess] = React.useState<boolean>(false);

  const deleteFile = async (fileName: string, storagePath?: string) => {
    setIsDeleting(true);
    if (!isLoggedIn || !fileName || !firebase) {
      return;
    }

    const filePath = storagePath ? `${storagePath}/${fileName}` : fileName;

    console.log('deleting file, filepath: ', filePath);
    const fileRef = ref(getStorage(firebase), filePath);
    try {
      // await storageFileRef.delete();
      await deleteObject(fileRef);
      createNotification(NotificationType.SUCCESS, successNotification, 4000);
      setIsDeleting(false);
      setHasDeleteSuccess(true);
    } catch (err) {
      createNotification(NotificationType.ERROR, errorNotification, 4000);
      setIsDeleting(false);
      setHasDeleteError(true);
    }
  };
  return {
    hasDeleteError,
    hasDeleteSuccess,
    isDeleting,
    deleteFile,
  };
};
