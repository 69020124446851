import * as React from 'react';
import MPagination from '@material-ui/lab/Pagination';

import { Grid, Typography } from 'src/app/components';

interface PaginationControlsProps {
  countText: string | React.ReactNode;
  currentPage: number;
  handleChange: (e: React.ChangeEvent<unknown>, pageNumber: number) => void;
  pageCount: number;
}

export const PaginationControls = ({
  currentPage,
  countText,
  handleChange,
  pageCount,
}: PaginationControlsProps) => (
  <div className='flex flex-col gap-y-3 dark:text-neutral-50 items-center'>
    <p className='text-p2 text-neutral-500'>
      {countText}
    </p>
    <div className='flex items-center justify-center'>
      <MPagination
        count={pageCount}
        shape='rounded'
        size='small'
        //showFirstButton
        //showLastButton
        page={currentPage}
        onChange={handleChange}
      />
    </div>
  </div>
);
