import * as React from 'react';

import { Grid, Typography, Button, Toolbar } from 'src/app/components';

interface DocumentDownloadProps {
  id: string;
  onClick: () => void;
}

export const DocumentDownload = ({ id, onClick }: DocumentDownloadProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Toolbar>
          <Typography>{id}</Typography>
        </Toolbar>
      </Grid>
      <Grid item xs={6}>
        <Toolbar>
          <Button fullWidth color={'primary'} onClick={onClick}>
            <Typography>Download document</Typography>
          </Button>
        </Toolbar>
      </Grid>
    </Grid>
  );
};
