import * as React from 'react';
import { Grid, Localized, Typography } from 'src/app/components';
import { Currency } from 'src/app/constants';
import { Price } from 'src/app/types';
import { euroPriceAsCurrency, padPrice } from 'src/lib/price';

export interface PriceTotalSummaryProps {
  currencyId: Currency;
  i18nPath: string;
  itemCount: number;
  totalPrice: Price;
}

export const PriceTotalSummary = (props: PriceTotalSummaryProps) => {
  const { currencyId, i18nPath, itemCount, totalPrice } = props;
  console.log('dcsdsasd',totalPrice);

  return (
    <div className='flex justify-between gap-4'>
      <div>
        <h4 className='text-p1 font-semibold'>
          <Localized
            dictKey={`${i18nPath}.cart.item-count`}
            translationOptions={{ count: itemCount }}
          />
        </h4>
      </div>
      <div className='text-right'>
        <h3 className='text-h3 font-bold'>
          <Localized
            dictKey={totalPrice.decimal==0?`${i18nPath}.cart.total-price-integer`:`${i18nPath}.cart.total-price`}
            translationOptions={{
              currencyId,
              price: totalPrice,
            }}
          />
        </h3>
        <p className='text-p4'>
          <Localized dictKey={`${i18nPath}.hint.no-fees`} />
        </p>
      </div>
    </div>
  );
};
