import * as React from 'react';

export interface FeatureItemProps {
  description: string | React.ReactNode;
  icon: React.ReactNode;
  title: string | React.ReactNode;
}

export const FeatureItem = ({ description, icon, title }: FeatureItemProps) => {
  return (
    <div className='
      bg-accent-400 bg-opacity-[0.08] text-neutral-900 flex flex-col gap-4 py-8 px-6 md:py-14 md:px-8 items-center text-center font-body rounded-2xl shadow-box-light dark:shadow-box-dark dark:bg-neutral-800 dark:bg-opacity-100 dark:text-neutral-50 h-full'>
      <span className='text-neutral-900 dark:text-neutral-50 bg-transparent scale-125 md:scale-[2.25] md:my-5'>{icon}</span>
      <h3 className='text-h4 font-semibold'>
        {title}
      </h3>
      <p className='text-p3 sm:text-p2'>
        {description}
      </p>
    </div>
  );
};
