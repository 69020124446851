import * as React from 'react';
import MTabs, { TabsProps as MTabsProps } from '@material-ui/core/Tabs';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';
import { WAPTab, Tab } from '../tab';

export interface TabsProps extends CombinedBaseComponentProps<MTabsProps> {
  ariaLabel: string;
  children?:
    | React.ReactElement<ReturnType<typeof WAPTab | typeof Tab>>
    | React.ReactElement<ReturnType<typeof WAPTab | typeof Tab>>[]
    | null;
}

/**
 * Tabs / WAPTabs - MUI
 * @param { TabsProps } props
 * @version 0.0.0
 * @description
 * @todo aria-conformity
 * @example
 */
const Tabs = (props: TabsProps) => {
  const { ariaLabel, children, className, ...passProps } = props;
  const styles = useStyles();
  return (
    <MTabs
      className={`${styles.tabs} ${className || ''}`}
      aria-label={ariaLabel || ''}
      {...passProps}
    >
      {children}
    </MTabs>
  );
};

const WAPTabs = (props: CombinedAppProps<TabsProps>) =>
  withoutAppProps<CombinedAppProps<TabsProps>, TabsProps>(Tabs, props);

export { Tabs, WAPTabs };
