import * as React from 'react';
import clsx from 'clsx';

import { DropDown, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useSourcedCityAreas, useLocalizationContext } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';

export interface CityAreaFilterProps {
  availableCityAreaIds: string[];
  currentCityAreaFilterIds: string[];
  setCityAreaFilter: (id: string, isOn: boolean) => void;
}

export const CityAreaFilter = ({
  availableCityAreaIds,
  currentCityAreaFilterIds,
  setCityAreaFilter,
}: CityAreaFilterProps) => {
  const styles = useStyles();
  const cityAreas = useSourcedCityAreas();
  const { language } = useLocalizationContext();

  const isFilterOn = () => !!currentCityAreaFilterIds.length;

  const isFilterOnId = (id: string) =>
    !!currentCityAreaFilterIds.find((filterId) => filterId === id);

  const onChangeFilter = (id: string) => {
    setCityAreaFilter(id, !isFilterOnId(id));
  };

  const selectedCityArea = currentCityAreaFilterIds?.length
    ? cityAreas.find((ca) => ca.id === currentCityAreaFilterIds[0])
    : null;

  const selectedIndex = selectedCityArea
    ? cityAreas.findIndex((ca) => ca.id === currentCityAreaFilterIds[0])
    : null;
  return (
    <Grid container spacing={2} className={clsx(styles.py__1)}>
      <Grid item xs={12}>
        <DropDown
          disabled={!availableCityAreaIds?.length}
          // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
          className={styles.dropDown__Button}
          variant={'contained'}
          // color={'primary'}
          color={isFilterOn() ? 'secondary' : 'primary'}
          label={
            <Typography align={'center'}>
              {currentCityAreaFilterIds?.length
                ? `${
                    getBaseDocumentLocales(selectedCityArea, language).name ||
                    currentCityAreaFilterIds[0]
                  }`
                : 'Select a city area'}
            </Typography>
          }
          placement={'top'}
          options={[
            ...availableCityAreaIds
              .filter((cityAreaId) => cityAreas.find((area) => area.id === cityAreaId))
              .map((cityAreaId) => {
                const cityArea = cityAreas.find((area) => area.id === cityAreaId);
                return !cityArea ? null : (
                  <Grid container key={cityAreaId}>
                    <Grid item xs={12}>
                      <Typography
                        color={!isFilterOnId(cityAreaId) ? 'primary' : 'secondary'}
                        align={'center'}
                        renderAs={'span'}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {getBaseDocumentLocales(cityArea, language).name || cityAreaId}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }),
          ]}
          selectedIndex={selectedIndex !== null ? selectedIndex : -1}
          onChange={(id: string) => onChangeFilter(availableCityAreaIds[id])}
          showArrowIcon={false}
          openOnHover
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
