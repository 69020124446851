import * as React from 'react';
import { AdminContext, AdminDocument } from 'src/admin/types';

const Context = React.createContext<AdminContext<AdminDocument> | null>(null);

export const useAdminContext: () => AdminContext<AdminDocument> =
  (): AdminContext<AdminDocument> =>
    React.useContext<AdminContext<AdminDocument> | null>(
      Context,
    ) as AdminContext<AdminDocument>;

export function AdminContextProvider<AdminDocument>({
  children,
  document: propDocument,
  role
}: {
  children: React.ReactNode;
  document: AdminDocument;
  role: string;
}): React.ReactElement | null {
  const [document, setDocument] = React.useState<AdminDocument | null>(null);
  const [userRole, setUserRole] = React.useState<String | null>(null);

  React.useEffect(() => {
    if (propDocument) {
      setDocument(propDocument);
    }
  }, [propDocument]);

  React.useEffect(() => {
    if (role) {
      setUserRole(role);
    }
  }, [role]);

  return (
    <Context.Provider
      value={{
        document,
        userRole
      }}
    >
      {children}
    </Context.Provider>
  );
}
