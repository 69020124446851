import * as React from 'react';
import MPaper, { PaperProps as MPaperProps } from '@material-ui/core/Paper';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import clsx from 'clsx';
import { useStyles } from 'src/app/context';
import { ThemeMode } from 'src/app.consumer/constants';

export interface PaperProps extends CombinedBaseComponentProps<MPaperProps> {
  isTransparent?: boolean;
  themeMode?: ThemeMode;
}

// & { ref: React.ForwardRefRenderFunction<{}, PaperProps> }

/**
 * Paper
 * @param { ButtonProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/paper/ - In the file is an attempt to debug mdx components attached
 * @todo
 * @example
 */
const Paper = (props: PaperProps) => {
  const styles = useStyles();
  const { children, className, isTransparent, themeMode, ...restProps } = props;
  // TODO: use theme mode to prevent bug or remove altogether + check where it is used => no prop drilling desired
  return (
    <MPaper
      className={clsx({ [styles.glassPaper]: !!isTransparent, [className || '']: true })}
      {...restProps}
    >
      {children}
    </MPaper>
  );
};

// const PaperDebugged: React.FC<PaperDProps> = (props: PaperDProps) => {
//   const { debug, children, elevation } = props;
//   // const debuggedElem = React.useRef<HTMLDivElement>(null); // TODO: infer this from MUI "as" property / pass that property?

//   if (!debug) {
//     return <>{children}</>;
//   }

//   const paperFR = React.forwardRef((p, ref: any) => (
//     <Paper ref={ref} {...(props as any)} elevation={elevation || 0}>
//       {p.children}
//     </Paper>
//   ));

//   return (
//     <DebugContainer<PaperProps> elem={paperFR || null} {...debug}>
//       {children}
//     </DebugContainer>
//   );
// };

const WAPPaper = (props: CombinedAppProps<PaperProps>) =>
  withoutAppProps<CombinedAppProps<PaperProps>, PaperProps>(Paper, props);

export { Paper, WAPPaper };
