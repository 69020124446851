import * as React from 'react';

import { DebugObject } from 'src/app/components';

import {
  AddToCartDialogInitialConfig,
  AddToCartDialogParams,
  ParticipantDialogParams,
  ParticipantPickupAddressInformation,
  ProductEventParticipant,
  SourcedGQLProduct,
} from 'src/app/types';
import { useAppContext } from 'src/app/context';
import {
  createProductLocationOptions,
  createProductFoodOptionsFromPriceSystem,
  createProductLanguageOptions,
} from 'src/lib/participant';
import { BookingSystemType, DialogType, ParticipantEditMode } from 'src/app/constants';
import { ParticipantListItemView } from './ParticipantListItemView';

interface ProductTicketGroupParticipantsProps {
  bookingSystemType: BookingSystemType;
  participants: ProductEventParticipant[];
  participantEditMode: ParticipantEditMode; // FIXME: remove again?
  product: SourcedGQLProduct;
  priceGroupId: string;
  priceSystemId: string;
  saveParticipant: (id: string, data: ProductEventParticipant) => void;
  fieldOptions: { fieldPropertyId: string }[];
  optionalEventReuseParams?: AddToCartDialogInitialConfig; // NOTE: propBag for addToCart reuse state mechanism
}

// TODO: get gender information?
export const ProductTicketGroupParticipants = ({
  bookingSystemType,
  participants: passedParticipants,
  participantEditMode, // FIXME: remove again?
  priceGroupId,
  priceSystemId,
  product,
  saveParticipant,
  fieldOptions,
  optionalEventReuseParams,
}: ProductTicketGroupParticipantsProps) => {
  // const styles = useStyles();
  const { isAppDebug, closeDialog, openDialog } = useAppContext();
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);
  const [participants, setParticipants] = React.useState<ProductEventParticipant[]>([
    ...passedParticipants,
  ]);

  /** update on participant updates */
  React.useEffect(() => {
    setParticipants(passedParticipants);
  }, [passedParticipants]);

  const productPickup = product.pickup;
  // const showPickup = !!productPickup?.hasPickupService;
  // const showFood = true; // TODO: implement
  // const showLanguage = true; // TODO: implement

  const locationOptions = createProductLocationOptions(product);

  const foodOptions = createProductFoodOptionsFromPriceSystem(product, priceSystemId);

  // { id: string }[]
  const languageOptions = createProductLanguageOptions(product, priceSystemId);

  const onSaveParticipant = async (
    participantId: string,
    participantData: ProductEventParticipant,
  ) => {
    console.log('onSaveParticipant participantId: ', participantId);
    console.log('onSaveParticipant participantData: ', participantData);
    if (!participantId || !saveParticipant) {
      return;
    }
    try {
      console.log('saving participant participantData: ', participantData);
      setIsRequestingUpdate(true);
      saveParticipant(participantId, participantData); // TODO: is not async
    } catch (e) {
      console.warn('Error: ', e);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  const onSetParticipantPickupAddress = (
    participantId: string,
    addressData: ParticipantPickupAddressInformation,
  ) => {
    console.log('####### onSetParticipantPickupAddress - addressData :', addressData);
    if (!participantId || !addressData) {
      return;
    }
    const updateParticipants = [];
    for (let index = 0; index < participants.length; index++) {
      if (participants[index].id === participantId) {
        // const languageOptionId = languageOptions[languageOptionIdx].id;
        updateParticipants[index] = {
          ...participants[index],
          // options: {
          //   ...participants[index].options,
          //   // language: languageOptionId,
          // },
          pickupAddress: addressData, // TODO: validate that all required fields are present
        };
      } else {
        updateParticipants[index] = participants[index];
      }
    }
    console.log(
      '####### onSetParticipantPickupAddress - updateParticipants: ',
      updateParticipants,
    );
    setParticipants(updateParticipants);
  };

  const onEditParticipant = (participantId: string, isInAddToCartMode: boolean) => {
    const participant = participants.find((p) => p.id === participantId);
    if (!participant) {
      return;
    }

    const addToCartDialogParams: AddToCartDialogParams = {
      product,
      bookingSystemType,
      // pickupOptionId:
      //   pickupLocations.find((l) => l.type.startsWith(PickupOptionType.DEFAULT))?.id ||
      //   undefined,
    };

    if (optionalEventReuseParams) {
      addToCartDialogParams.bookingSystemType =
        optionalEventReuseParams.bookingSystemType;
      addToCartDialogParams.initialParams = {
        ...optionalEventReuseParams,
        // bookingSystemType: AddToCartType.DEFAULT_BOOKING,
        // priceGroupTickets: ShoppingCartItemConfiguration[];
        // date: AppDate;
        // dayTime: DayTime;
        // fetchedEventDocument: DaytimeEventDocument;
      };
    }

    const params: ParticipantDialogParams = {
      fieldOptions: [
        ...fieldOptions,
        ...[
          { fieldPropertyId: 'firstName' },
          { fieldPropertyId: 'lastName' },
          { fieldPropertyId: 'dob' },
        ], // FIXME: testing
      ],
      participant,
      onSaveParticipant,
      onSetParticipantPickupAddress,
      //
      locationOptions,
      foodOptions,
      languageOptions,
      //
      showPickup: !!productPickup?.hasPickupService,
      showFood: true, // TODO: implement
      showLanguage: true, // TODO: implement
      //
      product,
      onCloseDialog: isInAddToCartMode
        ? () => openDialog(DialogType.ADD_TO_CART, addToCartDialogParams)
        : () => closeDialog(),
      // onCloseDialog: () => console.log('closing dialog'),
    };
    openDialog(DialogType.PARTICIPANT_EDIT, params);
  };
  // TODO use participantEditMode => either wrap inside accordion or provide a new dialog callback to get back to the addToCart dialog (will be messy with eventState retrival?)

  return (
    <>
      {isAppDebug ? (
        <div className='py-2'>
          <DebugObject isDebug={isAppDebug} object={participants} collapsed={0} />
        </div>
      ) : null}
      {participants?.length
        ? participants.map((participant, idx) => {
            return (
              <div key={participant.id} className='my-2 md:my-3'>
                <ParticipantListItemView
                  index={idx}
                  isRequestingUpdate={isRequestingUpdate}
                  participant={participant}
                  priceGroupId={priceGroupId}
                  onEditParticipantClick={() =>
                    onEditParticipant(participant.id, !!optionalEventReuseParams)
                  }
                />
              </div>
            );
          })
        : null}
    </>
  );
};
