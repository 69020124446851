import * as React from 'react';

import { Divider, Grid, Typography } from 'src/app/components';
import { Resolution } from 'src/app/types';
import { bytesToSize } from 'src/app/lib';

export interface ImageFileInformationProps {
  file: File; // image file with mime 'image/*'
  resolution: Resolution | null;
}

const ImageFileInformation = (props: ImageFileInformationProps) => {
  const { file, resolution } = props;
  const isFileSelected = !!file;
  const isImageFile = file?.type?.startsWith('image/');
  return (
    <Grid container spacing={2}>
      {isFileSelected ? (
        <>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography>Image file information:</Typography>
              </Grid>
              {!isImageFile ? (
                <Grid item xs={6}>
                  <Typography color={'error'}>
                    The selected file is not an image
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography>Filename:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{file?.name || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {resolution ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>Resolution:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{`Width: ${resolution.width}px - Height: ${resolution.height}px`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography>Size:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{file?.size ? bytesToSize(file.size) : ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography>Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{file?.type || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      ) : (
        <>
          {' '}
          <Grid item xs={12}>
            <Typography>Select an image file:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export { ImageFileInformation };
