import { AppEnvironment } from 'src/app/constants';

export const isLocalhost = () =>
  typeof window !== 'undefined' && window.location.hostname === 'localhost';

export const isSSR = typeof window === 'undefined';

export const isEnvironment = (pathname: string, env: AppEnvironment) => {
  // console.log('isEnvironment pathname: ', pathname);
  // console.log('isEnvironment env: ', env);
  if (env === AppEnvironment.DEVELOPMENT) {
    return !!(
      pathname.includes('localhost:8000') ||
      pathname.includes('https://travlr-dev.netlify.app')
    );
  }
  if (env === AppEnvironment.DEVELOPMENT_NETLIFY) {
    return !!pathname.includes('https://travlr-dev.netlify.app');
  }
  if (env === AppEnvironment.PRODUCTION) {
    return !!pathname.includes('https://hey.holiday');
  }
  if (env === AppEnvironment.STAGE) {
    return !!pathname.includes('https://travlr-test.netlify.app');
  }
  return false;
};
