import * as React from 'react';
import { Grid } from 'src/app/components';
import { isSSR } from 'src/app/lib';

const ReactJSON = React.lazy(() => import('react-json-view'));

interface DebugObjectProps {
  collapsed?: number;
  isDebug?: boolean;
  object: Record<string, any>;
}

export const DebugObject = ({ collapsed, isDebug, object }: DebugObjectProps) => {
  if (isSSR || !isDebug) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactJSON
          collapsed={!Number.isNaN(collapsed) ? collapsed : 2}
          theme={'codeschool'}
          src={object || {}}
          name={false}
          // onEdit={() => {
          //   return false;
          // }}
        />
      </Grid>
    </Grid>
  );
};
