import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { BookingCancellationFormSubmitData } from 'src/app/types';
import { FormError } from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  bookingReferenceId: yup
    .string()
    .required(FormError.REQUIRED)
    .max(64, FormError.NUMBER_MAX_VALUE), // TODO: min length + format check
  pin: yup.string().required(FormError.REQUIRED).max(16, FormError.NUMBER_MAX_VALUE), // TODO: min length  + format check
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: BookingCancellationFormSubmitData = {
  bookingReferenceId: '',
  pin: '',
};
