import * as React from 'react';
import { RouterPageProps } from 'src/app/types';

export interface PrivateRouteProps<T extends Record<string, any>>
  extends RouterPageProps {
  component: React.FC<T> | JSX.Element;
  passProps?: T;
}
// FIXME:
export const PrivateRoute = <T extends Record<string, any>>({
  component,
  passProps, // additional passprops for the rendered component
  ...rest // props from reach router inside
}: PrivateRouteProps<T>) => {
  // const { isLoggedIn, systemAdminRoles } = useAppContext();

  // system only (or create separate wrapper for sys & orgs)
  // const hasSystemAdminPermission = !!(isLoggedIn && systemAdminRoles?.length); // TODO: create role to available screens mapping cfg + role to avail. actions (or use user permission collection?)

  if (typeof window === 'undefined') {
    return null;
  }

  const Component = component as React.FC<T>;
  // if (!isLoggedIn && window.location.pathname !== '/admin/system/login') {
  //   navigate('/admin/system/login');
  //   return null;
  // }
  return <Component {...{ ...((rest as any) as T), ...passProps }} />;
};
