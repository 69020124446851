import * as React from 'react';
import { navigate } from 'gatsby';

import {
  Button,
  Grid,
  FirestoreCollectionFetch,
  Localized,
  ProductListItem,
  Typography,
  LoadingIndicator,
} from 'src/app/components';

import { formatFirebaseTimestamp } from 'src/lib/date';
import { duplicateProduct } from 'src/api/callables';
import { useStyles } from 'src/app/context';
import { onOpenInNewWindow } from 'src/app/lib';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';
import { OrganisationAdminDialogType } from 'src/app.organisation/constants';
import { NotificationType } from 'src/app/constants';

import { ProductDocumentState } from 'src/app/constants';
import { useAdminContext } from 'src/admin/context';
import clsx from 'clsx';
import { limit } from 'firebase/firestore';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';


interface OrganisationProductListItemProps {
  product: any;
}

// TODO:
type T = any;

const getButtonColorByProductState = (
  status: ProductDocumentState,
  styles: ReturnType<typeof useStyles>,
) => {
  switch (status) {
    case ProductDocumentState.DRAFT:
      return { root: styles.btn__info };

    case ProductDocumentState.PUBLISHED:
    case ProductDocumentState.PUBLISH__REQUEST:
    case ProductDocumentState.PUBLISH__APPROVED:
    case ProductDocumentState.CHANGE_APPROVED:
      return { root: styles.btn__success };

    case ProductDocumentState.CHANGE_REQUEST:
    case ProductDocumentState.CHANGE_APPROVAL_PENDING:
      return { root: styles.btn__action };

    case ProductDocumentState.PUBLISH__REJECTED:
    case ProductDocumentState.CHANGE_REJECTED:
    case ProductDocumentState.REVOKE__REQUEST:
    case ProductDocumentState.DELETE__REQUEST:
    case ProductDocumentState.DELETED:
      return { root: styles.btn__error };
    default:
      return {};
  }
};

export const OrganisationProductListItem = ({
  product,
}: OrganisationProductListItemProps) => {
  const styles = useStyles();
  const { closeDialog, currentOrganisation, isAppDebug, openDialog } = useAppContext();
  const { document: organisationDocument } = useAdminContext();
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string | null>(null);

  const productCategoryType = 'activity'; // FIXME: get plus apply path mapping
  const defaultPreviewLocaleId = 'en';

  const isProductPublished = product?.meta?.status === '__published';
  const [isDuplicatingDocs, setIsDuplicatingDocs] = React.useState<boolean>(
    false,
  );

  const documentPath = `organisation/${currentOrganisation?.id || ''}/product_source`;
  const productImageCollectionPath = `${documentPath}/${product.id}/image`;
  const { createStackedNotification, firebase, systemId } = useAppContext();
  const organisationId:any=currentOrganisation?.id;
  const { env } = useAppStaticQueryConfig();

  const onDuplicateProductClick = async () => {
    setIsDuplicatingDocs(true);
    try {
      if (!firebase || !product.id || !currentOrganisation?.id) {
        return;
      }

     const duplicateProductResponse= await duplicateProduct(
        firebase,
        {
          systemId,
          organisationId,
          productId: product.id,
        },
        env,
      );
      const redirectId=duplicateProductResponse.data.payload.successArray.productId;
      console.log('duplicateProductResponse.data.payload.successArray',duplicateProductResponse.data.payload.successArray)
      const sourceProductDocumentImages=duplicateProductResponse.data.payload.successArray.sourceProductDocumentImages;
      if (sourceProductDocumentImages.length > 0) {
        for (let index = 0; index < sourceProductDocumentImages.length; index++) {
          const element = sourceProductDocumentImages[index];
          const fileRef = ref(getStorage(firebase), element.storagePath+'/'+element.id);
          if(fileRef){
            const downloadUrl = await getDownloadURL(fileRef);
            element.imageUrl=downloadUrl;
            const collectionPath=`organisation/${organisationId}/product_source/${redirectId}/image`;
            const docRef = doc(getFirestore(firebase), `${collectionPath}/${element.id}`);
            await setDoc(docRef, element);
          }
        }
       }
      createStackedNotification(NotificationType.SUCCESS, 'Duplication product success');
      setIsDuplicatingDocs(false);
       if(redirectId){
        navigate(
          `/admin/partner/product/edit/${productCategoryType}/${redirectId}`,
        );
       }
     
    } catch (err) {
      console.log('Error: ', JSON.stringify(err));
      createStackedNotification(
        NotificationType.ERROR,
        `duplication product error: ${JSON.stringify(err)}`,
      );
      setIsDuplicatingDocs(false);
    }
  };

  if (!currentOrganisation?.id) {
    return null;
  }

  return (
    <>
     
      <FirestoreCollectionFetch
        collectionPath={productImageCollectionPath}
        queryConstraints={[limit(1)]}
        onError={console.log}
        onSuccess={(res) => {
          if (res[0]?.imageUrl) {
            setPreviewImageUrl(res[0].imageUrl);
          } else {
            // FIXME: show default fallback image
          }
        }}
      />

      <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 h-full dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative'>
        <div className='w-full md:w-1/3'>
          <ProductListItem<T>
            isSourcedImage={false}
            product={
              previewImageUrl ? { ...product, imagePreview: [previewImageUrl] } : product
            }
            hideTagsAndRatings
          />
        </div>
        <div className='w-full h-full md:h-auto md:w-2/3 p-4 md:p-8'>
          <div className='grid gap-4 md:grid-cols-2 pb-3'>
            <Button // TODO: must be disabled if organisation is not unlocked + user must have permissions
              size={'small'}
              fullWidth
              classes={getButtonColorByProductState(product.meta.status, styles)}
              onClick={() =>
                openDialog(OrganisationAdminDialogType.publish, {
                  documentId: product.id,
                  documentPath,
                  onCloseDialog: () => {
                    closeDialog();
                  },
                  organisationDocument,
                })
              }
            >
              {product.meta.status=="__publish_rejected" ?  "status : REJECTED" :
              <Localized
                dictKey={'organisation:org-products.product.status.button.label'}
                translationOptions={{ status: product.meta.status }}
              />}
            </Button>
            <Button
              onClick={() =>
                navigate(
                  `/admin/partner/product/edit/${productCategoryType}/${product.id}`,
                )
              }
              size={'small'}
              fullWidth
              color={'primary'}
              variant={'contained'}
            >
              <Localized
                dictKey={'organisation:org-products.product.action.edit-page-link.label'}
              />
            </Button>
            <Button
              disabled={!isProductPublished}
              onClick={() =>
                onOpenInNewWindow(`/${defaultPreviewLocaleId}/experience/${product.id}`)
              }
              size={'small'}
              fullWidth
              color={'primary'}
              variant={'contained'}
            >
              <Localized
                dictKey={
                  'organisation:org-products.product.action.product-page-link.label'
                }
              />
            </Button>
            <Button
              disabled={!isProductPublished} // TODO: must be disabled if organisation is not unlocked
              onClick={() => navigate(`event/${product.id}`)}
              size={'small'}
              fullWidth
              color={'primary'}
              variant={'contained'}
            >
              <Localized
                dictKey={
                  'organisation:org-products.product.action.booking-page-link.label'
                }
              />
            </Button>
            <Button // TODO: must be disabled if organisation is not unlocked
              onClick={onDuplicateProductClick}
              size={'small'}
              fullWidth
              color={'primary'}
              variant={'contained'}
              disabled={isDuplicatingDocs}
            >
              <Localized
                dictKey={
                  'organisation:org-products.product.action.duplicate-page-link.label'
                }
              />
            </Button>
          </div>
          {isDuplicatingDocs ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : null}
          <div className='grid gap-1 md:gap-4 md:grid-cols-2 text-p3 pt-3'>
            <p>{product.internal?.displayName || product.id} </p>
            <p className='md:text-right font-medium'>
              {formatFirebaseTimestamp(product.meta.createdAt)}
            </p>
            {product.internal?.sku ? (
              <p>
                {`(SKU: ${product.internal.sku})`}
              </p>
            ) : null}
            {isAppDebug ? (
              <>
                {product.internal?.sku ? (
                  <p className='md:text-right font-medium'>
                    {`(${product.id})`}
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};


