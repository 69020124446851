import * as React from 'react';

import { BaseComponentProps, SourcedGQLCity, SourcedGQLLocation } from 'src/app/types';
import { useSourcedCityAreas, useSourcedProducts } from 'src/app/hooks';

import { GatsbyNodeType } from 'src/app/constants';

import { useLocalizationContext } from 'src/app/context';
import { ContentItemScreen } from '../ContentItemScreen';

export interface CityScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLCity;
  itemLocations: SourcedGQLLocation[];
}

export const CityScreen = (props: CityScreenProps) => {
  const { item, itemLocations, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  const products = useSourcedProducts();
  const cityAreas = useSourcedCityAreas();

  return (
    <ContentItemScreen
      collectionMappings={[
        {
          id: GatsbyNodeType.CITY_AREA,
          collectionItems: cityAreas,
          collectionItemLocationIdPropertyName: 'cityId',
        },
        {
          id: GatsbyNodeType.PRODUCT,
          collectionItems: products,
          collectionItemLocationIdPropertyName: 'cityId',
          renderWithProductListItem: true, // TODO:
        },
      ]}
      itemLocations={itemLocations || []}
      itemType={GatsbyNodeType.CITY}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
