import * as React from 'react';
import clsx from 'clsx';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SwipeableViews from 'react-swipeable-views';

import { useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { IconButton, Paper, Tab, Tabs } from '@material-ui/core/';

import { useFormContext, useFieldArray, FieldValue, FieldValues } from 'react-hook-form';

import { ButtonTw, ButtonGroup, Grid, Localized } from 'src/app/components';
import { useStyles } from 'src/app/context';
// import { FieldInputContainer, FieldInformation } from '../Components';
import { FormFieldLayout } from '../Layouts';
import {
  FormFieldProps,
  FormArrayFieldRenderProps,
  NestedFieldProps,
} from '../form.types';

import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';
import { FieldStyle } from '../form.constants';
import { useAdminContext } from 'src/admin/context';

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface ArrayFieldProps {
  hideRemoveButton?: boolean;
  initialItemValue: Record<string, unknown>; // for initializing new items
  initialItems: Record<string, unknown>[]; // for defaultValues (maybe rename)
  renderElements: (props: FormArrayFieldRenderProps) => React.ReactNode;
  itemLimit?: number;
}

export const FormArrayFieldNew = <T extends Record<string, unknown>>(
  props: FormFieldProps<T[]> & ArrayFieldProps,
) => {
  const {
    children,
    // defaultValue, // not used in array
    hideRemoveButton,
    i18nKey,
    initialItemValue, // array only
    initialItems,
    itemLimit,
    renderElements, // array only
    name,
    disabled,
    rules,
  } = props;
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [isCollapsedIds, setIsCollapsedIds] = React.useState<string[]>([]);
  const [collapsedDataArray, setCollapsedDataArray] = React.useState<{ id: string }[]>(
    [],
  );
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();
  // TODO: maybe a state hook will suffice to temp save the array values while collapsed?

  //  console.log('xxx FormArrayField initialItemValues: ', children);

  const styles = useStyles();
  const hasError = !!errors?.[name]; // TODO:

  const keyName = `RHF__arrayItemKeyName_${name}`;
  console.log('i18nKey',i18nKey)

  let { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name, // unique name for your Field Array
    keyName, // default to "id", you can change the key name
    // shouldUnregister: true, // Field Array will be unregistered after unmount.
  });

  let watchedArrayValues: { id: string }[] = watch(name, []);
  let userRoleValue=false;
    if(i18nKey =='organisation:document.activityProductDocument.localizations.fields.food.localizations'){
      const { document, userRole } = useAdminContext();
      if (userRole == 'partner') {
      userRoleValue=true;
     }
    }
  // TODO: consider use setValues intially on presence of initialItems?

  // React.useEffect(() => {
  //   if (initialItems?.length && !watchedArrayValues?.length) {
  //     const transformedItems = initialItems.map((item) => {
  //       if (typeof item === 'string' || typeof item === 'number') {
  //         return {
  //           value: item,
  //         };
  //       }
  //       return item;
  //     });
  //   }
  // }, [initialItems, watchedArrayValues]);

  const onChangeCollapseState = (itemId: string, index: number) => {
    const res = isCollapsedIds.find((id) => id === itemId);
    if (res) {
      setIsCollapsedIds(isCollapsedIds.filter((id) => id !== itemId));
      setValue(`${name}.${index}`, watchedArrayValues?.[index], { shouldDirty: true });
    } else {
      setIsCollapsedIds([...isCollapsedIds, itemId]);
    }
  };

  const hasMaxItemsReached =
    !itemLimit || !watchedArrayValues ? false : watchedArrayValues.length >= itemLimit;

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex < 0) return;
    setTab(tabIndex);
  };

  const handleTabChangeIndex = (index: number) => {
    if (index < 0) return;
    setTab(index);
  };

  React.useEffect(() => {
    if (!fields.length) return;
    setTab(fields.length - 1);
  }, [fields.length]);
  // console.log('watchedArrayValues: ', watchedArrayValues);

  // const watchedArrayValues = watch(name, initialItems?.length ? [...initialItems] : []);
  return (
    <FormFieldLayout
      additionalBottomArea={
        fields?.length && (
          <FieldInformation
            hasError={hasError}
          // description={<Localized dictKey={`${i18nKey}.field.description`} />}
          // example={<Localized dictKey={`${i18nKey}.field.example`} />}
          >
            {children}
            {userRoleValue && i18nKey =='organisation:document.activityProductDocument.localizations.fields.food.localizations' ? 
          null
          :( 
            <div className='py-4'>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor='primary'
                onChange={handleTabChange}
                aria-label='disabled tabs example'
                scrollButtons='auto'
                variant='scrollable'
              >
                { fields?.length > 0 
                  ? fields.map((field: FieldValue<FieldValues>, index) => {
                    return (
                      <Tab
                        key={`${index}tab1`}
                        label={
                          <span>
                            <Localized
                              className={clsx({
                                [styles.pl__1]: true,
                              })}
                              dictKey={`${i18nKey}.array.itemCount`}
                              translationOptions={{ itemIndex: index + 1 }}
                            />
                            <IconButton
                              component='div'
                              size='small'
                              onClick={() => remove(index)}
                            >
                              <DeleteForeverIcon fontSize='small' />
                            </IconButton>
                          </span>
                        }
                        {...a11yProps(index)}
                      />
                    );
                  })
                  : null}
                ;
              </Tabs>
            </div>
          )
        }
            
            {/* There is an issue with SwipeableViews, on every other tab there is no caret in input boxes  */}
            {/* <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tab}
              onChangeIndex={handleTabChangeIndex}
            > */}
          
            {fields?.length > 0
              ? fields.map((field: FieldValue<FieldValues>, index) => {
                const isCollapsed = isCollapsedIds.includes(field[keyName]);
                const watchedItem = watchedArrayValues?.[index] || null;
                // console.log('watchedItem values: ', watchedItem);
                return (
         <Card
            key={field[keyName]} // IMPORTANT
            className={'!border-none'}
            variant='outlined'
            role='tabpanel'
            hidden={tab !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
          >
            {/* <Grid item xs={12}>
                <ButtonGroup fullWidth size={'medium'}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        color={'primary'}
                        type={'button'}
                        onClick={() => remove(index)}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Remove{' '}
                      </Button>
                    </Grid>
                  </Grid>
                </ButtonGroup>
              </Grid> */}

            {isCollapsed 
              ? null
              : renderElements({
                index,
                namePrefix: `${name}.${index}`,
                i18nPrefix: `${i18nKey}.fields`,
                arrayItems: watchedArrayValues,
                defaultValues: watchedItem || initialItemValue,
                removeItem: (idx: number) => remove(idx),
                // NOTE: maybe a 'isHidden' flag passed to renderProps is the solution?
              } as FormArrayFieldRenderProps)}
            {/* {isCollapsed || hideRemoveButton ? null : (
                  <Grid item xs={12}>
                    <ButtonGroup fullWidth size={'medium'}>
                      <Grid container>
                        <Button
                          fullWidth
                          color={'primary'}
                          type={'button'}
                          onClick={() => remove(index)}
                          startIcon={<DeleteForeverIcon />}
                        >
                          Remove{' '}
                        </Button>
                      </Grid>
                    </ButtonGroup>
                  </Grid>
                )} */}
          </Card>

                 

                );
              })
              : null}
            {/* </SwipeableViews> */}
          </FieldInformation>
        )
      }
      debugId={name}
      debugI18nKey={i18nKey}
      hasError={hasError}
      fieldStyle={FieldStyle.CLEAN}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      
      >
          {userRoleValue && i18nKey =='organisation:document.activityProductDocument.localizations.fields.food.localizations' ? 
          null
          :( <ButtonTw
            disabled={disabled || hasMaxItemsReached}
            type={'button'}
            size={'lg'}
            rounded
            variant={'outline'}
            onClick={() => append(initialItemValue)}
            className='w-full my-4'
          >
            <AddCircleOutlineIcon className='mr-2' />
            <Localized dictKey={'common:action.add.label'} />
            <Localized
              className='ml-2'
              dictKey={`${i18nKey}.array.itemName`}
            />
          </ButtonTw>)
        }
        
         
    

      </FieldInputContainer>
    </FormFieldLayout>
  );
};

export const ArrayFieldNew = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps & ArrayFieldProps) => (
  <FormArrayFieldNew
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormArrayFieldNew>
);
