import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import querystring from 'querystring';

import { NotificationType } from 'src/app/constants';
import { ButtonTw, TextField } from 'src/app/components';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks'; // TODO: select systemId based on system user data
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

interface MessageCreateProps {
  // id?: string; // not passed directly, but injected via reach-router route
  targetId: string | null;
  routerBasePath: string;
  messageCollectionPath: string;
  // messageType: MessageType;
  // onSendMessage: (messageId: string, messageText: string) => void;
  // uid: string;
}

export const MessageCreate = ({
  // id,
  routerBasePath,
  messageCollectionPath,
  targetId, // fixed targetId for organisation to system communication // => Phase1, needs to be improved
}: // messageType,
// onDeleteMessage,
// onRespondToMessage,
// uid,
MessageCreateProps) => {
  const urlParams = useLocation();
  let urlParamInitialValue = '';
  const queryStringObj = querystring.parse(urlParams.search);
  if (queryStringObj?.['?product']) {
    urlParamInitialValue = queryStringObj?.['?product'] as any as string;
  }
  const { /* currentSystem, */ createNotification, firebase } = useAppContext(); // TODO: select systemId based on system user data
  const [targetIdInput, setTargetIdInput] = React.useState('');
  const [subjectInput, setSubjectInput] = React.useState(urlParamInitialValue);
  const [bodyInput, setBodyInput] = React.useState('');
  const { env } = useAppStaticQueryConfig();

  const sendMessage = async () => {
    if (!firebase) {
      return;
    }

    interface SendMessageData {
      collection: string;
      // systemId: string;
      targetId: string;
      subject: string;
      body: string;
    }

    // Make regional
    const send = httpsCallable(
      getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
      'commonFunctions-sendMessage',
    );

    try {
      const params: SendMessageData = {
        collection: messageCollectionPath,
        // systemId: uid,
        targetId: targetId || targetIdInput,
        subject: subjectInput,
        body: bodyInput,
      };

      await send(params); // make function non async?

      // TODO: i18n
      createNotification(NotificationType.SUCCESS, 'Message successfully sent', 5000);
      setTargetIdInput('');
      setSubjectInput('');
      setBodyInput('');
      navigate(`${routerBasePath}`);
    } catch (err) {
      console.warn('Message sent error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    }
  };

  // FIXME: Input validation

  const onSendMessage = () => {
    if (!targetIdInput || !subjectInput || !bodyInput) {
      if (!targetId) {
        return;
      }
    }
    sendMessage();
  };

  return (
    <>
      <div className='flex flex-col gap-y-4'>
        {/** hide input if targetId is fixed */}
        {!targetId ? (
          <div>
            <TextField
              fullWidth
              onChange={(e) => setTargetIdInput(e.target.value)}
              value={targetIdInput}
              variant={'outlined'}
              label={'TargetId'}
            />
          </div>
        ) : null}
        <div>
          <TextField
            fullWidth
            onChange={(e) => setSubjectInput(e.target.value)}
            value={subjectInput}
            variant={'outlined'}
            label={'Subject'}
            disabled={!!urlParamInitialValue || false}
          />
        </div>
        <div>
          <TextField
            fullWidth
            multiline
            label={'Message body'}
            onChange={(e) => setBodyInput(e.target.value)}
            rowsMax={10}
            rows={10}
            value={bodyInput}
            variant={'outlined'}
          />
        </div>
        <div className='text-right'>
          <ButtonTw
            variant={'accent'}
            size={'lg'}
            onClick={onSendMessage}
            rounded
          >
            Send message
          </ButtonTw>
        </div>
       </div>
    </>
  );
};
