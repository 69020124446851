import * as React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';

export interface FieldInputContainerProps {
  children: React.ReactNode;
  hasError: boolean;
  title: React.ReactNode;
}

export const FieldInputContainer = ({
  children,
  hasError,
  title,
}: FieldInputContainerProps) => {
  const styles = useStyles();
  return <>{children}</>;
};
