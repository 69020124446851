import { FirebaseApp } from 'firebase/app';
import { collection, doc, getFirestore } from 'firebase/firestore';

import {
  ParticipantImportData,
  ProductEventParticipant,
  ShoppingCartData,
  ShoppingCartItemConfiguration,
  ShoppingCartItemDocumentData,
  SourcedGQLProduct,
} from 'src/app/types';

import { createDocumentId } from 'src/lib/db';

// TODO: sync with stipe excel table
export const createParticipantFromImportData = (
  participant: ParticipantImportData,
): ProductEventParticipant => ({
  id: createDocumentId(),
  dob: new Date(),
  firstName: participant.firstName,
  lastName: participant.lastName,
  options: {
    pickup: participant.pickupId || null,
    food: participant.foodId || null,
    language: participant.language || null,
  },
  // TODO: add other props from xslx import
});

export const createInitialParticipant = (
  firebase: FirebaseApp,
): ProductEventParticipant => ({
  id: doc(collection(getFirestore(firebase), 'dummy')).id,
  dob: null,
  firstName: null,
  lastName: null,
  options: {
    pickup: null,
    food: null,
    language: null,
  },
});

// NEW

// FIXME: proper validation
export const isParticipantValid = (p: ProductEventParticipant) => {
  if (!p.id || !p.firstName || !p.lastName || !p.dob || !p.options) {
    // TODO: validate options.food & options.pickup
    return false;
  }
  return true;
};

export const areShoppingCartItemParticipantsIncomplete = (
  item: ShoppingCartItemDocumentData,
) => {
  const { itemConfigurations } = item;
  const itemConfigurationsWithCount = [
    ...itemConfigurations.filter((iCfg) => iCfg.count),
  ];
  const invalidParticipants = [];

  itemConfigurationsWithCount.forEach((iCfg) => {
    if (iCfg.participants?.length) {
      iCfg.participants.forEach((p) => {
        if (!isParticipantValid(p)) {
          invalidParticipants.push(p);
        }
      });
    }
  });
  return !!invalidParticipants.length;
};

export const areShoppingCartItemsParticipantsIncomplete = (
  items: ShoppingCartItemDocumentData[],
  srcProducts: SourcedGQLProduct[],
) => {
  const invalidParticipants = [];

  // FIXME:
  // FIXME:

  // const priceSystemId = items[0].priceSystemId; // TODO: map
  // const bookingType = 'publicBooking'; // | 'privateBooking'

  // const isParticipantInfoNecessary = srcProducts[0].priceSystems.find((ps) => ps.id === priceSystemId)?.[bookingType].isParticipantInfoNecessary;

  // TODO: check if participantInfo is needed in src product

  for (let index = 0; index < items.length; index++) {
    const item = items[index];
    const { itemConfigurations } = item;
    const itemConfigurationsWithCount = [
      ...itemConfigurations.filter((iCfg) => iCfg.count),
    ];

    itemConfigurationsWithCount.forEach((iCfg) => {
      iCfg?.participants?.forEach((p) => {
        if (!isParticipantValid(p)) {
          invalidParticipants.push(p);
        }
      });
    });
  }
  return !!invalidParticipants.length;
};

export const replaceCartPartipantIds = (
  shoppingCart: ShoppingCartData,
  firebase: FirebaseApp,
) => {
  // console.log('>>> original cart: ', shoppingCart);
  const replacedIdsCart = {
    ...shoppingCart,
    items: shoppingCart.items.map((item) => ({
      ...item,
      itemConfigurations: item.itemConfigurations.map((iCfg) => ({
        ...iCfg,
        participants: iCfg.participants.map((participant) => ({
          ...participant,
          id: doc(collection(getFirestore(firebase), 'not-used-collection')).id,
        })),
      })),
    })),
  };
  // console.log('>>> replacedIdsCart: ', replacedIdsCart);
  return replacedIdsCart;
};

export const getCartItemsTotalParticipantCount = (
  cartItems: ShoppingCartItemConfiguration[],
) => {
  let totalCount = 0;
  if (cartItems && Array.isArray(cartItems)) {
    cartItems.forEach((item) => {
      totalCount += item.count;
    });
  }
  return totalCount;
};
