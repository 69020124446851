import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useAppContext } from 'src/app/hooks';
import { createBaseDocumentCreatedBy, delay, updateExistingDocument } from 'src/app/lib';

import {
  DocumentCreateErrorNotification,
  DocumentCreateSuccessNotification,
  DocumentUpdateErrorNotification,
  DocumentUpdateSuccessNotification,
} from 'src/app/components';

import { NotificationType } from 'src/app/constants';
import { BaseDocumentData } from 'src/app/types';

export type AcceptedDataTypesTodo = BaseDocumentData; // BaseDocumentData | CountryDocumentCreateFormData => all other source types combined | fix with meta structure ;

export interface UseSetDocument {
  setDocument: <T extends AcceptedDataTypesTodo>(
    data: T,
    collectionPath: string,
    isUpdateOperation: boolean, // indicates an update operation for notifications
    onSuccess?: (() => void) | Promise<void>,
  ) => Promise<void>;
}

export const useSetDocument = (): UseSetDocument => {
  const { createNotification, firebase, isLoggedIn, user } = useAppContext();

  const setDocument = async <T extends AcceptedDataTypesTodo>(
    data: T,
    collectionPath: string,
    isUpdateOperation: boolean,
    onSuccess?: (() => void) | Promise<void>,
  ) => {
    if (!firebase || !isLoggedIn || !user || !user.uid || !data.id) {
      // TODO: check if document already exists ?
      return;
    }
    try {
      const docRef = doc(getFirestore(firebase), `${collectionPath}/${data.id}`);

      // NOTE: while this is only used in system admin context, always insert createdBy
      const transformDocData = isUpdateOperation
        ? updateExistingDocument<T>(user.uid, data.id, data)
        : createBaseDocumentCreatedBy<T>(user.uid, data.id, data);

      await setDoc(docRef, transformDocData);
      if (typeof onSuccess !== 'undefined') {
        await delay(100);
        if (onSuccess instanceof Promise) {
          await onSuccess;
        } else {
          onSuccess();
        }
      }
      createNotification(
        NotificationType.SUCCESS,
        isUpdateOperation
          ? DocumentUpdateSuccessNotification
          : DocumentCreateSuccessNotification,
      );
    } catch (err) {
      createNotification(
        NotificationType.ERROR,
        isUpdateOperation
          ? DocumentUpdateErrorNotification
          : DocumentCreateErrorNotification,
      );
      console.warn('Error on submit - finalizing uploads error: ', err);
    }
  };

  return { setDocument };
};
