import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { BookingReferenceFormSubmitData } from 'src/app/types';

import { FormError } from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  bookingReferenceId: yup
    .string()
    .required(FormError.REQUIRED)
    .max(64, FormError.NUMBER_MAX_VALUE), // TODO: min length + format check
  name: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE), // TODO: min length  + format check
  email: yup
    .string()
    .email(FormError.EMAIL)
    .required(FormError.REQUIRED)
    .max(64, FormError.NUMBER_MAX_VALUE), // TODO: min length  + format check
  phone: yup.string().optional().max(32, FormError.NUMBER_MAX_VALUE), // TODO: min length  + format check
  body: yup.string().required(FormError.REQUIRED).max(2000, FormError.NUMBER_MAX_VALUE), // TODO: min length  + format check
  // attachment:
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: BookingReferenceFormSubmitData = {
  bookingReferenceId: '',
  name: '',
  email: '',
  phone: '',
  body: '',
  // attachment: null,
};
