import * as React from 'react';

import { Grid, Typography } from 'src/app/components';

export interface SubmitSuccessMessageProps {
  text: string | React.ReactNode;
}

export const SubmitSuccessMessage = ({ text }: SubmitSuccessMessageProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h6'} renderAs={'p'} align={'center'} color={'secondary'}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
