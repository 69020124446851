import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';
// import Input from '@material-ui/core/Input';

import { Localized, TextInputField } from 'src/app/components';
// import { useStyles } from 'src/app/context';

import { FormFieldProps, NestedFieldProps } from '../form.types';
import { FormFieldLayout } from '../Layouts';
import {
  FieldAdditionalContainer,
  FieldAdornment,
  FieldErrors,
  FieldInputContainer,
  FieldInformation,
} from '../Components';

type NumberChangeEventType = HTMLInputElement | HTMLTextAreaElement;
export const integerTransformWithoutZero: NumberFieldTransform = {
  renderValue: (value: number) => {
    if (!value) {
      return '';
    }
    return Number.isNaN(value) || value === 0 || value < 0 ? '' : value.toString();
  },
  input: (e: React.ChangeEvent<NumberChangeEventType>) => {
    const output = parseInt(e.target.value, 10);
    return Number.isNaN(output) || e.target.value === '-' ? 0 : output;
  },
};

export const integerTransform: NumberFieldTransform = {
  renderValue: (value: number) => {
    return Number.isNaN(value) /* || value === 0 */ ? '' : value.toString();
  },
  input: (e: React.ChangeEvent<NumberChangeEventType>) => {
    const output = parseInt(e.target.value, 10);
    return Number.isNaN(output) ? 0 : output;
  },
};

export const floatTransform: NumberFieldTransform = {
  renderValue: (value: number) => {
    return !value || Number.isNaN(value) /* || value === 0 */ ? '' : value.toString();
  },
  input: (e: React.ChangeEvent<NumberChangeEventType>) => {
    const output = parseFloat(e.target.value);
    return Number.isNaN(output) ? 0 : output;
  },
};

export const floatTransformWithoutZero: NumberFieldTransform = {
  renderValue: (value: number) => {
    return !value || Number.isNaN(value) /* || value === 0 */ ? '' : value.toString();
  },
  input: (e: React.ChangeEvent<NumberChangeEventType>) => {
    const output = parseFloat(parseFloat(e.target.value).toFixed(2));
    let res = Number.isNaN(output) ? 0 : output;
    if (res < 0) {
      res *= -1;
    }
    return res;
  },
};

// FIXME  input is "swallowing" the first char after the comma... => not used anymore ATM...
export const floatTransformWithoutZeroForMoney: NumberFieldTransform = {
  renderValue: (value: number) => {
    console.log('floatTransformWithoutZeroForMoney value: ', value);
    return  Number.isNaN(value)|| value === null /* || value === 0 */ ? '' : value.toString();
  },
  input: (e: React.ChangeEvent<NumberChangeEventType>) => {
    console.log('floatTransformWithoutZeroForMoney input: ', e.target.value);
    if(e.target.value==''){
      return 0;
    }
    const interMediateValue = parseFloat(e.target.value).toFixed(2);
    console.log('interMediateValue: ', interMediateValue);
    const output = parseFloat(interMediateValue);
    console.log('output: ', output);
    let res = Number.isNaN(output) ? 0 : output;
    if (res < 0) {
      res *= -1;
    }
    // TODO: use pad + convert into number afterwards?
    // return 0.04;
    return res;
  },
};

export interface NumberFieldTransformProps {
  inputProps?: {
    step?: number;
    min?: number;
    max?: number;
    type?: 'number' | 'string';
  };
  isInteger?: boolean;
  isMoney?: boolean;
  isPercent?: boolean;
  transform?: NumberFieldTransform;
}

export interface NumberFieldTransform {
  renderValue: (val: number) => string;
  input: (e: React.ChangeEvent<NumberChangeEventType>) => number;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={','}
      isNumericString
      prefix='$'
    />
  );
}

function MoneyFieldFormat(props: {}) {
  // TODO:
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={','}
      isNumericString
      // prefix='$'
      // suffix='€'
    />
  );
}

const FormNumberField = ({
  children,
  defaultValue,
  inputProps: passedInputProps,
  i18nKey,
  isInteger,
  isMoney,
  isPercent,
  label,
  name,
  rules,
  transform,
  ...props
}: FormFieldProps<number> & NumberFieldTransformProps) => {
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
  } = formMethods;

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    // shouldUnregister: true,
  });

  const { ref, ...inputProps } = field;
  // const styles = useStyles();
  const hasError = !!errors?.[name]; // TODO:

  const defaultTransform = integerTransformWithoutZero;

  let t: NumberFieldTransform = defaultTransform;
  let inputPropsArgs: any = {
    endAdornment: <FieldAdornment hasError={hasError} />,
  };
  if (transform) {
    t = transform;
  }
  if (isInteger) {
    inputPropsArgs = {
      // inputComponent: Input,
      // inputProps: { ...inputProps, checked: inputProps?.value || false },
      endAdornment: <FieldAdornment hasError={hasError} />,
      inputProps: {
        step: passedInputProps?.step || 1,
        min: passedInputProps?.min || 1,
        max: passedInputProps?.max || 1000000,
        type: passedInputProps?.type || 'number',
        // 'aria-labelledby': 'input-slider',
      },
    };
  }
  if (isMoney) {
    // t = floatTransformWithoutZero;
    t = floatTransformWithoutZeroForMoney;
    inputPropsArgs = {
      // inputComponent: Input,
      // inputProps: { ...inputProps, checked: inputProps?.value || false },
      endAdornment: <FieldAdornment hasError={hasError} />,
      inputProps: {
        step: passedInputProps?.step || 0.01,
        min: passedInputProps?.min || 0,
        max: passedInputProps?.max || 1000000,
        type: passedInputProps?.type || 'number',
        // type: 'string', // TODO: try
        // 'aria-labelledby': 'input-slider',
      },
      // inputComponent: MoneyFieldFormat, // for custom inputs => TODO: pass as props?
    };
  }
  if (isPercent) {
    t = floatTransformWithoutZero;
    inputPropsArgs = {
      // inputComponent: Input,
      // inputProps: { ...inputProps, checked: inputProps?.value || false },
      endAdornment: <FieldAdornment hasError={hasError} />,
      inputProps: {
        step: passedInputProps?.step || 0.1,
        min: passedInputProps?.min || 0,
        max: passedInputProps?.max || 100,
        type: passedInputProps?.type || 'number',
        // 'aria-labelledby': 'input-slider',
      },
    };
  }

  return (
    <FormFieldLayout
      additionalBottomArea={
        <FieldInformation
          hasError={hasError}
          description={<Localized dictKey={`${i18nKey}.field.description`} />}
          example={<Localized dictKey={`${i18nKey}.field.example`} />}
        >
          {children}
        </FieldInformation>
      }
      debugId={name}
      debugI18nKey={i18nKey}
      hasError={hasError}
    >
      <FieldInputContainer
        hasError={hasError}
        title={<Localized dictKey={`${i18nKey}.field.name`} />}
      >
        <TextInputField
          variant={'outlined'}
          type={'number'}
          {...inputProps}
          {...props}
          inputRef={ref}
          error={hasError}
          label={<Localized dictKey={`${i18nKey}.field.name`} />}
          fullWidth
          inputProps={inputPropsArgs}
          onChange={(e) => field.onChange(t.input(e))}
          onBlur={(e) => {
            // TODO: unclean invalidateion
            if (isInteger && !e?.target?.value) {
              field.onChange('invalidate -');
            }
            field.onBlur();
          }}
          value={t.renderValue(field.value)}
          //value={isMoney ? field.value : t.renderValue(field.value)}
        />
      </FieldInputContainer>
    </FormFieldLayout>
  );
};

export const NumberField = ({
  children,
  name,
  namePrefix,
  i18nKey,
  i18nPath,
  ...rest
}: NestedFieldProps<number> & NumberFieldTransformProps) => (
  <FormNumberField
    {...rest}
    i18nKey={`${i18nPath}.${i18nKey}`}
    name={namePrefix ? `${namePrefix}.${name}` : name}
  >
    <FieldErrors
      i18nKey={`${i18nPath}.${i18nKey}`}
      name={namePrefix ? `${namePrefix}.${name}` : name}
    />
    <FieldAdditionalContainer>{children}</FieldAdditionalContainer>
  </FormNumberField>
);
