import * as React from 'react';
import MUICheckbox, {
  CheckboxProps as MUICheckboxProps,
} from '@material-ui/core/Checkbox';
import { Grid } from 'src/app/components';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

export interface CheckboxProps extends BaseComponentProps, MUICheckboxProps {}

/**
 * Checkbox / WAPCheckbox
 * @param { CheckboxProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Checkbox = (props: CheckboxProps) => {
  const { children, ...restProps } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUICheckbox {...restProps} />
        {children}
      </Grid>
    </Grid>
  );
};

const WAPCheckbox = (props: CombinedAppProps<CheckboxProps>) =>
  withoutAppProps<CombinedAppProps<CheckboxProps>, CheckboxProps>(Checkbox, props);

export { Checkbox, WAPCheckbox };
