import * as React from 'react';
import MTypography, {
  TypographyProps as MTypographyProps,
} from '@material-ui/core/Typography';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyleContext } from 'src/app/context';
import { CenteredContainer } from 'src/app/components';

export interface TypographyProps extends CombinedBaseComponentProps<MTypographyProps> {
  center?: boolean;
  centerV?: boolean;
  renderAs?: string; // Component is also possible
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

/**
 * Typography / WAPTypography - MUI
 * @param { TypographyProps } props
 * @version 0.0.0
 * @description
 * @todo find font + find right sizes for layout
 * @example
 */
const Typography = (props: TypographyProps) => {
  const { center, centerV, renderAs, children, className, style, ...passProps } = props;
  const { appFont } = useStyleContext();

  const typography = (
    <MTypography
      component={renderAs as any}
      // variantMapping={{
      //   // TODO: move this to provider config
      //   h1: 'h1',
      //   h2: 'h2',
      //   h3: 'h3',
      //   h4: 'h4',
      //   h5: 'h5',
      //   h6: 'h6',
      //   subtitle1: 'h6',
      //   subtitle2: 'h6',
      //   body1: 'p',
      //   body2: 'p',
      // }}
      className={`${className || ''}`}
      style={
        appFont
          ? {
              fontFamily: `"${appFont}", sans-serif`,
              ...style,
            }
          : style
      }
      {...passProps}
    >
      {children}
    </MTypography>
  );

  return center || centerV ? (
    <CenteredContainer centerV={centerV}>{typography}</CenteredContainer>
  ) : (
    typography
  );
};

const WAPTypography = (props: CombinedAppProps<TypographyProps>) =>
  withoutAppProps<CombinedAppProps<TypographyProps>, TypographyProps>(Typography, props);

export { Typography, WAPTypography };
