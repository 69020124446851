import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { Grid, Typography } from 'src/app/components';
import { useAppContext } from 'src/app/context';
import { useLocalizationContext, useSourcedTags } from 'src/app/hooks';
import { BaseComponentProps, SourcedGQLProduct } from 'src/app/types';
import { getTranslationForLanguage } from 'src/lib/document';

export interface ProductTagsProps extends BaseComponentProps {
  product: SourcedGQLProduct;
  tagLimit?: number;
}

export const ProductTags = (props: ProductTagsProps) => {
  const { product, tagLimit = 5 } = props;
  const { meta } = product; // make dependable on certain productTypes
  const { tagIds } = meta;
  console.log(props,props.product.id,tagIds,'tagids')
  const { language } = useLocalizationContext();
  const { isAppDebug } = useAppContext();
  // const { navigateLocalized } = useLocalizedNavigation();
  const tags = useSourcedTags();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);
  const fontSize = isWiderMD ? '0.75rem' : '0.55rem';
  return (    
    <div className='flex flex-wrap gap-x-4 md:gap-1'>
      {tagIds?.length
        ? tagIds.map((tagId, idx) => {
            const tag = tags.find((t) => t.id === tagId);
            if (!tag) {
              return null;
            }
            if (tagLimit && idx >= tagLimit) {
              return null;
            }
            const tagName = getTranslationForLanguage(
              tag?.localizations,
              language,
              'name',
              isAppDebug,
            );

            return (
              <div
                key={tagId}
                // item
                // xs={4}
                className='text-neutral-400 text-p4 py-1 md:text-neutral-50 md:bg-primary-500 md:px-4 rounded-2xl'
              >
                {/** // TODO: BUTTONS CANNOT BE NESTED => 2 variants for component isClickable? prop */}
                {/* <Button
              variant={'text'}
              onClick={() => navigateLocalized(`/tag/${tag.id}`)}
              size={'small'}
              style={{ padding: '2px 0', minHeight: '0' }}
            > */}
                
                  
                {tagName || tag.id}
                  
                
                {/* </Button> */}
              </div>  
            );
          })
        : null}
    </div>    
  );
};