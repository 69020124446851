import React from 'react';

// import FastfoodIcon from '@material-ui/icons/Fastfood';
// import LaptopMacIcon from '@material-ui/icons/LaptopMac';
// import HotelIcon from '@material-ui/icons/Hotel';
import StarIcon from '@material-ui/icons/Star';
import EmojiFlags from '@material-ui/icons/EmojiFlags';

export const ProductTimeline = ({ timelineData }: { timelineData: any[] }) => {
  // const styles = useStyles();

  return (
    <div className='relative flex flex-col gap-y-10'>
      {timelineData.map((tld, idx) => {
        return tld.type === 'default' ? (
          <div key={`timeline_item_${tld.title}_${tld.time}`} className='relative flex gap-x-4 md:gap-x-9 md:pl-16'>   

            <div className=''>
              <div className='text-neutral-900 bg-accent-500 w-[38px] h-[38px] rounded-full flex items-center justify-center'>                
                <EmojiFlags />
              </div>
              {timelineData && idx < timelineData.length - 1 ? (
                <div className='w-[2px] h-full bg-primary-500 mx-auto'></div>
              ) : null}
            </div>

            <div>
              <span className='text-p3 md:text-p2 md:absolute md:top-2 md:left-0'>
                {tld.time}
              </span>   
              <p className='text-p2 font-semibold'>
                {tld.title || ''}
              </p>
              <p className='text-p4 md:text-p3'>
                {tld.additionalInformation || ''}
              </p>              
            </div>
          </div>
        ) : null;
      })}      
    </div>
  );
};
