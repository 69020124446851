import * as React from 'react';
import MuiList, { ListProps } from '@material-ui/core/List';

import { useStyles } from 'src/app/context';

/**
 * @param { ListProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/list
 * @todo
 * @example
 */
export const List = (props: ListProps) => {
  const styles = useStyles();
  return <MuiList classes={{ root: styles.bg__transparent }} {...props} />;
};
