import * as React from 'react';
import { AppDialogParams, AppDialogType, DialogState } from 'src/app/types';

function useDialogState(): DialogState {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [dialogType, setDialogType] = React.useState<AppDialogType | null>(null);
  const [dialogData, setDialogData] = React.useState<AppDialogParams | null>(null);
  const closeDialog = () => {
    setDialogType(null);
    setDialogData(null);
    setIsDialogOpen(false);
  };
  const openDialog = <T extends AppDialogParams>(type: AppDialogType, data?: T) => {
    setDialogType(type);
    if (data) {
      setDialogData(data);
    }
    setIsDialogOpen(true);
  };
  return {
    closeDialog,
    dialogData,
    isDialogOpen,
    openDialog,
    dialogType,
  };
}

export { useDialogState };
