import { graphql, useStaticQuery } from 'gatsby';

import { AppEnvConfig, GQLSiteData } from 'src/app/types';

export function useAppStaticQueryConfig(): AppEnvConfig {
  const {
    site: {
      siteMetadata: { appData },
    },
  } = useStaticQuery<GQLSiteData>(graphql`
    {
      site {
        siteMetadata {
          appData {
            app {
              buildTime
              env
              nodeVersion
              version
              emulationMode
              # passwordMode
              # passwordHash
              systemId
            }
          }
        }
      }
    }
  `);
  return { ...appData.app };
}
