import * as React from 'react';

import { Container, Grid } from 'src/app/components';
import {
  BaseLocalizedDocumentData,
  SeoObject,
  SeoLocalization,
  SourcedGQLContinent,
  SourcedGQLCountry,
  SourcedGQLCountryArea,
  SourcedGQLCity,
  SourcedGQLCityArea,
  SourcedGQLLocation,
  SourcedGQLProduct,
  SourcedGQLTag,
  SourcedGQLCategory,
  SourcedGQLCategoryType,
} from 'src/app/types';
import { AppPage, AppPageProps } from 'src/app.consumer/components';

import { getSeoForLanguage } from 'src/lib/document';
import { useLocalizationContext } from 'src/app/context';

import { AppLocale } from 'src/app/constants';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CategoryScreen,
  CategoryTypeScreen,
  ContinentScreen,
  CountryScreen,
  CountryAreaScreen,
  CityScreen,
  CityAreaScreen,
  LocationScreen,
  ProductScreen,
  TagScreen,
} from './Document';

const seo: SeoObject = {
  title: 'Content Navigation',
};

const getDocumentSeoData = (
  document: BaseLocalizedDocumentData,
  siteSeo: SeoObject,
  language: AppLocale,
) => {
  return {
    title: `${siteSeo.title || ''} ${getSeoForLanguage(
      (document?.localizations as SeoLocalization[]) || [],
      language,
      'title',
      false,
    )}`,
    description: `${siteSeo.description || ''} ${getSeoForLanguage(
      (document?.localizations as SeoLocalization[]) || [],
      language,
      'description',
      false,
    )}`,
  };
};

export interface ContentNavigationProps extends AppPageProps {
  // TODO: consider bundling data like cityData: { city, cityLocations, city...}
  pageContext: {
    category: SourcedGQLCategory;
    categoryType: SourcedGQLCategoryType;
    // avail. inside of continent
    continent?: SourcedGQLContinent;
    continentCountries?: SourcedGQLCountry[];
    // avail. inside of country
    country?: SourcedGQLCountry;
    countryCities?: SourcedGQLCity;
    // avail. inside of countryArea
    countryArea?: SourcedGQLCountryArea;
    // avail. inside of city
    city?: SourcedGQLCity;
    cityData?: {
      cityLocations?: SourcedGQLLocation[];
      // cityProducts?:
    };
    // avail. inside of cityArea
    cityArea?: SourcedGQLCityArea;
    location?: SourcedGQLLocation;
    product?: SourcedGQLProduct;
    tag?: SourcedGQLTag;

    // special injected
    nextNodeId?: string;
    prevNodeId?: string;
  };
  params: { id: string };
} // TODO: etc

const ContentNavigation = (props: ContentNavigationProps) => {
  // console.log('ContentNavigationProps: ', props);
  const { pageContext, pageResources, pathContext } = props; // pathContext for retrieve ids and pageContext for localizations?
  const {
    // sourced collection items
    category,
    categoryType,
    continent,
    country,
    countryArea,
    city,
    cityData, // for cityLocations display
    cityArea,
    location,
    product,
    tag,
    // special
    nextNodeId,
    prevNodeId,
  } = pageContext; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  let seoObjectReference: BaseLocalizedDocumentData | null = null;
  if (category) {
    seoObjectReference = category;
  }
  if (categoryType) {
    seoObjectReference = categoryType;
  }
  if (continent) {
    seoObjectReference = continent;
  }
  if (country) {
    seoObjectReference = country;
  }
  if (city) {
    seoObjectReference = city;
  }
  if (cityArea) {
    seoObjectReference = cityArea;
  }
  if (location) {
    seoObjectReference = location;
  }
  if (product) {
    seoObjectReference = product;
  }
  if (tag) {
    seoObjectReference = tag;
  }

  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const pageSeo = seoObjectReference
    ? getDocumentSeoData(seoObjectReference, seo, language)
    : {};

  return (
    // NOTE: SET SEO DATA FOR ALL CONTENT HERE
    <AppPage
      {...props}
      seo={{
        ...seo,
        ...pageSeo,
      }}
    >
      <main className='md:pt-12'>               
        {category ? (
          <CategoryScreen
            item={category}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {categoryType ? (
          <CategoryTypeScreen
            item={categoryType}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {continent ? (
          <ContinentScreen
            item={continent}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {country ? (
          <CountryScreen
            item={country}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {countryArea ? (
          <CountryAreaScreen
            item={countryArea}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {city ? (
          <CityScreen
            item={city}
            itemLocations={cityData?.cityLocations || []}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {cityArea ? (
          <CityAreaScreen
            item={cityArea}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {location ? (
          <LocationScreen
            item={location}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {product ? (
          <ProductScreen
            item={product}
            nextNodeId={nextNodeId}
            prevNodeId={prevNodeId}
          />
        ) : null}
        {tag ? (
          <TagScreen item={tag} nextNodeId={nextNodeId} prevNodeId={prevNodeId} />
        ) : null}        
        
      </main>
    </AppPage>
  );
};

export { ContentNavigation };
export default ContentNavigation;
