import * as React from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import {
  ButtonTw,
  Localized,
} from 'src/app/components';
import { formatFirebaseTimestamp } from 'src/lib/date';
import { MessageType } from 'src/app/constants';
import { useStyles } from 'src/app/context';
import { BaseMessageDocument } from '../message.types';

interface MessagesListItemProps extends Record<string, unknown> {
  messageType: MessageType;
  message: BaseMessageDocument;
  onDeleteMessage: (messageId: string) => void;
}

export const MessagesListItem = ({
  message,
  messageType,
  onDeleteMessage,
}: MessagesListItemProps) => {
  const { id, meta, subject } = message;
  const styles = useStyles();

  // TODO: display meta.type visually

  return (
    <div className='py-3 px-6 my-2 rounded-xl bg-neutral-100 text-p3 dark:bg-neutral-800'>
      <div className='md:flex gap-4 border-b border-white dark:border-neutral-400 dark:border-opacity-20 py-2'>
        <div className='md:w-3/4'>
          <p>
            {meta?.type ? (
              <Localized dictKey={`organisation:messageType.${meta.type}.name`} />
            ) : (
              'Unknown'
            )}
            {` from ${meta?.name || 'Unknown'}`}
          </p>
        </div>
        {/* <Grid item md={4} xs={12}>
          <Typography variant={'body1'} renderAs={'p'}>
            From: {meta?.name || 'Unknown'}
          </Typography>
        </Grid> */}
        <div className='md:w-1/4'>
          <p className='md:text-right text-neutral-400 dark:text-neutral-400'>
            {meta?.createdAt
              ? ` ${formatFirebaseTimestamp(meta.createdAt)}`
              : ' unknown'}
          </p>
        </div>
      </div>
      <div className='md:flex gap-4 pt-2 items-center'>
        <div className='md:w-3/4'>
          <p>
            Subject: {subject || ''}
          </p>
        </div>
        <div className='md:w-1/4 flex md:justify-end gap-2'>
          <ButtonTw
            className={styles.py__0_5}
            size={'sm'}
            disabled
            onClick={() => (id ? onDeleteMessage(id) : null)}
            rounded
            variant={'neutral'}
          >
            Delete
          </ButtonTw>
          <ButtonTw
            className={styles.py__0_5}
            size={'sm'}
            onClick={() => navigate(id)}
            rounded
            variant={'accent'}
          >
            Open
          </ButtonTw>
        </div>
      </div>
    </div>
  );
};
