// TODO cleanup => remove
/** @deprected => cleanup or new version */
export enum FormFieldType {
  array = 'array', // you can add same type of items to it
  object = 'object', // you can nest FormFieldConfigs in it?
  boolean = 'boolean',
  checkboxlist = 'checkboxlist', // or booleanlist? or is it the same as multiselect?
  string = 'string',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  number = 'number',
  enum = 'enum',
  multiselect = 'multiselect',
  multiline = 'textfield',
  bulletlist = 'bulletlist',
  paragraph = 'paragraph',
  password = 'password',
  radiogroup = 'radiogroup',
}

export enum FormError {
  REQUIRED = 'required',
  ARRAY_LENGTH_1 = 'array-length-1',
  ARRAY_MAX_LENGTH = 'array-max-length',
  NUMBER_MIN_VALUE = 'number_min_value',
  NUMBER_MAX_VALUE = 'number_max_value',
  EMAIL = 'format_email',
  STRING_LENGTH = 'string_length',
  W3W = 'format_w3w', // TODO create localized error message + refactor this + email out into "FormFormatError"?
}
