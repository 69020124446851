import * as React from 'react';

import { Divider, Grid, Typography } from 'src/app/components';
import { TableRowLayout } from './TableRowLayout';

export const TableHeader = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableRowLayout
          firstChildren={
            <Grid container>
              <Grid item md={1} xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography center>{'Import'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography center>{'#'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography center>{'First Name'}</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography center>{'Last Name'}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{'Gender'}</Typography>
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography center>{'Country'}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{'Age'}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{'Passport id'}</Typography>
              </Grid>
            </Grid>
          }
          secondChildren={
            <Grid container>
              <Grid item md={4} xs={12}>
                <Typography center>{'Pricegroup'}</Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography center>{'Pickup'}</Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography center>{'Food'}</Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
