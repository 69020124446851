import { SourceCollectionName } from 'src/app/constants';

// const systemName = 'holiday'; // TODO - source ?

// export const getCurrentSystemName = () => systemName; // TODO
// FIXME: continentId + in FB.functions

// NOTE: maybe obsolte for orgs (pipe images through functions only?)
export const getOrganisationSourceDocumentImageStoragePath = (
  organisationId: string,
  documentCollectionName: SourceCollectionName,
  documentId: string,
): string => {
  if (!organisationId || !documentCollectionName || !documentId) {
    return 'default__invalided__param____getOrganisationSourceDocumentImageStoragePath';
  }

  return `organisation/${organisationId}/image/${documentCollectionName}/${documentId}`;
};

export const getOrganisationSourceCollectionPath = (
  organisationId: string,
  documentCollectionName: SourceCollectionName,
): string => {
  if (!organisationId || !documentCollectionName) {
    return 'default__invalided__param____getOrganisationSourceCollectionPath';
  }
  if (documentCollectionName === SourceCollectionName.PRODUCT) {
    return `organisation/${organisationId}/${'product_source'}`; // TODO: not clean handling
  }
  return 'default__invalided__param____getOrganisationSourceCollectionPath';
};
