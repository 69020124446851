import * as React from 'react';

import { BaseComponentProps, SourcedGQLCityArea } from 'src/app/types';
import { useSourcedProducts } from 'src/app/hooks';

import { GatsbyNodeType } from 'src/app/constants';

import { useLocalizationContext } from 'src/app/context';
import { ContentItemScreen } from '../ContentItemScreen';

export interface CityAreaScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLCityArea;
}

export const CityAreaScreen = (props: CityAreaScreenProps) => {
  const { item, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  const products = useSourcedProducts();
  const cityLocations = item?.cityLocations || [];
  return (
    <ContentItemScreen
      collectionMappings={[
        {
          id: GatsbyNodeType.PRODUCT,
          collectionItems: products,
          collectionItemLocationIdPropertyName: 'cityAreaId',
          renderWithProductListItem: true, // TODO:
        },
      ]}
      itemLocations={cityLocations || []}
      itemType={GatsbyNodeType.CITY_AREA}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
