import * as React from 'react';

import { DropDown, Typography } from 'src/app/components';

import { BasePickupLocation } from 'src/app/types';

import { useStyles } from 'src/app/context';

interface SelectPickupOptionProps {
  disabled?: boolean;
  onChange: (priceGroupId: string) => void;
  pickupLocations: BasePickupLocation[];
  selectedId?: string;
}

export const SelectPickupOption = ({
  disabled,
  onChange,
  pickupLocations,
  selectedId,
}: SelectPickupOptionProps) => {
  const styles = useStyles();

  // TODO: handle different types of locations properly
  const locations: { id: string }[] = [
    ...pickupLocations.map((loc) => ({ id: loc.id, ...loc })),
  ];

  console.log('--- pickupLocations: ', pickupLocations);
  console.log('--- locations: ', locations);

  return (
    <DropDown
      containerClassName={styles.p__1}
      disabled={disabled}
      variant={'contained'}
      size={'small'}
      // color={isFilterOn() ? 'secondary' : 'primary'}
      color={'primary'}
      label={
        <div
          style={{
            overflow: 'hidden',
            maxWidth: '32ch',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography align={'center'} variant={'caption'}>
            {selectedId
              ? `${locations.find((pg) => pg.id === selectedId)?.id || ''} ${
                  pickupLocations.find((pl) => pl.id === selectedId)?.basePrice || ''
                }`
              : 'Select pickup option'}
          </Typography>
        </div>
      }
      placement={'top'}
      options={[
        ...locations.map((option, idx) => (
          <Typography
            // color={!isFilterOnId(categoryId) ? 'primary' : 'secondary'}
            align={'center'}
            renderAs={'span'}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {pickupLocations[idx].localizations?.find((l) => l.localeId === 'en')?.locales
              .name || option.id}{' '}
            {pickupLocations[idx].basePrice
              ? `(${pickupLocations[idx].basePrice} €)`
              : '(No extra fee)'}
          </Typography>
        )),
      ]}
      selectedIndex={locations.findIndex((pg) => pg.id === selectedId)}
      onChange={(i: number) => onChange(locations[i].id)}
      showArrowIcon={false}
      openOnHover
      fullWidth
    />
  );
};
