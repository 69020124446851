import system from './system.json';
import systemLogin from './system-login.json';
// import organisationInvitation from './organisation-invitation.json';
// import organisationLogin from './organisation-login.json';
// import organisationRegistration from './organisation-registration.json';

export default {
  '/system': system,
  '/system/login': systemLogin,
  // '/organisation/invitation': organisationInvitation,
  // '/organisation/login': organisationLogin,
};
