import * as React from 'react';
import MuiMenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

// import clsx from 'clsx';
// import { useStyles } from 'src/app/context';

/**
 * MenuItem
 * @param { MenuItemProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/menu-item
 * @todo
 * @example
 */
// export const MenuItem = <T extends Record<string, unknown>>(
//   props: MenuItemProps<'li', T>,
// ) => {
//   // const styles = useStyles();
//   return <MuiMenuItem {...props} />;
// };

export const MenuItem = React.forwardRef(
  <T extends Record<string, unknown>>(
    props: MenuItemProps<'li', T>,
    ref: React.Ref<any>, // TODO: type
  ) => <MuiMenuItem {...props} innerRef={ref} />,
);
