import * as React from 'react';
import {
  Accordion,
  ButtonTw,
  Localized,
  SettingOption,
} from 'src/app/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from 'src/app/context';

export enum GDPRCategoryType {
  NECESSARY = 'necessary',
  FUNCTIONALITY = 'functionality',
  TRACKING_PERFORMANCE = 'tracking-performance',
  TARGETING_ADVERTISING = 'targeting-advertising',
}

export interface GDPRCategoryProps extends GDPRCategory {
  // id: string;
  isActive: boolean;
  onChange: () => void;
}

const GDPRCategory = (props: GDPRCategoryProps) => {
  const { isActive, id, onChange } = props;
  const styles = useStyles();
  return (
    <>
      <div className='my-2'>
        <Accordion
          expandIcon={<ExpandMoreIcon className='mr-1' />}
          summary={
            <p>
              <Localized dictKey={`gdpr:category.${id}.label`} />
            </p>
          }
        >
          <div className='py-3 px-4'>
            <p>
              <Localized dictKey={`gdpr:category.${id}.description`} />
            </p>
            <div>
              <SettingOption
                checked={isActive}
                description={
                  <Localized dictKey={`gdpr:action.${'disable-category'}.description`} />
                }
                label={<Localized dictKey={`gdpr:action.${'disable-category'}.label`} />}
                onChange={onChange}
                name={`gdpr-category-${id}-enabled-checkbox`}
              >
              </SettingOption>
            </div>
          </div>
        </Accordion>
      </div>
    </>
  );
};

export interface GDPRCategory {
  id: GDPRCategoryType;
  isActive: boolean;
}

const GDPRCategories = () => {
  const styles = useStyles();
  const categories: GDPRCategory[] = [
    { id: GDPRCategoryType.NECESSARY, isActive: true },
    { id: GDPRCategoryType.FUNCTIONALITY, isActive: false },
    { id: GDPRCategoryType.TRACKING_PERFORMANCE, isActive: false },
    { id: GDPRCategoryType.TARGETING_ADVERTISING, isActive: false },
  ];
  return (
    <>
      <div className='my-2'>
        <Accordion
          expandIcon={<ExpandMoreIcon className='mr-1' />}
          summary={
            <p>
              <Localized dictKey={`gdpr:chapter.${'privacy'}.title`} />
            </p>
          }
        >
          <div className='py-3 px-4'>
            <p>
              <Localized dictKey={`gdpr:chapter.${'privacy'}.description`} />
            </p>
          </div>
        </Accordion>
      </div>
      <div>
        {categories.map((cat) => (
          <GDPRCategory
            key={cat.id}
            onChange={() => console.log('changed cat.id: ', cat.id)}
            {...cat}
          />
        ))}
      </div>
      {/** More information extra chapter */}
      <div className='my-2'>
        <Accordion
          expandIcon={<ExpandMoreIcon className='mr-1' />}
          summary={
            <p>
              <Localized dictKey={`gdpr:chapter.${'information'}.title`} />
            </p>
          }
        >
          <div className='py-3 px-4 flex flex-col items-start gap-y-4'>
            <p>
              <Localized dictKey={`gdpr:chapter.${'information'}.description`} />
            </p>
            <ButtonTw variant={'outline'} rounded size='sm' >
              <Localized dictKey={'staticLocales:pages./contact.link label'} />
            </ButtonTw>
          </div>
        </Accordion>
      </div>
    </>
  );
};

export { GDPRCategories };
