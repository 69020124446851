import * as React from 'react';

import { ButtonTw, RenderI18nUrlItem, Localized } from 'src/app/components';
import { BookingReferenceFormContainer, ContactFormContainer } from 'src/app/container';
import { useTranslation } from 'react-i18next';
import { customerHelpTopicDictKey } from '../contactTopic.dictKey';

const topicKey = 'send-email';
export const SendEmail = () => {
  const { t } = useTranslation();
  const [messageType, setMessageType] = React.useState<
    'withReferenceId' | 'withoutReferenceId' | null
  >(null);

  const contentKey = `${customerHelpTopicDictKey}.${topicKey}.content`;
  const contentItems: string[] = t(contentKey, { returnObjects: true }) || [];

  const imgUrlsKey = `${customerHelpTopicDictKey}.${topicKey}.imageUrls`;
  const imgUrlItems: string[] = t(imgUrlsKey, { returnObjects: true }) || [];

  return (
    <div className='py-2 md:p-4'>
      {contentItems?.length && Array.isArray(contentItems)
        ? contentItems.map((item) => {
            return (
              <p className='mb-2' key={item}>
                {item}
              </p>
            );
          })
        : null}

      {!messageType ? (
        <>
          {/** // TODO: i18n */}
          <p>
            <Localized dictKey={'shop:/contact.topic.send-email.referenceID.title'} />
          </p>
          <div className='flex justify-center md:justify-start gap-2 py-2'>
            <ButtonTw
              variant={'accent'}
              rounded
              size='sm'
              className='min-w-[160px]'
              onClick={() => setMessageType('withoutReferenceId')}
            >
              <Localized dictKey={'shop:/contact.topic.send-email.referenceID.buttonNo'} />
            </ButtonTw>
            <ButtonTw
              variant={'accent'}
              rounded
              size='sm'
              className='min-w-[160px]'
              onClick={() => setMessageType('withReferenceId')}
            >
              <Localized dictKey={'shop:/contact.topic.send-email.referenceID.buttonYes'} />
            </ButtonTw>
          </div>
        </>
      ) : null}

      {messageType === 'withReferenceId' ? (
        <div>
          <BookingReferenceFormContainer />
        </div>
      ) : null}

      {messageType === 'withoutReferenceId' ? (
        <div>
          <ContactFormContainer />
        </div>
      ) : null}

      {imgUrlItems?.length && Array.isArray(imgUrlItems)
        ? imgUrlItems.map((item, idx) => (
            <RenderI18nUrlItem key={idx} prefixedStringFromImgUrls={item} />
          ))
        : null}
    </div>
  );
};
