import * as React from 'react';
import { Grid, Localized, Typography, MenuItem } from 'src/app/components';
import {
  FieldAdditionalContainer,
  FieldErrors,
  // FieldWarningText,
} from 'src/app/components/RHF/Components';

import { FormArrayFieldRenderProps } from 'src/app/components/RHF';

import { ArrayField, TextField, SelectField } from 'src/app/components/RHF/Fields';

import { useStyles } from 'src/app/context';

import { DocumentLocalizationNodeData } from 'src/app/types';
import { useAdminContext } from 'src/admin/context';
import { useFormContext } from 'react-hook-form';
import {
  ButtonTw
} from 'src/app/components';

interface LocalizationsFormProps {
  children?: React.ReactNode;
  foodOptions: FoodOption[];
  hasFoodOptions?: boolean;
  hasTimeline?: boolean;
  i18nKey: string;
  initialValues: {}; // TODO:
  locales: LocaleData[];
  localeProperties: string[];
  name: string;
  productProperties: string[];
  hideRedoButton?:Boolean;
  redoTranslation: (id: string) => void;};

interface FoodOption {
  foodId: string;
}

interface LocaleData {}

export const LocalizationsForm = ({
  children,
  foodOptions,
  hasFoodOptions,
  hasTimeline,
  i18nKey,
  initialValues,
  locales,
  name,
  localeProperties,
  productProperties,
  redoTranslation,
  hideRedoButton
}: LocalizationsFormProps) => {
  const styles = useStyles();
  const { setValue, reset } = useFormContext();

  const localeIdDefaultValue = 'en';

  const arrayItemIndex = 0;
  const arrayI18nPrefix = `${i18nKey}.fields`;
  const arrayNamePrefix = '';
  const arrayDefaultValues = { localeId: '', locales: {} };
  const { document, userRole } = useAdminContext();
  const language = 'en';
  if (userRole == 'partner') {
     const index = productProperties.indexOf('covidSafetyMeasures');
      if (index > -1) { 
        productProperties.splice(index, 1); 
      }
      const j = productProperties.indexOf('covidTravelerRequirements');
      if (j > -1) { 
        productProperties.splice(j, 1);
      }
  }
  const [newInitialValues,setNewInitialValues]=React.useState(initialValues)
  React.useEffect(()=>{
    setNewInitialValues(initialValues)
  },[initialValues,newInitialValues])
  const updateFormValue=async(id)=>{
    const response =await redoTranslation(id)
     reset({
      'locales':response?.locales,
      'product':response?.product,
      "timeline":response?.timeline
     })
  }

  return (
    <>
   <Grid item xs={12}>
     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
       <Typography align={'center'} style={{ flex: 1 }}>
        <Localized
        dictKey={`${i18nKey}.array.itemCount`}
        translationOptions={{ itemIndex: arrayItemIndex + 1 }}
      />
    </Typography>
  {hideRedoButton && (
    <ButtonTw  onClick={updateFormValue} size='sm' rounded variant={'outline'} >
      Generate Translation
    </ButtonTw>
  )}
    </div>
   </Grid>

      <SelectField
        defaultValue={localeIdDefaultValue}
        i18nKey={'localeId'}
        i18nPath={arrayI18nPrefix}
        name={'localeId'}
        namePrefix={arrayNamePrefix}
        options={[
          <MenuItem key={'none'} value=''>
            <em>None</em>
          </MenuItem>,
          ...locales.map((locale) => {
            return (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.localizations.find((loc) => loc.localeId === language)?.locales
                  .name || locale.id}
              </MenuItem>
            );
          }),
        ]}
      />
      {localeProperties
        ?.filter((p) => p !== 'teaser')
        .map((propName) => {
          let defaultValue = arrayDefaultValues?.locales?.[propName] || '';
          if (newInitialValues) {
            defaultValue =
              newInitialValues?.[arrayItemIndex]?.locales?.[propName] || defaultValue;
          }
          const isMultiLine = propName.toLowerCase().includes('desc');
          const calculateNumberOfRows = (str: string) => {
            let rowNumber = 4;
            if (str === 'description') {
              rowNumber = 12;
            }
            return rowNumber;
          };
          return (
            <TextField
              key={propName}
              multiline={isMultiLine}
              rows={isMultiLine ? calculateNumberOfRows(propName) : 1}
              i18nKey={`locales.${propName}`}
              i18nPath={arrayI18nPrefix}
              name={`locales.${propName}`}
              namePrefix={arrayNamePrefix}
              defaultValue={defaultValue}
            />
          );
        }) || null}

      {/** // TODO: SEO */}

      {/* {seoProperties ? (
        <Grid container className={styles.pl__4}>
          <Grid item xs={12} className={styles.py__4}>
            <Typography paragraph variant={'h6'} align={'center'} renderAs={'p'}>
              SEO
            </Typography>
          </Grid>

          {seoProperties?.map((propName) => {
            let defaultValue = arrayDefaultValues?.seo?.[propName] || '';
            if (newInitialValues) {
              defaultValue =
                newInitialValues?.[arrayItemIndex]?.seo?.[propName] || defaultValue;
            }
            return (
              <TextField
                key={propName}
                i18nKey={`seo.${propName}`}
                i18nPath={arrayI18nPrefix}
                name={`seo.${propName}`}
                namePrefix={arrayNamePrefix}
                defaultValue={defaultValue}
              >
                <Link
                  href={t(`${arrayI18nPrefix}.seo.${propName}.field.link.url`)}
                  rel={'noopener noreferrer'} // TODO: create premade "external link"
                  target={'_blank'}
                >
                  <Typography variant={'caption'}>
                    <Localized
                      dictKey={`${arrayI18nPrefix}.seo.${propName}.field.link.text`}
                    />
                  </Typography>
                </Link>
              </TextField>
            );
          }) || null}
        </Grid>
      ) : null} */}

      {/** // TODO: Foodoptions */}
      {/* {isFoodFeatureEnabled && priceSystems?.length ? (
        <Grid container className={styles.pl__4}>
          <Grid item xs={12} className={styles.py__4}>
            <Typography paragraph variant={'h6'} align={'center'} renderAs={'p'}>
              Food options
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {priceSystems.map((pSystem, idx) => {
              console.log(
                'foodOptions stuff',
                watchedLocalizations?.[0].product?.foodOptions?.[idx],
              );
              return (
                <ArrayField
                  key={pSystem.id}
                  i18nKey={'product.foodOptions'}
                  i18nPath={arrayI18nPrefix}
                  name={`product.foodOptions.${idx}`}
                  namePrefix={arrayNamePrefix}
                  initialItemValue={{}}
                  // initialItemValue={
                  //   {
                  //     additionalInformation: 'Food Additional Info',
                  //     title: 'Food Title',
                  //     id: 'fooId',
                  //     // time: '0:00',
                  //     // type: 'default',
                  //   } as DocumentLocalizationNodeData // TODO: type
                  // }
                  // initialItems={
                  //   watchedLocalizations?.[0].product?.foodOptions?.[idx] || []
                  // }
                  initialItems={[]}
                  // initialItems={
                  //   pSystem.priceSystemFoodOptions?.foodOptions?.map((fOption) => {
                  //     return {
                  //       additionalInformation: 'AddiFoo',
                  //       title: fOption.foodId || '',
                  //       id: fOption.foodId || '',
                  //     };
                  //   }) || []
                  // }
                  renderElements={(renderProps2: FormArrayFieldRenderProps) => {
                    const {
                      defaultValues: arrayDefaultValues2,
                      namePrefix: arrayNamePrefix2,
                      i18nPrefix: arrayI18nPrefix2,
                      index: arrayItemIndex2,
                      // arrayItems: arrayItems2,
                    } = renderProps2;
                    // let localeIdDefaultValue = arrayDefaultValues2?.localeId || '';
                    // if (newInitialValues) {
                    //   localeIdDefaultValue =
                    //     newInitialValues?.[arrayItemIndex2]?.localeId ||
                    //     localeIdDefaultValue;
                    // }
                    return (
                      <>
                        <Grid item xs={12}>
                          <Typography align={'center'}>
                            <Localized
                              dictKey={`${arrayI18nPrefix}.foodOptions.array.itemCount`}
                              translationOptions={{
                                itemIndex: arrayItemIndex2 + 1,
                              }}
                            />
                          </Typography>
                        </Grid>
                        <TextField
                          // TODO: make this field disabled for user input
                          i18nKey={'id'}
                          i18nPath={arrayI18nPrefix2}
                          name={'id'}
                          namePrefix={arrayNamePrefix2}
                          defaultValue={arrayDefaultValues2?.id || ''}
                          // disabled // FIXME: if field is disabled it gets no value from form state
                          // defaultValue={
                          //   pSystem.priceSystemFoodOptions?.foodOptions?.[
                          //     arrayItemIndex2
                          //   ]?.foodId || 'Invalid'
                          // }
                        />
                        <TextField
                          i18nKey={'title'}
                          i18nPath={arrayI18nPrefix2}
                          name={'title'}
                          namePrefix={arrayNamePrefix2}
                          defaultValue={arrayDefaultValues2?.title || ''}
                          // defaultValue={
                          //   pSystem.priceSystemFoodOptions?.foodOptions?.[
                          //     arrayItemIndex2
                          //   ]?.foodId || 'Invalid'
                          // }
                        />
                        <TextField
                          i18nKey={'additionalInformation'}
                          i18nPath={arrayI18nPrefix2}
                          name={'additionalInformation'}
                          namePrefix={arrayNamePrefix2}
                          defaultValue={arrayDefaultValues2?.additionalInformation || ''}
                          // defaultValue={
                          //   pSystem.priceSystemFoodOptions?.foodOptions?.[
                          //     arrayItemIndex2
                          //   ]?.additionalInformation || 'Invalid'
                          // }
                        />
                      </>
                    );
                  }}
                >
                  <FieldErrors i18nKey={i18nKey} name={name} />
                  <FieldAdditionalContainer />
                </ArrayField>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : null} */}

      {hasTimeline ? (
        <ArrayField
          i18nKey={'timeline'}
          i18nPath={arrayI18nPrefix}
          name={'timeline'}
          namePrefix={arrayNamePrefix}
          initialItemValue={
            {
              additionalInformation: 'Addi',
              title: 'Title',
              time: '0:00',
              type: 'default',
            } as DocumentLocalizationNodeData
          }
          initialItems={[]}
          renderElements={(renderProps2: FormArrayFieldRenderProps) => {
            const {
              defaultValues: arrayDefaultValues2,
              namePrefix: arrayNamePrefix2,
              i18nPrefix: arrayI18nPrefix2,
              index: arrayItemIndex2,
              arrayItems: arrayItems2,
            } = renderProps2;

            // let localeIdDefaultValue = arrayDefaultValues2?.localeId || '';
            // if (newInitialValues) {
            //   localeIdDefaultValue =
            //     newInitialValues?.[arrayItemIndex2]?.localeId ||
            //     localeIdDefaultValue;
            // }
            return (
              <>
                <Grid item xs={12}>
                  <Typography align={'center'}>
                    <Localized
                      dictKey={`${arrayI18nPrefix}.timeline.array.itemCount`}
                      translationOptions={{ itemIndex: arrayItemIndex2 + 1 }}
                    />
                  </Typography>
                </Grid>
                <div className='hidden'>
                  <TextField
                    i18nKey={'type'}
                    i18nPath={arrayI18nPrefix2}
                    name={'type'}
                    namePrefix={arrayNamePrefix2}
                    defaultValue={arrayDefaultValues2?.type || 'default'}
                  />
                </div>
                <TextField
                  i18nKey={'time'}
                  i18nPath={arrayI18nPrefix2}
                  name={'time'}
                  namePrefix={arrayNamePrefix2}
                  defaultValue={arrayDefaultValues2?.time || ''}
                />
                <TextField
                  i18nKey={'title'}
                  i18nPath={arrayI18nPrefix2}
                  name={'title'}
                  namePrefix={arrayNamePrefix2}
                  defaultValue={arrayDefaultValues2?.title || ''}
                />
                <TextField
                  i18nKey={'additionalInformation'}
                  i18nPath={arrayI18nPrefix2}
                  name={'additionalInformation'}
                  namePrefix={arrayNamePrefix2}
                  defaultValue={arrayDefaultValues2?.additionalInformation || ''}
                />
              </>
            );
          }}
        >
          <FieldErrors i18nKey={i18nKey} name={name} />
          <FieldAdditionalContainer />
        </ArrayField>
      ) : null}

      {/** // FIXME: if second localization is in place, prefixing goes wrong... */}
      {productProperties
        ?.filter((p) => p !== 'productInformation')
        .map((propName) => {
          let defaultValue2 = arrayDefaultValues?.product?.[propName] || [];
          if (newInitialValues) {
            defaultValue2 = newInitialValues?.product?.[propName] || [];
          }
          return (
            <ArrayField
              key={propName}
              i18nKey={`product.${propName}`}
              i18nPath={arrayI18nPrefix}
              name={`product.${propName}`}
              namePrefix={arrayNamePrefix}
              initialItemValue={
                {
                  additionalInformation: '',
                  title: '',
                  type: 'default',
                } as DocumentLocalizationNodeData
              }
              initialItems={[]}
              renderElements={(renderProps2: FormArrayFieldRenderProps) => {
                const {
                  defaultValues: arrayDefaultValues2,
                  namePrefix: arrayNamePrefix2,
                  i18nPrefix: arrayI18nPrefix2,
                  index: arrayItemIndex2,
                  arrayItems: arrayItems2,
                } = renderProps2;
                console.log(renderProps2,'renderprops product')

                // let localeIdDefaultValue = arrayDefaultValues2?.localeId || '';
                // if (newInitialValues) {
                //   localeIdDefaultValue =
                //     newInitialValues?.[arrayItemIndex2]?.localeId ||
                //     localeIdDefaultValue;
                // }
                return (
                  <>
                    <Grid item xs={12}>
                      <Typography align={'center'}>
                        <Localized
                          dictKey={`${arrayI18nPrefix}.product.${propName}.array.itemCount`}
                          translationOptions={{ itemIndex: arrayItemIndex2 + 1 }}
                        />
                      </Typography>
                    </Grid>
                    <div className='hidden'>
                      <TextField
                        i18nKey={'type'}
                        i18nPath={arrayI18nPrefix2}
                        name={'type'}
                        namePrefix={arrayNamePrefix2}
                        defaultValue={arrayDefaultValues2?.type || 'default'}
                      />
                    </div>
                    <TextField
                      i18nKey={'title'}
                      i18nPath={arrayI18nPrefix2}
                      name={'title'}
                      namePrefix={arrayNamePrefix2}
                      defaultValue={arrayDefaultValues2?.title || ''}
                    />
                    <TextField
                      i18nKey={'additionalInformation'}
                      i18nPath={arrayI18nPrefix2}
                      name={'additionalInformation'}
                      namePrefix={arrayNamePrefix2}
                      defaultValue={arrayDefaultValues2?.additionalInformation || ''}
                    />
                  </>
                );
              }}
            >
              <FieldErrors i18nKey={i18nKey} name={name} />
              <FieldAdditionalContainer />
            </ArrayField>
          );
        }) || null}

      {/* {hasFoodOptions ? ( // This is deprecated, TODO: delete
        <>
          <ArrayField
            disabled
            // key={propName}
            i18nKey={'food'}
            i18nPath={arrayI18nPrefix}
            name={'food'}
            namePrefix={arrayNamePrefix}
            initialItemValue={
              {
                additionalInformation: 'a',
                title: 'b',
                type: 'default',
              } as DocumentLocalizationNodeData
            }
            initialItems={
              newInitialValues.food || []
              // [
              // ...foodOptions.map((option) => ({
              //   id: option.foodId,
              //   title: 'Food',
              //   additionalInformation: 'additionalInformation',
              // })),
              // ]
            }
            renderElements={(renderProps2: FormArrayFieldRenderProps) => {
              const {
                defaultValues: arrayDefaultValues2,
                namePrefix: arrayNamePrefix2,
                i18nPrefix: arrayI18nPrefix2,
                index: arrayItemIndex2,
                arrayItems: arrayItems2,
              } = renderProps2;
              // let localeIdDefaultValue = arrayDefaultValues2?.localeId || '';
              // if (newInitialValues) {
              //   localeIdDefaultValue =
              //     newInitialValues?.[arrayItemIndex2]?.localeId ||
              //     localeIdDefaultValue;
              // }
              return (
                <>
                  <Grid item xs={12}>
                    <Typography align={'center'}>
                      <Localized
                        dictKey={`${arrayI18nPrefix}.food.array.itemCount`}
                        translationOptions={{ itemIndex: arrayItemIndex2 + 1 }}
                      />
                    </Typography>
                  </Grid>
                  <TextField
                    i18nKey={'title'}
                    i18nPath={arrayI18nPrefix2}
                    name={'title'}
                    namePrefix={arrayNamePrefix2}
                    defaultValue={arrayDefaultValues2?.title || ''}
                  />
                  <TextField
                    i18nKey={'additionalInformation'}
                    i18nPath={arrayI18nPrefix2}
                    name={'additionalInformation'}
                    namePrefix={arrayNamePrefix2}
                    defaultValue={arrayDefaultValues2?.additionalInformation || ''}
                  />
                </>
              );
            }}
          >
            <FieldErrors i18nKey={i18nKey} name={name} />
            <FieldAdditionalContainer />
          </ArrayField>
        </>
      ) : null} */}

      {children}
    </>
  );
};
