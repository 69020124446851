import * as React from 'react';
import { Router } from '@reach/router';
import { FirebaseApp } from 'firebase/app';
import markerSDK from '@marker.io/browser';
// import { FirebaseAppProvider } from 'reactfire';

import { useFirebaseStaticQueryConfig } from 'src/app/hooks';

import { AppContextProvider, LocalizationContextProvider } from 'src/app/context';

import { Route } from 'src/app/components';

// Wrapper for dynamic import
const FBRFClientSideOnlyLazyLoaded = React.lazy(
  () => import('src/app.consumer/components/AppContext/FirebaseContextProviderImport'),
);

// Firebase default imported modules => remove as many as possible
const FBClientSideOnlyLazyLoaded = React.lazy(
  () => import('src/app.consumer/components/AppContext/FirebaseDefaultImportedModules'),
);

const App = ({ children }: { children: React.ReactNode }) => {
  const isSSR = typeof window === 'undefined';
  const firebaseConfig = useFirebaseStaticQueryConfig();
  const [firebaseApp, setFirebaseApp] = React.useState<FirebaseApp | null>(null);
  const [isMarkerIoLoaded, setIsMarkerIoLoaded] = React.useState<boolean>(false);

  // FIXME: marker.io - use sourced env config param here
  // FIXME: marker.io -  should only be used on dev/test/local => not prod without extra handling
  React.useEffect(() => {
    const loadMarkerSDK = async () => {
      console.log('awaiting markerSDK');
      await markerSDK.loadWidget({
        destination: '621783449e54564b7a02335d',
      });
    };
    if (!isMarkerIoLoaded) {
      setIsMarkerIoLoaded(true);
      loadMarkerSDK();
    }
  }, [isMarkerIoLoaded]);

  return (
    <LocalizationContextProvider importLocaleLocalizations>
      {!isSSR ? (
        <React.Suspense fallback={<div />}>
          <FBRFClientSideOnlyLazyLoaded firebaseConfig={firebaseConfig}>
            <FBClientSideOnlyLazyLoaded
              onFBLoadedCallback={(app: FirebaseApp) => setFirebaseApp(app)}
              firebaseApp={firebaseApp}
            >
              <>
                {firebaseApp ? (
                  <AppContextProvider firebaseApp={firebaseApp}>
                    {children}
                  </AppContextProvider>
                ) : null}
              </>
            </FBClientSideOnlyLazyLoaded>
          </FBRFClientSideOnlyLazyLoaded>
        </React.Suspense>
      ) : (
        <>
          {firebaseApp ? (
            <AppContextProvider firebaseApp={firebaseApp}>{children}</AppContextProvider>
          ) : null}
        </>
      )}
    </LocalizationContextProvider>
  );
};

/** Provides AppContext & FirebaseContextProvider & RootRouter for LocalizationContext (optional here I think) */
export const AppContext = (props: { children: React.ReactNode }) => {
  const { children } = props;

  // NOTE: we could source certain admin/org values directly from db and bake them into the apps (same in client app)
  // NOTE: wrapped with reach router for being able to provide useLocation-hook to all components
  return (
    <Router basepath={'/'}>
      <Route path={'/*'} component={App} passProps={{ children }} />
    </Router>
  );
};
