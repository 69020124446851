import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MAccordion, {
  AccordionProps as MAccordionProps,
} from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';
import { Grid, Paper } from 'src/app/components';
import clsx from 'clsx';

const StyledAccordion = withStyles({
  // root: {
  //   border: '1px solid rgba(0, 0, 0, .125)',
  //   boxShadow: 'none',
  //   borderRadius: '30px 0px 30px 0',
  //   '&:not(:last-child)': {
  //     borderBottom: 0,
  //   },
  //   '&:before': {
  //     display: 'none',
  //   },
  //   '&$expanded': {
  //     margin: 'auto',
  //   },
  // },
  // expanded: {},
})(MAccordion);

export interface AccordionProps extends CombinedBaseComponentProps<MAccordionProps> {
  nonVisuallyDisabled?: boolean; // dumb hack because of mui css class issues => solve
  summary?: React.ReactNode | React.ReactNode[] | null;
  expandIcon?: React.ReactNode;
  ariaControls?: string;
  id?: string;
  hideOnShrink?: boolean;
  hideSummary?: boolean;
}
// TODO: [0001] handle default expanded/collapsed functionality
/**
 * Button - Simple button
 * @param { ButtonProps } props
 * @version 0.0.0
 */
const Accordion = (props: AccordionProps) => {
  const {
    ariaControls,
    children,
    className,
    classNameSummary,
    expandIcon,
    hideOnShrink,
    hideSummary,
    id,
    nonVisuallyDisabled,
    summary,
    onClick,
    ...passProps
  } = props;
  const styles = useStyles();
  // const [expanded, setExpanded] = React.useState<string | false>(false);

  // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  return (
    <StyledAccordion
      expanded={nonVisuallyDisabled ? true : undefined}
      className={clsx({
        [className || '']: !!className,
        [styles.muiAccordion__root]: true,
        // [styles.red]: true,
      })}
      {...passProps}
      // classes={{ root: styles.paper_theme }}
    >
      {!hideSummary ? (
        <AccordionSummary
          className={classNameSummary}
          // expandIcon={expandIcon || <ExpandMoreIcon />}
          expandIcon={expandIcon}
          aria-controls={ariaControls || `accordion-content-${id}`}
          id={id || 'accordion-header'}
          // style={{ padding: '0', margin: '0' }}
          onClick={onClick}
        >
          {summary}
        </AccordionSummary>
      ) : null}
      <AccordionDetails className='!p-0'>
        {children}
        {/* {children} */}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const WAPAccordion = (props: CombinedAppProps<AccordionProps>) =>
  withoutAppProps<CombinedAppProps<AccordionProps>, AccordionProps>(Accordion, props);

export { Accordion, WAPAccordion };
