import * as React from 'react';
import { Localized } from 'src/app/components';

export interface AdminScreenTitleLocalizedProps {
  nameSpace: string;
  screenName: string;
  // orgDisplayName?: string; // optional for interpolation
}

/**
 * AdminScreenTitleLocalized
 * @param { AdminScreenTitleLocalizedProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const AdminScreenTitleLocalized = ({ nameSpace, screenName }: AdminScreenTitleLocalizedProps) => {
  return screenName ? <Localized dictKey={`${nameSpace}:${screenName}.title`} /> : null;
};

export { AdminScreenTitleLocalized };
