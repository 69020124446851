import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import { Glass, Grid, ItemCard, Typography } from 'src/app/components';
import { BaseComponentProps, BaseProduct, SourcedGQLProduct } from 'src/app/types';
import { useLocalizationContext } from 'src/app/hooks';
import { ProductCategoryType } from 'src/app/constants';
// import Carousell from './Carousell';
// import { ProductReviews } from '../ProductReviews'; // TODO
import { useStyles } from 'src/app/context';

// NOTE: now prifixed with 'itemCard__' in itemCardStyles
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       // display: 'flex',
//       backgroundColor: '#ffffff00',
//       borderRadius: '16px',
//       width: '100%',
//     },
//     title: {
//       position: 'absolute',
//       top: '350px',
//       color: theme.palette.common.white,
//       padding: '16px 32px',
//       minWidth: '33%',
//       // maxWidth: '50%',
//       maxWidth: '1100px',
//       // width: '90%',
//     },
//     titleSM: {
//       top: '200px',
//       maxWidth: '80%',
//     },
//     media: {
//       borderRadius: '16px 16px 0 0',
//       height: '600px',
//       width: '1230px',
//     },
//     mediaSM: {
//       height: '500px',
//       objectPosition: 'center',
//     },
//   }),
// );

export interface ProductCardProps<T extends ProductCategoryType>
  extends BaseComponentProps {
  extraProps?: React.ReactNode;
  onAddWishlistProduct: () => void;
  product: SourcedGQLProduct<T>; // TODO:  | BaseProduct<T> - BaseProduct as fallback technique for direct api query
  reviewAnchorId: string;
}

/**
 * ActivityProductView
 * @param { ProductCardProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const ProductCard = <T extends ProductCategoryType>(props: ProductCardProps<T>) => {
  const { children, extraProps, onAddWishlistProduct, product, reviewAnchorId } = props;
  const { productLocales } = product;
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const { language } = useLocalizationContext();
  const [selectedImageIdx, setSelectedImageIdx] = React.useState<number | null>(null);
  // const cn = useStyles();

  const isSourcedProduct = Boolean(product?.imageFileTitle || product.imageFiles?.length); // TODO:
  const imageCount = isSourcedProduct
    ? product.imageFiles.length
    : product.productImages.length;

  // TODO: blend in addition appBar with product nav points
  // console.log('carousellItems: ', carousellItems);

  // const onWishlistButtonClick = () => {
  //   console.log('wishlist');
  // };

  console.log('activityViewProps: ', props);

  let locales = productLocales?.find((locale) => locale.localeId === language);
  if (!locales) {
    locales = productLocales?.find((locale) => locale.localeId === 'en') || null;
  }

  if (!locales) {
    return null;
  }

  // const onNextImageClick = () => {
  //   if (!selectedImageIdx) {
  //     setSelectedImageIdx(imageCount > 0 ? 1 : 0);
  //   } else {
  //     setSelectedImageIdx(selectedImageIdx + 1 < imageCount ? selectedImageIdx + 1 : 0);
  //   }
  // };
  // const onPrevImageClick = () => {
  //   if (!selectedImageIdx) {
  //     setSelectedImageIdx(imageCount - 1);
  //   } else {
  //     setSelectedImageIdx(selectedImageIdx - 1 < 0 ? imageCount - 1 : selectedImageIdx - 1);
  //   }
  // };
  // const onSelectImageIndexClick = (index: number) => {
  //   setSelectedImageIdx(index || null);
  // };

  console.log('selectedImageIdx: ', selectedImageIdx);
  console.log('product.productImages: ', product.productImages);

  return (
    <ItemCard
      title={locales?.productTitle?.toUpperCase() ?? ''}
      description={locales?.productTeaser?.toUpperCase() ?? ''}
      {...props}
    >
      {children}
    </ItemCard>
  );

  // return (
  //   <Grid container>
  //     <Grid item xs={12}>
  //       <Glass>
  //         <Card className={cn.root}>
  //           <CardContent style={{ padding: '0px' }}>
  //             {!isSourcedProduct ? (
  //               <CardMedia
  //                 className={clsx(cn.media, !isWiderMD ? cn.mediaSM : {})}
  //                 // image={productTitleImage || '/backgroundViva.webp'}
  //                 image={
  //                   selectedImageIdx === null
  //                     ? product.productTitleImage
  //                     : product.productImages[selectedImageIdx]
  //                 }
  //                 title='ActivityImageTitle'
  //               />
  //             ) : null}
  //             {isSourcedProduct ? (
  //               <GatsbyImage
  //                 key={selectedImageIdx} // for blur reload effect
  //                 className={clsx(cn.media, !isWiderMD ? cn.mediaSM : {})}
  //                 fluid={
  //                   selectedImageIdx === null
  //                     ? product.imageFileTitle.childImageSharp.fluid
  //                     : product.imageFiles[selectedImageIdx].childImageSharp.fluid
  //                 }
  //                 alt={'Activity Title Image'}
  //               />
  //             ) : null}
  //             <ProductCardTopArea<T>
  //               onWishlistButtonClick={onAddWishlistProduct}
  //               product={product}
  //               reviewAnchorId={reviewAnchorId}
  //             />
  //             <ProductImageSelectWidget<T>
  //               onNextImageClick={onNextImageClick}
  //               onPrevImageClick={onPrevImageClick}
  //               onSelectImageIndexClick={onSelectImageIndexClick}
  //               product={product}
  //             />
  //             {/* <Glass
  //               blurAmount={2}
  //               opacity={0.2}
  //               borderRadius={'0 16px 16px 0'}
  //               className={clsx(cn.title, !isWiderMD ? cn.titleSM : {})}
  //             >
  //               <Typography
  //                 paragraph
  //                 variant={isWiderMD ? 'h2' : 'h4'}
  //                 renderAs='h1'
  //                 style={{ textShadow: '0px 1px 2px #00000088' }}
  //               >
  //                 {locales?.productTitle?.toUpperCase() ?? ''}
  //               </Typography>
  //               <Typography
  //                 variant={isWiderMD ? 'h5' : 'h6'}
  //                 renderAs='p'
  //                 style={{ textShadow: '0px 1px 2px #00000088' }}
  //               >
  //                 {locales?.productTeaser?.toUpperCase() ?? {}}
  //               </Typography>
  //             </Glass> */}
  //             <Grid container style={{ padding: '0px 32px 32px 32px' }}>
  //               <Grid item xs={12}>
  //                 {children}
  //               </Grid>
  //             </Grid>
  //           </CardContent>
  //         </Card>
  //       </Glass>
  //     </Grid>
  //     {extraProps ? (
  //       <Grid item xs={12}>
  //         {extraProps}
  //       </Grid>
  //     ) : null}
  //   </Grid>
  // );
};

export { ProductCard };
