import * as React from 'react';
import { Components } from '@mdx-js/react';
import { PageProps } from 'gatsby';

/** CORE */
import { BaseComponentProps } from 'src/app/types';
import {
  // WAPAppBar,
  // AppBarProps,
  // WAPAccordion,
  // AccordionProps,
  // WAPBreadcrumbs,
  // BreadcrumbsProps,
  WAPButtonGroup,
  ButtonGroupProps,
  // Card as WAPCard,
  // CardProps,
  WAPContainer,
  ContainerProps,
  // WAPDropDown,
  // DropDownProps,
  WAPGrid,
  GridProps,
  WAPHero,
  HeroProps,
  // WAPImage,
  // GatsbyImageProps,
  // WAPKeyboardDayTimePicker,
  // KeyboardDayTimePickerProps,
  WAPLanguageSelection,
  LanguageSelectionProps,
  WAPLink,
  LinkProps,
  WAPLoginButton,
  LoginButtonProps,
  WAPLoginContainer,
  LoginContainerProps,
  WAPLoginDropDown,
  LoginDropDownProps,
  WAPPaper,
  PaperProps,
  ProductFetchContainer, // ProductView is not passing Props and needs url params
  ProductFetchContainerProps,
  // ScrollToTop as WAPScrollToTop,
  // ScrollToTopProps,
  WAPSearchBar,
  SearchBarProps,
  WAPSearchResults,
  SearchResultsProps,
  SignupBox,
  SignupBoxProps,
  WAPTabs,
  TabsProps,
  // WAPTextInputField,
  // TextInputFieldProps,
  WAPToolbar,
  ToolbarProps,
  WAPTypography,
  TypographyProps,
} from 'src/app/components';
// import { BaseComponentProps, AppBar, AppBarProps } from 'src/app/collection.types';

import {
  // AppFooter,
  // AppFooterProps,
  // AppNavBar,
  // AppNavBarProps,
  WAPCurrencySelection,
  CurrencySelectionProps,
  CountryProductsContainer,
  CountryProductsContainerProps,
  // HistoryContainer,
  // HistoryContainerProps,
  // ShoppingCartContainer,
} from 'src/app.consumer/components';

/** SYSTEM */
// import { SystemAdminAppRouter, SystemAdminProps } from 'src/app.system/components';

import // OrganisationFooter,
// OrganisationConfirmation,
// OrganisationConfirmationProps,
// WAPOrganisationLoginContainer as OrganisationLoginContainer, // FIXME: same procedure for other DOM violating comps
// OrganisationLoginContainerProps,
// OrganisationMemberInvitation,
// OrganisationMemberInvitationProps,
// OrganisationSignup,
// OrganisationSignupProps,
'src/app.organisation/components';

// TODO: SystemLoginContainer => we take OrganisationLoginContainer as SystemLoginContainer for quick and dirty implementation

/** ETC */

type MyComponentType =
  | 'AppBar'
  | 'Avatar'
  | 'Background'
  | 'Box'
  | 'Button'
  | 'ButtonGroup'
  | 'DropDown'
  | 'KeyboardDayTimePicker'
  | 'Localized'
  | 'Toolbar';

// type MyComponents = {
//   [key in MyComponentType]?:
//     | React.ComponentType<AppProps & BaseComponentProps>
//     | ((props: any) => JSX.Element);
// };

type MyComponents = {
  [key in MyComponentType]?: (
    props: PageProps & BaseComponentProps,
  ) => React.ReactElement<PageProps & BaseComponentProps>;
};

// TODO: try dynamic imports from 'src/components'
/**
 * returns a React component to MDX tag mapping config
 * - injects global props then merges shallow with props injected from inside MDX declaration
 * - to render with MDXProvider
 * */
// TODO: optimize via useMemo or useCallback and check if most of the components needs prop injection at all?
// - mostly for better rendering performance while in dev mode

// TODO: create 2(3?) different configs for each app
export const getMDXComponentsConfig = <T extends PageProps>(
  globalProps: T,
): Components & MyComponents => ({
  // SPECIAL COMPOSED ELEMENTS
  // xAppBar: (p: AppNavBarProps) => <AppNavBar {...{ ...globalProps, ...p }} />,
  // xFooter: (p: any) => <AppFooter {...{ ...globalProps, ...p }} />,
  // xOrganisationFooter: (p: any) => <OrganisationFooter {...{ ...globalProps, ...p }} />,
  // APP ELEMENTS
  a: (p: LinkProps) => <WAPLink {...{ ...globalProps, ...p }} />,
  // Accordion: (p: AccordionProps) => <WAPAccordion {...{ ...globalProps, ...p }} />,
  // AppBar: (p: AppBarProps) => <WAPAppBar {...{ ...globalProps, ...p }} />,
  // Button: (p: ButtonProps) => <WAPButton {...{ ...globalProps, ...p }} />,
  ButtonGroup: (p: ButtonGroupProps) => <WAPButtonGroup {...{ ...globalProps, ...p }} />,
  // Breadcrumbs: (p: BreadcrumbsProps) => <WAPBreadcrumbs {...{ ...globalProps, ...p }} />,
  // Card: (p: CardProps) => <WAPCard {...{ ...globalProps, ...p }} />,
  Container: (p: ContainerProps) => <WAPContainer {...{ ...globalProps, ...p }} />,
  CountryProducts: (p: CountryProductsContainerProps) => (
    <CountryProductsContainer {...{ ...globalProps, ...p }} />
  ),
  CurrencySelection: (p: CurrencySelectionProps) => (
    <WAPCurrencySelection {...{ ...globalProps, ...p }} />
  ),
  // Dialog: (p: DialogProps) => <WAPDialog {...{ ...globalProps, ...p }} />,
  // DropDown: (p: DropDownProps) => <WAPDropDown {...{ ...globalProps, ...p }} />,
  // Footer: (p: FooterProps) => <WAPFooter {...{ ...globalProps, ...p }} />,
  // Form: (p: FormProps<any>) => <WAPForm {...{ ...globalProps, ...p }} />,
  // Glass: (p: GlassProps) => <WAPGlass {...{ ...globalProps, ...p }} />,
  Grid: (p: GridProps) => <WAPGrid {...{ ...globalProps, ...p }} />,
  Hero: (p: HeroProps) => <WAPHero {...{ ...globalProps, ...p }} />,
  // Image: (p: GatsbyImageProps) => <WAPImage {...{ ...globalProps, ...p }} />,
  // KeyboardDayTimePicker: (p: KeyboardDayTimePickerProps) => (
  //   <WAPKeyboardDayTimePicker {...{ ...globalProps, ...p }} />
  // ),
  LanguageSelection: (p: LanguageSelectionProps) => (
    <WAPLanguageSelection {...{ ...globalProps, ...p }} />
  ),
  Link: (p: LinkProps) => <WAPLink {...{ ...globalProps, ...p }} />,
  LoginButton: (p: LoginButtonProps) => <WAPLoginButton {...{ ...globalProps, ...p }} />,
  LoginContainer: (p: LoginContainerProps) => (
    <WAPLoginContainer {...{ ...globalProps, ...p }} />
  ),
  LoginDropDown: (p: LoginDropDownProps) => (
    <WAPLoginDropDown {...{ ...globalProps, ...p }} />
  ),
  Paper: (p: PaperProps) => <WAPPaper {...{ ...globalProps, ...p }} />,
  ProductView: (p: ProductFetchContainerProps) => (
    <ProductFetchContainer {...{ ...globalProps, ...p }} />
  ),
  // ScrollToTop: (p: ScrollToTopProps) => <WAPScrollToTop {...{ ...globalProps, ...p }} />,
  SearchResults: (p: SearchResultsProps) => (
    <WAPSearchResults {...{ ...globalProps, ...p }} />
  ),
  SearchBar: (p: SearchBarProps) => <WAPSearchBar {...{ ...globalProps, ...p }} />,
  SignupBox: (p: SignupBoxProps) => <SignupBox {...{ ...globalProps, ...p }} />,
  Tabs: (p: TabsProps) => <WAPTabs {...{ ...globalProps, ...p }} />,
  // TextInputField: (p: TextInputFieldProps) => (
  //   <WAPTextInputField {...{ ...globalProps, ...p }} />
  // ),
  Toolbar: (p: ToolbarProps) => <WAPToolbar {...{ ...globalProps, ...p }} />,
  Typography: (p: TypographyProps) => <WAPTypography {...{ ...globalProps, ...p }} />,
  // SHOP
  // History: (p: HistoryContainerProps) => (
  //   <HistoryContainer {...{ ...globalProps, ...p }} />
  // ),
  // ORGANISATION
  // OrganisationConfirmation: (p: OrganisationConfirmationProps) => (
  //   <OrganisationConfirmation {...{ ...globalProps, ...p }} />
  // ),
  // OrganisationLoginContainer: (p: OrganisationLoginContainerProps) => (
  //   <OrganisationLoginContainer {...{ ...globalProps, ...p }} />
  // ),
  // OrganisationMemberInvitation: (p: OrganisationMemberInvitationProps) => (
  //   <OrganisationMemberInvitation {...{ ...globalProps, ...p }} />
  // ),
  // OrganisationSignup: (p: OrganisationSignupProps) => (
  //   <OrganisationSignup {...{ ...globalProps, ...p }} />
  // ),
  // SYSTEM
  // SystemAdmin: (p: SystemAdminProps) => (
  //   <SystemAdminAppRouter {...{ ...globalProps, ...p }} />
  // ),
  // SystemLogin: (p: OrganisationLoginContainerProps) => (
  //   <OrganisationLoginContainer {...{ ...globalProps, ...p }} />
  // ),
});
