import * as React from 'react';

import {
  ButtonUpload,
  Grid,
  // LoadingIndicator,
  LocalizedLink,
  Typography,
} from 'src/app/components';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useAppContext } from 'src/app/hooks';
import { DialogType, ImportDataMode } from 'src/app/constants';
import {
  BasePickupLocation,
  ParticipantsDialogParams,
  ParticipantImportData,
  PriceSystem,
} from 'src/app/types';

import { uploadParticipantTableSchema } from './uploadParticipantTable.schema';

interface UploadParticipantListProps {
  onImportData: (data: ParticipantImportData[], mode: ImportDataMode) => void;
  pickupLocations: BasePickupLocation[];
  priceSystem: PriceSystem;
}

export const UploadParticipantList = ({
  onImportData,
  priceSystem,
  pickupLocations,
}: UploadParticipantListProps) => {
  const { openDialog } = useAppContext();

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file = e.target.files[0] || null; // TODO: no multi file support atm
      if (!file) {
        return;
      }
      // const fileData = await file;

      openDialog<ParticipantsDialogParams>(DialogType.PARTICIPANT_LIST, {
        file,
        schema: uploadParticipantTableSchema,
        onImportData,
        pickupLocations,
        priceSystem,
      });
    }
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography center variant={'caption'}>
          You can alternatively upload a list (
          <LocalizedLink href={'/help/customer/upload-participants'}>
            {'Information'}
          </LocalizedLink>
          )
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ButtonUpload
          // xlsx / xls / csv
          // accept={
          //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv'
          // }
          accept={
            'application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          color={'secondary'}
          fullWidth
          onChange={onSelectFile}
          variant={'contained'}
          startIcon={<CloudUploadIcon />}
          title={'Upload Excel/CSV participant list'}
        />
      </Grid>
    </Grid>
  );
};
