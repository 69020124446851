import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/ReportProblemOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
  ButtonTw,
  Localized,
  LocationMapWidget,
  ProductTimeline,
  YoutubeEmbed,
} from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';
import { useLocalizationContext } from 'src/app/hooks';

import { useStyles, useAppContext } from 'src/app/context';
import {
  getMediaLocalizations,
  getProductLocalizations,
  getTimelineForLanguage,
  getBaseDocumentLocales,
} from 'src/lib/document';
import { BookingSystemType } from 'src/app/constants';
import {
  GeneralInformationContainer,
  NodeContainer,
  ProductStaticFeatures,
  FoodOptionsInformation,
} from './Components';
import { ProductPriceContainer, onOpenAddToCartDialog } from './ProductPriceContainer';

export interface ProductContent2Props {
  product: SourcedGQLProduct;
}

export const ProductContent2 = ({ product }: ProductContent2Props) => {
  const { location, locations } = product;
  const { isAppDebug, payBoxVisible } = useAppContext();
  const { language } = useLocalizationContext();

  const { openDialog, currencyId } = useAppContext();
  // const cities = useSourcedCities();

  const timeline = getTimelineForLanguage(
    product?.localizations || [],
    language,
    isAppDebug,
  );

  const {
    covidSafetyMeasures,
    covidTravelerRequirements,
    importantInformationBeforeYouGo,
    importantInformationNotAllowed,
    importantInformationWhatToBring,
    notSuitableFor,
    productIncludes,
    productExcludes,
    productFeatures,
    productInformation,
  } = getProductLocalizations(product?.localizations || [], language, isAppDebug);

  const productLocales = getBaseDocumentLocales(product, language);

  const { youtube } = getMediaLocalizations(
    product?.localizations || [],
    language,
    isAppDebug,
  );

  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;

  const [isDropdownInfo, setDropdownInfo] = React.useState(false);
  const toggleDropdownInfoClass = () => {
    setDropdownInfo(!isDropdownInfo);
  };

  const [isDropdownActivity, setDropdownActivity] = React.useState(false);
  const toggleDropdownActivityClass = () => {
    setDropdownActivity(!isDropdownActivity);
  };

  const [isDropdownCovid, setDropdownCovid] = React.useState(false);
  const toggleDropdownCovidClass = () => {
    setDropdownCovid(!isDropdownCovid);
  };

  return (
    <>
      {/** // TODO: limit because of special flags? */}
      {/** PRODUCT PRICE (<= MD DEVICES) */}
      {/** // NOTE: this is visible on mobile */}
      {product.id ? (
        <div
          className={`transition-opacity duration-200 fixed bottom-0 left-0 w-full shadow-box-light dark:shadow-box-dark md:col-span-12 md:top-0 md:bottom-auto ${
            payBoxVisible ? 'opacity-0 h-0 z-[-1]' : 'opacity-100 z-20'
          }`}
        >
          <div className='bg-neutral-800 p-4 text-neutral-50 rounded-t-xl md:rounded-none'>
            <div className='xl:container'>
              <div className='md:grid md:grid-cols-12 gap-4 items-center'>
                <div className='hidden md:block md:col-span-8 lg:col-span-9'>
                  <h2 className='text-h2 font-bold'>{productLocales.name}</h2>
                </div>
                <div className='md:col-span-4 lg:col-span-3'>
                  <ButtonTw
                    variant={'accent'}
                    size={'lg'}
                    rounded={'rounded'}
                    className={'w-full'}
                    onClick={() =>
                      onOpenAddToCartDialog(
                        BookingSystemType.PRIVATE,
                        openDialog,
                        product,
                      )
                    }
                  >
                    Book Now
                  </ButtonTw>
                </div>
              </div>
              {/* <ProductPriceContainer className={'bg-transparent'} product={product} /> */}
            </div>
          </div>
          <div className='hidden py-3 px-4 bg-neutral-50 text-p3 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-50 md:block'>
            <div className='flex gap-14 xl:container'>
              <a
                href='#overview'
                className='hover:font-semibold active:font-semibold target:font-semibold'
              >
                Overview
              </a>
              {timeline?.length ? (
                <a
                  href='#activity'
                  className='hover:font-semibold active:font-semibold target:font-semibold'
                >
                  Activity map
                </a>
              ) : null}
              <a
                href='#availability'
                className='hover:font-semibold active:font-semibold target:font-semibold'
              >
                Availability & Meeting point
              </a>
            </div>
          </div>
        </div>
      ) : null}

      {productFeatures?.length ? (
        <NodeContainer
          iconList={
            <span className='text-accent-500 absolute left-0 top-0'>
              <Check />
            </span>
          }
          iconTitle={
            <span className='text-accent-500 mr-2 align-text-bottom'>
              <WhatshotOutlinedIcon />
            </span>
          }
          mdSize={4}
          i18nPrefix={i18nPrefix}
          i18nPath={'product-features'}
          localizedNodes={productFeatures}
          elevation={2}
          className={
            'text-neutral-900 border-t border-neutral-400 border-opacity-30 pt-4 order-5 dark:border-primary-500 dark:border-opacity-100 dark:text-neutral-50 md:col-span-4 md:px-6 md:border-none md:order-none md:mt-10 md:mb-6'
          }
          titleClass={
            'pb-3 text-p2 relative font-medium md:font-semibold md:text-p1 md:pb-8 md:text-center'
          }
          hasDropdown
        />
      ) : null}

      {productIncludes?.length ? (
        <NodeContainer
          iconList={
            <span className='text-accent-500 absolute left-0 top-0'>
              <Check />
            </span>
          }
          iconTitle={
            <span className='text-accent-500 mr-2 align-text-bottom'>
              <SentimentVerySatisfiedOutlinedIcon />
            </span>
          }
          mdSize={4}
          i18nPrefix={i18nPrefix}
          i18nPath={'product-includes'}
          localizedNodes={productIncludes}
          elevation={2}
          className={
            'order-7 text-neutral-900 border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 dark:text-neutral-50 md:col-span-4 md:px-6 md:border-none md:order-none md:mt-10 md:mb-6'
          }
          titleClass={
            'pb-3 text-p2 font-medium relative md:font-semibold md:text-p1 md:pb-8 md:text-center'
          }
          hasDropdown
        >
          {/* {product?.foodOptions?.featureEnabled ? (
            <FoodOptionsInformation
              i18nPrefix={i18nPrefix}
              priceSystemFoodOptions={product.foodOptions} // TODO: handle multiple pricesystems
            />
          ) : undefined} */}
        </NodeContainer>
      ) : null}

      <NodeContainer
        mdSize={4}
        iconTitle={
          <span className='hidden text-accent-500 mr-2 align-text-bottom md:inline-block'>
            <ThumbUpAltOutlinedIcon />
          </span>
        }
        i18nPrefix={i18nPrefix}
        i18nPath={'product-information'}
        localizedNodes={productInformation}
        elevation={2}
        className={
          'order-3 text-neutral-900 pt-7 dark:text-neutral-50 md:col-span-4 md:px-6 md:border-none md:order-none md:pt-4 md:mt-10 md:mb-6'
        }
        titleClass={
          'pb-3 text-p1 font-bold relative md:font-semibold md:text-p1 md:pb-8 md:text-center'
        }
      >
        <ProductStaticFeatures product={product} />
      </NodeContainer>


      {youtube?.length
        ? youtube.map(({ embedId }) => {
            return embedId ? (
              <section className='hidden py-9 md:py-8 md:col-span-12 md:block' key={embedId}>
                <div className='w-full'>
                  <YoutubeEmbed
                    embedId={embedId}
                    title={
                      <Localized dictKey={`${i18nPrefix}.product-features.title`} />
                    }
                  />
                </div>
              </section>
            ) : null;
          })
        : null}

      <section className='order-8 py-1 md:py-8 md:col-span-12 md:order-none'>
        <div className='border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 md:border-none md:pt-0'>
          <div className='text-neutral-900 dark:text-neutral-50'>
            <h2 className='font-medium pb-3 text-p2 relative md:pb-5 md:font-bold md:text-h2' onClick={toggleDropdownInfoClass}>
              <span className='text-accent-500 mr-2 align-text-bottom md:hidden'>
                <Warning />
              </span>
              <Localized dictKey={`${i18nPrefix}.important-information.title`} />
              <span className={`absolute right-0 top-[2px] md:hidden ${isDropdownInfo ? 'rotate-180' : '' }`}>
                <ArrowDropDown />
              </span>
            </h2>
            <div className={`mb-6 md:mb-0 ${isDropdownInfo ? 'block' : 'hidden md:block' }`}>
              <p className='text-p2'>
                <Localized dictKey={`${i18nPrefix}.important-information.description`} />
              </p>

              <div className='flex flex-wrap'>
                {importantInformationWhatToBring?.length ? (
                  <NodeContainer
                    // mdSize={12}
                    iconList={
                      <span className='text-accent-500 absolute left-0 top-0'>
                        <Check />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'important-information.prerequisites'}
                    localizedNodes={importantInformationWhatToBring}
                    elevation={2}
                    isGlassWrapped={false}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2 lg:w-1/4 lg:flex-1'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}

                {productExcludes?.length ? (
                  <NodeContainer
                    // mdSize={12}
                    iconList={
                      <span className='text-danger-500 absolute left-0 top-0'>
                        <CloseIcon />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'product-excludes'}
                    localizedNodes={productExcludes}
                    elevation={2}
                    isGlassWrapped={false}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2 lg:w-1/4 lg:flex-1'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}

                {importantInformationNotAllowed?.length ? (
                  <NodeContainer
                    // mdSize={12}
                    iconList={
                      <span className='text-danger-500 absolute left-0 top-0'>
                        <CloseIcon />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'important-information.not-allowed'}
                    localizedNodes={importantInformationNotAllowed}
                    elevation={2}
                    isGlassWrapped={false}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2 lg:w-1/4 lg:flex-1'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}

                {notSuitableFor?.length ? (
                  <NodeContainer
                    // mdSize={12}
                    iconList={
                      <span className='text-danger-500 absolute left-0 top-0'>
                        <CloseIcon />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'important-information.not-suitable'}
                    localizedNodes={notSuitableFor}
                    elevation={2}
                    isGlassWrapped={false}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2 lg:w-1/4 lg:flex-1'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}
              </div>

              {importantInformationBeforeYouGo?.length ? (
                <NodeContainer
                  iconList={
                    <span className='absolute left-0 top-0 scale-50'>
                      <FiberManualRecordIcon fontSize='small' />
                    </span>
                  }
                  // mdSize={12}
                  iconTitle={
                    <span className='mr-2 align-text-bottom'>
                      <InfoOutlinedIcon />
                    </span>
                  }
                  i18nPrefix={i18nPrefix}
                  i18nPath={'important-information.before-go'}
                  localizedNodes={importantInformationBeforeYouGo}
                  elevation={2}
                  isGlassWrapped={false}
                  className={'text-neutral-900 pt-6 dark:text-neutral-50'}
                  titleClass={'pb-2 text-p2 md:text-h4 md:font-medium md:pb-5'}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>

      {timeline?.length ? (
        <section
          id='activity'
          className='py-1 order-4 md:order-none md:py-8 md:col-span-12'
          style={{ scrollMarginTop: '130px' }}
        >
          <div className='border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 md:border-none md:pt-0'>
            <div className='text-neutral-900 dark:text-neutral-50'>
              <h2 className='font-medium pb-3 text-p2 relative md:pb-5 md:font-bold md:text-h2' onClick={toggleDropdownActivityClass}>
                <span className='text-accent-500 mr-2 align-text-bottom md:hidden'>
                  <RoomOutlinedIcon />
                </span>
                <Localized dictKey={`${i18nPrefix}.map.title`} />
                <span className={`absolute right-0 top-[2px] md:hidden ${isDropdownActivity ? 'rotate-180' : '' }`}>
                  <ArrowDropDown />
                </span>
              </h2>
              <div className={`mb-6 md:mb-0 ${isDropdownActivity ? 'block' : 'hidden md:block' }`}>
                {/* <p className='text-p2'>
                  <Localized dictKey={`${i18nPrefix}.map.description`} />
                </p> */}
                <LocationMapWidget
                  location={location}
                  locations={locations}
                  mapLocations={{ zoomFactor: 11 }}
                >
                  {timeline?.length ? <ProductTimeline timelineData={timeline} /> : null}
                </LocationMapWidget>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <section
        id='availability'
        className='order-9 py-1 md:py-8 md:col-span-12 md:order-none'
        style={{ scrollMarginTop: '130px' }}
      >
        <div className='border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 md:border-none md:pt-0'>
          <GeneralInformationContainer elevation={2} product={product} />
        </div>
      </section>

      <section className='order-10 py-1 md:py-8 md:col-span-12 md:order-none'>
        <div className='border-t border-neutral-400 border-opacity-30 pt-4 dark:border-primary-500 dark:border-opacity-100 md:border-none md:pt-0'>
          <div className='text-neutral-900 dark:text-neutral-50'>
            <h2 className='font-medium pb-3 text-p2 relative md:pb-5 md:font-bold md:text-h2' onClick={toggleDropdownCovidClass}>
              <span className='text-accent-500 mr-2 align-text-bottom md:hidden'>
                <LocalHospitalOutlinedIcon />
              </span>
              <Localized dictKey={`${i18nPrefix}.covid.title`} />
              <span className={`absolute right-0 top-[2px] md:hidden ${isDropdownCovid ? 'rotate-180' : '' }`}>
                <ArrowDropDown />
              </span>
            </h2>
            <div className={`mb-6 md:mb-0 ${isDropdownCovid ? 'block' : 'hidden md:block' }`}>
              <p className='text-p2'>
                <Localized dictKey={`${i18nPrefix}.covid.description`} />
              </p>
              <div className='flex flex-wrap'>
                {covidSafetyMeasures?.length ? (
                  <NodeContainer
                    iconList={
                      <span className='text-accent-500 absolute left-0 top-0'>
                        <Check />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'covid.safety-measures'}
                    localizedNodes={covidSafetyMeasures}
                    elevation={2}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}
                {covidTravelerRequirements?.length ? (
                  <NodeContainer
                    iconList={
                      <span className='text-danger-500 absolute left-0 top-0'>
                        <Warning />
                      </span>
                    }
                    i18nPrefix={i18nPrefix}
                    i18nPath={'covid.requirements'}
                    localizedNodes={covidTravelerRequirements}
                    elevation={2}
                    className={
                      'text-neutral-900 py-3 dark:text-neutral-50 w-full md:w-1/2'
                    }
                    titleClass={'pb-2 text-p2 md:pb-5 md:font-medium'}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
