import * as React from 'react';
import clsx from 'clsx';
// import * as util from 'util';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Divider, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';

export interface ItemTextParagraphProps {
  children?: React.ReactNode;
  className?: string;
  description?: string | React.ReactNode;
  title: string | React.ReactNode;
}

export const ItemTextParagraph = ({
  children,
  className,
  description,
  title,
}: ItemTextParagraphProps) => {
  const styles = useStyles();
  // const theme = useTheme();
  // const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  return (    
    <div className={className}>       
      <h2 className='font-semibold pb-3 md:pb-5 md:font-bold'>
        {title || ''}
      </h2>
      
      {description && typeof description === 'string' ? (          
        <p className='text-p2'>
          {description}
        </p>
        
      ) : null}
      {description && typeof description === 'object' ? description : null}
      {children}
    </div>   
  );
};
