import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import Hidden from '@material-ui/core/Hidden';
import StarRate from '@material-ui/icons/StarRate';
// eslint-disable-next-line no-restricted-imports
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { Button, Grid, Typography } from 'src/app/components';

import { BaseComponentProps, SourcedGQLProduct } from 'src/app/types';

interface ProductRatingProps extends BaseComponentProps {
  color?: string;
  direction?: 'row' | 'column';
  product: SourcedGQLProduct;
  reviewAnchorId: string;
  textSizeForProductListItem?: string;
}

/**
 * ProductRating
 * @param { ProductRatingProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const ProductRating = (props: ProductRatingProps) => {
  const {
    className,
    color,
    direction,
    product,
    reviewAnchorId,
    style,
    textSizeForProductListItem, // TODO: remove
  } = props;
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  // const dummy = {
  //   rating: 1,
  //   reviewsCount: 0,
  // };
  const { rating, reviewsCount } = product.productData;
  // const { rating, reviewsCount } = dummy;

  const ratingInt = Math.floor(rating || 0);
  // return null;

  const stars = [];
  const handleClick = () => {
    const anchor = window.document.getElementById(reviewAnchorId);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  if (!rating || !reviewsCount || ratingInt < 1) {
    if (direction && direction === 'column') {
      return (
        <div style={style ?? { color: '#222' }} className={className ?? ''}>
          <Typography variant={'caption'}>
            <em>Not rated yet</em>
          </Typography>
        </div>
      );
    }
    return (
      <div style={style ?? { color: color || '#fff' }} className={className ?? ''}>
        <Typography
          align={'right'}
          variant={textSizeForProductListItem || 'button'}
          renderAs={'p'}
        >
          Not rated yet
        </Typography>
      </div>
    );
  }
  for (let i = 0; i < 5; i++) {
    stars.push(
      <StarRate
        // fontSize={direction && direction === 'column' ? 'default' : 'large'}
        // style={i + 1 <= ratingInt ? { color: '#222' } : { color: '#22222288' }}
        style={
          i + 1 <= ratingInt
            ? { color: color || '#fff' }
            : { color: color ? alpha(color, 0.25) : alpha('#fff', 0.25) }
        }
        key={i}
      />,
    );
  }

  if (direction && direction === 'column') {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant={'body1'} style={{ paddingRight: '8px' }}>
            <strong>{rating || 'Not rated yet'}</strong>
          </Typography>
          {stars}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant={'caption'} style={{ paddingRight: '8px' }}>
            {`${reviewsCount} Reviews`}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return !isWiderMD ? (
    <div
      style={
        style ?? {
          paddingRight: '32px',
          color: color || '#fff',
          display: 'flex',
          flexDirection: 'row',
          // width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }
      }
      className={className ?? ''}
    >
      {/** //TODO: BUTTONS CANNOT BE NESTED... */}
      {/* <Button
        variant={'text'}
        onClick={handleClick}
        style={{ padding: '0 16px 0 8px', margin: '0', textTransform: 'none' }}
      > */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {stars}
        <Typography
          variant={'h6'}
          renderAs={'p'}
          style={{ margin: '0 16px', color: color || '#fff' }}
        >
          <strong>{rating} Stars</strong>
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant={'h6'} renderAs={'p'} style={{ color: color || '#fff' }}>
          <strong>{`${reviewsCount} Reviews`}</strong>
        </Typography>
      </div>
      {/* </Button> */}
    </div>
  ) : (
    <Grid container className={className ?? ''}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          color: color || '#fff',
        }}
      >
        {rating ? (
          <>
            <Typography variant={'body1'} style={{ paddingRight: '8px' }}>
              {/* <strong>{rating || 'Not rated yet'}</strong> */}
            </Typography>
            {stars}
          </>
        ) : (
          <Typography variant={'body1'} style={{ paddingRight: '8px' }}>
            {'Not rated yet'}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export { ProductRating };
