import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { useAppContext } from 'src/app/hooks';

import { LoadingIndicator, SubmitSuccessMessage } from 'src/app/components';

import { NotificationType } from 'src/app/constants';

import { FormRootContainer } from 'src/app/components/RHF';

import { BookingCancellationFormSubmitData } from 'src/app/types';
import { BookingCancellationForm } from './BookingCancellationForm';

import { defaultValues, createDocumentResolver } from './bookingCancellation.form.config';

const documentI18nBasePath = 'common:forms.bookingCancellation.form';
type DocumentType = BookingCancellationFormSubmitData;

// TODO: i18n
export const BookingCancellationFormContainer = () => {
  const { /* currentSystemId, */ createNotification, firebase, isAppDebug } =
    useAppContext(); // TODO: select systemId based on system user data
  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);
  const [hasSubmitSuccess, setHasSubmitSuccess] = React.useState<boolean>(false);

  const currentSystemId = 'holiday'; // TODO: currentSystem => get via ENV

  const onSubmit = async (data: DocumentType) => {
    if (!firebase) {
      return;
    }

    setRequestingUpdate(true);

    interface CancellationMessageData {
      referenceId: string;
      systemId: string;
      pin: string;
    }

    const createCancellationMessage = httpsCallable(
      getFunctions(firebase),
      'commonFunctions-createCancellationMessage',
    );

    try {
      const params: CancellationMessageData = {
        referenceId: data.bookingReferenceId,
        systemId: currentSystemId,
        pin: data.pin,
      };

      await createCancellationMessage(params); // make function non async?

      // TODO: i18n
      createNotification(
        NotificationType.SUCCESS,
        'Cancellation successfully sent',
        5000,
      );
      setHasSubmitSuccess(true);
    } catch (err) {
      console.warn('Cancellation send error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setRequestingUpdate(false);
    }
  };

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    const data: DocumentType = {
      ...dataRaw,
    };

    await onSubmit(data);
  };

  const isSubmitDataValid = true;

  return hasSubmitSuccess ? (
    <SubmitSuccessMessage text={'Cancellation is sent'} />
  ) : (
    <FormRootContainer<DocumentType>
      defaultValues={defaultValues}
      resolver={createDocumentResolver}
      onSubmit={onSubmitWrapper}
      useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
      isDebug={isAppDebug}
      renderForm={({ formProps }) => {
        return isRequestingUpdate ? (
          <LoadingIndicator />
        ) : (
          <BookingCancellationForm
            formProps={formProps}
            i18nBasePath={documentI18nBasePath}
            isSubmitDataValid={isSubmitDataValid}
          />
        );
      }}
    />
  );
};
