import * as React from 'react';

import { MessageType } from 'src/app/constants';
import {
  ButtonTw,
  Localized,
  LoadingIndicator,
  TextInputField,
  Divider,
} from 'src/app/components';
import { FirestoreDocData } from 'src/app/container';
import { formatFirebaseTimestamp } from 'src/lib/date';

import { useStyles } from 'src/app/context';
import { BaseMessageDocument } from '../message.types';

interface MessageProps {
  id?: string; // not passed directly, but injected via reach-router route
  messageCollectionPath: string;
  messageType: MessageType;
  onDeleteMessage: (messageId: string) => void;
  onRespondToMessage: (messageId: string, messageText: string) => void;
  uid: string;
}

export const Message = ({
  id: messageId,
  messageCollectionPath,
  messageType,
  onDeleteMessage,
  onRespondToMessage,
  uid,
}: MessageProps) => {
  const [responseText, setResponseText] = React.useState<string>('');
  const styles = useStyles();

  const onSendResponseText = () => {
    if (messageId && responseText) {
      onRespondToMessage(messageId, responseText);
    }
  };

  const canDeleteMessage = false; // TODO: when is it allow to delete a message?
  const canRespond = messageType === MessageType.INBOUND;

  // TODO: map fromId to DB id real names (different contexts)
  // TODO: map refId to DB id real names (different contexts)

  console.log('hsjajhdjgshajhdgsa',`${messageCollectionPath}/${uid}/${messageType}/${messageId}`);

  return (
    <FirestoreDocData<BaseMessageDocument>
      documentPath={`${messageCollectionPath}/${uid}/${messageType}/${messageId}`}
      renderElements={({ data: message, status }) => {
        if (status === 'loading') {
          return <LoadingIndicator />;
        }
        if (status === 'error') {
          return 'Error';
          // <LocalizedErrorIndicator // TODO:
          //   i18nContext={''}
          //   i18nErrorCode={'LOADING_DOCUMENT'}
          //   i18nErrorAreaCode={'message'}
          // />
        }
        return status !== 'success' ? null : (
          <>
            <div className='flex flex-col gap-y-4'>
              {/* <Grid item md={6} xs={12}>
              {fetchedMessage?.id ? (
                <Typography>Message id: {fetchedMessage.id}</Typography>
              ) : null}
            </Grid> */}
              {/* <Grid item md={6} xs={12}>
                {message?.meta?.name ? (
                  <Typography>From: {message.meta.name}</Typography>
                ) : null}
              </Grid> */}
              <div className='md:flex text-p3'>
                <div className='md:w-1/2'>
                  <p>
                    {message?.meta?.type ? (
                      <Localized
                        dictKey={`organisation:messageType.${message.meta.type}.name`}
                      />
                    ) : (
                      'Unknown'
                    )}
                    {` from ${message?.meta?.name || 'Unknown'}`}
                  </p>
                </div>
                <div className='md:w-1/2'>
                  {message?.meta?.createdAt ? (
                    <p className='md:text-right text-neutral-400 dark:text-neutral-400'>
                      Created at:{' '}
                      {formatFirebaseTimestamp(
                        message.meta.createdAt,
                        'MM.DD.YYYY - hh:mm', // TODO: i18n
                      )}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <Divider />
              </div>
              {/* <Grid item xs={12}>
              {fetchedMessage?.meta?.toId ? (
                <Typography>Message to: {fetchedMessage.meta.toId}</Typography>
              ) : null}
            </Grid> */}
              {message?.meta?.refId ? (
                <div>
                  <p>Reference id: {message.meta.refId}</p>
                </div>
              ) : null}
            </div>
            <div className='flex flex-col gap-y-4 pt-4'>
              <div>
                {message?.subject ? (
                  <p className='text-p3'>Subject: <span className='font-semibold'>{message.subject}</span></p>
                ) : null}
                {/* {message?.subject ? (
                  <div>
                    <TextInputField
                      fullWidth
                      label={'Subject'}
                      variant={'outlined'}
                      value={message.subject}
                      disabled
                    />
                  </div>
                ) : null} */}
              </div>
              <div>
                {message?.body ? (
                  <>
                    <p className='text-p3'>Message:</p>
                    <p className='pt-3'>{message.body}</p>
                  </>
                ) : null}
                {/* {message?.body ? (
                  <div>
                    <TextInputField
                      fullWidth
                      variant={'outlined'}
                      value={message.body}
                      multiline
                      rowsMax={10}
                      label={'Message'}
                      disabled
                    />
                  </div>
                ) : null} */}
              </div>
              <div>
                <Divider />
              </div>
              {canRespond ? (
                <div>
                  <TextInputField
                    fullWidth
                    variant={'outlined'}
                    value={responseText}
                    multiline
                    minRows={7}
                    onChange={(e) => setResponseText(e.target.value)}
                    label={'Your Response'}
                    // rowsMax={10}
                  />
                </div>
              ) : null}
              <div className='flex gap-4 w-full justify-end'>
                <ButtonTw
                  variant={'neutral'}
                  onClick={() => (messageId ? onDeleteMessage(messageId) : null)}
                  rounded
                  size='sm'
                  disabled={!canDeleteMessage}
                >
                  Delete message
                </ButtonTw>
                {canRespond ? (
                  <ButtonTw variant={'accent'} onClick={onSendResponseText} rounded size='sm'>
                    Send response
                  </ButtonTw>
                ) : null}
              </div>
            </div>
          </>
        );
      }}
    />
  );
};
