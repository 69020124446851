import * as React from 'react';
import {
  Grid,
  GlassPaper,
  ItemTextParagraph,
  Localized,
  SignupBox,
  Typography,
} from 'src/app/components';
import { BaseDocumentData, BaseComponentProps } from 'src/app/types';
import { ProductLoaderContainer } from 'src/app.consumer/container';
import { GatsbyNodeType } from 'src/app/constants';

// PRODUCT CONTENT
import { useStyles } from 'src/app/context';
import { ProductReviews } from './Product/ProductReviews';

// BASE CONTENT

// import Carousell from './Carousell';
// import { ProductRating } from '../components/ProductRating';

// import { ProductReviews } from '../ProductReviews'; // TODO
// import { useStyles } from 'src/app/context';

// const carousellItems = [
//   {
//     imgAltLabel: '',
//     imgLabel: 'San Francisco – Oakland Bay Bridge, United States',
//     imgUrl:
//       'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
//   {
//     imgAltLabel: '',
//     imgLabel: 'Bali, Indonesia',
//     imgUrl:
//       'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
//   {
//     imgAltLabel: '',
//     imgLabel: 'Goč, Serbia',
//     imgUrl:
//       'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=1200&h=400&q=100',
//   },
// ];

export interface ContentNavigationAdditionalContentProps extends BaseComponentProps {
  item: BaseDocumentData;
  itemType: GatsbyNodeType;
}

/**
 * ActivityProductView
 * @param { ActivityProductViewProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const ContentNavigationAdditionalContent = (
  props: ContentNavigationAdditionalContentProps,
) => {
  const { item, itemType } = props;
  const styles = useStyles();

  return (
    <>
      {itemType === GatsbyNodeType.PRODUCT ? (
        <section className='py-9 px-4 md:py-10'>
          <div className='xl:container'>
            <div className='text-neutral-900 dark:text-neutral-50'>
              <h2 className='font-semibold pb-3 md:pb-5 md:font-bold text-h4 md:text-h3'>
                <Localized dictKey={'shop:/experience.reviews.title'} />
              </h2>
              <p className='text-p2'>
                <Localized dictKey={'shop:/experience.reviews.description'} />
              </p>
              <ProductReviews />
            </div>
          </div>
        </section>
      ) : null}
      {itemType === GatsbyNodeType.PRODUCT ? (
        <section className='py-9 md:px-4 md:py-10'>
          <div className='xl:container'>
            <ProductLoaderContainer
              itemsAddPerStepCount={4}
              title={<Localized dictKey={'shop:/experience.recommended-products.title'} />}
              description={
                <Localized dictKey={'shop:/experience.recommended-products.description'} />
              }
            />
          </div>
        </section>
      ) : null}
      {/* <Grid item xs={12}>
        <ProductReviews reviewAnchorId={reviewAnchorId} />
      </Grid> */}

      {/* <SignupBox
        item={item}
        // cityId={'hr-split-000'}
        // contextId={'product-view'}
        // countryId={'hr'}
        // imageUrl={'/backgroundViva.webp'}
      /> */}

      {/* <Grid item xs={12}>
          <Carousell items={tutorialSteps} />
        </Grid> */}
    </>
  );
};
