import * as React from 'react';

import { Grid } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { BaseComponentProps } from 'src/app/types';

export interface ConfirmationDialogProps extends BaseComponentProps {
  children?: React.ReactNode;
}

/**
 * ConfirmationDialog
 * @param { ConfirmationDialogProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const ConfirmationDialog = ({ children }: ConfirmationDialogProps) => {
  const { dialogData } = useAppContext();
  return (
    <Grid container spacing={2} style={{ padding: '16px 32px' }}>
      <Grid item xs={12}>
        {dialogData}
      </Grid>
      {children ? (
        <Grid item xs={12}>
          {dialogData}
        </Grid>
      ) : null}
    </Grid>
  );
};
export { ConfirmationDialog };
