import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import clsx from 'clsx';
// import GatsbyImage from 'gatsby-image';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import {
  ButtonTw,
  Divider,
  GlassPaper,
  Grid,
  Image,
  LoadingIndicator,
  Localized,
  TextInputField,
  Typography,
} from 'src/app/components';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import {
  CombinedAppProps,
  BaseComponentProps,
  NewsletterRegistrationBody,
  BaseDocumentData,
} from 'src/app/types';
import {
  useAppContext,
  useLocalizationContext,
  useAppImages,
  useSourcedCities,
} from 'src/app/hooks';
import { NotificationType, ProductCategoryType } from 'src/app/constants';
import { useStyles } from 'src/app/context';

// FIXME: validate email format
export interface SignupBoxProps extends BaseComponentProps {
  item?: BaseDocumentData;

  // OPTIONAL EMAIL CONTEXT:
  contextId?: string; // TODO: enum identifier for where the signup comes from
  productType?: ProductCategoryType; // TODO:
}

// TODO: provide correct + lang
/**
 * SignupBox / WAPBSignupBox
 * @param { SignupBoxProps } props
 * @version 0.0.0
 * @description
 * @todo
 * - regex validation
 * @example
 */
const SignupBox = (props: SignupBoxProps) => {
  const { item, contextId, productType } = props;
  const { createNotification, firebase } = useAppContext();
  const { language } = useLocalizationContext();

  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const { fallbackImage } = useAppImages();
  const cities = useSourcedCities();

  const [email, setEmail] = React.useState<string>('');
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  const onSignupButtonClick = async () => {
    if (!firebase) {
      return;
    }
    // FIXME: validate email format
    // const signupFormValues: NewsletterRegistrationBody = {
    //   email,
    //   context: {
    //     contextId: contextId || '',
    //     locale: language || 'en',

    //     categoryId: categoryId || '',
    //     cityId: cityId || '',
    //     countryId: cityId || '',
    //     productType: productType || '',
    //   },
    // };
    const signupFormValuesDummy: NewsletterRegistrationBody = {
      email,
      context: {
        contextId: contextId || '',
        locale: language || 'en',

        categoryId: item?.categoryId || '',
        cityId: item?.cityId || '',
        countryId: item?.countryId || '',
        productType: productType || '',
        href: '', // ?
      },
    };
    try {
      setIsRequestingUpdate(true);
      const registerToNewsletter = httpsCallable(
        getFunctions(firebase),
        'shopFunction-newsletterSubscribe',
      );

      await registerToNewsletter({ ...signupFormValuesDummy });

      // TODO: i18n
      createNotification(
        NotificationType.SUCCESS,
        `Signup successful, an email has been sent to ${email}`,
        5000,
      );
      setEmail('');
    } catch (err) {
      console.warn('Newsletter signup error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  if (!item) {
    return null;
  }
  const city = cities.find((c) => c.id === item.cityId) || null;
  let locales = null;
  if (city) {
    locales =
      city.localizations.find((l) => l.localeId === language || 'en')?.locales || null;
  }

  const fallbackName = 'Croatia'; // TODO: i18n fallback

  return (
    <>
      <div className='pb-7'>
        <h3 className='text-accent-500 font-bold text-h3 text-center md:text-left'>
          <Localized
            dictKey={'shop:screen-newsletter.main.header'}
            translationOptions={{ itemName: locales?.name || fallbackName }}
          />
        </h3>
        <p className='text-p3 md:text-p2 pt-8 md:pt-0 md:font-medium text-center md:text-left'>
          <Localized
            dictKey={'shop:screen-newsletter.main.text'}
            translationOptions={{ itemName: locales?.name || fallbackName }}
          />
        </p>
      </div>
      <div className='flex flex-col gap-2 md:gap-4 md:flex-row'>
        <div className='flex-1'>
          <TextInputField
            disabled={isRequestingUpdate}
            variant={'outlined'}
            label={<Localized dictKey={'shop:screen-newsletter.input-email.label'} />}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            className={'rounded-xl bg-neutral-100 dark:bg-neutral-100'}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: '!rounded-xl dark:!text-neutral-900',
                input:
                  '!rounded-xl dark:!text-neutral-900 dark:!bg-neutral-100 !h-12 md:!h-14 !box-border',
                //focused: classes.textField__focused,
                //notchedOutline: classes.textField__notchedOutline,
              },
            }}
          />
        </div>
        <div className='md:w-auto'>
          {isRequestingUpdate ? (
            <LoadingIndicator />
          ) : (
            <ButtonTw
              variant={'accent'}
              onClick={onSignupButtonClick}
              rounded={'rounded'}
              size={'lg'}
              className={'w-full'}
            >
              <Localized dictKey={'shop:screen-newsletter.button-signup.label'} />
            </ButtonTw>
          )}
        </div>
      </div>
    </>
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <GlassPaper
          elevation={8}
          isTransparent
          noOutline
          className={clsx(styles.signupBox__glass, styles.w__100)}
        >
          <Card
            className={clsx({
              [styles.signupBox__card]: isWiderMD,
              [styles.signupBox__card__MD]: !isWiderMD,
              [styles.paper_transparent]: true,
            })}
          >
            {/* <GatsbyImage
              className={clsx({
                [styles.signupBox__leftSide]: isWiderMD,
                [styles.signupBox__leftSide__MD]: !isWiderMD,
              })}
              fluid={
                // item?.imageFiles?.[0]?.childImageSharp?.fluid ||
                fallbackImage.childImageSharp.fluid
              }
              alt={'Signup Title Image'}
            /> */}
            <div>
              <Image
                src={
                  'https://user-images.githubusercontent.com/75487668/150805491-8a7f3ba8-b781-4a37-9209-60e519a432c1.jpg'
                }
                alt={'ImageAlt'}
                // style={{ border: 'dashed #222 0.5px' }}
                className={clsx({
                  [styles.signupBox__leftSide]: isWiderMD,
                  [styles.signupBox__leftSide__MD]: !isWiderMD,
                })}
                style={{ maxWidth: '75%' }}
              />
            </div>

            <div
              className={clsx({
                [styles.signupBox__rightSide]: isWiderMD,
                [styles.signupBox__rightSide__MD]: !isWiderMD,
              })}
            >
              <div className={styles.signupBox__details}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography renderAs='h2' variant='h4'>
                      <Localized
                        dictKey={'shop:screen-newsletter.main.header'}
                        translationOptions={{ itemName: locales?.name || fallbackName }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                      <Localized
                        dictKey={'shop:screen-newsletter.main.text'}
                        translationOptions={{ itemName: locales?.name || fallbackName }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <TextInputField
                      disabled={isRequestingUpdate}
                      variant={'outlined'}
                      color={'primary'}
                      label={
                        <Localized dictKey={'shop:screen-newsletter.input-email.label'} />
                      }
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isRequestingUpdate ? (
                      <LoadingIndicator />
                    ) : (
                      <Button
                        className={styles.buttonContained}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        onClick={onSignupButtonClick}
                        size={'large'}
                      >
                        <Typography>
                          <Localized
                            dictKey={'shop:screen-newsletter.button-signup.label'}
                          />
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Card>
        </GlassPaper>
      </Grid>
    </Grid>
  );
};

const WAPSignupBox = (props: CombinedAppProps<SignupBoxProps>) =>
  withoutAppProps<CombinedAppProps<SignupBoxProps>, SignupBoxProps>(SignupBox, props);

export { SignupBox, WAPSignupBox };
