import { Resource } from 'i18next';

import { AppLocale, LocaleNamespaces } from 'src/app/constants';
import * as de from './de';
import * as en from './en';
import * as hr from './hr';
import * as fr from './fr';

const supportedLanguages: AppLocale[] = [
  AppLocale.DE,
  AppLocale.EN,
  AppLocale.HR,
  AppLocale.FR,
];

const nameSpaces: LocaleNamespaces[] = [
  LocaleNamespaces.account, // move to common?
  LocaleNamespaces.common,
  LocaleNamespaces.meta, // move to common?
  LocaleNamespaces.organisation,
  LocaleNamespaces.staticLocales, // TODO: merge into shop NS?

  LocaleNamespaces.currency, // move to common?
  LocaleNamespaces.gdpr, // needed/move?
  LocaleNamespaces.product, // needed/move?
  LocaleNamespaces.shop,
  LocaleNamespaces.system,
];

const defaultNS: string = 'webapp';

// TODO: type the locales structure?
const localeResources: { [key: string]: Resource } = {
  de,
  en,
  hr,
  fr,
};

export { supportedLanguages, localeResources, nameSpaces, defaultNS };
