import * as React from 'react';
import { DocumentData } from 'firebase/firestore';
import { isSSR } from 'src/app/lib';

const UseFirestoreDocData = React.lazy(
  () => import('./UseFirestoreDocData'),
) as any as React.FunctionComponent<DocumentData>;

const UseFirestoreDocDataOnce = React.lazy(
  () => import('./UseFirestoreDocDataOnce'),
) as any as React.FunctionComponent<DocumentData>;

interface FirestoreDocDataProps<T extends DocumentData> {
  documentPath: string;
  renderElements: (props: {
    data: T;
    status: 'loading' | 'error' | 'success';
  }) => React.ReactNode;
  // pathSegments: string; // not needed atm (either of type string or string[]?)
  useSubscription?: boolean; // do not subscribe to document changes
  isDebug?: { collapsed?: number } | boolean;
  noIdField?: boolean;
}

// NOTE: if not in gatsby SSR build mode - then it is ok to import package 'reactfire', it depends on browser fetch feature
export const FirestoreDocData = <T extends DocumentData>({
  documentPath,
  renderElements,
  useSubscription,
  isDebug,
  noIdField = true,
}: FirestoreDocDataProps<T>) => {
  return isSSR ? null : (
    <>
      {useSubscription ? (
        <UseFirestoreDocData
          documentPath={documentPath}
          renderElements={renderElements}
          isDebug={isDebug}
          noIdField={noIdField}
        />
      ) : (
        <UseFirestoreDocDataOnce
          documentPath={documentPath}
          renderElements={renderElements}
          isDebug={isDebug}
          noIdField={noIdField}
        />
      )}
    </>
  );
};
