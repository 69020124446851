import * as React from 'react';
import { Grid, Glass, Typography } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

import { HistoryList } from './HistoryList';

/**
 * HistoryContainer
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const HistoryContainer = () => {
  const { isLoggedIn, user } = useAppContext();

  return (
    <Glass className='p-4'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h4'} renderAs={'h1'}>
            Your history
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            An overview over your purchased items
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoggedIn && user?.uid ? (
            <HistoryList uid={user.uid} />
          ) : (
            <>
              <Typography variant={'subtitle1'}>
                You need to have an account at Hey Holiday to use this feature
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Glass>
  );
};

export { HistoryContainer };
