import * as React from 'react';
import MGrid, { GridProps as MGridProps } from '@material-ui/core/Grid';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
// import { useStyles } from 'src/app/context';
import clsx from 'clsx';
// import { fade } from '@material-ui/core/styles/colorManipulator';

// const useStyles = (props: GridProps) =>
//   makeStyles<Theme, MGridProps>((theme: Theme) =>
//       grid: {
//         // backgroundColor: `${fade(theme.palette.primary.main, 0.95)} !important`, // use for debug with important
//         // padding: `${theme.spacing(spacingV || spacing || 2)}pximport { useStyles } from 'src/app/context';

//         //   ${theme.spacing(spacingH || spacing || 2)}px`,
//       },
//     }),
//   );

export interface GridProps extends CombinedBaseComponentProps<MGridProps> {}

/**
 * Grid / WAPGrid
 * @param { GridProps } props https://material-ui.com/api/grid
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Grid = (props: GridProps) => {
  const { children, className, ...passProps } = props;
  // const styles = useStyles(passProps)(props);
  // const styles = useStyles();
  return (
    <MGrid className={clsx({ [className || '']: true })} {...passProps}>
      {children}
    </MGrid>
  );
};

const WAPGrid = (props: CombinedAppProps<GridProps>) =>
  withoutAppProps<CombinedAppProps<GridProps>, GridProps>(Grid, props);

export { Grid, WAPGrid };
