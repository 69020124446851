import * as React from 'react';

import { DialogType, NotificationType, ProductCategoryType } from 'src/app/constants';
import { Container, Grid, LoadingIndicator } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { BaseDocumentData, SourceProduct } from 'src/app/types';

import { AddToWishlistConfirmation } from 'src/app.consumer/components';

import { doc, getFirestore, Timestamp } from 'firebase/firestore';
import { ActivityProductView } from '../activityProductView';

export interface UserWishlistDocumentData extends BaseDocumentData {}

export interface ProductContainerProps {
  product: SourceProduct<ProductCategoryType.ACTIVITY>; // TODO: pick right one
}
const ProductContainer = (props: ProductContainerProps) => {
  const { product } = props;
  const reviewAnchorId = 'product-review-area-anchor';

  const { createNotification, firebase, isLoggedIn, user, openDialog } = useAppContext();
  const [isPostingToWishlist, setIsPostingToWishlist] = React.useState<boolean>(false);

  // FIXME: fallback for not logged in users...
  const onAddWishlistProduct = () => {
    if (!firebase) {
      return; // FIXME: notify error
    }
    if (!isLoggedIn || !user?.uid) {
      // TODO: show create account dialog
      openDialog(DialogType.SIGNUP);
      return;
    }
    setIsPostingToWishlist(true);

    // NOTE: COMMENTED OUT DUE TO USAGE OF FB V8 => need custom fb-like id generator here
    // const wishlistDocRef = firebase
    //   ?.firestore()
    //   .collection(`user/${user?.uid}/wishlist`)
    //   .doc();

    // const wishlistDocRef = doc(getFirestore(firebase), `user/${user?.uid}/wishlist/${}`); // TODO:

    const wishlistDocData = {
      createdAt: Timestamp.now(),
      id: '', // wishlistDocRef.id,
      productId: product.id,
      // TODO:
      // productDateId if selected by user
      // productConfig if created by user
      // userInputData if input by user
      v: '1',
    };

    // wishlistDocRef
    //   .set(wishlistDocData)
    //   .then(() => {
    //     createNotification(NotificationType.SUCCESS, <AddToWishlistConfirmation />, 3500); // TODO: default values enum for timeouts
    //   })
    //   .catch((err) => {
    //     console.warn('Wishlist error: ', err);
    //   })
    //   .finally(() => {
    //     setIsPostingToWishlist(false);
    //   });
  };

  // TODO: create global actions/processes indicator system
  return (
    <Container>
      <Grid container>
        {isPostingToWishlist ? (
          <Grid item xs={12}>
            <LoadingIndicator />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {product?.productType === ProductCategoryType.ACTIVITY ? (
            <ActivityProductView
              activity={product}
              onAddWishlistProduct={onAddWishlistProduct}
              reviewAnchorId={reviewAnchorId}
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export { ProductContainer };
