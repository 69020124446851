import * as React from 'react';

import { Grid, InternationalFlag, Typography } from 'src/app/components';
import { FlagCountryId } from 'src/app/constants';

interface FlaggedPhoneNumberProps {
  countryTitle: string | React.ReactNode;
  flagCountryId: FlagCountryId;
  phoneNumber: string | React.ReactNode;
}
export const FlaggedPhoneNumber = ({
  countryTitle,
  flagCountryId,
  phoneNumber,
}: FlaggedPhoneNumberProps) => {
  return (
    <div className='flex justify-between my-4'>
      <div className='flex flex-1 gap-4 items-center'>
        <InternationalFlag flagCountryId={flagCountryId} />
        {countryTitle}
      </div>
      <div className='flex-1 text-right'>
        {phoneNumber}
      </div>
    </div>
  );
};
