import * as React from 'react';

import { BaseComponentProps, SourcedGQLProduct } from 'src/app/types';

import { GatsbyNodeType } from 'src/app/constants';

import { ContentItemScreen } from '../ContentItemScreen';

export interface ProductScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLProduct;
}

export const ProductScreen = (props: ProductScreenProps) => {
  const { item, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  return (
    <ContentItemScreen
      collectionMappings={[]} // maybe similar tag/categories
      itemLocations={[]}
      itemType={GatsbyNodeType.PRODUCT}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
