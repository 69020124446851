import * as React from 'react';
import clsx from 'clsx';
import ReorderIcon from '@material-ui/icons/Reorder';

import {
  ButtonTw,
  Localized,
  LocalizedLink,
} from 'src/app/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SourcedGQLProduct, Price, ShoppingCartItemDocumentData } from 'src/app/types';
import { useLocalizationContext, useLocalizedNavigation } from 'src/app/hooks';
import {
  calculateTotalPrice,
  euroPriceAsCurrency,
  getCartItemsTotalPrices,
  getQuantityDiscount,
  padPrice,
} from 'src/lib/price';

import { getTranslationForLanguage } from 'src/lib/document';
import { useStyles, useAppContext } from 'src/app/context';
import { areShoppingCartItemParticipantsIncomplete } from 'src/lib/participant';

interface ProductListItemContentProps {
  cartItem: ShoppingCartItemDocumentData;
  hideTeaser?: boolean;
  onExtendDetails: () => void;
  // onOpenAdditionalInformation: () => void;
  product: SourcedGQLProduct;
}

export const ProductListItemContent = (props: ProductListItemContentProps) => {
  const { cartItem, hideTeaser, onExtendDetails, product } = props;
  const { id, localizations } = product;
  const { isAppDebug, currencyId } = useAppContext();

  const styles = useStyles();
  // const { navigateLocalized } = useLocalizedNavigation();
  const { language } = useLocalizationContext();

  const productTitle = getTranslationForLanguage(
    localizations || [],
    language,
    'name',
    isAppDebug,
  );
  // const productTitle = 'From Split: Krka National Park Day Trip with Boat Ride 1234 6789';
  const productTeaser = getTranslationForLanguage(
    localizations || [],
    language,
    'teaser',
    isAppDebug,
  );

  const i18nPath = 'shop:/shopping-cart';

  const totalPrices: Price[] = getCartItemsTotalPrices([cartItem], [product]);
  const totalPrice = calculateTotalPrice(totalPrices);


  // const quantityDiscount = getQuantityDiscount(
  //   product.priceSystems.find((ps) => ps.id === cartItem.priceSystemId)?.publicBooking
  //     .quantityDiscounts,
  //   cartItem.itemConfigurations.length,
  // );
  // console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ${quantityDiscount}`);


  const paddedCurrencyPrice =euroPriceAsCurrency(totalPrice, currencyId)
  console.log('priceFloat',paddedCurrencyPrice);
   

  // const paddedCurrencyPrice = {
  //   integer: 0,
  //   decimal: 1,
  // };

  // const isPriceValid = !(
  //   Number.isNaN(totalPrice.integer) ||
  //   Number.isNaN(totalPrice.decimal) ||
  //   totalPrice.integer < 0 ||
  //   totalPrice.decimal < 0
  // ); // FIXME: better validation needed

  // const price = 0; // productPriceSystems?[0]?.publicSettings?.productBasePrice || ''; // TODO:
  // const hasDiscount = true; // FIXME show discounts explicitly
  // const priceBeforeDiscount = price * 2 || ''; // TODO: dummy

  const fontSizeStyle = { fontSize: '20px' }; // for fontsize matching with 64 chars into 70px max-height

  const hasParticipantInformationFeature = !!product.priceSystems.find(
    (ps) => ps.id === cartItem.priceSystemId,
  )?.publicBooking?.isParticipantInfoNecessary;

  // TODO:
  // const participantInformationFields: ParticipantFieldInfo[] = product.priceSystems.find(
  //   (ps) => ps.id === cartItem.priceSystemId,
  // )?.publicBooking?.participantInfos;

  const areItemParticipantsIncomplete =
    hasParticipantInformationFeature &&
    areShoppingCartItemParticipantsIncomplete(cartItem);
  // console.log('areItemParticipantsIncomplete 0: ', areItemParticipantsIncomplete);

  return (
    <>
      <div className='flex flex-col justify-between h-full'>
        <div className='flex flex-col gap-8 md:gap-4 md:flex-row md:min-h-[100px]'>
          <div className='w-full md:w-3/4'>
            <div>
              <LocalizedLink href={`/experience/${product.id}`}>
                <h3 className='text-p1 font-semibold md:text-h3 md:font-bold'>
                  {productTitle ?? ''}
                </h3>
              </LocalizedLink>

              {!hideTeaser ? (
                <p>
                  {productTeaser ?? ''}
                </p>
              ) : null}
              {areItemParticipantsIncomplete ? (
                <p className='font-bold text-danger-500'>
                  <Localized dictKey={`${i18nPath}.error.participants-incomplete-error`} />
                </p>
              ) : null}
            </div>
          </div>
          <div className='w-full md:w-1/4 md:self-end'>
            <div className='flex justify-between items-center md:flex-col md:items-end'>
              {/* <Grid item xs={12}>
                <Typography variant={'body2'} align={'right'}>
                  From
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {hasDiscount ? (
                  <Typography variant={'body2'} align={'right'}>
                    <strike>€ {priceBeforeDiscount}</strike>
                  </Typography>
                ) : (
                  ''
                )}
              </Grid> */}
              <p className='text-p3'>
                <Localized dictKey={`${i18nPath}.cart.total-label`} />
              </p>

              <h3 className='text-h3 font-semibold'>
                <Localized
                  dictKey={paddedCurrencyPrice.decimal===0?`${i18nPath}.cart.total-price-integer`:`${i18nPath}.cart.total-price`}
                  translationOptions={{
                    currencyId,
                    price: paddedCurrencyPrice,
                  }}
                />
              </h3>
            </div>
          </div>
        </div>
        <div className='text-center mt-8'>
          <ButtonTw
            onClick={onExtendDetails}
            className='!underline'
            variant={'text'}
          >
            <Localized dictKey={`${i18nPath}.action.edit-cart-item.label`} />
            <ExpandMoreIcon />
          </ButtonTw>
        </div>
      </div>
    </>
  );
};
