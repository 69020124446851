import * as React from 'react';
import {
  FieldValues,
  FormProvider,
  useForm,
  SubmitHandler,
  Resolver,
  DefaultValues,
} from 'react-hook-form';

import { ButtonTw } from 'src/app/components';
import { FormDebug } from './FormDebug';
import { useAdminContext } from 'src/admin/context';

export interface FormRootContainerProps<T extends FieldValues> {
  className?: string;
  defaultValues: DefaultValues<T>;
  isDebug?: boolean;
  onSubmit: (formData: T) => void;
  renderForm: ({
    formProps,
  }: {
    formProps: FormRootContainerProps<T>;
  }) => React.ReactNode;

  resolver: Resolver<T, Object>;
  useFormProps?: {
    criteriaMode?: 'all' | 'firstError';
    mode?: 'all' | 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched';
    reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit';
  };
}

const validImageFileTypes: string[] = ['image/jpeg', 'image/png', 'image/webp'];

const FormRootContainer = <T extends FieldValues>(props: FormRootContainerProps<T>) => {
  const {
    className,
    defaultValues,
    isDebug,
    onSubmit,
    renderForm,
    resolver,
    useFormProps,
    // errors,
  } = props;
  const [debugDisplayState, setDebugDisplayState] = React.useState<boolean>(false);
  if (defaultValues?.product?.covidSafetyMeasures?.length == 0) {
    defaultValues?.product?.covidSafetyMeasures.push({ type: 'default', additionalInformation: '', title: 'For a full refund, you must cancel at least 24 hours before the experience’s start time.' })
    defaultValues?.product?.covidSafetyMeasures.push({ type: 'default', additionalInformation: '', title: 'If you cancel less than 24 hours before the experience’s start time, the amount you paid will not be refunded.' })
    defaultValues?.product?.covidSafetyMeasures.push({ type: 'default', additionalInformation: '', title: 'Any changes made less than 24 hours before the experience’s start time will not be accepted' })
  }
  if (defaultValues?.product?.covidTravelerRequirements?.length == 0) {
    defaultValues?.product?.covidTravelerRequirements.push({ type: 'default', additionalInformation: '', title: 'Cut-off times are based on the experience’s local time.' })
    defaultValues?.product?.covidTravelerRequirements.push({ type: 'default', additionalInformation: '', title: 'This experience requires a minimum number of travellers. If it’s cancelled because the minimum isn’t met, you’ll be offered a different date/experience or a full refund.' })
    defaultValues?.product?.covidTravelerRequirements.push({ type: 'default', additionalInformation: '', title: 'This experience requires good weather. If it’s cancelled due to poor weather, you’ll be offered a different date or a full refund.' })
  }
  if (defaultValues?.foodOptions?.options) {
    const { document, userRole } = useAdminContext();
    if (userRole == 'partner') {
      let filteredArray = defaultValues?.foodOptions?.options
        .filter((element: any) => element.localizations
          .some((localizations: { localeId: any; }) => localizations.localeId == document?.organisation?.localeId)
        )
        .map((element: { localizations: any[]; }) => {
          let n = Object.assign({}, element, {
            'localizations': element.localizations.filter(
              localizations => localizations.localeId == document?.organisation?.localeId
            )
          })
          return n;
        })
      defaultValues.foodOptions.options = filteredArray;
    }
  }
  console.log('form Root',defaultValues);

  const formMethods = useForm<T>({
    defaultValues,
    resolver,
    ...useFormProps,
    // defaultValues: defaultValues || {},
    // mode: 'onSubmit',
    // reValidateMode: 'onChange',
    // criteriaMode: 'all',
    // shouldFocusError: true,
    // shouldUnregister: true,
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  // possibility to intercept
  const onSubmitLocal: SubmitHandler<T> = (
    formData: Record<string, unknown>,
    e: React.ChangeEvent<HTMLFormElement>,
  ) => {
    // e.preventDefault();
    e.persist();

    // FIXME: remove workaround for product image creation... => check if workaround is needed elsewhere + if side effects arises
    // NOTE: file reference get lost on form submit...
    // const { imageUrls, ...restData } = formData as {
    //   imageUrls: { displayName: string; localizations: any[] }[];
    // };

    // // TODO: handle update existing image url from db (keeping same)

    // const patchedImageUrls: {
    //   imageFile: File;
    //   imageUrl: string;
    //   displayName: string;
    //   localizations: any[];
    // }[] = [];

    // if (!e.target.elements.length) {
    //   return;
    // }

    // for (let index = 0; index < e.target.elements.length; index++) {
    //   if (e.target.elements.item(index)?.files) {
    //     const fileList: FileList = e.target.elements.item(index).files || [];
    //     for (let i = 0; i < fileList.length; i++) {
    //       const file = fileList.item(i);
    //       if (file !== null && validImageFileTypes.includes(file.type)) {
    //         patchedImageUrls.push({
    //           imageFile: file,
    //           imageUrl: '',
    //           displayName: imageUrls?.[i]?.displayName || '',
    //           localizations: imageUrls?.[i]?.localizations?.length
    //             ? [...imageUrls[i].localizations]
    //             : [],
    //         });
    //       }
    //     }
    //   }
    // }

    // // FIXME: blind copy - this will most certainly lead to bugssss
    // const patchedSubmitData = {
    //   ...restData,
    //   imageUrls: [],
    // };
    // if (imageUrls) {
    //   patchedSubmitData.imageUrls = [...patchedSubmitData.imageUrls, ...imageUrls];
    // }

    // if (patchedImageUrls) {
    //   patchedSubmitData.imageUrls = [...patchedSubmitData.imageUrls, ...patchedImageUrls];
    // }

    // console.log('submit patchedSubmitData: ', patchedSubmitData);
    // onSubmit(patchedSubmitData as any as T);

    onSubmit(formData as any as T);
  };
  const onErrors = (errors2: any) => {
    // NOTE: pass up for determination of submit state?
    console.warn('FORM ERRORS2; ', errors2);
    console.warn('FORM ERRORS; ', errors);
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitLocal, onErrors)}>
        <div className={className}>
          {isDebug ? (
            <div className='flex flex-wrap gap-4 w-full py-4 items-center justify-center'>
              <ButtonTw
                rounded
                variant='accent'
                size='sm'
                className='w-full sm:w-auto'
                onClick={() => setDebugDisplayState(!debugDisplayState)}
              >
                Debug
              </ButtonTw>
              <ButtonTw
                rounded
                variant='accent'
                size='sm'
                className='w-full sm:w-auto'
                type={'submit'}
              >
                Debug Submit
              </ButtonTw>
            </div>
          ) : null}
          {isDebug && debugDisplayState ? (
            <FormDebug defaultValues={defaultValues} />
          ) : null}
          <div>
            {renderForm ? renderForm({ formProps: props }) : null}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { FormRootContainer };
