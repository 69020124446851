import * as React from 'react';
import { LinkBaseProps } from '@material-ui/core/Link';
import { Link as GatMuiLink } from 'gatsby-theme-material-ui';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { Typography } from 'src/app/components';
import { useStyles } from 'src/app/context'; // TODO: dark theme
import clsx from 'clsx';
import { useLocalizationContext } from 'src/app/hooks';

interface MLinkProps extends LinkBaseProps {
  // href?: string;
  // target?: string;
}

export interface LinkProps extends CombinedBaseComponentProps<MLinkProps> {
  icon?: React.ReactNode;
}

/**
 * Link / WAPLink
 * @param { LinkProps } props https://material-ui.com/api/link/
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Link = (props: LinkProps) => {
  const { children, className, color, icon, ...passProps } = props;
  const styles = useStyles();
  const { language } = useLocalizationContext();
  return (
    <GatMuiLink
      hrefLang={language}
      className={`${styles.link} ${className || ''}`}
      to={passProps.href || '/'}
      onHover
      underline='none'
      {...passProps}
      // rel={passProps.rel || ''}
      // target={'_blank'}
    >
      {icon || null}
      <Typography
        className={clsx({
          [styles.link]: true,
          [className || '']: true,
        })}
        variant={language !== 'cimode' ? 'button' : 'inherit'}
      >
        {children}
      </Typography>
    </GatMuiLink>
  );
};

const WAPLink = (props: CombinedAppProps<LinkProps>) =>
  withoutAppProps<CombinedAppProps<LinkProps>>(Link, props);

export { Link, WAPLink };
