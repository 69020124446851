import * as React from 'react';
import { WindowLocation } from '@reach/router';
import { PageProps, BrowserPluginArgs, WrapPageElementBrowserArgs } from 'gatsby';
import { useCookies } from 'react-cookie';
import { MDXProvider } from '@mdx-js/react';
import { SnackbarProvider } from 'notistack';
import i18n from 'i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { getMDXComponentsConfig } from 'src/app/config/mdxComponents';

import ThemeProvider from 'src/app/context/Theme';
import {
  AppNotifications,
  GDPRConsentTrigger,
  PageBackground,
  // PasswordProtected,
  // Site,
  ScrollToTop,
  classNames,
  // ScrollToTopAnchor // TODO as Comp
} from 'src/app/components';
import { AppDialogRouter } from 'src/app/dialogs';
import { AppProps, SeoObject } from 'src/app/types';

import { AppNavBar, AppFooter } from 'src/app.consumer/components';
import { OrganisationFooter } from 'src/app.organisation/components';

import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en';
import hrLocale from 'dayjs/locale/hr';
import frLocale from 'dayjs/locale/fr';

dayjs.extend(LocalizedFormat);

class LocalizedDayJSUtils extends DayJSUtils {
  // NOTE: you can override the mdethods here:
  // https://material-ui-pickers.dev/guides/formats / https://day.js.org/docs/en/display/format
  // getDatePickerHeaderText(date: Dayjs) {
  //   return dayjs(date)
  //     .locale(this.locale || 'en')
  //     .format();
  // }
}

const getDateLocale = (language: string) => {
  switch (language) {
    case 'de':
      return deLocale;
    case 'hr':
      return hrLocale;
    case 'fr':
      return frLocale;
    case 'en':
    default:
      return enLocale;
  }
};

const ScrollToTopAnchor = () => {
  return <div id='back-to-top-anchor' />;
};

// FIXME: not tested yet => base functionality is already implemented
const CookieManager = () => {
  const cookiesListToUpgradeToSameSiteNone = [''];
  const [cookies, setCookie] = useCookies(cookiesListToUpgradeToSameSiteNone);
  const [upgradeCookies, setUpgradeCookies] = React.useState<boolean>(true); // dummy logic

  /* test this => dummy effect logic needs to be enhanced
    this should set all (or just specific cookies e.g youtube set cookies) to sameSite: none & secure: true
  */
  React.useEffect(() => {
    if (upgradeCookies) {
      cookiesListToUpgradeToSameSiteNone.forEach((cookieId) => {
        const originalCookie = cookies[cookieId];
        setCookie(cookieId, originalCookie, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
      });
    }
    setUpgradeCookies(false);
  }, [cookies]);
  return null;
};

interface AppPageContainerProps extends AppProps {
  props: {
    location: WindowLocation;
  };
}

export const AppPageContainer = ({ children, props }: AppPageContainerProps) => {
  const pathname: string = props?.location?.pathname || '';

  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const unameRef = React.useRef<any>(null);
  const passRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (localStorage.getItem('isit')) {
      setIsAuthorized(true);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorized) localStorage.setItem('isit', 't');
  }, [isAuthorized]);

  let containerClassName = 'page-container';

  if (
    pathname === '/' ||
    pathname === '/en' ||
    pathname === '/de' ||
    pathname === '/fr' ||
    pathname === '/hr'
  ) {
    containerClassName = '';
  }

  let showAppBar = true;
  let showAdminFooter = false;
  if (pathname.includes('/admin/')) {
    showAppBar = false;
    showAdminFooter = true;
    containerClassName = 'page-container-less-v-padding';
  }
  if (pathname === '/admin/partner/signup') {
    showAppBar = true;
    showAdminFooter = true;
    containerClassName = 'page-container-no-v-padding';
  }
  if (pathname === '/admin/partner/terms-for-supplier') {
    showAppBar = true;
    showAdminFooter = true;
  }

  const login = () => {
    if (
      btoa(unameRef.current.value) === 'aGV5aG9saWRheQ==' &&
      btoa(passRef.current.value) === 'aGV5aG9saWRheTEyMyM='
    ) {
      setIsAuthorized(true);
    } else setIsAuthorized(false);
  };
  if (!isAuthorized) {
    return (
      <div
        className='flex flex-col gap-4 items-center justify-center h-screen 
      font-body bg-neutral-50 dark:bg-neutral-900'
      >
        <h1>Under Construction</h1>
        <p className='px-40'>
          We’re working hard to make Hey Holiday website available again on Wednesday 6th
          December. You will then find a new design and many new exiting features.
        </p>
        <div>
          <span className='font-medium'>Username: </span>
          <input
            ref={unameRef}
            autoComplete='off'
            className='border-b border-grey-dark'
            type='text'
          />
        </div>
        <div>
          <span className='font-medium'>Password: </span>
          <input
            ref={passRef}
            autoComplete='new-password'
            className='border-b border-grey-dark'
            type='password'
          />
        </div>
        <div
          className='border-solid border-2 border-sky-900 hover:border-dotted px-4 cursor-pointer'
          onClick={login}
        >
          Login
        </div>
      </div>
    );
  }

  return (
    <div className='min-w-[350px]'>
      {/* <ProtectedRoute> */}
      {showAppBar ? <AppNavBar /> : null}
      <ScrollToTopAnchor />
      <div className='font-body bg-neutral-50 dark:bg-neutral-900'>{children}</div>
      <ScrollToTop />
      <AppFooter admin={!showAdminFooter} />
      {/** Portals */}
      <AppNotifications />
      <AppDialogRouter />
      {/* </ProtectedRoute> */}
    </div>
  );
};

interface PageDataType { }
interface PageContextType { }

const WrapPageElement = (
  props: WrapPageElementBrowserArgs<PageDataType, PageContextType>,
) => {
  const {
    // children,
    element: pageElement,
    // NOTE: destructured out of passProps
    getResourceURLsForPathname,
    getResourcesForPathname,
    getResourcesForPathnameSync,
    loadPage,
    loadPageSync,
    ...pageProps
  } = props;
  const { language } = i18n;

  const locale = getDateLocale(language);

  return (
    <ThemeProvider>
      <MuiPickersUtilsProvider utils={LocalizedDayJSUtils} locale={locale}>
        <MDXProvider components={getMDXComponentsConfig(pageProps as any as PageProps)}>
          {/* <PasswordProtected> */}
          <GDPRConsentTrigger />
          {/* <CookieManager /> //FIXME: not tested yet => base functionality is already implemented */}
          <SnackbarProvider maxSnack={12}>
            <AppPageContainer {...(pageProps as any as AppPageContainerProps)}>
              {pageElement}
            </AppPageContainer>
          </SnackbarProvider>
        </MDXProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default WrapPageElement;
