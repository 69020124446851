import * as React from 'react';

import { Button, Grid, Toolbar } from 'src/app/components';

interface DocumentActionOptions {
  hasCreateOption: boolean;
  hasEditOption: boolean;
  hasDeleteOption: boolean;
}
interface AdminCollectionToolbarProps<T> {
  hasSelectedDocument: boolean;
  documentActionOptions: DocumentActionOptions;
  onCreateDocument: () => void;
  onDeleteDocument: () => void;
  onEditDocument: () => void;
}
// NOTE: could accept a range of btn cfgs when different create dialog etc should be needed
const AdminCollectionToolbar = <T extends Record<string, unknown>>(
  props: AdminCollectionToolbarProps<T>,
) => {
  const {
    hasSelectedDocument,
    documentActionOptions,
    onCreateDocument,
    onDeleteDocument,
    onEditDocument,
  } = props;
  const { hasCreateOption, hasEditOption, hasDeleteOption } = documentActionOptions;
  return (
    <Toolbar style={{ margin: '16px 0' }}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Button
            disabled={!hasCreateOption}
            onClick={onCreateDocument}
            // color={'primary'}
            fullWidth
          >
            Create
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            disabled={!hasSelectedDocument || !hasEditOption}
            onClick={onEditDocument}
            // color={'primary'}
            fullWidth
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            disabled={!hasSelectedDocument || !hasDeleteOption}
            onClick={onDeleteDocument}
            // style={{ backgroundColor: '#f22' }}
            fullWidth
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export { AdminCollectionToolbar };
