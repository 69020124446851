import * as React from 'react';
import MTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps } from 'src/app/types';

// type workaround since CombinedBaseComponentProps<TextFieldProps> isn't accepted by TSC
type TextInputFieldProps = BaseComponentProps & TextFieldProps;
// FIXME: has ref isses with forms
/**
 * TextInputField / WAPTextInputField
 * @param { TextInputFieldProps }
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const TextInputField = (props: TextInputFieldProps) => {
  const { children, className, inputProps, ...passProps } = props;

  return (
    <MTextField
      InputProps={{
        ...inputProps,
      }}
      className={className || ''}
      {...passProps}
    >
      {children}
    </MTextField>
  );
};

const WAPTextInputField = (props: CombinedAppProps<TextInputFieldProps>) =>
  withoutAppProps<CombinedAppProps<TextInputFieldProps>, TextInputFieldProps>(
    TextInputField,
    props,
  );

export { TextInputField, WAPTextInputField };
