import * as React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { Grid, Localized, SignupFormUser, LoadingIndicator } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps, SignupFormData } from 'src/app/types';

import { useStyles } from 'src/app/context';

import { FormRootContainer } from 'src/app/components/RHF';
import { NotificationType } from 'src/app/constants';
import { navigate } from 'gatsby';

const defaultValues = {
  dob: dayjs(new Date()),
  title: '',
  gender: '',
  displayName: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordRepeat: '',
  phoneNumber: '',
};

const loginFormSchema = yup.object().shape({
  dob: yup.date().required(),
  displayName: yup.string().required(),
  firstName: yup.string().required(),
  gender: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  password: yup.string().required(),
  passwordRepeat: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  phoneNumber: yup.string().required(),
});

const resolver = yupResolver(loginFormSchema);

export interface SignupFormContainerProps extends BaseComponentProps {
  children?: React.ReactNode;
  signupType: 'shop' | 'organisation';
}

/**
 * LoginForm / WAPLoginForm - MUI
 * @param { LoginFormProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const SignupFormContainer = (props: SignupFormContainerProps) => {
  const { children, signupType } = props;
  const {
    createStackedNotification,
    closeDialog,
    isAppDebug,
    signup,
    signupOrganisationUser,
  } = useAppContext();
  const styles = useStyles();

  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);
  const localizationPath = 'shop:signupForm';

  const onSubmit = async (formData: SignupFormData) => {
    if (!signupType) {
      return;
    }
    try {
      setIsRequestingUpdate(true);
      if (signupType === 'organisation') {
        await signupOrganisationUser(formData);
        navigate('/admin/partner');
      } else {
        const res = await signup(formData);
        console.log('res: ', res);

        // TODO: build "Error switch handler for different types of known errors?"
        // TODO: create error mappings for all other known possible server errors
        if (res && res.data?.code && res.data.details?.codePrefix) {
          // NOTE: has ERROR
          if (res.data.details.codePrefix === 'auth' && res.data.details.errorInfo) {
            createStackedNotification(
              NotificationType.ERROR,
              <Localized
                dictKey={`${localizationPath}.action.signup-submit.notification.error`}
                translationOptions={{
                  errorNs: 'firebase',
                  errorCode: res.data.details.errorInfo.code,
                }}
              />,
            );
          } else {
            createStackedNotification(
              NotificationType.ERROR,
              <Localized
                dictKey={`${localizationPath}.action.signup-submit.notification.error`}
                translationOptions={{ errorNs: 'internal', errorCode: 'unknown' }}
              />,
            );
          }
        } else if (res) {
          // NOTE: has SUCCESS
          createStackedNotification(
            NotificationType.SUCCESS,
            <Localized
              dictKey={`${localizationPath}.action.signup-submit.notification.success`}
            />,
          );
        }
      }
    } catch (e) {
      const err = e as any;
      console.warn('Error: ', err);
      createStackedNotification(
        NotificationType.ERROR,
        <Localized
          dictKey={`${localizationPath}.action.signup-submit.notification.error`}
          translationOptions={{ errorNs: 'internal', errorCode: 'unknown' }}
        />,
      );
    } finally {
      setIsRequestingUpdate(false);
    }
    closeDialog();
  };

  // TODO: consider lifting up to hide outer container content
  if (isRequestingUpdate) {
    return (
      <LoadingIndicator />
    );
  }

  return (
    <>
      <FormRootContainer<SignupFormData>
        className={styles.px__4}
        defaultValues={defaultValues}
        isDebug={isAppDebug}
        resolver={resolver}
        onSubmit={onSubmit}
        useFormProps={{ mode: 'onBlur', reValidateMode: 'onSubmit' }}
        renderForm={({ formProps }) => (
          <SignupFormUser<SignupFormData>
            i18nBasePath={`${localizationPath}`}
            isValid={!isRequestingUpdate}
            // formProps={formProps}
          />
        )}
      />
      {children}
    </>
  );
};

const WAPSignupFormContainer = (props: CombinedAppProps<SignupFormContainerProps>) =>
  withoutAppProps<CombinedAppProps<SignupFormContainerProps>, SignupFormContainerProps>(
    SignupFormContainer,
    props,
  );

export { SignupFormContainer, WAPSignupFormContainer };
