import { FirebaseApp } from 'firebase/app';
import { LocaleNamespaces } from 'src/app/types';
import { functionNamespace } from './_namespace';
import { callableFunction, createCallableFunctionMeta } from '../lib';

export type GetLocalizationsCallableRequestData = {
  systemId: string;
};

export interface GetLocalizationsCallableResponseData extends Record<string, unknown> {
  [key: string]: LocaleNamespaces;
  // code: string;
  // details: {
  //   codePrefix: string;
  //   errorInfo: {
  //     code: string;
  //   };
  // };
}

/**
 * exportFunction-getLocalizations
 * @version 0.0.0 functions version
 */
export const getLocalizations = async (
  firebase: FirebaseApp,
  env: string,
  params: GetLocalizationsCallableRequestData,
  functionName: string = 'getLocalizations',
) =>
  callableFunction<
    GetLocalizationsCallableRequestData,
    GetLocalizationsCallableResponseData
  >(firebase, env, params, createCallableFunctionMeta(functionNamespace, functionName));
