import React from 'react';
import { LocalizationContext } from './localizationContext.types';

export const localizationContext = React.createContext<LocalizationContext | null>(null);

// since the app is wrapped in a themeProvider, we assume that context cannot be null
export const useLocalizationContext: () => LocalizationContext =
  (): LocalizationContext =>
    React.useContext<LocalizationContext | null>(
      localizationContext,
    ) as LocalizationContext;

export default localizationContext;
