import * as React from 'react';
import MuiListItem, { ListItemProps } from '@material-ui/core/ListItem';

// import clsx from 'clsx';
// import { useStyles } from 'src/app/context';

/**
 * @param { ListItemProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/list-item
 * @todo
 * @example
 */
export const ListItem = React.forwardRef(
  <T extends Record<string, unknown>>(
    props: ListItemProps<'li', T>,
    ref: React.Ref<any>, // TODO: type
  ) => {
    // const styles = useStyles();
    return <MuiListItem innerRef={ref} {...props} />;
  },
);
