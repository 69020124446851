import constants from './constants';
import dialogs from './dialogs';
import document from './document';
import organisationPages from './pages';
import organisationScreens from './screens';

export default {
  ...constants,
  ...organisationPages,
  ...organisationScreens,
  document,
  dialogs,
};
