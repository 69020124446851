import * as React from 'react';
// import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';

import {
  Grid,
  Localized,
  Typography,
  QuantityWidget,
} from 'src/app/components';

import {
  BookingSystem,
  Price,
  ProductEventParticipant,
  ShoppingCartItemConfiguration,
  SourcedGQLProduct,
} from 'src/app/types';
import { useStyles } from 'src/app/context';

import { calculatePriceFromPriceString, calculatePriceGroupPrice, convertPriceToFloat, euroPriceAsCurrency, padPrice } from 'src/lib/price';

import { useAppContext, useLocalizationContext } from 'src/app/hooks';

// import { parsePickupOptionId } from 'src/lib/participant';
import { ProductTicketGroupParticipants } from './ProductTicketGroupParticipants';
import { BookingSystemType } from 'src/app/constants';
interface PriceGroupControlProps {
  groupPrice: Price;
  fieldOptions: { id: string }[];
  priceGroupId: string;
  priceGroupTickets: ShoppingCartItemConfiguration[];
  priceSystemId: string;
  priceSystemSettings: BookingSystem;
  product: SourcedGQLProduct;
  showParticipantsInfo: boolean;
  onSaveParticipant: (
    priceGroupId: string,
    participantId: string,
    participantData: ProductEventParticipant,
  ) => void;
  setTicketCount: (priceGroupId: string, amount: number) => void;
  maxTicketsOrderable: number;
  bookingSystem :'publicBooking' | 'privateBooking',
}

export const PriceGroupControl = ({
  groupPrice,
  fieldOptions,
  priceGroupId,
  priceGroupTickets,
  priceSystemId,
  priceSystemSettings,
  product,
  showParticipantsInfo, // TODO:
  onSaveParticipant,
  setTicketCount,
  maxTicketsOrderable,
  bookingSystem ,
}: PriceGroupControlProps) => {
  const { currencyId } = useAppContext();
  // const { language } = useLocalizationContext();
  const styles = useStyles();
console.log("priceSystemSettings...",groupPrice)
  const productPriceSystem = product.priceSystems.find((ps) => ps.id === priceSystemId);
  if (!productPriceSystem) {
    return null;
  }

  const orderMinQuantity = priceSystemSettings?.orderMinQty;
  // const orderMinQuantity = 1;
  // NOTE: orderMinQty could also be a prop inside of each priceGroup

  const orderMinQty = orderMinQuantity || 1;
  const priceGroup = priceGroupTickets.find((pgt) => pgt.priceGroupId === priceGroupId);

  if (!priceGroup) {
    return null;
  }
  // this could be injected as prop
  const participants = [...(priceGroup.participants || [])];

  const participantTicketCount =   priceGroup.count || 0;
   // console.log('--groupPrice: ', groupPrice);

  // const showParticipantsInfo = true; // DEBUG => always render but supply config (food vs pickup vs org req properties)

  // const { pickup } = product;

  // console.log('>>pickup: ', pickup);
  const priceSystem =
  product.priceSystems?.find((ps) => ps.id === priceGroup.priceSystemId) || null;

  const pubSettings = bookingSystem; // TODO: what about private bookings
  const basePrice:any =
  priceSystem?.basePrice || priceSystem?.[bookingSystem]?.basePrice || '00.00';
  const max_num=maxTicketsOrderable;
  let convertedPrice =convertPriceToFloat(groupPrice);
  let discountAmount=calculatePriceFromPriceString(basePrice-convertedPrice);
  
  let price= euroPriceAsCurrency(
    calculatePriceGroupPrice(priceGroup, product.priceSystems,bookingSystem),
    currencyId,
)


  return (
    <QuantityWidget
      amount={participantTicketCount}
      onAmountChange={(val) => setTicketCount(priceGroupId, val)}
      min={0}
      // max={priceSystemSettings?.maxParticipants || 0} // TODO: check how many tickets are still available
      max={maxTicketsOrderable || 0} // TODO: check how many tickets are still available
      labelDictId={`shop:screen-product.price-group.${priceGroupId}.label`}
      stepAmount={orderMinQty}
      className='text-p3 sm:text-p2'
    >
      {
        priceGroupId=="__PRIVATE_GROUP"?      
        <p>
    
        <Localized
          dictKey={price.decimal===0?'shop:screen-product.add-cart.price-per-group.labelInteger':'shop:screen-product.add-cart.price-per-group.label'}
          translationOptions={{
            currencyId,
            max_num:max_num,
            price:
              euroPriceAsCurrency(
                calculatePriceGroupPrice(priceGroup, product.priceSystems,bookingSystem),
                currencyId,
              
            ),
          }}
        />
         
      </p>:
            <p>
        
            <Localized
              dictKey={price.decimal===0?'shop:screen-product.add-cart.price-per-person.labelInteger':'shop:screen-product.add-cart.price-per-person.label'}
              translationOptions={{
                currencyId,
                max_num:max_num,
                price:
                  euroPriceAsCurrency(
                    calculatePriceGroupPrice(priceGroup, product.priceSystems,bookingSystem),
                    currencyId,
                  
                ),
              }}
            />
            {(basePrice-convertedPrice)>0? 
           <p className="text-sm ...">(discount applied)</p>:null}
          </p>
          
      }
    </QuantityWidget>
  );

  // OLD CODE WITH ProductTicketGroupParticipants underneath

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <QuantityWidget
          amount={participantTicketCount}
          className={styles.px__2}
          onAmountChange={(val) => setTicketCount(priceGroupId, val)}
          min={0}
          max={priceSystemSettings?.maxParticipants || 0} // TODO: check how many tickets are still available
          labelDictId={`shop:screen-product.price-group.${priceGroupId}.label`}
          stepAmount={orderMinQty}
        >
          <Typography align={'center'}>
            <Localized
              dictKey={'shop:screen-product.add-cart.price-per-person.label'}
              translationOptions={{
                currencyId,
                // price: createPriceStringWithCurrency(groupPrice, currencyId),
                price: padPrice(
                  calculatePriceGroupPrice(priceGroup, product.priceSystems),
                ),
              }}
            />
          </Typography>
        </QuantityWidget>
      </Grid>
      <Grid item xs={6}>
        <Grid container className={styles.px__2}>
          <Grid item xs={12}>
            <Grid container>
              {/* <Grid item xs={12}>
                <Typography align={'center'}>
                  <Localized
                    dictKey={'shop:screen-product.add-cart.price-per-person.label'}
                    translationOptions={{
                      currencyId,
                      // price: createPriceStringWithCurrency(groupPrice, currencyId),
                      price: padPrice(
                        calculatePriceGroupPrice(priceGroup, product.priceSystems),
                      ),
                    }}
                  />
                </Typography>
              </Grid> */}
              {participants.filter((p) => !!p.options?.pickup).length ? (
                <>
                  {/** TODO: */}
                  {/* <Grid item xs={12}>
                    <Typography align={'center'}>
                      <Localized
                    dictKey={'shop:screen-product.add-cart.price-per-person.label'}
                    translationOptions={{
                      currencyId,
                      // price: createPriceStringWithCurrency(groupPrice, currencyId),
                      price: padPrice(
                        calculatePriceGroupPrice(priceGroup, product.priceSystems),
                      ),
                    }}
                  />
                      + Additional transport fee:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align={'center'}>
                      <Localized
                    dictKey={'shop:screen-product.add-cart.price-per-person.label'}
                    translationOptions={{
                      currencyId,
                      // price: createPriceStringWithCurrency(groupPrice, currencyId),
                      price: padPrice(
                        calculatePriceGroupPrice(priceGroup, product.priceSystems),
                      ),
                    }}
                  />
                      € X.XX (
                      {parsePickupOptionId(participants[0].options.pickup)?.id ||
                        'Invalid id'}
                      )
                    </Typography>
                  </Grid> */}
                </>
              ) : null}
              {orderMinQty && orderMinQty > 1 ? (
                <Grid item xs={12}>
                  <Typography align={'center'}>
                    <em>
                      <Localized
                        dictKey={'shop:screen-product.add-cart.min-order-qty'}
                        translationOptions={{
                          count: orderMinQty,
                        }}
                      />
                    </em>
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* {showParticipantsInfo && participants?.length ? (
        <Grid item xs={12}>
          <ProductTicketGroupParticipants
            saveParticipant={(id, data) => onSaveParticipant(priceGroupId, id, data)}
            participants={participants}
            product={product}
            priceSystemId={priceSystemId}
            // propertiesConfig={[
            //   {
            //     id: 'foodOption',
            //   },
            // ]}
          />
        </Grid>
      ) : null} */}
      <Grid item xs={12}>
        <ProductTicketGroupParticipants
          saveParticipant={(id, data) => onSaveParticipant(priceGroupId, id, data)}
          participants={participants}
          product={product}
          priceSystemId={priceSystemId}
          // FIXME: pass as props
          fieldOptions={fieldOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
