import * as React from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';

export const FieldAdornment = ({ hasError }: { hasError: boolean }) => {
  return (
    <InputAdornment position='end'>
      {hasError ? (
        <AssignmentLateIcon color={'error'} />
      ) : (
        <AssignmentTurnedInIcon color={'primary'} />
      )}

      {/* <IconButton
      aria-label='toggle password visibility'
      // onClick={handleClickShowPassword}
      // onMouseDown={handleMouseDownPassword}
      edge='end'
    >
      {'Butto'}
      {values.showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton> */}
    </InputAdornment>
  );
};
