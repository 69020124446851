import axios, { AxiosRequestConfig } from 'axios';
import * as React from 'react';
import { useLocalizationContext } from 'src/app/context';
import { OpenWeatherMapResponse } from 'src/app/types';

export interface UseOpenWeatherMap {
  getWeatherDataFor: (countryId: string, cityName: string) => void;
  hasError: boolean;
  isFetching: boolean;
  weatherData: OpenWeatherMapResponse | null;
}
const apiKey = '0273dbec14be53fd000fc421b6f3a58b';

// https://openweathermap.org/current#multi
export const useOpenWeatherMap = (): UseOpenWeatherMap => {
  const { language } = useLocalizationContext();
  const [weatherData, setWeatherData] = React.useState<OpenWeatherMapResponse | null>(
    null,
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const fetchData = async (countryId: string, cityName: string) => {
    let isCancelled = false;
    const stateName = ''; // only supported for US states...
    const params: AxiosRequestConfig = {
      params: {
        q: `${cityName},${stateName},${countryId}`,
        appid: apiKey,
        units: 'metric', // FIXME: show imperial for the imperialsts.. (US/Liberia/Myanmar (opt: for Commonwealth countries))
        lang: language,
      },
    };
    const dataUrl = 'https://api.openweathermap.org/data/2.5/weather';
    try {
      if (!isFetching) {
        console.log('Requesting weather data');
        setIsFetching(true);
        const res = await axios.get(dataUrl, params);
        // console.log('got weather data: ', res.data);
        if (!isCancelled) {
          setWeatherData(res.data);
        }
      }
    } catch (err) {
      console.warn('got weather error: ', err);
      if (!isCancelled) {
        setHasError(true);
      }
    } finally {
      if (!isCancelled) {
        setIsFetching(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  };

  const getWeatherDataFor = (countryId: string, cityName: string) => {
    if (!weatherData && !isFetching) {
      // fetchData(countryId, cityName);
    }
  };

  return { hasError, isFetching, getWeatherDataFor, weatherData };
};
