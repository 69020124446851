import { getApp } from 'firebase/app';
import { doc, collection, getFirestore } from 'firebase/firestore';

// NOTE: this doesn't work outside of jsx (in config files etc)
export const createDocumentId = (): string => {
  const firebase = getApp();
  if (!firebase) {
    throw Error('no firebase app');
  }
  return doc(collection(getFirestore(firebase), 'collection-not-used')).id;
};

const escapedCharsRegex = (str: string) =>
  // eslint-disable-next-line no-useless-escape
  str.replace(/[\-\{\}\[\]\/\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

const firestoreFieldpathReplacer = (chars: string[]) => {
  const characterCodeMappings = chars.map(
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );

  const char2Code: Record<string, any> = {};
  const code2Char: Record<string, any> = {};
  chars.forEach((c, i) => {
    char2Code[c] = characterCodeMappings[i];
    code2Char[characterCodeMappings[i]] = c;
  });

  const charRegex = new RegExp(`[${escapedCharsRegex(chars.join(''))}]`, 'g');
  const charCodeRegex = new RegExp(characterCodeMappings.join('|'), 'g');

  const decodeFirestoreFieldpath = (str: string) =>
    str.replace(charCodeRegex, (match) => code2Char[match]);
  const encodeFirestoreFieldpath = (str: string) =>
    str.replace(charRegex, (match) => char2Code[match]);

  return {
    encodeFirestoreFieldpath,
    decodeFirestoreFieldpath,
  };
};

// escape firestore document field paths ".$[]#/%"
const { encodeFirestoreFieldpath, decodeFirestoreFieldpath } = firestoreFieldpathReplacer(
  // '.$[]#/%'.split(''),
  '/'.split(''),
);

export { encodeFirestoreFieldpath, decodeFirestoreFieldpath };
