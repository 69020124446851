import * as React from 'react';
import {
  Button,
  ButtonProps,
  CenteredContainer,
  Grid,
  Typography,
} from 'src/app/components';

// import { useStyles } from 'src/app/context';

interface ButtonWithDescriptionProps extends ButtonProps {
  description?: string | React.ReactNode;
}

export const ButtonWithDescription = ({
  description,
  ...restProps
}: ButtonWithDescriptionProps) => {
  // const styles = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={description ? 6 : 12}>
        <Button fullWidth {...restProps} />
      </Grid>
      {description ? (
        <Grid item xs={6}>
          {typeof description === 'string' ? (
            <CenteredContainer centerV>
              <Typography variant={'body1'}>{description}</Typography>
            </CenteredContainer>
          ) : (
            description
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
