import * as React from 'react';
import { SelectProps } from '@material-ui/core/Select';
import { BaseComponentProps } from 'src/app/types';
import { Select , TextField , MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// type workaround since CombinedBaseComponentProps<TextFieldProps> isn't accepted by TSC
type SelectInputFieldProps = BaseComponentProps &
  SelectProps & { isSingleSelection?: boolean };
// export interface TextInputFieldProps extends CombinedBaseComponentProps<TextFieldProps> {}

// FIXME: has ref isses with forms
/**
 * SelectInputField / SelectInputField
 * @param { SelectInputFieldProps }
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const SelectInputField = (props: SelectInputFieldProps) => {
  const { children, isSingleSelection, ...passProps } = props;
  const onChangeSingleSelectionMode = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    c: React.ReactNode,
  ) => {
    if (
      passProps?.onChange &&
      e?.target?.value &&
      Array.isArray(e.target.value) &&
      e.target.value.length >= 1
    ) {
      // pick only last entry in array
      e.target.value = [e.target.value[e.target.value.length - 1]];
      // TODO: close menu after select click
      passProps.onChange(e, c);
    }
  };
  return (
    <>
      {props.multiple ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={children?.map((item:any)=>
            ({
              'label': item.props.children,
              'id': item.key,
              'value':item.key
          })
          )}
          getOptionLabel={(option:any) => option.label}
          value={props?.value.filter((item1:any) =>  children.some((item2:any) => item2.key === item1))
            .map(item => ({
                label: children?.find((child:any) => child.key === item)?.props.children,
                id: item
            }))
        }
          onChange={((e,selectedArray)=>{
             props.sendDataToAutoComplete(selectedArray.map((item:any)=>item?.id?item.id:item))}
             )}
          MenuProps={{
            backgroundColor: 'red',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={props.label}
            />     
          )}
          getOptionSelected={(option,value) =>                                               
           option.id === value.id
        }      
        >
          {children}
        </Autocomplete>
      ) : (
        <Select
          {...passProps}
          onChange={isSingleSelection ? onChangeSingleSelectionMode : passProps.onChange}
          MenuProps={{
            backgroundColor: 'red',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {children}
        </Select>
      )}
    </>
  );
};

export { SelectInputField };
