import * as React from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import {
  collection,
  CollectionReference,
  limit,
  query,
  QueryConstraint,
} from 'firebase/firestore';

export interface FirestoreCollectionFetchProps<T> {
  collectionPath: string;
  onError: () => void;
  onSuccess: (data: T[]) => void;
  queryConstraints?: QueryConstraint[];
}

export const FirestoreCollectionFetch = <T extends Record<string, any>>({
  collectionPath,
  onError,
  onSuccess,
  queryConstraints,
}: FirestoreCollectionFetchProps<T>) => {
  const firestore = useFirestore();

  // const collectionRef = collection(firestore, collectionPath) as CollectionReference<T>;

  const defaultQueryParams = [limit(8)]; // NOTE: default fetch document amount

  const constraints = queryConstraints ? [...queryConstraints] : [...defaultQueryParams]; // Fallback constraints

  const collectionRef = collection(firestore, collectionPath) as CollectionReference<T>;

  // TODO: append query params optionally

  const [hasFetched, setHasFetched] = React.useState(false);
  const [currentData, setCurrentData] = React.useState<T[] | null>(null);

  const fetchOptions = {
    initialData: null,
    // suspense
  };

  const { status, data } = useFirestoreCollectionData<T>(
    query(collectionRef, ...constraints),
    fetchOptions,
  );
  const isDataAltered =
    !!currentData && JSON.stringify(currentData) !== JSON.stringify(data); // TODO: better deep comparison needed

  React.useEffect(() => {
    if (status === 'loading') {
      //
    }
    if (status === 'error' && onError !== undefined && !hasFetched) {
      onError();
      setHasFetched(true);
    }
    if (
      status === 'success' &&
      onSuccess !== undefined &&
      data &&
      (!hasFetched || (hasFetched && isDataAltered))
    ) {
      onSuccess(data);
      setCurrentData(data);
      setHasFetched(true);
    }
  }, [data, hasFetched, onError, onSuccess, status, isDataAltered]);

  return null;
};
