import * as React from 'react';
import { LocalStorageCart, ShoppingCartItemConfiguration } from 'src/app/types';
import { LocalStorageKey } from 'src/app/constants';

export const useLocalStorageCart = () => {
  const [localCart, setLocalCard] = React.useState<LocalStorageCart>({ cart: [] });

  const currentLocalStorageCartString =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(LocalStorageKey.SHOPPING_CART) || '{"cart":[]}'
      : '';

  React.useEffect(() => {
    if (currentLocalStorageCartString) {
      const currentLocalStorageCart: LocalStorageCart = JSON.parse(
        currentLocalStorageCartString,
      );
      if (currentLocalStorageCart?.cart?.length) {
        setLocalCard(currentLocalStorageCart);
      }
    }
  }, [currentLocalStorageCartString]);

  const onLocalCartItemRemove = (documentId: string) => {
    const currentLocalStorageCart: LocalStorageCart = JSON.parse(
      currentLocalStorageCartString,
    );
    if (
      currentLocalStorageCart?.cart &&
      Array.isArray(currentLocalStorageCart.cart) &&
      currentLocalStorageCart.cart.length
    ) {
      const updateCart = currentLocalStorageCart.cart.filter(
        (item) => item.id !== documentId,
      );
      window.localStorage.setItem(
        LocalStorageKey.SHOPPING_CART,
        JSON.stringify({ cart: updateCart }),
      );
      setLocalCard({ cart: updateCart });
    }
  };

  const onLocalCartItemUpdate = (
    documentId: string,
    data: ShoppingCartItemConfiguration[],
  ) => {
    const currentLocalStorageCart: LocalStorageCart = JSON.parse(
      currentLocalStorageCartString,
    );

    if (
      currentLocalStorageCart?.cart &&
      Array.isArray(currentLocalStorageCart.cart) &&
      currentLocalStorageCart.cart.length
    ) {
      const cartItemToUpdate = currentLocalStorageCart.cart.find(
        (item) => item.id === documentId,
      );
      if (cartItemToUpdate) {
        cartItemToUpdate.itemConfigurations = data;
      }
      const unmodifiedCartItems = currentLocalStorageCart.cart.filter(
        (item) => item.id !== documentId,
      );
      window.localStorage.setItem(
        LocalStorageKey.SHOPPING_CART,
        JSON.stringify(
          cartItemToUpdate
            ? { cart: [...unmodifiedCartItems, cartItemToUpdate] }
            : { cart: [...unmodifiedCartItems] },
        ),
      );
      setLocalCard(
        cartItemToUpdate
          ? { cart: [...unmodifiedCartItems, cartItemToUpdate] }
          : { cart: [...unmodifiedCartItems] },
      );
    }
  };

  return { localCart, onLocalCartItemRemove, onLocalCartItemUpdate };
};
