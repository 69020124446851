import { useTranslation } from 'react-i18next';

import { useLocalizationContext } from 'src/app/context';
import { getCIModeKeysFromI18nArray } from 'src/app/lib';

// TODO: improve by providing default id per item, even if none is saved inside of the object (=> for renderKeys)

/** Provides logic for displaying dictKeys in ci mode */
export const useI18nArray = <T extends string | Record<string, any>>(
  dictKey: string,
  arrObjPropNamesForCiDebug?: string[],
) => {
  const { i18n } = useTranslation();
  const { language } = useLocalizationContext();

  const getFixedT = i18n.getFixedT(language === 'cimode' ? 'en' : null); // if cimode use en, otherwise use i18n current locale

  let i18nItems: T[] =
    getFixedT(dictKey, '', {
      // returnObjects: !!arrObjPropNames?.length, // consider passing this setting through params
      returnObjects: true,
    }) || [];

  if (language === 'cimode') {
    i18nItems = getCIModeKeysFromI18nArray<T>(
      dictKey,
      i18nItems,
      arrObjPropNamesForCiDebug,
    );
  }

  return { i18nItems };
};
