import * as React from 'react';
import styled from 'styled-components';
import MTextField from '@material-ui/core/TextField';
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import { Grid, Localized } from 'src/app/components';
import { DatePickerType } from 'src/app/constants';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';
// import { ThemeMode } from 'src/app.consumer/constants';
import dayjs from 'dayjs';

const StyledTextField = styled(MTextField)`
  label {
    // color: #ff9800;
  }
  label.focused {
    // color: #ff9800;
  }
  .MuiOutlinedInput-root {
    fieldset {
      // border-color: #ff9800;
    }
    &:hover fieldset {
      // border-color: #ff9800;
    }
    &.Mui-focused fieldset {
      // border-color: #ff9800;
    }
  }
  .MuiFilledInput-root {
    color: #fff;
    background-color: #eee;
    background-color: '#222';
    border-color: #fff;
    fieldset {
      color: #ff9800;
      // background-color: #ff9800;
      // border-color: #ff9800;
    }
    &:hover fieldset {
      // border-color: #ff9800;
    }
    &.Mui-focused fieldset {
      // border-color: #ff9800;
    }
  }
  .MuiInputBase-input {
    // color: #ff9800;
    font-weight: bold;
  }

  .MuiSvgIcon-root {
    // color: #ff9800;
    font-weight: bold;
  }
`;

export interface DatePickerProps extends BaseComponentProps, KeyboardDateTimePickerProps {
  selectedDate: MaterialUiPickersDate;
  setDate: (date: MaterialUiPickersDate) => void;
  type: DatePickerType;
}

const DatePicker = (props: DatePickerProps) => {
  const { selectedDate, setDate, type, ...restProps } = props;
  let labelDictId = ''; // TODO: create default/fallback translation
  let views = ['year', 'month', 'date'];
  let format = 'DD-MM-YYYY';
  let defaultValue = dayjs();
  switch (type) {
    case DatePickerType.FROM:
      labelDictId = 'common:date-picker.placeholder.from';
      break;
    case DatePickerType.TO:
      labelDictId = 'common:date-picker.placeholder.to';
      break;
    case DatePickerType.DAY:
      labelDictId = 'common:date-picker.placeholder.day';
      break;
    case DatePickerType.TIME:
      defaultValue = dayjs().set('hour', 11).set('minute', 47);
      labelDictId = 'common:date-picker.placeholder.time';
      views = ['hours', 'minutes'];
      format = 'HH:mm';
      break;
    case DatePickerType.DATE:
    default:
      labelDictId = 'common:date-picker.placeholder.date';
      break;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <KeyboardDateTimePicker
          // clearable
          // clearLabel={'Clear'}
          // cancelLabel={'Cancel'}
          // helperText={'Foo'}
          value={selectedDate}
          onChange={setDate}
          fullWidth
          disablePast
          size={'small'}
          label={<Localized dictKey={labelDictId} />}
          // anchorEl={}
          // inputRef={props.ref}  => problems with inline variant
          disableToolbar
          // openTo='day'
          autoOk
          ampm={false}
          // ampm={!de} // => so.com
          views={views}
          variant='inline' // inline | dialog
          inputVariant='outlined'
          format={format}
          // defaultValue={selectedDate || defaultValue}
          InputAdornmentProps={{ position: 'end', color: 'primary' }}
          TextFieldComponent={(tfProps) => <StyledTextField {...tfProps} />}
          minutesStep={5}
          // showTodayButton
          // todayLabel='now'
          // InputProps={}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          // InputProps={{
          //   step: 300, // 5 min
          // }}
          {...restProps}
        />
      </Grid>
    </Grid>
  );
};

const WAPDatePicker = (props: CombinedAppProps<DatePickerProps>) =>
  withoutAppProps<CombinedAppProps<DatePickerProps>, DatePickerProps>(DatePicker, props);

export { DatePicker, WAPDatePicker };
