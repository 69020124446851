import * as React from 'react';
import { getAuth, updateEmail } from 'firebase/auth';

import { useAppContext } from 'src/app/hooks';
import {
  Button,
  Grid,
  LoadingIndicator,
  TextInputField,
  Typography,
} from 'src/app/components';

interface UserChangeEmailProps {
  onSuccess: () => void;
  onFailure: (error: any) => void;
}

export const UserChangeEmail = ({ onSuccess, onFailure }: UserChangeEmailProps) => {
  const { firebase, user } = useAppContext();
  const [emailInput, setEmailInput] = React.useState('');
  const [emailConfirmInput, setEmailConfirmInput] = React.useState('');
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const onSubmit = async () => {
    try {
      if (!firebase || !user?.email || !emailInput || emailInput !== emailConfirmInput) {
        return null;
      }

      setIsFetching(true);

      const auth = getAuth(firebase);
      if (!auth.currentUser) {
        return null;
      }
      await updateEmail(auth.currentUser, emailInput);
      onSuccess();
    } catch (err) {
      console.warn(err);
      onFailure(err);
    } finally {
      setIsFetching(false);
    }
    return null;
  };
  return !isFetching ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{'Please enter the new email address'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <TextInputField
              fullWidth
              variant={'outlined'}
              label={'Email'}
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <TextInputField
              fullWidth
              variant={'outlined'}
              label={'Email confirmation'}
              value={emailConfirmInput}
              onChange={(e) => {
                setEmailConfirmInput(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onSubmit}>OK</Button>
      </Grid>
    </Grid>
  ) : (
    <LoadingIndicator />
  );
};
