/* eslint-disable no-nested-ternary */
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PickupIcon from '@material-ui/icons/DirectionsBusOutlined';
import FoodAvailableIcon from '@material-ui/icons/Restaurant';
import GroupBookingIcon from '@material-ui/icons/GroupWork';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import * as React from 'react';
import { Grid, Localized, Typography, ButtonTw, ProductTags } from 'src/app/components';
import { ProductCategoryType, BookingSystemType } from 'src/app/constants';
import { SourcedGQLProduct } from 'src/app/types';

import { useAppContext, useLocalizationContext, useStyles } from 'src/app/context';
import { getBaseDocumentLocales } from 'src/lib/document';
import {
  calculatePriceFromPriceString,
  euroPriceAsCurrency,
  getBasePriceByFeatureEnabled,
  padPrice,
} from 'src/lib/price';

export const ProductListItemContent = <T extends ProductCategoryType>(
  props: SourcedGQLProduct,
) => {
  const { availability, priceSystems, pickup, foodOptions } = props;
  const style = useStyles();
  const { isAppDebug, currencyId } = useAppContext();
  const { language } = useLocalizationContext();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);

  const bookingSystemId = 'publicBooking'; // TODO:
 
  // FIXME:
  const pricing  = priceSystems?.[0] || []
  
  const priceDetails =getBasePriceByFeatureEnabled(pricing)
  const rawPrice = priceDetails?.basePrice
  const priceType :any = priceDetails?.priceType
  let bookingSystemType=BookingSystemType.PUBLIC;
  let max_num=0;
  if(priceType === BookingSystemType.PRIVATE){
    bookingSystemType=BookingSystemType.PRIVATE
    max_num=priceSystems?.[0]?.privateBooking?.maxParticipants;
  }
  const hasDiscount = true; // FIXME: calculate
  const priceBeforeDiscount = rawPrice * 2 || ''; // TODO: dummy
  const exactDuration = availability?.duration || 0;

  // TODO: GIT => issue
  const hasFreeCancellation = true;
  const hasPrivateBookings = priceSystems?.[0]?.privateBooking?.featureEnabled || false;
  const hasFoodOptions = foodOptions?.featureEnabled || false;

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO: switch based on <T>

  const i18nPrefix = 'shop:product-list-item';
  const categoryType = 'activity-000';

  const price = calculatePriceFromPriceString(rawPrice);

  const { name, title, teaser } = getBaseDocumentLocales(props, language, isAppDebug); // issue with name and title dual use
  return (
    <div className='flex flex-col p-4 h-full md:p-9 lg:flex-row md:gap-8'>
      <div className='flex flex-1 flex-col text-left lg:w-7/12 xl:w-8/12'>
        <h3 className='text-p1 font-semibold md:text-h3 md:font-bold'>{name ?? ''}</h3>

        {/* <p className='text-p2 md:text-p1'>{teaser ?? ''}</p> */}

        {props.meta?.tagIds !== undefined ? (
          <div className='py-2'>
            <ProductTags product={props} />
          </div>
        ) : null}

        <div className='text-p3 py-3 flex flex-col gap-1'>
          <p className='flex gap-2'>
            <span className='text-accent-500'>
              <TimelapseIcon fontSize={'small'} className='text-accent' />
            </span>
            <span className='text-p3'>
              <Localized
                dictKey={`${i18nPrefix}.${categoryType}.duration.label`}
                className='pr-1'
              />

              {exactDuration < 60 ? (
                <Localized
                  dictKey={'common:format-duration.minute-format'}
                  translationOptions={{ minutes: exactDuration }}
                />
              ) : exactDuration < 60 * 24 ? (
                <Localized
                  dictKey={'common:format-duration.hour-format'}
                  translationOptions={{
                    hours: Math.floor(exactDuration / 60) || 0,
                    minutes: exactDuration % 60 || 0,
                  }}
                />
              ) : (
                <Localized
                  dictKey={'common:format-duration.day-format'}
                  translationOptions={{
                    days: Math.floor(exactDuration / (60 * 24)) || 0,
                    hours: Math.floor(((exactDuration % 60) * 24 || 0) / 60) || 0,
                    minutes: ((exactDuration % 60) * 24 || 0) % 60 || 0,
                  }}
                />
              )}
            </span>
          </p>

          {hasFoodOptions ? (
            <p className='flex gap-2'>
              <span className='text-accent-500'>
                <FoodAvailableIcon fontSize={'small'} />
              </span>
              <span>
                <Localized
                  dictKey={`${i18nPrefix}.${categoryType}.feature.food-available.label`}
                />
              </span>
            </p>
          ) : null}

          {pickup?.hasPickupService ? (
            <p className='flex gap-2'>
              <span className='text-accent-500'>
                <PickupIcon fontSize={'small'} />
              </span>
              <span>
                <Localized
                  dictKey={`${i18nPrefix}.${categoryType}.feature.pickup.label`}
                />
              </span>
            </p>
          ) : null}

          {hasPrivateBookings ? (
            <p className='flex gap-2'>
              <span className='text-accent-500'>
                <GroupBookingIcon fontSize={'small'} />
              </span>
              <span>
                <Localized
                  dictKey={`${i18nPrefix}.${categoryType}.feature.private-booking.label`}
                />
              </span>
            </p>
          ) : null}
        </div>
      </div>

      <div className='flex flex-col gap-4 items-start lg:justify-end lg:items-center lg:w-5/12 xl:w-4/12'>
        {/* Price Box */}
        <div className='flex flex-row md:flex-col gap-1 md:items-center gap-0 '>
        <span className='flex items-baseline  '>
          <span className='text-p3'>
            <Localized dictKey={`${i18nPrefix}.${categoryType}.from.label`} />
          </span>

            {/* <Grid item xs={12}> // TODO: temporal discount
            {hasDiscount ? (
              <Typography variant={'body2'} align={'right'}>
                <strike>€ {priceBeforeDiscount}</strike>
              </Typography>
            ) : (
              ''
            )}
          </Grid> */}
          <span className='text-p3 pl-2 font-bold md:text-h2'>
             <Localized
              dictKey={euroPriceAsCurrency(price, currencyId).decimal===0?`${i18nPrefix}.${categoryType}.price.integerFormat`:`${i18nPrefix}.${categoryType}.price.format`}
              translationOptions={{
              price: euroPriceAsCurrency(price, currencyId),
              currencyId,
              }}
             />
           </span>
          </span>

        <span className='text-p3 pl-2 md:text-right '>
          {bookingSystemType === BookingSystemType.PRIVATE ? (
            isMobile ? (
            <Localized
             dictKey={`${i18nPrefix}.${categoryType}.per-group.labelMobile`}
               translationOptions={{
               max_num: max_num,
               }}
            />
          ) : (
           <Localized
             dictKey={`${i18nPrefix}.${categoryType}.per-group.label`}
             translationOptions={{
             max_num: max_num,
             }}
            />
         )
        ) : (
         <Localized dictKey={`${i18nPrefix}.${categoryType}.per-person.label`} />
         )}
        </span>

       </div>
        {/* / Price Box */}

        <div className='w-full hidden text-left md:inline-block lg:block lg:text-center'>
          <ButtonTw
            variant={'accent'}
            size={'lg'}
            rounded={'rounded'}
            className='lg:w-full'
          >
            Check availability
          </ButtonTw>
        </div>

        {hasFreeCancellation ? (
          <strong>
            <Localized
              dictKey={`${i18nPrefix}.${categoryType}.feature.free-cancellation.label`}
            />
          </strong>
        ) : null}
      </div>
    </div>
  );
};
