import * as React from 'react';

import { BaseComponentProps, SourceProduct } from 'src/app/types';
import { ProductCategoryType } from 'src/app/constants';

import { Grid, Glass, LoadingIndicator, Typography } from 'src/app/components';
import { FirestoreDocData } from 'src/app/container';
import { ProductContainer } from '../productContainer';

export interface ProductFetchContainerProps extends BaseComponentProps {
  params: { id: string };
}

/**
 * ProductContainer
 * @param { ProductContainerProps } props
 * @version 0.0.0
 * @description
 * - Fetches SourceProduct document from 'product' collection
 * - Renders SourceProduct by ProductType
 * @todo
 * @example
 */
const ProductFetchContainer = (props: ProductFetchContainerProps) => {
  const { params } = props;

  if (!params?.id) {
    return null;
  }

  return (
    <FirestoreDocData<ProductCategoryType.ACTIVITY> // SourceProduct
      documentPath={`product/${params.id}`}
      renderElements={({ data: product, status }) => {
        if (status === 'loading') {
          return <LoadingIndicator />;
        }
        if (status === 'error') {
          return 'Error';
          // <LocalizedErrorIndicator
          //   i18nContext={'organisation'}
          //   i18nErrorCode={'LOADING_DOCUMENT'}
          //   i18nErrorAreaCode={'organisation'}
          // />
        }
        return (
          <Grid container>
            <Grid item xs={12}>
              {product ? <ProductContainer product={product} /> : null}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export { ProductFetchContainer };
