import { TopicNode } from 'src/app/types';
import { TopicNodeType } from 'src/app/constants';

// import {
//   HowToBookAnActivity,
//   Availability,
//   PricingLogic,
//   SuitableActivities,
// } from './searchAndBook';

// const searchAndBookTopics = [
//   {
//     id: 'how-to-book-an-activity',
//     type: TopicNodeType.ACCORDION_NODE,
//     children: [
//       {
//         // id: 'Topic child id 2 1',
//         type: TopicNodeType.CONTENT_NODE,
//         content: [], // FIXME: use context prop for content_NODE
//         children: [HowToBookAnActivity],
//       },
//     ],
//   },
//   {
//     id: 'availability',
//     type: TopicNodeType.ACCORDION_NODE,
//     children: [
//       {
//         // id: 'Topic child id 2 1',
//         type: TopicNodeType.CONTENT_NODE,
//         content: [], // FIXME: use context prop for content_NODE
//         children: [Availability],
//       },
//     ],
//   },
//   {
//     id: 'pricing-logic',
//     type: TopicNodeType.ACCORDION_NODE,
//     children: [
//       {
//         // id: 'Topic child id 2 1',
//         type: TopicNodeType.CONTENT_NODE,
//         content: [], // FIXME: use context prop for content_NODE
//         children: [PricingLogic],
//       },
//     ],
//   },
//   {
//     id: 'suitable-activities',
//     type: TopicNodeType.ACCORDION_NODE,
//     children: [
//       {
//         // id: 'Topic child id 2 1',
//         type: TopicNodeType.CONTENT_NODE,
//         content: [], // FIXME: use context prop for content_NODE
//         children: [SuitableActivities],
//       },
//     ],
//   },
// ];

// const activityInformationTopics = [];

// const onTheDayOfTheActivityTopics = [];

// const meetingPointAndPickupTopics = [];

// const cancellationTopics = [];

// const cancellationByLocalPartnerTopics = [];

export const organisationHelpTopics: TopicNode[] = [
  // {
  //   id: 'search-and-book',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...searchAndBookTopics],
  // },
  // {
  //   id: 'activity-information',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...activityInformationTopics],
  // },
  // {
  //   id: 'on-the-day-of-the-activity',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...onTheDayOfTheActivityTopics],
  // },
  // {
  //   id: 'meeting-point-and-pickup',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...meetingPointAndPickupTopics],
  // },
  // {
  //   id: 'cancellation',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...cancellationTopics],
  // },
  // {
  //   id: 'cancellation-by-local-partner',
  //   type: TopicNodeType.ACCORDION_NODE,
  //   children: [...cancellationByLocalPartnerTopics],
  // },
];
