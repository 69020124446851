import * as React from 'react';
import { BaseDocumentData, BaseLocalization, GatsbyImageData } from 'src/app/types';

import { ButtonTw, GatsbyImage } from 'src/app/components';

export interface TileButtonProps {
  altImageLabel: string; // TODO: get from item data + create inside of admin/system or admin/partner/product
  childCount: number;
  data: BaseDocumentData & GatsbyImageData;
  localization: BaseLocalization;
  onClick: (e: any, type: string, id: string, subId?: string) => void;
  type: 'category' | 'categoryType' | 'city'; // TODO: use const
}

// TODO: move to enduser app components
// TODO: labels should work both for categories/categoryTypes and cities
// TODO: i18n
export const TileButton = (props: TileButtonProps) => {
  const { altImageLabel, childCount, data, localization, onClick, type } = props;

  if (type === 'category') {
    return (
      <ButtonTw
        fullWidth
        onClick={(e) =>
          data.meta?.categoryTypeIds?.[0]
            ? onClick(e, type, data.id, data.meta.categoryTypeIds[0]) // FIXME: only working with categories with 1 categoryType set
            : onClick(e, type, data.id)
        }
        variant={'text'}
        className={'w-full group'}
      >
        {data?.imageFiles?.[0]?.childImageSharp ? (
          <GatsbyImage
            // key={selectedImageIdx} // for blur reload effect
            // className={clsx(
            //   styles.itemCard__media,
            //   !isWiderMD ? styles.itemCard__mediaSM : {},
            // )}
            // style={{
            //   aspectRatio: '5 / 4',
            // }}
            className={
              'rounded-lg shadow-box-light dark:shadow-box-dark aspect-square md:aspect-landscape'
            }
            imageFile={data.imageFiles[0]}
            // fluid={city.imageFiles[0].childImageSharp.fluid}
            alt={altImageLabel}
          >
            <p className='text-p3 font-normal py-3 md:font-medium md:text-p2 group-hover:text-accent-500 duration-300'>
              {/*childCount */}
              {/* <Localized dictKey={`country:${countryId}.city.${city.id}.description`} /> */}
              {localization?.locales?.name || ''}
            </p>

            {true ? null : (
              <p className='text-p3 font-normal text-center py-3 md:font-medium md:text-p2 group-hover:text-accent-500 duration-300'>
                Discover {childCount}
                {/* <Localized dictKey={`country:${countryId}.city.${city.id}.description`} /> */}
                in
                {localization?.locales?.name || ''}
                {localization?.locales?.teaser || ''}
              </p>
            )}
          </GatsbyImage>
        ) : null}
      </ButtonTw>
    );
  }
  return (
    <ButtonTw
      fullWidth
      onClick={(e) =>
        data.meta?.categoryTypeIds?.[0]
          ? onClick(e, type, data.id, data.meta.categoryTypeIds[0]) // FIXME: only working with categories with 1 categoryType set
          : onClick(e, type, data.id)
      }
      variant={'text'}
      className={'w-full group'}
    >
      {data?.imageFiles?.[0]?.childImageSharp ? (
        <GatsbyImage
          // key={selectedImageIdx} // for blur reload effect
          // className={clsx(
          //   styles.itemCard__media,
          //   !isWiderMD ? styles.itemCard__mediaSM : {},
          // )}
          // style={{
          //   aspectRatio: '5 / 4',
          // }}
          imageFile={data.imageFiles[0]}
          // fluid={city.imageFiles[0].childImageSharp.fluid}
          alt={altImageLabel}
          className={
            'rounded-lg shadow-box-light dark:shadow-box-dark aspect-square md:aspect-landscape'
          }
        >
          <p className='text-p3 font-normal text-center items-center justify-center py-3 flex flex-col flex-wrap gap-1 md:flex-row md:font-medium md:text-p2 group-hover:text-accent-600 duration-300'>
            <span>
              {childCount} activities
              {/* <Localized dictKey={`country:${countryId}.city.${city.id}.description`} /> */}{' '}
              in{' '}
            </span>
            <strong className='text-p2'>
              {localization?.locales?.name || ''}
              {localization?.locales?.teaser || ''}
            </strong>
          </p>
        </GatsbyImage>
      ) : null}
    </ButtonTw>
  );
};
