import * as React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TopicNode } from 'src/app/types';
import { TopicNodeType } from 'src/app/constants';
import { Accordion, Localized } from 'src/app/components';

interface HelpTopicContainerProps {
  dictKey: string;
  topicData: TopicNode;
}

export const HelpTopicContainer = ({ dictKey, topicData }: HelpTopicContainerProps) => {
  return topicData.type === TopicNodeType.ACCORDION_NODE ? (
    <>
      <Accordion
        //style={{ borderWidth: '0', width: '100%' }}
        className='!my-2'
        expandIcon={<ExpandMoreIcon />}
        summary={
            <Localized dictKey={`${dictKey}.${topicData.id}.title`} />
        }
      >
        <div className='ml-2 w-full'>
          {topicData.children?.length
            ? topicData.children.map((topic, idx) => {
                return (
                  <div key={idx} className='accordion--child'>
                    <HelpTopicContainer dictKey={dictKey} topicData={topic} />
                  </div>
                );
              })
            : null}
        </div>
      </Accordion>
    </>
  ) : (
    <>
      {topicData.children?.length
        ? topicData.children.map((topic, idx) => {
            return (
              <div key={idx}>
                {topic()}
              </div>
            );
          })
        : null}
    </>
  );
};
