import * as React from 'react';
import { useCookies } from 'react-cookie';
import { GDPRSettings, GDPRState } from 'src/app/types';

const gdprCookieName = 'gdpr_set';
// TODO: maybe import cookie settings from BE
function useGDPRState(): GDPRState {
  const [cookies, setCookie] = useCookies([gdprCookieName]);
  const [isGDPRSet, setIsGDPRSet] = React.useState<boolean>(cookies[gdprCookieName]);
  const isGDPRCookieSet = cookies[gdprCookieName];
  React.useEffect(() => {
    if (isGDPRCookieSet) {
      setIsGDPRSet(true);
    }
  }, [isGDPRCookieSet]);

  // FIXME: set only permitted cookies
  const setGDPRSettings = (gdprSettings: GDPRSettings) => {
    if (gdprSettings?.configs?.length) {
      for (let settingIdx = 0; settingIdx < gdprSettings.configs.length; settingIdx++) {
        const { id, value, options } = gdprSettings.configs[settingIdx];
        setCookie(id, value, options || { path: '/' });
      }
    }
    setCookie(gdprCookieName, true, { path: '/' });
  };

  return { isGDPRSet, setGDPRSettings };
}

export { useGDPRState };
