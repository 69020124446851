import * as React from 'react';

import { Typography } from 'src/app/components';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  return (
    <div role='alert'>
      <Typography variant={'body1'}>Something went wrong:</Typography>
      <pre>{error?.message || ''}</pre>
      <button type={'button'} onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
};
