import { AppLocale } from 'src/app/constants';
import { SourcedGQLProduct } from 'src/app/types';

export const getLanguageOptionNameById = (
  languageOptionId: string,
  product: SourcedGQLProduct,
  language: AppLocale,
  // showSource?: boolean,
) => {
  // TODO:
  // return (
  //   product?.localizations
  //     ?.find((l) => l.localeId === language)
  //     ?.food?.find((f) => f.id === pickupOptionId)?.title || pickupOptionId
  // );
  return languageOptionId;
};

// TODO:
export const createProductLanguageOptions = (
  product: SourcedGQLProduct,
  priceSystemId: string,
): { id: string }[] => {
  const lng: { id: string }[] = [];
  product.localizations.forEach((l) => {
    lng.push({ id: l.localeId });
  });
  // TODO: (copy from food.lib.ts)
  // const priceSystem = product?.priceSystems.find((ps) => ps.id === priceSystemId);

  // return priceSystem?.priceSystemFoodOptions?.featureEnabled &&
  //   priceSystem.priceSystemFoodOptions.foodOptions?.length
  //   ? [
  //       ...priceSystem.priceSystemFoodOptions.foodOptions
  //         .filter((foodOption) => !!foodOption.foodId)
  //         .map((foodOption) => ({
  //           id: foodOption.foodId || '', // fallback piping only for TS since already filtered out
  //         })),
  //     ]
  //   : [];
  return lng;
};
