import * as React from 'react';
import clsx from 'clsx';
import { useLocalizationContext, useStyleContext, useStyles } from 'src/app/context';

import {
  Button,
  ButtonGroup,
  DropDown,
  Glass,
  Grid,
  Localized,
  TextInputField,
  Typography,
} from 'src/app/components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import {
  CombinedAppProps,
  BaseComponentProps,
  BaseLocalizedDocumentData,
} from 'src/app/types';
import { ThemeMode } from 'src/app.consumer/constants';

export interface SearchFilterProps extends BaseComponentProps {
  filterTypeId: string; // eg city, category etc
  filterOptions: BaseLocalizedDocumentData[];
  selectedFilterId: string; // eg hr-split-000, hike_tour
  setFilter: (filterId: string) => void;
  // themeMode: ThemeMode; NOTE: not used atm
  placeholderLabel: string | React.ReactNode;
}

/**
 * SearchFilter / WAPSearchFilter
 * @param { SearchFilterProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const SearchFilter = <T extends string>(props: SearchFilterProps) => {
  const {
    filterTypeId,
    filterOptions,
    placeholderLabel,
    selectedFilterId,
    setFilter,
    // themeMode,
  } = props;
  const styles = useStyles();
  const { language } = useLocalizationContext();

  const countryId = 'hr';
  const productType = 'activity-000';
  let localizePrefix = '';
  let localizeFilterCollectionPrefix = '';
  if (filterTypeId === 'city') {
    localizePrefix = `country:${countryId}`;
    localizeFilterCollectionPrefix = `${localizePrefix}.city`;
  }
  if (filterTypeId === 'category') {
    localizePrefix = `product:${productType}`;
    localizeFilterCollectionPrefix = `${localizePrefix}.category`;
  }
  // console.log('filterOptions: ', filterOptions);

  const useDarkTheme = true;

  const limit = 10;

  // FIXME: localize
  const filterOptionsLocalized = filterOptions.map((optionId) => {
    if (optionId.localizations.length) {
      const resLocale = optionId.localizations.find(
        (el) => el.localeId === language || 'en',
      );
      if (resLocale) {
        return { searchTerm: resLocale?.locales?.name || '', id: optionId.id };
      }
    }
    return '';
  });

  const [activeOptionIdx, setActiveOptionIdx] = React.useState<number>(0);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<T[]>([...filterOptions]);
  const [userInput, setUserInput] = React.useState<string>('');

  const [optionsLocalized, setOptionsLocalized] = React.useState<
    { searchTerm: string; id: string }[]
  >([...filterOptionsLocalized]);
  const [filteredOptions, setFilteredOptions] = React.useState<
    { searchTerm: string; id: string }[]
  >([]);

  const onDropDownClick = () => {};

  // console.log('filteredOptions: ', filteredOptions);

  const onInputChange = (e: React.KeyboardEvent) => {
    const input = e.currentTarget.value;

    const filteredOptions: { searchTerm: string; id: string }[] =
      optionsLocalized.filter(
        (option) => option.searchTerm.toLowerCase().indexOf(input.toLowerCase()) > -1,
      ) || [];

    setActiveOptionIdx(0);
    setFilteredOptions(filteredOptions);
    setShowOptions(true);
    setUserInput(e.currentTarget.value);

    console.log('onInputChange e: ', e);

    // this.setState({
    //   activeOption: 0,
    //   filteredOptions,
    //   showOptions: true,
    //   userInput: e.currentTarget.value,
    // });
  };

  const onOptionClick = (e: number, id: string) => {
    setActiveOptionIdx(0);
    setFilteredOptions([]);
    setShowOptions(false);
    setUserInput(e.currentTarget.innerText);
    const res = filterOptions.find((fo) => fo.id === id) || null;
    if (res) {
      setFilter(res.id);
    }
  };

  const onInputKeyDown = (e: React.KeyboardEvent) => {
    // enter
    if (e.keyCode === 13) {
      setActiveOptionIdx(0);
      setShowOptions(false);
      setUserInput(filteredOptions[activeOptionIdx].searchTerm);

      // arrow up
    } else if (e.keyCode === 38) {
      if (activeOptionIdx === 0) {
        return;
      }
      setActiveOptionIdx(activeOptionIdx - 1); // limit

      // arrow down
    } else if (e.keyCode === 40) {
      if (activeOptionIdx - 1 === filteredOptions.length) {
        return;
      }
      setActiveOptionIdx(activeOptionIdx + 1); // limit
    }
  };

  let optionsListComponent = null;

  if (showOptions && userInput) {
    if (filteredOptions.length) {
      optionsListComponent = (
        <div className='dark:bg-neutral-800 bg-white absolute top-auto z-10'>
          <Grid container>
            {filteredOptions.map((option, index) => {
              let className;

              if (index === activeOptionIdx) {
                className = 'option-active';
              }

              if (index > limit) {
                return null;
              }

              // console.log('option: ', option);

              return (
                <Grid item xs={12} className={className} key={option.id}>
                  <Button
                    variant={'text'}
                    fullWidth
                    onClick={(e) =>
                      onOptionClick(e, option.id)
                    } /* onClick={() => onOptionClick(index)} */
                  >
                    <div
                      style={{ width: '100%', padding: '4px 8px', borderRadius: '8px' }}
                    >
                      <Grid container>
                        <Grid item xs={12} className={className}>
                          <Typography align={'left'} variant={'body1'} color={'primary'}>
                            {option.searchTerm}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    } else {
      optionsListComponent = (
        <div className='text-neutral-900 dark:text-neutral-50'>
          <em>
            <Localized
              dictKey={'common:filter.format-result-count'}
              translationOptions={{ count: 0 }}
            />
          </em>
        </div>
      );
    }
  }
  // return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        {/** use toolBar here? */}
        <TextInputField
          // classes={{ root: styles.inputTextField }}
          // className={clsx(styles.searchFilter, styles.inputTextField)}
          variant={'outlined'}
          color={'primary'}
          label={placeholderLabel || ''}
          // label={<Localized dictKey={'shop:screen-newsletter.input-email.label'} />}
          fullWidth
          // labelClassName={cn.placeholder}
          InputLabelProps={{
            className: styles.searchBoxInputColor,
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              // overflow: 'hidden',
              width: '100%',
              // color: '#ffa500dd',
            },
          }}
          size={'small'}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          value={userInput}
          className={'rounded-xl bg-neutral-100 dark:bg-neutral-800'}
          InputProps={{
            disableUnderline: true,
            style: {
              // color: '#ffa500dd',
            },
            className: styles.searchBoxInputColor,
            classes: {
              input: '!h-12 md:!h-14 !box-border',
              // focused: classes.textField__focused,
              // notchedOutline: classes.textField__notchedOutline,
            },
          }}
        />
        {/* <ButtonGroup fullWidth>
          <Button
            onClick={onDropDownClick}
            variant={'outlined'}
            color={'primary'}
            style={{ maxWidth: '64px' }}
          >
            <ArrowDropDownIcon
              style={
                useDarkTheme
                  ? {
                      color: '#ffa500dd',
                      // color: '#fff',
                    }
                  : { color: '#222' }
              }
            />
          </Button>
        </ButtonGroup> */}
        {optionsListComponent}
      </Grid>
      {/* <Grid item xs={10}>
        {selectedFilterId ? (
          <Typography>
            <Localized dictKey={`${localizeFilterCollectionPrefix}.${selectedFilterId}.label`} />
          </Typography>
        ) : (
          <>
            <input />
            <Typography>
              Select <Localized dictKey={`${localizePrefix}.filterName`} />
            </Typography>
          </>
        )}
      </Grid> */}
      {/* <DropDown
          variant={'contained'}
          color={'primary'}
          fullWidth
          themeMode={themeMode || ThemeMode.LIGHT}
          onChange={(opt: string) => {
            if (opt !== selectedFilterId) {
              setFilter(opt);
            }
          }}
          label={
            selectedFilterId ? (
              <Typography>
                <Localized
                  dictKey={`${localizeFilterCollectionPrefix}.${selectedFilterId}.label`}
                />
              </Typography>
            ) : (
              <>
                <input />
                <Typography>
                  Select <Localized dictKey={`${localizePrefix}.filterName`} />
                </Typography>
              </>
            )
          }
          options={
            filterOptionIds?.map((opt) => (
              <Typography key={opt}>
                <Localized dictKey={`${localizeFilterCollectionPrefix}.${opt}.label`} />
              </Typography>
            )) || []
          }
        /> */}
    </Grid>
  );
};

const WAPSearchFilter = (props: CombinedAppProps<SearchFilterProps>) =>
  withoutAppProps<CombinedAppProps<SearchFilterProps>, SearchFilterProps>(
    SearchFilter,
    props,
  );

export { SearchFilter, WAPSearchFilter };
