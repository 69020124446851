import contact from './contact.json';
import checkout from './checkout.json';
import helpCustomer from './help.customer.json';
import helpOrganisation from './help.organisation.json';
import shop from './shop.json';

export default {
  '/': shop,
  '/contact': contact,
  '/shopping-cart': checkout,
  '/help/customer': helpCustomer,
  '/help/organisation': helpOrganisation,
};
