import * as React from 'react';
import { Typography, LocalizedErrorIndicator, LoadingIndicator } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { useLocation } from '@reach/router';
import { AppEnvironment } from 'src/app/constants';
import { isEnvironment } from 'src/app/lib';
import { OrderList } from './OrdersList';
import { OrderDocumentData } from 'src/app/types';
import { FirestoreCollectionData, Pagination } from 'src/app/container';
import { where } from 'firebase/firestore';
import DataSearchTable from 'src/app/components/table-search/DataSearchTable';
import { TableHeadings } from 'src/lib/types/tableTypes';

/**
 * OrderContainer
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const OrdersContainer = () => {
  const { isLoggedIn, user } = useAppContext();
  const { href } = useLocation();
  const parseSkuString = (str: string): {
    productId: string;
    priceSystemId: string;
    priceGroupId: string;
    eventDateIsoStringId: string;
    userId: string;
    organisationId: string;
    orderId: string;
    cartItemId: string;
  } | null => {
    // NOTE: verify sku string parse requirements are met else return null
    const split = str.split('#');


    for (let index = 0; index < split.length; index++) {
      if (!split[index]) {
        console.log('parseSkuString() - content error');
        return null;
      }
    }

    return ({
      productId: split[0] || '',
      priceSystemId: split[1] || '',
      priceGroupId: split[2] || '', // does this suffice for quantity related discounts ?
      eventDateIsoStringId: split[3] || '', // is this fail proof ? => ZULU 10 BUG
      userId: split[4] || '',
      organisationId: split[5] || '',
      orderId: split[6] || '',
      cartItemId: split[7] || '',
    });
  };

  const isProduction = isEnvironment(
    href,
    AppEnvironment.STAGE || AppEnvironment.PRODUCTION,
  );
// let headerValue:TableHeadings=  [
//     {name: 'Supplier Name',
//           filterField: 'supplierName',
//           dataName:'supplierName',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},

//           {name: 'Supplier Id',
//           filterField: 'supplierId',
//           dataName:'supplierId',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
//           {name: 'Total Amount',
//           filterField: 'totalAmount',
//           dataName:'totalAmount',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
//           {name: 'User Id',
//           filterField: 'userId',
//           dataName:'userId',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
//           {name: 'Product Id',
//           filterField: 'productId',
//           dataName:'productId',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
//           {name: 'Order Id',
//           filterField: 'orderId',
//           dataName:'orderId',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
//           {name: 'User Name',
//           filterField: 'userName',
//           dataName:'userName',
//           fieldType: 'string',
//           allowOrder: true,
//           isMain: true,
//           isSearchable: true},
          

//         ]
const tableHead = [
  {
    name: 'Product Name',
    filterField: 'productName',
    dataName: 'productName',
    fieldType: 'text',
    allowOrder: true,
    isMain: true,
    isSearchable: true
  },
  {
    name: 'Order Id',
    filterField: 'OrderId',
    dataName: 'OrderId',
    fieldType: 'text',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },
  {
    name: 'Order Placed',
    filterField: 'createdDate',
    dataName: 'createdDate',
    fieldType: 'dateString',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },
  {
    name: '	Number Of Participants',
    filterField: 'participantCount',
    dataName: 'participantCount',
    fieldType: 'int',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },
  {
    name: 'Date Of The Event',
    filterField: 'eventDate',
    dataName: 'eventDate',
    fieldType: 'dateString',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },
  {
    name: 'Time Of The Event',
    filterField: 'eventTime',
    dataName: 'eventTime',
    fieldType: 'time',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },
  {
    name: 'Total Amount',
    filterField: 'totalAmount',
    dataName: 'totalAmount',
    fieldType: 'number',
    allowOrder: true,
    isMain: false,
    isSearchable: true
  },

  {
    name: 'Contact Supplier',
    filterField: '',
    dataName: 'actionButton',
    buttonName:"contactSupplier",
    fieldType: 'nil',
    allowOrder: false,
    isMain: false,
    isSearchable: false
  }, 
  {
    name: `View Booking Details`,
    filterField: '',
    dataName:"actionButton",
    buttonName: 'viewBookingDetails',
    fieldType: 'nil',
    allowOrder: false,
    isMain: false,
    isSearchable: false
  },
];
let filterParamter={
  user:user?.uid
}
  return (
    <div className='py-2'  style={{ maxWidth: '100%' }}>
      {isLoggedIn && user?.uid ? (
        <DataSearchTable tableHeadings={tableHead} collectionName={isProduction?'newOrderProd':'newOrderDev'} tableName={'Orders'} filterParamter={filterParamter} />

        // <FirestoreCollectionData<OrderDocumentData>
        //   collectionPath={`user/${user.uid}/order`}
        //   queryConstraints={[where('successDetails.status', '==', 'COMPLETED')]}
        //   queryLimit={500}
        //   queryOrderBy={'meta.createdAt'}
        //   renderElements={({ data, status }) => {
        //     console.log('daaaaaa',data);
        //     if (status === 'loading') {
        //       return <LoadingIndicator />;
        //     }
        //     if (status === 'error') {
        //       return (
        //         <>{'Error'}</>
        //         // <LocalizedErrorIndicator
        //         //   i18nContext={'organisation'}
        //         //   i18nErrorCode={'LOADING_COLLECTION'}
        //         //   i18nErrorAreaCode={'order'}
        //         // />
        //       );
        //     }
        //     return status !== 'success' || !data?.length || !data ? 
        //     <Typography variant={'subtitle1'}>
        //    No Orders Found
        //    </Typography> : (
        //       <OrderList orders={data} />
        //     );
        //   }}
        // />
      ) : (
        <>
          <Typography variant={'subtitle1'}>
            You need to have an account at Hey Holiday to use this feature
          </Typography>
        </>
      )}
    </div>
  );
};

export { OrdersContainer };
