import { FieldValue, Timestamp, serverTimestamp } from 'firebase/firestore';

// FIXME: this is full of old shit => refactor as soon as possible
import {
  BaseDocumentData,
  // BaseDocumentMetaData, // TODO:
  SourcedDocumentMetaData, // DO NOT USE
  CreatorDocumentData,
  FirebaseTimestamp,
} from 'src/app/types';
import { ProductDocumentState } from '../constants';

/**
 * Use if the data is needed before submitting to BE
 * @returns
 */
export const createTimeStamp = (): FirebaseTimestamp => {
  // Guard for Gatsby SSR build success
  if (typeof window === 'undefined') {
    return null as any as FirebaseTimestamp;
  }
  // return firebase.firestore.FieldValue.serverTimestamp();
  return Timestamp.now();
};

/**
 * Use to submit data to BE immediately (Object is generated on the BE)
 * @returns
 */
export const createServerTimeStamp = (): FirebaseTimestamp => {
  // Guard for Gatsby SSR build success
  if (typeof window === 'undefined') {
    return null as any as FirebaseTimestamp;
  }
  return serverTimestamp() as FirebaseTimestamp;
  // return firebase.firestore.Timestamp.now();
};

// TODO: read from .env
export const createDocumentVersion = (): string => {
  return '1';
};

// TODO: define new interface
export const createBaseDocumentMeta = (): BaseDocumentMetaData => ({
  categoryIds: [],
  categoryTypeIds: [],
  createdAt: createServerTimeStamp(),
  // createdBy => keep separated?
  documentType: '', // TODO
  status: ProductDocumentState.DRAFT,
  systemIds: ['holiday'], // TODO
  tagIds: [],
  updatedAt: createServerTimeStamp(),
  updatedById: '',
  v: createDocumentVersion(),
});

// NOTE:
// FIXME: eval if that makes sense:
export const createSourcedDocumentMeta = (): SourcedDocumentMetaData => ({
  categoryIds: [],
  categoryTypeIds: [],
  createdAt: createServerTimeStamp(),
  // createdBy => keep separated?
  documentType: '', // TODO
  status: ProductDocumentState.DRAFT,
  systemIds: ['holiday'], // TODO
  tagIds: [],
  updatedAt: createServerTimeStamp(),
  updatedById: '',
  v: createDocumentVersion(),
});

const removeCreatedAtProperty = (obj: Record<string, any>) => {
  const properties = Object.getOwnPropertyNames(obj).filter(
    (propName) => propName !== 'createdAt',
  );
  const newObj: Record<string, any> = {};
  for (let index = 0; index < properties.length; index++) {
    newObj[`${properties[index]}`] = obj[`${properties[index]}`];
  }
  return newObj;
};

export const createBaseDocument = <T extends BaseDocumentData>(
  id: string,
  data: T,
): BaseDocumentData => {
  const baseMeta = createBaseDocumentMeta();
  const transformedDataMetaWithoutCreatedAt = data?.meta
    ? removeCreatedAtProperty(data.meta)
    : null;
  // delete dataMetaRemoveCreatedAt.createdAt;
  return {
    ...data,
    id,
    meta: transformedDataMetaWithoutCreatedAt
      ? { ...baseMeta, ...transformedDataMetaWithoutCreatedAt } // compose if meta structure is used in document
      : baseMeta,
  };
};

// CreatorDocumentMetaData

export const createBaseDocumentCreatedBy = <T extends BaseDocumentData>(
  userUid: string,
  id: string,
  data: T,
): CreatorDocumentData => {
  const baseDocument = createBaseDocument(id, data);
  return {
    ...baseDocument,
    meta: { ...baseDocument.meta, createdById: userUid }, // documentType
  };
};

export const updateExistingDocument = <T extends BaseDocumentData>(
  userUid: string,
  id: string,
  data: T,
): CreatorDocumentData => {
  // const baseDocument = createBaseDocument(id, data);
  const { meta, ...restData } = data;

  console.log('updateExistingDocument data: ', data);
  return {
    id,
    ...restData,
    meta: {
      ...meta,
      // createdAt: Timestamp.fromDate(meta?.createdAt?.toDate() || new Date()),
      createdAt: data.meta?.createdAt || createServerTimeStamp(), // FIXME: old docs count contain createdAt fields in a invalid format => check and cleanup
      createdById: meta?.createdById || '',
      updatedAt: createServerTimeStamp(),
      updatedById: userUid,
      // FIXME: check if this logic is sufficient or where is it used, because that seems pretty crappy...
      status: data.meta?.status && [ProductDocumentState.DRAFT, ProductDocumentState.PUBLISH__REQUEST].includes(data.meta.status)
    ? data.meta.status
    : ProductDocumentState.CHANGE_REQUEST,

    }, // documentType + backups
  };
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const delayedExecution = async (ms: number, cb: (() => void) | Promise<void>) => {
  await delay(ms);
  if (cb) {
    if (cb instanceof Promise) {
      await cb;
    } else {
      cb();
    }
  }
};

export const createCleanDocumentId = (str: string) =>
  str.replace(/[^A-Za-z0-9]/g, '-').toLowerCase();
