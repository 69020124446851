import * as React from 'react';
import clsx from 'clsx';
import { CSSProperties } from '@material-ui/styles';
import ExpandIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CondenseIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CalendarToday from '@material-ui/icons/CalendarTodayOutlined';

import {
  Button,
  ButtonTw,
  Grid,
  CategoryFilter,
  CityFilter,
  Glass,
  DatePicker,
  Localized,
} from 'src/app/components';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeMode } from 'src/app.consumer/constants';
import { useStyles } from 'src/app/context';
import { useLocalizedNavigation } from 'src/app/hooks';

export interface SearchBarProps extends BaseComponentProps {
  className?: string;
  // style?: CSSProperties;
  children?: React.ReactNode;
  currentCategoryTypeId?: string;

  setCategoryTypeId: (categoryTypeId: string) => void;
}

// enum DatePickerType {
//   FROM = 'from',
//   TO = 'to',
// }

/**
 * SearchBar / WAPSearchBar
 * @param { SearchBarProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const SearchBar = (props: SearchBarProps) => {
  const { children, className, currentCategoryTypeId, setCategoryTypeId } = props;
  const { navigateLocalized } = useLocalizedNavigation();
  const [cityId, setCityId] = React.useState<string | null>(null);
  const [categoryId, setCategoryId] = React.useState<string | null>(null);
  const [fromDate, setFromDate] = React.useState<MaterialUiPickersDate | null>(null);
  const [toDate, setToDate] = React.useState<MaterialUiPickersDate | null>(null);
  const [showAdditionalOptions, setShowAdditionalOptions] =
    React.useState<boolean>(false);
  const styles = useStyles();

  const countryId = 'hr'; // TODO:

  const onSearchSubmit = () => {
    let queryString = '';
    let queryString2 = '/europe';

    if (countryId) {
      queryString = `${queryString}&country=${countryId}`;
      queryString2 = `${queryString2}/${countryId}`;
    }
    if (cityId) {
      queryString = `${queryString}&city=${cityId}`;
      queryString2 = `${queryString2}/city/${cityId}`;
    }
    if (categoryId) {
      queryString = `${queryString}&p=${categoryId}`;
      queryString2 = `/activity-000/${categoryId}`;
    }

    // FIXME: has always to be present
    if (fromDate) {
      const from = fromDate
        ? fromDate?.toJSON().slice(0, 10)
        : new Date().toDateString().slice(0, 10);
      queryString = `${queryString}&from=${from}`;
      queryString2 = `${queryString2}?from=${from}`;
    }
    // FIXME: merging of ? in query has to occur if only toDate is provided
    if (toDate) {
      const to = toDate
        ? toDate?.toJSON().slice(0, 10)
        : new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
            .toDateString()
            .slice(0, 10);
      queryString = `${queryString}&to=${to}`;
      queryString2 = `${queryString2}&to=${to}`;
    }
    // navigateLocalized(`/explore/s?${queryString}`);
    console.log('queryString2: ', queryString2);
    navigateLocalized(queryString2);
  };

  const onSetCategoryType = (catTypeId: string) => {
    setCategoryTypeId(catTypeId);
  };

  const showProductRootTypeSelection = showAdditionalOptions; // false;

  return (
    <>
      <p className='text-p2 font-medium px-4 text-neutral-50 leading-none'>Search for:</p>
      <div className='flex items-end gap-2 mb-3 md:px-4 overflow-x-scroll no-scrollbar snap-mandatory snap-x md:overflow-x-auto'>
        <div className='flex-shrink-0 md:min-w-[160px] first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0'>
          <ButtonTw
            size={'sm'}
            rounded={'rounded'}
            variant={currentCategoryTypeId === 'activity-000' ? 'accent' : 'neutral'}
            className={'w-full'}
            onClick={() => onSetCategoryType('activity-000')}
          >
            <Localized dictKey={'shop:/.search-box.filter.categoryType.activity-000'} />
          </ButtonTw>
        </div>
        <div className='flex-shrink-0 flex flex-col items-center gap-y-1 md:min-w-[160px] first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0'>
          <span className='text-neutral-50 text-p5 uppercase'>Comming Soon</span>
          <ButtonTw
            variant={'neutral'}
            size={'sm'}
            rounded={'rounded'}
            onClick={() => onSetCategoryType('boat-000')}
            className={'w-full'}
          >
            <Localized dictKey={'shop:/.search-box.filter.categoryType.boat-000'} />
          </ButtonTw>
        </div>
        <div className='flex-shrink-0 flex flex-col items-center gap-y-1 md:min-w-[160px] first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0'>
          <span className='text-neutral-50 text-p5 uppercase'>Comming Soon</span>
          <ButtonTw
            variant={'neutral'}
            size={'sm'}
            rounded={'rounded'}
            className={'w-full'}
            onClick={() => onSetCategoryType('transfer-000')}
          >
            <Localized dictKey={'shop:/.search-box.filter.categoryType.transfer-000'} />
          </ButtonTw>
        </div>
      </div>

      <div className='mb-16 mx-4 bg-neutral-50 rounded-2xl p-3 relative dark:bg-neutral-900 md:mb-0'>
        <div className='flex flex-col gap-3'>
          <div className='flex flex-wrap md:flex-nowrap gap-3'>
            <div className='w-full md:w-1/2'>
              <CityFilter
                // themeMode={ThemeMode.DARK}
                currentCityId={cityId || ''}
                setCityId={setCityId}
                margin={'normal'}
                InputProps={{
                  // className: 'rounded-xl',
                  // // style: {
                  // //   backgroundColor: '#ffa500dd',
                  // // },
                  // classes: {
                  //   input: '!bg-neutral-50 !rounded-lg dark:!text-neutral-900',
                  //   //focused: classes.textField__focused,
                  //   //notchedOutline: classes.textField__notchedOutline,
                  //   //label: '!text-red-500'
                  // },
                }}
              />
            </div>
            <div className='w-full md:w-1/2'>
              <CategoryFilter
                themeMode={ThemeMode.DARK}
                currentCategoryId={categoryId || ''}
                setCategoryId={setCategoryId}
              />
            </div>


          </div>
          <div className='flex flex-wrap md:flex-nowrap gap-3'>
            <div className='w-full md:w-1/3'>
              <DatePicker
                selectedDate={fromDate}
                setDate={setFromDate}
                type={'from'}
                //className='rounded-xl bg-neutral-100 dark:bg-neutral-800'
                InputProps={{
                  classes: {
                    input: '!h-12 md:!h-14 !box-border !font-normal',
                  },
                }}
                keyboardIcon={<CalendarToday className={'text-accent-500'} />}
              />
            </div>
            <div className='w-full md:w-1/3'>
              <DatePicker
                selectedDate={toDate}
                setDate={setToDate}
                type={'to'}
                //className='rounded-xl bg-neutral-100 dark:bg-neutral-800'
                InputProps={{
                  classes: {
                    input: '!h-12 md:!h-14 !box-border !font-normal',
                  },
                }}
                keyboardIcon={<CalendarToday className={'text-accent-500'} />}
              />
            </div>
            <div className='w-full absolute -bottom-16 left-0 md:w-1/3 md:relative md:left-auto md:bottom-auto'>
              <ButtonTw
                className={'w-full'}
                // fullWidth
                variant={'accent'}
                onClick={onSearchSubmit}
                size={'lg'}
                rounded={'rounded'}
                // style={{ margin: '0 8px 0 8px' }}
                // startIcon={<SearchIcon />}
              >
                <Localized dictKey={'shop:/.search-box.button.submit'} />
              </ButtonTw>
            </div>
          </div>
        </div>

        {/* {showAdditionalOptions ? (
          <>

            <CategoryFilter
              themeMode={ThemeMode.DARK}
              currentCategoryId={categoryId || ''}
              setCategoryId={setCategoryId}
            />


          {showAdditionalOptions ? (

              <DatePicker
                selectedDate={toDate}
                setDate={setToDate}
                type={'to'}
                InputLabelProps={{
                  className: styles.searchBoxInputColor,
                }}
                InputProps={{
                  className: styles.searchBoxInputColor,
                  // style: {
                  //   backgroundColor: '#ffa500dd',
                  // },
                }}
                keyboardIcon={
                  <CalendarToday className={styles.searchBoxInputColor} />
                }
              />

          ) : null}


          </>
        ) : null} */}

        {children ? { children } : null}
      </div>
    </>
  );
};

const WAPSearchBar = (props: CombinedAppProps<SearchBarProps>) =>
  withoutAppProps<CombinedAppProps<SearchBarProps>, SearchBarProps>(SearchBar, props);

export { SearchBar, WAPSearchBar };
