import * as React from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';

import { useAppContext } from 'src/app/hooks';
import { Grid, PrivateRoute, Typography } from 'src/app/components';
import { UserChangePassword, UserReAuthentication } from 'src/app/container';
import { NotificationType } from 'src/app/constants';

export const UserAccountPasswordChange = () => {
  const { createNotification } = useAppContext();
  const [authState, setAuthState] = React.useState<'SUCCESS' | 'FAILURE' | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  // STEP 1 - AUTHENTICATION

  const onAuthenticationSuccess = () => {
    setAuthState('SUCCESS');
  };

  const onAuthenticationFailure = (error: any) => {
    setAuthState('FAILURE');
    setErrorMessage(error?.message || null);
  };

  // STEP 2 - CHANGE PASSWORD

  const onUpdateSuccess = () => {
    createNotification(NotificationType.SUCCESS, 'Password successfully changed', 5000);
    navigate('/account');
  };

  const onUpdateFailure = (error: any) => {
    createNotification(
      NotificationType.ERROR,
      error?.message || 'Password change error',
      5000,
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography paragraph variant={'h5'} renderAs={'h2'}>
          Change your password
        </Typography>
      </Grid>
      {!authState || authState === 'FAILURE' ? (
        <>
          <Grid item xs={12}>
            <UserReAuthentication
              onSuccess={onAuthenticationSuccess}
              onFailure={onAuthenticationFailure}
            />
          </Grid>
          {authState === 'FAILURE' && errorMessage ? (
            <Grid item xs={12}>
              <Typography color={'error'}>{errorMessage}</Typography>
            </Grid>
          ) : null}
        </>
      ) : (
        <Grid item xs={12}>
          <UserChangePassword onSuccess={onUpdateSuccess} onFailure={onUpdateFailure} />
        </Grid>
      )}
    </Grid>
  );
};

export const UserAccountPasswordRouter = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Router style={{ width: '100%' }}>
          <PrivateRoute<{}> path={'/*'} component={UserAccountPasswordChange} />
        </Router>
      </Grid>
    </Grid>
  );
};
