export enum SystemAdminRootScreen {
  // CONFIGURATION START
  configuration = 'sys-configuration',
  CONFIGURATION__SOURCE_CATEGORY = 'sys-configuration-source-category',
  CONFIGURATION__SOURCE_CATEGORY_TYPE = 'sys-configuration-source-category-type',
  CONFIGURATION__SOURCE_CITY = 'sys-configuration-source-city',
  CONFIGURATION__SOURCE_CITY_AREA = 'sys-configuration-source-city-area',
  CONFIGURATION__SOURCE_COUNTRY = 'sys-configuration-source-country',
  CONFIGURATION__SOURCE_COUNTRY_AREA = 'sys-configuration-source-country-area',
  CONFIGURATION__SOURCE_CONTINENT = 'sys-configuration-source-continent',
  CONFIGURATION__SOURCE_CURRENCY = 'sys-configuration-source-currency',
  CONFIGURATION__SOURCE_LOCALE = 'sys-configuration-source-locale',
  CONFIGURATION__SOURCE_LOCATION = 'sys-configuration-source-location',
  CONFIGURATION__SOURCE_TAG = 'sys-configuration-source-tag',

  // CONFIGURATION END
  dashboard = 'sys-dashboard',
  data = 'sys-data', // maybe split oder rename

  // ORDER START
  order = 'sys-order',
  ORDER__EVENT = 'sys-order-event',
  ORDER__ORDER = 'sys-order-order',
  ORDER__PREVIEW = 'sys-order-order-preview',

  // ORDER END

  media = 'sys-media',
  messages = 'sys-messages',
  organisation = 'sys-organisation',
  localization = 'sys-localization',
  product = 'sys-product',
  report = 'sys-report',
  user = 'sys-user',
  preview = 'sys-preview',
}

// TODO: build functionality + merge with orgAdmin

export enum SystemAdminDialogType {
  UPDATE_ORGANISATION_PRODUCT_CATEGORY_TYPES = 'UPDATE_ORGANISATION_PRODUCT_CATEGORY_TYPES',
  // FIXME: refactor:
  create = 'create',
  DOCUMENT_DELETE = 'document_delete',
  edit = 'edit',
}
