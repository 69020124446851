import { BaseOrganisationDocument } from 'src/admin/types';
import { ProductCategoryType } from 'src/app/constants';

export const categoryTypeRouteMappings: Record<ProductCategoryType, string> = {
  [ProductCategoryType.ACTIVITY]: 'activity',
  [ProductCategoryType.BOAT]: 'boat',
  [ProductCategoryType.RENTAL]: 'rental',
  [ProductCategoryType.RESERVATION]: 'reservation',
  [ProductCategoryType.TRANSFER]: 'transfer',
};

export const getOrganisationCategoryTypeIds = (
  organisationDocument: BaseOrganisationDocument,
) => {
  const res: ProductCategoryType[] = [];

  if (!organisationDocument?.productCategoryTypeConfig) {
    console.warn('No categoryTypes for org');
    return [];
  }

  organisationDocument.productCategoryTypeConfig.forEach((categoryTypeCfg) => {
    if (categoryTypeCfg.isEnabled && categoryTypeRouteMappings[categoryTypeCfg.id]) {
      res.push(categoryTypeCfg.id);
    }
  });

  return res;
};

// TODO:
export const getCurrentOrganisationProductCategoryTypeCount = (
  organisationDocument: BaseOrganisationDocument,
  productCategoryType: string,
) => {
  return 0;
};

export const getCurrentOrganisationProductCategoryTypeLimit = (
  organisationDocument: BaseOrganisationDocument,
  productCategoryType: string,
) => {
  return (
    organisationDocument.productCategoryTypeConfig?.find(
      (cfg) => cfg.id === productCategoryType && cfg.isEnabled,
    )?.productLimit || 0
  );
};

export const isOrganisationProductCategoryTypeLimitReached = (
  organisationDocument: BaseOrganisationDocument,
  productCategoryType: string,
  currentCount: number,
) => {
  console.log(
    'hasProductCategoryTypeLimitReached productCategoryType: ',
    productCategoryType,
  );
  return !organisationDocument.productCategoryTypeConfig.find(
    (cfg) =>
      cfg.id === productCategoryType && cfg.isEnabled && cfg.productLimit > currentCount,
  );
};
