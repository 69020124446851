import React from 'react';
import { useCookies } from 'react-cookie';

import { ThemeMode } from 'src/app.consumer/constants';

import { AppFontDebugState, BaseThemeState, StyleContext } from './styleContext.types';
// import { ThemeMode } from './styleContext.constants'; // TODO: remove
// import StyleContext from './styleContext';
import { useStyles } from './useStyles';

const themeCookieName = 'theme';

// TODO: handle route change via cookies (checkout why state is lost)
function GetThemeState(): BaseThemeState {
  const [cookies, setCookie] = useCookies([themeCookieName]);
  const [theme, setTheme] = React.useState<ThemeMode>(
    cookies[themeCookieName] || ThemeMode.LIGHT,
  );

  const setThemeMode = (themeMode: ThemeMode) => {
    document.documentElement.style.display = 'none';
    // https://stackoverflow.com/questions/65940522/how-do-i-switch-to-chromes-dark-scrollbar-like-github-does
    document.documentElement.style.display = 'none';

    document.documentElement.setAttribute('data-color-scheme', themeMode);
    // remove scrollbars
    document.documentElement.style.overflow = 'hidden';
    // trigger reflow so that overflow style is applied
    const cw = document.body.clientWidth; // needs to be calculated
    // remove overflow style, which will bring back the scrollbar with the correct scheme
    document.documentElement.style.overflow = '';
    document.documentElement.style.display = '';

    setTheme(themeMode);
    setCookie(themeCookieName, themeMode, { path: '/' });
  };

  return { setThemeMode, themeMode: theme };
}

function GetAppFontState(): AppFontDebugState {
  const [appFont, setAppFont] = React.useState<string | null>(null);

  // NOTE: only for debug - (CSP issues will arise...)
  const fontFamilyParams = `${appFont}&display=swap`;
  const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamilyParams}`;

  const onChangeFont = () => {
    if (appFont) {
      const link = document.createElement('link');
      link.rel = 'stylesheet'; // or 'reload/prefetch?
      link.href = fontUrl;
      document.getElementsByTagName('head')[0].appendChild(link);
      // TODO: remove old link in meta?
    }
    console.log('done');
  };

  React.useEffect(() => {
    if (appFont) {
      onChangeFont();
    }
  }, [appFont]);

  return { appFont, setAppFont };
}

const Context = React.createContext<StyleContext | null>(null);

const useStyleContext: () => StyleContext = (): StyleContext =>
  React.useContext<StyleContext | null>(Context) as StyleContext;

function StyleContextProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <Context.Provider
      value={{
        ...GetThemeState(),
        // ...GetAppFontState(),
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { StyleContextProvider, useStyles, useStyleContext };
