import * as React from 'react';
import { Grid, Toolbar, Typography } from 'src/app/components';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BaseComponentProps } from 'src/app/types';

export interface SettingOptionProps extends BaseComponentProps {
  checked: boolean;
  description: string | JSX.Element;
  name?: string;
  onChange: () => void;
  label: string | JSX.Element;
}

const SettingOption = (props: SettingOptionProps) => {
  const { checked, children, label, description, name, onChange } = props;
  return (
    <>
      <div className="flex py-2">
        {description ? (
          <div>
            <div className='w-full md:w-1/2'>
              <span>{description}</span>
            </div>
          </div>
        ) : null}
        <div className='w-full md:w-1/2'>
          <FormControlLabel
            control={
              <Checkbox color='primary' checked={!!checked} onChange={onChange} name={name} />
            }
            label={label}
            labelPlacement={'end'}
          />
        </div>
      </div>
      {children ? (
        <div>
          {children}
        </div>
      ) : null}
    </>
  );
};

export { SettingOption };
