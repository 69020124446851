import { nanoid } from 'nanoid';
import { StoredImageData, ImageLocalization } from 'src/app/types';
import { imgRandomUidLength } from 'src/app/constants';

export const getFileExtension = (file: File) =>
  file?.name?.split('.')?.pop() || '.invalid__extension';

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export const suffixWithUid = (s: string) => `${s}_${nanoid(imgRandomUidLength)}`;

export const getRandomSuffixedFileName = (fileName: string, fileExtension: string) =>
  `${suffixWithUid(fileName)}.${fileExtension}`;

/**
 * Compose filename from customFileName and file extension, otherwise keep original filename and extension
 * @param file
 * @param customFileName
 * @returns
 */
export const createImageFileName = (
  file: File,
  suffixUidToFileName: boolean,
  customFileName?: string,
) => {
  const fileExtension = getFileExtension(file);
  console.log('createImageFileName fileExtension: ', fileExtension);
  if (suffixUidToFileName) {
    if (customFileName) {
      console.log('case00');
      return getRandomSuffixedFileName(customFileName, fileExtension);
    }
    if (file.name) {
      console.log('case01');
      return getRandomSuffixedFileName(file.name, fileExtension);
    }
    console.log('case02');
    return getRandomSuffixedFileName('new_image', fileExtension); // fallback if no filename is found
  }
  const res =
    customFileName || file.name || getRandomSuffixedFileName('new_image', fileExtension);
  console.log('case1 customFileName: ', customFileName);
  console.log('case1 res: ', res);
  return res;
};

export const createStoredImageData = (
  file: File,
  downLoadUrl: string,
  localizations: ImageLocalization[],
  imageId: string,
  storagePath: string,
  suffixStoragePathFileNameWithUid: boolean,
  displayName?: string,
): StoredImageData => {
  // TODO: this is messy find all callers and perform uid magic there...
  const fileExtension = getFileExtension(file);
  const fileNameWithUid = suffixStoragePathFileNameWithUid
    ? `${imageId}_${nanoid(imgRandomUidLength)}`
    : imageId;
  const fullFilePath = `${storagePath}/${fileNameWithUid}.${fileExtension}`;

  return {
    imageUrl: downLoadUrl,
    fileExtension,
    displayName: displayName
      ? encodeURIComponent(displayName)
      : encodeURIComponent(file.name),
    fileName: `${fileNameWithUid}.${fileExtension}`,
    fullFilePath,
    storagePath,
    localizations,
  };
};
