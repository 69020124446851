import React from 'react';
import MStepper, { StepperProps as MStepperProps } from '@material-ui/core/Stepper';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';

export interface StepperProps extends CombinedBaseComponentProps<MStepperProps> {}

/**
 * Stepper / WAPStepper - MUI
 * @param { ButtonProps } props
 * @version 0.0.0
 * @description Stepper container
 * @todo
 * @example
 */
const Stepper = (props: StepperProps) => {
  const { children, style, ...passProps } = props;
  return (
    <div style={{ width: '100%' }}>
      <MStepper {...passProps} alternativeLabel style={{ backgroundColor: '#00000000', ...style }}>
        {children}
      </MStepper>
    </div>
  );
};

const WAPStepper = (props: CombinedAppProps<StepperProps>) =>
  withoutAppProps<CombinedAppProps<StepperProps>, StepperProps>(Stepper, props);

export { Stepper, WAPStepper };
