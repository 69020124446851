import React from 'react';
import { useAppContext } from 'src/app/hooks';
import { DialogType } from 'src/app/constants';

const GDPRConsentTrigger = () => {
  const { isGDPRSet, openDialog } = useAppContext();
  // const { isGDPRSet, setGDPRSettings } = useAppContext(); // TODO:
  const [isCookieDialogOpen, setIsCookieDialogOpen] = React.useState<boolean>();
  React.useEffect(() => {
    if (!isGDPRSet && !isCookieDialogOpen) {
      setIsCookieDialogOpen(true);
      openDialog(DialogType.GDPR);
    }
  }, [isCookieDialogOpen, isGDPRSet]);

  return null;
};

export { GDPRConsentTrigger };
