import * as React from 'react';

export interface YoutubeEmbedProps {
  embedId: string;
  title: string | React.ReactElement;
}
export const YoutubeEmbed = ({ embedId, title }: YoutubeEmbedProps) => {
  return (
    <div className='overflow-hidden relative rounded-2xl h-0' style={{paddingBottom: '56.25%'}}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title || ''}
        className='absolute top-0 left-0 h-full w-full'
      />
    </div>
  );
};
