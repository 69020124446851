import clsx from 'clsx';
import * as React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import { Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { Resolution } from 'src/app/types';

export interface ImageCropperProps {
  file: File | null;
  fileUrl: string | null;
  updateStorageFileName: (newName: string) => void; // update without file extension
}

export const ImagePreviewEditContainer = (props: ImageCropperProps) => {
  const { file, fileUrl } = props;
  const styles = useStyles();
  // const [crop, setCrop] = React.useState<{ aspect: number }>({ aspect: 16 / 9 });
  const [crop, setCrop] = React.useState<Crop>({
    width: 100,
    height: 100,
    unit: '%', // | '%'
  });

  const [updateFileUrl, setUpdateFileUrl] = React.useState<string | null>(null);

  const [updateFileName, setUpdateFileName] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (file?.name && file.name !== updateFileName) {
      setUpdateFileUrl(URL.createObjectURL(file));
      setUpdateFileName(file.name);
    }
  }, [file, updateFileName]);

  React.useEffect(() => {
    if (updateFileName && updateFileUrl && !file) {
      setUpdateFileUrl(null);
      setUpdateFileName(null);
    }
  }, [file, updateFileName, updateFileUrl]);

  const setImageContainerResolution = (res: Resolution) => {
    console.log('setImageContainerResolution to res: ', res);
  };
  return (
    <>
      {/* <Grid container className={styles.p__0}> */}
      {/* <Grid item xs={12}>
        <TextInputField
          fullWidth
          variant={'outlined'}
          onChange={(e) => setStorageFileName(e.target.value)}
          label={'Edit FileName:'}
          value={storageFileName || ''}
        />
      </Grid> */}
      {/* <Grid item xs={12}>
        <Button disabled fullWidth color={'primary'} variant={'contained'}>
          <Typography >Edit image</Typography>
        </Button>
      </Grid> */}
      {/* <Grid item xs={12}> */}
      <ReactCrop
        src={updateFileUrl || fileUrl || ''}
        className={clsx(styles.container__border_centerTop, styles.p__0)}
        imageStyle={{ maxWidth: '100%', maxHeight: '250px', width: '100%', height: '250px' }}
        // crop={crop}
        imageAlt={'Upload image preview'}
        minWidth={crop?.width}
        minHeight={crop?.height}
        maxWidth={crop?.width}
        maxHeight={crop?.height}
        // keepSelection
        locked
        // ruleOfThirds={false}
        // circularCrop={false}
        // renderComponent={<img src={fileUrl} />}
        renderSelectionAddon={() => (
          <Grid container>
            <Grid item xs={12}>
              <Typography align={'center'} color={'primary'}>
                {/* {`Area should be ${crop?.width} x ${crop?.height}`} */}
                {'Area will be converted to 1230x600'}
              </Typography>
            </Grid>
          </Grid>
        )}
        onComplete={(crop2, percentCrop) =>
          console.log('onComplete - crop: ', crop2, ' - percentCrop: ', percentCrop)
        }
        onImageLoaded={(target) => {
          // console.log('onImageLoaded - target.width: ', target.width);
          // console.log('onImageLoaded - target.height: ', target.height);
          setImageContainerResolution({
            height: target?.height,
            width: target?.width,
          });
        }}
        onDragStart={console.log}
        onDragEnd={console.log}
        onImageError={console.log}
        onChange={(newCrop: Crop) => setCrop(newCrop)}
      />
      {/* </Grid>
    </Grid> */}
    </>
  );
};
