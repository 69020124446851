import * as React from 'react';
import Divider from '@material-ui/core/Divider';

import { Grid, StringPropertyRow, Typography } from 'src/app/components';
import { ShoppingCartItemConfiguration } from 'src/app/types';

import { useLocalizationContext } from 'src/app/hooks';
import { useStyles } from 'src/app/context';
import { isParticipantValid } from 'src/lib/participant';

export const PriceSummaryTicketGroupParticipants = ({
  // priceSystems,
  // priceGroupTickets,
  priceGroupTicket,
}: {
  // priceSystems: PriceSystem[];
  // priceGroupTickets: ShoppingCartItemConfiguration[];
  priceGroupTicket: ShoppingCartItemConfiguration;
}) => {
  const { language } = useLocalizationContext();
  const styles = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography>Participants:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} className={styles.px__2}>
          {priceGroupTicket.participants?.map((part, idx) => {
            if (!isParticipantValid(part)) {
              return (
                <Grid key={part.id} item xs={9}>
                  <Typography color={'error'}>
                    {idx + 1}. Participant information must be completed
                  </Typography>
                </Grid>
              );
            }
            return (
              <Grid key={part.id} item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <StringPropertyRow
                      title={`${part.firstName} ${part.lastName} ( ${new Date(
                        part.dob,
                      ).toLocaleString(language, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })} )`}
                      value={' '}
                    />
                  </Grid>
                  {part.options ? (
                    <Grid item xs={12}>
                      <Grid container>
                        {part.options.pickup ? (
                          <Grid item xs={12}>
                            <Typography>Pickup</Typography>
                          </Grid>
                        ) : null}
                        {part.options.food ? (
                          <Grid item xs={12}>
                            <Typography>Food</Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            );
          }) || null}
        </Grid>
      </Grid>
    </Grid>
  );
};
