import { PaypalOptions } from 'react-paypal-button-v2';
import i18n from 'i18next';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

import {
  Price,
  ShoppingCartItemDocumentData,
  SourcedGQLProduct,
  PaypalOrderData,
  PaypalPurchaseUnitItem,
  PaypalPurchaseUnit,
} from 'src/app/types';
import { Currency, PickupOptionType, ProductLocale } from 'src/app/constants';

import { createDocumentId } from 'src/lib/db';

import {
  calculateTotalPrice,
  getCartItemsTotalPrices,
  calculatePriceGroupPrice,
  calculatePriceGroupTotalPrice,
  createPriceString,
  getCartItemTotalPrice,
  calculatePriceGroupTotalPriceWithExtraFees,
} from 'src/lib/checkout';

import { createSku } from 'src/lib/event';
import { calculatePriceFromPriceString, convertPriceToFloat } from 'src/lib/price';
import { useAppContext } from 'src/app/context';
import { calculatePickupFeeFromPickupLocationInformation, parsePickupOptionId } from 'src/lib/participant';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

/** Create description list example */

// const description = `${productLocales?.productTeaser || ''}`;
// const description = `${item.itemConfigurations
//   .filter((itemCfg) => itemCfg.count)
//   .map((itemCfg) => `[ group: ${itemCfg.priceGroupId} - qty : ${itemCfg.count} ]`)
//   .join('')}`;

export function getPaypalOptions(clientId: string, currency: Currency): PaypalOptions {
  return {
    clientId, // 	Your PayPal REST client ID. While you're testing in sandbox, you can use client-id=sb as a shortcut.
    commit: true, // Set to true if the transaction is Pay Now, or false if the amount captured changes after the buyer returns to your site.
    currency, // The currency of the transaction.
    intent: 'capture', // The intent of the payment. =>	capture, authorize, subscription, tokenize
    // merchantId: '3T6K....', // fb uid or hashed uid?
    // merchantId: 'SomeMerchantId', // 	The merchant for who you are facilitating a transaction.
    // debug: true, // Enable debug mode for ease of debugging. Do not enable for production traffic.
  };
}

// FIXME: implement changes from group function
function createItemData(
  currency: Currency,
  item: ShoppingCartItemDocumentData,
  srcProduct: SourcedGQLProduct,
  userId: string,
): PaypalPurchaseUnitItem {
  const productLocales =
    srcProduct.localizations.find((locale) => locale.localeId === ProductLocale.en) ||
    null;

  // TODO: check if is sourced through gatsby
  const organisationId = srcProduct.meta.organisationId || '';

  if (!organisationId) {
    console.warn('no organisation id');
  }

  // const itemTotalAmount = '0.00';
  const totalPrices: Price[] = getCartItemsTotalPrices([item], [srcProduct]);
  const totalItemPrice = calculateTotalPrice(totalPrices);
  const itemTotalAmount = `${totalItemPrice.integer}.${totalItemPrice.decimal}`;
  console.log('createItemData - itemTotalAmount: ', itemTotalAmount);

  const totalItemQuantity = item.itemConfigurations
    .map((itemCfg) => itemCfg.count)
    .reduce((prev = 0, next) => prev + next);

  const tzDate = dayjs(item.eventDate.toDate()).tz('Europe/Berlin');

  // const eventDateWithOffset = tzDate.add(tzDate.utcOffset(), 'minute');

  // FIXME: default items have no price group... (=> default?)
  const sku = createSku(
    item.productId,
    'item.priceSystemId', // TODO:
    'item.priceGroupId', // TODO:
    // item.eventDate.toDate().toISOString(), // FIXME: add/subtract time offset
    // eventDateWithOffset.toDate().toISOString(),
    tzDate.toDate().toISOString(),
    userId,
    organisationId,
  );
  // const sku = `${item.productId}#${item.productDateId}`; // item.productDateId (dateDocumentId format) '2021-04-07-13-00-0000'
  // console.log('truncatedProductDateIdWithSrcProductIdSuffix: ', sku);

  const description = `ProductId:${item.productId}`; // there is an old code example at start of file...

  // console.log('totalItemQuantity: ', totalItemQuantity);
  return {
    name: productLocales?.locales.name || '',
    description,
    sku,
    unit_amount: {
      currency_code: currency,
      value: itemTotalAmount,
    },
    tax: {
      currency_code: currency,
      value: '0.00',
    },
    quantity: totalItemQuantity.toString(),
    category: 'DIGITAL_GOODS', // TODO: const + google
  };
}

export function getOrderData(
  // orderSysId: string,
  currency_code: Currency,
  items: ShoppingCartItemDocumentData[],
  srcProducts: SourcedGQLProduct[],
  userId: string,
): PaypalOrderData {
  // FIXME: here we could get an proper transactionObj from server based on params in cart => get products current priceSystem and validate
  // TODO: save state and if pp transaction i successful then save the doc with this id here:
  // const orderId = createDocumentId();
  // const referenceId = orderSysId;
  // const customId = `${userId}#${orderSysId}`; // TODO. switch with sku?
  // TODO: take orderId and create a "log" document of the order with extra order details => take those details and copy them over to org order => after successful pp order

  // console.log('getOrderData - referenceId: ', referenceId);
  console.log('getOrderData - currency_code: ', currency_code);
  console.log('getOrderData - items: ', items);
  console.log('getOrderData - srcProducts: ', srcProducts);
  // const cartItemTotalPrices: Price[] = [...getCartItemsTotalPrices(items, srcProducts)];
  // console.log('cartItemTotalPrices: ', cartItemTotalPrices);

  // const totalPrice = calculateTotalPrice(cartItemTotalPrices);
  // console.log('totalPrice: ', totalPrice);
  // console.log('-------------------');

  // const softDescriptor = orderSysId;
  // const hardDescriptor = `${`Your order (${orderSysId}) at Hey.Holiday - Completed`}`;

  // const totalAmount = '0.00';
  // const totalAmount = `${totalPrice.integer}.${totalPrice.decimal}`;
  // const totalCurrency = currency_code;
  // const shippingAmount = '0.00';
  // const shippingCurrency = currency_code;
  // const handlingAmount = '0.00';
  // const handlingCurrency = currency_code;
  // const taxTotalAmount = '0.00';
  // const taxTotalCurrency = currency_code;
  // const shippingDiscountAmount = '0.00';
  // const shippingDiscountCurrency = currency_code;

  // TODO: shipping later for physical goods
  // const shippingAddressData = {
  //   name: {
  //     full_name: '',
  //     surname: '',
  //   },
  //   address_line_1: '',
  //   address_line_2: '',
  //   admin_area_2: '',
  //   admin_area_1: '',
  //   postal_code: '',
  //   country_code: '',
  // };

  // function createShippingData(
  //   method: string,
  //   address: PaypalShippingAddressData,
  // ): PaypalPurchaseUnitShipping {
  //   return {
  //     method,
  //     address,
  //   };
  // }

  // const itemsData: PaypalPurchaseUnitItem[] = [];

  // for (let itemIdx = 0; itemIdx < items.length; itemIdx++) {
  //   console.log(`/// creating stuff for items[${itemIdx}] - item: `, items[itemIdx]);
  //   console.log('/// srcProducts[0]: ', srcProducts);
  //   if (items[itemIdx].itemConfigurations.length >= 1) {
  //     // if(items[itemIdx].itemConfigurations[])
  //     itemsData.push(
  //       ...createGroupedItemData(
  //         currency_code,
  //         items[itemIdx],
  //         srcProducts[0],
  //         userId,
  //         orderSysId,
  //       ),
  //     );
  //   } else {
  //     // TODO: product in cart should always support itemConfigurations...
  //     console.error('got triggered - check if happens');
  //     itemsData.push(
  //       createItemData(currency_code, items[itemIdx], srcProducts[0], userId, orderSysId),
  //     );
  //   }
  // }

  // TODO: split products by groups
  // const itemsData = [
  //   ...items.map((item) => {
  //     if (item.itemConfigurations.length > 1) {
  //       return createItemData(currency_code, item, srcProducts[0]);
  //       // return ...createGroupedItemData(currency_code, item, srcProducts[0]);
  //     }
  //     return createItemData(currency_code, item, srcProducts[0]);
  //   }),
  // ];
  // console.log('getOrderData() - itemsData: ', itemsData);

  // FIXME: Split by organisation into purchase units or better into product(productGroup) purchase unit
  const orderData: PaypalOrderData = {
    // what is a purchase unit consider using per product or per org
    // consider using event wise?
    purchase_units: [...createPurchaseUnits(items, srcProducts, currency_code, userId)],
  };

  // console.log('getOrderData - itemsData: ', itemsData);
  console.log('getOrderData - orderData: ', orderData);

  return orderData;
}

// TODO: provide extra flag for privateGroupBookings (and one id for productCatType? => from sourceProd...)
const createPurchaseUnits = (
  cartItems: ShoppingCartItemDocumentData[],
  srcProducts: SourcedGQLProduct[],
  currency_code: Currency,
  userId: string,
): PaypalPurchaseUnit[] => {
  const purchaseUnits: PaypalPurchaseUnit[] = [];

  const orderSysId = 'orderSysId'; // new
  const referenceId = 'refId'; // new
  const customId = 'customId'; // new

  const softDescriptor = orderSysId;
  const hardDescriptor = `${`Your order (${orderSysId}) at Hey.Holiday - Completed`}`;

  // const totalAmount = '0.00';
  // const totalAmount = `${totalPrice.integer}.${totalPrice.decimal}`;
  const totalCurrency = currency_code;
  const shippingAmount = '0.00';
  const shippingCurrency = currency_code;
  const handlingAmount = '0.00';
  const handlingCurrency = currency_code;
  const taxTotalAmount = '0.00';
  const taxTotalCurrency = currency_code;
  const shippingDiscountAmount = '0.00';
  const shippingDiscountCurrency = currency_code;

  // const cartItemTotalPrices: Price[] = [...getCartItemsTotalPrices(items, srcProducts)];
  // console.log('cartItemTotalPrices: ', cartItemTotalPrices);

  // const totalPrice = calculateTotalPrice(cartItemTotalPrices);

  cartItems.forEach((cartItem) => {
    // NOTE: consider implementing mechanism: retrieving a "signkey" hash from an event (modify this hash in case of event changes + save copy of old version away, hash incl.)
    const sourcedProduct = srcProducts.find((sp) => sp.id === cartItem.productId);
    if (!sourcedProduct) {
      throw Error('No source product found'); // TODO: implement skipping of invalid items to be able to checkout nevertheless
    }
    const itemPrice = getCartItemTotalPrice(cartItem, sourcedProduct);
    if (!itemPrice) {
      throw Error('Item price calculation error'); // TODO: implement skipping of invalid items to be able to checkout nevertheless
    }

    const referenceId2 = `${referenceId}-${cartItem.id}`;

    // const cartItemTotalPrices: Price[] = [...getCartItemsTotalPrices(items, srcProducts)];
    // console.log('cartItemTotalPrices: ', cartItemTotalPrices);

    // const totalPrice = calculateTotalPrice(cartItemTotalPrices);
    // const totalAmount = `${totalPrice.integer}.${totalPrice.decimal}`;
    const totalAmount = `${itemPrice.integer}.${itemPrice.decimal}`;

    const itemsData: PaypalPurchaseUnitItem[] = []; // TODO:

    itemsData.push(
      ...createGroupedItemData(
        currency_code,
        cartItem,
        sourcedProduct,
        userId,
        orderSysId,
      ),
    );

    purchaseUnits.push({
      // this should be parameterized and returned
      reference_id: referenceId2, // is orderSysId
      description: 'purchase_unit_description', // `hey.holiday - ${referenceId}`,

      // sysId | countryId | public/group | catType | orgId | prodId | evtId | ordId | userId
      custom_id: customId, // TODO: use if possible instead of misuse of createSku
      soft_descriptor: softDescriptor, // The descriptor that shows up after a transaction has been authorized. // maybe i18n
      hard_descriptor: hardDescriptor, // The descriptor that shows up after a transaction has settled // maybe i18n
      amount: {
        currency_code: totalCurrency,
        value: totalAmount,
        breakdown: {
          item_total: {
            currency_code: totalCurrency,
            value: totalAmount,
          },
          shipping: {
            currency_code: shippingCurrency,
            value: shippingAmount,
          },
          handling: {
            currency_code: handlingCurrency,
            value: handlingAmount,
          },
          tax_total: {
            currency_code: taxTotalCurrency,
            value: taxTotalAmount,
          },
          shipping_discount: {
            currency_code: shippingDiscountCurrency,
            value: shippingDiscountAmount,
          },
        },
      },
      items: itemsData,
      // shipping: createShippingData('United States Postal Service', shippingAddressData),
    });
  });

  return purchaseUnits;
};

export function createGroupedItemData(
  currency: Currency,
  item: ShoppingCartItemDocumentData,
  srcProduct: SourcedGQLProduct,
  userId: string,
  orderSystemId: string,
  language: string = 'en', // TODO:
  // createCartItemId: () => string,
): PaypalPurchaseUnitItem[] {
  console.log('createGroupedItemData - itemData: ', srcProduct);

  const productLocales =
    srcProduct.localizations.find((locale) => locale.localeId === ProductLocale.en) ||
    null;

  // TODO: check if is sourced through gatsby
  const organisationId = srcProduct.meta.organisationId || '';

  if (!organisationId) {
    console.warn('no organisation id');
  }

  const groupItems: PaypalPurchaseUnitItem[] = [];

  for (let itemCfgIdx = 0; itemCfgIdx < item.itemConfigurations.length; itemCfgIdx++) {
    let { count, priceGroupId, priceSystemId,participants } = item.itemConfigurations[itemCfgIdx];
    if (count && count > 0) {
      // NOTE: rebuilding the date regarding offset
      const tzDate = dayjs(item.eventDate.toDate()); // .tz('Europe/Berlin'); // should already be correct => no transform needed here?
      // console.log('--------');
      // console.log(dayjs(item.eventDate.toDate()));
      // console.log('--------');
      // const eventDateWithUtcOffset = tzDate.add(tzDate.utcOffset(), 'minute');
      // const description = `Price group [${priceGroupId}]`;

      const getFixedT = i18n.getFixedT(language === 'cimode' ? 'en' : null); // if cimode use en, otherwise use i18n current locale
      const dictKey = `shop:screen-product.price-group.${priceGroupId}.label`;

      const priceGroupIdLocalization: string =
        getFixedT(dictKey, '', {
          // returnObjects: true,
        }) || '';

      const foodOptionIdLocalization = 'FoodOption'; // TODO
      const pickupIdLocalization = 'DEFAULT'; // TODO
      const languageIdLocalization = 'EN'; // TODO

      console.log('Translated product price group name: ', priceGroupIdLocalization);

      const description = `[${priceGroupIdLocalization}] [${pickupIdLocalization}] [${foodOptionIdLocalization}] [${languageIdLocalization}]`; // TODO: list food/pickup/lang
      // let i18nItems: T[] =
      //   getFixedT(dictKey, '', {
      //     // returnObjects: true,
      //   }) || [];

      const cartItemId = createDocumentId();

      const productEventId = tzDate.toDate().toISOString();

      // TODO: consider adding a general orderType + modifications ()
      const sku = createSku(
        // systemId
        // organisationId
        item.productId,
        priceSystemId,
        priceGroupId,
        productEventId,
        userId,
        organisationId,
        orderSystemId,
        cartItemId,
      );

      // const sku = `${item.productId}#${productEventId}#${cartItemId}#${priceGroupId}`; //userId

      // TODO: for real sku of item may suffice: cartItemId?

      // sysId | countryId | public/group | catType | orgId | prodId | evtId | ordId | userId | cartItemId
      const skuuu = '[holiday]#[P/G]#[A/B/T/R/]#[]';

      const sku2 = 'sku';
      const allExtraFees: Price[] = [];
    let totalPickupValue: number =0;
  if (item.itemConfigurations[itemCfgIdx].participants?.length) {
    item.itemConfigurations[itemCfgIdx].participants.forEach((p) => {
      const pickupInfo = parsePickupOptionId(p.options.pickup);
      if (!pickupInfo || pickupInfo.type === PickupOptionType.DEFAULT) {
        return;
      }
      p.pickup=calculatePickupFeeFromPickupLocationInformation(pickupInfo, srcProduct);
      if(p.pickup){
        let price = p.pickup.integer + '.' + p.pickup.decimal;
        console.log(parseFloat(price),'totalPickupValue');
        totalPickupValue += parseFloat(price);
        // let convertedString = totalPickupValue.toFixed(2).toString();
        console.log(totalPickupValue,'totalPickupValue');

      }
    })
    console.log(totalPickupValue,'totalPickupValue')
  }
  

    console.log('groupPricedsddssd', participants, item.itemConfigurations[itemCfgIdx],item.quantity)
    
      const posPrice = calculatePriceGroupPrice(
        item.itemConfigurations[itemCfgIdx],
        srcProduct.priceSystems,
      );
      calculateTotalPrice([posPrice, ...allExtraFees])
      console.log('createGroupedItemData - posTotalPrice: ', calculateTotalPrice([posPrice, ...allExtraFees]));
      // const totalPrices: Price[] = getCartItemsTotalPrices([item], [srcProduct]);
      // const totalItemPrice = posTotalPrice; // calculateTotalPrice(totalPrices);
      // const itemTotalAmount = `${totalItemPrice.integer}.${totalItemPrice.decimal}`;

      let itemPrice: any = createPriceString(posPrice);
      if (priceGroupIdLocalization == 'Private group') {
        count = 1;
        itemPrice = srcProduct?.priceSystems[0]?.privateBooking?.basePrice;
      }
      const currencyConversionRatesToEuro: Record<string, number> = {
        EUR: 1.0,
        USD: 1.09,
        BTC: 0.000028,
        HRK: 7.56,
      };
      let ItemPriceConverted = itemPrice * currencyConversionRatesToEuro[currency];
      let convertedValue = Math.round(ItemPriceConverted * 100) / 100;
      const availableGroupQuantityDiscounts = item.groupQuantityDiscount ? item.groupQuantityDiscount : null;
      let totalParticipantsCount = 0;
      item.itemConfigurations.forEach((item) => {
        totalParticipantsCount += item.count;
      });
      const sumOfCount = totalParticipantsCount;
      const applicableDiscount =
        Array.isArray(availableGroupQuantityDiscounts) && availableGroupQuantityDiscounts.length > 0
          ? availableGroupQuantityDiscounts.reduce((result, discount) => {
            if (sumOfCount >= discount.discountMinQuantity && discount.discountMinQuantity > (result?.discountMinQuantity || 0)) {
              return discount;
            }
            return result;
          }, null)
          : null;
      let discountAmount = 0;
      let discountPercentage = 0;
      let discountType = null;
      if (applicableDiscount != null) {
        discountType = applicableDiscount.discountType;
        if (discountType == "fixed") {
          discountAmount = sumOfCount * applicableDiscount.discountFixed
          const priceFloat = discountAmount * currencyConversionRatesToEuro[currency];
          let convertedDiscountValue = Math.round(priceFloat * 100) / 100;
          discountAmount = convertedDiscountValue;
        }
        else if (discountType == "percentage") {
          discountPercentage = applicableDiscount.discountPercentage
          discountAmount = ( count *convertedValue * discountPercentage) / 100;
        }
      }
      console.log('discountAmount', discountAmount);
      let finalPrice = discountAmount > 0 ? (convertedValue * count) - discountAmount : convertedValue;
      count = discountAmount > 0 ? 1 : count;

      // TODO: type
      const itemData: any = {
        name: productLocales?.locales?.name || '', // TODO: use product.id as fallback
        description,
        productEventId,
        participants,
        sku,
        unit_amount: {
          currency_code: currency,
          value: finalPrice.toFixed(2),
        },
        tax: {
          currency_code: currency,
          value: '0.00',
        },
        quantity: count, // TODO: not quite sure if should be number or string ???
        // quantity: count.toString(),
        category: 'DIGITAL_GOODS', // TODO: const + google
        imageFiles:srcProduct.imageFiles
      };
      console.log('createGroupedItemData - itemData: ', itemData);
      console.log('createGroupedItemData - productLocales: ', productLocales);
      groupItems.push(itemData);
    }
  }

  console.log('--------------groupItems:', groupItems);

  return groupItems;
}

// function createGroupedItemData(
//   currency: Currency,
//   item: ShoppingCartItemDocumentData,
//   srcProduct: SourcedGQLProduct,
//   userId: string,
//   orderSystemId: string,
//   // createCartItemId: () => string,
// ): PaypalPurchaseUnitItem[] {
//   const productLocales =
//     srcProduct.localizations.find((locale) => locale.localeId === ProductLocale.en) ||
//     null;

//   // TODO: check if is sourced through gatsby
//   const organisationId = srcProduct.meta.organisationId || '';

//   if (!organisationId) {
//     console.warn('no organisation id');
//   }

//   const groupItems: PaypalPurchaseUnitItem[] = [];

//   for (let itemCfgIdx = 0; itemCfgIdx < item.itemConfigurations.length; itemCfgIdx++) {
//     const { count, priceGroupId, priceSystemId } = item.itemConfigurations[itemCfgIdx];
//     if (count && count > 0) {
//       // NOTE: rebuilding the date regarding offset
//       const tzDate = dayjs(item.eventDate.toDate()).tz('Europe/Berlin'); // should already be correct => no transform needed here?
//       // const eventDateWithUtcOffset = tzDate.add(tzDate.utcOffset(), 'minute');
//       const description = `Price group [${priceGroupId}]`;

//       const cartItemId = createDocumentId();

//       // TODO: consider adding a general orderType + modifications ()
//       const sku = createSku(
//         // systemId
//         // organisationId
//         item.productId,
//         priceSystemId,
//         priceGroupId,
//         tzDate.toDate().toISOString(),
//         userId,
//         organisationId,
//         orderSystemId,
//         cartItemId,
//       );
//       const posTotalPrice = calculatePriceGroupTotalPrice(
//         item.itemConfigurations[itemCfgIdx],
//         srcProduct.priceSystems,
//       );
//       const posPrice = calculatePriceGroupPrice(
//         item.itemConfigurations[itemCfgIdx],
//         srcProduct.priceSystems,
//       );
//       console.log('createGroupedItemData - posTotalPrice: ', posTotalPrice);
//       console.log('createGroupedItemData - posPrice: ', posPrice);
//       // const totalPrices: Price[] = getCartItemsTotalPrices([item], [srcProduct]);
//       // const totalItemPrice = posTotalPrice; // calculateTotalPrice(totalPrices);
//       // const itemTotalAmount = `${totalItemPrice.integer}.${totalItemPrice.decimal}`;

//       const itemPrice = createPriceString(posPrice);

//       // TODO: type
//       const itemData = {
//         name: productLocales?.locales?.name || '', // TODO: use product.id as fallback
//         description,
//         sku,
//         unit_amount: {
//           currency_code: currency,
//           value: itemPrice,
//         },
//         tax: {
//           currency_code: currency,
//           value: '0.00',
//         },
//         // quantity: count.toString(),
//         quantity: count,
//         category: 'DIGITAL_GOODS', // TODO: const + google
//       };
//       console.log('createGroupedItemData - itemData: ', itemData);
//       console.log('createGroupedItemData - productLocales: ', productLocales);
//       groupItems.push(itemData);
//     }
//   }

//   console.log('--------------groupItems:', groupItems);

//   return groupItems;
// }

// export function getOrderData(
//   orderSysId: string,
//   currency_code: Currency,
//   items: ShoppingCartItemDocumentData[],
//   srcProducts: SourcedGQLProduct[],
//   userId: string,
// ): PaypalOrderData {
//   // FIXME: here we could get an proper transactionObj from server based on params in cart => get products current priceSystem and validate
//   // TODO: save state and if pp transaction i successful then save the doc with this id here:
//   // const orderId = createCartItemId();
//   const referenceId = orderSysId;
//   const customId = `${userId}#${orderSysId}`; // TODO. switch with sku?
//   // TODO: take orderId and create a "log" document of the order with extra order details => take those details and copy them over to org order => after successful pp order

//   console.log('getOrderData - referenceId: ', referenceId);
//   console.log('getOrderData - currency_code: ', currency_code);
//   console.log('getOrderData - items: ', items);
//   console.log('getOrderData - srcProducts: ', srcProducts);
//   const cartItemTotalPrices: Price[] = [...getCartItemsTotalPrices(items, srcProducts)];
//   console.log('cartItemTotalPrices: ', cartItemTotalPrices);

//   const totalPrice = calculateTotalPrice(cartItemTotalPrices);
//   console.log('totalPrice: ', totalPrice);
//   console.log('-------------------');

//   const softDescriptor = orderSysId;
//   const hardDescriptor = `${`Your order (${orderSysId}) at Hey.Holiday - Completed`}`;

//   // const totalAmount = '0.00';
//   const totalAmount = `${totalPrice.integer}.${totalPrice.decimal}`;
//   const totalCurrency = currency_code;
//   const shippingAmount = '0.00';
//   const shippingCurrency = currency_code;
//   const handlingAmount = '0.00';
//   const handlingCurrency = currency_code;
//   const taxTotalAmount = '0.00';
//   const taxTotalCurrency = currency_code;
//   const shippingDiscountAmount = '0.00';
//   const shippingDiscountCurrency = currency_code;

//   // TODO: shipping later for physical goods
//   // const shippingAddressData = {
//   //   name: {
//   //     full_name: '',
//   //     surname: '',
//   //   },
//   //   address_line_1: '',
//   //   address_line_2: '',
//   //   admin_area_2: '',
//   //   admin_area_1: '',
//   //   postal_code: '',
//   //   country_code: '',
//   // };

//   // function createShippingData(
//   //   method: string,
//   //   address: PaypalShippingAddressData,
//   // ): PaypalPurchaseUnitShipping {
//   //   return {
//   //     method,
//   //     address,
//   //   };
//   // }

//   const itemsData: PaypalPurchaseUnitItem[] = [];

//   for (let itemIdx = 0; itemIdx < items.length; itemIdx++) {
//     console.log(`/// creating stuff for items[${itemIdx}] - item: `, items[itemIdx]);
//     console.log('/// srcProducts[0]: ', srcProducts);
//     if (items[itemIdx].itemConfigurations.length >= 1) {
//       // if(items[itemIdx].itemConfigurations[])
//       itemsData.push(
//         ...createGroupedItemData(
//           currency_code,
//           items[itemIdx],
//           srcProducts[0],
//           userId,
//           orderSysId,
//         ),
//       );
//     } else {
//       // TODO: product in cart should always support itemConfigurations...
//       console.error('got triggered - check if happens');
//       itemsData.push(
//         createItemData(currency_code, items[itemIdx], srcProducts[0], userId, orderSysId),
//       );
//     }
//   }

//   // TODO: split products by groups
//   // const itemsData = [
//   //   ...items.map((item) => {
//   //     if (item.itemConfigurations.length > 1) {
//   //       return createItemData(currency_code, item, srcProducts[0]);
//   //       // return ...createGroupedItemData(currency_code, item, srcProducts[0]);
//   //     }
//   //     return createItemData(currency_code, item, srcProducts[0]);
//   //   }),
//   // ];
//   console.log('getOrderData() - itemsData: ', itemsData);

//   // FIXME: Split by organisation into purchase units or better into product(productGroup) purchase unit
//   const data: PaypalOrderData = {
//     // what is a purchase unit consider using per product or per org
//     // consider using event wise?
//     purchase_units: [
//       {
//         reference_id: referenceId, // is orderSysId
//         description: '_description', // `hey.holiday - ${referenceId}`,

//         custom_id: customId, // TODO: use if possible instead of misuse of createSku
//         soft_descriptor: softDescriptor, // The descriptor that shows up after a transaction has been authorized. // maybe i18n
//         hard_descriptor: hardDescriptor, // The descriptor that shows up after a transaction has settled // maybe i18n
//         amount: {
//           currency_code: totalCurrency,
//           value: totalAmount,
//           breakdown: {
//             item_total: {
//               currency_code: totalCurrency,
//               value: totalAmount,
//             },
//             shipping: {
//               currency_code: shippingCurrency,
//               value: shippingAmount,
//             },
//             handling: {
//               currency_code: handlingCurrency,
//               value: handlingAmount,
//             },
//             tax_total: {
//               currency_code: taxTotalCurrency,
//               value: taxTotalAmount,
//             },
//             shipping_discount: {
//               currency_code: ,
//               value: shippingDiscountAmount,
//             },
//           },
//         },
//         items: itemsData,
//         // shipping: createShippingData('United States Postal Service', shippingAddressData),
//       },
//     ],
//   };

//   console.log('itemsData: ', itemsData);
//   console.log('getOrderData - data: ', data);

//   return data;
// }
