import * as React from 'react';
import { AppProps, BaseComponentProps } from 'src/app/types';

// TODO: chechout PropSomething from 'gatsby' namespace => take a look at seo.tsx

/**
 * removes unnecessary AppProps from passing down to presentational components
 * wrap your components with this if you get dom element attribute warnings
 */
export default function withoutAppProps<
  // eslint-disable-next-line @typescript-eslint/naming-convention
  APP_PROPS extends BaseComponentProps & AppProps = BaseComponentProps & AppProps,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COMPONENT_PROPS extends BaseComponentProps = BaseComponentProps
>(
  component: React.FunctionComponent<COMPONENT_PROPS>,
  props: APP_PROPS,
): React.ReactElement<COMPONENT_PROPS> {
  const {
    children,
    hideAppbar,
    path,
    uri,
    location,
    navigate,
    pageContext,
    params,
    pageResources,
    pathContext,
    ...passProps
  } = props;
  const WrappedComponent = component;
  // console.log('WithoutAppProps props: ', props);
  // console.log('WithoutAppProps passProps: ', passProps);
  return (
    <WrappedComponent {...((passProps as unknown) as COMPONENT_PROPS)}>{children}</WrappedComponent>
  );
}

export { withoutAppProps };
