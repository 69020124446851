import { orderByKey } from 'firebase/database';
import {
  collection,
  collectionGroup,
  CollectionReference,
  FieldPath,
  orderBy,
  query,
  startAt,
  where,
} from 'firebase/firestore';
import * as React from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { LoadingIndicator } from 'src/app/components';

import { MessageType } from 'src/app/constants';
import { FirestoreCollectionData } from 'src/app/container';
import { BaseMessageDocument } from '../message.types';
import { MessagesListItem } from './MessagesListItem';

interface MessagesListProps {
  messageCollectionPath: string;
  messageType: MessageType;
  onDeleteMessage: (messageId: string) => void;
  uid: string;
}

type T = {};

export const MessagesList = (props: MessagesListProps) => {
  const { messageCollectionPath, messageType, onDeleteMessage, uid } = props;

  // TODO: if messageCollectionPath is message_user.. then get all users messages from message_organization
  // TODO: add real fetch pagination
  return (
    <>
      <FirestoreCollectionData<BaseMessageDocument>
        collectionPath={`${messageCollectionPath}/${uid}/${messageType}`}
        queryLimit={200}
        queryOrderBy={'meta.createdAt'}
        queryOrderDirection={'desc'}
        renderElements={({ data: messagesList, status }) => {
          if (status === 'loading') {
            return <LoadingIndicator />;
          }
          if (status === 'error') {
            return 'Error';
            // <LocalizedErrorIndicator // TODO:
            //   i18nContext={''}
            //   i18nErrorCode={'LOADING_COLLECTION'}
            //   i18nErrorAreaCode={'message'}
            // />
          }
          return status !== 'success' || !messagesList?.length
            ? null
            : messagesList.map((msgItem) => {
                return (
                  <div key={msgItem.id}>
                    <MessagesListItem
                      message={msgItem}
                      messageType={messageType}
                      onDeleteMessage={onDeleteMessage}
                    />
                  </div>
                );
              });
        }}
      />
      {/* {messageCollectionPath === 'message_user' && (
        <UserOrgMessages
          messageType={
            messageType === MessageType.INBOUND
              ? MessageType.OUTBOUND
              : MessageType.INBOUND
          }
          uid={uid}
          onDeleteMessage={onDeleteMessage}
        />
      )} */}
    </>
  );
};

const UserOrgMessages = (props: {
  messageType: MessageType;
  onDeleteMessage: (messageId: string) => void;
  uid: string;
}) => {
  const { messageType, onDeleteMessage, uid } = props;

  const firestore = useFirestore();
  const collectionPath = `${messageType}`;
  const collectionRef = collectionGroup(
    firestore,
    collectionPath,
  ) as CollectionReference<BaseMessageDocument>;
  const constraints = [
    where('meta.fromId', '==', `${uid}`),
    orderBy('id'), // this narrows it down to message_organisaton
    startAt('message_organisation'),
  ] as any;
  const collectionQuery = query<BaseMessageDocument>(collectionRef, ...constraints);
  const { status, data } = useFirestoreCollectionData<BaseMessageDocument>(
    collectionQuery,
    {
      idField: 'id',
    },
  );

  if (data) {
    data.forEach((d) => console.log(d));
  }

  return (
    <>
      {data?.map((msgItem) => {
        return (
          <div key={msgItem.id}>
            <MessagesListItem
              message={msgItem}
              messageType={messageType}
              onDeleteMessage={onDeleteMessage}
            />
          </div>
        );
      })}
    </>
  );
};
