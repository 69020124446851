import { NotificationType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';

import { getFirestore, deleteDoc, doc } from 'firebase/firestore';
import {
  DocumentDeleteErrorNotification,
  DocumentDeleteSuccessNotification,
} from 'src/app/components';

export interface UseDeleteDocument {
  deleteDocument: (id: string, collectionPath: string) => Promise<void>;
}

export const useDeleteDocument = (): UseDeleteDocument => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();

  const deleteDocument = async (id: string, collectionPath: string) => {
    if (!firebase || !isLoggedIn || !id || !collectionPath) {
      console.warn('Invalid delete operation params');
      return;
    }
    try {
      const store = getFirestore();
      const docRef = doc(store, `${collectionPath}/${id}`);

      await deleteDoc(docRef);
      createNotification(NotificationType.SUCCESS, DocumentDeleteSuccessNotification);
    } catch (err) {
      createNotification(NotificationType.ERROR, DocumentDeleteErrorNotification);
      console.warn('Error on delete operation - error: ', err);
    }
  };

  return { deleteDocument };
};
