import * as React from 'react';

import {
  ButtonTw,
  Localized
} from 'src/app/components';
import { SourcedGQLProduct, QuestionAboutProductDialogParams } from 'src/app/types';

import { useAppContext } from 'src/app/context';

import { DialogType, ProductQuestionType } from 'src/app/constants';
import { AvailabilityAndLanguages } from './AvailabilityAndLanguages';
import { PickupInformation } from './PickupInformation';
import { MeetingPointInformation } from './MeetingPointInformation';

import DirectionsBus from '@material-ui/icons/DirectionsBusOutlined';
import CommentOutlined from '@material-ui/icons/CommentOutlined';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

interface GeneralInformationContainerProps {
  elevation?: number;
  product: SourcedGQLProduct;
}

export const GeneralInformationContainer = ({
  elevation,
  product,
}: GeneralInformationContainerProps) => {
  const { openDialog } = useAppContext();

  const pageId = '/experience';
  const i18nPrefix = `shop:${pageId}`;

  const renderAvailabilityAndLanguages = function() {
    return (
      <AvailabilityAndLanguages product={product} />
    );
  }

  const [isDropdown, setDropdown] = React.useState(false);
  const toggleDropdownClass = () => {
    setDropdown(!isDropdown);
  };

  return (
    <div className='text-neutral-900 dark:text-neutral-50'>
      <h2 className='font-medium pb-3 text-p2 relative md:pb-5 md:font-bold md:text-h2' onClick={toggleDropdownClass}>
        <span className='text-accent-500 mr-2 align-text-bottom md:hidden'><DirectionsBus /></span>
        <Localized dictKey={`${i18nPrefix}.general-information.title`} />
        <span className={`absolute right-0 top-[2px] md:hidden ${isDropdown ? 'rotate-180' : '' }`}>
          <ArrowDropDown />
        </span>
      </h2>

      {/** //TODO: Consider showing a map here... */}
      {/* <LocationMapWidget
        location={location}
        locations={locations}
        mapLocations={{ zoomFactor: 11 }}
      >
        {timeline?.length ? (
          <Grid item xs={12}>
            <ProductTimeline timelineData={timeline} />
          </Grid>
        ) : null}
      </LocationMapWidget> */}

      <div className={`flex-col gap-9 pt-4 md:flex-row md:gap-4 md:pt-6 md:pb-2 ${isDropdown ? 'flex' : 'hidden md:flex' }`}>
        <div className='md:p-12 mb-4 rounded-lg  md:mb-0 md:bg-accent-400 md:bg-opacity-[0.08] md:shadow-box-light md:dark:bg-neutral-800 md:dark:shadow-box-dark flex-1'>
          {renderAvailabilityAndLanguages()}
        </div>
        <div className='md:p-12 mb-4 rounded-lg md:mb-0 md:bg-accent-400 md:bg-opacity-[0.08] md:shadow-box-light md:dark:bg-neutral-800 md:dark:shadow-box-dark flex-1 flex flex-col justify-between'>
          <MeetingPointInformation product={product} />
          <ButtonTw
            className={'w-full'}
            variant={'outline'}
            rounded={'rounded'}
            size={'lg'}
            onClick={() =>
              openDialog<QuestionAboutProductDialogParams>(
                DialogType.QUESTION_ABOUT_PRODUCT,
                {
                  product,
                  questionType: ProductQuestionType.PICKUP_AND_MEETINGPOINT,
                },
              )
            }
          >
            <CommentOutlined className='mr-2' />
            <Localized
              dictKey={`${i18nPrefix}.question.meeting-pickup.button.label`}
            />
          </ButtonTw>
        </div>
        {product.pickup.hasPickupService && (
          <div className='md:p-12 mb-4 rounded-lg md:mb-0 md:bg-accent-400 md:bg-opacity-[0.08] md:shadow-box-light md:dark:bg-neutral-800 md:dark:shadow-box-dark flex-1 flex flex-col justify-between'>
            <PickupInformation product={product} />
          </div>
        )}
      </div>
    </div>
  );
};
