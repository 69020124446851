import * as React from 'react';
import GatsbyImage from 'gatsby-image';

import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  BaseComponentProps,
  SourcedGQLContinent,
  SourcedGQLCountry,
  SourcedGQLCity,
} from 'src/app/types';
import {
  Button,
  Divider,
  Grid,
  Glass,
  ProductRating,
  ProductTags,
} from 'src/app/components';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useAppImages } from 'src/app/hooks';
import { useStyles } from 'src/app/context';

type ContentNavigationType = SourcedGQLContinent | SourcedGQLCountry | SourcedGQLCity; // TODO: etc

interface NavigationListItemProps<T extends ContentNavigationType>
  extends BaseComponentProps {
  item: T;
  extraChildren?: React.ReactNode;
  title?: React.ReactNode;
}

// TODO: switch based on <T>
// FIXME: blue background bug is here...

export const NavigationListItem = <T extends ContentNavigationType>(
  props: NavigationListItemProps<T>,
) => {
  const { children, extraChildren, item, title } = props;
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);

  const { fallbackImage } = useAppImages();
  // const itemHeight = '200px';
  const onWishlistClick = (e) => {
    // e.preventDefault();
    e.stopPropagation();
  };

  // NOTE: commented out due to button in button dom issues
  // const showWishlistButton = Boolean(
  //   typeof window !== 'undefined' && !window.location.pathname.startsWith('/wishlists'),
  // );

  const showWishlistButton = false;

  // TODO: use CardComponent for layout?
  // FIXME: Glass component is causing the blue background bug here
  return (
    <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 h-full dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative'>

      <div className='w-full md:w-1/3'>
        <GatsbyImage
          className={'rounded-tl-2xl rounded-tr-2xl md:rounded-bl-2xl md:rounded-tr-none h-full'}
          fluid={
            item?.imageFiles?.[0]?.childImageSharp?.fluid ||
            fallbackImage.childImageSharp.fluid
          }
          alt={'Navigation Item Title Image'}
        />
        {/** // FIXME: button cannot be descendant from button... => warp each the img area and the left side with a separate button each */}
        {showWishlistButton ? (
          <Button
            variant={'text'}
            onClick={onWishlistClick}
            className={'absolute top-4 right-4 text-accent-500 md:text-neutral-900 md:top-9 md:right-9 md:dark:text-neutral-50'}
          >
            <FavoriteBorderIcon />
          </Button>
        ) : null}
      </div>
      <div className='w-full h-full md:h-auto md:w-2/3'>
        <div className='flex flex-col p-4 h-full md:p-9 md:gap-8'>

          {title ? (
            <h3 className='text-p1 font-semibold md:text-h3 md:font-bold'>
              {title}
            </h3>
          ) : null}

          {item.meta?.tagIds !== undefined ? (
            <div className='py-2'>
              <ProductTags product={item} />
            </div>
          ) : null}

          {item.productData?.rating !== undefined ? (
            <ProductRating
              direction={'column'}
              product={item}
              reviewAnchorId={''}
            />
          ) : null}

          {children}
        </div>
      </div>

      {extraChildren}

    </div>
  );
};
