// import { BookingSystem } from 'src/app/constants';
import {
  calculatePickupFeeFromPickupLocationInformation,
  parsePickupOptionId,
} from 'src/lib/participant';

import {
  FormattedPrice,
  GroupDiscount,
  Price,
  PriceSystem,
  ShoppingCartItemConfiguration,
  ShoppingCartItemDocumentData,
  SourcedGQLProduct,
  DiscountInfo,
  publicQuantityDiscount
} from 'src/app/types';
import { BookingSystemType, PickupOptionType } from 'src/app/constants';

export const getLowestProductPriceSystemBasePrice = (
  product: SourcedGQLProduct,
  bookingSystemId: 'publicBooking' | 'privateBooking',
) => {
  const inititalToCompareWith = 1000000;
  let lowestPriceFound = inititalToCompareWith;

  if (!bookingSystemId) {
    return null;
  }

  for (let index = 0; index < product.priceSystems.length; index++) {
    if (
      product.priceSystems[index][bookingSystemId].basePrice &&
      product.priceSystems[index][bookingSystemId].basePrice < lowestPriceFound
    ) {
      lowestPriceFound = product.priceSystems[index][bookingSystemId].basePrice;
    }
  }
  if (lowestPriceFound === inititalToCompareWith) {
    return null;
  }
  return lowestPriceFound;
};

export const getProductPriceSystemBasePrice = (
  product: SourcedGQLProduct,
  priceSystemId: string,
  bookingSystemId: 'publicBooking' | 'privateBooking',
) => {
  const priceSystem = product.priceSystems.find((ps) => ps.id === priceSystemId);
  return priceSystem ? priceSystem[bookingSystemId]?.basePrice || null : null;
};

const getNumberAsDecimal = (number: number) => {
  const int = Math.abs(parseFloat(number.toString()));
  const intRest = parseInt(int.toString(), 10);
  // parse number with source precision
  return Number(
    (int - intRest).toFixed(Math.abs(`${int}`.length - `${intRest}`.length - 1)),
  );
};

export function getPercentageGroupDiscountPrice(
  { integer, decimal }: Price,
  discountGroup: GroupDiscount,
): Price {
  if (!discountGroup.discountPercentage) {
    throw Error('discountPercentage missing  in priceGroup');
  }

  const combined = integer * 100 + decimal;
  const diff =
    Math.abs(combined - (combined / 100) * discountGroup.discountPercentage) / 100;

  // NOTE: most outer Math.floor is for rounding up in favor of shop
  const diffDeci = Math.floor(getNumberAsDecimal(diff));

  const diffInt = Math.floor(integer - Math.floor(diff));

  const calcDeci = Math.abs(decimal - diffDeci);

  const isDeciDeltaGreaterOne = decimal - diffDeci < 0;

  let calcInt = integer - diffInt;

  if (isDeciDeltaGreaterOne) {
    calcInt -= 1;
  }

  if (calcInt < 0) {
    throw Error('discountFixed result is negative');
  }

  // console.log('getPercentageGroupDiscountPrice - diff', diff);
  // console.log('getPercentageGroupDiscountPrice - diffInt', diffInt);
  // console.log('getPercentageGroupDiscountPrice - diffDeci', diffDeci);
  // console.log('getPercentageGroupDiscountPrice => calcInt', calcInt);
  // console.log('getPercentageGroupDiscountPrice => calcDeci', calcDeci);
  // console.log('getPercentageGroupDiscountPrice ---');
  return { integer: calcInt, decimal: calcDeci };
}

export function getFixedGroupDiscountPrice(
  { integer, decimal }: Price,
  discountGroup: GroupDiscount,
): Price {
  // TODO: need type guard for only groupDiscounts
  if (typeof discountGroup.discountFixed === 'undefined') {
    throw Error('discountFixed missing in priceGroup');
  }
  const discDeci = getNumberAsDecimal(discountGroup.discountFixed) * 100;
  const discInt = Math.floor(discountGroup.discountFixed);

  const calcDeci = Math.abs(decimal - discDeci);

  const isDeciDeltaGreaterOne = decimal - discDeci < 0;

  let calcInt = integer - discInt;

  if (isDeciDeltaGreaterOne) {
    calcInt -= 1;
  }

  if (calcInt < 0) {
    throw Error('discountFixed result is negative');
  }

  // console.log('getFixedGroupDiscountPrice - decimal: ', decimal);
  // console.log('getFixedGroupDiscountPrice - discDeci: ', discDeci);
  // console.log('getFixedGroupDiscountPrice => int: ', calcInt);
  // console.log('getFixedGroupDiscountPrice => deci: ', calcDeci);
  // console.log('getFixedGroupDiscountPrice ---');
  return { integer: calcInt, decimal: calcDeci };
}

// TODO:
// function getQuantityDiscountPrice(
//   basePrice: Price,
//   discountGroup: GroupDiscount,
//   count: number,
// ): Price {
//   // let integer = 0;
//   // let decimal = 0;
//   // const combined = basePrice.integer * 100 + basePrice.decimal;
//   // const diff = Math.abs(combined - (combined / 100) * discountGroup.reduceAmountPercentage);
//   // integer = Math.floor(diff / 100);
//   // decimal = parseInt((diff % 100).toFixed(0), 10);
//   return { integer, decimal };
// }

// TODO: should throw error (needs to be wrapped in try/catch..)
export function calculatePriceFromPriceString(priceString: string | number): Price {
  let decimal:any = 0;
  let integer = 0;
  if (typeof priceString === 'undefined') {
    // return { decimal: 'invalid', integer: 'invalid' };
    throw Error('priceString is undefined');
  }
  console.log('calculatePriceFromPriceString for: ', priceString);
  console.log('calculatePriceFromPriceString typeof: ', priceString.toString().split('.')[1]);
  let num = 42;

  let paddedNum = String(num).padStart(1, '0'); // '00042'
  console.log('calculatePriceFromPriceString typeof: ', priceString.toString().split('.')[1]);


  if (typeof priceString === 'number') {
    integer = parseInt(priceString.toString().split('.')[0], 10);
    decimal = priceString.toString().split('.')[1]|| 0; // FIXME: this is not nice...
  } else {
    integer = parseInt(priceString.split('.')[0], 10);
    decimal = priceString.toString().split('.')[1]|| 0; // FIXME: this is not nice...
  }

  if (Number.isNaN(integer) || Number.isNaN(decimal)) {
    throw new Error('Integer or decimal is NaN');
  }

  // If the decimal value is single digit and not 0, double it
  if (decimal < 10 && decimal !== 0&& decimal.length==1 ) {
    decimal *= 10;
  }
  console.log('Price Float:', decimal, integer);

  return { decimal, integer };
}

export function calculateTotalPrice(prices: Price[]): Price {
  let sumI = 0;
  let sumD = 0;
  for (let index = 0; index < prices.length; index++) {
    sumD += prices[index].decimal;
    sumI += prices[index].integer;
  }
  const intPart = Math.floor(sumD / 100);
  sumD -= 100 * intPart;
  sumI += intPart;
  return { integer: sumI, decimal: sumD };
}

export function calculateTotalSum(decimal: number, integer: number): Price {
  let totalInteger = integer;
  let totalDecimal = decimal;
  if (decimal > 99) {
    const intToAdd = Math.floor(decimal / 100);
    if (intToAdd) {
      totalInteger += intToAdd;
    }
    if (decimal / 100 - intToAdd > 0) {
      totalDecimal -= intToAdd * 100;
    }
  }
  return { decimal: totalDecimal, integer: totalInteger };
}

export function calculatePriceGroupPrice(
  priceGroup: ShoppingCartItemConfiguration,
  priceSystems: PriceSystem[],
  bookingSystem: 'publicBooking' | 'privateBooking' | null = 'publicBooking'
): Price {
  const priceSystem =
    priceSystems?.find((ps) => ps.id === priceGroup.priceSystemId) || null;

  // FIXME: get bookingSystem mode and use it here

  if (!priceSystem?.basePrice && !priceSystem?.[bookingSystem]?.basePrice) {
    console.error('no base price in price systemSystem:', priceSystem);
    // throw Error(`no base price in price systemSystem`);
  }

  const pubSettings = priceSystem?.[bookingSystem]; // TODO: what about private bookings
  const basePrice =
    priceSystem?.basePrice || priceSystem?.[bookingSystem]?.basePrice || '00.00'; // maybe move inside of bookingSystem (for each pub and priv?) - yess
  const price: Price = calculatePriceFromPriceString(basePrice) as Price;
  const group = pubSettings?.groupDiscounts.find(
    (pgd) => pgd.id === priceGroup.priceGroupId,
  );
  let endPrice: Price = price; // TODO: what about fixed discount?
  if (group) {
    if (group.discountType === 'percentage') {
      endPrice = getPercentageGroupDiscountPrice(price, group);
    }
    if (group.discountType === 'fixed') {
      endPrice = getFixedGroupDiscountPrice(price, group);
    }
  }
  return endPrice;
}

export function calculatePriceGroupTotalPrice(
  priceGroupTicket: ShoppingCartItemConfiguration,
  priceSystems: PriceSystem[],
  bookingSystem: 'publicBooking' | 'privateBooking' | null = 'publicBooking'
): Price {
  const { count } = priceGroupTicket;
  const groupPrice = calculatePriceGroupPrice(priceGroupTicket, priceSystems,bookingSystem);

  let sumI = 0;
  let sumD = (bookingSystem == BookingSystemType.PUBLIC) ?(groupPrice.decimal * count): groupPrice.decimal ;
  sumI = Math.floor(sumD / 100);
  sumD -= sumI * 100;
  sumI = (bookingSystem == BookingSystemType.PUBLIC) ? (groupPrice.integer * count + sumI) : (groupPrice.integer + sumI) ;
  return { integer: sumI, decimal: sumD };
}

export function calculatePriceGroupTotalPriceWithExtraFees(
  priceGroupTicket: ShoppingCartItemConfiguration,
  priceSystems: PriceSystem[],
  product: SourcedGQLProduct,
  bookingSystem: 'publicBooking' | 'privateBooking' | null = 'publicBooking'
): Price {
  const { count } = priceGroupTicket;
  const groupPrice = calculatePriceGroupPrice(priceGroupTicket, priceSystems, bookingSystem);
  const allExtraFees: Price[] = [];

  if (priceGroupTicket.participants?.length) {
    priceGroupTicket.participants.forEach((p) => {
      const pickupInfo = parsePickupOptionId(p.options.pickup);
      if (!pickupInfo || pickupInfo.type === PickupOptionType.DEFAULT) {
        return;
      }
      allExtraFees.push(
        calculatePickupFeeFromPickupLocationInformation(pickupInfo, product),
      );
    });
  }

  let sumI = 0;
  let sumD = (bookingSystem == BookingSystemType.PUBLIC) ? (groupPrice.decimal * count) : groupPrice.decimal;
  sumI = Math.floor(sumD / 100);
  sumD -= sumI * 100;
  sumI = (bookingSystem == BookingSystemType.PUBLIC) ? (groupPrice.integer * count + sumI) :(groupPrice.integer  + sumI) ;
  const res = { integer: sumI, decimal: sumD };
  return calculateTotalPrice([res, ...allExtraFees]);
}

export function getCartItemTotalPrice(
  shoppingCartItem: ShoppingCartItemDocumentData,
  sourceProduct: SourcedGQLProduct,
) {

  let itemTotalPrice: Price | null = null;

  if (shoppingCartItem.itemConfigurations?.length) {
    const cartItemGroupPrices: Price[] = [];
    for (
      let cartItemConfigIdx = 0;
      cartItemConfigIdx < shoppingCartItem.itemConfigurations.length;
      cartItemConfigIdx++
    ) {
      // console.log('getCartItemsTotalPrices - shoppingCartItems:', shoppingCartItems);
      // console.log(
      //   'getCartItemsTotalPrices - shoppingCartItemsSrcProducts:',
      //   shoppingCartItemsSrcProducts,
      // );
      if (
        shoppingCartItem.itemConfigurations[cartItemConfigIdx]?.count &&
        sourceProduct?.priceSystems?.length
      ) {
        const priceSystems = sourceProduct.priceSystems || [];
        if (priceSystems?.length) {
          // TODO
          // calculate base price * count
          // calculate group discount
          // calculate qty discount

          // const groupPrice = calculatePriceGroupTotalPrice(
          //   shoppingCartItems[cartItemIdx].itemConfigurations[cartItemConfigIdx],
          //   priceSystems,
          // );
          const groupPrice = calculatePriceGroupTotalPriceWithExtraFees(
            shoppingCartItem.itemConfigurations[cartItemConfigIdx],
            priceSystems,
            sourceProduct,
            shoppingCartItem.bookingSystemType
          );
          // console.log('groupPrice: ', groupPrice);
          cartItemGroupPrices.push(groupPrice);
        }
      }
    }
    // console.log('cartItemGroupPrices: ', cartItemGroupPrices);
    itemTotalPrice = calculateTotalPrice(cartItemGroupPrices);
  } // TODO: else ?
  let finalPrice =itemTotalPrice;
  const availableGroupQuantityDiscounts = shoppingCartItem.groupQuantityDiscount ? shoppingCartItem.groupQuantityDiscount : null;
  let totalParticipantsCount = 0;
  shoppingCartItem.itemConfigurations.forEach((item) => {
    totalParticipantsCount += item.count;
  });

  const sumOfCount = totalParticipantsCount;
  const applicableDiscount =
    Array.isArray(availableGroupQuantityDiscounts) && availableGroupQuantityDiscounts.length > 0
      ? availableGroupQuantityDiscounts.reduce((result, discount) => {
        if (sumOfCount >= discount.discountMinQuantity && discount.discountMinQuantity > (result?.discountMinQuantity || 0)) {
          return discount;
        }
        return result;
      }, null)
      : null;

  let discountAmount = 0;
  let discountPercentage = 0;
  let discountType = null;
  if (applicableDiscount != null) {
    discountType = applicableDiscount.discountType;

    if (discountType == "fixed") {
      discountAmount = shoppingCartItem?.bookingSystemType === BookingSystemType.PUBLIC ?  sumOfCount * (applicableDiscount.discountFixed || 0) : (applicableDiscount.discountFixed || 0)
    }
    else if (discountType == "percentage") {
      discountPercentage = applicableDiscount.discountPercentage || 0
      discountAmount = convertPriceToFloat(itemTotalPrice) * discountPercentage / 100;
    }
  }
  finalPrice = calculatePriceFromPriceString(discountAmount > 0 ? convertPriceToFloat(itemTotalPrice) - discountAmount : convertPriceToFloat(itemTotalPrice));
  return finalPrice;
}

export function getCartItemsTotalPrices(
  shoppingCartItems: ShoppingCartItemDocumentData[],
  shoppingCartItemsSrcProducts: SourcedGQLProduct[],
): Price[] {
  const cartItemTotalPrices: Price[] = [];
console.log("shoppingCartItems..",shoppingCartItems)
  for (let cartItemIdx = 0; cartItemIdx < shoppingCartItems.length; cartItemIdx++) {
    const cartItemTotalPrice = getCartItemTotalPrice(
      shoppingCartItems[cartItemIdx],
      shoppingCartItemsSrcProducts[cartItemIdx],
    );
    if (!cartItemTotalPrice) {
      throw Error('item total price calculation error');
    }
    cartItemTotalPrices.push(cartItemTotalPrice);
  }

  return cartItemTotalPrices;
}

export const padDecimalAmount = (amount: number, maxLength: number = 2) =>
  amount.toString().padStart(maxLength, '0').slice(0, maxLength);

export const padPrice = (price: Price, currencyId: string): FormattedPrice => {
  return {
    decimal: padDecimalAmount(price.decimal, currencyId === 'BTC' ? 6 : 2), // FIXME does not work with BTC
    integer: price.integer.toString(),
  };
};

export const sliceIntegerLength = (number: number, sliceLength: number = 2) =>
  parseInt(number.toString().slice(0, sliceLength), 10);

export const createPriceString = (price: Price) => {
  // TODO: const maybe throw if not int/deci present...
  if(price.decimal===0){
    return price.integer
  }else{
    return `${price.integer}.${price.decimal}`;
  }
};

// TODO: should place somewhere locales related?
const getCurrencySign = (currencyId: string) => {
  switch (currencyId) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'BTC':
      return '₿';
    default:
      throw Error('unknown currency id');
  }
};

export const createPriceStringWithCurrency = (price: Price, currencyId: string) => {
  return `${createPriceString(price)} ${getCurrencySign(currencyId)}`;
};

// Currency conversion

export const convertPriceToFloat = (price: Price) => {
  const val = Number.parseFloat(`${price.integer}.${price.decimal}`);
  if (Number.isNaN(val)) {
    throw Error('converted price to float is NaN');
  }
  return val;
};

export const euroPriceAsCurrency = (price: Price, currencyId: string) => {
  // FIXME: Dummy values => need API call
  const currencyConversionRatesToEuro: Record<string, number> = {
    EUR: 1.0,
    USD: 1.09,
    BTC: 0.000028,
    HRK: 7.56,
  };

  const priceFloat = convertPriceToFloat(price);
  
  console.log('priceFloat',priceFloat);
  if (!currencyConversionRatesToEuro[currencyId]) {
    throw Error('currency not supported');
  }

  console.log('priceFloats:', priceFloat);

  // FIXME: round "kaufmännisch" for other checkout type than paypal => currently only used for display only
  const convertedPrice = priceFloat * currencyConversionRatesToEuro[currencyId];

  let convertedValue=Math.round(convertedPrice * 100) / 100;


  if (Number.isNaN(convertedValue)) {
    throw Error('currency conversion error');
  }
  console.log('priceFloatsssss:',convertedValue,calculatePriceFromPriceString(convertedValue.toString()));

  return calculatePriceFromPriceString(convertedValue.toString());
};
export function calculateGroupQuantityDiscountPrice(
  publicQuantityDiscounts: publicQuantityDiscount[],
  numberOfParticipants: number,
  bookingSystemType:string | "privateBooking" | "publicBooking"
  
): DiscountInfo {

  const applicableDiscount = publicQuantityDiscounts.reduce((result, discount) => {
    if (numberOfParticipants >= discount.discountMinQuantity && discount.discountMinQuantity > (result?.discountMinQuantity || 0)) {
      return discount;
    }
    return result;
  }, null);
  let discountAmount = 0;
  let discountPercentage = 0;
  let discountType = null;
  if (applicableDiscount != null) {
    discountType = applicableDiscount.discountType;
    console.log("discountType Pdt conf...", discountType)

    if (discountType == "fixed") {
      discountAmount = bookingSystemType ===BookingSystemType.PUBLIC ? numberOfParticipants * (applicableDiscount.discountFixed || 0) : (applicableDiscount.discountFixed || 0 )
    }
    else if (discountType == "percentage") {
      discountPercentage = applicableDiscount.discountPercentage ||0
    }
  }
  return {
    "discountType": discountType,
    "discountAmount": discountAmount,
    discountPercentage
  };
}

export function getGroupQuantityDiscounts( 
  priceSystems: PriceSystem[],
  priceSystemId: string,
): publicQuantityDiscount[] {
  const productPriceSystem = priceSystems.find((ps) => ps.id === priceSystemId);
  const publicSettings =getBasePriceByFeatureEnabled(productPriceSystem)
  //  productPriceSystem?.publicBooking?.featureEnabled
  // ? productPriceSystem.publicBooking
  // : null;

  const currentQuantityDiscounts = publicSettings?.quantityDiscounts || [];
  console.log("publicQuantityDiscounts on utitlity...",currentQuantityDiscounts ,"productPriceSystem",productPriceSystem)
  return currentQuantityDiscounts
}

export function getBasePriceByFeatureEnabled ( pricing :any) {
  const rawPrice = pricing?.publicBooking?.featureEnabled ===true ?{priceType : 'publicBooking' ,...pricing?.publicBooking }: {priceType : 'privateBooking' , ...pricing?.privateBooking } ; // TODO:
  return rawPrice
}
 

 