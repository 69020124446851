import * as React from 'react';
import MContainer, {
  ContainerProps as MContainerProps,
} from '@material-ui/core/Container';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';

export interface ContainerProps extends CombinedBaseComponentProps<MContainerProps> {}

/**
 * Container / WAPContainer - MUI
 * @param { ContainerProps } props
 * @version 0.0.0
 * @description responsive container
 * @todo
 * @example
 */
const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { children, ...passProps } = props;
  return <MContainer {...passProps}>{children}</MContainer>;
};

const WAPContainer = (props: CombinedAppProps<ContainerProps>) =>
  withoutAppProps<CombinedAppProps<ContainerProps>, ContainerProps>(Container, props);

export { Container, WAPContainer };
