import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider, { SliderProps } from '@material-ui/core/Slider';
// import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// // TODO: move to hook
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: 300 + theme.spacing(3) * 2,
//   },
//   margin: {
//     height: theme.spacing(3),
//   },
// }));

interface ValueLabelComponentProps {
  children: React.ReactElement<any, any>;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: ValueLabelComponentProps) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement={'bottom'} title={value}>
      {children}
    </Tooltip>
  );
}

const DefaultSlider = withStyles({
  root: {
    // color: '#ffa500',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSlider = (props: SliderProps) => {
  return (
    <DefaultSlider
      // valueLabelDisplay='auto'
      // aria-label='slider'
      // defaultValue={[10, 100]}
      // min={1}
      // max={1000}
      // valueLabelDisplay='on'
      ValueLabelComponent={ValueLabelComponent}
      // valueLabelFormat={(val, idx) => <div>{val} €</div>}
      {...props}
    />
  );
};

export { CustomSlider as Slider };
