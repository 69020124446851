import * as React from 'react';

import { Link, LinkProps } from 'src/app/components';
import { useLocalizationContext } from 'src/app/hooks';
// import { AppLocale } from 'src/app/constants';

// const isHash = (str: string) => /^#/.test(str);
// const isInternal = (to: string) => /^\/(?!\/)/.test(to);

export interface LocalizedLinkProps extends LinkProps {}

/**
 * LocalizedLink
 * @param { LocalizedLinkProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LocalizedLink = (props: LocalizedLinkProps) => {
  const { children, className, href, ...passProps } = props;
  const { language, locales } = useLocalizationContext();

  // NOTE: for MDX or general link implementations based on this component
  // isHash(href) || !isInternal(href) ? (
  //   <a {...props} href={href} />
  // ) : (
  //   <LocalizedLink {...props} to={href} />
  // );

  const isIndex = href === '/';

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const curLangLocalesIdx = locales.findIndex((locale) => locale === language) || 0;
  let locale = locales[curLangLocalesIdx] as string;
  if (!locale || locale === 'cimode') {
    locale = 'en';
  }
  const pathRewrite = `/${locale}${isIndex ? '' : `${href}`}`;

  return (
    <Link
      className={`${
        className || ''
      } inline-flex items-center dark:text-neutral-50 text-neutral-900 gap-1 hover:no-underline`}
      {...passProps}
      href={pathRewrite}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};

export { LocalizedLink };
