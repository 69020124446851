import * as React from 'react';

import {
  ButtonTw,
  Localized
} from 'src/app/components';
import { Price } from 'src/app/types';
import { useShoppingCartContext } from 'src/app/context';
import { AppFeature } from 'src/app/constants';
import { calculateTotalPrice, getCartItemsTotalPrices } from 'src/lib/checkout';
import {
  useAppContext,
  useAppFeatures,
  useAppStaticQueryConfig,
  useLocalizedNavigation,
} from 'src/app/hooks';

import { CheckoutButton } from './CheckoutButton';
import { PriceTotalSummary } from './PriceTotalSummary';

export interface CheckoutBoxProps {
  i18nPath: string;
}

export const CheckoutBox = (props: CheckoutBoxProps) => {
  const { i18nPath } = props;
  const {
    // areParticipantsValid, // TODO: implement correctly
    cartItems,
    sourceProducts,
  } = useShoppingCartContext();
  const { currencyId, isOrganisationUser } = useAppContext();
  const { navigateLocalized } = useLocalizedNavigation();
  const appConfig = useAppStaticQueryConfig();
  const isProduction = Boolean(appConfig.env === 'PRODUCTION'); // FIXME: remove later or use FLAG fetched from BE (use fb appConfig)
  const hasUserCheckoutFeature = true;
  // const { hasFeature: hasUserCheckoutFeature } = useAppFeatures(AppFeature.USER_CHECKOUT);

  const cartItemTotalPrices: Price[] = [
    ...getCartItemsTotalPrices(cartItems, sourceProducts),
  ];

  const totalPrice = calculateTotalPrice(cartItemTotalPrices);
  console.log('totalPrice',cartItemTotalPrices);

  const isPriceValid = !(
    Number.isNaN(totalPrice.integer) ||
    Number.isNaN(totalPrice.decimal) ||
    totalPrice.integer < 0 ||
    totalPrice.decimal < 0
  ); // FIXME: better validation needed

  return (
    <div className='px-7 md:px-8 py-12 border-2 rounded-2xl border-accent-500 flex flex-col gap-y-12 dark:border-primary-500 z-0 relative'>
      <div>
        <PriceTotalSummary
          currencyId={currencyId}
          i18nPath={i18nPath}
          itemCount={cartItems.length}
          totalPrice={totalPrice}
        />
      </div>
      <div className='text-center md:w-[270px] md:mx-auto flex flex-col gap-y-3'>
        {/* {!areParticipantsValid ? (
          <Typography paragraph color={'error'} align={'center'}>
            <Localized dictKey={`${i18nPath}.error.participants-incomplete-error`} />
          </Typography>
        ) : null} */}
        {isProduction ? (
          <p className='text-danger-500 font-semibold py-2'>
            {'Checkout is currently disabled'}
          </p>
        ) : null}
        {isPriceValid && hasUserCheckoutFeature ? (
          <CheckoutButton
          totalPrice={totalPrice}
           />
        ) : (
          <>
            {!hasUserCheckoutFeature ? (
              <>
                {isOrganisationUser ? (
                  <p className='text-danger-500 font-semibold py-2 text-center'>
                    <Localized
                      dictKey={`${i18nPath}.error.organisation-account-in-use-error`}
                    />
                  </p>
                ) : (
                  <p className='text-danger-500 font-semibold py-2 text-center'>
                    <Localized dictKey={`${i18nPath}.error.not-logged-in-error`} />
                  </p>
                )}
              </>
            ) : (
              <p className='text-danger-500 font-semibold py-2 text-center'>
                <Localized
                  dictKey={`${i18nPath}.error.total-price-calculation-error`}
                />
              </p>
            )}
          </>
        )}
        <ButtonTw
          onClick={() => navigateLocalized('/activity-000')}
          rounded
          size={'lg'}
          variant={'accent'}
          className='w-full'
        >
          <Localized dictKey={`${i18nPath}.action.navigate-to-products.label`} />
        </ButtonTw>
      </div>
      <div className='text-center flex flex-col gap-y-2'>
        <p>
          <Localized dictKey={`${i18nPath}.hint.create-account`} />
        </p>
        <p className='font-semibold'>
          <Localized dictKey={`${i18nPath}.hint.best-price`} />
        </p>
      </div>
    </div>
  );
};
