import * as React from 'react';
import clsx from 'clsx';
import { SourcedGQLProduct } from 'src/app/types';

import {
  ButtonTw,
  GlassPaper,
  Grid,
  LoadingIndicator,
  Localized,
  Typography,
  Toolbar,
  ProductListItem,
  ProductListItemContent,
} from 'src/app/components';

import { useLocalizationContext } from 'src/app/hooks';

import { AppLocale } from 'src/app/constants';

import { useStyles } from 'src/app/context';

// type ItemType = BaseDocumentData &
//   GatsbyImageData & { localizations: BaseLocalization[] };

export interface ProductLoaderProps {
  collection: SourcedGQLProduct[]; // | ItemType[] if needs to get generic for alle sourced docs...
  itemsAddPerStepCount?: number;
  onClick: (id: string) => void;
}

// TODO: move to central place?
const defaultItemsAddPerStepCount = 4;
export const ProductLoader = (props: ProductLoaderProps) => {
  const { itemsAddPerStepCount } = props;
  const { collection, onClick } = props;
  const styles = useStyles();
  const { language } = useLocalizationContext();

  const [visibleItemLimit, setVisibleItemLimit] = React.useState<number>(
    itemsAddPerStepCount || defaultItemsAddPerStepCount,
  );

  return (
    <>
      <div className='flex gap-2 overflow-x-scroll no-scrollbar md:overflow-x-auto md:flex-col md:gap-4 snap-mandatory snap-x py-4'>
        {collection?.length ? (
          collection.map((item, idx) => {
            if (idx >= visibleItemLimit) {
              return null;
            }
            let currentLocalization = item.localizations.find(
              (loc) => loc.localeId === language,
            );
            if (!currentLocalization) {
              currentLocalization = item.localizations.find(
                (loc) => loc.localeId === AppLocale.EN, // NOTE: #i18n fallback 'en'
              );
            }

            return true ? ( // TODO: || true for also displaying "cancelled" items...
              <div
                className='w-10/12 first:ml-4 last:mr-4 sm:w-2/3 md:first:ml-0 md:last:mr-0 md:ml-0 md:w-full flex-shrink-0 snap-center'
                key={item.id}
              >
                <div
                  onClick={() => onClick(item.id)}
                  className='w-full bg-transparent border-none h-full cursor-pointer'
                >
                  <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 h-full dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative'>
                    <div className='w-full md:w-1/3'>
                      <ProductListItem
                        key={item.id}
                        product={item as SourcedGQLProduct}
                        hasWishlistIcon={false}
                      />
                    </div>
                    <div className='w-full h-full md:h-auto md:w-2/3'>
                      <ProductListItemContent {...item} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <div>
        {visibleItemLimit >= collection.length ? null : (
          <p className='text-center pt-5 md:pt-12'>
            <ButtonTw
              variant={'text-underline'}
              onClick={() =>
                setVisibleItemLimit(
                  visibleItemLimit +
                    (itemsAddPerStepCount || defaultItemsAddPerStepCount),
                )
              }
            >
              <Localized dictKey={'common:action.load-more.label'} />
            </ButtonTw>
          </p>
        )}
      </div>
    </>
  );
};
