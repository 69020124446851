export const getCIModeKeysFromI18nArray = <T extends string | Record<string, any>>(
  arrayDictKey: string,
  array: T[],
  keys?: string[],
): T[] => {
  return array.map((item, idx) => {
    if (!keys) {
      return `${arrayDictKey}.${idx}`;
    }
    const data: Record<string, any> = {};
    keys.forEach((key) => {
      data[key] = `${arrayDictKey}.${idx}.${key}`;
    });

    return data;
  }) as T[];
};
