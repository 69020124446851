import * as React from 'react';
import MDivider, { DividerProps as MDividerProps } from '@material-ui/core/Divider';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyles } from 'src/app/context';

export interface DividerProps extends CombinedBaseComponentProps<MDividerProps> {}

/**
 * Divider / WAPDivider
 * @param { DividerProps } props https://material-ui.com/api/divider
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Divider = (props: DividerProps) => {
  const { children, className, ...passProps } = props;
  const styles = useStyles();
  return (
    <MDivider className={`${styles.divider} ${className || ''}`} {...passProps}>
      {children}
    </MDivider>
  );
};

const WAPDivider = (props: CombinedAppProps<DividerProps>) =>
  withoutAppProps<CombinedAppProps<DividerProps>, DividerProps>(Divider, props);

export { Divider, WAPDivider };
