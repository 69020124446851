import * as React from 'react';

import { Grid, Link, Typography, Localized } from 'src/app/components';

const basePath = '/account';

// TODO: i18n
export const UserAccountMenu = () => {
  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12}>
        <Link color={'inherit'} href={'/account'}>
          <Typography>Dashboard</Typography>
        </Link>
      </Grid> */}
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/profile`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./profile.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/messages`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./messages.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/orders`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./orders.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/history`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./history.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/settings`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./settings.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/email`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./email.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/password`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./password.link label'} />
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link color={'inherit'} href={`${basePath}/billing`}>
          <Typography>
            <Localized dictKey={'staticLocales:pages./account.pages./billing.link label'} />
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
