import React from "react"
import { changetommddyyyy } from "../helpers/helpers"
import { TableTimePickerProps } from "../types/DataTableComponentProps"

export const TableTimePicker: React.FC<TableTimePickerProps> = ({
  searchList,
  fieldDetails,
  handleTimeChange
}) => {

  console.log(searchList,fieldDetails,"hkuiuiii")
  return (
    <>
      <input
        type='time'
        value={
          Object.prototype.hasOwnProperty.call(
            searchList,
            fieldDetails.dataName,
          )
            ? searchList[fieldDetails.dataName]
            : ""
        }
        onChange={(e) => handleTimeChange(e, fieldDetails.dataName)}
        className='bg-gray-50 border  font-normal border-gray-300 text-gray-500 text-sm rounded-lg  focus:border-focus-border block w-full pl-10 p-[10px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  dark:focus:border-[#1FCCB9]'
      />
    </>
  )
}