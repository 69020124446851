export enum ProductDocumentState {
  // INITIAL / DEFAULT STATE:
  DRAFT = '__draft', // default state after creating document
  // USER SET REQUEST STATES:
  PUBLISH__REQUEST = '__publish_request', // system admin shall approve or reject
  CHANGE_REQUEST = '__change_request', // system admin shall approve or reject
  REVOKE__REQUEST = '__revoke_request', // system shall set state to 'draft'
  DELETE__REQUEST = '__delete_request', // system shall set state to 'deleted'

  // SYS SET STATES:
  DISABLED = '__disabled', // => product is paused either after revoke or by sys admin => not displayed & not orderable
  DELETED = '__deleted', // => shall be deleted when draft or deleted with order/priceSystem data copy
  PUBLISHED = '__published', // => everything ok & published
  CHANGE_APPROVAL_PENDING = '__change_approval_pending',

  // SYS ADMIN SET STATES:
  PUBLISH__APPROVED = '__publish_approved', // => waiting for FE rebuild
  PUBLISH__REJECTED = '__publish_rejected', // => QA not passed
  CHANGE_APPROVED = '__change_approved', // => changes are OK
  CHANGE_REJECTED = '__change_rejected', // => changes need to be changed
  DELETE__APPROVED = '__delete_approved', // => system admin is OK with deletion
  DELETE__REJECTED = '__delete_rejected', // => system admin is not OK with deletion - product need to stay online?
  REVOKE__APPROVED = '__revoke_approved', // => system admin is OK with pause
  REVOKE__REJECTED = '__revoke_rejected', // => system admin is not OK with pause - product need to stay online?
}

// TODO: other DocumentStates are missing in FE
