import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { LocalizedLink, Localized } from 'src/app/components';
// import { BaseComponentProps, RouterPageProps } from 'src/app/types';

// export interface OrganisationLoginContainerProps
//   extends BaseComponentProps,
//     RouterPageProps {
//   redirectAfterLoginTo: string;
// }

export const AdditionalLoginOptions = ({ className }: { className?: string }) => {
  const [shouldPersistSession, setShouldPersistSession] = React.useState(false);

  const onRememberMeClick = (value: boolean) => {
    console.warn('not implemented');
    // TODO: propagate to useAppContext and create functionality (if needed? or set cookie ttl to certain val)
    setShouldPersistSession(value);
  };
  return (
    <div className={className || 'flex flex-col justify-center items-center py-6 gap-4'}>
      <div>
        <span className='inline-flex items-center'>
          <Checkbox
            onChange={(e) => onRememberMeClick(e.target.checked)}
            checked={shouldPersistSession}
            color='primary'
          />
          <Localized dictKey={`organisation:/organisation/login.form.rememberMe.label`} />
        </span>
      </div>
      <div>
        <LocalizedLink href={'/lost-password'} className='text-accent-500 underline dark:text-accent-500'>
          <Localized dictKey={`organisation:/organisation/login.form.forgotPassword.label`} />
        </LocalizedLink>
      </div>
    </div>
  );
};
