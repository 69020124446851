import organisation from './organisation.json';
import organisationInvitation from './organisation-invitation.json';
import organisationLogin from './organisation-login.json';
import organisationSignup from './organisation-signup.json';
import organisationTerms from './organisation-terms';

export default {
  '/organisation': organisation,
  '/organisation/invitation': organisationInvitation,
  '/organisation/login': organisationLogin,
  '/organisation/signup': organisationSignup,
  '/organisation/terms': organisationTerms,
};
