import * as React from 'react';
import { Button } from 'gatsby-theme-material-ui';
import { ButtonProps } from 'src/app/components';

import { useLocalizationContext } from 'src/app/hooks';

// const isHash = (str: string) => /^#/.test(str);
// const isInternal = (to: string) => /^\/(?!\/)/.test(to);

export interface LocalizedLinkButtonProps extends ButtonProps {
  to: string;

  /* @deprecated */
  href?: string;
}

/**
 * LocalizedLink
 * @param { LocalizedLinkProps } props
 * @version 0.0.0
 * @description
 *  - do not use "href" prop / use "to" prop
 * @todo
 * @example
 */
const LocalizedLinkButton = (props: LocalizedLinkButtonProps) => {
  const { children, className, to, ...passProps } = props;
  const { language, locales } = useLocalizationContext();

  // NOTE: for MDX or general link implementations based on this component
  // isHash(href) || !isInternal(href) ? (
  //   <a {...props} href={href} />
  // ) : (
  //   <LocalizedLink {...props} to={href} />
  // );

  const isIndex = to === '/';

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  // const curLangLocalesIdx = locales.findIndex((locale) => locale === language) || 0;
  // const pathRewrite = `/${locales[curLangLocalesIdx]}${isIndex ? '' : `${to}`}`;

  const curLangLocalesIdx = locales.findIndex((locale) => locale === language) || 0;
  let locale = locales[curLangLocalesIdx] as string;
  if (locale === 'cimode') {
    locale = 'en';
  }
  const pathRewrite = `/${locale}${isIndex ? '' : `${to}`}`;

  return (
    <Button className={className || ''} {...passProps} to={pathRewrite}>
      {children}
    </Button>
  );
};

export { LocalizedLinkButton };
