import * as React from 'react';
import {
  ButtonTw,
  LanguageSelection,
  Localized
} from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { BaseComponentProps, CombinedAppProps, GDPRSettings } from 'src/app/types';
import { GDPRCategories } from './GDPRCategories';

const defaultPreferenceSettings: GDPRSettings = {
  configs: [],
};

export interface GDPRDialogProps extends BaseComponentProps {
  children?: React.ReactNode;
}

/**
 * LoginDialog / WAPLoginDialog
 * @param { GDPRDialogProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const GDPRDialog = (props: GDPRDialogProps) => {
  const { children } = props;
  const { isGDPRSet, setGDPRSettings, closeDialog } = useAppContext();
  const [showPreferences, setShowPreferences] = React.useState<boolean>(false);
  const [preferences, setPreferences] = React.useState<GDPRSettings>(
    defaultPreferenceSettings,
  );

  const onShowPreferences = () => {
    if (!showPreferences) {
      setShowPreferences(true);
    }
  };

  const onSavePreferences = (preferences: GDPRSettings) => {
    setGDPRSettings(preferences);
    closeDialog();
  };

  return (
    <div className='md:p-4'>
      <div className='max-w-[3rem]'>
        <LanguageSelection />
      </div>
      <div>
        {showPreferences ? (
          <>
            <GDPRCategories />
            {/* <Grid item xs={12}>
              <Button
                fullWidth
                color={'primary'}
                variant={'contained'}
                size='small'
                onClick={() => setShowPreferences(false)}
              >
                <Typography >Back</Typography>
              </Button>
            </Grid> */}
            <p className='text-center py-4'>
              <ButtonTw
                variant={'accent'}
                size='lg'
                rounded
                className='w-full md:w-auto md:min-w-[270px]'
                onClick={() => onSavePreferences(preferences)}
              >
                <Localized dictKey={`gdpr:action.${'save-gdpr-preferences'}.label`} />
              </ButtonTw>
            </p>
          </>
        ) : (
          <>
            <h3 className='text-p1 font-semibold mb-4'>
              <Localized dictKey={`gdpr:chapter.${'root'}.title`} />
            </h3>
            <p>
              <Localized dictKey={`gdpr:chapter.${'root'}.description`} />
            </p>

            <p className='text-center flex flex-col items-center gap-6 py-8'>
              <ButtonTw
                variant={'accent'}
                size='lg'
                rounded
                className='w-full md:w-auto md:min-w-[270px]'
                onClick={() => onSavePreferences(defaultPreferenceSettings)}
              >
                <Localized dictKey={`gdpr:action.${'save-gdpr-preferences'}.label`} />
              </ButtonTw>
              <ButtonTw
                variant={'text-underline'}
                onClick={onShowPreferences}
              >
                <Localized dictKey={`gdpr:action.${'configure-gdpr-preferences'}.label`} />
              </ButtonTw>
            </p>
            {children ? (
              <>
                {children}
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

const WAPGDPRDialog = (props: CombinedAppProps<GDPRDialogProps>) =>
  withoutAppProps<CombinedAppProps<GDPRDialogProps>, GDPRDialogProps>(GDPRDialog, props);

export { GDPRDialog, WAPGDPRDialog };
