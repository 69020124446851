import moment from 'moment';

import {
  ResultDataFromTypeSense,
  SearchParams,
  TableHeadings,
} from '../types/tableTypes';
import { typesenseApi } from 'src/api/callables';
import { useAppContext } from 'src/app/context';
import { useAppStaticQueryConfig } from 'src/app/hooks';

export const getSearchResult = async (
  searchParam: SearchParams,
  pageNumber: number,
  orderbyField: string,
  orderbyVal: string,
  collectionName: string,
) => {
  try {
    const { firebase } = useAppContext();
    const { env } = useAppStaticQueryConfig();
    const translateApiResponse = await typesenseApi(
      firebase,
      {
        searchParam,
        pageNumber,
        orderbyField,
        orderbyVal,
        collectionName,
      },
      env
    );
    return translateApiResponse;
  } catch (error: any) {
    console.error('Fetch error:', error.message);
    return { error: true, errorMessage: error.message };
  }
};

export const mapResultValues = (
  result: ResultDataFromTypeSense,
  tableHeadings: TableHeadings,
) => {
  let transformedResult: ResultDataFromTypeSense = [];
  console.log(result, tableHeadings);

  if (result.length > 0 && tableHeadings.length > 0) {
    transformedResult = result.map((item: any) => {
      const transformedItem = tableHeadings.reduce((acc: any, heading: any) => {
        acc[heading.dataName] = item[heading.dataName];
        return acc;
      }, {});

      // Add properties from item that are not present in heading.dataName
      Object.keys(item).forEach((key) => {
        if (!transformedItem.hasOwnProperty(key)) {
          transformedItem[key] = item[key];
        }
      });

      return transformedItem;
    });
  }
  
  return transformedResult;
};

function removeSpecialCharsFromStart(str: any) {
  return str.replace(/^[^\w]+/, '');
}

export const makeSearchQueryInTypSenseFormat = (
  currentSerachQuery: any,
  dateFileds: string[],
  amountFields:string[],
  participantCountFields:string[]
) => {

const  queryparam = Object.entries(currentSerachQuery)
    .map(([key, value]) => {
      if (dateFileds.includes(key)) {
        const { startUnixTimestamp, endUnixTimestamp } = getDateRangeAsUnixTimestampsLocal(currentSerachQuery[key]);
        return `${key}:>=${startUnixTimestamp} && ${key}:<=${endUnixTimestamp}`

        // const { startUnixTimestamp, endUnixTimestamp } =
        //   getDateRangeAsUnixTimestampsLocal(
        //     currentSerachQuery[key],
        //     currentSerachQuery.createdTill,
        //   );
        // return `${key}:>=${startUnixTimestamp} && ${key}:<=${endUnixTimestamp}`;
      } else if (key === 'createdTill') {
        // console.log('Inside till');
        return;
      } else if (key !== 'email' && removeSpecialCharsFromStart(value) != '') {
                console.log('Inside till');

        if(amountFields.includes(key)){
          return `${key}:=${value}`
        }  else if(participantCountFields.includes(key)){
          return `${key}:=${value}`
        }else{
          return `${key}:${removeSpecialCharsFromStart(value)}*`;
        }
      }  
    })
    .filter(Boolean)
    .join(' && ');
    

console.log(queryparam,"queryParam")

    const searchParameter: any = {
      q: '*',
      query_by: '',
      filter_by: queryparam,
    };
    if (currentSerachQuery?.email) {
      searchParameter.q = `${currentSerachQuery?.email}`;
      searchParameter.query_by = 'email';
    }
  return searchParameter;
};

export function getDateRangeAsUnixTimestampsLocall(
  dateString: string,
  endTime: string,
): {
  startUnixTimestamp: bigint;
  endUnixTimestamp: bigint;
} {
  // Parse the date string with Moment.js
  const date = moment(dateString, 'MM/DD/YYYY');
  const dateEnd = moment(endTime, 'MM/DD/YYYY');

  // console.log(dateString, 'datestring', endTime);
  const startOfDay = date.startOf('day');
  const startUnixTimestamp = BigInt(startOfDay.unix());
  const endOfDay =
    new Date(endTime) !== new Date()
      ? dateEnd.endOf('day').subtract(1, 'ms')
      : date.endOf('day').subtract(1, 'ms');
  const endUnixTimestamp = BigInt(endOfDay.unix());
  return { startUnixTimestamp, endUnixTimestamp };
}
export function getDateRangeAsUnixTimestampsLocal(dateString: string): { startUnixTimestamp: bigint; endUnixTimestamp: bigint } {
  // Parse the date string with Moment.js
  const date = moment(dateString, 'MM/DD/YYYY');
 console.log(date,"date")
  const startOfDay = date.startOf('day');
  const startUnixTimestamp = BigInt(startOfDay.unix());

  const endOfDay = date.endOf('day').subtract(1, 'ms');
  const endUnixTimestamp = BigInt(endOfDay.unix());

  return { startUnixTimestamp, endUnixTimestamp };
}
export function changetommddyyyy(dateString: string) {
  const parts = dateString.split('-');
  const formattedDate = parts[0] + '-' + parts[1] + '-' + parts[2];
  return formattedDate;
}
export function convertToNewDate(dateString: any, dFormart: string) {
  const date = moment.unix(dateString);
  const formattedDate = date.format(dFormart || 'DD/MM/YYYY');
  return formattedDate;
}
export function changeUnixStampToddmmyy(dateString:string) {
  const parts = dateString.split('/');
  const formattedDate = parts[2] + '-' + parts[0] + '-' + parts[1];
  return formattedDate;
}
