import React from 'react';
import Flags from 'country-flag-icons/react/3x2'; // TODO: declare
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from 'react-virtualized';
// import dayjs, { isDayjs } from 'dayjs';

import { Grid, Localized } from 'src/app/components';
import { formatFirebaseTimestamp } from 'src/lib/date';
import {
  FirebaseTimestamp,
  TableColumn,
  TableData,
  TableRow,
  TableRowClickEvent,
} from 'src/app/types';
// import { useStyles } from 'src/app/context'; // FIXME: use this and add this own style definitions

// import 'react-virtualized/styles.css';

declare module '@material-ui/core/styles/withStyles' {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
        flip: false,
        paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      },
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableCell: {
      flex: 1,
      // minWidth: '160px',
    },
    noClick: {
      cursor: 'initial',
    },
  });

interface MuiVirtualizedTableProps<T extends Record<string, unknown>>
  extends WithStyles<typeof styles> {
  columns: TableColumn[];
  headerHeight?: number;
  onRowClick?: (e: TableRowClickEvent) => void;
  rowCount: number;
  rowGetter: (row: TableRow) => TableData;
  rowHeight?: number;
  isLocalized: boolean;
  i18nBasePath?: string;
  currentSelectedIndex?: number;
}

class MuiVirtualizedTable<T extends Record<string, unknown>> extends React.PureComponent<
  MuiVirtualizedTableProps<T>
> {
  getRowClassName = ({ index }: TableRow) => {
    const { classes, currentSelectedIndex, onRowClick } = this.props;

    if (index === currentSelectedIndex) {
      return `${'row__selected'} ${classes.tableCell} ${classes.flexContainer}`;
    }

    return `${classes.tableCell} ${classes.flexContainer} ${
      onRowClick && index !== -1 ? classes.tableRowHover : ''
    }`;
  };

  renderData = (data: any) => {
    const actionRowValue = 'widthX';
    // console.log('data:', data);
    switch (typeof data) {
      case 'number':
        return data;
      case 'string':
        if (data === actionRowValue) {
          // return (
          //   <Grid container>
          //     <Grid item xs={12}>
          //       <Toolbar>
          //         <Button size={'small'} variant={'outlined'} color={'primary'}>
          //           <DeleteForeverIcon color={'primary'} />
          //         </Button>
          //         <Button size={'small'} color={'error'}>
          //           <DeleteForeverIcon />
          //         </Button>
          //       </Toolbar>
          //     </Grid>
          //   </Grid>
          // );
          return '';
        }
        return data;
      case 'object':
        // META OBJECT
        // FIXME: PSYK => check for v prop
        if (data.v !== undefined && data.createdAt) {
          // check for v prop could shoot into knee
          return (
            <Grid container>
              <Grid item xs={12}>
                {formatFirebaseTimestamp(data.createdAt as FirebaseTimestamp)}
              </Grid>
              {/* {data.status ? (
                <Grid item xs={12}>
                  {data.status}
                </Grid>
              ) : null} */}
            </Grid>
          );
        }
        // TODO: better differentiation needed
        if (
          data.seconds !== undefined &&
          typeof data.seconds === 'number' &&
          data.nanoseconds !== undefined &&
          typeof data.nanoseconds === 'number'
        ) {
          // return 'Foo';
          return formatFirebaseTimestamp(data as FirebaseTimestamp);
        }
        if (Array.isArray(data)) {
          if (data[0]?.localeId) {
            // is a localization array
            return (
              <>
                {data.map((localization) => (
                  <div
                    style={{ padding: '0 1px', minWidth: '24px' }}
                    key={localization.localeId}
                  >
                    {localization?.localeId === 'en' ? (
                      <Flags.GB title='English translation' className={''} />
                    ) : null}
                    {localization?.localeId === 'de' ? (
                      <Flags.DE title='German translation' className={''} />
                    ) : null}
                    {localization?.localeId === 'hr' ? (
                      <Flags.HR title='Croatian translation' className={''} />
                    ) : null}
                    {localization?.localeId === 'fr' ? (
                      <Flags.FR title='French translation' className={''} />
                    ) : null}
                  </div>
                ))}
              </>
            );
          }
          // NOTE: otherwise the array gets stringified
          return '';
        }
        return JSON.stringify(data);
      default:
        return null;
    }
  };

  cellRenderer: TableCellRenderer = (params) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    const { cellData, columnIndex, dataKey, rowData } = params;

    // cellData,
    // columnData,
    // dataKey: dataKey2,
    // rowData,
    // rowIndex,

    // console.log('cellData: ', cellData);
    // console.log('cellRenderer params: ', params);
    let renderedCellData = cellData;
    if (dataKey === 'status') {
      renderedCellData = rowData?.meta?.status || '';
    }
    return (
      <TableCell
        component='div'
        className={`${classes.tableCell} ${classes.flexContainer} ${
          !onRowClick ? classes.noClick : ''
        }`}
        variant='body'
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
      >
        {this.renderData(renderedCellData)}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes, isLocalized, i18nBasePath } = this.props;
    const tableRenderExtensionLabels = ['#', 'widthX'];
    const tableRenderExtensionLabelsReplace = ['widthX'];

    return (
      <TableCell
        component='div'
        className={`${classes.tableCell} ${classes.flexContainer} ${classes.noClick}`}
        variant='head'
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {/** // TODO: clean i18n key compose system */}
        {isLocalized && !tableRenderExtensionLabels.includes(label) ? (
          <Localized dictKey={`${i18nBasePath}-table.columns.${label}.field.name`} />
        ) : (
          <span>{tableRenderExtensionLabelsReplace.includes(label) ? '' : label}</span>
        )}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    // console.log('columns: ', columns);
    // console.log('tableProps: ', tableProps);
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns
              ? columns.map((col, index) => {
                  const { dataKey, ...other } = col;
                  // console.log('col: ', col);
                  if (!dataKey) {
                    console.log('no dataKey col: ', col);
                  }
                  return (
                    <Column
                      key={dataKey}
                      headerRenderer={(headerProps) =>
                        this.headerRenderer({
                          ...headerProps,
                          columnIndex: index,
                        })
                      }
                      className={classes.flexContainer}
                      cellRenderer={({
                        cellData,
                        columnData,
                        dataKey: dataKey2,
                        rowData,
                        rowIndex,
                      }) =>
                        this.cellRenderer({
                          cellData,
                          columnData,
                          dataKey: dataKey2,
                          rowData,
                          rowIndex,
                        })
                      } // TODO: rework
                      dataKey={dataKey}
                      {...other}
                    />
                  );
                })
              : null}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export const StyledVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
