import * as React from 'react';

import { Checkbox, Divider, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { PickupLocation, PriceSystem } from 'src/app/types';
import { TableRowLayout } from './TableRowLayout';
import { SelectPriceGroup } from './SelectPriceGroup';
import { SelectPickupOption } from './SelectPickupOption';

export const TableActions = ({
  enabled,
  locationId,
  onChangeEnabled,
  onChangePickup,
  onChangePriceGroup,
  pickupLocations,
  priceGroupId,
  priceSystem,
}: {
  enabled: boolean;
  locationId?: string;
  onChangeEnabled: () => void;
  onChangePickup: (priceGroupId: string) => void;
  onChangePriceGroup: (priceGroupId: string) => void;
  pickupLocations: PickupLocation[];
  priceGroupId?: string;
  priceSystem: PriceSystem;
}) => {
  const styles = useStyles();
  console.log('priceSystem: ', priceSystem);
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableRowLayout
          firstChildren={
            <Grid container className={styles.h__min__100}>
              <Grid item md={1} xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Checkbox checked={enabled} onChange={onChangeEnabled} />
                  </Grid>
                  <Grid item xs={6} />
                </Grid>
              </Grid>
              <Grid item md={11} xs={12}>
                <Typography variant={'h6'} renderAs={'p'} center>
                  Set settings on all participants
                </Typography>
              </Grid>
              {/* <Grid item md={3} xs={12} />
              <Grid item md={3} xs={12} />
              <Grid item md={1} xs={12} />
              <Grid item md={2} xs={12} />
              <Grid item md={1} xs={12} />
              <Grid item md={1} xs={12} /> */}
            </Grid>
          }
          secondChildren={
            <Grid container className={styles.h__min__100}>
              <Grid item md={4} xs={12}>
                <SelectPriceGroup
                  onChangePriceGroup={onChangePriceGroup}
                  priceSystem={priceSystem}
                  selectedId={priceGroupId}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectPickupOption
                  onChange={onChangePickup}
                  pickupLocations={pickupLocations}
                  selectedId={locationId}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography center>{'Food'}</Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
