import communication from './communication.json';
import customers from './customers.json';
import documents from './documents.json';
import media from './media.json';
import orders from './orders.json';
import organisation from './organisation.json';
import productsCreateProductPreset from './products-create-product-preset.json';
import productsCreateProductProduct from './products-create-product-product.json';
import productsCreateProductTutorial from './products-create-product-tutorial.json';
import productsCreateProduct from './products-create-product.json';
import productEvents from './products-events.json';
import productsManagement from './products-management.json';
import productsSettings from './products-settings.json';
import products from './products.json';
import reports from './reports.json';
import settings from './settings.json';
import settingsEmail from './settings-email.json';
import settingsPayout from './settings-payout.json';
import users from './users.json';

export default {
  'org-communication': communication,
  'org-customers': customers,
  'org-documents': documents,
  'org-media': media,
  'org-orders': orders,
  'org-organisation': organisation,
  'org-products': products,
  'org-products-create-product-preset': productsCreateProductPreset,
  'org-products-create-product-product': productsCreateProductProduct,
  'org-products-create-product-tutorial': productsCreateProductTutorial,
  'org-products-create-product': productsCreateProduct,
  'org-products-events': productEvents,
  'org-products-management': productsManagement,
  'org-products-settings': productsSettings,
  'org-reports': reports,
  'org-settings': settings,
  'org-settings-email': settingsEmail,
  'org-settings-payout': settingsPayout,
  'org-users': users,
};
