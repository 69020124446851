import * as React from 'react';
import clsx from 'clsx';
import Carousel from 'react-material-ui-carousel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  BaseDocumentData,
  BaseLocalization,
  DocumentProductsInformation,
  GatsbyImageData,
} from 'src/app/types';

import { ButtonTw } from 'src/app/components';

import { useLocalizationContext } from 'src/app/hooks';

import { AppLocale } from 'src/app/constants';
import { TileButton } from './TileButton';

const MuiCarousel = ({ items }: { items: any[] }) => {
  const theme = useTheme();
  const isWiderSM = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Carousel
      className={'2xl:-mx-20 2xl:px-20'}
      animation={'slide'}
      // duration={5000} // doesnt work
      interval={5000}
      // indicators
      //navButtonsAlwaysVisible
      cycleNavigation={false}
      navButtonsAlwaysInvisible={!isWiderSM}
      autoPlay={false}
      stopAutoPlayOnHover
      swipe
      indicators={false}
      fullHeightHover
      NextIcon={<NavigateNextIcon />}
      PrevIcon={<NavigatePrevIcon />}
      NavButton={({ onClick, next, prev }) => {
        return (
          <ButtonTw
            onClick={onClick}
            className={
              'rounded-full w-12 h-12 bg-neutral-900 dark:bg-neutral-50 bg-opacity-40 dark:bg-opacity-20 shadow-box-light !text-neutral-50 dark:shadow-box-dark relative top-1/2 -translate-y-12'
            }
            variant={'text'}
          >
            {next && <NavigateNextIcon />}
            {prev && <NavigatePrevIcon />}
          </ButtonTw>
        );
      }}
    >
      {items.map((itemsInItem, i) => (
        <Item key={i} items={itemsInItem} />
      ))}
    </Carousel>
  );
};

function Item({ items }: { items: { id: string; component: React.ReactNode }[] }) {
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className='grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-4'>
      {items?.length
        ? (
            <>
              {items.map((item, idx) => {
                return (
                  <div
                    className='dark:text-neutral-50'
                    key={item.id}
                  >
                    {item.component}
                  </div>
                );
              })}
              {items[items.length - 1].component &&
              <div className="w-full group">
                <a className='flex justify-center items-center text-center rounded-lg shadow-box-light bg-gradient-to-tl from-accent-400 via-accent-600 to-accent-600 shadow-md transition-all duration-500 bg-size-200 bg-pos-100 hover:bg-pos-0 dark:shadow-box-dark aspect-square md:aspect-landscape' href="en/activity-000">
                  <span className='text-p3 md:text-p1 font-semibold'>View all categories</span>
                </a>
              </div>
              }
            </>
          )
        : null}
    </div>
  );
}

type ItemType = BaseDocumentData &
  GatsbyImageData &
  DocumentProductsInformation & { localizations: BaseLocalization[] };

export interface CollectionDataProductsProps {
  collection: ItemType[];
  onClick: (e: any, type: string, id: string, subId?: string) => void;
  type: 'category' | 'categoryType' | 'city';
}

// TODO: move to central place?
// FIXME: childCount is not working anymore
export const CollectionDataProductCategories = (props: CollectionDataProductsProps) => {
  const { collection, onClick, type } = props;
  const { language } = useLocalizationContext();
  const theme = useTheme();
  const isWiderSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const [itemSet, setItemSet] = React.useState<
    { id: string; component: React.ReactNode }[][]
  >([]);

  // TODO: sort less items in per row for mobile
  let counterLimit = 7;
  if (isWiderSM) {
    counterLimit = 3;
  }
  if (isWiderMD) {
    counterLimit = 7;
  }
  const setCount = Math.ceil(collection.length / counterLimit);

  React.useEffect(() => {
    if (collection?.length > 0) {
      const sortedItems: any[][] = new Array(setCount)
        .fill(0)
        .map(() => new Array(counterLimit).fill(0));
      let counter = 0;
      let round = 0;

      const setItem = (item: ItemType, rnd: number, cnt: number) => {
        const activityCount = item?.fields?.activityCount || 0; // TODO: new gatsby nodeField addtion
        let currentLocalization = item.localizations.find(
          (loc) => loc.localeId === language,
        );
        if (!currentLocalization) {
          currentLocalization = item.localizations.find(
            (loc) => loc.localeId === AppLocale.EN, // NOTE: #i18n fallback 'en'
          );
        }

        // console.log('sortedItems: ', sortedItems);
        sortedItems[rnd][cnt] = {
          id: item.id || `${rnd}_${cnt}`,
          component: (
            <TileButton
              altImageLabel={'Category Image'}
              childCount={activityCount}
              data={item}
              localization={currentLocalization!} // TODO: should not be of type undefined
              onClick={onClick}
              type={type}
            />
          ),
        };
      };

      for (let index = 0; index < collection.length; index++) {
        const item = collection[index];
        if (counter >= counterLimit) {
          counter = 0;
          round += 1;
        }
        setItem(item, round, counter);
        counter += 1;
      }
      setItemSet(sortedItems);
    }
  }, [collection, language, counterLimit, isWiderSM, isWiderSM, isWiderMD]);

  return (
    <>
      <MuiCarousel items={itemSet} />
    </>
  );
};
