import * as React from 'react';
import {
  Divider,
  Grid,
  Localized,
  ProductConfiguration,
  Typography,
} from 'src/app/components';
import UpdateIcon from '@material-ui/icons/Update';
import {
  SourcedGQLProduct,
  ShoppingCartItemDocumentData,
  ShoppingCartItemConfiguration,
} from 'src/app/types';

import { WishlistDocumentData } from './wishlist.types';

export interface WishlistItemAdditionalInformationProps {
  wishlistDocument: WishlistDocumentData; // <T> ??
  onCartItemUpdate: (itemDocId: string, data: ShoppingCartItemConfiguration[]) => void;
  product: SourcedGQLProduct;
}

const WishlistItemAdditionalInformation = (
  props: WishlistItemAdditionalInformationProps,
) => {
  const { wishlistDocument, onCartItemUpdate, product } = props;
  const exactDuration = product?.exactDuration || null; // TODO // NOTE: must be greater than 1 minute to display smth
  const productLocationCountry = product?.location.countryId || null;
  const productLocationCity = product?.location.cityId || null;
  const productLocationZip = product?.productLocationZipCode || null; // TODO
  const productLocationCityArea = product?.location.cityAreaId || null;
  const productPickupLocation = product?.productPickupLocation || null; // TODO
  const productPickupTimeDelta = product?.productPickupTimeDelta || null; // TODO
  const productPriceSystems = product?.priceSystems || null;

  console.log('API  cartItem: ', wishlistDocument);
  return (
    <>
      <h3 className='text-h4 font-semibold'>
        Additional Information
      </h3>
      <div className='py-4'>
        {exactDuration ? (
          <div className='flex gap-2 items-center'>
            <UpdateIcon fontSize={'small'} />
            <h4 className='text-p2 font-semibold'>
              Duration:{' '}
              {`${
                exactDuration < 60
                  ? `${exactDuration} Minutes`
                  : `${Math.floor(exactDuration / 60) || 0} Hours ${
                      exactDuration % 60
                    } Minutes`
              }`}
            </h4>
          </div>
        ) : null}
        {productLocationCountry && productLocationCity ? (
          <div className='flex gap-2 items-center'>
            <UpdateIcon fontSize={'small'} />
            <h4 className='text-p2 font-semibold'>
              City:{' '}
              <Localized
                dictKey={`country:${productLocationCountry}.city.${productLocationCity}.label`}
              />
              {productLocationZip ? ` ( ${productLocationZip} )` : ''}
            </h4>
          </div>
        ) : null}
        {productPickupLocation ? (
          <div className='flex gap-2 items-center'>
            <UpdateIcon fontSize={'small'} />
            <h4 className='text-p2 font-semibold'>
              {`Meeting address: ${productPickupLocation}${
                productLocationCityArea ? ` ( ${productLocationCityArea} )` : ''
              }`}
            </h4>
          </div>
        ) : null}
        {productPickupTimeDelta ? (
          <div className='flex gap-2 items-center'>
            <UpdateIcon fontSize={'small'} />
            <h4 className='text-p2 font-semibold'>
              {`Be there upfront: ${productPickupTimeDelta}`}
            </h4>
          </div>
        ) : null}
      </div>
      <ProductConfiguration
        product={product}
        priceGroupTickets={wishlistDocument.itemConfigurations}
        priceSystemId={''} // TODO
        setPriceGroupTickets={(data) => onCartItemUpdate(wishlistDocument.id, data)}
        showParticipantsInfo
      />
    </>
  );
};

export { WishlistItemAdditionalInformation };
