import * as React from 'react';

import { Grid } from 'src/app/components';

import { useAppContext } from 'src/app/hooks';
// TODO: reuse AppPageLoayout here?
// import { AppPageLayout } from 'src/app.consumer/components';
import { UserAccountPasswordRouter } from 'src/app/container';

import { basePath } from '../navigationBasePath';

export const UserPassword = () => {
  const { isLoggedIn } = useAppContext();
  if (
    !isLoggedIn ||
    typeof window === 'undefined' ||
    !window.location.pathname.startsWith(basePath)
  ) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <UserAccountPasswordRouter />
      </Grid>
    </Grid>
  );
};
