export enum DatePickerType {
  FROM = 'from',
  TO = 'to',
  DAY = 'day',
  DATE = 'date',
  TIME = 'time',
}

export enum Weekday {
  SUN = 'sun',
  MON = 'mon',
  TUE = 'tue',
  WED = 'wed',
  THU = 'thu',
  FRI = 'fri',
  SAT = 'sat',
}
