import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Button, DialogLayout, Grid, Typography } from 'src/app/components';

import CancelIcon from '@material-ui/icons/Block';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ImportDataMode } from 'src/app/constants';
import { useReadXlsxFile } from 'src/app/hooks';
import {
  // BasePickupLocation,
  ParticipantImportData,
  ParticipantsDialogParams,
} from 'src/app/types';
import { useStyles } from 'src/app/context';

import { TableHeader } from './TableHeader';
import { TableActions } from './TableActions';
import { ParticipantsRow } from './ParticipantsRow';

export interface ParticipantsDialogProps extends ParticipantsDialogParams {
  onCloseDialog: () => void;
}

export const ParticipantsDialog = ({
  children,
  file,
  priceSystem,
  pickupLocations,
  schema,
  onCloseDialog,
  onImportData,
}: ParticipantsDialogProps) => {
  const { rows, errors } = useReadXlsxFile<ParticipantImportData>(file, schema, true);
  const [importMode, setImportMode] = React.useState<ImportDataMode>(ImportDataMode.ADD);

  const [itemsActive, setItemsActive] = React.useState<boolean[]>([]);
  const [itemsLocationIds, setItemsLocationIds] = React.useState<(string | null)[]>([]);
  const [itemsPriceGroupIds, setItemsPriceGroupIds] = React.useState<(string | null)[]>(
    [],
  );
  const styles = useStyles();
  React.useEffect(() => {
    if (rows?.length && itemsActive.length === 0) {
      setItemsActive([...rows.map(() => true)]);
      setItemsPriceGroupIds([...rows.map((row) => row.priceGroupId || null)]);
      setItemsLocationIds([...rows.map((row) => row.pickupId || null)]);
    }
  }, [rows, itemsActive.length]);

  // TODO: handle case of errors

  const areAllPriceGroupsTheSameId = itemsPriceGroupIds.find(
    (pg) => pg !== itemsPriceGroupIds[0],
  )
    ? false
    : itemsPriceGroupIds[0];

  const areAllPickupLocationsTheSameId = itemsLocationIds.find(
    (loc) => loc !== itemsLocationIds[0],
  )
    ? false
    : itemsLocationIds[0];

  const areAllParticipantsInSameEnabledState = !itemsActive.find(
    (ia) => ia !== itemsActive[0],
  );

  return (
    <DialogLayout
      dialogDescription={`File name: ${file ? file.name : ''}`}
      dialogControls={
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ToggleButtonGroup
              value={importMode}
              exclusive
              className={styles.w__50}
              onChange={(e, mode) => setImportMode(mode)}
            >
              <ToggleButton
                value={ImportDataMode.ADD}
                className={styles.w__100}
                color={'primary'}
                disabled={importMode === ImportDataMode.ADD}
              >
                <Typography>{'Add to existing participants in cart'}</Typography>
              </ToggleButton>
              <ToggleButton
                value={ImportDataMode.REPLACE}
                className={styles.w__100}
                color={'primary'}
                disabled={importMode === ImportDataMode.REPLACE}
              >
                <Typography>{'Replace existing participant in cart'}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() =>
                onImportData(
                  rows.map(
                    (row, idx) =>
                      ({
                        ...row,
                        priceGroupId: itemsPriceGroupIds[idx],
                        enabled: itemsActive[idx],
                        pickupId: itemsLocationIds[idx],
                      } as ParticipantImportData),
                  ),
                  importMode,
                )
              }
              fullWidth
              variant={'contained'}
              color={'primary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              <CheckBoxIcon className={styles.mr__1} />
              <Typography>Import</Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={onCloseDialog}
              fullWidth
              variant={'contained'}
              color={'secondary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              <CancelIcon className={styles.mr__1} />
              <Typography>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container>
        {/* <Grid item xs={12}>
          <LoadingIndicator />
        </Grid> */}
        <Grid item xs={12}>
          <TableHeader />
        </Grid>
        <Grid item xs={12}>
          <TableActions
            enabled={areAllParticipantsInSameEnabledState && itemsActive[0] !== false}
            locationId={areAllPickupLocationsTheSameId || undefined}
            onChangeEnabled={() =>
              setItemsActive([
                ...itemsActive.map(
                  () =>
                    !(areAllParticipantsInSameEnabledState && itemsActive[0] !== false),
                ),
              ])
            }
            onChangePriceGroup={(priceGroupId) =>
              setItemsPriceGroupIds([...itemsPriceGroupIds.map(() => priceGroupId)])
            }
            onChangePickup={(locationId) =>
              setItemsLocationIds([...itemsLocationIds.map(() => locationId)])
            }
            pickupLocations={pickupLocations}
            priceSystem={priceSystem}
            priceGroupId={areAllPriceGroupsTheSameId || undefined}
          />
        </Grid>
        {rows?.length
          ? rows.map((participantData: ParticipantImportData, idx) => {
              return (
                <Grid item xs={12} key={idx}>
                  <ParticipantsRow
                    {...participantData}
                    index={idx}
                    enabled={itemsActive[idx]}
                    pickupLocations={pickupLocations}
                    priceSystem={priceSystem}
                    priceGroupId={itemsPriceGroupIds[idx] || undefined}
                    onChangeEnabledState={() =>
                      setItemsActive([
                        ...itemsActive.map((item, i) => (i === idx ? !item : item)),
                      ])
                    }
                    onChangePickup={(locationId) =>
                      setItemsLocationIds([
                        ...itemsLocationIds.map((il, index) =>
                          index === idx ? locationId : il,
                        ),
                      ])
                    }
                    onChangePriceGroup={(priceGroupId) =>
                      setItemsPriceGroupIds([
                        ...itemsPriceGroupIds.map((pg, index) =>
                          index === idx ? priceGroupId : pg,
                        ),
                      ])
                    }
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
      {children ? (
        <Grid item xs={12}>
          {children}
        </Grid>
      ) : null}
    </DialogLayout>
  );
};
