/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import 'firebase/analytics';
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/storage';
// import 'firebase/functions';
// import 'firebase/remote-config';

import '../src/global.css';
import { navigate } from 'gatsby';
import CustomLayout from './wrapPageElement';
import CustomProvider from './wrapRootElement';

export const wrapPageElement = CustomLayout;

export const wrapRootElement = CustomProvider;

export const onRouteUpdate = ({ location, prevLocation }: any) => {
  if (!prevLocation?.pathname.startsWith('/admin/partner/product')) return;
  //   console.log('new pathname', location.pathname);
  //   console.log('old pathname', prevLocation?.pathname);
  if (location.pathname === `${prevLocation?.pathname}/configuration`) {
    navigate(`${location.pathname}/baseData`);
  } else if (prevLocation?.pathname === `${location.pathname}/baseData`) {
    navigate(-1);
  } else if (location.pathname === `${prevLocation?.pathname}/activity`) {
    navigate(`${location.pathname}/baseData`);
  }
};
