import * as React from 'react';
// import { useStyles } from 'src/app/context';

export interface FooterProps {
  children?: React.ReactNode;
}

/**
 * Footer
 * @param { FooterProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const Footer = ({ children }: FooterProps) => {
  // const styles = useStyles();
  return <footer className='bg-neutral-800 py-7 px-4 md:py-16 font-body text-neutral-50'>{children}</footer>;
};
