import { TopicNode } from 'src/app/types';
import { TopicNodeType } from 'src/app/constants';

import {
  HowToBookAnActivity,
  Availability,
  PricingLogic,
  SuitableActivities,
} from './searchAndBook';

import { GroupSizePrivateAndPublicActivities } from './activityInformation';
import {
  IAmRunningLateToMyActivity,
  ICantFindTheMeetingPoint,
} from './onTheDayOfTHeActivity';

import { ArrangePickup } from './meetingPointAndPickup';

import { CancelBooking, CancellationByLocalPartner } from './cancellation';

const searchAndBookTopics = [
  {
    id: 'how-to-book-an-activity',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [HowToBookAnActivity],
      },
    ],
  },
  {
    id: 'availability',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [Availability],
      },
    ],
  },
  {
    id: 'pricing-logic',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [PricingLogic],
      },
    ],
  },
  {
    id: 'suitable-activities',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [SuitableActivities],
      },
    ],
  },
];

const activityInformationTopics = [
  {
    id: 'group-size-private-and-public-activities',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [GroupSizePrivateAndPublicActivities],
      },
    ],
  },
];

const onTheDayOfTheActivityTopics = [
  {
    id: 'i-am-running-late-to-my-activity',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [IAmRunningLateToMyActivity],
      },
    ],
  },
  {
    id: 'i-cant-find-the-meeting-point',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [ICantFindTheMeetingPoint],
      },
    ],
  },
];

const meetingPointAndPickupTopics = [
  {
    id: 'arrange-pickup',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [ArrangePickup],
      },
    ],
  },
];

const cancellationTopics = [
  {
    id: 'cancel-booking',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [CancelBooking],
      },
    ],
  },
  {
    id: 'cancellation-by-local-partner',
    type: TopicNodeType.ACCORDION_NODE,
    children: [
      {
        type: TopicNodeType.CONTENT_NODE,
        children: [CancellationByLocalPartner],
      },
    ],
  },
];

export const customerHelpTopics: TopicNode[] = [
  {
    id: 'search-and-book',
    type: TopicNodeType.ACCORDION_NODE,
    children: [...searchAndBookTopics],
  },
  {
    id: 'activity-information',
    type: TopicNodeType.ACCORDION_NODE,
    children: [...activityInformationTopics],
  },
  {
    id: 'on-the-day-of-the-activity',
    type: TopicNodeType.ACCORDION_NODE,
    children: [...onTheDayOfTheActivityTopics],
  },
  {
    id: 'meeting-point-and-pickup',
    type: TopicNodeType.ACCORDION_NODE,
    children: [...meetingPointAndPickupTopics],
  },
  {
    id: 'cancellation',
    type: TopicNodeType.ACCORDION_NODE,
    children: [...cancellationTopics],
  },
];
