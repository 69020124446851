import { FirebaseApp } from 'firebase/app';
import { OrganisationSignupFormData } from 'src/app.organisation/types';
import { SignupFormData } from 'src/app/types';
import { functionNamespace } from './_namespace';
import { callableFunction, createCallableFunctionMeta } from '../lib';

export type UserSignupCallableRequestData = SignupFormData | OrganisationSignupFormData;

export interface UserSignupCallableResponseData extends Record<string, unknown> {
  code: string;
  details: {
    codePrefix: string;
    errorInfo: {
      code: string;
    };
  };
}

/**
 * shopFunction-userSignup
 * @version 0.0.0 functions version
 */
export const userSignup = async (
  firebase: FirebaseApp,
  env: string,
  params: UserSignupCallableRequestData,
  functionName: string = 'userSignup',
) =>
  callableFunction<UserSignupCallableRequestData, UserSignupCallableResponseData>(
    firebase,
    env,
    params,
    createCallableFunctionMeta(functionNamespace, functionName),
  );
