import * as React from 'react';
import { Divider, Grid, Typography } from 'src/app/components';
import UpdateIcon from '@material-ui/icons/Update';
import { useSourcedCities, useLocalizationContext } from 'src/app/hooks';
import { SourcedGQLProduct } from 'src/app/types';

import SourceDataCityIcon from '@material-ui/icons/LocationCity';
import { getTranslationForLanguage } from 'src/lib/document';
import { useAppContext } from 'src/app/context';

interface AdditionalProductCheckoutInformationProps {
  product: SourcedGQLProduct;
}

export const AdditionalProductCheckoutInformation = (
  props: AdditionalProductCheckoutInformationProps,
) => {
  const { product } = props;
  const { location } = product;
  const {
    // cityAreaId,
    cityId,
    // continentId,
    // coord,
    // countryAreaId,
    // countryId,
    // streetName,
    // streetNumber,
    timeDelta,
    // w3w,
  } = location;
  const { isAppDebug } = useAppContext();
  const { language } = useLocalizationContext();

  // TODO: replace by values from i18n
  // const countries = useSourcedCountries();
  const cities = useSourcedCities();

  // const country = countries.find((c) => c.id === countryId);
  const city = cities.find((c) => c.id === cityId);

  const exactDuration = 1; // product?.exactDuration || null; // TODO // NOTE: must be greater than 1 minute to display smth
  // const productLocationZipCode = product?.productLocationZipCode || null; // TODO
  const productPickupLocation = product?.productPickupLocation || null; // TODO
  // const productPickupTimeDelta = product?.productPickupTimeDelta || null; // TODO

  const productLocationCountry = product?.location.countryId || null;
  const productLocationCity = product?.location.cityId || null;
  const productLocationCityArea = product?.location.cityAreaId || null;

  const cityName = getTranslationForLanguage(
    city?.localizations || [],
    language,
    'name',
    isAppDebug,
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h6'} renderAs={'h4'} align={'center'} className='mt-4'>
          Additional Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ padding: '16px' }}>
          {exactDuration ? (
            <Grid item xs={12} style={{ display: 'flex' }}>
              <UpdateIcon fontSize={'small'} />
              <Typography
                variant={'body2'}
                renderAs={'h4'}
                style={{ paddingLeft: '8px' }}
              >
                Duration:{' '}
                {`${
                  exactDuration < 60
                    ? `${exactDuration} Minutes`
                    : `${Math.floor(exactDuration / 60) || 0} Hours ${
                        exactDuration % 60
                      } Minutes`
                }`}
              </Typography>
            </Grid>
          ) : null}
          {productLocationCountry && productLocationCity ? (
            <Grid item xs={12} style={{ display: 'flex' }}>
              <SourceDataCityIcon fontSize={'small'} />
              <Typography
                variant={'body2'}
                renderAs={'h4'}
                style={{ paddingLeft: '8px' }}
              >
                {`City: ${cityName || ''}`}
                {/* <Localized
                  dictKey={`country:${productLocationCountry}.city.${productLocationCity}.label`}
                />
                {productLocationZipCode ? ` ( ${productLocationZipCode} )` : ''} */}
              </Typography>
            </Grid>
          ) : null}
          {productPickupLocation ? (
            <Grid item xs={12} style={{ display: 'flex' }}>
              <UpdateIcon fontSize={'small'} />
              <Typography
                variant={'body2'}
                renderAs={'h4'}
                style={{ paddingLeft: '8px' }}
              >
                {`Meeting address: ${productPickupLocation}${
                  productLocationCityArea ? ` ( ${productLocationCityArea} )` : ''
                }`}
              </Typography>
            </Grid>
          ) : null}
          {timeDelta ? (
            <Grid item xs={12} style={{ display: 'flex' }}>
              <UpdateIcon fontSize={'small'} />
              <Typography
                variant={'body2'}
                renderAs={'h4'}
                style={{ paddingLeft: '8px' }}
              >
                {`Be there upfront: ${timeDelta}`}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
