// import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { ProductQuestionType } from 'src/app/constants';
import { functionNamespace } from './_namespace';
import { callableFunction, createCallableFunctionMeta } from '../lib';

export interface SendProductInformationRequestMessageCallParams
  extends Record<string, unknown> {
  systemId: string;
  organisationId: string;
  productId: string;
  topicType: ProductQuestionType;
  phoneNumber: string;
  name: string;
  email: string;
  body: string;
}

/**
 * commonFunctions-sendProductInformationRequestMessage
 * @version 0.0.0 functions version
 */
export const sendProductInformationRequestMessage = (
  firebase: FirebaseApp,
  env: string,
  params: SendProductInformationRequestMessageCallParams,
  functionName: string = 'sendProductInformationRequestMessage',
) =>
  callableFunction<SendProductInformationRequestMessageCallParams, any>(
    firebase,
    env,
    params,
    createCallableFunctionMeta(functionNamespace, functionName),
  );
