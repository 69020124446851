import * as React from 'react';
import { navigate } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';

import {
  Accordion,
  BurgerButton,
  ButtonTw,
  ButtonGroup,
  Localized,
} from 'src/app/components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { AdminScreenNavigationElement } from 'src/admin/constants';
import { ScreenNavigationConfigData } from 'src/admin/types';
import { useStyles } from 'src/app/context';

export interface ButtonContentProps {
  dictKey: string;
  icon?: React.ReactNode;
  indentation?: string;
}

const OrganisationNavButtonContent = (props: ButtonContentProps) => {
  const { dictKey, icon } = props;
  return (
    <div className='flex gap-x-3 items-center dark:text-neutral-50'>
      {icon || null}
      <span className='relative after:w-0 after:h-[2px] after:absolute after:bg-accent-500 after:-bottom-0 after:right-0  hover:after:w-full transition-all duration-300 after:transition-all after:duration-300 hover:after:right-auto hover:after:left-0 group-active:after:w-full'>
        <Localized dictKey={dictKey} />
      </span>
    </div>
  );
};

export interface AdminNavigationProps<T> {
  basePath: string;
  currentScreen: T;
  i18nNS: string;
  screenNavigationConfig: ScreenNavigationConfigData<T>[];
  setCurrentScreen: (screen: T, options?: {}) => void; // TODO: specify options functionality
}

const indentation = {
  level0: '8px',
  level1: '24px',
};

// TODO: add controlled exapansion based on path
const AdminNavigation = <T extends string>(props: AdminNavigationProps<T>) => {
  const {
    basePath,
    /* currentScreen, */ i18nNS,
    screenNavigationConfig,
    setCurrentScreen,
  } = props;
  const styles = useStyles();
  const theme = useTheme();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = React.useState<boolean>(false);
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);

  if (typeof window === 'undefined') {
    return null;
  }

  const currentPath = window.location.pathname;
  const hasRouteMatch = (path: string) => {
    // handling of subroutes with presence of ('/edit' + '/create')
    if (
      currentPath.match(path) &&
      (currentPath.includes('/edit') || currentPath.includes('/create'))
    ) {
      const truncatedCurrentPathSegments = currentPath.split(`${basePath}${path}`);
      if (
        truncatedCurrentPathSegments?.[1]?.startsWith('/edit') ||
        truncatedCurrentPathSegments?.[1]?.startsWith('/create')
      ) {
        return true;
      }
    }
    return !!(currentPath.match(path) && `${currentPath}` === `${basePath}${path}`);
  };

  const onNavigationClick = (path: string) => {
    setIsBurgerMenuOpen(false);
    // setCurrentScreen(screenId, options);
    navigate(`${basePath}${path}`);
  };
  return (
    <div className='flex my-4 gap-x-4 gap-y-2 flex-wrap md:my-0 md:flex-col md:items-start md:gap-y-4'>

      <div className='hidden w-full py-2'>
        <BurgerButton onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)} />
      </div>

      {/* {(isBurgerMenuOpen || isWiderMD) && screenNavigationConfig?.length } */}
      {screenNavigationConfig?.length // TODO: handle nested buttons etc
        ? screenNavigationConfig.map((cfg) => {
            switch (cfg.type) {
              case AdminScreenNavigationElement.button:
                return (
                  <React.Fragment key={cfg.id}>
                    <button
                      type='button'
                      onClick={() => onNavigationClick(cfg.matchPath)}
                      className={hasRouteMatch(cfg.matchPath) ? 'group font-semibold relative after:w-[calc(100%-2.25rem)] after:h-[2px] after:absolute after:bg-accent-500 after:-bottom-0 after:left-9' : ''}
                    >
                      <OrganisationNavButtonContent
                        dictKey={`${i18nNS}:${cfg.id}.nav label`}
                        icon={cfg.icon}
                        indentation={indentation.level0}
                      />
                    </button>
                  </React.Fragment>
                );
              case AdminScreenNavigationElement.expandableContainer:
                return (
                  <React.Fragment key={cfg.id}>
                    <div key={cfg.id}>
                      <Accordion
                        // TODO: add extend if route or subroute match
                        className={'accordion--menu-item !border-none'}
                        expandIcon={<ExpandMoreIcon className='mr-1' />}
                        summary={
                          <button
                            type='button'
                            className={
                              hasRouteMatch(cfg.matchPath) ||
                              cfg.options?.find((opt) => hasRouteMatch(opt.matchPath))
                                ? '!font-semibold relative after:w-[calc(100%-2.25rem)] after:h-[2px] after:absolute after:bg-accent-500 after:bottom-0 after:left-9'
                                : ''
                            }
                          >
                            <OrganisationNavButtonContent
                              dictKey={`${i18nNS}:${cfg.id}.nav label`}
                              icon={cfg.icon}
                              indentation={indentation.level0}
                            />
                          </button>
                        }
                        variant={'outlined'}
                        id={cfg.id}
                      >
                        <div className='flex items-start flex-col gap-3 pt-4 pl-8'>
                          {cfg.options?.length
                            ? cfg.options.map((cfgOption) => {
                                switch (cfgOption.type) {
                                  case 'button': // TODO: create recursion
                                    return (
                                      // <React.Fragment key={cfgOption.id}>
                                      //   <Grid item xs={12} style={{ paddingTop: '2px' }}>
                                      <ButtonTw
                                        key={cfgOption.id}
                                        onClick={() =>
                                          onNavigationClick(cfgOption.matchPath)
                                        }
                                        variant={'text'}
                                        className={
                                          hasRouteMatch(cfgOption.matchPath)
                                            ? '!font-semibold relative after:w-full after:h-[2px] after:absolute after:bg-accent-500 after:bottom-0 after:left-0'
                                            : ''
                                        }
                                      >
                                        <OrganisationNavButtonContent
                                          dictKey={`${i18nNS}:${cfgOption.id}.nav label`}
                                          //icon={cfgOption.icon}
                                          indentation={indentation.level1}
                                        />
                                      </ButtonTw>
                                      //   </Grid>
                                      //   <Grid item xs={12}>
                                      //     <Divider />
                                      //   </Grid>
                                      // </React.Fragment>
                                    );
                                  default:
                                    return null;
                                }
                              })
                            : null}
                        </div>
                      </Accordion>
                    </div>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })
        : null}
    </div>
  );
};

export { AdminNavigation };
