import * as React from 'react';
import clsx from 'clsx';

import { DropDown, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useLocalizationContext, useSourcedLocales } from 'src/app/hooks';

import { getBaseDocumentLocales } from 'src/lib/document';

interface LanguageFilterProps {
  availableLanguageIds: string[];
  currentLanguageFilterIds: string[];
  setLanguageFilter: (id: string, isOn: boolean) => void;
}

export const LanguageFilter = ({
  availableLanguageIds,
  currentLanguageFilterIds,
  setLanguageFilter,
}: LanguageFilterProps) => {
  const styles = useStyles();
  const locales = useSourcedLocales();
  const { language } = useLocalizationContext();

  const isFilterOn = () => !!currentLanguageFilterIds.length;

  const isFilterOnId = (id: string) =>
    !!currentLanguageFilterIds.find((filterId) => filterId === id);

  const onChangeFilter = (id: string) => {
    setLanguageFilter(id, !isFilterOnId(id));
  };

  const selectedLocale = currentLanguageFilterIds?.length
    ? locales.find((ca) => ca.id === currentLanguageFilterIds[0])
    : null;

  const selectedIndex = selectedLocale
    ? locales.findIndex((ca) => ca.id === currentLanguageFilterIds[0])
    : null;
  return (
    <Grid container spacing={2} className={clsx(styles.py__1)}>
      <Grid item xs={12}>
        <DropDown
          disabled={!availableLanguageIds?.length}
          // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
          className={styles.dropDown__Button}
          variant={'contained'}
          color={isFilterOn() ? 'secondary' : 'primary'}
          label={
            <Typography align={'center'}>
              {currentLanguageFilterIds?.length
                ? `${
                    getBaseDocumentLocales(selectedLocale, language).name ||
                    currentLanguageFilterIds[0]
                  }`
                : 'Select a language'}
            </Typography>
          }
          placement={'top'}
          options={[
            ...availableLanguageIds
              .filter((localeId) => locales.find((loc) => loc.id === localeId))
              .map((localeId) => {
                const locale = locales.find((loc) => loc.id === localeId);
                return !locale ? null : (
                  <Grid container key={localeId}>
                    <Grid item xs={12}>
                      <Typography
                        color={!isFilterOnId(localeId) ? 'primary' : 'secondary'}
                        align={'center'}
                        renderAs={'span'}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {getBaseDocumentLocales(locale, language).name || localeId}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }),
          ]}
          selectedIndex={selectedIndex !== null ? selectedIndex : -1}
          onChange={(id: string) => onChangeFilter(availableLanguageIds[id])}
          showArrowIcon={false}
          openOnHover
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
