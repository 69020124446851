import * as React from 'react';
import { FieldValues } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
// import dayjs from 'dayjs';

import { Localized } from 'src/app/components';

import {
  FormErrors,
  FormControl,
  FormRootContainerProps,
  FormLayout,
  FieldStyle,
} from 'src/app/components/RHF';

import { SelectField, TextField, DateField } from 'src/app/components/RHF/Fields';

// TODO: move to central place + generic
interface BaseFormProps<T extends FieldValues> {
  // formProps: FormRootContainerProps<T>;
  i18nBasePath: string;
  isValid: boolean;
}

/** This is the really used user signup form */
export const SignupFormUser = <T extends FieldValues>({
  i18nBasePath,
  isValid,
}: BaseFormProps<T>) => {
  const textFieldIds = [
    'email',
    'password',
    'passwordRepeat',
    'gender',
    'title',
    'displayName',
    'firstName',
    'lastName',
    'dob',
    'phoneNumber',
  ];
  // const isValid = true; // TODO

  const genderOptions = [{ id: 'female' }, { id: 'male' }, { id: 'other' }];

  return (
    <FormLayout
      // header={
      //   <Grid item xs={12}>
      //     <Typography align={'center'} variant={'h4'} renderAs={'h2'}>
      //       <Localized dictKey={`${i18nBasePath}.form.title`} />
      //     </Typography>
      //   </Grid>
      // }
      formControl={
        <FormControl
          isValid={typeof isValid !== 'undefined' ? isValid : true} className={'text-center'} /* hasReset */
        />
      }
      // renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
    >
      {textFieldIds?.map((tfId) => {
        if (tfId === 'gender') {
          return (
            <SelectField
              defaultValue={''}
              key={tfId}
              name={tfId}
              i18nKey={tfId}
              i18nPath={i18nBasePath}
              // fieldStyle={FieldStyle.CLEAN}
              // i18nKey={'localeId'}
              // i18nPath={arrayI18nPrefix}
              // name={'localeId'}
              // namePrefix={arrayNamePrefix}
              options={[
                <MenuItem key={'none'} value=''>
                  <em>None selected</em>
                </MenuItem>,
                ...genderOptions.map((gender) => {
                  return (
                    <MenuItem key={gender.id} value={gender.id}>
                      <Localized
                        dictKey={`shop:signupForm.gender.options.${gender.id}`}
                      />
                    </MenuItem>
                  );
                }),
              ]}
            />
          );
        }
        if (tfId === 'dob') {
          // FIXME: limit to over 18???
          return (
            <DateField
              autoComplete={tfId}
              key={tfId}
              name={tfId}
              i18nKey={tfId}
              i18nPath={i18nBasePath}
              datePickerMode={'DATE'}
              // fieldStyle={FieldStyle.CLEAN}
              disableFuture
            />
          );
        }
        return (
          <TextField
            // autoComplete={tfId}
            key={tfId}
            name={tfId}
            i18nKey={tfId}
            i18nPath={i18nBasePath}
            fieldStyle={FieldStyle.CLEAN}
            isPassword={tfId === 'password' || tfId === 'passwordRepeat'}
            // autoComplete={tfId === 'passwordRepeat' ? 'new-password' : tfId}
          />
        );
      }) || null}
    </FormLayout>
  );
};
