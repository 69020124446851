import * as React from 'react';
import MButtonGroup, {
  ButtonGroupProps as MButtonGroupProps,
} from '@material-ui/core/ButtonGroup';

import { BaseComponentProps, CombinedAppProps } from 'src/app/types';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';

type CombinedBaseProps = BaseComponentProps & MButtonGroupProps;

export interface ButtonGroupProps extends CombinedBaseProps {}

/**
 * ButtonGroup
 * @param { ButtonGroupProps } props
 * @version 0.0.0
 */
function ButtonGroup(props: ButtonGroupProps): React.ReactElement<ButtonGroupProps> {
  const { children, ...passProps } = props;
  return (
    <MButtonGroup aria-label={'Navigation'} {...passProps}>
      {children}
    </MButtonGroup>
  );
}

// TODO: maybe needed
// export const MenuItem = React.forwardRef(
//   <T extends Record<string, unknown>>(
//     props: MenuItemProps<'li', T>,
//     ref: React.Ref<any>, // TODO: type
//   ) => <MuiMenuItem {...props} innerRef={ref} />,
// );

const WAPButtonGroup = (props: CombinedAppProps<ButtonGroupProps>) =>
  withoutAppProps<CombinedAppProps<ButtonGroupProps>, ButtonGroupProps>(
    ButtonGroup,
    props,
  );

export { ButtonGroup, WAPButtonGroup };
