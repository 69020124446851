import { FlagCountryId } from 'src/app/constants';

// TODO: import from sourcing from db
export const callSupportFlagNumbers: { id: FlagCountryId; phoneNumber: string }[] = [
  {
    id: FlagCountryId.GB,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.DE,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.HR,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.RU,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.US,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.FR,
    phoneNumber: 'N/A',
  },
];

export const whatsappSupportFlagNumbers: { id: FlagCountryId; phoneNumber: string }[] = [
  {
    id: FlagCountryId.GB,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.DE,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.HR,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.RU,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.US,
    phoneNumber: 'N/A',
  },
  {
    id: FlagCountryId.FR,
    phoneNumber: 'N/A',
  },
];
