import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
// import WbSunnyIcon from '@material-ui/icons/WbSunny'; // TODO: iconize

import { Divider, Glass, Grid, Typography } from 'src/app/components';
import { useStyles } from 'src/app/context';
import { useOpenWeatherMap } from 'src/app/hooks';

export interface ContentItemWeatherInformationProps {
  city: string;
  cityName: string;
  country: string;
}

export const ContentItemWeatherInformation = ({
  city,
  cityName,
  country,
}: ContentItemWeatherInformationProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const { hasError, isFetching, weatherData, getWeatherDataFor } = useOpenWeatherMap();

  // TODO: fetch one per city per session => use context (or even redux?)
  React.useEffect(() => {
    if (!isFetching && city && country) {
      getWeatherDataFor(country, city);
    }
  }, [isFetching]);

  return weatherData ? (
    <Glass
      blurAmount={3}
      opacity={0.2}
      className={clsx(
        // styles.p__4,
        isWiderMD ? styles.itemCardTitleContainer__additional : '',
      )}
    >
      <Typography variant={'h6'} renderAs={'p'} align={'center'}>
        Current weather in {cityName}
      </Typography>
      <Divider />
      <Grid container>
        <Grid item xs={6}>
          <Typography align={'center'}>Temp. {weatherData.main.temp}°C </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align={'center'}>
            Feels like {weatherData.main.feels_like}°C{' '}
          </Typography>
        </Grid>
      </Grid>
      <Typography align={'center'}>
        Min / Max: {weatherData.main.temp_min}°C / {weatherData.main.temp_max}°C{' '}
      </Typography>
      <Typography align={'center'}>
        Wind {weatherData.wind.speed}m/s from {weatherData.wind.deg}°{' '}
      </Typography>
      <Typography align={'center'}>Humidity: {weatherData.main.humidity}% </Typography>
      <Divider />
      {weatherData.weather.map((wi) => {
        return (
          <Typography
            color={'primary'}
            variant={'h6'}
            renderAs={'p'}
            key={wi.id}
            align={'center'}
          >
            {wi.description?.toUpperCase() || ''}
          </Typography>
        );
      })}
    </Glass>
  ) : null;
};
