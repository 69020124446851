import * as React from 'react';

import { SourcedGQLProduct } from 'src/app/types';
import { ProductFilter } from './productFilter.types';

export interface ProductListFilterContainerProps {
  products: SourcedGQLProduct[];
  renderElements: (renderProps: {
    data: SourcedGQLProduct[];
    filter: ProductFilter[];
    setFilter: (filter: ProductFilter[]) => void;
  }) => React.ReactNode;
}

export const ProductListFilterContainer = ({
  products,
  renderElements,
}: ProductListFilterContainerProps) => {
  const [currentFilter, setCurrentFilter] = React.useState<ProductFilter[]>([]);

  // TODO: make this operation asynchronous (apply filtering in a promise all, on each item?)
  const getFilteredProducts = (filter: ProductFilter[]) => {
    return filter?.length
      ? products.filter((product) => {
          let isProductInResultSet: boolean = true;

          for (let index = 0; index < filter.length && isProductInResultSet; index++) {
            if (!filter[index](product)) {
              isProductInResultSet = false;
            }
          }
          return isProductInResultSet;
        })
      : products;
  };

  const [filteredProducts, setFilteredProducts] = React.useState<SourcedGQLProduct[]>(
    getFilteredProducts(currentFilter),
  );

  const setFilter = (filter: ProductFilter[]) => {
    setCurrentFilter(filter);
    setFilteredProducts(getFilteredProducts(filter));
  };

  return (
    <>
      {renderElements({
        data: filteredProducts,
        filter: currentFilter,
        setFilter,
      })}
    </>
  );
};
