import * as React from 'react';
import { FieldValues } from 'react-hook-form';

import {
  FormControl,
  FormErrors,
  FormRootContainerProps,
  FormLayout,
  FieldStyle,
  TextField,
} from 'src/app/components/RHF';

// TODO:
// const UploadAttachment = () => {
//   return (
//     <Grid container>
//       <Grid item xs={4}>
//         <Typography align={'left'}>Add Attachment (PDF or image file)</Typography>
//       </Grid>
//       <Grid item xs={4}>
//         <Button fullWidth disabled color={'primary'} onClick={onChooseFileClick}>
//           <Typography>Choose file</Typography>
//         </Button>
//       </Grid>
//       <Grid item xs={4}>
//         <Typography align={'right'}>Maximum 19.5 MB</Typography>
//       </Grid>
//     </Grid>
//   );
// };

interface BaseFormProps<T extends FieldValues> {
  formProps: FormRootContainerProps<T>;
  i18nBasePath: string;
}

export interface BookingReferenceFormProps<T extends FieldValues>
  extends BaseFormProps<T> {
  isSubmitDataValid: boolean;
}

export const BookingReferenceForm = <T extends FieldValues>({
  i18nBasePath,
  isSubmitDataValid,
}: BookingReferenceFormProps<T>) => {
  return (
    <FormLayout
      formControl={<FormControl isValid={isSubmitDataValid} />}
      // renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
    >
      <TextField
        name={'bookingReferenceId'}
        i18nKey={'bookingReferenceId'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        name={'name'}
        i18nKey={'name'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        name={'email'}
        i18nKey={'email'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        name={'phone'}
        i18nKey={'phone'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        name={'body'}
        i18nKey={'body'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
        multiline
      />
      {/* ATTACHMENTS */}
    </FormLayout>
  );
};
