export const getLocalizationNamespaceDocumentPath = (
  systemId: string,
  localeId: string,
  moduleId: string,
) => `system/${systemId}/localization/${localeId}/module/${moduleId}`;

export const getLocalizationNamespaceDocumentBackupPath = (
  systemId: string,
  localeId: string,
  moduleId: string,
  docNamePostFix: string,
) =>
  `system/${systemId}/localization/${localeId}/module_backup/${moduleId}_${docNamePostFix}`;
