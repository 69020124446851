import * as React from 'react';

import { BaseComponentProps, SourcedGQLCategoryType } from 'src/app/types';
import { useSourcedProducts } from 'src/app/hooks';

import { GatsbyNodeType } from 'src/app/constants';

import { useLocalizationContext } from 'src/app/context';
import { ContentItemScreen } from '../ContentItemScreen';

export interface CategoryTypeScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLCategoryType;
}

export const CategoryTypeScreen = (props: CategoryTypeScreenProps) => {
  const { item, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  const products = useSourcedProducts();

  return (
    <ContentItemScreen
      collectionMappings={[
        // {
        //   id: GatsbyNodeType.COUNTRY,
        //   collectionItems: countries,
        //   collectionItemIdPropertyName: 'continentId',
        // },
        // TODO: display category + product per cat type
        // display products per cat type
        {
          id: GatsbyNodeType.PRODUCT,
          collectionItems: products,
          // collectionItemIdPropertyName: '',
          collectionItemIdMetaArrayIdPropertyName: 'categoryTypeIds',
          renderWithProductListItem: true, // TODO:
        },
      ]}
      itemLocations={[]}
      itemType={GatsbyNodeType.CATEGORY_TYPE}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
