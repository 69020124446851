import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Glass, Grid, ItemTextParagraph, Typography } from 'src/app/components';
import {
  ActivityProduct,
  DocumentLocalizationNodeData,
  BaseComponentProps,
} from 'src/app/types';
import { useLocalizationContext } from 'src/app/hooks';
import { ActivityProductAdditionalContent } from './ActivityProductAdditionalContent';

import { ProductCard, ProductTimeline } from '../components';

// import { ProductReviews } from '../ProductReviews'; // TODO

export interface BulletContainerProps extends BaseComponentProps {
  bullets: DocumentLocalizationNodeData[]; // atm support only for BaseBullets
  containerTitle: string;
}

const BulletContainer = (props: BulletContainerProps) => {
  const { bullets, containerTitle } = props; // TODO: render different bulletTypes...
  return (
    <ItemTextParagraph title={containerTitle}>
      <Grid item xs={12}>
        <ul>
          {bullets?.length
            ? bullets.map((bullet, idx) => {
                return (
                  <li key={idx} style={{ margin: '8px 0' }}>
                    <Typography>{bullet.title}</Typography>
                    {bullet.additionalInformation ? (
                      <Typography>{bullet.additionalInformation}</Typography>
                    ) : null}
                  </li>
                );
              })
            : null}
        </ul>
      </Grid>
    </ItemTextParagraph>
  );
};

export interface ActivityProductViewProps extends BaseComponentProps {
  activity: ActivityProduct;
  onAddWishlistProduct: () => void;
  reviewAnchorId: string;
}

/**
 * ActivityProductView
 * @param { ActivityProductViewProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const ActivityProductView = (props: ActivityProductViewProps) => {
  const { activity, onAddWishlistProduct, reviewAnchorId } = props;
  const {
    productLocales,
    productLocationCity,
    productLocationZipCode,
    productPickupLocation,
  } = activity;
  const theme = useTheme();
  const isWiderXL = useMediaQuery(theme.breakpoints.up('xl') /* '(min-width:600px)' */);
  const isWiderLG = useMediaQuery(theme.breakpoints.up('lg') /* '(min-width:600px)' */);
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md') /* '(min-width:600px)' */);
  const isWiderSM = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);
  const isWiderXS = useMediaQuery(theme.breakpoints.up('xs') /* '(min-width:600px)' */);
  const { language } = useLocalizationContext();
  // TODO: blend in addition appBar with product nav points

  const map = {
    // height: isWiderMD ? 586 : 293,
    // width: isWiderMD ? 586 : 293, // 1172
    height: 0,
    width: 0,
    scale: 2,
    zoom: 19,
  };
  if (isWiderXS) {
    // map.height = 146;
    // map.width = 146;
    map.height = 326;
    map.width = 326;
  }
  if (isWiderSM) {
    // map.height = 293;
    // map.width = 293;
    map.height = 652;
    map.width = 652;
  }
  if (isWiderMD) {
    map.height = 466;
    map.width = 466;
  }
  if (isWiderLG) {
    map.height = 586;
    map.width = 586;
  }
  if (isWiderXL) {
    map.height = 586;
    map.width = 586;
  }
  const area = productPickupLocation; // productLocationAddress; // productLocationCityArea
  const city = productLocationCity; // productLocationZipCode; // productLocationCity
  const country = 'Croatia';
  const embedId = 'TlXjWLBVHTc';

  const apiKey = 'AIzaSyD5FpBZYFnWoqj7r4xagUwfS4qyqexVFqs';
  const reqParams = `center=${area},${city},${country}&maptype=hybrid&language=de&zoom=${
    map.zoom
  }&scale=${map.scale}&size=${map.width * map.zoom}x${
    map.height * map.zoom
  }&maptype=roadmap&key=${apiKey}`;
  console.log('!reqParams: ', reqParams);
  const imgMapRequest = `https://maps.googleapis.com/maps/api/staticmap?${reqParams}`; // &amp;KEY_OR_CLIENT=YOUR_KEY_OR_ID

  console.log('activityViewProps: ', props);

  let locales = productLocales?.find((locale) => locale.localeId === language);
  if (!locales) {
    locales = productLocales?.find((locale) => locale.localeId === 'en') || null;
  }

  if (!locales) {
    return null;
  }
  console.log('rendering');

  return (
  
      <div>
        <ProductCard
          onAddWishlistProduct={onAddWishlistProduct}
          product={activity} // TODO: SourcedGQLProduct<T>
          reviewAnchorId={reviewAnchorId}
          extraProps={<ActivityProductAdditionalContent />}
        >
          <Grid container spacing={2}>
            {/* // TODO - do fetch for notifications or bake them in every 24h? */}
            {/* <Grid item xs={12} md={7}>
              <Glass
                style={{
                  minHeight: '96px',
                  // backgroundColor: '#00e5ff55',
                  marginTop: '16px',
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-evenly',
                }}
              >
                <InfoOutlined style={{ marginRight: '16px' }} />
                <Typography>Important notifications</Typography>
              </Glass>
            </Grid> */}
            {/* <Grid item xs={12} md={5}>
              <ProductCheckoutContainer<ProductType.ACTIVITY> product={activity} />
            </Grid> */}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} style={{ margin: '32px 0' }}>
              <ItemTextParagraph title={`About this ${activity.productType}`}>
                <Typography paragraph>
                  {locales?.productShortDescription ?? ''}
                </Typography>
              </ItemTextParagraph>
            </Grid>
            <Grid item xs={12} md={7} style={{ margin: '32px 0' }}>
              <ItemTextParagraph title={`${activity.productType} program`}>
                <ProductTimeline timelineData={locales?.productTimeline ?? []} />
              </ItemTextParagraph>
            </Grid>
            <Grid item xs={12} md={5} style={{ margin: '32px 0' }}>
              <BulletContainer
                bullets={locales?.productFeatures || []}
                containerTitle={'Features'}
              />

              <div style={{ margin: '64px 0 32px 0' }}>
                <BulletContainer
                  bullets={locales?.productIncludes || []}
                  containerTitle={'Activity includes'}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider variant='middle' />
            </Grid>
            <Grid item xs={12} style={{ margin: '32px 0' }}>
              <div style={{ padding: '16px' }}>
                <Typography variant={'h4'} renderAs={'h2'} paragraph>
                  Description
                </Typography>
                <Typography paragraph>{locales?.productFullDescription ?? ''}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ margin: '32px 0' }}>
              <Glass>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <img
                      src={imgMapRequest}
                      alt={'map'}
                      style={{
                        // minWidth: `${map.width}px`,
                        // maxWidth: `${map.width}px`,
                        width: `${map.width}px`,
                        // aspectRatio: 'auto',
                        // objectPosition: '50% 50%',
                        // objectFit: 'fill',
                        // height: `${map.height}px`,
                        height: `${map.height}px`,
                        borderRadius: '16px 0 0 16px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: '32px 0' }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          color={'primary'}
                          align={'center'}
                          variant={'h4'}
                          renderAs={'h2'}
                          paragraph
                        >
                          Location information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align={'center'}
                          variant={'h6'}
                          renderAs={'h3'}
                          paragraph
                        >
                          City: {city}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align={'center'}
                          variant={'h6'}
                          renderAs={'h3'}
                          paragraph
                        >
                          Street: {area}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '32px' }}>
                      <Grid item xs={12}>
                        <Typography
                          color={'primary'}
                          align={'center'}
                          variant={'h4'}
                          renderAs={'h2'}
                          paragraph
                        >
                          Meeting point information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align={'center'}
                          variant={'h6'}
                          renderAs={'h3'}
                          paragraph
                        >
                          - Meetingpoint information TODO
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '32px' }}>
                      <Grid item xs={12}>
                        <Typography
                          color={'primary'}
                          align={'center'}
                          variant={'h4'}
                          renderAs={'h2'}
                          paragraph
                        >
                          Points of interest
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align={'center'}
                          variant={'h6'}
                          renderAs={'h3'}
                          paragraph
                        >
                          - POI information TODO
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Glass>
            </Grid>
            <Grid item xs={12} style={{ margin: '32px 0' }}>
              <Glass className='video-responsive item-container'>
                <iframe
                  // width='853'
                  // height='480'
                  src={`https://www.youtube.com/embed/${embedId}`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Embedded youtube product video'
                />
              </Glass>
            </Grid>
            <Grid item xs={12} md={7} style={{ margin: '32px 0' }}>
              <BulletContainer
                bullets={locales?.productAdditionalInformation || []}
                containerTitle={'Additional information'}
              />
            </Grid>
            <Grid item xs={12} md={5} style={{ margin: '32px 0' }}>
              <BulletContainer
                bullets={locales?.productExcludes || []}
                containerTitle={'Activity excludes'}
              />
            </Grid>
          </Grid>
        </ProductCard>
      </div>
    
  );
};

export { ActivityProductView };
