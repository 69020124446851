import clsx from 'clsx';
import * as React from 'react';

import { useStyles } from 'src/app/context';

export const CenteredContainer = ({
  className,
  children,
  centerV,
}: {
  className?: string;
  children: React.ReactNode;
  centerV?: boolean;
}) => {
  const styles = useStyles();
  return (
    <div
      className={clsx({
        [styles.centered]: centerV === undefined,
        [styles.centered__vertical]: centerV !== undefined ? centerV : false,
        [className || '']: !!className,
      })}
    >
      {children}
    </div>
  );
};
