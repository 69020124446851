import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider, Grid, Toolbar, Typography, TextInputField } from 'src/app/components';
import { BaseDocumentData } from 'src/app/types';

export interface TableToolbarProps<T extends BaseDocumentData> {
  autocompleteOptions?: T[];
  children?: React.ReactNode;
  centerToolbar?: React.ReactNode;
  filterOptions?: any; // TODO
  groupBy?: string;
  title: string | React.ReactNode;
  onChange: (document: T | null) => void; // TODO: string or document???
}

export const TableToolbar = <T extends BaseDocumentData>({
  autocompleteOptions,
  children,
  centerToolbar,
  filterOptions,
  groupBy,
  onChange,
  title,
}: TableToolbarProps<T>) => {
  // const styles = useStyles();
  return (
    <>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          padding: '0',
        }}
      >
        <Grid container>
          {true ? null : (
            <>
              <Grid item xs={12} md={3}>
                <Toolbar
                  style={{
                    padding: '0',
                  }}
                >
                  <Typography
                    color={'primary'}
                    align={'left'}
                    variant={'h6'}
                    renderAs={'p'}
                  >
                    {title}
                  </Typography>
                </Toolbar>
              </Grid>
              <Grid item xs={12} md={5}>
                {''}
              </Grid>
              <Grid item xs={12} md={4}>
                <Toolbar
                    style={{
                      padding: '0',
                    }}
                  >
                  <Autocomplete
                    disabled
                    fullWidth
                    size={'small'}
                    id='combo-box-demo'
                    options={autocompleteOptions || []}
                    groupBy={
                      groupBy ? (option) => option?.[groupBy]?.toUpperCase() : undefined
                    }
                    filterOptions={filterOptions}
                    getOptionLabel={
                      (option: T) =>
                        groupBy ? `${option[groupBy]} : ${option.id}` : `${option.id}`
                      // <Typography>{`${option.propName} - ${option.propValue}`}</Typography>
                      // option.propName
                    }
                    renderOption={(option: T) => (
                      <>
                        {/* <span>{countryToFlag(option.code)}</span> */}
                        {option.id}
                      </>
                    )}
                    // onSelect={(e) => onSelect(e.target.value)}
                    onChange={(event: any, newValue: T | null) => {
                      // setValue(newValue);
                      onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextInputField
                        disabled
                        color={'primary'}
                        variant={'outlined'}
                        label={'Search...'}
                        size={'small'}
                        {...params}
                      />
                    )}
                  />
                </Toolbar>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {centerToolbar || null}
          </Grid>
          {children || null}
        </Grid>
      </Toolbar>
      <div className='py-2'>
        <Divider />
      </div>
    </>
  );
};
