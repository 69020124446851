import * as React from 'react';
import { DialogType } from 'src/app/constants';

import { Button, ButtonGroup, Grid, Typography } from 'src/app/components';

// TODO: accept a password or id to verify
// NOTE: there is also a hook version of this => "useConfirmAction"
export const confirmAction = async (
  action: () => Promise<void> | void,
  openDialog: (type: DialogType, dialogData?: React.ReactNode | any) => void,
  closeDialog: () => void,
  additionalMessage?: string,
) => {
  openDialog(
    DialogType.CONFIRMATION,
    <Grid container spacing={2}>
      {additionalMessage ? (
        <Grid item xs={12}>
          <Typography paragraph>{additionalMessage}</Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography paragraph>Are your really sure?</Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup fullWidth>
          <Button
            onClick={() => {
              action();
              closeDialog();
            }}
          >
            YES
          </Button>
          <Button
            onClick={() => {
              closeDialog();
            }}
          >
            CANCEL
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>,
  );
};
