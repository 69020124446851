import * as React from 'react';
import { ErrorBoundary as BaseErrorBoundary, FallbackProps } from 'react-error-boundary';

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
  FallbackComponent: React.ComponentType<FallbackProps>;
  errorHandler: (error: Error, info: { componentStack: string }) => void;
  onReset: () => void;
}

export const ErrorBoundary = ({
  children,
  FallbackComponent,
  errorHandler,
  onReset,
}: ErrorBoundaryProps) => {
  return (
    <BaseErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={errorHandler}
      onReset={onReset}
    >
      {children}
    </BaseErrorBoundary>
  );
};
