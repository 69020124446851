import dayjs from 'dayjs';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import * as React from 'react';

import { AddToCartConfirmation } from 'src/app.consumer/components';
import {
  BookingSystemType,
  // ProductCategoryType,
  CartSystemType,
  LocalStorageKey,
  // GatsbyNodeType,
  NotificationType,
} from 'src/app/constants';
import { useAppContext, useFirebaseCollectionEZ } from 'src/app/hooks';
import {
  AddToCartDialogInitialConfig,
  AppDate,
  DayTime,
  DaytimeEventDocument,
  // FirebaseTimestamp,
  GroupDiscount,
  LocalStorageCart,
  Price,
  ProductEventParticipant,
  ShoppingCartItemConfiguration,
  ShoppingCartItemDocumentData,
  SourcedGQLProduct,
} from 'src/app/types';
import { getDayTimeFromTimeString } from 'src/lib/date';
import { calculatePriceGroupTotalPrice, calculateTotalPrice, calculatePriceFromPriceString,convertPriceToFloat, calculateGroupQuantityDiscountPrice, getGroupQuantityDiscounts, getProductPriceSystemBasePrice, getBasePriceByFeatureEnabled } from 'src/lib/price';

// import { useShoppingCartContext } from 'src/app/context';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createBaseDocumentCreatedBy } from 'src/app/lib';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getEventTicketOrderLimit } from 'src/lib/event';
import { getCartItemsTotalParticipantCount } from 'src/lib/participant';
import { ProductAddToCartControls } from './ProductAddToCartControls';

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO: REFACTOR

export interface ProductAddToCartContainerProps {
  children?: React.ReactNode;
  bookingSystemType: BookingSystemType;
  product: SourcedGQLProduct;
  onSuccess?: () => void;
  pickupOptionId?: string; // TODO: decide if optional
  initialParams?: AddToCartDialogInitialConfig;
}

// TODO: remove useFirebaseCollection => replace with exact document call

const createInitialPriceGroupTickets = (
  priceGroup: GroupDiscount,
  priceSystemId: string
): ShoppingCartItemConfiguration => ({
  priceGroupId: priceGroup.id,
  count: 0,
  priceSystemId,
  participants: [],
});

const createInitialPrivatePriceGroupTickets = (
  priceGroup: GroupDiscount,
  priceSystemId: string, 
  selectedPriceSystem:any 
): ShoppingCartItemConfiguration => ({
  priceGroupId: priceGroup.id,
  count: selectedPriceSystem?.privateBooking?.minParticipants,
  priceSystemId,
  participants: [],
});
/**
 * ProductCheckoutContainer
 * @param { ProductAddToCartContainerProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const ProductAddToCartContainer = (props: ProductAddToCartContainerProps) => {
  const { initialParams, product, onSuccess, pickupOptionId } = props;
  const { id, availability, priceSystems, meta } = product;
  const { daytimeEvents } = availability;
  const { categoryIds, categoryTypeIds, organisationId, tagIds } = meta;
  console.log('ProductCheckoutContainer - props: ', props);
  const { firebase, user, createNotification, createStackedNotification } =
    useAppContext();
  const [bookingSystemType, setBkSystemType] = React.useState<BookingSystemType>(BookingSystemType.PUBLIC); // FIXME: DEV false initially (issue of resetting state)
  const [date, setDate] = React.useState<AppDate>(
    initialParams ? initialParams.date : dayjs().add(1, 'day'),
  ); // TODO: DEV remove 1 extra day //TODO: => auto insert next day with a valid event date
  const [fetchedEventDocument, setFetchedEventDocument] =
  React.useState<DaytimeEventDocument | null>(
    initialParams ? initialParams.fetchedEventDocument : null,
  );
  const [dayTime, setDayTime] = React.useState<DayTime | null>(
    initialParams
      ? initialParams.dayTime
      : {
          hours: daytimeEvents?.[0]?.startTime?.split(':')?.[0] || '00', // TODO
          minutes: daytimeEvents?.[0]?.startTime?.split(':')?.[1] || '00',
        },
  );
  
  const priceSystemId = fetchedEventDocument?.priceSystem.id;
  const initSystemId = daytimeEvents[0].priceSystemId;
  const initPriceSystemId = priceSystems.findIndex((ps) => ps.id === (priceSystemId|| initSystemId));
  const selectedPriceSystem = priceSystems[initPriceSystemId];
  
  const privatePriceGroupTickets = { 
    discountFixed: 0,
    discountPercentage: 0,
    discountType: 'fixed',
    id: "__PRIVATE_GROUP",
    isEnabled: true
  }
  const [isPriceOptionsOpen, setIsPriceOptionsOpen] = React.useState(!!initialParams); // FIXME: DEV false initially (issue of resetting state)
  const [priceGroupTickets, setPriceGroupTickets] = React.useState<
    ShoppingCartItemConfiguration[] | []
  >([]);

  const [triggerUpdate, setTriggerUpdate] = React.useState<boolean>(false);
  const [hasInitialRequest, setHasInitialRequest] = React.useState<boolean>(false);

  const year = date?.get('year').toString() || null;
  const month = date ? (date.get('month') + 1).toString().padStart(2, '0') : null;
  const day = date?.get('date').toString().padStart(2, '0') || null;
  const hours = dayTime?.hours || null;
  const minutes = dayTime?.minutes || null;

  const isDateValid = () => Boolean(year && month && day && hours && minutes);
  // FIXME: TIMEZONE LOCALE
  const combinedDate = new Date( // FIXME:_ REMOVE => IS CURRENTLY FURTHER DOWN IN CHILD COMPONENT USED
    `${year}-${month}-${day}T${hours}:${minutes}:00.000+01:00`, // TODO: convert user browser Timezone
  );

  const tzDate = dayjs.tz(`${year}-${month}-${day} ${hours}:${minutes}`, 'Europe/Berlin');
  const combinedDateId = `${year}-${month}-${day}-${hours}-${minutes}-0000`; // TODO: convert user browser Timezone
  // const timestamp = new Date('2021-07-14T10:00:00.000+02:00');

  // TODO: query for free capacity (doc.orders.length < doc.priceSystem.capacity (pub/priv))
  console.log('combinedDate: ', combinedDate);
  console.log('combinedDateId: ', combinedDateId);
  // FIXME: replace with useDocument etc
  const { results: fetchResults } = useFirebaseCollectionEZ<DaytimeEventDocument>(
    `product/${id}/daytimeEvent`,
    triggerUpdate && isDateValid() && !initialParams,
    false, // isPrivileged
    1,
    {
      collectionItemProperty: 'id',
      whereFilterOperator: '==',
      compareValue: combinedDateId,
    },
    // TODO: check for orderCount < capacity
    // calc diff for booking of multiple tickets
  );

  console.log('fetchResults: ', fetchResults);
  // NOTE: THIS property doesnt exist anymore
  // const eDate = fetchResults?.[0]?.eventDate as FirebaseTimestamp;
  // const date2 = dayjs.unix(parseInt(eDate?.seconds?.toString(), 10) || 0);
  // console.log('fetchResultsDate: ', date2.toDate());

  const error = null;

  React.useEffect(() => {
    if (
      !initialParams &&
      fetchResults?.[0] &&
      fetchResults?.[0].id === combinedDateId
      // JSON.stringify(fetchResult) !== JSON.stringify(fetchedProductDateDocument)
    ) {
      setFetchedEventDocument(fetchResults[0] as DaytimeEventDocument); // WTF type casting - why?
    }
  }, [initialParams, combinedDateId, fetchResults, fetchedEventDocument]);

  React.useEffect(() => {
    if (triggerUpdate) {
      setTriggerUpdate(false);
    }
    if (!initialParams && triggerUpdate && !hasInitialRequest) {
      setHasInitialRequest(true);
    }
  }, [initialParams, triggerUpdate, hasInitialRequest]); // if isFetching is left away the component has auto update

  React.useEffect(()=>{
  const selectedPriceBasedFeatureEnable = getBasePriceByFeatureEnabled(selectedPriceSystem)
  let bkSystemType= selectedPriceBasedFeatureEnable?.priceType || 'publicBooking'
  setPriceGroupTickets(
    initialParams
      ? initialParams.priceGroupTickets
      : bkSystemType === BookingSystemType.PUBLIC
      ? [
          ...product.priceSystems[initPriceSystemId].publicBooking.groupDiscounts.map(
            (pgd) =>
              createInitialPriceGroupTickets(
                pgd,
                product.priceSystems[initPriceSystemId].id,
              ),
          ),
        ]
      : [
        createInitialPrivatePriceGroupTickets(
          privatePriceGroupTickets,
          product.priceSystems[initPriceSystemId].id,
          selectedPriceSystem
        )
      ],
  )
  setBkSystemType(bkSystemType)

  },[priceSystemId ,initSystemId])

  if (!product?.id) {
    return null;
  }

  const isCheckoutDisabled = () => Boolean(!fetchedEventDocument);

  const cartSystemType = user?.uid ? CartSystemType.CLOUD : CartSystemType.LOCAL_STORAGE;

  const onAddItemToCart = async (cartSystem: CartSystemType) => {
    if (
      (bookingSystemType == BookingSystemType.PUBLIC && !priceGroupTickets?.length) ||
      !organisationId ||
      // !user?.uid ||
      !isDateValid() ||
      !combinedDateId
    ) {
      return;
    }
    const hasTickets = !!priceGroupTickets?.filter((pgt) => pgt.count).length;
    if (!hasTickets) {
      createStackedNotification(NotificationType.INFO, 'No items selected');
      return;
    }

    const eventDocRef = doc(
      collection(getFirestore(firebase), `product/${product.id}/daytimeEvent`),
      combinedDateId,
    );

    const eventDocSnap = await getDoc(eventDocRef);

    if (!eventDocSnap.exists || !eventDocSnap.data()) {
      createStackedNotification(NotificationType.ERROR, 'This event is not available');
      return;
    }

    const eventDoc = eventDocSnap.data() as DaytimeEventDocument;
    // FIXME: check eventDoc.status => should provide info is this event is already groupBooked
    // FIXME: differentia public/private booking (bookingSystemType)

    const eventTicketsAvailable = getEventTicketOrderLimit(
      eventDoc,
      bookingSystemType, // FIXME: provide correct bookingSystemType
    );

    if (!eventTicketsAvailable || eventTicketsAvailable <= 0) {
      createStackedNotification(
        NotificationType.ERROR,
        'This event is no more available',
      );
      return;
    }

    const totalParticipantsCount = getCartItemsTotalParticipantCount(priceGroupTickets);

    console.log('totalParticipantsCount: ', totalParticipantsCount);

    if (eventTicketsAvailable - totalParticipantsCount < 0) {
      createStackedNotification(
        NotificationType.ERROR,
        `Too many tickets - currently available: ${eventTicketsAvailable}`,
      );
      return;
    }

    const orderMinQuantity =
      (eventDoc.priceSystem.publicBooking.featureEnabled
        ? eventDoc.priceSystem.publicBooking.orderMinQty
        : eventDoc.priceSystem.privateBooking.orderMinQty) || 0;
    const notEnoughtTickets = totalParticipantsCount < orderMinQuantity;

    // Check if minimum is satisfied !!!
    if (notEnoughtTickets) {
      createStackedNotification(
        NotificationType.ERROR,
        `Minimum required ticket amount is ${orderMinQuantity}`,
      );
      return;
    }

    // const eventDocData = eventDocSnap.data();
    // console.log('Found event data: ', eventDocData);

    if (cartSystem === CartSystemType.LOCAL_STORAGE) {
      const randUid = doc(collection(getFirestore(firebase), 'dummy')).id;

      // NOTE: priceSystemId should remain same in the itemConfigurations (=> eventDoc has 1 priceSystem)
      let priceSystemId: string | null = null;

      // AKA ShoppingCartPosition
      const data: ShoppingCartItemDocumentData = {
        bookingSystemType,
        id: randUid,
        // shoppingCartName/ID?
        // save precise query date values additionally?
        // public/private reservations
        itemConfigurations: [
          // this are the priceGroups
          ...priceGroupTickets.map((ticket) => {
            const participants: ProductEventParticipant[] = [];

            for (let index = 0; index < ticket.count; index++) {
              participants[index] = {
                id: doc(collection(getFirestore(firebase), 'dummy')).id, // create fb id
                dob: null,
                firstName: null,
                lastName: null,
                options: {
                  pickup: null,
                  food: null,
                },
              };
              // if there are already some participant settings defined
              if (ticket.participants[index]) {
                participants[index] = {
                  ...participants[index],
                  ...ticket.participants[index], // maybe a deep merge is necessary here?
                };
              }
              // NOTE: priceSystemId should remain same in the itemConfigurations (=> eventDoc has 1 priceSystem)
              if (!priceSystemId) {
                priceSystemId = ticket.priceSystemId;
              } else {
                priceSystemId =
                  priceSystemId && priceSystemId === ticket.priceSystemId
                    ? ticket.priceSystemId
                    : null;
              }
            }
            return {
              priceGroupId: ticket.priceGroupId,
              count: ticket.count,
              priceSystemId: ticket.priceSystemId,
              participants,
            };
          }),
        ],
        priceSystemId,
        productId: id,
        eventDate: Timestamp.fromDate(tzDate.toDate()),
        meta: {
          categoryIds: [...categoryIds],
          categoryTypeIds: [...categoryTypeIds],
          createdAt: Timestamp.now(),
          documentType: '__user_cartItem',
          organisationId,
          status: '__draft', // TODO => DocumentState not ProductDocument state
          systemId: 'holiday', // TODO
          systemIds: ['holiday'], // TODO
          tagIds: [...tagIds],
          v: '1',
        },
        groupQuantityDiscount :getGroupQuantityDiscounts( priceSystems, priceSystemId)
      };
      console.log('submit local storage cart data: ', data);

      try {
        // const docData = createBaseDocumentCreatedBy(user.uid, cartItemDocRef.id, data);
        // await setDoc(cartItemDocRef, docData);

        if (typeof window === 'undefined') {
          return;
        }

        const currentLocalStorageCartString =
          typeof window !== 'undefined'
            ? window.localStorage.getItem(LocalStorageKey.SHOPPING_CART) || '{"cart":[]}'
            : '';

        const currentLocalStorageCart: LocalStorageCart = JSON.parse(
          currentLocalStorageCartString,
        );

        if (
          !currentLocalStorageCart?.cart &&
          !Array.isArray(currentLocalStorageCart.cart)
        ) {
          console.log('>>> currentLocalStorageCart: ', currentLocalStorageCart);
          return;
        }
        currentLocalStorageCart.cart.push(data);

        window.localStorage.setItem(
          LocalStorageKey.SHOPPING_CART,
          JSON.stringify(currentLocalStorageCart),
        );

        createNotification(
          NotificationType.INFO,
          <AddToCartConfirmation itemConfigurations={data.itemConfigurations} />,
          10000,
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.warn('err: ', err);
        createStackedNotification(
          NotificationType.ERROR,
          `Got error on adding item to local cart: ${err?.message || ''}`,
        );
      }
    } else if (cartSystem === CartSystemType.CLOUD) {
      if (!user?.uid) {
        return; // should not happen in case of passing correct CartSystemType
      }

      const cartItemDocRef = doc(
        collection(getFirestore(firebase), `user/${user.uid}/cart`),
      );

      // NOTE: priceSystemId should remain same in the itemConfigurations (=> eventDoc has 1 priceSystem)
      let priceSystemId: string | null = null;

      // AKA ShoppingCartPosition
      const data: ShoppingCartItemDocumentData = {
        bookingSystemType,
        id: cartItemDocRef.id,
        // shoppingCartName/ID?
        // save precise query date values additionally?
        // public/private reservations
        itemConfigurations: [
          // this are the priceGroups
          ...priceGroupTickets.map((ticket) => {
            const participants: ProductEventParticipant[] = [];

            for (let index = 0; index < ticket.count; index++) {
              participants[index] = {
                id: doc(collection(getFirestore(firebase), 'dummy')).id, // create fb id
                dob: null,
                firstName: null,
                lastName: null,
                options: {
                  pickup: null,
                  food: null,
                },
              };
              // if there are already some participant settings defined
              if (ticket.participants[index]) {
                participants[index] = {
                  ...participants[index],
                  ...ticket.participants[index], // maybe a deep merge is necessary here?
                };
              }
              // NOTE: priceSystemId should remain same in the itemConfigurations (=> eventDoc has 1 priceSystem)
              if (!priceSystemId) {
                priceSystemId = ticket.priceSystemId;
              } else {
                priceSystemId =
                  priceSystemId && priceSystemId === ticket.priceSystemId
                    ? ticket.priceSystemId
                    : null;
              }
            }
            return {
              priceGroupId: ticket.priceGroupId,
              count: ticket.count,
              priceSystemId: ticket.priceSystemId,
              participants,
            };
          }),
        ],
        priceSystemId,
        productId: id,
        eventDate: Timestamp.fromDate(tzDate.toDate()),
        meta: {
          categoryIds: [...categoryIds],
          categoryTypeIds: [...categoryTypeIds],
          createdAt: Timestamp.now(),
          documentType: '__user_cartItem',
          organisationId,
          status: '__draft', // TODO => DocumentState not ProductDocument state
          systemId: 'holiday', // TODO
          systemIds: ['holiday'], // TODO
          tagIds: [...tagIds],
          v: '1',
        },
        groupQuantityDiscount :getGroupQuantityDiscounts( priceSystems, priceSystemId)

      };
      console.log('submit cloud cart data: ', data);

      try {
        const docData = createBaseDocumentCreatedBy(user.uid, cartItemDocRef.id, data);
        await setDoc(cartItemDocRef, docData);

        createNotification(
          NotificationType.INFO,
          <AddToCartConfirmation itemConfigurations={data.itemConfigurations} />,
          10000,
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.warn('err: ', err);
        createNotification(
          NotificationType.ERROR,
          `Got error on adding item to online cart: ${err?.message || ''}`,
        );
      }
    }
  };

  const reset = () => {
    setIsPriceOptionsOpen(false);
    setFetchedEventDocument(null);
    // setPriceGroupTickets([]); // dont need to do that => priceGroupDiscounts stay the same inside a single priceSystem
  };

  const onSetDate = (muiDate: MaterialUiPickersDate) => {
    if (muiDate) {
      setDate(muiDate);
    }
    reset();
  };

  const onCheckAvailability = () => {
    if (date) {
      setTriggerUpdate(true);
    }
    reset();
  };

  const onDayTimeSelect = (time: string) => {
    if (time) {
      setDayTime(getDayTimeFromTimeString(time));
      const dtEvent = daytimeEvents.find((de) => de.startTime === time);
      if (dtEvent) {
        const priceSystemIndex = priceSystems.findIndex(
          (ps) => ps.id === dtEvent.priceSystemId,
        );
        const bkType = getBasePriceByFeatureEnabled(priceSystems[priceSystemIndex])?.priceType || 'publicBooking'
        setBkSystemType(bkType)
        setPriceGroupTickets(
          bkType === BookingSystemType.PUBLIC ? 
          [
          ...product.priceSystems[priceSystemIndex].publicBooking.groupDiscounts.map(
            (pgd) =>
              createInitialPriceGroupTickets(
                pgd,
                product.priceSystems[priceSystemIndex].id,
              ),
          ),
        ]:
        [
          createInitialPrivatePriceGroupTickets(
            privatePriceGroupTickets,
            product.priceSystems[priceSystemIndex].id,
            product.priceSystems[priceSystemIndex]
          )
        ]
        );
      }
    }
    reset();
  };

  let totalPrice: Price = {
    integer: 0,
    decimal: 0,
  };

  // FIXME: reset doesn't work properly on the add to cart button...

  const isProductNotAvailable = () =>
    !!(
      (error && error === 'Missing or insufficient permissions.') ||
      !fetchedEventDocument
    ); // TODO

  const allGroupPositionsPrices: Price[] =
    priceGroupTickets?.map((pgt) => {
      return calculatePriceGroupTotalPrice(pgt, priceSystems, bookingSystemType);
    }) || [];
    const productBasePrice = calculatePriceFromPriceString(getProductPriceSystemBasePrice(
      product,
      initSystemId,
      bookingSystemType,
    )?.toString() || 0);
   totalPrice = calculateTotalPrice(allGroupPositionsPrices);

  // console.log(`fetchedProductDateDocument id ${combinedDateId}: `, fetchedEventDocument);

  // console.group('- ProductAddToCartContainer -');
  // console.log('date: ', date);
  // console.log('dayTime: ', dayTime);
  // console.log('isCheckoutDisabled: ', isCheckoutDisabled());
  // console.log('isPriceOptionsOpen: ', isPriceOptionsOpen);
  // console.log('isProductNotAvailable: ', isProductNotAvailable());
  // console.log('priceGroupTickets: ', priceGroupTickets);
  // console.log('product: ', product);
  // console.log('totalPrice: ', totalPrice);
  // console.groupEnd();

  const ticketOrderLimit = fetchedEventDocument
    ? getEventTicketOrderLimit(fetchedEventDocument, bookingSystemType) // FIXME bookingtype
    : 0;

  // console.log('ProductAddToCartContainer ticketOrderLimit: ', ticketOrderLimit);
  // console.log('ProductAddToCartContainer priceGroupTickets: ', priceGroupTickets);

  console.log('>>>>>>>>> bookingSystemType >>>>>>>>>> ', bookingSystemType);


  //new code
  let finalPrice =totalPrice;
  const availableGroupQuantityDiscounts =   getGroupQuantityDiscounts( priceSystems, priceSystemId);
  const totalParticipantsCount = getCartItemsTotalParticipantCount(priceGroupTickets);
  const groupQuantityDiscount = calculateGroupQuantityDiscountPrice(availableGroupQuantityDiscounts,totalParticipantsCount,bookingSystemType)
  
  console.log("groupQuantityDiscount ProductAddToCartControls....", groupQuantityDiscount)
  let discountAmount =0;
  if(groupQuantityDiscount.discountType!=null){
    let discountType = groupQuantityDiscount.discountType;
    if(discountType=="fixed"){
       discountAmount = groupQuantityDiscount.discountAmount;
    console.log("discountAmountnnnn",discountAmount)
      }else{
      let discountPercentage = groupQuantityDiscount.discountPercentage;
       discountAmount = convertPriceToFloat(totalPrice)*discountPercentage/100;
    }
    finalPrice = calculatePriceFromPriceString(discountAmount >0 ? convertPriceToFloat(totalPrice)-discountAmount : convertPriceToFloat(totalPrice));

  }
  
return product?.priceSystems?.length ? (
    <ProductAddToCartControls
      bookingSystemType={bookingSystemType}
      date={date}
      dayTime={dayTime}
      eventDocument={fetchedEventDocument}
      isCheckoutDisabled={isCheckoutDisabled}
      isExpanded={isPriceOptionsOpen || !!fetchedEventDocument}
      isLoading={triggerUpdate}
      isProductAvailable={!isProductNotAvailable()}
      onAddItemToCart={() => onAddItemToCart(cartSystemType)}
      onCheckAvailability={onCheckAvailability}
      onDayTimeSelect={onDayTimeSelect}
      priceGroupTickets={priceGroupTickets}
      priceSystemId={priceSystemId}
      product={product}
      setDate={onSetDate}
      setIsExpanded={setIsPriceOptionsOpen}
      setPriceGroupTickets={(tickets) => {
        console.log('setPriceGroupTickets tickets: ', tickets);
        setPriceGroupTickets(tickets);
      }}
      // TODO: bookingSystemType
      totalPrice={finalPrice}
      hasInitialRequest={hasInitialRequest}
      pickupOptionId={pickupOptionId}
      ticketOrderLimit={ticketOrderLimit}
    />
  ) : null;
};
