// GENERAL ACTION HOOKS (COMPOSED)
import {
  useSetDocumentWithImageUpload,
  UseSetDocumentWithImageUpload,
} from './actions/useSetDocumentWithImageUpload';

import { useSetDocument, UseSetDocument } from './actions/useSetDocument';
import { useGetDocument, UseGetDocument } from './actions/useGetDocument';

import {
  useDeleteDocumentWithStorageFiles,
  UseDeleteDocumentWithStorageFiles,
} from './actions/useDeleteDocumentWithStorageFiles';

import { useDeleteDocument, UseDeleteDocument } from './actions/useDeleteDocument';

// STORAGE HOOKS
import { useUploadFile, UseUploadFile } from './storage/useStorageUploadFile';
import { useUploadFiles, UseUploadFiles } from './storage/useStorageUploadFiles';
import { useDeleteFile, UseDeleteFile } from './storage/useStorageDeleteFile';

// FUNCTION HOOKS?

// ACTIONS
export interface UseStoreActions {
  useDeleteDocument: () => UseDeleteDocument;
  useDeleteDocumentWithStorageFiles: () => UseDeleteDocumentWithStorageFiles;
  useGetDocument: (
    collectionName: string,
    documentId: string,
    isPrivilegedCollection: boolean,
  ) => UseGetDocument;
  useSetDocument: () => UseSetDocument;
  useSetDocumentWithImageUpload: () => UseSetDocumentWithImageUpload;
}

export function useStoreActions(): UseStoreActions {
  return {
    useDeleteDocument,
    useDeleteDocumentWithStorageFiles,
    useGetDocument,
    useSetDocument,
    useSetDocumentWithImageUpload,
  };
}

// STORAGE

interface UseFireBaseStorage {
  useUploadFile: (
    successNotification: string | React.ReactNode,
    errorNotification: string | React.ReactNode,
  ) => UseUploadFile;
  useUploadFiles: (
    successNotification: string | React.ReactNode,
    errorNotification: string | React.ReactNode,
  ) => UseUploadFiles;
  useDeleteFile: (
    successNotification: string | React.ReactNode,
    errorNotification: string | React.ReactNode,
  ) => UseDeleteFile;
}

export function useFirebaseStorage(): UseFireBaseStorage {
  return { useUploadFile, useUploadFiles, useDeleteFile };
}

// BASE NAMESPACE

// THIS WILL BE MAINTAINED IN THE FUTURE => DROP SHOPHOOKS... step by step
interface UseFirebase {
  useFirebaseStorage: () => UseFireBaseStorage;
  useStoreActions: () => UseStoreActions;
}

export function useFirebase(): UseFirebase {
  return { useFirebaseStorage, useStoreActions };
}
