
import React, { useEffect, useState } from 'react';


import { SearchBox } from '../components';
import { convertToNewDate } from '../helpers/helpers';
import { TableListProps } from '../types/DataTableComponentProps';
import { HeadingObject } from '../types/tableTypes';
import { TableDatePicker,TableTimePicker } from '../components';
import TableDateSearch from './TableDateSearch';
import utc from 'dayjs/plugin/utc';
import { ArrowDownward,ArrowUpward  } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import {
  Button,
  Divider,
  Grid,
  GlassPaper,
  Typography,
  LoadingIndicator,
  DebugObject,
  StringPropertyRow,
  LocalizedLink,
  ButtonTw,
  Localized, Paper,
  LocalizedErrorIndicator
} from 'src/app/components';
import { useAppContext, useAppStaticQueryConfig, useConfirmAction, useSourcedProducts } from 'src/app/hooks';
import { navigate } from 'gatsby';

import { DialogType, NotificationType } from 'src/app/constants';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { FirestoreDocData } from 'src/app/container';
import { DaytimeEventDocument } from 'src/app/types';
import { parseSkuString } from 'src/lib/event';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';
import { onOpenInNewWindow } from 'src/app/lib';
import { Timestamp, collection, doc, getDoc, getFirestore } from 'firebase/firestore';
import { formatFirebaseTimestamp } from 'src/lib/date';

export const TableList: React.FC<TableListProps> = ({
  tableData,
  tableHead,
  detailUrl,
  handleSetOrder,
  orderbyVal,
  orderbyField,
  handleSearch,
  searchList,
  setSearchList,
  onEditProfileClick,
  onOpenMessage,
  onUpdateProductCategoryTypes,
  onEnableOrganisationOperations

}) => {
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const { currentOrganisation ,firebase,createNotification} = useAppContext();
  const { env } = useAppStaticQueryConfig();
  interface DaytimeEventCancelFunctionParams {
    organisationId: string;
    productId: string;
    eventId: string;

  }


  useEffect(() => {
    if (Object.keys(searchList).length > 0) {
      setStartDate(new Date(searchList.createdDate))
      setEndDate(new Date(searchList.createdTill))
    }
  }, [])



  const { confirmAction } = useConfirmAction();
  const handleCancel = () => {
    handleSearch('createdDate', '');
    setStartDate('');
    setEndDate('');
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // handleSearch(name, value);
    console.log(name, value);
    console.log(searchList, 'searchlist')

    if (value.trim() == '') {
      // delete the search key when value is empty
      delete searchList[name];
      setSearchList({ ...searchList });
    } else {
      const field = { [name]: value };
      setSearchList({ ...searchList, ...field });
    }
  };
  // const handleDateChange = (e: any,fieldName:string) => {debugger
  //   const { name, value } = e.target;
  //   // handleSearch(name, value);
  //   // console.log(name, value);
  //   console.log(searchList,value, 'searchhjhjhjlist')

  //   if (value.trim() == '') {
  //     // delete the search key when value is empty
  //     delete searchList[fieldName];
  //     setSearchList({ ...searchList });
  //   } else {
  //     const field = { [fieldName]: value };
  //     setSearchList({ ...searchList, ...field });
  //   }
   
  // };
  const handleDateChange = (date: any, fieldDetails: any) => {
    console.log(date.target.value);
    console.log(searchList, 'searchlrtrtist')
    const fieldName=fieldDetails.dataName
    const {fieldType}=fieldDetails
    if (date.target.value !== '') {
      const givenDateTime = new Date(date.target.value);
      console.log(givenDateTime);
      const formattedDate =fieldType=="date"? `${(givenDateTime.getMonth() + 1).toString().padStart(2, '0')}/${givenDateTime.getDate().toString().padStart(2, '0')}/${givenDateTime.getFullYear()}`:date.target.value

      setSearchList({ ...searchList, [fieldName]: formattedDate });
      handleSearch(fieldName, formattedDate);
    } else {
      handleSearch(fieldName, '');
    }
  };

  const handleTimeChange = (time: any, fieldName: string) => {
    console.log(time.target.value);
    console.log(searchList, 'searchlrtrtist')

    if (time.target.value !== '') {
      setSearchList({ ...searchList, [fieldName]: time.target.value });
      handleSearch(fieldName, time.target.value);
    } else {
      handleSearch(fieldName, '');
    }
  };

  console.log(tableData, tableHead, "table headdhhhhh")
  // const handleDateChange = (date: any, fieldName: string) => {
  //   console.log(date.target.value);
  //   if (date.target.value !== '') {
  //     const givenDateTime = new Date(date.target.value);
  //     console.log(givenDateTime);

  //     const formattedDate = `${(givenDateTime.getMonth() + 1).toString().padStart(2, '0')}/${givenDateTime.getDate().toString().padStart(2, '0')}/${givenDateTime.getFullYear()}`;

  //     setSearchList({ ...searchList, [fieldName]: formattedDate });
  //     handleSearch(fieldName, formattedDate);
  //   } else {
  //     handleSearch(fieldName, '');
  //   }
  // };
  const sourcedProducts = useSourcedProducts();

  const { openDialog } = useAppContext();
  const onOpenQuestionAboutProductDialog = (product: any) => {
    openDialog<any>(DialogType.QUESTION_ABOUT_PRODUCT, {
      product,
    });
  };
  const getEventTimeFromIsoString = (isoDateString: string, timeZone: string = 'Europe/Berlin') => {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    return dayjs(new Date(isoDateString)).tz(timeZone).format('HH:mm');
  };
  const onOpenOrder = async (item: any) => {
    const orderDocRef = doc(
      collection(getFirestore(firebase), `order`),
      item.OrderId,
    );
    const orderDocSnap = await getDoc(orderDocRef);
    const order:any=orderDocSnap.data();
    let orderItem: any = null;
    if (order.orderData?.purchase_units) {
      const purchaseItems = order.orderData.purchase_units[0].items;
      order.orderData.purchase_units[0].items.forEach((val: any) => {
        val.orderId = order.id;
        val.payer = order.successDetails.payer;
        val.productItems = [];
        let sameOrder: any = purchaseItems.filter((el) => el.sku == val.sku);
        let grossamount: number = 0;
        val.participantCount = 0;
        val.productItems = sameOrder;
        if (sameOrder.length > 1) {
          val.productItems.forEach(item => {
            grossamount += (item.unit_amount.value * item.quantity)
            val.participantCount += item.quantity;
          });
        } else {
          grossamount = val.unit_amount.value * val.quantity;
          val.participantCount = val.productItems[0].quantity;
        }
        console.log('participants', val.participantCount);
        val.grossAmount = grossamount;
        const parsedSku = parseSkuString(val.sku);
        if (!parsedSku) {
          return null;
        }
        const { productId, eventDateIsoStringId } = parsedSku;
        val.product = order.orderCart.items.filter((el) => el.productId == productId)[0];
        if (val.product.bookingSystemType == 'privateBooking') {
          val.participantCount = val.product.itemConfigurations[0].count;
        }
        console.log(getEventTimeFromIsoString(eventDateIsoStringId));
        val.sourcedProduct = sourcedProducts.find((sp) => sp.id === productId);
        val.productId = productId;
        val.eDate = getEventIdFromIsoString(eventDateIsoStringId)
        val.createdAt = order.successDetails.create_time;
        let create_time = Timestamp.fromDate(new Date(order.successDetails.create_time));
        val.create_time = formatFirebaseTimestamp(create_time);
        val.etime = getEventTimeFromIsoString(eventDateIsoStringId);
        // let duplicates: any = orderItem.filter((el) => el.orderId == val.orderId && el.productId == val.productId && el.eDate == val.eDate && el.etime == val.etime);
        // if (duplicates.length == 0) {
          orderItem=val;
        // }
      });
    }
   
    openDialog<any>(DialogType.TRANSACTION_DETAIL, {
      item,
      orderItem
    });
  };
  const getEventIdFromIsoString = (isoDateString: any, timeZone: string = 'Europe/Berlin') => {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    return dayjs(new Date(isoDateString)).tz(timeZone).format('YYYY-MM-DD-HH-mm-0000');
  };
  const onCancelEvent = async (productId: any, id: any) => {
    if (!firebase || !id || !productId || !currentOrganisation?.id) {
      return;
    }
    try {
      const daytimeEventCancel = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'organisationFunction-daytimeEventCancel',
      );
      const params: DaytimeEventCancelFunctionParams = {
        organisationId: currentOrganisation.id,
        productId,
        eventId: id,
      };
      await daytimeEventCancel(params);
      createNotification(
        NotificationType.SUCCESS,
        `The event has been cancelled`,
        5000,
      );
    } catch (err) {
      console.log('err: ', err);
    } finally {

    }
  };

  // const handleDateChange = (date: any, fieldName: string) => {
  //   // console.log(startDate, endDate, 'handleDateChange')
  //   if (startDate === '' && endDate === '') {
  //     handleSearch('createdDate', '');
  //     return;
  //   } else {
  //     let givenStartDate = new Date();
  //     let givenEndDate = new Date();

  //     if (startDate != '') {
  //       // console.log(startDate);
  //       givenStartDate = new Date(startDate);
  //     }

  //     if (endDate != '') {
  //       // console.log(endDate);
  //       givenEndDate = new Date(endDate);
  //     }

  //     // console.log(startDate, 'startdatein');

  //     const formattedStartDate = `${(givenStartDate.getMonth() + 1).toString().padStart(2, '0')}/${givenStartDate.getDate().toString().padStart(2, '0')}/${givenStartDate.getFullYear()}`;
  //     const formattedEndDate = `${(givenEndDate.getMonth() + 1).toString().padStart(2, '0')}/${givenEndDate.getDate().toString().padStart(2, '0')}/${givenEndDate.getFullYear()}`;
  //     setSearchList({
  //       ...searchList,
  //       createdDate: formattedStartDate,
  //       createdTill: formattedEndDate,
  //     });
  //     const dateDetails = {
  //       createdDate: formattedStartDate,
  //       createdTill: formattedEndDate,
  //     };

  //     handleSearch('createdDate', dateDetails);
  //   }
  // };

  const callAction = async (actionName: any, data: any) => {
    if (actionName === "contactSupplier") {
      const sourcedProduct = sourcedProducts.find((sp) => sp.id === data.productId);
      onOpenQuestionAboutProductDialog(sourcedProduct)

    } else if (actionName === "viewBookingDetails") {
      onOpenOrder(data)
    } else if (actionName === "viewProfle") {
      const orgDocRef = doc(
        collection(getFirestore(firebase), `organisation`),
        data.id,
      );
      const  orgDocSnap = await getDoc(orgDocRef);
      localStorage.Organization=JSON.stringify(orgDocSnap.data());
      onOpenInNewWindow(
        (`/admin/partner/signup/company-information`),
      );
    } else if (actionName === "ViewDocuments") {
      onOpenMessage(data.id)
    } else if (actionName === "setCategories") {
      onUpdateProductCategoryTypes(data.id)

    } else if (actionName === "viewBookingDetailsSupplier") {
      navigate(`${data.OrderId}`)
    }
    else if (actionName === "changeEventParamater") {
      navigate(`${data.OrderId}`)
    }
  }


  return (
    <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
      <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
        <thead className='text-lg text-gray-400 min-w-100 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
          <tr>
            {tableHead.map((item: any, key: any) => (
              <th key={key} scope='col' className='px-4 py-3 min-w-100' style={{ whiteSpace: 'nowrap' }}>
                {item.name !== 'Action' ? (
                  <span className='flex items-center justify-between'>
                    {item.name} 
                    {item.allowOrder ? (
                      orderbyField === item.filterField &&
                        orderbyVal === 'desc' ? (
                          <ArrowDownward
                          className='hover:text-gray-500 cursor-pointer'
                          width={17}
                          onClick={() => handleSetOrder(item.filterField)}
                        />
                      ) : (
                        
                        <ArrowUpward
                        className='hover:text-gray-500 cursor-pointer'
                        width={17}
                        onClick={() => handleSetOrder(item.filterField)}
                      />
                       
                      )
                    ) : (
                      ''
                    )}
                  </span>
                ) : (
                  <span className='flex items-center justify-center '>
                    {item.name}
                  </span>
                )}
                {item.isSearchable && (
                  <>
                    {item.fieldType == 'text' ||item.fieldType == 'number' ||item.fieldType == 'int' ? (
                      <SearchBox
                        placeholder='Search'
                        name={item.dataName}
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        searchList={searchList}
                      />
                    ) : (item.fieldType == 'date'|| item.fieldType=='dateString') ? (
                      <>
                      <TableDatePicker searchList={searchList}                         
                       handleSearch={handleSearch}
                        handleDateChange={handleDateChange} 
                        fieldDetails={item}/>
                      </>
                    ) :item.fieldType=="time"?(

                      <>
                      <TableTimePicker searchList={searchList}                         
                       handleSearch={handleSearch}
                        handleTimeChange={handleTimeChange} 
                        fieldDetails={item}/>
                      </>
                    ): (
                      ''
                    )}
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.length == 0 && (
            <tr className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700'>
              <td className='px-4 py-4  flex'>No data found</td>
            </tr>
          )}
          {tableData.map((item: any, key: number) => (
            <tr
              key={key}
              className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700'
            >
              {tableHead.map((head: HeadingObject, key: number) => (
                <>
                  {head.isMain ? (
                    <th
                      scope='row'
                      className='px-4 py-4 font-medium'
                      key={key}
                    >
                      {item[head.dataName]}
                      {item.isNew && (
                        <span className='bg-gray-500 text-white px-2 text-xs opacity-85 ml-6  rounded-lg'>
                          New
                        </span>
                      )}
                    </th>
                  ) : (
                    <>
                      {head.fieldType == 'date' ? (
                        <td className='px-4 py-4 text-center' key={key}>
                          {convertToNewDate(item[head.dataName], 'DD/MM/YYYY')}
                        </td>
                      ) : (
                        <>
                          {head.dataName == 'actionButton' ? (
                            <td
                              className='px-4 py-4 capitalize text-center'
                              key={key}
                            >
                              {head.buttonName == 'cancelEvent' ? (
                                <FirestoreDocData<DaytimeEventDocument>
                                  useSubscription
                                  documentPath={`product/${parseSkuString(item.skuId).productId}/daytimeEvent/${getEventIdFromIsoString(parseSkuString(item.skuId).eventDateIsoStringId)}`}
                                  renderElements={({ data, status }) => {
                                    if (status === 'loading') {
                                      return <LoadingIndicator />;
                                    }
                                    if (status === 'error') {
                                      return (
                                        <LocalizedErrorIndicator
                                          i18nContext={'organisation'}
                                          i18nErrorCode={'LOADING_COLLECTION'}
                                          i18nErrorAreaCode={'product/daytimeEvent'}
                                        />
                                      );
                                    }
                                    return status !== 'success' || !data?.id ? null : (

                                      <ButtonTw color={'primary'}
                                      variant={'orderAction'}
                                      rounded
                                      size={'sm'} disabled={data.status === "cancelled"} onClick={() => onCancelEvent(parseSkuString(item.skuId).productId, getEventIdFromIsoString(parseSkuString(item.skuId).eventDateIsoStringId))}>
                                        <Typography>                                        
                                          {data.status == "cancelled" ? "Event Cancelled " : "Cancel Event"}
                                        </Typography>
                                        </ButtonTw>
                                    );
                                  }}
                                />
                              ) : (
                                <ButtonTw
                                 disabled={head.buttonName == 'changeEventParamater'}
                                  onClick={() => callAction(head.buttonName, item)}
                                  color={'primary'}
                                  variant={'orderAction'}
                                  rounded
                                  size={'sm'}
                                >
                                  <Typography>{head.name}</Typography>
                                </ButtonTw>)}

                            </td>
                          ) : head.dataName === "checkBox" ? (
                            <td className='px-4 py-4 text-center' key={key}>
                              <Checkbox
                                disabled={
                                  (item.isOrganisationUnlocked &&
                                    !item.isOrganisationDisabled) ||
                                  !item.isOrganisationDocumentsValid ||
                                  !item.organisationUploadDocuments
                                    ?.organisationInsuranceDocument ||
                                  !item.organisationUploadDocuments
                                    ?.organisationLicenceDocument ||
                                  !item.organisationUploadDocuments
                                    ?.organisationRegistrationDocument
                                }
                                checked={item.isOrganisationUnlocked}
                                onChange={(e: any) =>
                                  confirmAction(
                                    () => onEnableOrganisationOperations(e, item.id),
                                    'Did you check all the necessary organisation data for validity? You confirm this with your userId',
                                  )
                                }
                              />
                            </td>) :head.dataName==="totalAmount"?(
                             <td className='px-4 py-4 text-center' key={key}>
                              {item[head.dataName]} { item["currencyCode"]}
                             </td>
                            ): (
                            <td className='px-4 py-4 text-center' key={key}>
                              {item[head.dataName]}
                            </td>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
