import * as React from 'react';

import { Pagination } from 'src/app/container';
import { Divider, VirtualizedTable } from 'src/app/components';
import { BaseDocumentData, TableRowClickEvent, TableColumn } from 'src/app/types';

export interface DocumentTableContainerProps<T extends BaseDocumentData> {
  data: T[];
  header: TableColumn[];
  i18nBasePath: string;
  onRowClick: (e: TableRowClickEvent) => void;
  selectedRowIndex?: number;
  toolbar?: React.ReactNode;
}

export const DocumentTableContainer = <T extends BaseDocumentData>({
  data,
  header,
  i18nBasePath,
  onRowClick,
  selectedRowIndex,
  toolbar,
}: DocumentTableContainerProps<T>) => {
  return (
    <Pagination<T>
      data={data}
      hideControlsTop
      renderElements={({ data: paginatedData }) => (
        <>
          <VirtualizedTable<T>
            className='w-full my-2'
            data={paginatedData}
            header={header}
            i18nBasePath={i18nBasePath}
            isLocalized
            onRowClick={onRowClick}
            selectedRowIndex={selectedRowIndex}
            toolbar={toolbar}
          />
          <div className='py-2'>
            <Divider />
          </div>
        </>
      )}
    />
  );
};
