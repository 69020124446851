import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { RenderI18nUrlItem } from 'src/app/components';
import { BookingCancellationFormContainer } from 'src/app/container';

import { customerHelpTopicDictKey } from '../customerHelpTopic.dictKey';

const topicKey = 'cancel-booking';
export const CancelBooking = () => {
  const { t } = useTranslation();

  const contentKey = `${customerHelpTopicDictKey}.${topicKey}.content`;
  const contentItems: string[] = t(contentKey, { returnObjects: true });

  const imgUrlsKey = `${customerHelpTopicDictKey}.${topicKey}.imageUrls`;
  const imgUrlItems: string[] = t(imgUrlsKey, { returnObjects: true });

  return (
    <div className='py-2 md:p-4'>
      {contentItems?.length && Array.isArray(contentItems)
        ? contentItems.map((item) => {
            return (
              <p className='mb-2' key={item}>
                {item}
              </p>
            );
          })
        : null}

      <div className='my-4'>
        <BookingCancellationFormContainer />
      </div>

      {imgUrlItems?.length && Array.isArray(imgUrlItems)
        ? imgUrlItems.map((item, idx) => (
            <RenderI18nUrlItem key={idx} prefixedStringFromImgUrls={item} />
          ))
        : null}
    </div>
  );
};
