import * as React from 'react';
import { Divider, Grid, StringPropertyRow, Typography } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';
import { BookingSystemType } from 'src/app/constants';

export const ProductImportantInformation = ({
  bookingType,
  product,
}: {
  bookingType: BookingSystemType;
  product: SourcedGQLProduct;
}) => {
  return (
    <div className='flex flex-col gap-y-4 text-p3 sm:text-p2'>
      <StringPropertyRow
        title={<p>{'Duration:'}</p>}
        value={
          <p className='text-right'>{product.availability.duration || ''}</p>
        }
      />
      <StringPropertyRow
        title={<p>{'Be there:'}</p>}
        value={
          <p className='text-right'>{product.location.timeDelta || ''}</p>
        }
      />
      <StringPropertyRow
        title={<p>{'BookingType:'}</p>}
        value={<p className='text-right'>{bookingType}</p>}
      />
    </div>
  );
};
