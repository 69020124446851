import { graphql, useStaticQuery } from 'gatsby';
import { SourcedGQLProduct } from 'src/app/types';
import { ProductCategoryType } from 'src/app/constants';

interface ProductsQuery<T extends ProductCategoryType> {
  allProduct: {
    nodes: SourcedGQLProduct[];
  };
}

// TODO: zipcode + availability
const allProductQuery = graphql`
  {
    allProduct {
      nodes {
        assembly {
          baseLocation {
            cityAreaId
            cityId
            continentId
            countryId
            countryAreaId
            streetName
            streetNumber
            timeDelta
            w3w
          }
          baseLocationIsActivityLocation
        }
        availability {
          daytimeEvents {
            languageIds
            priceSystemId
            startTime
          }
          duration
          type
          unavailabilityPeriods {
            fromDate
            toDate
          }
          weekdays
        }
        id
        foodOptions {
          featureEnabled
          options {
            localizations {
              locales {
                additionalInformation
                name
              }
              localeId
            }
          }
        }
        imageFiles {
          childImageSharp {
            fixed(width: 1230, height: 800, quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1230, maxHeight: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        imageUrls {
          localizations {
            localeId
            locales {
              description
              name
            }
          }
          order
        }
        localizations {
          food {
            additionalInformation
            id
            title
          }
          localeId
          locales {
            additionalInformation
            description
            name
            shortDescription
            teaser
          }
          media {
            youtube {
              embedId
              title
            }
          }
          product {
            covidSafetyMeasures {
              additionalInformation
              type
              title
            }
            covidTravelerRequirements {
              additionalInformation
              type
              title
            }

            importantInformationBeforeYouGo {
              additionalInformation
              type
              title
            }
            importantInformationNotAllowed {
              additionalInformation
              type
              title
            }
            importantInformationWhatToBring {
              additionalInformation
              type
              title
            }
            notSuitableFor {
              additionalInformation
              type
              title
            }
            productExcludes {
              additionalInformation
              type
              title
            }
            productFeatures {
              additionalInformation
              type
              title
            }
            productIncludes {
              additionalInformation
              type
              title
            }
            productInformation {
              additionalInformation
              type
              title
            }
          }
          seo {
            description
            title
          }
          timeline {
            additionalInformation
            time
            title
            type
          }
        }
        location {
          cityAreaId
          cityId
          continentId
          countryId
          countryAreaId
          streetName
          streetNumber
          timeDelta
          w3w
        }
        meta {
          categoryIds
          categoryTypeIds
          organisationId
          tagIds
        }
        pickup {
          customLocations {
            basePrice
            cityId
            localizations {
              localeId
              locales {
                additionalInformation
                description
                name
              }
            }
            radiusKM
            timeDelta
            # w3w TODO
          }
          hasCustomLocationPickupService
          hasPickupService
          locations {
            basePrice
            cityAreaId
            cityId
            continentId
            countryId
            countryAreaId
            localizations {
              localeId
              locales {
                additionalInformation
                description
                name
              }
            }
            streetName
            streetNumber
            timeDelta
            w3w
          }
        }
        priceSystems {
          id
          name
          privateBooking {
            basePrice
            featureEnabled
            groupDiscounts {
              discountFixed
              discountPercentage
              discountType
              isEnabled
              id
            }
            hasGroupDiscount
            hasQuantityDiscount
            maxParticipants
            minParticipants
            isParticipantInfoNecessary
            orderMinQty
            quantityDiscounts {
              discountFixed
              discountMinQuantity
              discountPercentage
              discountType
              isEnabled
              id
            }
          }
          publicBooking {
            basePrice
            featureEnabled
            groupDiscounts {
              discountFixed
              discountPercentage
              discountType
              isEnabled
              id
            }
            hasGroupDiscount
            hasQuantityDiscount
            isParticipantInfoNecessary
            maxParticipants
            orderMinQty
            quantityDiscounts {
              discountFixed
              discountMinQuantity
              discountPercentage
              discountType
              isEnabled
              id
            }
          }
        }
        productData {
          rating
          reviewsCount
        }
      }
    }
  }
`;

export function useSourcedProducts<T extends ProductCategoryType>(): SourcedGQLProduct[] {
  const { allProduct } = useStaticQuery<ProductsQuery<T>>(allProductQuery);
  return allProduct?.nodes || [];
}
