import { graphql, useStaticQuery } from 'gatsby';
import { SourcedGQLLocale } from 'src/app/types';

interface LocalesQuery {
  allLocale: {
    nodes: SourcedGQLLocale[];
  };
}

// TODO: not used or implemented (locales are atm defined by FE i18n)
export function useSourcedLocales(): SourcedGQLLocale[] {
  const { allLocale } = useStaticQuery<LocalesQuery>(graphql`
    {
      allLocale {
        nodes {
          id
          localizations {
            localeId
            locales {
              description
              name
            }
          }
        }
      }
    }
  `);
  const availableLocales = allLocale.nodes.map((localeNode) => ({
    ...localeNode,
  }));
  return [...availableLocales];
}
