import * as React from 'react';

import { Grid, Typography, Localized } from 'src/app/components';
import { ShoppingCartItemConfiguration, SourcedGQLProduct } from 'src/app/types';

import { createPriceStringWithCurrency, euroPriceAsCurrency } from 'src/lib/price';

import { useAppContext } from 'src/app/context';
import { PickupOptionType } from 'src/app/constants';
import {
  calculatePickupFeeFromPickupLocationInformation,
  parsePickupOptionId,
  getPickupLocationNameById,
} from 'src/lib/participant';

import { useLocalizationContext } from 'src/app/hooks';

const i18nPrefix = 'shop:screen-product';
const i18nPriceSummaryPrefix = `${i18nPrefix}.price-summary`;

interface PriceSummaryPriceGroupFeesProps {
  priceGroupTicket: ShoppingCartItemConfiguration;
  product: SourcedGQLProduct;
}

export const PriceSummaryPriceGroupFees = (props: PriceSummaryPriceGroupFeesProps) => {
  const { priceGroupTicket, product } = props;
  // const styles = useStyles();
  const { currencyId, isAppDebug } = useAppContext();
  const { language } = useLocalizationContext();

  console.log('priceGroupTicket: ', priceGroupTicket);

  return (
    <>
      {priceGroupTicket?.participants?.map((p) => {
        const pickupId = p.options?.pickup || '';
        const locationInfo = parsePickupOptionId(pickupId);

        if (!locationInfo) {
          return null;
        }

        const isDefaultLocation = locationInfo.type === PickupOptionType.DEFAULT;
        // const isShuttle = locationInfo.type === PickupOptionType.SHUTTLE;
        // const isCustomPickup = locationInfo.type === PickupOptionType.CUSTOM_PICKUP;
        const pickupFee = calculatePickupFeeFromPickupLocationInformation(
          locationInfo,
          product,
        );

        if (isDefaultLocation) {
          return null;
        }

        return (
          <Grid key={p.id} item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography>
                  <em>{`${1}x`}</em>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  <em>
                    {/* <Localized
                  dictKey={`shop:screen-product.price-group.${priceGroupId}.label`}
                /> */}
                    {`Additional "${getPickupLocationNameById(
                      pickupId,
                      product,
                      language,
                      isAppDebug,
                    )}" fee`}
                  </em>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={'right'}>
                  <em>
                    <Localized dictKey={`${i18nPriceSummaryPrefix}.price-group.price`} />
                  </em>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align={'right'}>
            

                  <em> {createPriceStringWithCurrency(euroPriceAsCurrency(pickupFee,currencyId), currencyId)}</em>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      }) || null}
    </>
  );
};
