import * as React from 'react';
import Divider from '@material-ui/core/Divider';

import { Grid, Typography, Localized } from 'src/app/components';
import {
  PriceSystem,
  Price,
  ShoppingCartItemConfiguration,
  SourcedGQLProduct,
} from 'src/app/types';

import {
  // calculatePriceGroupTotalPrice,
  calculatePriceGroupTotalPriceWithExtraFees,
  calculatePriceGroupPrice,
  createPriceStringWithCurrency,
  euroPriceAsCurrency,
} from 'src/lib/price';

import { useStyles, useAppContext } from 'src/app/context';

import { PriceSummaryTicketGroupParticipants } from './PriceSummaryTicketGroupParticipants';

import { PriceSummaryPriceGroupFees } from './PriceSummaryPriceGroupFees';

const i18nPrefix = 'shop:screen-product';
const i18nPriceSummaryPrefix = `${i18nPrefix}.price-summary`;

export const PriceSummary = ({
  priceSystems,
  priceGroupTickets,
  product,
  showParticipantsInfo,
  bookingSystem
}: {
  priceSystems: PriceSystem[];
  priceGroupTickets: ShoppingCartItemConfiguration[];
  product: SourcedGQLProduct;
  showParticipantsInfo: boolean;
  bookingSystem: 'publicBooking' | 'privateBooking';
}) => {
  // const styles = useStyles();
  const { currencyId } = useAppContext();

  return (
    <Grid container spacing={1} className='text-p3 sm:text-p2'>

      <Grid item xs={4}>
        <p className='underline'>
          <Localized dictKey={`${i18nPriceSummaryPrefix}.column.amount`} />
        </p>
      </Grid>

      <Grid item xs={4}>
        <p className='underline'>
          <Localized dictKey={`${i18nPriceSummaryPrefix}.column.discountGroup`} />
        </p>
      </Grid>

      <Grid item xs={4}>
        <p className='underline text-right'>
          <Localized dictKey={`${i18nPriceSummaryPrefix}.column.price`} />
        </p>
      </Grid>

      {priceGroupTickets?.length
        ? priceGroupTickets.map((priceGroupTicket) => {
            const { priceGroupId } = priceGroupTicket;
            const groupPrice: Price = calculatePriceGroupPrice(
              priceGroupTicket,
              priceSystems,
              bookingSystem
            );
            // const totalGroupPrice = calculatePriceGroupTotalPrice(
            //   priceGroupTicket,
            //   priceSystems,
            // );

            const totalGroupPriceWithExtraFees =
              calculatePriceGroupTotalPriceWithExtraFees(
                priceGroupTicket,
                priceSystems,
                product,
                bookingSystem
              );
              console.log('dhjsgdhdgsjhgjhs',groupPrice,priceGroupTickets);
            if (!priceGroupTicket.count) {
              return null;
            }
            return (
              <React.Fragment key={priceGroupId}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <p>
                        <em>{`${priceGroupTicket.count}x`}</em>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p>
                        <em>
                          <Localized
                            dictKey={`shop:screen-product.price-group.${priceGroupId}.label`}
                          />
                        </em>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p className='text-right'>
                        <em>
                          <Localized
                            dictKey={`${i18nPriceSummaryPrefix}.price-group.price`}
                          />
                        </em>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p className='text-right'>
                        <em>{createPriceStringWithCurrency(euroPriceAsCurrency(groupPrice,currencyId), currencyId)}</em>
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <PriceSummaryPriceGroupFees
                  priceGroupTicket={priceGroupTicket}
                  product={product}
                />
                {showParticipantsInfo ? (
                  <Grid item xs={12}>
                    <PriceSummaryTicketGroupParticipants
                      priceGroupTicket={priceGroupTicket}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      {' '}
                    </Grid>
                    <Grid item xs={4}>
                      {' '}
                    </Grid>
                    <Grid item xs={2}>
                      <p className='text-right'>
                        <Localized
                          dictKey={`${i18nPriceSummaryPrefix}.price-group.total`}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p className='text-right font-bold'>
                          {createPriceStringWithCurrency(
                            euroPriceAsCurrency(totalGroupPriceWithExtraFees,currencyId),
                            currencyId,
                          )}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })
        : null}
    </Grid>
  );
};
