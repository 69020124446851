import * as React from 'react';

import CancelIcon from '@material-ui/icons/Block';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import {
  Button,
  DialogLayout,
  Divider,
  Grid,
  ObjectView,
  Typography,
  Link,
  StringPropertyRow,
  DebugObject,
} from 'src/app/components';

import { AppDialogParams } from 'src/app/types';
import { useAppStaticQueryConfig, useLocalizationContext } from 'src/app/hooks';
import { useAppContext, useStyles } from 'src/app/context';
import { DialogType } from 'src/app/constants';
// import { useAdminContext } from 'src/admin/context';

export interface DebugDialogProps extends AppDialogParams {
  children?: React.ReactNode;
  onCloseDialog: () => void;
}

export const DebugDialog = (props: DebugDialogProps) => {
  const { children, onCloseDialog } = props;
  const styles = useStyles();
  const appStaticQuery = useAppStaticQueryConfig();
  const {
    closeDialog,
    openDialog,
    isAppDebug,
    isEmailVerified,
    setIsAppDebug,
    user,
    systemAdminRoles,
    organisations,
  } = useAppContext();
  const { onUpdateLocalizations } = useLocalizationContext();
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  return (
    <DialogLayout
      dialogDescription={'Environment Variables:'}
      dialogControls={
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={onCloseDialog}
              fullWidth
              variant={'contained'}
              color={'primary'}
              style={{ maxWidth: '280px' }}
              size={'large'}
            >
              <CancelIcon className={styles.mr__1} />
              <Typography>Close</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ObjectView
            object={appStaticQuery}
            propertyOptions={[
              {
                id: 'buildTime',
                renderType: '__date',
                renderParams: 'YYYY-MM-DD hh:mm:ss',
              },
              // optional
              // { id: 'env' },
              // { id: 'nodeVersion' },
              // { id: 'version' },
              // { id: 'emulationMode' },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <StringPropertyRow title={'User uid:'} value={user?.uid || 'not logged in'} />
        </Grid>

        <Grid item xs={12}>
          <StringPropertyRow
            title={'User email verified:'}
            value={isEmailVerified ? 'Yes' : 'No'}
          />
        </Grid>

        <Grid item xs={12}>
          {systemAdminRoles?.length ? (
            <DebugObject isDebug collapsed={0} object={systemAdminRoles || {}} />
          ) : (
            'No system'
          )}
        </Grid>

        <Grid item xs={12}>
          {organisations?.length ? (
            <DebugObject isDebug collapsed={0} object={organisations || {}} />
          ) : (
            'No organisations'
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography align={'left'}>Actions:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size={'large'}
            disabled={isRequestingUpdate}
            onClick={async () => {
              openDialog(DialogType.LOADING);
              setIsRequestingUpdate(true);
              await onUpdateLocalizations();
              setIsRequestingUpdate(false);
              closeDialog();
            }}
            variant={'contained'}
            color={'primary'}
            endIcon={isRequestingUpdate ? <CircularProgress /> : <CachedIcon />}
          >
            <Typography>Update window session translations from DB</Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size={'large'}
            disabled={isRequestingUpdate}
            onClick={() => setIsAppDebug(!isAppDebug)}
            variant={'contained'}
            color={isAppDebug ? 'secondary' : 'primary'}
            endIcon={isAppDebug ? <CheckCircleOutlineIcon /> : <NotInterestedIcon />}
          >
            <Typography>App Debug Mode</Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography align={'left'}>Information:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link href={'https://github.com/TravlrProject/consumerFrontend#readme'}>
            <Typography align={'center'}>Readme</Typography>
          </Link>
        </Grid>
      </Grid>
      {children}
    </DialogLayout>
  );
};
