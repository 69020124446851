import { ReadExcelFileSchema } from 'src/app/types';

export const uploadParticipantTableSchema: ReadExcelFileSchema = {
  'First Name': {
    prop: 'firstName',
    type: String,
  },
  'Last Name': {
    prop: 'lastName',
    type: String,
  },
  Gender: {
    prop: 'gender',
    type: String,
  },
  Country: {
    prop: 'country',
    type: String,
  },
  Age: {
    prop: 'age',
    type: String,
  },
  Date: {
    prop: 'date',
    type: String,
  },
  Id: {
    prop: 'id',
    type: String,
  },
  // 'START DATE': {
  //   // JSON object property name.
  //   prop: 'date',
  //   type: Date,
  // },
  // 'NUMBER OF STUDENTS': {
  //   prop: 'numberOfStudents',
  //   type: Number,
  //   required: true,
  // },
  // // Nested object example.
  // // 'COURSE' here is not a real Excel file column name,
  // // it can be any string — it's just for code readability.
  // COURSE: {
  //   // Nested object path: `row.course`
  //   prop: 'course',
  //   // Nested object schema:
  //   type: {
  //     'IS FREE': {
  //       prop: 'isFree',
  //       type: Boolean,
  //     },
  //     'COURSE TITLE': {
  //       prop: 'title',
  //       type: String,
  //     },
  //   },
  // },
  // CONTACT: {
  //   prop: 'contact',
  //   required: true,
  //   // A custom `type` can be defined.
  //   // A `type` function only gets called for non-empty cells.
  //   type: (value) => {
  //     const number = parsePhoneNumber(value);
  //     if (!number) {
  //       throw new Error('invalid');
  //     }
  //     return number;
  //   },
  // },
  // STATUS: {
  //   prop: 'status',
  //   type: String,
  //   oneOf: ['SCHEDULED', 'STARTED', 'FINISHED'],
  // },
};
