import * as React from 'react';
import { OrderDocumentData } from 'src/app/types';
import {
  Button,
  Divider,
  Grid,
  GlassPaper,
  Typography,
  LoadingIndicator,
  DebugObject,
  StringPropertyRow,
  LocalizedLink,
} from 'src/app/components';
import { formatFirebaseTimestamp } from 'src/lib/date';
import { FirestoreCollectionData } from 'src/app/container';
import { where } from 'firebase/firestore';
import { parseSkuString } from 'src/lib/event';
import { useLocalizationContext } from 'src/app/hooks';

const PurchaseUnitItemView = ({
  item,
}: {
  item: {
    description: string;
    name: string;
    quantity: number;
    unit_amount: {
      value: string;
      currency_code: string;
    };
    sku: string;
    category: string; // could be left away in BE while saving userOrderDoc
    tax: {
      value: string;
      currency_code: string;
    };
  };
}) => {
  const { language } = useLocalizationContext();
  const parsedSku = parseSkuString(item.sku);
  if (!parsedSku) {
    return null;
  }
  const {
    productId,
    priceSystemId,
    priceGroupId,
    eventDateIsoStringId,
    userId,
    organisationId,
    orderId,
    cartItemId,
  } = parsedSku;

  return (
    <Grid container spacing={1} style={{ padding: '2px 0' }}>
      {/* <Grid item xs={12}>
        <StringPropertyRow title={'Quantity'} value={item?.quantity || 0} />
      </Grid> */}
      {/* <Grid item xs={12}>
        <DebugObject isDebug object={item} collapsed={0} />
      </Grid> */}
      <Grid item xs={12}>
        <StringPropertyRow
          title={`
            ${
              new Date(eventDateIsoStringId).toLocaleString(language, {
                hour: '2-digit',
                minute: '2-digit',
              }) || ''
            }
             - 
            ${
              new Date(eventDateIsoStringId).toLocaleString(language, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }) || ''
            }
          `}
          value={' '}
        />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow
          title={
            <>
              <LocalizedLink href={`/experience/${productId}`}>
                {item?.name || ''}
              </LocalizedLink>
              {` - ${item?.description || ''}`}
            </>
          }
          value={`${item?.quantity || 0} x ${item?.unit_amount?.value || 0} ${
            item?.unit_amount?.currency_code || 'EUR'
          }`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* //TODO: map booked options here */}
      {/* <Grid item xs={12}>
        <StringPropertyRow title={'Quantity'} value={item?.quantity || 0} />
      </Grid> */}
    </Grid>
  );
};

const TotalsView = ({
  purchaseUnits,
}: {
  purchaseUnits: any[]; // TODO
}) => {
  // TODO: calc total of all purchase units
  const total = purchaseUnits[0]?.amount?.breakdown?.item_total?.value || 0;
  const currencyCode =
    purchaseUnits[0]?.amount?.breakdown?.item_total?.currency_code || 'EUR';
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow title={'Total'} value={`${total} ${currencyCode}`} />
      </Grid>
      {/* <Grid item xs={12}>
        <DebugObject isDebug object={purchaseUnits} collapsed={0} />
      </Grid> */}
    </Grid>
  );
};

export interface HistoryItemProps {
  orderDocument: OrderDocumentData;
}

const HistoryItem = (props: HistoryItemProps) => {
  const { orderDocument } = props;
  const { id: referenceId, orderId, meta } = orderDocument; // id is the passed reference_id in pp doc

  const [expandedId, setExpandedId] = React.useState<string | null>(null);
  const onOpenOrder = (openId: string) => {
    setExpandedId(expandedId === openId ? null : openId);
  };

  return (
    <GlassPaper elevation={4} style={{ padding: '32px', marginBottom: '32px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StringPropertyRow
                // title={`OrderNo.: ${orderId} / ${referenceId}`}
                title={`OrderNo.: ${orderId}`}
                value={formatFirebaseTimestamp(meta?.createdAt || null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {orderDocument?.resource?.purchase_units?.[0]?.items?.map((item) => (
                <PurchaseUnitItemView
                  key={`${item.name}_${item.description}_${item.quantity}`}
                  item={item}
                />
              )) || null}
            </Grid>
            {orderDocument?.resource?.purchase_units?.length ? (
              <Grid item xs={12}>
                <TotalsView purchaseUnits={orderDocument.resource.purchase_units} />
              </Grid>
            ) : null}
            {expandedId === referenceId ? (
              <Grid item xs={12}>
                <DebugObject isDebug object={orderDocument || {}} collapsed={1} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                onClick={() => onOpenOrder(referenceId)}
                fullWidth
                color={'primary'}
              >
                <Typography>View order</Typography>
              </Button>
            </Grid>
            {/** // TODO: this should be done per purchase_item probably  */}
            {/* <Grid item xs={12}>
              <Button disabled fullWidth color={'primary'}>
                <Typography>Cancel order</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button disabled fullWidth color={'primary'}>
                <Typography>Send question to supplier</Typography>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </GlassPaper>
  );
};

export const HistoryList = ({ uid }: { uid: string }) => {
  if (!uid) {
    return null;
  }

  return (
    <FirestoreCollectionData<OrderDocumentData>
      collectionPath={`user/${uid}/order`}
      queryConstraints={[where('isOrderApproved', '==', true)]}
      renderElements={({ data: historyList, status }) => {
        return historyList ? (
          historyList.map((historyDoc) => (
            <HistoryItem key={historyDoc.id} orderDocument={historyDoc} />
          ))
        ) : (
          <>
            <LoadingIndicator />
          </>
        );
      }}
    />
  );
};
