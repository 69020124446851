import * as React from 'react';

export const classNames = (...classes: any) => classes.filter(Boolean).join(' ');

export const ButtonTw = (props: any) => {
  const { children, className, rounded, variant, size, fullWidth, ...passProps } = props;

  return (
    <button
      type='button'
      className={classNames(
        'cursor-pointer select-none font-body disabled:cursor-not-allowed disabled:opacity-75 disabled:pointer-events-none duration-300 transition-all',
        rounded ? 'rounded-xl' : '',
        size === 'xl' ? 'text-p2 font-medium px-9 py-3 sm:py-[0.875rem] sm:text-p1 sm:font-semibold' : '',
        size === 'lg' ? 'text-p2 px-9 py-3 sm:py-[0.875rem] font-medium' : '',
        size === 'md' ? 'text-p2 px-6 py-3 font-medium' : '',
        size === 'sm' ? 'text-p3 px-6 py-[0.375rem] font-normal' : '',
        // variant ? '' : 'bg-neutral-50 text-neutral-900',
        variant === 'text' ? 'font-normal text-neutral-900 dark:text-neutral-50 disabled:opacity-50' : '',
        variant === 'text-underline'
          ? 'font-semibold md:font-medium text-p3 md:text-p2 relative after:w-full after:h-[2px] after:absolute after:bg-accent-500 after:-bottom-0 after:left-0 dark:text-neutral-50 hover:after:bg-primary-500 after:duration-300'
          : '',
        variant === 'neutral'
          ? 'text-neutral-900 border-2 border-transparent bg-neutral-50 shadow-md hover:text-accent-600 dark:hover:text-accent-600 dark:bg-neutral-900 dark:text-neutral-50'
          : '',
        variant === 'default'
          ? 'text-neutral-50 border-2 border-transparent bg-neutral-900 shadow-md dark:bg-neutral-800 dark:text-neutral-50'
          : '',
        variant === 'accent'
          ? 'text-neutral-900 border-2 border-transparent bg-gradient-to-tl from-accent-400 via-accent-600 to-accent-600 shadow-md transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100'
          : '',
        variant === 'primary'
          ? 'bg-primary-500 border-2 border-transparent hover:hue-rotate-15 focus:ring-primary-500 text-neutral-50 shadow-md'
          : '',
        variant === 'outline'
          ? 'bg-neutral-50 text-neutral-900 border-2 border-accent-500 hover:bg-accent-100 focus:ring-accent-500 shadow-md dark:bg-neutral-800  dark:text-neutral-50 dark:hover:text-neutral-50 dark:hover:bg-accent-500 dark:hover:bg-opacity-10'
          : '',
          variant === 'orderAction'
          ? 'bg-neutral-50 text-neutral-900 border-2 border-accent-500 hover:bg-accent-100 focus:ring-accent-500 shadow-md dark:bg-neutral-800  disabled:border-accent-200 disabled:bg-gray-300	 dark:text-neutral-50 dark:hover:text-neutral-50 dark:hover:bg-accent-500 dark:hover:bg-opacity-10'
          : '',
        className,
      )}
      {...passProps}
    >
      {children}
    </button>
  );
};
