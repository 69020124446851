import * as React from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';

import { Container, Grid, Glass } from 'src/app/components';
import { BaseComponentProps, RouterPageProps } from 'src/app/types';
import { useAppContext } from 'src/app/hooks';
import { AdminType } from 'src/admin/constants';
import { useStyles } from 'src/app/context';
import { AdminLoginFormContainer } from './AdminLoginFormContainer';
import { AdditionalLoginOptions } from './AdditionalLoginOptions';

export interface AdminLoginContainerProps extends BaseComponentProps, RouterPageProps {
  adminType: AdminType;
  redirectAfterLoginTo: string;
}

/**
 * OrganisationLoginContainer
 * @param { AdminLoginContainerProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const AdminLoginContainer = (props: AdminLoginContainerProps) => {
  const { adminType, children, redirectAfterLoginTo } = props;
  const { isLoggedIn } = useAppContext();

  const onSuccessfulLogin = () => {
    if (redirectAfterLoginTo) {
      navigate(redirectAfterLoginTo);
    }
  };

  return !isLoggedIn ? (
    <section className='px-4 py-16 dark:text-neutral-50'>
      <div className='xl:container'>
        <div className='md:mx-auto md:w-8/12 lg:w-6/12'>
          <AdminLoginFormContainer onSuccess={onSuccessfulLogin} adminType={adminType}>
            <div>
              <AdditionalLoginOptions />
            </div>
            <div>
              {children}
            </div>
          </AdminLoginFormContainer>
        </div>
      </div>
    </section>
  ) : null;
};

export { AdminLoginContainer };
