import * as React from 'react';
import { Router } from '@reach/router';

import { Grid, Link, PrivateRoute, Typography } from 'src/app/components';

import { useAppContext } from 'src/app/hooks';
// TODO: reuse AppPageLoayout here?
import { AppPageLayout, HistoryContainer } from 'src/app.consumer/components';
import { UserAccountMenu } from '../components';

import { basePath } from '../navigationBasePath';

export const UserHistory = () => {
  const { isLoggedIn } = useAppContext();
  if (
    !isLoggedIn ||
    typeof window === 'undefined' ||
    !window.location.pathname.startsWith(basePath)
  ) {
    return null;
  }

  // TODO: change profile info
  // cancel order

  return (
    <Grid container>
      <Grid item xs={12}>
        <HistoryContainer />
      </Grid>
      <Grid item xs={12}>
        <Router basepath={basePath}>
          {/* <PrivateRoute path={'/'} component={Dashboard as React.FC} />
          <PrivateRoute path={`${'profile'}/*`} component={UserProfile as React.FC} />
          <PrivateRoute
            path={`${'history'}/*`}
            component={HistoryContainer as React.FC}
          />
          <PrivateRoute path={`${'messages'}/*`} component={UserMessages as React.FC} />
          <PrivateRoute path={`${'settings'}/*`} component={Settings as React.FC} />
          <PrivateRoute path={`${'billing'}/*`} component={UserBilling as React.FC} /> */}
        </Router>
      </Grid>
    </Grid>
  );
};
