import * as React from 'react';
import {
  ProductListItem
} from 'src/app/components';
import ClearIcon from '@material-ui/icons/Clear';

import {
  SourcedGQLProduct,
  ShoppingCartItemDocumentData,
  ShoppingCartItemConfiguration,
} from 'src/app/types';
import { useLocalizationContext } from 'src/app/hooks';
import { parseFirebaseTimestampToDate } from 'src/lib/date';
import { AdditionalProductInformation } from './AdditionalProductInformation';
import { ProductListItemContent } from './ProductListItemContent';

export interface ShoppingCartListItemProps {
  cartItem: ShoppingCartItemDocumentData;
  isExtended: boolean;
  onExtendDetails: () => void;
  onCartItemRemove: () => void;
  onCartItemUpdate: (data: ShoppingCartItemConfiguration[]) => void;
  srcProduct: SourcedGQLProduct;
}

export const ShoppingCartListItem = (props: ShoppingCartListItemProps) => {
  const { language } = useLocalizationContext();
  const {
    isExtended,
    onCartItemRemove,
    onCartItemUpdate,
    onExtendDetails,
    cartItem,
    srcProduct,
  } = props;

  return (
    <>
      <div className='flex flex-col md:flex-row bg-neutral-100 bg-opacity-70 rounded-2xl shadow-box-light text-neutral-900 dark:text-neutral-50 dark:bg-neutral-800 dark:shadow-box-dark relative my-6 md:my-4' key={cartItem.id}>
        <div className='w-full md:w-1/3'>
          <ProductListItem
            hasWishlistIcon={false}
            hideTagsAndRatings
            product={srcProduct}
          />
        </div>
        <div className='w-full h-full py-4 px-4 gap-2 md:px-6 md:h-auto md:w-2/3 md:min-h-[240px] flex flex-col'>
          <div className='flex justify-between items-center'>
            <div>
              <div className='w-full flex justify-start items-center'>
                <p className='text-p3'>
                  {parseFirebaseTimestampToDate(cartItem.eventDate).toLocaleString(
                    language,
                    {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}
                  <span className='ml-1'>
                    {parseFirebaseTimestampToDate(cartItem.eventDate).toLocaleString(
                      language,
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      },
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className='absolute top-4 right-4 md:top-auto md:right-auto  md:relative'>
              <button
                onClick={onCartItemRemove}
                type='button'
                className='text-neutral-50 md:dark:text-neutral-50 md:text-neutral-900 hover:text-accent-500'
              >
                <ClearIcon />
              </button>
            </div>
          </div>
          <ProductListItemContent
            cartItem={cartItem}
            hideTeaser
            onExtendDetails={onExtendDetails}
            product={srcProduct}
          />
        </div>
      </div>
      {isExtended ? (
          <>
            <AdditionalProductInformation
              cartItem={cartItem}
              onCartItemUpdate={onCartItemUpdate}
              product={srcProduct}
            />
          </>
        ) : null
      }
    </>
  );
};
