import * as React from 'react';
import { RouterPageProps } from 'src/app/types';

export interface RouteProps<T extends Record<string, any>> extends RouterPageProps {
  component: React.FC<T> | JSX.Element;
  passProps?: T;
}

export const Route = <T extends Record<string, any>>({
  component,
  passProps, // additional pass-props for the rendered component
  ...rest // props from reach router inside
}: RouteProps<T>) => {
  const Component = component as React.FC<T>;
  return <Component {...{ ...(rest as any as T), ...passProps }} />;
};
