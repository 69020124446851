import * as React from 'react';

import { Checkbox, Divider, Grid, Typography } from 'src/app/components';

import { ParticipantImportData, PriceSystem, PickupLocation } from 'src/app/types';

import { TableRowLayout } from './TableRowLayout';
import { SelectPriceGroup } from './SelectPriceGroup';
import { SelectPickupOption } from './SelectPickupOption';

interface ParticipantsRowProps extends ParticipantImportData {
  enabled: boolean;
  index: number;
  locationId?: string;
  pickupLocations: PickupLocation[];
  priceGroupId?: string;
  priceSystem: PriceSystem;
  onChangeEnabledState: () => void;
  onChangePickup: (priceGroupId: string) => void;
  onChangePriceGroup: (priceGroupId: string) => void;
}

export const ParticipantsRow = (props: ParticipantsRowProps) => {
  const {
    // ParticipantImportData props
    firstName,
    lastName,
    gender,
    country,
    age,
    passportId,
    // pass trough
    locationId,
    pickupLocations,
    priceGroupId,
    priceSystem,
    // utility props
    enabled,
    index,
    onChangeEnabledState,
    onChangePickup,
    onChangePriceGroup,
  } = props;
  const na: string = 'N/A';
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableRowLayout
          firstChildren={
            <Grid container>
              <Grid item md={1} xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Checkbox
                      checked={typeof enabled !== 'undefined' ? enabled : false}
                      onChange={onChangeEnabledState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography center>{index + 1}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography center>{firstName}</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography center>{lastName}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{gender}</Typography>
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography center>{country}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{age}</Typography>
              </Grid>
              <Grid item md={1} xs={12}>
                <Typography center>{passportId || na}</Typography>
              </Grid>
            </Grid>
          }
          secondChildren={
            <Grid container>
              <Grid item md={4} xs={12}>
                <SelectPriceGroup
                  disabled={!enabled}
                  onChangePriceGroup={onChangePriceGroup}
                  priceSystem={priceSystem}
                  selectedId={priceGroupId}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectPickupOption
                  disabled={!enabled}
                  onChange={onChangePickup}
                  pickupLocations={pickupLocations}
                  selectedId={locationId}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography center>{'food'}</Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
