import * as React from 'react';
import { FieldValues } from 'react-hook-form';

import {
  FormErrors,
  FormControl,
  FormRootContainerProps,
  FormLayout,
  FieldStyle,
} from 'src/app/components/RHF';

import { TextField } from 'src/app/components/RHF/Fields';

// TODO: move to central place + generic
interface BaseFormProps<T extends FieldValues> {
  // formProps: FormRootContainerProps<T>;
  i18nBasePath: string;
}

export const LoginForm = <T extends FieldValues>({ i18nBasePath }: BaseFormProps<T>) => {
  const isValid = true; // TODO
  return (
    <FormLayout
      // header={
      //   <Grid item xs={12}>
      //     <Typography align={'center'} variant={'h4'} renderAs={'h2'}>
      //       <Localized dictKey={`${i18nBasePath}.form.title`} />
      //     </Typography>
      //   </Grid>
      // }
      formControl={<FormControl isValid={isValid} className={'text-center'} /* hasReset */ />}
      // renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
    >
      <TextField
        autoComplete={'email'}
        name={'email'}
        i18nKey={'email'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
      />
      <TextField
        autoComplete={'password'}
        name={'password'}
        i18nKey={'password'}
        i18nPath={i18nBasePath}
        fieldStyle={FieldStyle.CLEAN}
        isPassword
      />
    </FormLayout>
  );
};
