import * as React from 'react';
import MuiMenuList, { MenuListProps } from '@material-ui/core/MenuList';

// import clsx from 'clsx';
// import { useStyles } from 'src/app/context';

/**
 * @param { MenuListProps } props
 * @version 0.0.0
 * @description https://material-ui.com/api/menu-list
 * @todo
 * @example
 */
export const MenuList = (props: MenuListProps) => {
  // const styles = useStyles();
  return <MuiMenuList {...props} />;
};
