import { FirebaseApp } from 'firebase/app';
import { functionNamespace } from './_namespace';
import { callableFunction, createCallableFunctionMeta } from '../lib';

export type CaptureOrderCallableRequestData = {};

export interface CaptureOrderCallableResponseData extends Record<string, unknown> {
  orderId: string;
  // code: string;
  // details: {
  //   codePrefix: string;
  //   errorInfo: {
  //     code: string;
  //   };
  // };
}

/**
 * orderFunction-captureOrder
 * @version 0.0.0 functions version
 */
export const captureOrder = async (
  firebase: FirebaseApp,
  env: string,
  params: CaptureOrderCallableRequestData,
  functionName: string = 'captureOrder',
) =>
  callableFunction<CaptureOrderCallableRequestData, CaptureOrderCallableResponseData>(
    firebase,
    env,
    params,
    createCallableFunctionMeta(functionNamespace, functionName),
  );
