import { AppLocale, PickupOptionType } from 'src/app/constants';
import { PickupLocation, Price, SourcedGQLProduct } from 'src/app/types';

import { getTranslationForLanguage } from 'src/lib/document';

import { calculatePriceFromPriceString } from 'src/lib/price';

interface SelectedPickupOption {
  id: string;
  type: PickupOptionType;
  cityId: string;
}

interface SelectedPickupOptionInformation {
  id: string;
  type: PickupOptionType;
  index: number | null;
}

const createPickupLocationOption = (
  id: string,
  type: PickupOptionType,
  data: { cityId: string },
): SelectedPickupOption => {
  return {
    ...data,
    id,
    type,
  };
};

export const createProductLocationOptions = (product: SourcedGQLProduct) => {
  const productPickup = product.pickup;

  const meetingLocation = productPickup.baseLocationIsActivityLocation
    ? productPickup.baseLocation
    : product.location;
  const pickupLocations = productPickup?.locations;
  const pickupCustomLocations = productPickup?.customLocations;
  return [
    createPickupLocationOption(
      `${PickupOptionType.DEFAULT}_${meetingLocation.cityId}`,
      PickupOptionType.DEFAULT,
      meetingLocation,
    ),
    ...pickupLocations.map((pl, idx) =>
      createPickupLocationOption(
        `${PickupOptionType.SHUTTLE}_${pl.cityId}_${idx}`,
        PickupOptionType.SHUTTLE,
        pl,
      ),
    ),
    ...pickupCustomLocations.map((pcl, idx) =>
      createPickupLocationOption(
        `${PickupOptionType.CUSTOM_PICKUP}_${pcl.cityId}_${idx}`,
        PickupOptionType.CUSTOM_PICKUP,
        pcl,
      ),
    ),
  ];
};

export const parsePickupOptionId = (
  productLocationOptionId: string,
): SelectedPickupOptionInformation | null => {
  if (!productLocationOptionId) {
    return null;
  }
  if (productLocationOptionId.startsWith(PickupOptionType.DEFAULT)) {
    const id = productLocationOptionId
      .split(`${PickupOptionType.DEFAULT}_`)[1]
      .split('_')[0];
    if (!id) {
      return null;
    }
    return {
      id,
      type: PickupOptionType.DEFAULT,
      index: null,
    };
  }
  if (productLocationOptionId.startsWith(PickupOptionType.SHUTTLE)) {
    const id = productLocationOptionId
      .split(`${PickupOptionType.SHUTTLE}_`)[1]
      .split('_')[0];
    if (!id) {
      return null;
    }
    // TODO: bäh
    const indexStr = productLocationOptionId
      .split(`${PickupOptionType.SHUTTLE}_`)[1]
      .split('_')[1];
    const index = indexStr ? parseInt(indexStr, 10) : null;
    return {
      id,
      type: PickupOptionType.SHUTTLE,
      index: !Number.isNaN(index) ? index : null,
    };
  }
  if (productLocationOptionId.startsWith(PickupOptionType.CUSTOM_PICKUP)) {
    const id = productLocationOptionId
      .split(`${PickupOptionType.CUSTOM_PICKUP}_`)[1]
      .split('_')[0];
    if (!id) {
      return null;
    }
    // TODO: bäh
    const indexStr = productLocationOptionId
      .split(`${PickupOptionType.CUSTOM_PICKUP}_`)[1]
      .split('_')[1];
    const index = indexStr ? parseInt(indexStr, 10) : null;
    return {
      id,
      type: PickupOptionType.CUSTOM_PICKUP,
      index: !Number.isNaN(index) ? index : null,
    };
  }

  return null;
};

export const isDefaultPickupLocationId = (id: string) =>
  id.startsWith(PickupOptionType.DEFAULT);

// NEW

const createLocationString = (location: PickupLocation) => {
  return `${location.cityId} - ${location.streetName} ${location.streetNumber}`;
};

export const getPickupLocationNameById = (
  pickupOptionId: string,
  product: SourcedGQLProduct,
  language: AppLocale,
  showSource?: boolean,
) => {
  if (pickupOptionId.startsWith(PickupOptionType.DEFAULT)) {
    return product.pickup.baseLocationIsActivityLocation
      ? createLocationString(product.pickup.baseLocation) // FIXME: this should be localized my sys locales
      : createLocationString(product.location); // FIXME: this should be localized my sys locales + solve type mismatch
  }
  if (pickupOptionId.startsWith(PickupOptionType.SHUTTLE)) {
    const optionRestId = pickupOptionId
      .replace(`${PickupOptionType.SHUTTLE}_`, '')
      .split('_')[0];
    const index = parseInt(optionRestId?.at(-1) || 'invalid', 10);
    return Number.isNaN(index)
      ? optionRestId
      : getTranslationForLanguage(
          product.pickup?.locations[index]?.localizations || [],
          language,
          'name',
          showSource,
        ) || optionRestId;
  }
  if (pickupOptionId.startsWith(PickupOptionType.CUSTOM_PICKUP)) {
    const optionRestId = pickupOptionId
      .replace(`${PickupOptionType.CUSTOM_PICKUP}_`, '')
      .split('_')[0];
    const index = parseInt(optionRestId?.at(-1) || 'invalid', 10);
    return Number.isNaN(index)
      ? optionRestId
      : getTranslationForLanguage(
          product.pickup?.customLocations[index]?.localizations || [],
          language,
          'name',
          showSource,
        ) || optionRestId;
  }
  return '';
};

export const calculatePickupFeeFromPickupLocationInformation = (
  locationInfo: SelectedPickupOptionInformation,
  product: SourcedGQLProduct,
): Price => {
  let price = calculatePriceFromPriceString('0.00');
  if (locationInfo.type === PickupOptionType.DEFAULT) {
    price = calculatePriceFromPriceString('0.00');
  }
  if (
    locationInfo.type === PickupOptionType.SHUTTLE &&
    locationInfo.index !== null &&
    !Number.isNaN(locationInfo.index)
  ) {
    const basePrice = product.pickup.locations[locationInfo.index]?.basePrice;
    price = calculatePriceFromPriceString(basePrice ? basePrice.toString() : '0.00');
  }
  if (
    locationInfo.type === PickupOptionType.CUSTOM_PICKUP &&
    locationInfo.index !== null &&
    !Number.isNaN(locationInfo.index)
  ) {
    const basePrice = product.pickup.customLocations[locationInfo.index]?.basePrice;
    price = calculatePriceFromPriceString(basePrice ? basePrice.toString() : '0.00');
  }
  return price;
};
