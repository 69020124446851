import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Accordion,
  Grid,
  GlassPaper,
  Localized,
  Typography,
  CenteredContainer,
} from 'src/app/components';

const FAQAccordion = ({ i18nPath, index }: { i18nPath: string; index: number }) => {
  return (
    <Accordion
      expandIcon={<ExpandMoreIcon />}
      className='my-2'
      classNameSummary='!bg-transparent !border-accent-50 dark:!border-primary-500'
      summary={
        <p className='text-p2'>
          <Localized dictKey={`${i18nPath}.faq.question.${index}.question`} />
        </p>
      }
    >
      <div className='py-4 px-2 md:p-6'>
        <p className='mb-2'>
          <Localized dictKey={`${i18nPath}.faq.question.${index}.answer`} />
        </p>
      </div>
    </Accordion>
  );
};
export interface FrequentlyAskedCheckoutQuestionsProps {
  i18nPath: string;
}

/**
 * FrequentlyAskedCheckoutQuestions
 * @param { FrequentlyAskedCheckoutQuestionsProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const FrequentlyAskedCheckoutQuestions = (
  props: FrequentlyAskedCheckoutQuestionsProps,
) => {
  const { i18nPath } = props;

  const faqElements = ['1', '2', '3'];
  return (
    <>
      <p>
        <Localized dictKey={`${i18nPath}.faq.title`} />
      </p>

      {faqElements.map((faqEl, idx) => (
        <div>
          <FAQAccordion index={idx} i18nPath={i18nPath} />
        </div>
      ))}
    </>
  );
};
