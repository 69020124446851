import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DialogLayout, Grid, LoadingIndicator } from 'src/app/components';

import { DocumentDeleteProps } from 'src/app/types';

export interface LoadingDialogProps extends DocumentDeleteProps {
  children?: React.ReactNode;
  onCloseDialog: () => void;
}

export const LoadingDialog = (props: LoadingDialogProps) => {
  return (
    <DialogLayout>
      <Grid container>
        <Grid item xs={12}>
          <LoadingIndicator />
          {/** <CircularProgress size={'small'} color={'primary'} /> */}
        </Grid>
      </Grid>
    </DialogLayout>
  );
};
