import { GridSpacing } from '@material-ui/core';
import * as React from 'react';

import { Divider, Typography } from 'src/app/components';

interface StringPropertyRow {
  className?: string;
  spacing?: GridSpacing;
  title: string | React.ReactNode;
  underlined?: boolean;
  plainText?: boolean;
  value: string | React.ReactNode;
  image:any
}

export const StringPropertyRow = ({
  className,
  spacing,
  title,
  underlined,
  value,
  plainText,
  image
}: StringPropertyRow) => {
  return (
    <div className={className || 'sm:flex gap-4'}>
      {image}
      <div className='sm:w-1/2'>
        {typeof title === 'string' || plainText ? <Typography centerV>{title}</Typography> : title}
      </div>
      <div className='sm:w-1/2'>
        {typeof value === 'string' || plainText ? (
          <Typography centerV align={'right'}>
            {value || 'N/A'}
          </Typography>
        ) : (
          value
        )}
      </div>
      {underlined ? (
        <div>
          <Divider />
        </div>
      ) : null}
    </div>
  );
};
