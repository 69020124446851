import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { UserBillingInformationData } from 'src/app/types';
import { FormError } from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  street1: yup.string().required(FormError.REQUIRED).max(64, FormError.NUMBER_MAX_VALUE),
  street2: yup.string().optional().max(64, FormError.NUMBER_MAX_VALUE),
  streetNumber: yup
    .string()
    .required(FormError.REQUIRED)
    .max(5, FormError.NUMBER_MAX_VALUE),
  zipCode: yup.string().required(FormError.REQUIRED).max(16, FormError.NUMBER_MAX_VALUE),
  city: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
  region: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
  country: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: UserBillingInformationData = {
  streetNumber: '',
  street1: '',
  street2: '',
  zipCode: '',
  city: '',
  region: '',
  country: '',
};

export const defaultValues__DEV: UserBillingInformationData = {
  streetNumber: 'streetNumber',
  street1: 'street1',
  street2: 'street2',
  zipCode: 'zipCode',
  city: 'city',
  region: 'region',
  country: 'country',
  testFail: 'plz fail', // Test, should not be accepted with that property
};
