import * as React from 'react';
import clsx from 'clsx';
import MAppBar, { AppBarProps as MAppBarProps } from '@material-ui/core/AppBar';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
import { useStyleContext, useStyles } from 'src/app/context';
import { ThemeMode } from 'src/app.consumer/constants';

export interface AppBarProps extends CombinedBaseComponentProps<MAppBarProps> {}

// TODO: [0002] create glass fallback style for browsers without backdropFilter support
/**
 * AppBar / WAPAppBar - MUI
 * @param { AppBarProps } props
 * @version 0.0.0
 * @description An AppBar composed from MaterialUI
 * @todo
 * @example
 */
const AppBar = (props: AppBarProps): React.ReactElement<AppBarProps> => {
  const { children, className, ...passProps } = props;
  const { themeMode } = useStyleContext();
  const styles = useStyles();
  return (
    <header className='font-body bg-neutral-50 py-2 px-4 sticky top-0 left-0 w-full z-20 h-14 md:h-16 shadow-box-light dark:text-neutral-50 dark:shadow-box-dark dark:bg-neutral-900 flex items-center'
      {...passProps}
    >
      {children}
    </header>
  );
};

const WAPAppBar = (props: CombinedAppProps<AppBarProps>) =>
  withoutAppProps<CombinedAppProps<AppBarProps>, AppBarProps>(AppBar, props);

export { AppBar, WAPAppBar };
