import * as React from 'react';
import { DocumentData } from 'firebase/firestore';
import { isSSR } from 'src/app/lib';
import { FirestoreCollectionDataProps } from './useFirestoreCollection.types';

const UseFirestoreCollectionData = React.lazy(
  () => import('./UseFirestoreCollectionData'),
) as any as React.FunctionComponent<DocumentData>;

// NOTE: if not in gatsby SSR build mode - then it is ok to import package 'reactfire', it depends on browser fetch feature
export const FirestoreCollectionData = <T extends DocumentData>({
  collectionPath,
  renderElements,
  queryConstraints,
  queryLimit,
  queryOrderBy,
  queryOrderDirection,
  noIdField,
}: FirestoreCollectionDataProps<T>) => {
  return isSSR ? null : (
    <UseFirestoreCollectionData
      collectionPath={collectionPath}
      renderElements={renderElements}
      queryConstraints={queryConstraints}
      queryLimit={queryLimit}
      queryOrderBy={queryOrderBy}
      queryOrderDirection={queryOrderDirection}
      noIdField={noIdField}
    />
  );
};
