import * as React from 'react';
import { navigate } from 'gatsby';

import { useAppContext } from 'src/app/hooks';

import {
  Button,
  Grid,
  LoadingIndicator,
  TextInputField,
  Typography,
} from 'src/app/components';

import { NotificationType } from 'src/app/constants';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
// import { TextField } from 'src/app/components/RHF/Fields'; // For using inside of Forms

// dev email url https://travlr-dev.netlify.app/action?mode=resetPassword&oobCode=HSLq74vU4F4-LiZgL9jROsKpIbLpbXreBb8cOXZt6SMAAAF8n4-H1A&apiKey=AIzaSyAnDgWq0KuQkXAgawD7q1UVi_X1WVpzV5E&lang=en
interface ResetPasswordFormProps {
  apiKey: string; // not used ATM - how to verify?
  code: string; // the FB code
  lang: string; // is supported but currently not used to change language based on this provided value from FB
}

// TODO: i18n
// TODO: wrap inside from and use 'TextField' from RHF for that + form validation

export const ResetPasswordForm = ({ code }: ResetPasswordFormProps) => {
  const { createNotification, firebase } = useAppContext();

  const [passwordInput, setPasswordInput] = React.useState<string>('');
  const [passwordConfirmInput, setPasswordConfirmInput] = React.useState<string>('');

  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const onSubmitButtonClick = async () => {
    if (!firebase) {
      return;
    }

    if (
      !code ||
      !passwordInput ||
      !passwordConfirmInput ||
      passwordInput !== passwordConfirmInput ||
      passwordInput.length < 8
    ) {
      createNotification(NotificationType.ERROR, 'Invalid params', 5000);
      return;
    }

    setIsFetching(true);

    try {
      const auth = getAuth(firebase);
      await confirmPasswordReset(auth, code, passwordInput);

      // TODO: i18n
      createNotification(
        NotificationType.SUCCESS,
        'Password was successfully changed',
        5000,
      );
      navigate('/');
    } catch (err) {
      console.warn('Password change error: ', err);
      // TODO: i18n
      createNotification(NotificationType.ERROR, err.message || '', 5000);
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{'Enter a new password.'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              fullWidth
              label={'Password (8-64 characters) *'}
              variant={'outlined'}
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              type={'password'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              fullWidth
              label={'Repeat password *'}
              variant={'outlined'}
              value={passwordConfirmInput}
              onChange={(e) => setPasswordConfirmInput(e.target.value)}
              type={'password'}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color={'secondary'} onClick={onSubmitButtonClick}>
              <Typography>Send</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
