export enum BaseDocumentSeoLocalizationsPropertyName {
  DESCRIPTION = 'description',
  TITLE = 'title',
}

export enum BaseDocumentProductLocalizationsPropertyName {
  PRODUCT_EXCLUDES = 'productExcludes',
  PRODUCT_FEATURES = 'productFeatures',
  PRODUCT_INCLUDES = 'productIncludes',
  PRODUCT_INFORMATION = 'productInformation',
}

// NOTE: watchout - is used generically throughout all product.localization[].locale objects
// TODO: this should be refactored away...
export enum BaseDocumentLocalizationsPropertyName {
  ADDITIONAL_INFORMATION = 'additionalInformation', // is also used in product view bullet items
  DESCRIPTION = 'description',
  LABEL = 'label', // TODO: check where used => remove
  NAME = 'name',
  SHORT_DESCRIPTION = 'shortDescription',
  TEASER = 'teaser',
  META_DESCRIPTION = 'metaDescription', // max 140 chars? // TODO: is only set by system admin
  META_TITLE = 'metaTitle', // max 64 chars // TODO: is only set by system admin
}
