import * as React from 'react';
import clsx from 'clsx';
import MButton, { ButtonProps as MButtonProps } from '@material-ui/core/Button';

import { useStyles, useLocalizationContext } from 'src/app/context';

export interface ButtonProps extends MButtonProps {}

/**
 * Button
 * @param { ButtonProps } props https://material-ui.com/api/button
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const Button = (props: ButtonProps) => {
  const { children, className, ...passProps } = props;
  const { language } = useLocalizationContext();
  const styles = useStyles();

  return (
    <MButton
      classes={language === 'cimode' ? { root: styles.textTransform__none } : {}}
      className={className || ''}
      variant='contained'
      {...passProps}
    >
      {children}
    </MButton>
  );
};

// const Button = styled('button')<ButtonProps>`
//   padding: 4px 12px;
//   margin: 0;
//   background: none;
//   border: none;
//   border: 2px solid #663399;
//   border-radius: 2px;
//   color: #663399;
//   font-size: 14px;
//   font-weight: 500;
//   letter-spacing: 0.4px;
//   line-height: 24px;
//   text-transform: uppercase;
//   transition: all ease-in-out 0.2s;
//   transition-property: color, background-color;

//   &:hover,
//   &:focus {
//     color: #fff;
//     background-color: #663399;
//   }
// `;
