import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { GQLSiteData, SeoObject } from 'src/app/types';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStyleContext } from 'src/app/context';

export interface SeoProps {
  location: PageProps['location'];
  pageContext: PageProps['pageContext'];
  seo?: SeoObject;
}

// @import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

/**
 * Seo
 * @param { SeoProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const Seo = ({ location: { pathname }, pageContext, seo }: SeoProps) => {
  const { themeMode } = useStyleContext();
  const {
    site: { siteMetadata },
  } = useStaticQuery<GQLSiteData>(graphql`
    {
      site {
        siteMetadata {
          name
          basepath
          description
          keywords
          type
          image
        }
      }
    }
  `);

  const { name, basepath, title, description, keywords, type, image } = {
    ...siteMetadata,
    ...seo,
    ...(pageContext as any).frontmatter,
  } as SeoObject;

  const url = `${basepath}${pathname}`;

  // const isSeoDebugMode = true; // TODO: FLAG FOR ENV

  // if (isSeoDebugMode && typeof window !== 'undefined') {
  //   console.group('### SEO ###');
  //   console.log('window.location.pathname: ', window.location.pathname);
  //   console.log('siteMetadata: ', siteMetadata);
  //   console.log('seo: ', seo);
  //   console.log('pageContext.frontmatter: ', pageContext.frontmatter);
  //   console.log('url: ', url);
  //   console.log('name: ', name);
  //   console.log('basepath: ', basepath);
  //   console.log('title: ', title);
  //   console.log('description: ', description?.substring(0, 139));
  //   console.log('keywords: ', keywords);
  //   console.log('type: ', type);
  //   console.log('image: ', image);
  //   console.log('--------OG--------');
  //   console.log('og:site_name: ', name);
  //   console.log('og:title: ', title);
  //   console.log('og:description: ', description?.substring(0, 139));
  //   console.log('og:type: ', type);
  //   console.log('og:image: ', image);
  //   console.log('og:url: ', url);
  //   console.groupEnd();
  // }

  return (
    <Helmet titleTemplate={`%s • ${name}`} defaultTitle={name}>
      <html lang='en' data-color-scheme={themeMode || 'light'} className={themeMode || 'light'} style='scroll-behavior: smooth' />
      <title>{title}</title>
      <meta name='description' content={description?.substring(0, 139) || ''} />
      <meta name='keywords' content={keywords?.join(', ')} />
      <meta
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
      />
      <link rel='canonical' href={url} />
      {/* <link rel='prefetch' href='https://fonts.gstatic.com' /> */}{' '}
      {/** REMOVED TESTWISE - maybe needed for font replacements?? */}
      {/* <link rel='preconnect' href='https://fonts.gstatic.com' /> */}
      {/* <link
        href='https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap'
        rel='stylesheet'
      /> */}
      {/* <link
        href='https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;700&family=Teko:wght@300&display=swap'
        rel='stylesheet'
      /> */}
      {/* <meta
        property='csp-nonce'
        content='sha256-6ieGaw5eIPqcj0EAe9nSDdZ9nx1rtXYL35/APMK40uU='
      /> */}
      <meta property='og:site_name' content={name} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description?.substring(0, 139) || ''} />
      <meta property='og:type' content={type} />
      <meta property='og:image' content={image} />
      <meta property='og:url' content={url} />
    </Helmet>
  );
};
