import * as React from 'react';
import { useSnackbar } from 'notistack';

// import { NotificationType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Slide, { SlideProps } from '@material-ui/core/Slide';

import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, BaseComponentProps } from 'src/app/types';
// import { Button } from 'src/app/components';
import { useStyles } from 'src/app/context';

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      elevation={10}
      //variant='outlined'
      severity={'info'}
      style={{ minWidth: '256px' }}
      {...props}
    />
  );
}

type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionDown(props: TransitionProps) {
  return <Slide {...props} in direction='down' mountOnEnter unmountOnExit />;
}

const action = (key: string) => {
  // const { closeSnackbar } = useSnackbar();
  return (
    <>
      {/* <Button
        size={'small'}
        onClick={() => {
          // closeSnackbar(key);
        }}
      >
        {`Dismiss ${key}`}
      </Button> */}
    </>
  );
};

export interface AppNotificationsProps extends BaseComponentProps {}

/**
 * AppNotifications / WAPAppNotifications - MUI
 * @param { AppNotificationsProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const AppNotifications = (props: AppNotificationsProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const styles = useStyles();
  const {
    closeNotification,
    isNotificationOpen,
    notificationContent,
    notificationType,
    notificationDisplayTime, // in ms
    useStack,
    setUseStack,
  } = useAppContext();

  React.useEffect(() => {
    if (useStack && isNotificationOpen && notificationContent) {
      const options = {
        key: Math.random(),
        variant: notificationType || 'default',
        // anchorOrigin: {
        //   vertical: 'top',
        //   horizontal: 'center',
        // },
        action,
        autoHideDuration: notificationDisplayTime || 5000,
        TransitionComponent: Fade,
      };
      enqueueSnackbar(<>{notificationContent}</>, options); // TODO: add close btn
      setUseStack(false); // Not ideal triggers too early
    }
  }, [
    isNotificationOpen,
    notificationContent,
    notificationType,
    notificationDisplayTime,
    useStack,
  ]);

  if (!isNotificationOpen) {
    return null;
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    /* don't close notifications on outside click */
    if (reason === 'clickaway') {
      return;
    }
    closeNotification();
    closeSnackbar();
  };

  // let severity = 'success';

  // if (notificationType && notificationType === 'success') {
  //   severity = 'danger';
  // }

  // FIXME: consider not using old notification system anymore??

  return useStack ? null : (
    <TransitionDown>
      <Snackbar
        open={isNotificationOpen}
        autoHideDuration={notificationDisplayTime}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ margin: '64px 0', left: '0', right: '0' }}
        TransitionComponent={Fade}
      >
        {/* <Glass> */}

          <Alert className={styles.p__4} onClose={handleClose}>
            {notificationContent}
          </Alert>

        {/* </Glass> */}
      </Snackbar>
    </TransitionDown>
  );
};

const WAPAppNotifications = (props: CombinedAppProps<AppNotificationsProps>) =>
  withoutAppProps<CombinedAppProps<AppNotificationsProps>, AppNotificationsProps>(
    AppNotifications,
    props,
  );

export { AppNotifications, WAPAppNotifications };
