import * as React from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  UserInfo,
} from 'firebase/auth';

import {
  AuthenticationState,
  OrganisationClaimInfo,
  OrganisationClaims,
  SignupFormData,
  SystemAdminClaim,
} from 'src/app/types';
import { SystemAdminClaimType, SignupType, LocalStorageKey } from 'src/app/constants';
import { userSignup } from 'src/api/callables';
import { useAppStaticQueryConfig } from '../staticQueryHooks';

const convertSystemAdminClaims = (
  systemAdminClaimData: SystemAdminClaimType[],
): SystemAdminClaim[] => {
  return systemAdminClaimData &&
    systemAdminClaimData.length &&
    systemAdminClaimData.length > 0
    ? systemAdminClaimData
        .filter(
          (adminClaimType) => Boolean(adminClaimType) && adminClaimType.length === 3,
        ) // maybe split sysAdmins by system later
        .map((type) => ({ displayName: '', type })) || [] // FIXME: display name missing => do we need one?
    : [];
};

const convertOrganisationClaims = (
  orgClaimData: OrganisationClaims,
): OrganisationClaimInfo[] => {
  return orgClaimData
    ? Object.getOwnPropertyNames(orgClaimData)
        .filter((orgId) =>
          Boolean(!!orgClaimData[orgId]?.role && !!orgClaimData[orgId]?.feat),
        )
        .map((orgId) => ({
          displayName: '',
          id: orgId,
          role: orgClaimData[orgId].role,
        }))
    : [];
  // return orgClaimData && orgClaimData.length && orgClaimData.length > 0
  //   ? orgClaimData
  //       .filter((id) => Boolean(id) && id.length === 20)
  //       .map((id) => ({ displayName: '', id })) || [] // FIXME: display name missing => find best way for max 1 extra call to db (save in user document?)
  //   : [];
};

export function useAuthState(firebaseApp: any): AuthenticationState {
  const { env } = useAppStaticQueryConfig();
  const auth = firebaseApp ? getAuth(firebaseApp) : null;
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserInfo | null>(null);
  const [isOrganisationUser, setIsOrganisationUser] = React.useState<boolean>(false);
  const [isSystemUser, setIsSystemUser] = React.useState<boolean>(false);
  // system admin
  const [systemAdminClaims, setSystemAdminClaims] = React.useState<SystemAdminClaim[]>(
    [],
  );
  // organisation admin
  const [organisations, setOrganisations] = React.useState<OrganisationClaimInfo[]>([]);
  const [currentOrganisation, setCurrentOrganisation] =
    React.useState<OrganisationClaimInfo | null>(null);

  // FIXME: retrieve the org + role names from db in one call preferably
  React.useEffect(() => {
    if (firebaseApp && auth) {
      // firebase.auth().tenantId = 'Travlr-d2wka'; // TODO: test & implement?
      onAuthStateChanged(auth, async (usr) => {
        if (usr?.uid && usr.emailVerified) {
          // NOTE: TESTING

          if (usr.emailVerified) {
            setIsEmailVerified(true);
          }

          setIsLoggedIn(true);
          // const u = firebaseCompat.auth().currentUser; // https://github.com/EddyVerbruggen/nativescript-plugin-firebase/issues/1008

          const idTokenResult = await usr.getIdTokenResult();
          // idTokenResult.claims.admin = ['sua'];
          // idTokenResult.claims.orgs = {
          //     e195c4Fz7RJ98x90N9rJ: {
          //       role: 'manager',
          //       feat: ['com', 'doc', 'med', 'ord', 'pro', 'rep', 'set', 'usr', 'evt'],
          //     },
          //   };
          // console.log('idTokenResult: ', idTokenResult);
          // console.log('emailVerified: ', usr?.emailVerified);
          // console.log('admin claims: ', idTokenResult.claims.admin);
          // console.log('orgs claims: ', idTokenResult.claims.orgs);

          // provide system admin claims
          if (idTokenResult && idTokenResult.claims.admin) {
            const adminRoles = convertSystemAdminClaims(
              idTokenResult.claims.admin as SystemAdminClaimType[],
            );
            // console.log('adminRoles: ', adminRoles);
            if (adminRoles?.length > 0) {
              setSystemAdminClaims(adminRoles);
              setIsSystemUser(true);
            }
          }
          // provide organisation claims
          if (idTokenResult && idTokenResult.claims.orgs) {
            const orgs = convertOrganisationClaims(
              idTokenResult.claims.orgs as OrganisationClaims,
            );
            if (orgs?.length > 0) {
              // TODO: multi org management
              // console.log('got org user organisations: ', orgs);
              setOrganisations(orgs);
              // if (orgs.length === 1) {
              //   setCurrentOrganisation(orgs[0]);
              // }
              setCurrentOrganisation(orgs[0]);
              setIsOrganisationUser(true);
            }
          }
          const userInfo: UserInfo = {
            displayName: usr?.displayName ?? '',
            email: usr?.email ?? '',
            phoneNumber: usr?.phoneNumber ?? '',
            photoURL: usr?.photoURL ?? '',
            providerId: usr?.providerId ?? '',
            uid: usr?.uid ?? '',
          };
          // console.log('logged in user: ', u);

          setUser(userInfo);
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
        } else {
          setUser(null);
          setIsLoggedIn(false);
          setIsOrganisationUser(false);
          setIsSystemUser(false);
          setIsEmailVerified(false);

          setSystemAdminClaims([]);
          setOrganisations([]);
          setCurrentOrganisation(null);
          signOut(auth);
        }
      });
    }
  }, [firebaseApp, auth]);

  const logout = () => {
    if (!auth) {
      return;
    }

    signOut(auth)
      .then(() => {
        setUser(null);
        setIsLoggedIn(false);
        setIsOrganisationUser(false);
        setIsSystemUser(false);
        setIsEmailVerified(false);

        setSystemAdminClaims([]);
        setOrganisations([]);
        setCurrentOrganisation(null);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const login = async (loginData: { email: string; password: string }) => {
    if (!auth) {
      return null;
    }
    return signInWithEmailAndPassword(auth, loginData.email, loginData.password);
  };

  // NOTE: regular user signup

  const signup = async (signupData: SignupFormData) => {
    if (!firebaseApp || !env || !signupData) {
      return Promise.reject(new Error('precondition failed'));
    }

    window.localStorage.setItem(LocalStorageKey.EMAIL_LOGIN, signupData.email);

    return userSignup(firebaseApp, env, {
      ...signupData,
      signupType: SignupType.USER,
    });
  };

  // NOTE: organisation user signup

  const signupOrganisationUser = async (signupData: SignupFormData) => {
    if (!firebaseApp || !env || !signupData) {
      return Promise.reject(new Error('precondition failed'));
    }

    window.localStorage.setItem(LocalStorageKey.EMAIL_LOGIN, signupData.email);

    return userSignup(firebaseApp, env, {
      ...signupData,
      signupType: SignupType.ORGANISATION_USER,
    });
  };

  return {
    currentOrganisation,
    logout,
    login,
    organisations,
    setCurrentOrganisation,
    signup,
    signupOrganisationUser,
    systemAdminRoles: systemAdminClaims,
    user,
    // helper flags
    isLoggedIn,
    isEmailVerified,
    isOrganisationUser,
    isSystemUser,
  };
}
