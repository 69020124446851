import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PrevIcon from '@material-ui/icons/NavigateBefore';

import {
  useLocalizationContext,
  useLocalizedNavigation,
  useSourcedContinents,
  useSourcedCountries,
  useSourcedCountryAreas,
  useSourcedCities,
  useSourcedCityAreas,
} from 'src/app/hooks';

import { ButtonTw, ButtonGroup, Grid, Typography } from 'src/app/components';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import {
  BaseComponentProps,
  CombinedAppProps,
  BaseLocalizedDocumentData,
} from 'src/app/types';

export interface BreadcrumbsProps extends BaseComponentProps {
  continentId: string;
  countryAreaId: string;
  cityAreaId: string;
  countryId: string;
  cityId: string;
}

// TODO. use MUI Breadcrumbs
/**
 * Breadcrumbs / WAPBreadcrumbs
 * @param { BreadcrumbsProps } props
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const Breadcrumbs = (props: BreadcrumbsProps) => {
  // TODO: rename in areaId and find out what value
  const { cityAreaId, cityId, countryId, continentId, countryAreaId, className, style } =
    props;
  const { language } = useLocalizationContext();
  const { navigateLocalized } = useLocalizedNavigation();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('sm') /* '(min-width:600px)' */);
  const fontSize = isWiderMD ? '0.9em' : '0.9em';

  // TODO: get id elements from gql query
  const continents = useSourcedContinents();
  const countries = useSourcedCountries();
  const countryAreas = useSourcedCountryAreas();
  const cities = useSourcedCities();
  const cityAreas = useSourcedCityAreas();

  const onNavigateBackContinentLevel = () => {
    navigateLocalized(`/${continentId}`);
  };
  const onNavigateBackCountryLevel = () => {
    navigateLocalized(`/${continentId}/${countryId}`);
  };
  const onNavigateBackCountryAreaLevel = () => {
    navigateLocalized(`/${continentId}/${countryId}/area/${countryAreaId}`);
  };
  const onNavigateBackCityLevel = () => {
    navigateLocalized(`/${continentId}/${countryId}/city/${cityId}`);
    // navigateLocalized(`/explore/s?=&country=${countryId}&city=${cityId}`);
  };
  const onNavigateBackCityAreaLevel = () => {
    navigateLocalized(`/${continentId}/${countryId}/city/${cityId}/area/${cityAreaId}`);
    // navigateLocalized(`/explore/s?=&country=${countryId}&city=${cityId}&area=${area}`);
  };
  const onNavigateBack = () => {
    onNavigateBackCityAreaLevel();
  };

  const getElementLocales = (elements: BaseLocalizedDocumentData[], id: string) => {
    const element = elements.find((el) => el.id === id);
    return (
      element?.localizations?.find((loc) => loc.localeId === language)?.locales?.name ||
      id
    );
  };
  return (    
    <ButtonGroup className={'flex flex-wrap gap-1 text-neutral-900 dark:text-neutral-50 text-p4 max-lg:flex-col'}>
      {continentId ? (
        <ButtonTw
          disabled={!countryId}
          onClick={onNavigateBackContinentLevel}
          variant={'text'}
          className={'flex gap-1 items-center hover:opacity-60 after:w-1 after:h-[1px] after:bg-neutral-900 dark:after:bg-neutral-50 last:after:hidden'}
        >          
          {getElementLocales(continents, continentId)}     
        </ButtonTw>
      ) : null}
      {continentId && countryId ? (
        <ButtonTw
          disabled={!countryAreaId && !cityId}
          onClick={onNavigateBackCountryLevel}
          variant={'text'}
          className={'flex gap-1 items-center hover:opacity-60 after:w-1 after:h-[1px] after:bg-neutral-900 dark:after:bg-neutral-50 last:after:hidden'}
        >          
          {getElementLocales(countries, countryId)}    
        </ButtonTw>
      ) : null}
      {continentId && countryId && countryAreaId ? (
        <ButtonTw
          disabled={!cityId}
          onClick={onNavigateBackCountryAreaLevel}
          variant={'text'}
          className={'flex gap-1 items-center hover:opacity-60 after:w-1 after:h-[1px] after:bg-neutral-900 dark:after:bg-neutral-50 last:after:hidden'}
        >          
          {getElementLocales(countryAreas, countryAreaId)}    
        </ButtonTw>
      ) : null}
      {continentId && countryId && cityId ? (
        <ButtonTw
          disabled={!cityAreaId}
          onClick={onNavigateBackCityLevel}
          variant={'text'}
          className={'flex gap-1 items-center hover:opacity-60 after:w-1 after:h-[1px] after:bg-neutral-900 dark:after:bg-neutral-50 last:after:hidden'}
        >          
          {getElementLocales(cities, cityId)}
        </ButtonTw>
      ) : null}
      {continentId && countryId && cityId && cityAreaId ? (
        <ButtonTw
          disabled={!cityAreaId}
          onClick={onNavigateBackCityAreaLevel}
          variant={'text'}
          className={'flex gap-1 items-center hover:opacity-60 after:w-1 after:h-[1px] after:bg-neutral-900 dark:after:bg-neutral-50 last:after:hidden'}
        >          
          {getElementLocales(cityAreas, cityAreaId)}          
        </ButtonTw>
      ) : null}
    </ButtonGroup>   
  );
};

const WAPBreadcrumbs = (props: CombinedAppProps<BreadcrumbsProps>) =>
  withoutAppProps<CombinedAppProps<BreadcrumbsProps>, BreadcrumbsProps>(
    Breadcrumbs,
    props,
  );

export { Breadcrumbs, WAPBreadcrumbs };
