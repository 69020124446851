import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { withoutAppProps } from 'src/app/lib/withoutAppProps';
import { CombinedAppProps, CombinedBaseComponentProps } from 'src/app/types';
// import { fade } from '@material-ui/core/styles/colorManipulator';

interface MProgressProps extends LinearProgressProps {}

// TODO: remove
const useStyles = (props: MProgressProps) =>
  makeStyles<Theme, MProgressProps>((theme) =>
    createStyles({
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
        // // color: theme.palette.primary.main,
        // textDecoration: 'none',
        // '&:hover': {
        //   // color: theme.palette.getContrastText('#222'),
        //   color: theme.palette.primary.main,
        //   textDecoration: 'none',
      },
    }),
  );

export interface LoadingIndicatorProps
  extends CombinedBaseComponentProps<MProgressProps> {}

/**
 * LoadingIndicator / WAPLoadingIndicator - MUI Progress
 * @param { LoadingIndicatorProps } props https://material-ui.com/api/progress/
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { children, className, ...passProps } = props;

  const cls = useStyles(passProps)(props); // TODO: remove
  return (
    <div className={clsx(cls.link, className || '')}>
      <LinearProgress {...passProps} />
      {children}
    </div>
  );
};

const WAPLoadingIndicator = (props: CombinedAppProps<LoadingIndicatorProps>) =>
  withoutAppProps<CombinedAppProps<LoadingIndicatorProps>>(LoadingIndicator, props);

export { LoadingIndicator, WAPLoadingIndicator };
