import * as React from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { NotificationType } from 'src/app/constants';
import { useAppContext } from 'src/app/hooks';

export interface UseUploadFile {
  isUploading: boolean;
  hasUploadError: boolean;
  hasUploadSuccess: boolean;
  uploadFile: (file: File, storagePath: string, replacefileName?: string) => void;
  uploadProgress: number;
  uploadFileUrl: string | null;
}

export const useUploadFile = (
  successNotification: string | React.ReactNode,
  errorNotification: string | React.ReactNode,
): UseUploadFile => {
  const { createNotification, firebase, isLoggedIn } = useAppContext();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [hasUploadError, setHasUploadError] = React.useState<boolean>(false);
  const [hasUploadSuccess, setHasUploadSuccess] = React.useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [uploadFileUrl, setUploadFileUrl] = React.useState<string | null>(null);
  const uploadFile = async (
    file: File,
    storagePath: string,
    replacefileName?: string,
  ) => {
    // console.log('start uploading - selectedFile: ', selectedFile);
    setIsUploading(true);
    if (typeof window === 'undefined' || !firebase || !isLoggedIn || !file) {
      return;
    }

    console.log('selected file: ', file);

    let fileName = file.name || 'new_image'; // FIXME: add uid + *.extentsion
    if (replacefileName) {
      fileName = replacefileName;
    }
    // const fileType = selectedFile.type;
    // const data = await file.arrayBuffer();
    const selectedfileData = await file;

    const filePath = `${storagePath}/${fileName}`;

    console.log('start upload, filepath: ', filePath);
    const fileRef = ref(getStorage(firebase), filePath);
    try {
      const task = uploadBytes(fileRef, selectedfileData);
      // Firebase.storage.TaskEvent.STATE_CHANGED
      // https://firebase.google.com/docs/reference/js/firebase.storage#taskevent // FIXME: check if still working
      task.on('STATE_CHANGED', (snapshot: any) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(percent);
        console.log(`${percent}% done`);
        // switch (snapshot.state) {
        //   case this.afStorage.storage.TaskState.PAUSED: // or 'paused'
        //   console.log('Upload is paused');
        //   break;
        //   case this.afStorage.storage.TaskState.RUNNING: // or 'running'
        //   console.log('Upload is running');
        //   break;
        // }
      });
      task
        .then(async (fileSnapshop) => {
          createNotification(NotificationType.SUCCESS, successNotification, 4000);
          // const downLoadUrl = await snapShot.ref.getDownloadURL();
          const downLoadUrl = await getDownloadURL(fileSnapshop.ref);
          console.log(
            'Uploaded a blob or file - snapShot.ref.fullPath: ',
            fileSnapshop.ref.fullPath,
          ); // use this to delete file afterwards?
          console.log(
            'Uploaded a blob or file - snapShot.ref.downLoadUrl: ',
            downLoadUrl,
          );
          console.log(
            'Uploaded a blob or file - snapshot.metadata: ',
            fileSnapshop.metadata,
          );
          setUploadFileUrl(downLoadUrl);
          setIsUploading(false);
          setHasUploadSuccess(true);
        })
        .catch(async (err) => {
          // show some thing? => err
          console.log('Upload - catched1 err: ', err);
          createNotification(NotificationType.ERROR, errorNotification, 4000);
          setIsUploading(false);
          setHasUploadError(true);
        });
      // setUploadTask(task);

      // await storageFileRef.put(selectedfileData).then(async (snapshot) => {
      //   setIsUploading(false);
      //   setHasUploadSuccess(true);

      //   // const metaData = {
      //   //   // bucket	string	NO
      //   //   // generation	string	NO
      //   //   // metageneration	string	NO
      //   //   // fullPath	string	NO
      //   //   // name	string	NO
      //   //   // size	number	NO
      //   //   // timeCreated	string	NO
      //   //   // updated	string	NO
      //   //   // md5Hash	string	YES on upload, NO on updateMetadata
      //   //   // cacheControl	string	YES
      //   //   // contentDisposition	string	YES
      //   //   // contentEncoding	string	YES
      //   //   // contentLanguage	string	YES
      //   //   // contentType	string	YES
      //   //   // customMetadata	Object containing string->string mappings	YES
      //   // };
      //   // await storageFileRef
      //   //   .updateMetadata(metaData)
      //   //   .then((newMeta) => {
      //   //     console.log('uploaded - file metadata:', newMeta);
      //   //   })
      //   //   .catch((error) => {
      //   //     console.warn('Upload error: ', error);
      //   //   })
      //   //   .finally(() => {
      //   //     setIsUploading(false);
      //   //   });
      // });
    } catch (err) {
      console.log('Upload - catched2 err: ', err);
      createNotification(NotificationType.ERROR, errorNotification, 4000);
      setIsUploading(false);
      setHasUploadError(true);
    }

    // const uploadImage = firebase
    // .functions()
    // .httpsCallable('organisationFunction-uploadImage');
    // const data = await file.text()
    // console.log('uploadData: ', data);
    // const res = await uploadImage({base64ImgStr: data});
    // console.log('res: ', res);

    // //read data from the blob objects(file)
    // let reader = new FileReader();

    // //reads the binary data and encodes it as base64 data url
    // reader.readAsDataURL(file);

    // //reads it finish with either success or failure
    // reader.onloadend = (reader: FileReader, event: ProgressEvent<FileReader>): any => {
    //   //reader.result is the result of the reading in base64 string
    //   setFileName(file.name);
    //   setSelectedFile(reader.result);
    //   // });
    //   //   this.sendImageData()
    // };
  };
  return {
    hasUploadError,
    hasUploadSuccess,
    isUploading,
    uploadFile,
    uploadProgress,
    uploadFileUrl,
  };
};
