import * as React from 'react';

import { BaseComponentProps, SourcedGQLContinent } from 'src/app/types';
import { useSourcedCountries, useSourcedProducts } from 'src/app/hooks';

import { GatsbyNodeType } from 'src/app/constants';

import { useLocalizationContext } from 'src/app/context';
import { ContentItemScreen } from '../ContentItemScreen';

export interface ContinentScreenProps extends BaseComponentProps {
  nextNodeId?: string;
  prevNodeId?: string;
  item: SourcedGQLContinent;
}

export const ContinentScreen = (props: ContinentScreenProps) => {
  const { item, nextNodeId, prevNodeId } = props; // TODO: pageContext & pathContext seems to contain same data (cityOBJ)
  const { language } = useLocalizationContext();
  const countries = useSourcedCountries();
  const products = useSourcedProducts();

  return (
    <ContentItemScreen
      collectionMappings={[
        {
          id: GatsbyNodeType.COUNTRY,
          collectionItems: countries,
          collectionItemLocationIdPropertyName: 'continentId',
        },
        {
          id: GatsbyNodeType.PRODUCT,
          collectionItems: products,
          collectionItemLocationIdPropertyName: 'continentId',
          renderWithProductListItem: true, // TODO:
        },
      ]}
      itemLocations={[]}
      itemType={GatsbyNodeType.CONTINENT}
      screenItemData={item}
      nextItemId={nextNodeId}
      prevItemId={prevNodeId}
    />
  );
};
