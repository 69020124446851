import * as React from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc, DocumentReference } from 'firebase/firestore';

export interface FirestoreDocumentFetchProps<T> {
  documentPath: string;
  onError: () => void;
  onSuccess: (data: T) => void;
}

export const FirestoreDocumentFetch = <T extends Record<string, any>>({
  documentPath,
  onError,
  onSuccess,
}: FirestoreDocumentFetchProps<T>) => {
  const firestore = useFirestore();
  const docRef = doc(
    firestore,
    documentPath,
    /* pathSegments */
  ) as DocumentReference<T>;

  const [hasFetched, setHasFetched] = React.useState(false);
  const [currentData, setCurrentData] = React.useState<T | null>(null);

  const fetchOptions = {
    initialData: null,
    // suspense
  };

  const { status, data } = useFirestoreDocData<T>(docRef, fetchOptions);
  const isDataAltered =
    !!currentData && JSON.stringify(currentData) !== JSON.stringify(data); // TODO: better deep comparison needed

  React.useEffect(() => {
    if (status === 'loading') {
      //
    }
    if (status === 'error' && onError !== undefined && !hasFetched) {
      onError();
      setHasFetched(true);
    }
    if (
      status === 'success' &&
      onSuccess !== undefined &&
      data &&
      (!hasFetched || (hasFetched && isDataAltered))
    ) {
      onSuccess(data);
      setCurrentData(data);
      setHasFetched(true);
    }
  }, [data, hasFetched, onError, onSuccess, status, isDataAltered]);

  return null;
};
