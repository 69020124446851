import * as React from 'react';
import dayjs from 'dayjs';
import { Grid, StringPropertyRow } from 'src/app/components';

import { useStyles } from 'src/app/context';

interface ObjectViewProps {
  children?: React.ReactNode;
  hideUndeclaredProperties?: boolean; // NOTE: if set => only those items which are declared in propertyOptions are rendered (propertyOptions must also to be provided)
  object: Record<string, any>;
  propertyOptions?: ObjectPropertyOption[];
}

export interface ObjectPropertyOption {
  id: string;
  // rest should be optional
  renderType?: '__date'; // defaults to "string" if not set
  renderParams?: 'YYYY-MM-DD hh:mm:ss'; // TODO: map all combinations to each renderType
}

// TODO: provide sort functions etc

export const ObjectView = ({
  children,
  hideUndeclaredProperties,
  object,
  propertyOptions,
}: ObjectViewProps) => {
  const styles = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        {object
          ? Object.getOwnPropertyNames(object)
              .sort()
              .map((objPropName) => {
                const value = object[objPropName];
                if (propertyOptions) {
                  const config = propertyOptions.find(
                    (option) => option.id === objPropName,
                  );
                  if (config) {
                    let v = JSON.stringify(value); // default operation
                    if (config.renderType === '__date') {
                      v = dayjs(value).format(
                        config.renderParams || 'YYYY-MM-DD hh:mm:ss',
                      );
                    }
                    return (
                      <StringPropertyRow
                        key={objPropName}
                        title={objPropName}
                        value={v}
                      />
                    );
                  }
                  if (hideUndeclaredProperties) {
                    return null;
                  }
                  return (
                    <StringPropertyRow
                      key={objPropName}
                      title={objPropName}
                      value={JSON.stringify(value)}
                    />
                  );
                }
                return (
                  <StringPropertyRow
                    key={objPropName}
                    title={objPropName}
                    value={JSON.stringify(value)}
                  />
                );
              })
          : null}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
