import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Grid } from 'src/app/components';
import { SourcedGQLProduct } from 'src/app/types';

import { useStyles, useAppContext } from 'src/app/context';
import {
  ActivityProductViewMode,
  BoatProductViewMode,
  ProductCategoryType,
} from 'src/app/constants';
import { ProductPriceContainer } from './ProductPriceContainer';
import { ActivityProductContentView } from './ActivityProductContentView';
import { ActivityProductContentControl } from './ActivityProductContentControl';
import { ProductContent2 } from './ProductContent2';

export interface ProductContentProps {
  product: SourcedGQLProduct;
}

export const ProductContent = ({ product }: ProductContentProps) => {
  // const { isAppDebug } = useAppContext();
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));

  const [viewMode, setViewMode] = React.useState<
    ActivityProductViewMode | BoatProductViewMode
  >(ActivityProductViewMode.FEATURES);

  // const isOldViewOn = !!isAppDebug;
  const isOldViewOn = true;

  if (product.meta?.categoryTypeIds?.length && product.meta.categoryTypeIds.length > 1) {
    console.warn('Multiple categoryTypeIds feature currently not supported');
    return null; // TODO: render products with multiple categoryTypes with type extracted from url (area dependant)
  }

  return (
    <>
      {/** // TODO: limit because of special flags? */}
      {/** PRODUCT PRICE (<= MD DEVICES) */}
      {/** // NOTE: this is visible on mobile */}
      {/* {!isWiderMD && product.id ? (
        <Grid item xs={12}>
          <ProductPriceContainer className={styles.p__1} product={product} />
        </Grid>
      ) : null} */}

      {isOldViewOn ? (
        <ProductContent2 product={product} />
      ) : (
        <>
          <ActivityProductContentControl
            viewMode={viewMode as ActivityProductViewMode}
            onChange={(val) => setViewMode(val)}
          />
          {product.meta.categoryTypeIds.includes(ProductCategoryType.ACTIVITY) ? (
            <ActivityProductContentView
              viewMode={viewMode as ActivityProductViewMode}
              product={product}
            />
          ) : null}
        </>
      )}
    </>
  );
};
